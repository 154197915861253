<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Select Images
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <gallery [facilityId]="facility.Id" [checklistId]="checklistId" [assetId]="asset.Id" [selectable]="true" [embed]="true"
    [selectedFileIds]="newSelectedImages" (onImageSelected)="onImageSelected($event)"
    (onImageDeselected)="onImageDeselected($event)" (setImageSelectedIds)="onSetImageSelectedIds($event)" [allowTakingPhotos]="true"></gallery>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="useSelected()" color="light">
        <ion-label>Use {{newSelectedImages.length}} selected</ion-label>
      </ion-segment-button>
      <ion-segment-button (click)="cancel()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>