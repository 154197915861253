<oms-header title="My Locations" [menu]="true"></oms-header>
<ion-content class="ion-padding">
  <facilities-list *ngIf="mode == 'downloaded'"></facilities-list>
  <facilities-search *ngIf="mode == 'search'"></facilities-search>
</ion-content>
<ion-tab-bar slot="bottom" class="checklist-questions-tabs" *ngIf="!isVendorTechnician">
  <ion-tab-button (click)="switchMode('downloaded')" [class.tab-selected]="mode == 'downloaded'">
    <i class="far fa-save"></i>
    <ion-label>Downloaded</ion-label>
  </ion-tab-button>
  <ion-tab-button (click)="switchMode('search')" [class.tab-selected]="mode == 'search'">
    <i class="far fa-search"></i>
    <ion-label>Search All</ion-label>
  </ion-tab-button>
</ion-tab-bar>