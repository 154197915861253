<ion-card (click)="goToChecklist()" *ngIf="facility && summary && summary.totalCount > 0">
    <ion-card-header class="ion-text-center">
        <ion-card-title>{{title}}
            <ng-container *ngIf="summary">
                <span class="count">{{summary.totalAnsweredCount}}/{{summary.totalCount}}</span>
            </ng-container>
        </ion-card-title>
        <i [class]="icon" class="site-walk-icon facility-icon"></i>
    </ion-card-header>
    <ion-card-content *ngIf="!loading">
        <div class="facility-site-walk-info" *ngIf="summary && showSummary">
            <!-- <span *ngIf="summary.imageCount > 0">
                <i class="fas fa-image"></i> {{summary.imageCount}}
            </span> -->
            <!-- <span *ngIf="summary.requiredCount > 0">
                <i class="fas fa-asterisk color-danger"></i>
                {{summary.requiredAnsweredCount}}/{{summary.requiredCount}}
            </span> -->
            <span *ngIf="summary && summary.lastUpdatedDate != null">
                <i class="fas fa-edit"></i> {{summary.lastUpdatedDate | localdatetime}}
            </span>
        </div>
        <!-- <div *ngIf="summary && summary.lastUpdatedDate != null" class="facility-site-walk-last-updated">
            Updated: {{summary.lastUpdatedDate | localdatetime}}</div> -->
    </ion-card-content>
    <ion-card-content *ngIf="loading">
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
    </ion-card-content>
</ion-card>