import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as _ from "lodash";
import { CameraHelperService } from '../../services/plugin-helpers/camera-helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MultiPlatformUploadFileName, MultiPlatformUploadOptions, UploadedFile } from '../../models/multi-file-upload-models';
import { FileUploader } from 'ng2-file-upload';
import { PopoverController } from '@ionic/angular';
import { NativeMultiFileUploadMediaSelectorComponent } from './native-multi-file-upload-media-selector.component';

@Component({
  selector: 'native-multi-file-upload',
  templateUrl: './native-multi-file-upload.component.html',
  styleUrls: ['./native-multi-file-upload.scss']
})
export class NativeMultiFileUploadComponent {
  @Input()
  label: string = "Attach Additional Files";
  @Input()
  options: MultiPlatformUploadOptions;
  @Input()
  hideUploadButton: boolean;
  @Input()
  uploadedFiles: UploadedFile[] = [];
  uploader: FileUploader;
  @Input()
  takePhotosOnly: boolean;

  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;

  constructor(
    private cameraHelperService: CameraHelperService,
    private domSanitizer: DomSanitizer,
    private popoverController: PopoverController
  ) {
  }

  async open(event: Event) {
    if (this.takePhotosOnly) {
      await this.takePhoto();
    } else {
      this.openFilePicker(event);
    }
  }

  async openGalleryPicker() {
    let fileNames: MultiPlatformUploadFileName[] = [];
    let files = await this.cameraHelperService.pickImages(() => {
      let tmp = this.options.GetFileIdAndName();
      fileNames.push(tmp);
      return tmp.Name;
    });
    for (let file of files) {
      var name = fileNames.find(f => f.Name == file.FileName);
      this.uploadedFiles.push({
        Key: name.Id,
        Name: file.OriginalFileName || file.FileName,
        Url: file.PhonePath
      });
    }
  }

  getFiles(): UploadedFile[] {
    return this.uploadedFiles;
  }

  setFiles(files: UploadedFile[]) {
    this.uploadedFiles = files;
  }

  hasFiles() {
    return this.uploadedFiles.length > 0;
  }

  delete(index: number) {
    if (confirm("Are you sure you wanto to delete this?")) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  isImage(item: UploadedFile) {
    const lowerCase = item.Name?.toLowerCase();
    return lowerCase?.endsWith(".jpg")
      || lowerCase?.endsWith(".jpeg")
      || lowerCase?.endsWith(".png")
      || lowerCase?.endsWith(".heic");
  }

  async openFilePicker(event: Event) {
    const popover = await this.popoverController.create({
      component: NativeMultiFileUploadMediaSelectorComponent,
      event: event,
      translucent: true,
      componentProps: {
        takePhoto: () => { this.takePhoto() },
        selectFile: () => { this.selectFile() }
      },
      mode: "ios"
    });
    return await popover.present();
  }

  convertUrl(url: string) {
    let newUrl = window["Ionic"].WebView.convertFileSrc(url);
    if (newUrl && newUrl.indexOf("file://") == 0) {
      newUrl = newUrl.replac("file://", "");
    }
    newUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(newUrl);
    return newUrl;
  }

  private async selectFile() {
    this.closePopup();
    let uniqueFileIdAndName = this.options.GetFileIdAndName();
    let uniqueFileName = uniqueFileIdAndName.Name;
    let uniqueFileId = uniqueFileIdAndName.Id;

    let result = await this.cameraHelperService.selectFile(uniqueFileName);
    if (result) {
      this.uploadedFiles.push({
        Key: uniqueFileId,
        Name: result.OriginalFileName || result.FileName,
        Url: result.PhonePath
      });
    }
  }

  private async takePhoto() {
    this.closePopup();
    let uniqueFileIdAndName = this.options.GetFileIdAndName();
    let uniqueFileName = uniqueFileIdAndName.Name;
    let uniqueFileId = uniqueFileIdAndName.Id;
    await this.cameraHelperService.takePhoto(uniqueFileName);
    let fileEntry = (await this.cameraHelperService.getImagesTaken()).find(x => x.name == uniqueFileName);
    this.uploadedFiles.push({
      Key: uniqueFileId,
      Name: uniqueFileName,
      Url: fileEntry.nativeURL
    });
  }

  private closePopup() {
    try {
      this.popoverController.dismiss();
    } catch { }
  }
}