<div class="ion-text-center ion-margin">
    <ion-button (click)="open()">Download Checklist PDF</ion-button>
</div>
<div class="ion-text-center ion-margin">
    <ion-button (click)="openBlank()">Download Blank Checklist PDF</ion-button>
</div>
<div class="ion-text-center ion-margin">
    <ion-button (click)="openCloseout()">Download Close Out PDF</ion-button>
</div>
<div class="ion-padding" *ngIf="pendingCount > 0">
    You have some data that is not yet uploaded to OMS. Download PDF will give you a PDF with latest data that is
    uploaded.
</div>