<ion-card>
  <div class="ion-text-center ion-padding-top">
    <i class="fal fa-truck" style="font-size: 64px"></i>
  </div>
  <ion-card-header class="ion-text-center">
    <ion-card-title>App Logs</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <div class="ion-text-center">
      If you're having issues with the app, OMS support may ask you to send logs. You can do that by clicking the
      button "Send Logs" below
    </div>
    <a (click)="sendLogs()" class="ion-padding-top">
      <ion-button shape="round" fill="outline" class="w-100">
        Send Logs
        <i class="far fa-sync-alt animate-rotate" *ngIf="sendingLogs"></i>
      </ion-button>
    </a>
    <a (click)="ping()" class="ion-padding-top">
      <ion-button shape="round" fill="outline" class="w-100">
        Ping OMS
        <i class="far fa-sync-alt animate-rotate" *ngIf="sendingPing"></i>
      </ion-button>
    </a>
  </ion-card-content>
</ion-card>