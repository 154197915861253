<ion-list>
    <ion-item (click)="capturePhoto($event)">
        <ion-label>
            Take Photo
        </ion-label>
        <ion-icon name="camera" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="select($event)" lines="none">
        <ion-label>
            Select a file
        </ion-label>
        <ion-icon name="document-outline" slot="end"></ion-icon>
    </ion-item>
</ion-list>