import { Component, Input, OnInit } from "@angular/core";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { AssetManageService } from "../asset-manage.service";
import { AlertController, ModalController, PopoverController } from "@ionic/angular";
import { Router } from "@angular/router";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { AssetsService } from "../assets.service";
import { Facility } from "src/app/data/entities/Facility";
import { AssetSchedulingComponent } from "../asset-scheduling/asset-scheduling.component";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { AssetWorkHistoryComponent } from "../asset-work-history/asset-work-history.component";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { BatteryDataComponent } from "../batteries/battery-data.component";
import * as _ from "lodash";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "asset-option-selector",
  templateUrl: "asset-option-selector.component.html"
})
export class AssetOptionSelectorComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  asset: SiteAsset;
  @Input()
  assets: SiteAsset[];
  @Input()
  checklistId: number;
  @Input()
  preview: boolean = false;

  isWeb: boolean = false;
  isVendor: boolean = false;

  constructor(
    private assetManageService: AssetManageService,
    private popoverController: PopoverController,
    private router: Router,
    private cameraHelperService: CameraHelperService,
    private assetsService: AssetsService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private previewService: PreviewService,
    private alertController: AlertController,
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    this.isWeb = WebAppShowComponent.IsWebAppIFrame;
    this.isVendor = await this.userService.isVendor();
  }

  async editAsset() {
    await this.assetManageService.editAsset(this.asset, this.checklist, false, false, this.preview, null);
    await this.popoverController.dismiss();
  }

  async replaceAsset() {
    var asset = this.assetsService.getAssetFromFacility(this.facility, this.asset.Id);
    var subAssetsCount = _.filter(asset.Assets || [], a => a.OneToMany || a.Replaceable).length;
    if (subAssetsCount > 0) {
      //alert("Replace not supported on this type of asset");
      const alert = await this.alertController.create({
        header: `What do you want to replace?`,
        buttons: [
          {
            text: "Replace this asset only",
            handler: async () => {
              await this.assetManageService.editAsset(asset, this.checklist, true, false, false, null);
              await this.popoverController.dismiss();
              await this.alertController.dismiss();
            }
          },
          {
            text: "Replace this asset + all sub assets",
            handler: async () => {
              await this.assetManageService.editAsset(asset, this.checklist, true, true, false, null);
              await this.popoverController.dismiss();
              await this.alertController.dismiss();
            }
          },
          {
            text: "Cancel",
            role: "cancel"
          }
        ]
      });
      return await alert.present();
    } else {
      var replaceSubAssets = (asset.Batteries || []).length > 0;
      await this.assetManageService.editAsset(asset, this.checklist, true, replaceSubAssets, this.preview, null);
      await this.popoverController.dismiss();
    }
  }

  async deleteAsset() {
    await this.assetManageService.deleteAsset(this.asset, this.assets, this.checklist);
    await this.popoverController.dismiss();
  }

  async goToBatteries() {
    await this.assetManageService.goToBatteries(this.asset, this.checklist, this.preview);
    await this.popoverController.dismiss();
  }

  async replaceBatteries() {
    const modal = await this.modalController.create({
      component: BatteryDataComponent,
      componentProps: {
        asset: this.asset,
        facility: this.facility,
        checklistId: this.checklistId,
        replace: true,
        preview: this.preview
      }
    });
    this.popoverController.dismiss();
    return await modal.present();
  }

  async getQuote() {
    await this.popoverController.dismiss();
    this.router.navigate([`/facilities/${this.asset.SiteKey}/quotes/${this.checklist}/highlight/${this.asset.Id}`]);
  }

  async uploadBatteryTests() {
    await this.popoverController.dismiss();
    this.router.navigate([`/facilities/${this.asset.SiteKey}/batterytests/${this.checklist}/${this.asset.Id}`]);
  }

  async takePhoto(): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame && window.parent) {
      window.parent.postMessage({ message: "open-image-gallery-web", siteKey: this.facility.Id, siteAssetKey: this.asset.Id }, "*")
      return;
    }
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist,
      SiteAssetId: this.asset.Id
    });

    const image = await this.cameraHelperService.takePhoto(fileName);
    if (image) {
      this.asset.ImageCount = (this.asset.ImageCount || 0) + 1;
      this.assetsService.updateFacilityNoSyncNeeded(this.facility);
    }
  }

  async openGallery(): Promise<void> {
    this.router.navigate([`/gallery/${this.facility.Id}/${this.checklist}/${this.asset.Id}/null/false/true/${this.preview}/open`]);
    await this.popoverController.dismiss();
  }

  async scheduleWork(): Promise<void> {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const modal = await this.modalController.create({
      component: AssetSchedulingComponent,
      componentProps: {
        asset: this.asset,
        facility: this.facility
      }
    });
    return await modal.present();
  }

  async workHistory(): Promise<void> {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const modal = await this.modalController.create({
      component: AssetWorkHistoryComponent,
      componentProps: {
        asset: this.asset,
        facility: this.facility
      }
    });
    return await modal.present();
  }

  get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }
}
