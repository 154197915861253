import { Component, Input, AfterViewInit, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ChecklistSectionModel, ChecklistQuestionModel, ChecklistQuestionAnswerModel, JobDataModel, ChecklistQuestionImageModel } from "../checklist.models";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { ChecklistService } from "../checklist.service";
import { ToastController, PopoverController, ModalController, AlertController, IonDatetime } from "@ionic/angular";
import * as _ from "lodash";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventsConstants from "../../shared/constants/events-constants";
import { DateTimeHelperService } from "src/app/shared/services/datetime/datetime-helper.service";
import { Router } from "@angular/router";
import { QuestionMediaSelectorComponent } from "./question-media-selector.component";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { MyWorkService } from "src/app/my-work/my-work.service";
import { AssetSchedulingSelectionComponent } from "src/app/assets/asset-scheduling-selection/asset-scheduling-selection.component";
import { SelectedAsset, SelectListItem } from "src/app/assets/assets.models";
import { AssetReplaceComponent } from "src/app/assets/asset-replace/asset-replace.component";
import { ChecklistAnswerBatteryTestUploadComponent } from "src/app/assets/checklist-answer-battery-test-upload/checklist-answer-battery-test-upload.component";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { Guid } from "guid-typescript";
import { QuestionAttachmentsComponent } from "./question-attachments.component";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { CameraPhotoModel } from "src/app/shared/services/plugin-helpers/models/camera-photo.model";

@Component({
  selector: "checklist-question",
  templateUrl: "question.component.html"
})
export class QuestionComponent implements OnInit, AfterViewInit {
  @Input()
  checklist: FacilityChecklist
  @Input()
  section: ChecklistSectionModel;
  @Input()
  item: ChecklistQuestionModel;
  @Input()
  preview: boolean;
  options: SelectListItem[] = [];
  oldItem: ChecklistQuestionModel;
  id: string;
  isWeb: boolean = false;
  showQuestionWebImages: boolean = false;

  @ViewChild('webImages') webImages: ElementRef;
  @ViewChild('fileUpload') fileUpload: ElementRef;

  constructor(
    private cameraHelperService: CameraHelperService,
    private checklistService: ChecklistService,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private toastController: ToastController,
    private pubSub: PubSubService,
    private dateTimeHelperService: DateTimeHelperService,
    private router: Router,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private previewService: PreviewService,
    private myWorkService: MyWorkService,
    private modalController: ModalController,
    private loaderHelperService: LoaderHelperService,
    private browserHelperService: BrowserHelperService
  ) {
  }

  ngOnInit() {
    this.oldItem = _.cloneDeep(this.item);
    this.preview = this.preview || this.item.Readonly;
    this.options = this.getOptions();
    this.id = Guid.create().toString();
    this.isWeb = !this.browserHelperService.isNativeApp();
    if (WebAppShowComponent.IsWebAppIFrame) {
      this.showQuestionWebImages = true;
    }
  }

  ngAfterViewInit() {
  }

  async selectFile(event: Event): Promise<void> {
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist.Id,
      VendorViewId: this.item.VendorViewPK,
    });

    let originalFileName = await this.cameraHelperService.selectFile(fileName);
    if (originalFileName) {
      this.item.ImageCount = (this.item.ImageCount || 0) + 1;
      this.item.ImageUpdatedDate = this.dateTimeHelperService.getUtcString();
      this.checklistService.updateFacilityChecklistNoSyncNeeded(this.checklist);
      await this.myWorkService.updateJobImageCount(this.checklist.Id, 1);
    }
  }

  async takePhoto(event: Event, fromGallery: boolean = false): Promise<void> {
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist.Id,
      VendorViewId: this.item.VendorViewPK,
    });

    if (this.item.Photo && this.item.Video) {
      const popover = await this.popoverController.create({
        component: QuestionMediaSelectorComponent,
        event: event,
        translucent: true,
        componentProps: {
          checklist: this.checklist,
          section: this.section,
          item: this.item
        },
        mode: "ios"
      });
      return await popover.present();
    }

    let images: CameraPhotoModel[] = [];
    if (this.item.Video) {
      let tmpImage = await this.cameraHelperService.takeVideo(fileName);
      if (tmpImage) {
        images.push(tmpImage);
      }
    } else {
      if (fromGallery) {
        images = await this.cameraHelperService.pickImages(() => {
          return this.cameraHelperService.getFileName({
            ChecklistId: this.checklist.Id,
            VendorViewId: this.item.VendorViewPK,
          })
        }) || [];
      } else {
        let tmpImage = await this.cameraHelperService.takePhoto(fileName, fromGallery);
        if (tmpImage) {
          images.push(tmpImage);
        }
      }
    }
    //let image = this.item.Video ? await this.cameraHelperService.takeVideo(fileName) : await this.cameraHelperService.takePhoto(fileName, fromGallery);
    if (images && images.length > 0) {
      for (let image of images) {
        if (image) {
          this.item.ImageCount = (this.item.ImageCount || 0) + 1;
        }
      }
      this.item.ImageUpdatedDate = this.dateTimeHelperService.getUtcString();
      await this.checklistService.updateFacilityChecklistNoSyncNeeded(this.checklist);
      await this.myWorkService.updateJobImageCount(this.checklist.Id, images.length);
    }
  }

  async scan(answer: ChecklistQuestionModel, event: Event): Promise<void> {
    const text = await this.barcodeScannerHelperService.scan(`Scan barcode for question ${answer.PromptText}`);
    if (text) {
      if (text != answer.AuditData && answer.Unique && !this.isScanUnique(text)) {
        const alert = await this.alertController.create({
          header: "Scan error",
          message: "Serial number is already used on this checklist.",
          buttons: [
            {
              text: "Scan again",
              handler: async () => {
                this.scan(answer, event);
              }
            },
            {
              text: "Cancel",
              role: "cancel"
            }
          ]
        });
        await alert.present();
        return;
      }
      answer.AuditData = text;
      answer.AnswerChanged = true;
      this.onAnswerChange(true);
    }
  }

  isScanUnique(scanValue: string): boolean {
    if ((scanValue || "").length > 0) {
      for (let section of this.checklist.SectionsModel) {
        for (let question of section.Questions) {
          if (question.FieldDataType == 'scan' &&
            question.Unique &&
            question.AuditData == scanValue &&
            question != this.item) {
            return false;
          }
        }
      }
    }
    return true;
  }

  clearDate(answer: ChecklistQuestionModel) {
    answer.AuditData = null;
    this.onAnswerChange(true);
  }

  async goToAssets() {
    this.item.AuditData = "Accessed";
    this.item.AnswerChanged = true;
    this.item.LastUpdatedDate = this.dateTimeHelperService.getUtcString();
    await this.checklistService.updateFacilityChecklist(this.checklist);
    this.pubSub.$pub(EventsConstants.CHECKLIST_ANSWER_UPDATED, this.section);
    this.router.navigate([`/facilities/${this.checklist.SiteKey}/assets/fromJob/${this.checklist.Id}/${this.preview}`]);
  }

  async goToAssetSelection() {
    let assetIds = (this.item.AuditData || "").split(",");
    let jobData: JobDataModel = JSON.parse(this.checklist.JobData);
    if (!this.item.AuditData && !this.item.Id && !this.item.LastUpdatedDate) {
      if (jobData.Assets && jobData.Assets.length > 0) {
        assetIds = _.map(jobData.Assets, "Id");
      }
    }
    let modal = await this.modalController.create({
      component: AssetSchedulingSelectionComponent,
      componentProps: {
        facilityId: this.checklist.SiteKey,
        checklistId: this.checklist.Id,
        assetIds: assetIds,
        onSelected: async (value: SelectedAsset[]) => {
          this.item.AuditData = _.map(value || [], "Id").join(",");
          this.item.AnswerChanged = true;
          this.item.LastUpdatedDate = this.dateTimeHelperService.getUtcString();
          this.checklist.JobDataModel = JSON.parse(this.checklist.JobData);
          this.checklist.JobDataModel.Assets = value;
          this.checklist.JobData = JSON.stringify(this.checklist.JobDataModel);
          await this.checklistService.updateFacilityChecklist(this.checklist);
          this.pubSub.$pub(EventsConstants.CHECKLIST_ANSWER_UPDATED, this.section);
        }
      }
    });
    modal.present();
  }

  async replaceAssets() {
    let assetIds = (this.item.AuditData || "").split(",");
    let modal = await this.modalController.create({
      component: AssetReplaceComponent,
      componentProps: {
        facilityId: this.checklist.SiteKey,
        checklistId: this.checklist.Id,
        preview: this.preview,
        assetIds: (assetIds || []).filter(x => x),
        onReplaced: async (list: string[]) => {
          this.item.AuditData = (list || []).join(",");
          this.item.AnswerChanged = true;
          this.item.LastUpdatedDate = this.dateTimeHelperService.getUtcString();
          let checklistFromDb = await this.checklistService.getChecklistById(this.checklist.Id);
          this.checklist.JobData = checklistFromDb.JobData;
          await this.checklistService.updateFacilityChecklist(this.checklist);
          this.pubSub.$pub(EventsConstants.CHECKLIST_ANSWER_UPDATED, this.section);
        }
      }
    });
    modal.present();
  }

  async uploadBatteryTests() {
    let modal = await this.modalController.create({
      component: ChecklistAnswerBatteryTestUploadComponent,
      componentProps: {
        facilityId: this.checklist.SiteKey,
        checklistId: this.checklist.Id,
        preview: this.preview,
        vendorViewId: this.item.VendorViewPK,
        onUploaded: async (siteAsset: SiteAsset) => {
          this.item.AuditData = "uploaded";
          this.item.AnswerChanged = true;
          this.item.LastUpdatedDate = this.dateTimeHelperService.getUtcString();
          await this.checklistService.updateFacilityChecklist(this.checklist);
        }
      }
    });
    modal.present();
  }

  showComment(): boolean {
    if (this.item.AnswerOptions && this.item.AnswerOptions.length > 0) {
      var answer = _.find(this.item.AnswerOptions, a => String(a.Id) == this.item.AuditData);
      return answer && answer.CommentFlag;
    }
    return false;
  }

  getOptions(): SelectListItem[] {
    var getItem = (a: ChecklistQuestionAnswerModel): SelectListItem => {
      return {
        Text: a.Name,
        Value: String(a.Id),
        Color: a.Color
      };
    };
    return this.item.AnswerOptions.map(getItem);
  }

  async uploadFileWeb(event) {
    try {
      this.loaderHelperService.on();
      let result = await this.checklistService.uploadFiles(this.checklist.Id, this.item, event.target.files);
      if (result && result.Images) {
        for (let i of result.Images) {
          this.item.Images.push(i);
        }
        if (this.isWeb && window.parent) {
          window.parent.postMessage({ message: "refresh-checklist-question-image-frame-module" }, "*");
        }
        let toast = await this.toastController.create({
          message: "Successfully Uploaded",
          duration: 1000,
          color: "success"
        });
        await toast.present();
        setTimeout(() => {
          this.webImages.nativeElement.scrollLeft = this.webImages.nativeElement.scrollWidth;
          //Have no idea why the above doesn't work
          let items = document.getElementsByClassName("questions-web-images");
          for (let i = 0; i < items.length; i++) { items[i].scrollLeft = items[i].scrollWidth };
        }, 500);

        this.item.ImageUpdatedDate = this.dateTimeHelperService.getUtcString();
        this.item.ImageCount = (this.item.ImageCount || 0) + result.Images.length;
        await this.checklistService.updateFacilityChecklistNoSyncNeeded(this.checklist);
        await this.myWorkService.updateJobImageCount(this.checklist.Id, result.Images.length);
      }
    } finally {
      this.loaderHelperService.off();
      this.fileUpload.nativeElement.value = "";
    }
  }

  openImageWeb(image: ChecklistQuestionImageModel) {
    if (this.isWeb && window.parent) {
      window.parent.postMessage({ message: "open-image-annotation", imageId: image.ImageId }, "*")
    }
  }

  historyWeb() {
    if (this.isWeb && window.parent) {
      window.parent.postMessage({ message: "open-question-history", checklistId: this.checklist.Id, vendorViewId: this.item.VendorViewPK }, "*")
    }
  }

  hasAttachments() {
    return this.item.Attachments && this.item.Attachments.length > 0;
  }

  async openAttachments() {
    if (this.item.Attachments && this.item.Attachments.length > 0) {
      let modal = await this.modalController.create({
        component: QuestionAttachmentsComponent,
        componentProps: {
          attachments: this.item.Attachments
        }
      });
      modal.present();
    }
  }

  async onDateSelected(datePicker: IonDatetime) {
    await datePicker.confirm(true);
    if (!datePicker.value) {
      await datePicker.reset(new Date().toISOString());
      await datePicker.confirm(true);
    }
  }

  private timeout: number = null;

  private onAnswerChange(force: boolean = false) {
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(async () => {
      if (!force && (this.oldItem.AuditData == this.item.AuditData && this.oldItem.Comment == this.item.Comment)) {
        return;
      }

      if (!force && this.item.Unique && this.item.FieldDataType == 'scan') {
        if (!this.isScanUnique(this.item.AuditData)) {
          const alert = await this.alertController.create({
            header: "Error",
            message: "Serial number is already used on this checklist.",
            buttons: [
              {
                text: "Okay",
                handler: () => {
                  this.item.AuditData = this.oldItem.AuditData;
                },
                role: "cancel"
              }
            ]
          });
          await alert.present();
          return;
        }
      }

      if (!this.showComment()) {
        this.item.Comment = null;
      }
      this.oldItem.Comment = this.item.Comment;
      this.item.AnswerChanged = true;
      this.item.LastUpdatedDate = this.dateTimeHelperService.getUtcString();
      await this.checklistService.updateFacilityChecklist(this.checklist);
      this.pubSub.$pub(EventsConstants.CHECKLIST_ANSWER_UPDATED, this.section);
    }, 500);
  }

}
