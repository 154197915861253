<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Status History
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content *ngIf="!loading">
    <ion-list>
        <ion-item *ngFor="let item of items">
            <ion-label>
                <div>{{item.Status}}</div>
                <div *ngIf="item.Notes" class="job-status-notes">
                    <i class="fal fa-comment-lines"></i>
                    {{item.Notes}}
                </div>
                <ion-note>
                    <i>{{item.UserFullName}} on {{item.Date}}</i>
                </ion-note>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>
<ion-content *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="dismissModal()" color="light" class="color-white">
                Close
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>