<ion-card *ngIf="asset">
    <ion-card-header>
        <ion-card-title>
            <ion-item>
                <ion-label>
                    <div>
                        {{assetImages.Name}}
                    </div>
                    <ion-note *ngIf="assetImages.Name != assetImages.Type">
                        {{assetImages.Type}}
                    </ion-note>
                </ion-label>
                <ion-icon name="camera-outline" slot="end" (click)="takeAssetPhoto(asset)" *ngIf="!preview&& allowTakingPhotos"></ion-icon>
            </ion-item>
        </ion-card-title>
    </ion-card-header>
    <ion-card-content style="margin: 0 1rem">
        <div *ngFor="let property of assetImages.Properties">
            <h4>{{property.Name}}</h4>
            <ion-grid>
                <ion-row>
                    <ng-container *ngFor="let image of property.Images">
                        <ion-col size="3">
                            <image-item [image]="image" (deleteImage)="deleteAssetPropertyImage($event)"
                                [selectable]="selectable" (onImageSelected)="onImageSelectedCallback($event)"
                                (onImageDeselected)="onImageDeselectedCallback($event)"
                                [preview]="preview">
                            </image-item>
                        </ion-col>
                    </ng-container>
                    <ion-col size="12" *ngIf="(loading || loadingWeb) && !isOffline">
                        <div class="color-medium ion-text-center">
                            Loading...
                        </div>
                    </ion-col>
                    <ion-col size="12" *ngIf="!loading && !loadingWeb && property.Images == 0">
                        <div class="color-medium ion-text-center">
                            No images
                        </div>
                    </ion-col>
                    <ion-col size="12" *ngIf="isOffline">
                        <div class="color-medium ion-text-center">
                            You're offline, some images may not load
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div>
            <h4 *ngIf="assetImages.Images && assetImages.Images.length > 0">
                Asset Images
            </h4>
            <ion-grid>
                <ion-row>
                    <ng-container *ngFor="let image of assetImages.Images">
                        <ion-col size="3">
                            <image-item [image]="image" (deleteImage)="deleteAssetImage($event)"
                                [selectable]="selectable" (onImageSelected)="onImageSelectedCallback($event)"
                                (onImageDeselected)="onImageDeselectedCallback($event)"
                                [preview]="preview">
                            </image-item>
                        </ion-col>
                    </ng-container>
                    <ion-col size="12" *ngIf="(loading || loadingWeb) && !isOffline">
                        <div class="color-medium ion-text-center">
                            Loading...
                        </div>
                    </ion-col>
                    <ion-col size="12"
                        *ngIf="!loading && !loadingWeb && assetImages.Images.length == 0 && assetImages.Properties.length == 0 && assetImages.BatteryImages.length == 0">
                        <div class="color-medium ion-text-center">
                            No images
                        </div>
                    </ion-col>
                    <ion-col size="12" *ngIf="isOffline">
                        <div class="color-medium ion-text-center">
                            You're offline, some images may not load
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div *ngIf="assetImages.BatteryImages.length > 0">
            <h4>Battery Images</h4>
            <ion-grid>
                <ion-row>
                    <ng-container *ngFor="let image of assetImages.BatteryImages">
                        <ion-col size="3">
                            <image-item [image]="image" (deleteImage)="deleteAssetBatteryImage($event)"
                                [selectable]="selectable" (onImageSelected)="onImageSelectedCallback($event)"
                                (onImageDeselected)="onImageDeselectedCallback($event)"
                                [preview]="preview">
                            </image-item>
                        </ion-col>
                    </ng-container>
                    <ion-col size="12" *ngIf="(loading || loadingWeb) && !isOffline">
                        <div class="color-medium ion-text-center">
                            Loading...
                        </div>
                    </ion-col>
                    <ion-col size="12" *ngIf="!loading && !loadingWeb && assetImages.BatteryImages == 0">
                        <div class="color-medium ion-text-center">
                            No images
                        </div>
                    </ion-col>
                    <ion-col size="12" *ngIf="isOffline">
                        <div class="color-medium ion-text-center">
                            You're offline, some images may not load
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ion-card-content>
</ion-card>

<ng-container *ngIf="!asset && checklistGallery">
    <ng-container *ngFor="let checklistImage of checklistImages">
        <ion-card *ngIf="checklistImage.hasImages()">
            <ion-card-header>
                <ion-card-title>
                    <ion-item>
                        <ion-label>
                            <div>
                                {{checklistImage.Name}}
                            </div>
                        </ion-label>
                    </ion-item>
                </ion-card-title>
            </ion-card-header>
            <ion-card-content style="margin: 0 1rem">
                <ng-container *ngFor="let question of checklistImage.Questions">
                    <ng-container *ngIf="question.hasImages()">
                        <div>
                            <h4>{{question.Name}}</h4>
                            <ion-grid>
                                <ion-row>
                                    <ng-container *ngFor="let image of question.Images">
                                        <ion-col size="3">
                                            <image-item [image]="image" (deleteImage)="deleteQuestionImage($event)"
                                                [selectable]="selectable"
                                                (onImageSelected)="onImageSelectedCallback($event)"
                                                (onImageDeselected)="onImageDeselectedCallback($event)"
                                                [preview]="preview">
                                            </image-item>
                                        </ion-col>
                                    </ng-container>
                                    <ion-col size="12" *ngIf="(loading || loadingWeb) && !isOffline">
                                        <div class="color-medium ion-text-center">
                                            Loading...
                                        </div>
                                    </ion-col>
                                    <ion-col size="12" *ngIf="!loading && !loadingWeb && question.Images.length == 0">
                                        <div class="color-medium ion-text-center">
                                            No images
                                        </div>
                                    </ion-col>
                                    <ion-col size="12" *ngIf="isOffline">
                                        <div class="color-medium ion-text-center">
                                            You're offline, some images may not load
                                        </div>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </div>
                    </ng-container>
                </ng-container>
            </ion-card-content>
        </ion-card>
    </ng-container>
    <ion-card>
        <ion-card-header>
            <ion-card-title>
                <ion-item>
                    <ion-label>
                        Additional Images
                    </ion-label>
                    <ion-icon name="image-outline" slot="end" (click)="takeChecklistPhoto(true)" *ngIf="!preview && allowTakingPhotos">
                    </ion-icon>
                    <ion-icon name="camera-outline" slot="end" (click)="takeChecklistPhoto()" *ngIf="!preview && allowTakingPhotos">
                    </ion-icon>
                </ion-item>
            </ion-card-title>
        </ion-card-header>
        <ion-card-content style="margin: 0 1rem">
            <div>
                <ion-grid>
                    <ion-row>
                        <ng-container *ngFor="let image of unassociatedChecklistImages">
                            <ion-col size="3">
                                <image-item [image]="image" (deleteImage)="deleteChecklistUnassociatedImage($event)"
                                    [selectable]="selectable" (onImageSelected)="onImageSelectedCallback($event)"
                                    (onImageDeselected)="onImageDeselectedCallback($event)"
                                    [preview]="preview">
                                </image-item>
                            </ion-col>
                        </ng-container>
                        <ion-col size="12" *ngIf="(loading || loadingWeb) && !isOffline">
                            <div class="color-medium ion-text-center">
                                Loading...
                            </div>
                        </ion-col>
                        <ion-col size="12" *ngIf="!loading && !loadingWeb && unassociatedChecklistImages.length == 0">
                            <div class="color-medium ion-text-center">
                                No images
                            </div>
                        </ion-col>
                        <ion-col size="12" *ngIf="isOffline">
                            <div class="color-medium ion-text-center">
                                You're offline, some images may not load
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </ion-card-content>
    </ion-card>
</ng-container>