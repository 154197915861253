<oms-header title="{{facility?.Name || 'Loading...'}}">
  <ion-back-button defaultHref="/facilities"></ion-back-button>
</oms-header>
<ion-content class="ion-padding my-work-details-container" *ngIf="!loading">
  <ng-container *ngIf="facility && facility.SiteWalkEnabled">
    <checklist-button [facility]="facility" [checklistId]="facility.SiteWalkChecklistId" title="Site Walk"
      [showSummary]="true" [preview]="false">
    </checklist-button>
  </ng-container>
  <ng-container *ngIf="facility">
    <assets-button [facility]="facility" [preview]="assetPreview"></assets-button>
  </ng-container>
  <ng-container *ngIf="facility">
    <quotes-button [facility]="facility"></quotes-button>
  </ng-container>
  <ng-container *ngIf="facility">
    <asset-lookup-button [facility]="facility"></asset-lookup-button>
  </ng-container>
</ion-content>
<ion-content class="ion-padding my-work-details-container" *ngIf="loading">
  <ion-card *ngFor="let i of [1,2,3,4]">
    <ion-card-content>
      <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
    </ion-card-content>
  </ion-card>
</ion-content>
<oms-bottom-trademark></oms-bottom-trademark>