import { Injectable } from "@angular/core";
import { BrowserHelperService } from "../browser/browser-helper.service";
import { Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable()
export class StorageWrapperService {
  private initialized: boolean = false;
  constructor(
    private browserHelperService: BrowserHelperService,
    private storage: Storage) {
  }

  async get(key: string): Promise<string> {
    if (this.browserHelperService.isNativeApp()) {
      await this.initDriver();
      return await this.storage.get(key);
    } else {
      return window.localStorage.getItem(key);
    }
  }

  async set(key: string, value: string) {
    if (this.browserHelperService.isNativeApp()) {
      await this.initDriver();
      await this.storage.set(key, value);
    } else {
      window.localStorage.setItem(key, value);
    }
  }

  async remove(key: string) {
    if (this.browserHelperService.isNativeApp()) {
      this.initDriver();
      await this.storage.remove(key);
    } else {
      window.localStorage.removeItem(key);
    }
  }

  async initDriver() {
    if(this.initialized) return;
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
    this.initialized = true;
  }
}
