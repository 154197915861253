import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class DateTimeHelperService {
  getString(): string {
    return moment().format("YYYY-MM-DD");
  }

  getUtcString(): string {
    return moment.utc().format("YYYY-MM-DD HH:mm:ss");
  }

  getUtcTimeStamp(): number {
    return moment.utc().valueOf();
  }

  getCustomPickerOptions = (callback: (value: string) => void) => {
    return {
      buttons: [
        {
          text: "Cancel",
          role: "cancel"
        },
        {
          text: "Clear",
          handler: () => {
            callback(null);
          }
        },
        {
          text: "Save",
          handler: (data) => {
            let year: string = data.year.text;
            let month: string = data.month.value < 10 ? '0' + data.month.value.toString() : data.month.value.toString();
            let day: string = data.day ? data.day.text : "0";
            let hour: string = data.hour ? data.hour.text : "0";
            let minute: string = data.minute ? data.minute.text : "0";
            callback(year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':00Z');
          }
        }
      ]
    };
  }
}
