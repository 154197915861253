<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Question Attachments
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-list *ngFor="let item of files">
        <ion-item (click)="open(item)">
            <ion-label>
                {{item.Description || item.FileName}}
            </ion-label>
        </ion-item>
    </ion-list>
    <ion-list *ngFor="let item of images">
        <ion-item (click)="open(item)">
            <ion-img *ngIf="isImage(item)" [src]="getUrl(item)">
            </ion-img>
        </ion-item>
    </ion-list>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="dismissModal()" color="light" class="color-white">
                Close
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>