import { Component, Input, OnInit } from "@angular/core";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { ModalController, PopoverController } from "@ionic/angular";
import { Router } from "@angular/router";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { AssetsService } from "../assets.service";
import { Facility } from "src/app/data/entities/Facility";
import { AssetSchedulingComponent } from "../asset-scheduling/asset-scheduling.component";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { AssetWorkHistoryComponent } from "../asset-work-history/asset-work-history.component";

@Component({
  selector: "sub-asset-option-selector",
  templateUrl: "sub-asset-option-selector.component.html"
})
export class SubAssetOptionSelectorComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  asset: SiteAsset;
  @Input()
  checklistId: number;
  @Input()
  preview: boolean;
  isWeb: boolean = false;

  constructor(
    private popoverController: PopoverController,
    private router: Router,
    private cameraHelperService: CameraHelperService,
    private assetsService: AssetsService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService
  ) {
  }

  ngOnInit() {
    this.isWeb = WebAppShowComponent.IsWebAppIFrame;
  }
  async getQuote() {
    await this.modalController.dismiss();
    await this.popoverController.dismiss();
    this.router.navigate([`/facilities/${this.asset.SiteKey}/quotes/${this.checklist}/highlight/${this.asset.Id}`]);
  }

  async takePhoto(): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame && window.parent) {
      window.parent.postMessage({ message: "open-image-gallery-web", siteKey: this.facility.Id, siteAssetKey: this.asset.Id }, "*")
      return;
    }
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist,
      SiteAssetId: this.asset.Id
    });

    const image = await this.cameraHelperService.takePhoto(fileName);
    if (image) {
      this.asset.ImageCount = (this.asset.ImageCount || 0) + 1;
      this.assetsService.updateFacilityNoSyncNeeded(this.facility);
    }
  }

  async scheduleWork(): Promise<void> {
    await this.popoverController.dismiss();
    if(await this.browserHelperService.isOfflineAndMessage()){
      return;
    }
    const modal = await this.modalController.create({
      component: AssetSchedulingComponent,
      componentProps: {
        asset: this.asset,
        facility: this.facility
      }
    });
    return await modal.present();
  }

  async workHistory(): Promise<void> {
    await this.popoverController.dismiss();
    if(await this.browserHelperService.isOfflineAndMessage()){
      return;
    }
    const modal = await this.modalController.create({
      component: AssetWorkHistoryComponent,
      componentProps: {
        asset: this.asset,
        facility: this.facility
      }
    });
    return await modal.present();
  }

  get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }
}
