<div class="file-input-container" [hidden]="hideUploadButton">
    <label>
        <input #fileInput type="file" ng2FileSelect [uploader]="uploader" multiple />
        {{label}}
    </label>
</div>
<ion-list>
    <ion-item *ngFor="let item of uploader.queue; let i = index">
        <ion-label>
            <ng-container *ngIf="isImage(item)">
                <img mediaPreview [media]="item?._file" />
            </ng-container>
            <ng-container *ngIf="!isImage(item)">
                {{ item?.file?.name }}
            </ng-container>
            <div *ngIf="item.isError" style="font-size: 10px;">
                <ion-text color="danger">
                    An error occurred while uploading this file
                </ion-text>
            </div>
        </ion-label>
        <ion-spinner *ngIf="item.isUploading && isAutoUpload()">
        </ion-spinner>
        <span *ngIf="item.isUploading && isAutoUpload()">{{item.progress}}%</span>
        <ion-button (click)="delete(i)">
            <ion-icon name="trash"></ion-icon>
        </ion-button>
    </ion-item>
    <ion-item *ngFor="let item of uploadedFiles; let i = index">
        <ion-label>
            <ng-container *ngIf="isUploadedFileImage(item)">
                <img [src]="getUploadedUrl(item)" />
            </ng-container>
            <ng-container *ngIf="!isUploadedFileImage(item)">
                {{ item?.Name }}
            </ng-container>
        </ion-label>
        <ion-button (click)="deleteUploadedImage(i)">
            <ion-icon name="trash"></ion-icon>
        </ion-button>
    </ion-item>
</ion-list>