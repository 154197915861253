<div class="battery-test-form" *ngIf="data">
  <h1>Tester information</h1>
  <table class="battery-test-form--configuration">
    <tr>
      <td class="header">
        Tester Model
      </td>
      <td>
        <input [(ngModel)]="data.BatteryTest.TesterModel" type="text" maxlength="100" />
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="2"></td>
    </tr>
    <tr>
      <td class="header">
        Tester Version
      </td>
      <td>
        <input [(ngModel)]="data.BatteryTest.TesterVersion" type="text" maxlength="100" />
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="2"></td>
    </tr>
    <tr>
      <td class="header">
        Additional Tester Information
      </td>
      <td>
        <textarea style="width: 100%" [(ngModel)]="data.BatteryTest.TesterInfo" maxlength="1000"></textarea>
      </td>
    </tr>
  </table>
</div>