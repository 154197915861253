import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  HostListener
} from "@angular/core";
import { Keyboard } from "@ionic-native/keyboard/ngx";

const TAB_KEY_CODE = 9;
const ENTER_KEY_CODE = 13;

@Directive({
  selector: "[close-keyboard-on-enter]" // Attribute selector
})
export class CloseKeyboardOnEnterDirective {
  @Output() input: EventEmitter<string> = new EventEmitter<string>();

  constructor(public Keyboard: Keyboard, public elementRef: ElementRef) {}

  @HostListener("keydown", ["$event"])
  keyEvent(event) {
    if (event.srcElement.tagName !== "INPUT") {
      return;
    }

    var code = event.keyCode || event.which;
    if (code === TAB_KEY_CODE) {
      event.preventDefault();
      this.Keyboard.hide();
    } else if (code === ENTER_KEY_CODE) {
      event.preventDefault();
      this.Keyboard.hide();
    }
  }
}
