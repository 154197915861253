import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileUploader, FileLikeObject, FileItem, FileUploaderOptions } from 'ng2-file-upload';
import * as _ from "lodash";
import { UploadedFile } from '../../models/multi-file-upload-models';
import { UserService } from '../../services/user-service/user-service.service';

@Component({
  selector: 'multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrls: ['./multi-file-upload.scss']
})
export class MultiFileUploadComponent implements OnInit, AfterViewInit {
  @Input()
  label: string = "Attach Additional Files";
  @Input()
  options: FileUploaderOptions;
  @Input()
  hideUploadButton: boolean;
  @Input()
  uploadedFiles: UploadedFile[] = [];
  public uploader: FileUploader;

  private token: string;
  
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;

  constructor(private userService: UserService) {
  }

  async ngOnInit() {
    this.uploader = new FileUploader(this.options || {});
    this.token = await this.userService.getUserToken();
  }

  ngAfterViewInit(): void {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      var data = JSON.parse(response);
      item.file.ServerId = data.Id;
      item.file.Url = data.Url;
    };
  }

  isUploading() {
    return this.uploader.queue.some(x => x.isUploading);
  }

  isAutoUpload() {
    if (this.options && this.options.autoUpload) {
      return true;
    }
    return false;
  }

  getUploadedFiles(): UploadedFile[] {
    return this.uploader.queue.map((fileItem) => {
      fileItem._file
      return {
        Key: fileItem.file["ServerId"],
        Name: fileItem._file.name,
        Url: fileItem.file["Url"]
      };
    }).concat(this.uploadedFiles);
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  getUploadedUrl(item: UploadedFile) {
    if(item){
      return item.Url + "?token=" + this.token;
    }
  }

  setFiles(files: UploadedFile[]){
    this.uploadedFiles = files;
  }

  hasFiles() {
    return this.uploader?.queue?.length > 0 || this.uploadedFiles.length > 0;
  }

  open() {
    this.fileInput.nativeElement.click();
  }

  delete(index: number) {
    if (confirm("Are you sure you want to delete this?")) {
      this.uploader.queue.splice(index, 1);
    }
  }

  deleteUploadedImage(index: number){
    if (confirm("Are you sure you want to delete this?")) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  isUploadedFileImage(item: UploadedFile) {
    const lowerCase = item?.Name?.toLowerCase();
    return lowerCase?.endsWith(".jpg")
      || lowerCase?.endsWith(".jpeg")
      || lowerCase?.endsWith(".heic");
  }

  isImage(item: FileItem) {
    let fileName = item._file?.name || "";
    let mediaType = item._file?.type || "";

    const lowerCase = fileName?.toLowerCase();
    return mediaType?.startsWith("image") || lowerCase?.endsWith(".jpg")
      || lowerCase?.endsWith(".jpeg")
      || lowerCase?.endsWith(".heic");
  }

  convertUrl(url: string) {
    let newUrl = window["Ionic"].WebView.convertFileSrc(url);
    if (newUrl && newUrl.indexOf("file://") == 0) {
      newUrl = newUrl.replac("file://", "");
    }
    return newUrl;
  }
}