import { Component, Input, OnInit } from "@angular/core";
import { AssetsService } from "../assets.service";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { Router } from "@angular/router";
import { Facility } from "src/app/data/entities/Facility";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "asset-path-info",
  templateUrl: "asset-path-info.component.html"
})
export class AssetPathInfoComponent implements OnInit {
  @Input() facility: Facility;
  @Input() assetId: string;
  @Input() preview: boolean;
  
  assetPath: SiteAsset[] = [];

  constructor(
    private assetService: AssetsService,
    private router: Router,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
    this.assetPath = await this.assetService.getAssetPath(this.facility, this.assetId);
  }

  async goToFacility() {
    if (this.assetPath.length > 0) {
      this.router.navigate([`facilities/${this.assetPath[0].SiteKey}/assets/${this.preview}`]);
      await this.closeDialogs();
    }
  }

  async goToAsset(asset: SiteAsset) {
    if (asset !== this.assetPath[this.assetPath.length - 1]) {
      this.router.navigate([`facilities/${asset.SiteKey}/assets/${asset.Id}/${this.preview}`]);
      await this.closeDialogs();
    }
  }

  private async closeDialogs() {
    try {
      let top = await this.modalController.getTop();
      if (top) {
        await this.modalController.dismiss();
      }
      top = await this.modalController.getTop();
      if (top) {
        await this.modalController.dismiss();
      }
    } catch (err) {
      console.error(err);
    }
  }
}
