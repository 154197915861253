import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { Facility } from "src/app/data/entities/Facility";
import { ActivatedRoute } from "@angular/router";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { BatteryTestService } from "../../battery-test.service";
import { ParsedBatteryTestModel } from "../../battery-test.models";
import { ModalController, ToastController } from "@ionic/angular";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";

@Component({
  selector: "battery-test-update",
  templateUrl: "battery-test-update.component.html"
})
export class BatteryTestUpdateComponent implements OnInit, OnDestroy {
  @Input()
  facility: Facility;
  @Input()
  checklistId: number;
  @Input()
  asset: SiteAsset;
  @Input()
  batteryTestId: number;
  @Input()
  fromDialog: boolean;
  data: ParsedBatteryTestModel;

  loading: boolean = false;
  updating: boolean = false;

  constructor(
    private batteryTestService: BatteryTestService,
    private route: ActivatedRoute,
    private pubSub: PubSubService,
    private userService: UserService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private toastController: ToastController,
    private pubSubService: PubSubService
  ) {
  }

  async ngOnInit() {
    if (!(await this.browserHelperService.isOfflineAndMessage())) {
      this.loading = true;
      this.data = (await this.batteryTestService.getModel(this.batteryTestId)).Data;
      this.loading = false;
      setTimeout(() => {
        window["syncscroll"].reset();
      }, 1000);
    }
  }

  ngOnDestroy() {
  }

  async save() {
    if (this.updating) {
      return;
    }
    try {

      if (this.data.Data.length == 0) {
        let message = "";
        if (!this.data.BatteryTest.TesterModel) {
          message = "Type in Tester Model";
        } else if (!this.data.BatteryTest.TesterVersion) {
          message = "Type in Tester Version";
        }
        if (message) {
          let toast = await this.toastController.create({
            message: message,
            duration: 2000,
            color: "danger"
          });
          await toast.present();
          return false;
        }
      }

      this.updating = true;
      await this.batteryTestService.updateTest(this.data);
      let toast = await this.toastController.create({
        message: "Tests Updated",
        duration: 2000,
        color: "success"
      });
      await toast.present();
      this.pubSubService.$pub(EventsConstants.ADD_UPDATE_BATTERY_TESTS);
      if (window.parent) {
        window.parent.postMessage({ message: "sync-done" }, "*")
      }
      this.dismissModal();
    } catch{
      let toast = await this.toastController.create({
        message: "An error occurred",
        duration: 2000,
        color: "danger"
      });
      await toast.present();
    }finally {
      this.updating = false;
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}