import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, QueryList, ViewChildren, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { Facility } from "../data/entities/Facility";
import { ActivatedRoute, Router } from "@angular/router";
import { AssetsService } from "../assets/assets.service";
import { RepositoryService } from "../data/db/repository.service";
import { SiteAsset } from "../data/entities/SiteAsset";
import { ChecklistService } from "../checklist/checklist.service";
import { ChecklistQuestionModel } from "../checklist/checklist.models";
import { GalleryImage } from "./gallery.models";
import { GalleryService } from "./gallery.service";
import { FacilityChecklist } from "../data/entities/FacilityChecklist";
import { GalleryItemComponent } from "./gallery-item/gallery-item.component";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { Subscription } from "rxjs";

@Component({
  selector: "gallery",
  templateUrl: "gallery.component.html"
})
export class GalleryComponent implements OnInit, OnDestroy {
  facility: Facility;
  loading: boolean;
  webCount: number = 0;
  @Input()
  facilityId: string;
  @Input()
  checklistId: number;
  @Input()
  selectable: boolean;
  @Input()
  selectedFileIds: number[] = [];
  @Input()
  embed: boolean = false;
  @Input()
  assetId: string;
  assets: SiteAsset[] = [];
  @Input()
  vendorViewId: string;
  @Input()
  checklistGallery: boolean = false;
  @Input()
  checklist: FacilityChecklist;
  question: ChecklistQuestionModel;
  @Input()
  allowTakingPhotos: boolean;
  @Input()
  preview: boolean;

  loadingWeb: boolean = true;
  imagesWeb: GalleryImage[] = [];
  title: string;

  @Output()
  onImageSelected: EventEmitter<GalleryImage> = new EventEmitter<GalleryImage>();
  @Output()
  onImageDeselected: EventEmitter<GalleryImage> = new EventEmitter<GalleryImage>();
  @Output()
  setImageSelectedIds: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ViewChildren('galleryitemsref') galleryItemList: QueryList<GalleryItemComponent>;
  @ViewChild('galleryitemref') galleryItem: GalleryItemComponent;

  showAssetSiteGallery: boolean;

  private subscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
    private repositoryService: RepositoryService,
    private assetsService: AssetsService,
    private checklistService: ChecklistService,
    private galleryService: GalleryService,
    private pubSubService: PubSubService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.subscription = this.pubSubService.$sub(EventConstants.REFRESH_GALLERY, () => {
      this.doRefresh(null);
    });
    await this.load();
  }

  async doRefresh(event: any) {
    this.assets = [];
    this.imagesWeb = [];
    if (this.galleryItem) {
      this.galleryItem.reset();
      this.galleryItem.ngOnInit();
    }
    if (this.galleryItemList) {
      for (let item of this.galleryItemList) {
        item.reset();
      }
    }
    await this.load();
    if (event) {
      event.target.complete();
    }
  }

  async load() {
    this.loading = true;
    this.loadingWeb = true;
    this.facilityId = this.facilityId || this.getRouteParam("id");
    this.assetId = this.assetId || this.getRouteParam("assetId");
    this.checklistId = this.checklistId || this.getRouteParam("checklistId");
    this.vendorViewId = this.vendorViewId || this.getRouteParam("vendorViewId");
    this.checklistGallery = this.checklistGallery || this.getRouteParam("checklistGallery") == "true";
    this.allowTakingPhotos = this.allowTakingPhotos || this.getRouteParam("checklistGalleryAllowTakingPhotos") == "true";
    this.preview = this.preview || this.getRouteParam("preview") == "true";
    if (!this.checklist) {
      this.checklist = await this.checklistService.getChecklistById(this.checklistId);
    }
    this.title = (this.checklist?.JobData || "").length > 0 ? "Gallery" : "Location Gallery";
    this.facility = await this.repositoryService.getFacilityRepository().findOneBy({ Id: this.facilityId });
    if (this.assetId) {
      this.facility = await this.assetsService.getFacilityWithAssets(this.facilityId);
      let asset = this.assetsService.getAssetFromFacility(this.facility, this.assetId);
      this.assets.push(asset);
      let assets = this.getAssetsRecursive(asset);
      //let assets = await this.assetsService.getAssets(this.facility, this.assetId, true);
      this.assets = this.assets.concat(assets);
    }
    this.loading = false;
    this.loadImagesFromWeb();
    this.setShowAssetSiteGallery();
  }

  private getRouteParam(key: string) {
    let value = this.activatedRoute.snapshot.params[key];
    if (value == "null") {
      return undefined;
    }
    return value;
  }
  private getAssetsRecursive(siteAsset: SiteAsset): SiteAsset[] {
    let result: SiteAsset[] = [];
    for (let asset of siteAsset.Assets) {
      result.push(asset)
      result = result.concat(this.getAssetsRecursive(asset));
    }
    return result;
  }

  async ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async loadImagesFromWeb() {
    try {
      this.imagesWeb = await this.galleryService.getImagesFromWeb(this.checklistId);
      this.loadingWeb = false;
    } catch {
      this.loadingWeb = true;
    }
  }
  updateWebCount(value: number) {
    this.webCount = value;
  }

  getSubtitle2() {
    if (this.question) {
      return `${this.question.SectionName}: ${this.question.PromptText}`;
    }
    return '';
  }

  onImageSelectedCallback(image: GalleryImage) {
    this.onImageSelected.next(image);
  }

  onImageDeselectedCallback(image: GalleryImage) {
    this.onImageDeselected.next(image);
  }

  onSetImageSelectedIds(imageIds: number[]) {
    this.setImageSelectedIds.next(imageIds);
  }

  async setShowAssetSiteGallery() {
    this.showAssetSiteGallery = false;
    if (this.assetId) {
      let checklist = await this.repositoryService.getFacilityChecklistRepository().findOneBy({ Id: this.checklistId });
      this.showAssetSiteGallery = (checklist?.JobData || "").length > 0;
    }
  };

  openAssetSiteGallery() {
    this.router.navigate([`/gallery/${this.facility.Id}/${this.facility.SiteGalleryChecklistId}/${this.assetId}/null/false/true/${this.preview}/open`]);
  }
}