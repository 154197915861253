<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Job Details
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="my-work-details-container my-work-details-modal ion-padding">
        <!-- <ion-button expand="block" (click)="dismiss()" *ngIf="fromMap" size="small" color="medium">
            <i class="fas fa-times ion-margin-end"></i>
            Close
        </ion-button> -->
        <ion-button expand="block" (click)="goToItem()" *ngIf="fromMap && facility" size="small">
            Go to Job
        </ion-button>
        <checklist-button *ngIf="facility && !jobModel.IsSiteWalk && facility.SiteWalkEnabled" [facility]="facility"
            [checklistId]="facility.SiteWalkChecklistId" title="Site Walk" [showSummary]="false" [preview]="preview">
        </checklist-button>
        <ion-button expand="block" (click)="navigate()"
            *ngIf="!fromMap && this.jobModel.Latitude && this.jobModel.Latitude" color="medium">
            <i class="far fa-location-arrow ion-margin-end"></i>
            Navigate to Site
        </ion-button>
        <ion-button expand="block" (click)="navigate()"
            *ngIf="fromMap && this.jobModel.Latitude && this.jobModel.Latitude" color="medium" size="small">
            <i class="far fa-location-arrow ion-margin-end"></i>
            Navigate to Site
        </ion-button>
        <div>
            <div class="job-details-item">
                <label>Job ID:</label> {{jobModel.ChecklistId}}
            </div>
            <div class="job-details-item" *ngIf="isVendor">
                <label>Asset Owner:</label>{{jobModel.CustomerName}}
            </div>
            <div class="job-details-item">
                <label>Location:</label>{{jobModel.SiteName}}
                <div *ngIf="jobModel.SiteDescription">{{jobModel.SiteDescription}}</div>
            </div>
            <div class="job-details-item">
                <label>Address:</label>{{jobModel.SiteAddress}}
            </div>
            <!-- <div *ngIf="jobModel.FACode" class="job-details-item">
                <label>FA Code:</label>{{jobModel.FACode}}
            </div> -->
            <div class="job-details-item" *ngIf="jobModel.WorkOrderNumber">
                <label>Work Order Number:</label>{{jobModel.WorkOrderNumber}}
            </div>
            <div class="job-details-item" *ngIf="jobModel.SystemPartNumber">
                <label>System Part Number:</label>{{jobModel.SystemPartNumber}}
            </div>
            <div class="job-details-item" *ngIf="jobModel.ReferenceNumber">
                <label>Reference Number:</label>{{jobModel.ReferenceNumber}}
            </div>
            <div class="job-details-item">
                <label>Project:</label>{{jobModel.ProjectName}}
            </div>
            <div class="job-details-item">
                <label>Template:</label>{{jobModel.ProjectType}}
            </div>
            <div class="job-details-item">
                <label>Job Status:</label>
                <span [style.color]="jobModel.StatusColor"> {{jobModel.StatusName}}</span>
                <ion-button expand="block" (click)="statusHistory()" *ngIf="!fromMap" color="medium" size="small">
                    <i class="fal fa-comment-lines ion-margin-end"></i>
                    Status History
                </ion-button>
            </div>
            <div class="job-details-item" *ngIf="jobHistoryItems.length > 0">
                <ion-button expand="block" (click)="locationJobHistory()" color="medium" size="small">
                    <i class="fas fa-history ion-margin-end"></i>
                    Location Job History
                </ion-button>
            </div>
            <div *ngIf="jobModel.StatusNotes" class="job-details-item">
                <label>Status Notes:</label> {{jobModel.StatusNotes}}
            </div>

            <div *ngIf="jobModel.SiteNotes" class="job-details-item">
                <label>Notes/Scope Of Work:</label>{{jobModel.SiteNotes}}
            </div>
            <div *ngIf="jobModel.BatteryQty" class="job-details-item">
                <label>Battery Qty:</label>{{jobModel.BatteryQty}}
            </div>
            <div *ngIf="jobModel.EquipmentName" class="job-details-item">
                <label>Equipment Name:</label>{{jobModel.EquipmentName}}
            </div>
            <div *ngIf="jobModel.Pacenumber" class="job-details-item">
                <label>Job Number:</label> {{jobModel.Pacenumber}}
            </div>
            <div *ngIf="jobModel.TechNotes" class="job-details-item">
                <label>Tech/Project Comments:</label>{{jobModel.TechNotes}}
            </div>
            <div *ngIf="jobModel.AccessNotes" class="job-details-item">
                <label>Access Notes:</label>{{jobModel.AccessNotes}}
            </div>
            <div *ngIf="jobModel.Directions" class="job-details-item">
                <label>Directions:</label>{{jobModel.Directions}}
            </div>
            <div *ngIf="jobModel.CustomerAccessNotes && jobModel.CustomerType != 'D'" class="job-details-item">
                <label>Customer Access Notes:</label>{{jobModel.CustomerAccessNotes}}
            </div>
            <div *ngIf="jobModel.CustomerAccessNotes && jobModel.CustomerType == 'D'" class="job-details-item">
                <label>Notes:</label>{{jobModel.CustomerAccessNotes}}
            </div>
            <div *ngIf="jobModel.CustomerDirections" class="job-details-item">
                <label>Customer Directions:</label>{{jobModel.CustomerDirections}}
            </div>
            <div *ngIf="jobModel.LeadTechnicianName && !jobModel.LeadTechnician" class="job-details-item">
                <label>Lead Technician Name:</label>{{jobModel.LeadTechnicianName}}
            </div>
            <div *ngIf="jobModel.LeadTechnicianPhone && !jobModel.LeadTechnician" class="job-details-item">
                <label class="block">Lead Technician Phone:</label>{{jobModel.LeadTechnicianPhone}}
                <a [href]="getSMSHref(jobModel.LeadTechnicianPhone)" class="sms">
                    <i class="far fa-comments"></i>
                </a>
                <a [href]="getPhoneHref(jobModel.LeadTechnicianPhone)" class="phone">
                    <i class="fas fa-phone"></i>
                </a>
            </div>
            <div *ngIf="jobModel.TechnicianName" class="job-details-item">
                <label>Technician Name:</label>{{jobModel.TechnicianName}}
            </div>
            <div *ngIf="jobModel.TechnicianPhone" class="job-details-item">
                <label class="block">Technician Phone:</label>{{jobModel.TechnicianPhone}}
                <a [href]="getSMSHref(jobModel.TechnicianPhone)" class="sms">
                    <i class="far fa-comments"></i>
                </a>
                <a [href]="getPhoneHref(jobModel.TechnicianPhone)" class="phone">
                    <i class="fas fa-phone"></i>
                </a>
            </div>
            <div class="job-details-item" *ngIf="jobModel.Assets && jobModel.Assets.length > 0">
                <label class="block">Assets ({{(jobModel.Assets || []).length}})</label>
                <ul style="margin: 0.2rem; padding-left: 2.5rem;">
                    <li *ngFor="let asset of jobModel.Assets">
                        {{asset.Name}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="dismissModal()" color="light" class="color-white">
                Close
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>