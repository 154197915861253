import { Injectable } from '@angular/core';

/**
 * Uses window.config to grab the config.
 * Provides the config values to the services.
 */
@Injectable()
export class ConfigService {
  private config: any;

  // AOT Compiler errors if 'Window' is injected here.
  // Will probably be fixed in the angular future.
  constructor() {
    if (window['appConfig']) {
      this.config = window['appConfig'];
      console.log('Configuration loaded ', this.config);
    } else {
      console.warn('No configuration found in window object');
    }
  }

  getWindow(): any {
    return window;
  }

  get baseUrl() {
    return this.config.baseUrl;
  }

  get isWebApp() {
    return this.config.isWebApp;
  }
}
