import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as _ from "lodash";
import { BatteryTestAnswer, ParsedBatteryTestModel } from "../../../battery-test.models";

@Component({
  selector: "battery-test-data",
  templateUrl: "battery-test-data.component.html"
})
export class BatteryTestDataComponent {
  @Input()
  data: ParsedBatteryTestModel;
  @Output() onTestTypeChangeOutput = new EventEmitter<number>();


  constructor(
  ) {
  }

  async ngOnInit() {
    setTimeout(() => {
      window["syncscroll"].reset();
    }, 1000);
    let index = 0;
    for (let row of this.data.Data) {
      if (index == 0) {
        for (let column of row) {
          this.checkTestType(column);
        }
      } else {
        break;
      }
      index++;
    }
  }

  onDataTypeChange(answer: BatteryTestAnswer) {
    if(<any>answer.BatteryTestDataTypeId == 'null') {
      answer.BatteryTestDataTypeId = null;
    }
    for (let row of this.data.Data) {
      for (let column of row) {
        if (column.ColumnIndex == answer.ColumnIndex) {
          column.BatteryTestDataTypeId = answer.BatteryTestDataTypeId;
        }
      }
    }
    this.checkTestType(answer);
  }

  checkTestType(answer: BatteryTestAnswer) {
    //2 is coming from BatteryTestDataType table, and 3 is in BatteryTestTypes table
    if (answer.BatteryTestDataTypeId == 2) {
      this.onTestTypeChangeOutput.emit(3);
    } else if (answer.BatteryTestDataTypeId == 3) {
      this.onTestTypeChangeOutput.emit(1);
    } else if (answer.BatteryTestDataTypeId == 4) {
      this.onTestTypeChangeOutput.emit(2);
    }
  }
}