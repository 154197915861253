import { Injectable } from "@angular/core";
import { RepositoryService } from "../data/db/repository.service";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import { Facility } from "../data/entities/Facility";
import { FacilityChecklist } from "../data/entities/FacilityChecklist";
import { DateTimeHelperService } from "../shared/services/datetime/datetime-helper.service";
import { SyncQueueService } from "../sync/sync-queue.service";
import { ChecklistSectionModel, ChecklistQuestionModel, ChecklistSummaryModel, ChecklistQuestionImageAnnotationModel } from "./checklist.models";
import * as _ from "lodash";
import * as moment from "moment";
import { ApiService } from "../shared/services/api/api.service";

@Injectable()
export class ChecklistService {
  constructor(
    private repositoryService: RepositoryService,
    public errorHandlerService: ErrorHandlerService,
    private dateTimeHelperService: DateTimeHelperService,
    private syncQueueService: SyncQueueService,
    private apiService: ApiService
  ) { }
  async getChecklistById(id: number): Promise<FacilityChecklist> {
    let checklist = await this.repositoryService.getFacilityChecklistRepository().findOneBy({ Id: id });
    if (checklist) {
      checklist.SectionsModel = JSON.parse(checklist.Sections) || [];
      checklist.JobDataModel = JSON.parse(checklist.JobData);;
    }
    return checklist;
  }

  async saveChecklist(checklist: FacilityChecklist) {
    await this.repositoryService.getFacilityChecklistRepository().save(checklist, { transaction: false });
  }

  async getFacilityByChecklistId(id: number): Promise<Facility> {
    let checklist = await this.repositoryService.getFacilityChecklistRepository().findOneBy({ Id: id });
    let facility = await this.repositoryService.getFacilityRepository().findOneBy({ Id: checklist.SiteKey });
    return facility;
  }

  // async getFacilityChecklistsForSync(): Promise<FacilityChecklist[]> {
  //   let facilities = await this.repositoryService.getFacilityChecklistRepository().find({
  //     where: { DateSynchronized: null }
  //   });
  //   for (let facility of facilities) {
  //     facility.SectionsModel = JSON.parse(facility.Sections)
  //   }
  //   return facilities;
  // }


  async updateFacilityChecklistNoSyncNeeded(facilityChecklist: FacilityChecklist) {
    try {
      facilityChecklist.Sections = JSON.stringify(facilityChecklist.SectionsModel);
      await this.repositoryService.getFacilityChecklistRepository().save(facilityChecklist, { transaction: false });
    } catch (err) {
      this.errorHandlerService.logError(err);
      alert("An error occurred!");
    }
  }

  async updateFacilityChecklist(facilityChecklist: FacilityChecklist): Promise<void> {
    try {
      facilityChecklist.TimeStampUpdated = this.dateTimeHelperService.getUtcTimeStamp();
      await this.updateFacilityChecklistNoSyncNeeded(facilityChecklist);
      await this.syncQueueService.addToQueueOncePerEntity(String(facilityChecklist.Id), 'checklist', facilityChecklist);
    } catch (err) {
      this.errorHandlerService.logError(err);
      alert("An error occurred!");
    }
  }

  getFacilityChecklistSummary(facilityChecklist: FacilityChecklist): ChecklistSummaryModel {
    if (facilityChecklist == null) {
      return null;
    }
    let summary: ChecklistSummaryModel = {
      totalCount: 0,
      totalAnsweredCount: 0,
      requiredAnsweredCount: 0,
      requiredCount: 0,
      lastUpdatedDate: null,
      imageCount: 0
    };
    let date = null;
    for (let section of facilityChecklist.SectionsModel) {
      this.setSummary(section);
      summary.totalCount += section.Summary.totalCount;
      summary.totalAnsweredCount += section.Summary.totalAnsweredCount;
      summary.requiredAnsweredCount += section.Summary.requiredAnsweredCount;
      summary.requiredCount += section.Summary.requiredCount;
      summary.imageCount += section.Summary.imageCount;
      if (section.Summary.lastUpdatedDate) {
        if (date == null) {
          date = section.Summary.lastUpdatedDate;
        } else {
          date = moment.max(moment(section.Summary.lastUpdatedDate), moment(date)).format("YYYY-MM-DD HH:mm:ss");
        }
      }
    }
    summary.lastUpdatedDate = date;
    return summary;
  }

  setSummary(section: ChecklistSectionModel) {
    section.Summary = {
      requiredAnsweredCount: 0,
      requiredCount: 0,
      totalAnsweredCount: 0,
      totalCount: 0,
      lastUpdatedDate: null,
      imageCount: 0
    }
    for (let question of section.Questions) {
      if (!question.Hidden && question.FieldDataType != 'label' && !question.Readonly) {
        section.Summary.imageCount += (question.ImageCount || 0);
        if (question.LastUpdatedDate || question.ImageUpdatedDate) {
          let datesToCompare = [];
          if (section.Summary.lastUpdatedDate) {
            datesToCompare.push(moment(section.Summary.lastUpdatedDate));
          }
          if (question.ImageUpdatedDate) {
            datesToCompare.push(moment(question.ImageUpdatedDate));
          }
          if (question.LastUpdatedDate) {
            datesToCompare.push(moment(question.LastUpdatedDate));
          }
          section.Summary.lastUpdatedDate = moment.max(datesToCompare).format("YYYY-MM-DD HH:mm:ss");
        }
        let isAnswered = this.isAnswered(question);
        section.Summary.totalCount++;
        if (isAnswered) {
          section.Summary.totalAnsweredCount++;
        }
        if (question.Required) {
          section.Summary.requiredCount++;
          if (isAnswered) {
            section.Summary.requiredAnsweredCount++;
          }
        }
      }
    }
  }

  getFirstQuestionWithDataType(fieldDataType: string, checklist: FacilityChecklist): ChecklistQuestionModel {
    for (let section of checklist.SectionsModel) {
      for (let question of section.Questions) {
        if (question.FieldDataType == fieldDataType) {
          return question;
        }
      }
    }
    return null;
  }

  public async uploadFiles(checklistId: number, question: ChecklistQuestionModel, files: File[]): Promise<any> {
    const formData = new FormData();
    if (files && files.length > 0) {
      formData.append("ChecklistId", checklistId.toString());
      formData.append("vendorViewPK", String(question.VendorViewPK));
      formData.append("checklistQuestionId", String(question.VendorQuestionPK));
      for (let file of files) {
        formData.append("files", file);
      }
      return this.apiService.post<any>("image/upload", formData);
    }
  }
  
  public async getImageAnnotationCounts(checklistId: number) {
    return this.apiService.get<ChecklistQuestionImageAnnotationModel[]>("image/getImageAnnotationCounts", { id: checklistId });
  }

  private isAnswered(question: ChecklistQuestionModel): boolean {
    if (!question) {
      return false;
    }
    if (question.FieldDataType == "photo" || question.FieldDataType == "video" || question.FieldDataType == "file") {
      return question.ImageCount > 0;
    }
    return String(question.AuditData || "").length > 0
  }
}
