<ion-list>
    <ion-item *ngIf="!isWeb && !preview" (click)="takePhoto()">
        <ion-label>Take Photo</ion-label>
        <ion-icon name="camera-outline" slot="end">
        </ion-icon>
    </ion-item>
    <ion-item (click)="openGallery()">
        <ion-label>Open Gallery</ion-label>
        <ion-icon name="image-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="editAsset()" *ngIf="!preview">
        <ion-label>
            Edit
        </ion-label>
        <ion-icon name="create-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="replaceAsset()" *ngIf="!preview && asset.Replaceable && !isVendor">
        <ion-label>
            Replace Asset
        </ion-label>
        <ion-icon name="repeat-outline" slot="end"></ion-icon>
    </ion-item>
    <!-- <ion-item (click)="replaceBatteries()"
        *ngIf="!preview && asset.HasBatteries && asset.BatteryDetailsEnabled && asset.Batteries && asset.Batteries.length > 0">
        <ion-label>
            Replace {{asset.Batteries.length}} Batteries
        </ion-label>
        <ion-icon name="repeat-outline" slot="end"></ion-icon>
    </ion-item> -->
    <ion-item (click)="editAsset()" *ngIf="preview">
        <ion-label>
            View
        </ion-label>
        <ion-icon name="eye-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="getQuote()" *ngIf="facility.QuotesEnabled && asset.Quotable && !preview">
        <ion-label>
            Request Quote
        </ion-label>
        <ion-icon name="send-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="scheduleWork()" *ngIf="facility.SchedulingEnabled && asset.Schedulable && !preview">
        <ion-label>
            Schedule Work
        </ion-label>
        <ion-icon name="hammer-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="workHistory()" *ngIf="facility.SchedulingEnabled && asset.Schedulable && !preview">
        <ion-label>
            Work History
        </ion-label>
        <ion-icon name="list-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="goToBatteries()" *ngIf="asset.HasBatteries && asset.BatteryDetailsEnabled">
        <ion-label>
            Batteries (Scan SNs...)
        </ion-label>
        <ion-icon name="battery-charging" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="uploadBatteryTests()" *ngIf="asset.BatteryTestEnabled && checklistId > 0 && !preview">
        <ion-label>
            Upload Battery Tests
        </ion-label>
        <ion-icon name="cloud-upload-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="deleteAsset()" lines="none" *ngIf="!preview">
        <ion-label>
            Delete
        </ion-label>
        <ion-icon name="trash-outline" slot="end"></ion-icon>
    </ion-item>
</ion-list>