<oms-header title="My Jobs"> </oms-header>
<ion-progress-bar type="indeterminate" class="my-jobs-progress-bar" color="primary" *ngIf="refreshing">
</ion-progress-bar>
<ion-searchbar class="my-work-search" [(ngModel)]="query" (ngModelChange)="startSearch()" debounce="500"
  *ngIf="workList.length > 1 && !loading" placeholder="Filter jobs" #searchBarElement [hidden]="!search">
</ion-searchbar>
<ion-icon class="my-work-search-button" name="search-outline" (click)="searchOn()" *ngIf="!search && !loading">
</ion-icon>
<ion-content class="ion-padding" *ngIf="!loading">
  <div class="ion-text-center" *ngIf="newJobCount > 0">
    <ion-chip (click)="refresh()" class="ion-margin-bottom">
      <ion-label>
        <i class="fas fa-wrench" style="color: green;"></i>
        {{newJobCount}} new job(s) available. Refresh to load.
      </ion-label>
    </ion-chip>
  </div>

  <div class="job-data-loading" *ngIf="refreshing">
    Job data is loading...</div>

  <ion-segment (ionChange)="segmentChanged($event)" mode="ios" value="all" *ngIf="hasStatusFilter()" [(ngModel)]="statusFilter">
    <ion-segment-button value="all">
      <ion-label>All</ion-label>
    </ion-segment-button>
    <ion-segment-button value="3" *ngIf="hasScheduled()">
      <ion-label>Scheduled</ion-label>
    </ion-segment-button>
    <ion-segment-button value="started" *ngIf="hasStarted()">
      <ion-label>Started</ion-label>
    </ion-segment-button>
    <ion-segment-button value="9" *ngIf="hasCorrection()">
      <ion-label>Corrections</ion-label>
    </ion-segment-button>
  </ion-segment>
  <my-work-list [workList]="workList" (workListUpdated)="onWorkListUpdated($event)" *ngIf="mode == 'list'">
  </my-work-list>
  <my-work-map [workList]="workList" (workListUpdated)="onWorkListUpdated($event)" *ngIf="mode == 'map'"></my-work-map>
</ion-content>
<ion-tab-bar slot="bottom" *ngIf="!loading" class="checklist-questions-tabs">
  <ion-tab-button (click)="switchMode('list')" [class.tab-selected]="mode == 'list'">
    <i class="far fa-th-list"></i>
    <ion-label>List</ion-label>
  </ion-tab-button>
  <ion-tab-button (click)="switchMode('map')" [class.tab-selected]="mode == 'map'">
    <i class="fal fa-map-marker-alt"></i>
    <ion-label>Map</ion-label>
  </ion-tab-button>
  <ion-tab-button (click)="refresh()">
    <i class="far fa-sync-alt" [class.animate-rotate]="refreshing"></i>
    <ion-label>Refresh</ion-label>
  </ion-tab-button>
</ion-tab-bar>
<ion-content class="ion-padding" *ngIf="loading">
  <ion-item *ngFor="let i of [1,2,3,4,5]">
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-content>