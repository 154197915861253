<ion-list>
    <ion-item (click)="edit()">
        <ion-label>
            Edit
        </ion-label>
        <ion-icon name="create-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="download()">
        <ion-label>
            Download original file
        </ion-label>
        <ion-icon name="cloud-download-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="preview()" *ngIf="test.PreviewUrl">
        <ion-label>
            Preview report
        </ion-label>
        <ion-icon name="eye-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="delete()" lines="none">
        <ion-label>
            Delete
        </ion-label>
        <ion-icon name="trash-outline" slot="end"></ion-icon>
    </ion-item>
</ion-list>