<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Manage Time Off {{forUser}}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding time-off" *ngIf="!loading">
    <ng-container *ngIf="!selectUser">
        <ion-title class="ion-text-center">Select day(s) off</ion-title>
        <ion-datetime presentation="date" [value]="inputDays.length > 0 ? inputDays : ''"
            (ionChange)="onDateSelectionChange($event)" [highlightedDates]="[minDate]" [multiple]="true"
            [min]="minDate">
        </ion-datetime>
        <ion-title class="ion-text-center" *ngIf="availableDays.length > 0">{{getNumberOfDays()}} day(s) off
            selected:</ion-title>
        <ion-list *ngIf="availableDays.length > 0">
            <ng-container *ngFor="let day of availableDays">
                <ion-item>
                    <ion-label>
                        <i>
                            {{formatDate(day.Day)}}
                        </i>
                    </ion-label>
                    <ion-input [(ngModel)]="day.Description" placeholder="Additional info">
                    </ion-input>
                    <ion-button slot="end" color="danger" (click)="removeDay(day)">
                        <ion-icon name="trash"></ion-icon>
                    </ion-button>
                </ion-item>
            </ng-container>
        </ion-list>
    </ng-container>
    <ng-container *ngIf="selectUser">
        <h2>Select user you want to add time off for:</h2>
        <ion-list>
            <ion-list-header>
                <ion-label><b>Me</b></ion-label>
            </ion-list-header>
            <ng-container *ngFor="let user of users">
                <ion-item *ngIf="user.Value == myUserId" (click)="onSelectUser(user)">
                    <ion-label>
                        {{user.Text}}
                    </ion-label>
                </ion-item>
            </ng-container>
            <ion-list-header>
                <ion-label><b>Others</b></ion-label>
            </ion-list-header>
            <ng-container *ngFor="let user of users">
                <ion-item *ngIf="user.Value != myUserId" (click)="onSelectUser(user)">
                    <ion-label>{{user.Text}}</ion-label>
                </ion-item>
            </ng-container>
        </ion-list>
    </ng-container>
</ion-content>

<ion-content *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="save()" color="primary" checked [disabled]="loading" *ngIf="!selectUser">
                <ion-label *ngIf="!loading">Save</ion-label>
                <ion-label *ngIf="loading">Saving...</ion-label>
            </ion-segment-button>
            <ion-segment-button (click)="dismissModal()" color="light">
                <ion-label>Cancel</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>