<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Create Job
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" *ngIf="!loading">
    <ion-list>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start">
                Job Schedule Date
            </ion-note>
            <ion-input value="{{ model.Date | date:'MM/dd/yyyy' }}" readonly="readonly"
                id="create-job-calendar-id"></ion-input>
            <ion-modal trigger="create-job-calendar-id" size="auto" class="date-picker-add-job">
                <ng-template>
                    <ion-content>
                        <ion-datetime #ionDateTime presentation="date" [(ngModel)]="model.Date" format="YYYY-MM-DD"
                            size="fixed" showDefaultButtons="true" locale="en-US">
                            <span slot="title">Job Schedule Date</span>
                        </ion-datetime>
                    </ion-content>
                </ng-template>
            </ion-modal>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start">
                Due Date
            </ion-note>
            <ion-input value="{{ model.DueDate | date:'MM/dd/yyyy' }}" readonly="readonly"
                id="create-job-calendar-due-date-id" placeholder="Set Due Date"></ion-input>
            <ion-modal trigger="create-job-calendar-due-date-id" size="auto" class="date-picker-add-job">
                <ng-template>
                    <ion-content>
                        <ion-datetime #ionDueDateTime presentation="date" [(ngModel)]="model.DueDate"
                            format="YYYY-MM-DD" size="fixed" showDefaultButtons="true" locale="en-US">
                            <span slot="title">Due Date</span>
                        </ion-datetime>
                    </ion-content>
                </ng-template>
            </ion-modal>
        </ion-item>
        <new-customer-project-location [(model)]="model.CustomerLocationProject" #customerLocationProject (locationChange)="onLocationChanged($event)">
            <div slot="afterLocation" *ngIf="customerLocationProject && customerLocationProject.customerSelected()">
                <ion-item class="custom-input-ion-item" *ngIf="model.CustomerLocationProject.CustomerId > 0 && model.CustomerLocationProject.LocationInfo.AssetsEnabled" (click)="selectAssets()">
                    <ion-note position="fixed" slot="start">
                        Assets
                    </ion-note>
                    <ion-button *ngIf="model.Assets && model.Assets.length == 0">Select Assets</ion-button>
                    <ion-spinner *ngIf="loadingAssets"></ion-spinner>
                    <ion-label *ngIf="model.Assets && model.Assets.length > 0" style="white-space: nowrap !important;">
                        {{assetsCommaSeparated()}}
                        <!-- <div>
                            <h2>Job Assets</h2>
                            <ul>
                                <li *ngFor="let asset of model.Assets; let i = index">{{asset.AssetName}} <ion-button size="small"
                                        color="danger" (click)="deleteAsset(asset, i)"><ion-icon name="trash"></ion-icon></ion-button></li>
                            </ul>
                        </div> -->
                    </ion-label>
                </ion-item>
                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="focusSoNumber()">
                        Sales Order Number
                    </ion-note>
                    <ion-input [(ngModel)]="model.SONumber" #soNumberInput placeholder="Scan or type in..."
                        next-on-enter="wonumber" id="sonumber">
                    </ion-input>
                    <div slot="end">
                        <span tabindex="1" (click)="scanSoNumber($event)" class="battery-item-photo"
                            style="z-index: 5;">
                            <ion-icon name="barcode-outline"></ion-icon>
                        </span>
                    </div>
                </ion-item>
                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="focusWorkOrderNumber()">
                        Work Order Number
                    </ion-note>
                    <ion-input [(ngModel)]="model.WorkOrderNumber" maxlength="150" #workOrderNumberInput
                        placeholder="Scan or type in..." next-on-enter="ponumber" id="wonumber">
                    </ion-input>
                    <div slot="end">
                        <span tabindex="1" (click)="scanWorkOrderNumber($event)" class="battery-item-photo"
                            style="z-index: 5;">
                            <ion-icon name="barcode-outline"></ion-icon>
                        </span>
                    </div>
                </ion-item>
                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="focusPoNumber()">
                        Customer PO
                    </ion-note>
                    <ion-input type="text" [(ngModel)]="model.PONumber" #poNumberInput placeholder="Scan or type in..."
                        next-on-enter="systempartnumber" id="ponumber">
                    </ion-input>
                    <div slot="end">
                        <span tabindex="1" (click)="scanPoNumber($event)" class="battery-item-photo"
                            style="z-index: 5;">
                            <ion-icon name="barcode-outline"></ion-icon>
                        </span>
                    </div>
                </ion-item>

                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="focusReferenceNumber()">
                        Reference #
                    </ion-note>
                    <ion-input [(ngModel)]="model.ReferenceNumber" maxlength="150" #referenceNumberInput
                        placeholder="Scan or type in..." id="referencenumber">
                    </ion-input>
                    <div slot="end">
                        <span tabindex="1" (click)="scanReferenceNumber($event)" class="battery-item-photo"
                            style="z-index: 5;">
                            <ion-icon name="barcode-outline"></ion-icon>
                        </span>
                    </div>
                </ion-item>

                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="focusEquipmentName()">
                        Equipment Name
                    </ion-note>
                    <ion-input [(ngModel)]="model.EquipmentName" maxlength="150" #equipmentNameInput
                        placeholder="Scan or type in..." id="equipmentName">
                    </ion-input>
                    <div slot="end">
                        <span tabindex="1" (click)="scanEquipmentName($event)" class="battery-item-photo"
                            style="z-index: 5;">
                            <ion-icon name="barcode-outline"></ion-icon>
                        </span>
                    </div>
                </ion-item>

                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="focusBatteryQty()">
                        Battery Qty
                    </ion-note>
                    <ion-input [(ngModel)]="model.BatteryQty" maxlength="150" #batteryQtyNumberInput
                        placeholder="Type in..." id="batteryqty">
                    </ion-input>
                </ion-item>

                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="focusNotes()">
                        Notes
                    </ion-note>
                    <ion-textarea [(ngModel)]="model.Notes" #notesInput placeholder="Type in notes..." id="notes">
                    </ion-textarea>
                    <div slot="end">
                        <span tabindex="1" (click)="scanReferenceNumber($event)" class="battery-item-photo"
                            style="z-index: 5;">
                            <ion-icon name="barcode-outline"></ion-icon>
                        </span>
                    </div>
                </ion-item>
            </div>
        </new-customer-project-location>
        <ng-container *ngIf="customerLocationProject && customerLocationProject.customerSelected()">
            <ion-item *ngIf="!isTech && vendors.length > 0" class="custom-input-ion-item">
                <ion-note position="fixed" slot="start" (click)="focusServiceProvider()">
                    Service Provider
                </ion-note>
                <ion-select [(ngModel)]="model.VendorId" okText="Okay" cancelText="Dismiss" placeholder="Please Choose"
                    (ionChange)="onVendorChange($event.detail.value)" #serviceProviderSelectList>
                    <ion-label>Service Provider</ion-label>
                    <ion-select-option [value]="item.Value" *ngFor="let item of vendors">
                        {{ item.Text }}
                    </ion-select-option>
                    <ion-select-option value="-1">Can't find Service Provider in the list, I want to add a new Service
                        Provider</ion-select-option>
                </ion-select>
            </ion-item>
            <ng-container *ngIf="model.VendorId == -1 || vendors.length == 0">
                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="serviceProviderName.focus()">
                        New Service Provider Name
                    </ion-note>
                    <ion-input type="text" [(ngModel)]="model.ServiceProviderName" #serviceProviderName
                        placeholder="Type in...">
                    </ion-input>
                </ion-item>
                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="serviceProviderUserFirstName.focus()">
                        New Service Provider User First Name
                    </ion-note>
                    <ion-input type="email" [(ngModel)]="model.ServiceProviderUserFirstName"
                        #serviceProviderUserFirstName placeholder="Type in user first name...">
                    </ion-input>
                </ion-item>
                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="serviceProviderUserLastName.focus()">
                        New Service Provider User Last Name
                    </ion-note>
                    <ion-input type="email" [(ngModel)]="model.ServiceProviderUserLastName" #serviceProviderUserLastName
                        placeholder="Type in user last name...">
                    </ion-input>
                </ion-item>
                <ion-item class="custom-input-ion-item">
                    <ion-note position="fixed" slot="start" (click)="serviceProviderUserEmail.focus()">
                        New Service Provider User Email
                    </ion-note>
                    <ion-input type="email" [(ngModel)]="model.ServiceProviderUserEmail" #serviceProviderUserEmail
                        placeholder="Type in email...">
                    </ion-input>
                </ion-item>
            </ng-container>
            <ion-item lines="none" *ngIf="selectedVendorData && !selectedVendorData.CanSchedule && model.VendorId > 0">
                <ion-note>You can't schedule directly to the selected Vendor. This will be awarded to them. If you want
                    to
                    be able to directly schedule they need to allow that by selecting 'Allow Direct Schedule' on their
                    member profile.</ion-note>
            </ion-item>
            <ion-item *ngIf="selectedVendorData && !isTech" class="custom-input-ion-item">
                <ion-note position="fixed" slot="start" (click)="focusLeadTechnician()">
                    Lead Technician
                </ion-note>
                <ion-select [(ngModel)]="model.TechnicianId" okText="Okay" cancelText="Dismiss" #leadTechSelectList
                    placeholder="Please Choose">
                    <ion-select-option [value]="item.Value != null ? toNumeric(item.Value) : item.Value"
                        *ngFor="let item of selectedVendorData.TechnicianList">
                        {{ item.Text }}
                    </ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item *ngIf="selectedVendorData && selectedVendorData.TechnicianList.length > 0 !isTech"
                class="custom-input-ion-item">
                <ion-note position="fixed" slot="start" (click)="focusAdditionalTechnicians()">
                    Additional Technicians
                </ion-note>
                <ion-select [(ngModel)]="model.AdditionalTechnicianIds" okText="Okay" cancelText="Dismiss"
                    placeholder="Please Choose" multiple="true" #additionalTechSelectList>
                    <ng-container *ngFor="let item of selectedVendorData.TechnicianList">
                        <ion-select-option [value]="item.Value != null ? toNumeric(item.Value) : item.Value"
                            *ngIf="item.Value != model.TechnicianId">
                            {{ item.Text }}
                        </ion-select-option>
                    </ng-container>
                </ion-select>
            </ion-item>
            <ion-item *ngIf="selectedVendorData && selectedVendorData.IsMyMember && !isTech"
                class="custom-input-ion-item">
                <ion-note position="fixed" slot="start" (click)="focusAlsoNotify()">
                    Also Notify
                </ion-note>
                <ion-select [(ngModel)]="model.AlsoNotifyIds" okText="Okay" cancelText="Dismiss"
                    placeholder="Please Choose" multiple="true" #alsoNotifySelectList>
                    <ion-select-option [value]="item.Value != null ? toNumeric(item.Value) : item.Value"
                        *ngFor="let item of selectedVendorData.TechnicianList">
                        {{ item.Text }}
                    </ion-select-option>
                </ion-select>
            </ion-item>
            <div class="ion-margin-top">
                <ion-segment mode="ios" [(ngModel)]="model.ReleaseJob">
                    <ion-segment-button [value]="true">
                        <ion-label>
                            <div>
                                Release to Technician
                            </div>
                            <ion-note>Jobs will immediatelly appear on the Technician job list</ion-note>
                        </ion-label>
                    </ion-segment-button>
                    <ion-segment-button [value]="false">
                        <ion-label>
                            <div>
                                Hold
                            </div>
                            <ion-note>
                                Job will appear as scheduled and Not Released
                            </ion-note>
                        </ion-label>
                    </ion-segment-button>
                </ion-segment>
            </div>
        </ng-container>
    </ion-list>
</ion-content>
<ion-content class="ion-padding" *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="createJob()" color="primary" checked *ngIf="!submitting">
                <ion-label>
                    Create Job
                </ion-label>
            </ion-segment-button>
            <ion-segment-button *ngIf="submitting">
                <ion-spinner></ion-spinner>
            </ion-segment-button>
            <ion-segment-button (click)="dismissModal()" color="light" *ngIf="!submitting">
                <ion-label>Cancel</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>