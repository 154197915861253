import { Component, OnInit, OnDestroy } from "@angular/core";
import { AssetsService } from "../assets.service";
import { Facility } from "../../data/entities/Facility";
import { Router, ActivatedRoute } from "@angular/router";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { ChecklistService } from "src/app/checklist/checklist.service";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { ChecklistSummaryModel } from "src/app/checklist/checklist.models";
import { GeolocationService } from "src/app/shared/services/geolocation-service/geolocation.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "facility",
  templateUrl: "facility.component.html"
})
export class FacilityComponent implements OnInit, OnDestroy {
  facility: Facility;
  totalWarn: number = 0;
  totalFail: number = 0;
  totalPass: number = 0;
  private facilityChecklist: FacilityChecklist;
  siteWalkSummary: ChecklistSummaryModel;
  loading: boolean;
  assetPreview: boolean;

  constructor(
    private assetsService: AssetsService,
    private router: Router,
    private route: ActivatedRoute,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private checklistService: ChecklistService,
    private geolocationService: GeolocationService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.assetPreview = !(await this.userService.canMaintainAssets());
  }

  ngOnDestroy() {
  }

  async ionViewWillEnter(hardReload: boolean = false) {
    this.loading = true;
    // Init your component properties here.
    let facilityId = this.route.snapshot.params["id"];
    this.facility = await this.assetsService.getFacilityWithAssets(facilityId);
    this.facilityChecklist = await this.checklistService.getChecklistById(this.facility.SiteWalkChecklistId);
    this.siteWalkSummary = this.checklistService.getFacilityChecklistSummary(this.facilityChecklist);
    this.totalPass = 0;
    this.totalWarn = 0;
    this.totalFail = 0;
    this.assetRecursive(this.facility.Assets, (asset: SiteAsset) => {
      let calc = this.assetsService.getSiteAssetCalcInfo(asset, this.facility);
      if (calc) {
        if (calc.IsWarn) {
          this.totalWarn += 1;
        }
        if (calc.IsFail) {
          this.totalFail += 1;
        }
        if (calc.IsPass) {
          this.totalPass += 1;
        }
      }
    });
    this.geolocationService.setCurrentLocation();
  }

  ionViewDidEnter() {
    this.loading = false;
  }

  private assetRecursive(siteAssets: SiteAsset[], callback: (item: SiteAsset) => void): void {
    for (let asset of siteAssets) {
      callback(asset);
      this.assetRecursive(asset.Assets, callback);
    }
  }
}
