import { Injectable } from "@angular/core";
import {
  Repository,
  ObjectLiteral,
  DataSource
} from "typeorm";
import { BrowserHelperService } from "../../shared/services/browser/browser-helper.service";
import { User } from "../entities/User";
import { MemberConfiguration } from "../entities/MemberConfiguration";
import { Facility } from "../entities/Facility";
import { FacilityChecklist } from "../entities/FacilityChecklist";
import { FacilityAssets } from "../entities/FacilityAssets";
import { SyncQueue } from "../entities/SyncQueue";
import { FileSync } from "../entities/FileSync";

@Injectable({
  providedIn: 'root',
})
export class RepositoryService {
  private readonly dbName: string = "MPIAssets";
  private static dataSource: DataSource = null;

  constructor(private browserHelperService: BrowserHelperService) { }

  async initDb() {
    if (!this.browserHelperService.isNativeApp()) {
      window["SQL"] = await window["initSqlJs"]({ locateFile: filename => `/assets/sqljs/sql-wasm.wasm` });
    }
    await this.initDataSource();
    //this.cleanIfNeeded();
  }

  public getMemberConfigurationRepository(): Repository<MemberConfiguration> {
    return this.getRepository<MemberConfiguration>("memberconfiguration");
  }

  public getFacilityRepository(): Repository<Facility> {
    return this.getRepository<Facility>("facility");
  }

  public getFacilityChecklistRepository(): Repository<FacilityChecklist> {
    return this.getRepository<FacilityChecklist>("facilitychecklist");
  }

  public getFileSyncRepository(): Repository<FileSync> {
    return this.getRepository<FileSync>("filesync");
  }

  public getFacilityAssetsRepository(): Repository<FacilityAssets> {
    return this.getRepository<FacilityAssets>("facilityassets");
  }

  public getSyncQueueRepository(): Repository<SyncQueue> {
    return this.getRepository<SyncQueue>("syncqueue");
  }

  private getRepository<T extends ObjectLiteral>(name: string): Repository<T> {
    return RepositoryService.dataSource.getRepository(name) as Repository<T>;
  }

  private async initDataSource(): Promise<void> {
    let dataSource: DataSource = null;
    if (this.browserHelperService.isNativeApp()) {
      // Running on device or emulator
      console.log("Creating connection...")
      dataSource = new DataSource({
        type: "cordova",
        database: this.dbName,
        migrationsTransactionMode: "none",
        location: "default",
        logging: ["error"/*, "query", "schema"*/],
        synchronize: true,
        entities: this.getEntities()
      });
      await dataSource.initialize();
      console.log("running on cordova");
    } else {
      //await (<any>window).openDatabase("OMS", "0.1", "MPI Assets", 200000);
      // Running app in browser
      dataSource = new DataSource({
        type: "sqljs",
        //autoSave: true,
        //location: "123",//this.dbName,
        logging: ["error"/*, "query", "schema"*/],
        synchronize: true,
        entities: this.getEntities()
      });
      await dataSource.initialize();
      console.log("running on websql");
      //connection.sqljsManager.loadDatabase("OMS")
    }
    console.log("Data source " + dataSource);
    RepositoryService.dataSource = dataSource;
  }

  private getEntities(): any[] {
    return [MemberConfiguration, Facility, FacilityChecklist, FacilityAssets, SyncQueue, FileSync];
  }

  async cleanDb() {
    const entities = await this.getEntitiesFromConnection(
      RepositoryService.dataSource
    );
    await this.cleanEntities(entities);
  }

  private async getDataSource() {
    if(RepositoryService.dataSource) return RepositoryService.dataSource;
    await this.initDataSource();
    return RepositoryService.dataSource;
  }

  private async getEntitiesFromConnection(dataSource: DataSource) {
    const entities = [];
    dataSource.entityMetadatas.forEach(x =>
      entities.push({ name: x.name, tableName: x.tableName })
    );
    return entities;
  }

  private async cleanEntities(entities) {
    try {
      for (const entity of entities) {
        const repository = await RepositoryService.dataSource.getRepository(entity.name);
        await repository.query(`DELETE FROM ${entity.tableName};`);
        await repository.query(
          `DELETE FROM SQLITE_SEQUENCE WHERE name = '${entity.tableName}';`
        );
      }
    } catch (error) {
      throw new Error(`ERROR: Cleaning db: ${error}`);
    }
  }
}
