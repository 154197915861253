import { Facility } from "../data/entities/Facility";
import { SiteAsset, SiteAssetBattery } from "../data/entities/SiteAsset";
import { BaseResponse } from "../shared/models/base-response-model";

export class RegisterAssetModel {
  Assets: SiteAsset[];
}

export class AssetConfigurationResponse {
  ConfigurationGroups: SiteAssetDataConfigurationGroup[];
  QuoteEmails: AssetQuoteContact[]
}

export class SiteAssetDataConfigurationGroup {
  MemberId: number;
  AssetConfigurations: SiteAssetDataConfiguration[];
  LookupData: MemberLookup
}

export class AssetFacilitiesResponse extends BaseResponse {
  Facilities: Facility[];
  HighlightChecklistId: number;
  Configuration: AssetConfigurationResponse
  DownloadedAccess: UserFacilityAccess[];
}

export class UserFacilityAccess {
  SiteKey: string;
  HasAccess: boolean;
}


export class AddWorkResponse extends BaseResponse {
  Facility: Facility;
  HighlightChecklistId: number;
  ConfigurationData: AssetConfigurationResponse
}

export class AssetFacilityResponse extends Facility {
}

export class AssetsResponse {
  Assets: SiteAsset[];
}

export class MemberLookup {
  BatteryTypes: LookupListGroup[];
  BatteryConditions: LookupListItem[];
  UPSModels: LookupListGroup[];
  CabinetModels: LookupListGroup[];
  CustomLists: { [id: number]: LookupListItem[] };
  UseHeatMap: boolean;
}

export class LookupListGroup {
  Text: string;
  Items: LookupListItem[];
}

export class LookupListItem {
  Value: number;
  Text: string;
  Color?: string;
}

export class SelectListItem {
  Value: string;
  Text: string;
  Color?: string;
}

export class SiteAssetDataConfiguration {
  Id: number;
  DisplayOrder: number;
  MemberId: number;
  ParentId: number;
  //Doesn't exist in the model
  //Parent: SiteAssetDataConfiguration;
  SiteAssetTypeId: number;
  SiteAssetType: SiteAssetType;
  Items: SiteAssetDataItemConfiguration[];
  IsContainer: boolean;
  OneToMany: boolean;
}

export class SiteAssetDataItemConfiguration {
  Id: number;
  Active: boolean;
  DisplayOrder: number;
  FieldName: string;
  FieldDataType: string;
  SiteAssetDataConfigurationId: number;
  ListId: number;
  AssetDisplay: boolean;
  ParentAssetDisplay: boolean;
}

export class SiteAssetType {
  Id: number;
  Description: string;
  ResKey: string;
  DisplayOrder: number;
  HasBatteries: boolean;
  Quotable: boolean;
  Schedulable: boolean;
  Replaceable: boolean;
  WarnThreshold: number;
  FailThreshold: number;
  BatteryTestEnabled: boolean;
  Active: boolean;
}

export class AssetQuoteModel {
  Asset: SiteAsset;
  BatteryModel: string;
  Battery: SiteAssetBattery;
  Selected: boolean;
  Level: number;
  Quantity: number;
  OldQuantity: number;
  AssetPathString: string;
  SelectedImages: number[];
  BatteryTests: number;
  Hidden: boolean;
}

export class AssetQuoteHistory {
  Id: number;
  Key: string;
  Description: string;
  Notes: string;
  DateCreated: string;
  DateUpdated: string;
  CreatedBy: string;
  IncludeSiteWalk: boolean;
  Url: string;
  ToEmails: string;
  CcEmails: string;
  Hidden: boolean;
}

export class FacilityQuoteDbModel {
  Id: string;
  Key: string;
  FacilityId: string;
  Notes: string;
  Description: string;
  Warranty: boolean;
  IncludeSiteWalk: boolean;
  RequiresApproval: boolean;
  Items: AssetQuoteDbModel[];
  Documents: AssetQuoteDocument[];
  UserId: number;
  StatusId: number;
}

export class AssetQuoteDocument {
  Name: string;
  Url: string;
  FileGuid: string;
  FileSize: number;
}

export class AssetQuoteDbModel {
  Quantity: number;
  ItemId: string;
  ItemType: "asset" | "battery";
  Images: number[];
}

export class AssetQuoteContact {
  Id: number;
  MemberId: number;
  Name: string;
  Email: string;
  Selected: boolean;
}

export class ScheduleAssetModel {
  Asset: SiteAsset;
  Selected: boolean;
}

export class AssetWorkHistory {
  JobId: number;
  ProjectName: string;
  ServiceProvider: string;
  Status: string;
  StatusColor: string;
  StatusDate: string;
  HasCloseout: boolean
}

export class ScheduleModel {
  ScheduleNow: boolean;
  ScheduleDate: string;
  ProjectId: number;
  VendorId: number;
  ProjectManagerId: number;
  TechnicianId: number;
  AdditionalTechnicianIds: number[];
  AlsoNotifyIds: number[];
  AssetId: string;
  AdditionalAssetIds: string[];
}

export class AvailableScheduleData {
  Projects: LookupListGroup[];
  Vendors: AvailableScheduleVendorData[];
}

export class AvailableScheduleVendorData {
  Value: number;
  Text: string;
  ProjectManagerList: LookupListItem[];
  TechnicianList: LookupListItem[];
  AdditionalTechnicianList: LookupListItem[];
  AlsoNotifyList: LookupListItem[];
  CanSchedule: boolean;
  IsMyMember: boolean;
}

export class FacilitySearchModel {
  Id: number;
  SiteKey: string;
  SiteId: number;
  CustomerId: number;
  CustomerName: string;
  CustomerType: string;
  Name: string;
  Description: string;
  Address: string;
  FACode: string;
  AccessNotes: string;
  Directions: string;
  CustomerAccessNotes: string;
  CustomerDirections: string;
  Projects: LookupListItem[];
  Downloaded: boolean;
  Downloading: boolean;
}

export class FacilitySimpleSearchModel {
  Id: number;
  Name: string;
  Description: string;
  Address: string;
  SiteKey: string;
}

export class SelectedAsset {
  Id: string;
  Name: string;
  ReplacedWithId: string;
  ReplacedDate: string;
  BatteryTests: number;
}