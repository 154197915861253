import { Component, Input, OnChanges, OnInit, SimpleChange, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { AssetQuoteModel, AssetQuoteContact, FacilityQuoteDbModel, AssetQuoteDocument } from "../../assets.models";
import { ChecklistSummaryModel } from "src/app/checklist/checklist.models";
import { ChecklistService } from "src/app/checklist/checklist.service";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { Facility } from "src/app/data/entities/Facility";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { ModalController, AlertController, ToastController } from "@ionic/angular";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ApiService } from "src/app/shared/services/api/api.service";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";
import { Router } from "@angular/router";
import { AssetQuoteService } from "../../services/quotes/asset-quote.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { AddQuoteEmailComponent } from "./add-quote-email/add-quote-email.component";
import { MultiFileUploadComponent } from "src/app/shared/components/upload/multi-file-upload.component";
import { BaseResponse } from "src/app/shared/models/base-response-model";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { Guid } from "guid-typescript";

@Component({
  selector: "asset-quote-submit",
  templateUrl: "asset-quote-submit.component.html"
})
export class AssetQuoteSubmitComponent implements OnInit, OnChanges {
  @Input()
  assets: AssetQuoteModel[];
  @Input()
  facility: Facility;
  @Input()
  mode: string;
  @Input()
  checklistId: number;
  @Input()
  submittable: boolean;
  @Input()
  existingFacilityQuote: FacilityQuoteDbModel;
  @Input()
  editQuoteWeb: boolean;

  assetsToSubmit: AssetQuoteModel[] = [];
  siteWalkSummary: ChecklistSummaryModel;
  facilityChecklist: FacilityChecklist;
  contacts: AssetQuoteContact[];
  includeSiteWalk: boolean = false;
  notes: string;
  description: string;
  loading: boolean = false;
  enableSiteWalk: boolean = true;
  warranty: boolean = false;
  @ViewChild(MultiFileUploadComponent) fileField: MultiFileUploadComponent;
  baseUrl: string;
  token: string;
  canAskApproval: boolean;
  requestApproval: boolean;

  constructor(
    private assetQuoteService: AssetQuoteService,
    private checklistService: ChecklistService,
    private toastController: ToastController,
    private alertController: AlertController,
    private router: Router,
    private loaderHelperService: LoaderHelperService,
    private errorHandlerService: ErrorHandlerService,
    private browserHelperService: BrowserHelperService,
    private modalController: ModalController,
    private pubSub: PubSubService,
    private apiService: ApiService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    if (this.existingFacilityQuote) {
      this.notes = this.existingFacilityQuote.Notes;
      this.description = this.existingFacilityQuote.Description;
      this.warranty = this.existingFacilityQuote.Warranty;
      this.includeSiteWalk = this.existingFacilityQuote.IncludeSiteWalk;
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    let property = "mode";
    if (changes[property] && changes[property].previousValue != changes[property].currentValue && changes[property].currentValue == 'submit') {
      console.log("Loading...");
      this.ionViewWillEnter();
    }
  }

  async ionViewWillEnter() {
    this.loading = true;
    for (let item of this.assets) {
      item.Quantity = item.Quantity || 0;
    }
    this.assetsToSubmit = _.filter(this.assets, a => a.Selected);
    this.facilityChecklist = await this.checklistService.getChecklistById(this.facility.SiteWalkChecklistId);
    this.siteWalkSummary = this.checklistService.getFacilityChecklistSummary(this.facilityChecklist);
    this.includeSiteWalk = (this.siteWalkSummary.lastUpdatedDate || "").length > 0 && this.facility.SiteWalkEnabled;
    this.enableSiteWalk = this.includeSiteWalk;
    await this.setContacts();
    this.baseUrl = await this.apiService.getBaseUrl();
    this.token = await this.apiService.getToken();

    const user = await this.userService.getLoggedInUser();
    this.requestApproval = false;
    var managerIds = [];
    if (this.facility.ManagerId) {
      managerIds.push(this.facility.ManagerId);
    }
    var tmpList = this.facility.AdditionalManagerIds || "";
    if (tmpList.length > 0) {
      managerIds = managerIds.concat(tmpList.split(","))
    }
    var userIsFacilityManager = managerIds.some(x => x == user.UserId)
    this.canAskApproval = managerIds.length > 0 && (!userIsFacilityManager || managerIds.length > 1);
    //this.requestApproval = !userIsFacilityManager;

    if (this.existingFacilityQuote && this.existingFacilityQuote.Id) {
      this.includeSiteWalk = this.existingFacilityQuote.IncludeSiteWalk;
      this.requestApproval = this.existingFacilityQuote.RequiresApproval;
    }

    if (!userIsFacilityManager && user.RoleId != 50 && user.RoleId != 90) {
      this.canAskApproval = false;
      //this.requestApproval = true;
    }

    this.loading = false;
  }

  async itemUp(item: AssetQuoteModel) {
    if (item.Quantity < 999) {
      item.Quantity++;
    } else {
      let toast = await this.toastController.create({
        message: `Quantity can't be greater than 999`,
        color: "danger",
        position: "bottom",
        duration: 1000
      });
      toast.present();
    }
    await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
      ItemId: item.Asset.Id,
      ItemType: "asset",
      Quantity: item.Quantity || 0,
      Images: item.SelectedImages
    });
  }

  async itemDown(item: AssetQuoteModel) {
    if (item.Quantity > 0) {
      item.Quantity--;
      await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
        ItemId: item.Asset.Id,
        ItemType: "asset",
        Quantity: item.Quantity || 0,
        Images: item.SelectedImages
      });
    }
  }

  async deleteItem(item: AssetQuoteModel) {
    let assetToDelete = _.find(this.assets, a => a.Asset.Id == item.Asset.Id);
    if (assetToDelete) {
      if (item.Battery) {
        let battery = _.find(assetToDelete.Asset.Batteries, b => b.BatteryKey == item.Battery.BatteryKey);
        if (battery) {
          let batteryFromAllItems = _.find(this.assets, a => a.Asset.Id == item.Asset.Id && a.Battery && a.Battery.BatteryKey == battery.BatteryKey);
          if (batteryFromAllItems) {
            batteryFromAllItems.Selected = false;
            batteryFromAllItems.Hidden = true;
          }
          battery.Selected = false;
          let index = _.findIndex(this.assetsToSubmit, a => a.Asset.Id == item.Asset.Id && a.Battery && a.Battery.BatteryKey == battery.BatteryKey);
          this.assetsToSubmit.splice(index, 1);
          await this.assetQuoteService.deleteFacilityQuoteItem(this.facility.Id, item.Battery.BatteryKey);
        }
      } else {
        assetToDelete.Selected = false;
        let index = _.findIndex(this.assetsToSubmit, a => a.Asset.Id == item.Asset.Id);
        this.assetsToSubmit.splice(index, 1);
        await this.assetQuoteService.deleteFacilityQuoteItem(this.facility.Id, item.Asset.Id);
      }
    }
  }

  async deletePreviouslyAdded(index: number) {
    if (confirm("Are you sure you want to delete this?")) {
      this.existingFacilityQuote.Documents.splice(index, 1);
    }
  }

  async downloadPreviouslyAdded(document: AssetQuoteDocument) {
    this.browserHelperService.openUrl(document.Url);
  }

  private async submitData(type: "submit" | "cancel" | "save" | "accept", successMessageHeader: string, successMessage: string): Promise<void> {
    // if ((type == "save" || type == "submit") && this.assetsToSubmit.length == 0) {
    //   alert("You have to select at least one asset!");
    //   return;
    // }
    let selectedContacts = _.filter(this.contacts, c => c.Selected);
    await this.loaderHelperService.on();
    this.assetsToSubmit = _.filter(this.assets, a => a.Selected);
    let response = await this.assetQuoteService.submit(
      this.existingFacilityQuote ? this.existingFacilityQuote.Key : null,
      this.facility.Id,
      this.assetsToSubmit,
      selectedContacts,
      this.notes,
      this.description,
      this.includeSiteWalk,
      this.requestApproval,
      this.warranty,
      this.fileField.getFiles(),
      ((this.existingFacilityQuote || {}).Documents || []),
      type);

    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    try {
      if (response && response.Success) {
        const alert = await this.alertController.create({
          header: successMessageHeader,
          message: successMessage,
          buttons: [
            {
              text: "Okay",
              role: "cancel",
              handler: async () => {
                try {
                  window.parent.postMessage({ message: "refresh-quote-review-dashboard" }, "*");
                } catch { }
                this.notes = null;
                this.description = null;
                this.warranty = false;
                this.fileField.uploader.clearQueue();
                this.existingFacilityQuote.Documents = [];
                this.existingFacilityQuote.Id = null;
                this.existingFacilityQuote.Key = null;
                this.assetsToSubmit = [];
                for (let item of this.assets) {
                  item.Selected = false;
                  item.Asset.Highlight = false;
                  item.SelectedImages = [];
                  item.Quantity = item.OldQuantity;
                  await this.deleteItem(item);
                }
                await this.assetQuoteService.removeFacilityQuote(this.facility.Id);
                this.pubSub.$pub(EventsConstants.QUOTE_CLEARED);
              }
            }
          ]
        });
        try {
          window.parent.postMessage({ message: "refresh-site-dashboard" }, "*")
          window.parent.postMessage({ message: "refresh-quote-status-module" }, "*")
        } catch { }
        if (this.browserHelperService.isNativeApp()) {
          this.pubSub.$pub(EventsConstants.QUOTE_HISTORY);
        }
        return await alert.present();
      } else {
        if (response.Message) {
          alert(response.Message);
        } else {
          alert("An error occurred " + (response.Message || ""));
        }
        return;
      }
    } catch (err) {
      this.errorHandlerService.showErrorMessage(err);
      return;
    } finally {
      await this.loaderHelperService.off();
    }
  }

  async save() {
    await this.submitData("save",
      "Quote request has been saved.",
      "Saved to drafts. You can continue to work on this later.");
  }

  async submit() {
    await this.submitData("submit",
      "Quote request successfully submitted",
      "You will get an email with a copy of your quote request.");
  }

  async cancel() {
    await this.submitData("cancel",
      "Quote request successfully Cancelled",
      "Quote request successfully cancelled.");
  }

  async accept() {
    await this.submitData("accept",
      "Quote Successfully Accepted",
      "Your Quote has been accepted.");
  }

  timeout: number = 0;

  notesChanged() {
    clearTimeout(this.timeout);
    setTimeout(async () => {
      let quote = await this.assetQuoteService.getFacilityQuote(this.facility.Id);
      quote.Notes = this.notes;
      quote.Description = this.description;
      await this.assetQuoteService.setFacilityQuote(quote);
    }, 500);
  }

  goToSiteWalk() {
    this.router.navigate([`/checklist/${this.facility.SiteWalkChecklistId}/false/open`]);
  }

  goToAsset(asset: SiteAsset) {
    if (this.editQuoteWeb) {
      return;
    }
    this.router.navigate([`/facilities/${asset.SiteKey}/assets/${asset.ParentId}/highlight/${asset.Id}`]);
  }

  async addNewEmail() {
    const modal = await this.modalController.create({
      component: AddQuoteEmailComponent
    });
    return await modal.present();
  }

  getImageUrl(imageId: number) {
    return `${this.baseUrl}/ImageGallery/GetImageThumbnailById/${imageId}?checklistId=${this.checklist}&mobile-app-token=${this.token}`;
  }

  openImage(imageId: number) {
    let url = `${this.baseUrl}/ImageGallery/GetImagePreviewById/${imageId}?checklistId=${this.checklist}&mobile-app-token=${this.token}`;
    this.browserHelperService.openUrl(url);
  }

  async deleteImage(item: AssetQuoteModel, index: number, imageId: number) {
    if (confirm("Are you sure you want to remove this image from this quote?")) {
      item.SelectedImages.splice(index, 1);
      if (item.Battery) {
        await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
          ItemId: item.Battery.Id,
          ItemType: "battery",
          Quantity: item.Quantity || 0,
          Images: item.SelectedImages
        });
      } else {
        await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
          ItemId: item.Asset.Id,
          ItemType: "asset",
          Quantity: item.Quantity || 0,
          Images: item.SelectedImages
        });
      }
    }
  }

  private async setContacts() {
    this.contacts = await this.assetQuoteService.getEmails();

    this.pubSub.$sub(EventsConstants.SHOPPING_CART_EMAIL_ADDED, async () => {
      let oldContacts = _.clone(this.contacts);
      this.contacts = await this.assetQuoteService.getEmails();
      for (let contact of this.contacts) {
        let item = _.find(oldContacts, c => c.Id == contact.Id)
        if (item) {
          contact.Selected = item.Selected;
        }
      }
    });

    for (let contact of this.contacts) {
      if (!contact.MemberId) {
        contact.Selected = true;
      }
    }
  }

  get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }
}