import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { ActionSheetController, AlertController, ModalController } from "@ionic/angular";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import * as moment from "moment";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "calendar-new",
  templateUrl: "calendar-new.component.html"
})
export class CalendarNewComponent implements OnInit {

  date: string;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private alertController: AlertController,
    private activatedRoute: ActivatedRoute,
    private actionSheetController: ActionSheetController,
    private userService: UserService
  ) { }

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    try {
      await this.modalController.dismiss();
      await this.modalController.dismiss();
    } catch { }
    this.date = this.activatedRoute.snapshot.params["jobDate"];

    const isPast = moment(new Date(this.date)).isSameOrBefore(moment().startOf("day"));

    var buttons = [
      {
        text: 'New Job',
        icon: '',
        handler: async () => {
          await this.router.navigate([`/mywork/createcalendarjob/${this.date}/0/version/${new Date().getTime()}`], { replaceUrl: true });
        },
      },
      {
        text: 'New Note',
        handler: async () => {
          await this.router.navigate([`/mywork/createcalendarnote/${this.date}/version/${new Date().getTime()}`], { replaceUrl: true });
        },
      }
    ];
    if (!isPast) {
      buttons.push({
        text: 'New Time Off',
        handler: async () => {
          if(await this.userService.isAdmin()){
            await this.router.navigate([`/mywork/createcalendartimeoff/${this.date}/selectUser/true/version/${new Date().getTime()}`], { replaceUrl: true });
          } else {
            await this.router.navigate([`/mywork/createcalendartimeoff/${this.date}/version/${new Date().getTime()}`], { replaceUrl: true });
          }
        },
      });
    }

    const actionSheet = await this.actionSheetController.create({
      header: 'What do you want to create',
      mode: "ios",
      buttons: [...buttons, {
        text: 'Cancel',
        role: 'cancel',
        data: {
          action: 'cancel',
        },
        handler: () => {
          this.dismiss();
        },
      }],
    });

    await actionSheet.present();

  }

  private async dismiss(): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "close-modal" }, "*");
    } else {
      await this.modalController.dismiss();
    }
  }
}