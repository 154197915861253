export const ADD_UPDATE_ASSET = "ADD_UPDATE_ASSET";
export const ADD_UPDATE_BATTERY = "ADD_UPDATE_BATTERY";
export const SYNC_STARTED = "SYNC_STARTED";
export const SYNC_DONE = "SYNC_DONE";
export const RELOAD_DATA_DONE = "RELOAD_DATA_DONE";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const IMAGE_TAKEN = "IMAGE_TAKEN";
export const IMAGE_SYNC_DONE = "IMAGE_SYNC_DONE";
export const TRIGGER_SYNC = "TRIGGER_SYNC";
export const TRIGGER_SYNC_WEB = "TRIGGER_SYNC_WEB";
export const ENVIRONMENT_UPDATED = "ENVIRONMENT_UPDATED";
export const SYNC_UPDATE = "SYNC_UPDATE";
export const REFRESH_ASSETS = "REFRESH_ASSETS";
export const REFRESH_ASSETS_WEB = "REFRESH_ASSETS_WEB";
export const CHECKLIST_ANSWER_UPDATED = "CHECKLIST_ANSWER_UPDATED";
export const SHOPPING_CART_UPDATED = "SHOPPING_CART_UPDATED";
export const SHOPPING_CART_EMAIL_ADDED = "SHOPPING_CART_EMAIL_ADDED";
export const LOG_ERROR = "LOG_ERROR";
export const QUOTE_HISTORY = "QUOTE_HISTORY";
export const REFRESH_GALLERY = "REFRESH_GALLERY";
export const REFRESH_CHECKLIST_QUESTIONS_IMAGE_ANNOTATIONS = "REFRESH_CHECKLIST_QUESTIONS_IMAGE_ANNOTATIONS";
export const IMAGE_UPLOADED_WEB = "IMAGE_UPLOADED_WEB";
export const UPDATE_JOB_STATUS = "UPDATE_JOB_STATUS";
export const PREVIEW_STAMP_ON = "PREVIEW_STAMP_ON";
export const PREVIEW_STAMP_OFF = "PREVIEW_STAMP_OFF";
export const JOB_SEARCH_DONE = "JOB_SEARCH_DONE";
export const QUOTE_REVIEW_NAVIGATE_WEB_START = "QUOTE_REVIEW_NAVIGATE_WEB_START";
export const QUOTE_REVIEW_NAVIGATE_WEB = "QUOTE_REVIEW_NAVIGATE_WEB";
export const QUOTE_CLEARED = "QUOTE_CLEARED";
export const UPDATE_CHECKLIST_BUTTON_SUMMARY = "UPDATE_CHECKLIST_BUTTON_SUMMARY";
export const ASSET_REPLACED = "ASSET_REPLACED";
export const ADD_UPDATE_BATTERY_TESTS = "ADD_UPDATE_BATTERY_TESTS";
export const ADD_BATTERY_TESTS = "ADD_BATTERY_TESTS";
export const DELETE_BATTERY_TESTS = "DELETE_BATTERY_TESTS";
export const JOB_REFRESHED = "JOB_REFRESHED";
export const WARRANTY_SUBMITTED = "WARRANTY_SUBMITTED";
export const NEW_WARRANTY = "NEW_WARRANTY";
export const NEW_WARRANTY_MODAL_CLOSED = "NEW_WARRANTY_MODAL_CLOSED";
export const WARRANTY_DRAFT_UPDATED = "WARRANTY_DRAFT_UPDATED";
export const JOB_TIME_ADD_UPDATE = "JOB_TIME_ADD_UPDATE";
export const TIME_OFF_ADD_UPDATE = "TIME_OFF_ADD_UPDATE";
export const TEMPLATE_ADDED = "TEMPLATE_ADDED";