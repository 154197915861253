<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Create Note
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" *ngIf="!loading">
    <ion-list>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusTask()">
                Task
            </ion-note>
            <ion-select [(ngModel)]="model.TaskId" okText="Okay" cancelText="Dismiss" placeholder="Please Choose"
                #taskSelect>
                <ion-label>Task</ion-label>
                <ion-select-option [value]="item.Value" *ngFor="let item of tasks">
                    {{ item.Text }}
                </ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusCustomer()">
                Customer
            </ion-note>
            <ion-input type="text" [(ngModel)]="model.Customer" #customerInput placeholder="Customer">
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusLocation()">
                Location
            </ion-note>
            <ion-input type="text" [(ngModel)]="model.Location" #locationInput placeholder="Location">
            </ion-input>
        </ion-item>

        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusNotes()">
                Notes
            </ion-note>
            <ion-textarea [(ngModel)]="model.Notes" maxlength="150" #notesTextArea placeholder="Type in notes...">
            </ion-textarea>
        </ion-item>

        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="notify.open()">
                Who to notify
            </ion-note>
            <ion-select [(ngModel)]="model.NotifyIds" okText="Okay" cancelText="Dismiss"
                placeholder="Select who to notify about this note" multiple #notify>
                <ion-select-option [value]="item.Value" *ngFor="let item of users">
                    {{ item.Text }}
                </ion-select-option>
            </ion-select>
            <ion-button (click)="selectAllToNotify()" slot="end">Select all</ion-button>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start">High Importance</ion-note>
            <ion-checkbox [(ngModel)]="model.HighImportance" #highImportance></ion-checkbox>
        </ion-item>
    </ion-list>

    <ion-button *ngIf="model && model.Id > 0" class="ion-margin-top ion-margin-bottom" color="danger" size="block"
        (click)="deleteNote()">
        Delete Note
        <ion-icon slot="end" name="trash"></ion-icon>
    </ion-button>
</ion-content>
<ion-content class="ion-padding" *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="createNote()" color="primary" checked *ngIf="!submitting">
                <ion-label *ngIf="model && model.Id > 0">
                    Update Note
                </ion-label>
                <ion-label *ngIf="model && !model.Id">
                    Create Note
                </ion-label>
            </ion-segment-button>
            <ion-segment-button *ngIf="submitting">
                <ion-spinner></ion-spinner>
            </ion-segment-button>
            <ion-segment-button (click)="dismissModal()" color="light" *ngIf="!submitting">
                <ion-label>Cancel</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>