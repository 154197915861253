import { assertPlatform, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AssetsService } from "../assets.service";
import * as _ from "lodash";
import { Facility } from "src/app/data/entities/Facility";
import { ActivatedRoute, Router } from "@angular/router";
import { AssetQuoteModel, LookupListItem, MemberLookup, SelectedAsset, SiteAssetType } from "../assets.models";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { InfiniteScrollModel } from "src/app/shared/models/infinite-scroll-model";
import { AlertController, IonInfiniteScroll, ModalController } from "@ionic/angular";
import { ChecklistService } from "src/app/checklist/checklist.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { Subscription } from "rxjs";
import { MyWorkService } from "src/app/my-work/my-work.service";
import { AssetManageService } from "../asset-manage.service";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { ChecklistQuestionModel } from "src/app/checklist/checklist.models";

@Component({
  selector: "asset-replace",
  templateUrl: "asset-replace.component.html"
})
export class AssetReplaceComponent implements OnInit, OnDestroy {
  facility: Facility;
  assets: AssetQuoteModel[] = [];
  filteredAssets: AssetQuoteModel[] = [];
  loading: boolean = false;
  assetTypes: SiteAssetType[] = [];
  assetType: number;
  lookupData: MemberLookup;
  quotePreviewWeb: boolean;
  preview: boolean;
  facilityId: string;
  assetIds: string[];
  checklistId: number;
  onReplaced: (items: string[]) => {};

  jobAssets: SelectedAsset[] = [];

  scrollModel: InfiniteScrollModel<AssetQuoteModel> = new InfiniteScrollModel<AssetQuoteModel>();
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

  private subscription: Subscription;
  private facilityChecklist: FacilityChecklist;
  private replaceQuestion: ChecklistQuestionModel;

  constructor(
    private assetsService: AssetsService,
    private modalController: ModalController,
    private myWorkService: MyWorkService,
    private pubSubService: PubSubService,
    private alertController: AlertController,
    private assetManageService: AssetManageService,
    private checklistService: ChecklistService
  ) {
  }

  async ngOnInit() {
    this.subscription = this.pubSubService.$sub(EventConstants.ASSET_REPLACED, (data: { asset: SiteAsset, replaceAllSubAssets: boolean }) => {
      this.replaced(data);
      this.ionViewWillEnter();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async ionViewWillEnter() {
    this.loading = true;
    this.facility = await this.assetsService.getFacilityWithAssets(this.facilityId);
    this.assetManageService.init(this.facility);
    let job = (await this.myWorkService.getJob(this.checklistId));
    this.jobAssets = (job || {}).Assets || [];
    var assetTypesTmp = [];
    this.assets = [];

    this.facilityChecklist = await this.checklistService.getChecklistById(this.checklistId);
    this.replaceQuestion = this.checklistService.getFirstQuestionWithDataType("replaceassets", this.facilityChecklist);

    await this.assetRecursive(this.facility.Assets, 0, async (asset: SiteAsset, level: number) => {
      if (!asset.Deleted && this.jobAssets.some(x => x.Id == asset.Id)) {
        let assetPathString = null;
        let assetPath = await this.assetsService.getAssetPath(this.facility, asset.Id);
        let tmp = [];
        for (let j = 0; j < assetPath.length - 1; j++) {
          tmp.push(assetPath[j].Description);
        }
        if (tmp.length > 0) {
          assetPathString = tmp.join("/");
        }
        let selected = this.assetIds.indexOf(asset.Id) >= 0;
        this.assets.push({
          Asset: asset,
          Battery: null,
          BatteryModel: null,
          Selected: selected,
          Level: level,
          AssetPathString: assetPathString,
          Quantity: 0,
          OldQuantity: 0,
          Hidden: false,
          SelectedImages: [],
          BatteryTests: 0
        });
      }
    });
    for (let asset of this.assets) {
      if (!_.find(assetTypesTmp, i => i.Id == asset.Asset.SiteAssetTypeId)) {
        assetTypesTmp.push({
          Description: asset.Asset.SiteAssetTypeDescription,
          Id: asset.Asset.SiteAssetTypeId
        } as SiteAssetType);
      }
    }
    this.assetTypes = assetTypesTmp;
    this.filteredAssets = this.assets;
    this.scrollModel.init(this.filteredAssets, this.infiniteScroll);
    this.loading = false;
    setTimeout(() => {
      this.scrollModel.initInfiniteScrollElement(this.infiniteScroll);
    }, 1000);
  }

  onFilterChange() {
    this.filteredAssets = [];
    for (let asset of this.assets) {
      let hidden = false;
      if (!hidden && this.assetType != null) {
        hidden = this.assetType != asset.Asset.SiteAssetTypeId;
      }
      asset.Hidden = hidden;
      if (!asset.Hidden) {
        this.filteredAssets.push(asset);
      }
    }
    this.scrollModel.init(this.filteredAssets, this.infiniteScroll);
  }

  openAsset(assetId: string, replacedWithId: string) {
    if (replacedWithId) {
      let asset = this.assetsService.getAssetFromFacility(this.facility, replacedWithId);
      if (asset) {
        this.assetManageService.editAsset(asset, this.checklistId, false, false, false, null);
      }
    } else if (assetId) {
      let asset = this.assetsService.getAssetFromFacility(this.facility, assetId);
      if (asset && asset.ReplacedWithId) {

        let asset1 = this.assetsService.getAssetFromFacility(this.facility, asset.ReplacedWithId);
        if (asset1) {
          this.assetManageService.editAsset(asset1, this.checklistId, false, false, false, null);
        }
      }
    }
  }

  async replaced(data: { asset: SiteAsset, replaceAllSubAssets: boolean }) {
    for (let item of this.assets) {
      if (item.Asset.Id == data.asset.Id) {
        this.assetIds.push(item.Asset.Id);
      }
    }

    if (data.replaceAllSubAssets) {
      let assetItems = this.assets.map(x => x.Asset);
      this.assetsService.assetRecursiveForEach(data.asset.Assets, (a: SiteAsset) => {
        if (a.OneToMany || a.Replaceable) {
          this.assetsService.assetRecursiveForEach(assetItems, (a1: SiteAsset) => {
            if (a1.Id == a.Id) {
              this.assetIds.push(a1.Id);
            }
          });
        }
      });
    }
    if (this.onReplaced) {
      this.onReplaced(this.assetIds);
      this.onFilterChange();
    }
  }

  anyAssetReplaced(): boolean {
    var auditData = this.replaceQuestion?.AuditData;
    return (auditData || "").length > 0;
  }

  hasReplacedAssets(): boolean {
    return this.jobAssets.some(x => this.isAssetReplaced(x));
  }

  isAssetReplaced(item: SelectedAsset) {
    let isReplaced = false;
    if (this.replaceQuestion && this.replaceQuestion.AuditData) {
      isReplaced = this.replaceQuestion.AuditData.split(",").indexOf(item.Id) >= 0 || (item.ReplacedWithId || "").length > 0;
    }
    return isReplaced || (item.ReplacedWithId || "").length > 0;
  }

  isAnyAssetReplaced() {
    return this.jobAssets.some(x => this.isAssetReplaced(x));
  }

  replacedCount() {
    return (this.assetIds || []).filter(x => (x || "").length > 0).length;
  }

  async previewAsset(item: AssetQuoteModel) {
    await this.assetManageService.editAsset(item.Asset, this.checklistId, false, false, true, null);
  }

  async replace(model: AssetQuoteModel) {
    if (this.preview) {
      return;
    }
    var asset = this.assetsService.getAssetFromFacility(this.facility, model.Asset.Id);
    var subAssetsCount = _.filter(asset.Assets || [], a => a.OneToMany || a.Replaceable).length;
    if (subAssetsCount > 0) {
      //alert("Replace not supported on this type of asset");
      const alert = await this.alertController.create({
        header: `What do you want to replace?`,
        buttons: [
          {
            text: "Replace this asset only",
            handler: async () => {
              await this.assetManageService.editAsset(asset, this.checklistId, true, false, false, null);
              await this.alertController.dismiss();
            }
          },
          {
            text: "Replace this asset + all sub assets",
            handler: async () => {
              await this.assetManageService.editAsset(asset, this.checklistId, true, true, false, null);
              await this.alertController.dismiss();
            }
          },
          {
            text: "Cancel",
            role: "cancel"
          }
        ]
      });
      return await alert.present();
    } else {
      var replaceSubAssets = (asset.Batteries || []).length > 0;
      await this.assetManageService.editAsset(asset, this.checklistId, true, replaceSubAssets, false, null);
    }
  }

  cancel() {
    window.parent.postMessage({ message: "close-modal" }, "*")
    this.modalController.dismiss();
  }

  private async assetRecursive(siteAssets: SiteAsset[], level: number, callback: (item: SiteAsset, level: number) => void): Promise<void> {
    for (let asset of siteAssets) {
      await callback(asset, level);
      await this.assetRecursive(asset.Assets, level + 1, callback);
    }
  }
}