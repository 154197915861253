<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            <ng-container *ngIf="asset.SiteAssetTypeDescription != asset.Description">
                {{asset.SiteAssetTypeDescription}}
            </ng-container>
            {{asset.Description}} - Schedule Work
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ng-container *ngIf="!loading">
        <ion-label>
            Project
        </ion-label>
        <ion-item class="ion-margin-bottom">
            <oms-group-select [(model)]="scheduleModel.ProjectId" [defaultText]="'Please Choose'"
                [title]="'Select Project'" [options]="availableSheduleData.Projects">
            </oms-group-select>

            <!-- <ion-select [(ngModel)]="scheduleModel.ProjectId" okText="Okay" cancelText="Dismiss"
                placeholder="Please Choose">
                <ion-label>Project</ion-label>
                <ion-select-option [value]="item.Value" *ngFor="let item of availableSheduleData.Projects">
                    {{ item.Text }}
                </ion-select-option>
            </ion-select> -->
        </ion-item>
        <ng-container *ngIf="!isTech">
            <ion-label>
                Service Provider
            </ion-label>
            <ion-item class="ion-margin-bottom">
                <ion-select [(ngModel)]="scheduleModel.VendorId" okText="Okay" cancelText="Dismiss"
                    placeholder="Please Choose" (ionChange)="onVendorChange()">
                    <ion-label>Service Provider</ion-label>
                    <ion-select-option [value]="item.Value" *ngFor="let item of availableSheduleData.Vendors">
                        {{ item.Text }}
                    </ion-select-option>
                </ion-select>
            </ion-item>
        </ng-container>

        <div class="scheduling-container">
            <ion-item lines="none">
                <ion-label>
                    <b>Schedule Now</b>
                </ion-label>
                <ion-toggle [(ngModel)]="this.scheduleModel.ScheduleNow" [disabled]="!selectedVendorData.CanSchedule"
                    slot="start">
                </ion-toggle>
            </ion-item>
            <ion-item lines="none" *ngIf="!selectedVendorData.CanSchedule && scheduleModel.VendorId > 0">
                <ion-note>You can't schedule directly to the selected Vendor. This will be awarded to them. If you want
                    to
                    be able to directly schedule they need to allow that by selecting 'Allow Direct Schedule' on their
                    member profile.</ion-note>
            </ion-item>

            <div *ngIf="this.scheduleModel.ScheduleNow">
                <ion-label *ngIf="!isTech">
                    Date
                </ion-label>
                <ion-item class="ion-margin-bottom" *ngIf="!isTech">
                    <ion-input value="{{ scheduleModel.ScheduleDate | date: 'MM/dd/yyyy' }}" [id]="id"
                        [disabled]="preview" [placeholder]="'Tap to select a date'">
                    </ion-input>
                    <ion-modal [attr.trigger]="id" size="auto" class="date-picker-modal">
                        <ng-template>
                            <ion-content>
                                <ion-datetime presentation="date" [(ngModel)]="scheduleModel.ScheduleDate"
                                    locale="en-US" showDefaultButtons="true" [min]="minDate" [max]="maxDate">
                                </ion-datetime>
                            </ion-content>
                        </ng-template>
                    </ion-modal>
                </ion-item>
                <ng-container *ngIf="!this.selectedVendorData.IsMyMember && !isTech">
                    <ion-label>
                        Project Manager
                    </ion-label>
                    <ion-item class="ion-margin-bottom">
                        <ion-select [(ngModel)]="scheduleModel.ProjectManagerId" okText="Okay" cancelText="Dismiss"
                            placeholder="Please Choose">
                            <ion-label>Project Manager</ion-label>
                            <ion-select-option [value]="item.Value"
                                *ngFor="let item of selectedVendorData.ProjectManagerList">
                                {{ item.Text }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                </ng-container>
                <ng-container *ngIf="!isTech">
                    <ion-label>
                        Lead Technician
                    </ion-label>
                    <ion-item class="ion-margin-bottom">
                        <ion-select [(ngModel)]="scheduleModel.TechnicianId" okText="Okay" cancelText="Dismiss"
                            placeholder="Please Choose">
                            <ion-label>Lead Technician</ion-label>
                            <ion-select-option [value]="item.Value"
                                *ngFor="let item of selectedVendorData.TechnicianList">
                                {{ item.Text }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                </ng-container>
                <ng-container *ngIf="!isTech">
                    <ion-label>
                        Additional Technicians
                    </ion-label>
                    <ion-item class="ion-margin-bottom">
                        <ion-select [(ngModel)]="scheduleModel.AdditionalTechnicianIds" okText="Okay"
                            cancelText="Dismiss" placeholder="Please Choose" multiple="true">
                            <ion-label>Additional Technicians</ion-label>
                            <ng-container *ngFor="let item of selectedVendorData.AdditionalTechnicianList">
                                <ion-select-option [value]="item.Value"
                                    *ngIf="item.Value != scheduleModel.TechnicianId">
                                    {{ item.Text }}
                                </ion-select-option>
                            </ng-container>
                        </ion-select>
                    </ion-item>
                </ng-container>
                <ng-container *ngIf="this.selectedVendorData.IsMyMember && !isTech">
                    <ion-label>
                        Also Notify
                    </ion-label>
                    <ion-item class="ion-margin-bottom">
                        <ion-select [(ngModel)]="scheduleModel.AlsoNotifyIds" okText="Okay" cancelText="Dismiss"
                            placeholder="Please Choose" multiple="true">
                            <ion-label>Also Notify</ion-label>
                            <ion-select-option [value]="item.Value"
                                *ngFor="let item of selectedVendorData.AlsoNotifyList">
                                {{ item.Text }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                </ng-container>
            </div>
        </div>

        <div class="scheduling-additional-assets-container" *ngIf="subAssets && subAssets.length > 0">
            <ion-item lines="none">
                <ion-label>
                    <b>Include Additional Assets</b>
                </ion-label>
                <ion-toggle [(ngModel)]="includeAdditionalAssets" slot="start"></ion-toggle>
            </ion-item>
            <div *ngIf="includeAdditionalAssets">
                <ng-container *ngFor="let item of subAssets">
                    <ion-item style="align-items: start; border-bottom: 1px solid lightgray; cursor: pointer;"
                        lines="none" (click)="assetToggle(item)">
                        <ion-label>
                            {{item.Asset.Description}}
                        </ion-label>
                        <ion-toggle slot="start" [(ngModel)]="item.Selected" style="z-index: -1;"></ion-toggle>
                    </ion-item>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loading">
        <ion-item *ngFor="let i of [1,2,3,4,5]">
            <ion-label>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </ion-label>
        </ion-item>
    </ng-container>
</ion-content>

<ion-footer *ngIf="!loading">
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="schedule()" color="primary" checked>
                <ion-label>Save</ion-label>
            </ion-segment-button>
            <ion-segment-button (click)="dismissModal()" color="light">
                <ion-label>Cancel</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>