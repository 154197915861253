<div class="data-container" *ngIf="data.Data.length > 0">
  <div class="data-container-header">
    <div class="syncscroll" name="myElements">
      <table>
        <thead>
          <tr>
            <th *ngFor="let dataColumn of data.Data[0]" [style.width.px]="100" [style.max-width.px]="100">
              <select [(ngModel)]="dataColumn.BatteryTestDataTypeId" (ngModelChange)="onDataTypeChange(dataColumn)"
                style="width: 85px">
                <option *ngFor="let item of data.DataTypes" [value]="item.Value">{{item.Text}}</option>
                <option [value]="null">Other</option>
              </select>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngFor="let dataRow of data.Data[0]">
              <input type="text" [style.width.px]="100" [style.max-width.px]="100" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="syncscroll data-container-data" name="myElements">
    <table>
      <thead>
        <tr>
          <th *ngFor="let dataColumn of data.Data[0]" [style.width.px]="100" [style.max-width.px]="100">
            Test
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dataRow of data.Data">
          <td *ngFor="let dataColumn of dataRow">
            <input type="text" [(ngModel)]="dataColumn.Data" [style.width.px]="100" [style.max-width.px]="100" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="data.Data.length == 0" style="text-align: center;">
  <div>
    OMS doesn't currently support the tester you're using.
  </div>
  <div>
    Please provide tester information below
  </div>
</div>