<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom" *ngIf="!preview">
      {{this.title}}
    </ion-title>
    <ion-title class="ion-padding-top ion-padding-bottom" *ngIf="preview">
      View Battery
    </ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">Close</ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" *ngIf="battery != null">
  <ion-item class="custom-input-ion-item">
    <ion-note position="fixed" slot="start">
      Type
    </ion-note>
    <oms-group-select [(model)]="battery.InvgenId" [title]="'Select Battery'" [options]="batteryTypes"
      [disabled]="preview">
    </oms-group-select>
  </ion-item>
  <ion-item class="custom-input-ion-item">
    <ion-note position="fixed" slot="start">
      Condition
    </ion-note>
    <ion-select [(ngModel)]="battery.ConditionId" okText="Okay" cancelText="Dismiss" [disabled]="preview">
      <ion-select-option *ngFor="let item of batteryConditions" [value]="item.Value">{{ item.Text }}</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item class="custom-input-ion-item" *ngIf="!replace || replaceOneBattery">
    <ion-note position="fixed" slot="start">
      Serial #
    </ion-note>
    <ion-input [(ngModel)]="battery.SerialNumber" (ionChange)="onSerialNumberChange()" maxlength="50"
      [disabled]="preview"></ion-input>
    <ion-icon name="barcode" (click)="scanSerialNumber()" slot="end" *ngIf="!preview"></ion-icon>
  </ion-item>
  <ion-item class="custom-input-ion-item">
    <ion-note position="fixed" slot="start">
      Date Code
    </ion-note>



    <ion-input value="{{ dateCode | date: 'MM/yyyy' }}" [id]="dateCodeId" [disabled]="preview"
      [placeholder]="'Tap to select a date'" [disabled]="preview">
    </ion-input>
    <ion-modal [attr.trigger]="dateCodeId" size="auto" class="date-picker-modal">
      <ng-template>
        <ion-content>
          <ion-datetime #ionDateTime [disabled]="preview" presentation="month-year" [(ngModel)]="dateCode" locale="en-US"
            (ionChange)="onDateCodeChange()">
            <ion-buttons slot="buttons">
              <ion-button color="danger" (click)="ionDateTime.cancel(true);">Cancel</ion-button>
              <ion-button color="primary" (click)="onDateSelected(ionDateTime)">Done</ion-button>
            </ion-buttons>
          </ion-datetime>
        </ion-content>
      </ng-template>
    </ion-modal>

    <span (click)="clearDateCode()" slot="end" class="battery-item-photo clear-date"
      *ngIf="battery.DateCode && !preview">
      <ion-icon name="close-outline"></ion-icon>
    </span>
  </ion-item>
  <ion-item class="custom-input-ion-item">
    <ion-note position="fixed" slot="start">
      Date Installed
    </ion-note>


    <ion-input value="{{ battery.DateInstalled | date: 'MM/dd/yyyy' }}" [id]="dateInstalledId" [disabled]="preview"
      [placeholder]="'Tap to select a date'" [disabled]="preview">
    </ion-input>
    <ion-modal [attr.trigger]="dateInstalledId" size="auto" class="date-picker-modal">
      <ng-template>
        <ion-content>
          <ion-datetime #ionDateTime [disabled]="preview" presentation="date" [(ngModel)]="battery.DateInstalled" locale="en-US">
            <ion-buttons slot="buttons">
              <ion-button color="danger" (click)="ionDateTime.cancel(true);">Cancel</ion-button>
              <ion-button color="primary" (click)="onDateSelected(ionDateTime)">Done</ion-button>
            </ion-buttons>
          </ion-datetime>
        </ion-content>
      </ng-template>
    </ion-modal>


    <span (click)="clearDateInstalled()" slot="end" class="battery-item-photo clear-date"
      *ngIf="battery.DateInstalled && !preview">
      <ion-icon name="close-outline"></ion-icon>
    </span>
  </ion-item>
  <ion-item *ngIf="!batteryId && !replace" class="custom-input-ion-item">
    <ion-note position="fixed" slot="start">
      Quantity
    </ion-note>
    <ion-input [(ngModel)]="numberOfBatteries" type="number" custom-max-length="2" [disabled]="preview"></ion-input>
  </ion-item>
  <ion-item class="custom-input-ion-item">
    <ion-note position="fixed" slot="start">
      Notes
    </ion-note>
    <ion-textarea [(ngModel)]="battery.Notes" [disabled]="preview"></ion-textarea>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="save()" color="primary" checked *ngIf="!preview">
        <ion-label>
          {{this.title}}
        </ion-label>
      </ion-segment-button>
      <ion-segment-button (click)="dismissModal()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>