import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { AssetQuoteModel, SiteAssetType } from "../../assets.models";
import { Router } from "@angular/router";
import { SiteAsset, SiteAssetCalcInfo } from "src/app/data/entities/SiteAsset";
import { AssetQuoteService } from "../../services/quotes/asset-quote.service";
import { Facility } from "src/app/data/entities/Facility";
import { BatteriesComponent } from "../../batteries/batteries.component";
import { ModalController, AlertController, IonInfiniteScroll } from "@ionic/angular";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ApiService } from "src/app/shared/services/api/api.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { AssetManageService } from "src/app/assets/asset-manage.service";
import { AssetQuoteSelectImagesComponent } from "../asset-quote-select-images/asset-quote-select-images.component";
import { GalleryImage } from "src/app/gallery/gallery.models";
import { AssetsService } from "../../assets.service";
import { InfiniteScrollModel } from "src/app/shared/models/infinite-scroll-model";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { Subscription } from "rxjs";

@Component({
  selector: "asset-quote-asset-list",
  templateUrl: "asset-quote-asset-list.component.html"
})
export class AssetQuoteAssetListComponent implements OnInit, OnDestroy {
  @Input()
  facility: Facility;
  @Input()
  assets: AssetQuoteModel[] = [];
  @Input()
  checklistId: number;
  @Input()
  preview: boolean = false;
  filteredAssets: AssetQuoteModel[] = [];
  assetTypes: SiteAssetType[] = [];
  assetType: number;
  fail: boolean;
  warn: boolean;
  pass: boolean;
  filteringFail: boolean;
  filteringWarn: boolean;
  filteringPass: boolean;
  canExportHeatMap: boolean;
  hasHeatMapAssets: boolean;
  query: string;
  filterInProgress: boolean;
  toggleAll: boolean;
  scrollModel: InfiniteScrollModel<AssetQuoteModel> = new InfiniteScrollModel<AssetQuoteModel>();
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

  private isInitialized: boolean = false;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private assetQuoteService: AssetQuoteService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private apiService: ApiService,
    private userService: UserService,
    private assetManageService: AssetManageService,
    private alertController: AlertController,
    private assetsService: AssetsService,
    private pubSub: PubSubService
  ) { }

  async ngOnInit() {
    this.isInitialized = false;
    let tmp: SiteAssetType[] = [];
    this.canExportHeatMap = false;
    for (let asset of this.assets) {
      if (!_.find(tmp, i => i.Id == asset.Asset.SiteAssetTypeId)) {
        tmp.push({
          Description: asset.Asset.SiteAssetTypeDescription,
          Id: asset.Asset.SiteAssetTypeId
        } as SiteAssetType);
        if (this.hasCalc(asset)) {
          this.canExportHeatMap = true;
          this.hasHeatMapAssets = true;
        }
      }
      let batteryModel = _.find(asset.Asset.Answers, a => a.FieldDataType == "battery-model");
      if (batteryModel) {
        asset.BatteryModel = batteryModel.AnswerText;
      }
    }
    this.assetTypes = tmp;
    this.filteredAssets = this.assets;
    this.scrollModel.init(this.filteredAssets, this.infiniteScroll);
    this.subscription = this.pubSub.$sub(EventsConstants.QUOTE_CLEARED, () => {
      this.query = null;
      this.pass = false;
      this.warn = false;
      this.fail = false;
      this.toggleAll = false;
      this.startSearch();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.scrollModel.initInfiniteScrollElement(this.infiniteScroll);
    setTimeout(() => {
      this.isInitialized = true;
      this.scrollToHighlight();
    }, 1000);
  }

  scrollToHighlight() {
    try {
      //document.getElementsByClassName("asset-highlight")[0].scrollIntoView();
    } catch { }
  }

  // isFiltering() {
  //   return this.query || this.pass || this.fail || this.warn || this.showHighlighted || this.showSelected || this.assetType;
  // }

  // filterOn() {
  //   this.filterVisible = true;
  // }

  // filterOff() {
  //   this.filterVisible = false;
  // }

  // clearFilter() {
  //   this.query = null;
  //   this.pass = false;
  //   this.fail = false;
  //   this.warn = false;
  //   this.showHighlighted = false;
  //   this.showSelected = false;
  //   this.assetType = null;
  //   this.onFilterChange();
  // }

  startSearch() {
    this.onFilterChange();
  }

  onFilterFailChange() {
    this.filteringFail = true;
    setTimeout(() => {
      this.onFilterChange();
      setTimeout(() => {
        this.filteringFail = false;
      }, 1000);
    }, 10);
  }

  onFilterWarnChange() {
    this.filteringWarn = true;
    setTimeout(() => {
      this.onFilterChange();
      setTimeout(() => {
        this.filteringWarn = false;
      }, 1000);
    }, 10);
  }

  onFilterPassChange() {
    this.filteringPass = true;
    setTimeout(() => {
      this.onFilterChange();
      setTimeout(() => {
        this.filteringPass = false;
      }, 1000);
    }, 10);
  }

  onFilterChange() {
    this.filterInProgress = true;
    this.filteredAssets = [];
    this.canExportHeatMap = false;
    var queryLowerCase = (this.query || "").toLowerCase();
    for (let asset of this.assets) {
      let hidden = false;
      let match = false;
      if (!match && this.fail) {
        hidden = !(asset.Asset.Calc || {} as SiteAssetCalcInfo).IsFail;
        if (!match) {
          match = !hidden;
        }
      }
      if (!match && this.warn) {
        hidden = !(asset.Asset.Calc || {} as SiteAssetCalcInfo).IsWarn;
        if (!match) {
          match = !hidden;
        }
      }
      if (!match && this.pass) {
        hidden = !(asset.Asset.Calc || {} as SiteAssetCalcInfo).IsPass;
        if (!match) {
          match = !hidden;
        }
      }

      if (!hidden && this.assetType != null) {
        hidden = this.assetType != asset.Asset.SiteAssetTypeId;
      }

      if (!hidden && this.query) {
        if (asset.Battery) {
          hidden = (asset.BatteryModel || "").toLowerCase().indexOf(queryLowerCase) < 0;
        } else if (asset.Asset) {
          hidden = (asset.Asset.Description || "").toLowerCase().indexOf(queryLowerCase) < 0 && (asset.AssetPathString || "").toLowerCase().indexOf(queryLowerCase) < 0;
        }
      }

      asset.Hidden = hidden;
      if (!hidden && this.hasCalc(asset)) {
        this.canExportHeatMap = true;
      }
    }
    this.filteredAssets = _.filter(this.assets, a => !a.Hidden);
    this.scrollModel.init(this.filteredAssets, this.infiniteScroll);
    setTimeout(() => {
      this.filterInProgress = false;
    }, 1000);
  }

  private hasCalc(asset: AssetQuoteModel) {
    if (asset && asset.Asset && asset.Asset.Calc) {
      if (asset.Asset.Calc.IsFail || asset.Asset.Calc.IsWarn || asset.Asset.Calc.IsPass) {
        return true;
      }
    }
    return false;
  }

  goToAsset(asset: SiteAsset) {
    if (!asset.OneToMany) {
      this.assetManageService.init(this.facility);
      this.assetManageService.editAsset(asset, this.checklist, false, false, false, null);
    } else {
      let parentAsset = this.assetsService.getAssetFromFacility(this.facility, asset.ParentId);
      this.assetManageService.goToAsset(parentAsset, this.checklist, this.preview, asset.Id);
    }
  }

  onToggleAll(event: any) {
    var checked: boolean = event.detail.checked;
    for (let a of this.filteredAssets) {
      a.Selected = checked;
    }
  }

  async goToBatteries(asset: SiteAsset, event: Event) {
    const modal = await this.modalController.create({
      component: BatteriesComponent,
      componentProps: {
        asset: asset,
        facility: this.facility,
        modalView: true,
        checklistId: this.checklist,
        preview: this.preview
      }
    });
    return await modal.present();
  }

  get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }

  async updateAsset(item: AssetQuoteModel, toggle: boolean) {
    if (!this.isInitialized || this.filterInProgress) {
      return;
    }

    if (item.Selected) {
      if (toggle && (item.Quantity || 0) == 0) {
        item.Quantity = 1;
      }

      await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
        ItemId: item.Asset.Id,
        ItemType: "asset",
        Quantity: item.Quantity || 0,
        Images: item.SelectedImages
      });

      if (toggle && item.Asset && item.Asset.HasBatteries) {
        var assetsToSelect = _.filter(this.assets, a => a.Asset
          && a.Asset.Id != item.Asset.Id
          && a.Asset.ParentId == item.Asset.ParentId
          && !a.Selected
          && a.Asset.SiteAssetTypeId == item.Asset.SiteAssetTypeId);
        var parentName = this.facility.Name;
        if (item.Asset.ParentId) {
          parentName = _.find(this.assets, a => a.Asset && a.Asset.Id == item.Asset.ParentId)?.Asset?.SiteAssetTypeDescription;
        }
        let description = item.Asset.SiteAssetTypeDescription;
        if (!(/s$/.test(description))) {
          description += "s";
        }
        if (assetsToSelect.length > 0) {
          const alert = await this.alertController.create({
            header: "Quote Request",
            message:
              `Do you want to include other ${description} in this ${parentName} for replacement?`,
            buttons: [
              {
                text: "Yes",
                handler: async () => {
                  for (let a of assetsToSelect) {
                    a.Selected = true;
                    await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
                      ItemId: a.Asset.Id,
                      ItemType: "asset",
                      Quantity: a.Quantity || 0,
                      Images: a.SelectedImages
                    });
                  }
                }
              },
              {
                text: "No",
                role: "cancel"
              }
            ]
          });
          return await alert.present();
        }
      }
    } else {
      await this.assetQuoteService.deleteFacilityQuoteItem(this.facility.Id, item.Asset.Id);
    }
  }

  async updateBattery(item: AssetQuoteModel, toggle: boolean) {
    if (!this.isInitialized) {
      return;
    }

    if (item.Selected) {
      if (toggle && (item.Quantity || 0) == 0) {
        item.Quantity = 1;
      }
      await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
        ItemId: item.Battery.BatteryKey,
        ItemType: "battery",
        Quantity: item.Quantity || 0,
        Images: item.SelectedImages
      })
    } else {
      await this.assetQuoteService.deleteFacilityQuoteItem(this.facility.Id, item.Battery.BatteryKey);
    }
  }

  async exportHeatmap() {
    let baseUrl = await this.apiService.getBaseUrl();
    let token = await this.userService.getUserToken();
    this.browserHelperService.openUrl(`${baseUrl}/quote/exportheatmap/${this.facility.Id}?mobile-app-token=${token}&siteAssetTypeId=${this.assetType}&isFail=${this.fail}&isWarn=${this.warn}&isPass=${this.pass}`);
  }

  async selectImages(item: AssetQuoteModel) {
    let modal = await this.modalController.create({
      component: AssetQuoteSelectImagesComponent,
      componentProps: {
        facility: this.facility,
        asset: item.Asset,
        checklistId: this.checklist,
        selectedImages: item.SelectedImages,
        useSelectedCallback: (images: number[]) => {
          this.useSelectedImages(item, images);
        }
      }
    });
    await modal.present();
  }

  async useSelectedImages(item: AssetQuoteModel, selected: number[]) {
    item.SelectedImages = selected;
    if (item.Battery) {
      await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
        ItemId: item.Battery.BatteryKey,
        ItemType: "battery",
        Quantity: item.Quantity || 0,
        Images: item.SelectedImages
      })
    } else {
      await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
        ItemId: item.Asset.Id,
        ItemType: "asset",
        Quantity: item.Quantity || 0,
        Images: item.SelectedImages
      })
    }
    this.modalController.dismiss();
  }

  goToAssets() {
    this.router.navigate([`/facilities/${this.facility.Id}/assets`]);
  }
  
  quoteInfo() {
    alert("Asset has been quoted and the quote has been accepted. This asset is pending replacement.");
  }

  quoteInProcessInfo() {
    alert("This asset is on a quote request that is currently in process.");
  }
}