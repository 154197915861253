import { Injectable } from "@angular/core";
import { BrowserHelperService } from "../browser/browser-helper.service";
import { environment } from "src/environments/environment";
import { Storage } from '@ionic/storage';
import { ConfigService } from "../config/config.service";
import { StorageWrapperService } from "../storage-wrapper/storage-wrapper.service";

@Injectable()
export class EnvironmentService {
  private static key: string = "oms-environment-key";
  constructor(
    private browserHelperService: BrowserHelperService,
    private storage: StorageWrapperService,
    private configService: ConfigService) {
  }

  async getEnvironment(): Promise<string> {
    let environment: string;
    if (this.browserHelperService.isNativeApp()) {
      environment = (await this.storage.get(EnvironmentService.key));
    } else {
      environment = window.localStorage.getItem(EnvironmentService.key);
    }
    if (environment) {
      return environment;
    }
    return "";
  }

  async setEnvironment(environment: string) {
    if (this.browserHelperService.isNativeApp()) {
      await this.storage.set(EnvironmentService.key, environment);
    } else {
      window.localStorage.setItem(EnvironmentService.key, environment);
    }
  }

  getRootUrl(env: string) {
    if (this.configService.isWebApp && this.configService.baseUrl) {
      return this.configService.baseUrl;
    }
    let rootUrl;
    switch(env) {
      case 'local': {
        //rootUrl = 'http://192.168.1.17:8080'
        
        //rootUrl = 'https://192.168.1.32:44353'
        //rootUrl = 'https://localhost:44353'
        if(this.browserHelperService.isNativeApp()){
          rootUrl = 'http://192.168.100.84:5000'
        } else {
          rootUrl = 'https://localhost:5001'
        }
        //rootUrl = 'https://192.168.0.197:44353'

        //rootUrl = 'http://localhost:8080'
        break;
      }
      case 'uat': {
        rootUrl = 'https://uat.megapwr.com'
        break;
      }
      case 'dev': {
        rootUrl = 'https://dev.megapwr.com'
        break;
      }
      case 'training': {
        rootUrl = 'https://test.megapwr.com'
        break;
      }
      case 'test': {
        rootUrl = 'https://test.megapwr.com'
        break;
      }
      default: {
        rootUrl = 'https://oms.megapwr.com'
        break;
      }
    }
    return rootUrl;
  }
}
