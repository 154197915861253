import { Injectable } from "@angular/core";
import {
  MemberLookup
} from "./assets.models";
import { RepositoryService } from "../data/db/repository.service";

@Injectable()
export class MemberService {
  constructor(
    private repositoryService: RepositoryService
  ) { }

  async getLookupData(memberId: number): Promise<MemberLookup> {
    const repo = this.repositoryService.getMemberConfigurationRepository();
    let config = await repo.findOne({ where: { MemberId: memberId } });
    if (config) {
      return JSON.parse(config.LookupData || <any>{});
    }
    return <any>{};
  }
}
