<ion-menu side="start" menuId="main-menu" contentId="content1" (ionWillOpen)="menuWillOpen()">
  <ion-header class="main-header">
    <ion-toolbar>
      <ion-title>Menu</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item (click)="mySites()">
        <i class="fal fa-house-signal main-menu-icon color-primary" slot="start"></i>
        <ion-label>My Locations</ion-label>
      </ion-item>
      <ion-item (click)="myWork()">
        <i class="far fa-tools main-menu-icon" slot="start"></i>
        <ion-label>My Jobs</ion-label>
      </ion-item>
      <ion-item (click)="newJob()" *ngIf="mobileCreateJobsEnabled">
        <i class="fas fa-plus main-menu-icon color-success" slot="start"></i>
        <ion-label>New QC Job</ion-label>
      </ion-item>
      <ion-item (click)="newWarehouseBuild()" *ngIf="mobileWarehouseBuildEnabled">
        <i class="fal fa-wrench main-menu-icon color-success" slot="start"></i>
        <ion-label>New Warehouse Build</ion-label>
      </ion-item>
      <ion-item (click)="warranty()">
        <i class="fad fa-toolbox main-menu-icon" slot="start"></i>
        <ion-label>Warranty</ion-label>
      </ion-item>
      <ion-item (click)="pendingChanges()">
        <i class="fal fa-layer-group main-menu-icon" [class.color-warning]="pendingCount > 0"
          [class.color-success]="pendingCount == 0" slot="start"></i>
        <ion-spinner *ngIf="syncInProgress" slot="start"></ion-spinner>
        <ion-label>Data Transfer</ion-label>
        <i class="fal fa-check-double color-success" slot="end" *ngIf="pendingCount == 0"></i>
        <ion-badge color="warning" slot="end" *ngIf="pendingCount > 0">{{pendingCount}}</ion-badge>
      </ion-item>
      <ion-item (click)="timeOff()" *ngIf="mobileTimeOffEnabled">
        <i class="fal fa-user-clock main-menu-icon" slot="start"></i>
        <ion-label>Time Off</ion-label>
      </ion-item>
      <ion-item (click)="timeTracking()" *ngIf="mobileTimeTrackingEnabled">
        <i class="fal fa-clock main-menu-icon" slot="start"></i>
        <ion-label>Time and Mileage</ion-label>
      </ion-item>
      <ion-item (click)="website()">
        <i class="fal fa-globe main-menu-icon color-dark" slot="start"></i>
        <ion-label>OMS Website</ion-label>
      </ion-item>
      <ion-item (click)="help()">
        <i class="fal fa-question-circle main-menu-icon color-dark" slot="start"></i>
        <ion-label>Support</ion-label>
      </ion-item>
      <ion-item (click)="about()">
        <i class="fal fa-info-circle main-menu-icon color-dark" slot="start"></i>
        <ion-label>About</ion-label>
      </ion-item>
      <ion-item (click)="signOut()">
        <i class="far fa-sign-out main-menu-icon color-danger" color="danger" slot="start"></i>
        <ion-label>Sign Out</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-toolbar>
    <ion-label class="ion-margin-start">
      {{userFullName}}
    </ion-label>
  </ion-toolbar>
</ion-menu>