<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      {{title}}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <!-- <div class="ion-padding">Currently selected: <b>{{selectedText}}</b></div> -->
  <ion-searchbar *ngIf="!disableSearch" show-cancel-button="never" mode="ios" placeholder="Search" [(ngModel)]="query" (ngModelChange)="search()" debounce="500">
  </ion-searchbar>
  <ion-item-group *ngFor="let group of visibleOptions">
    <ion-item-divider *ngIf="group.Text">
      <h3 style="width: 100%; text-align: center;">{{group.Text}}</h3>
    </ion-item-divider>
    <ion-item *ngFor="let item of group.Items" (click)="selectItem(item)">
      <ion-label>{{item.Text}}</ion-label>
      <ion-icon *ngIf="item.Value == model" name="checkmark"></ion-icon>
    </ion-item>
  </ion-item-group>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="dismissModal()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>