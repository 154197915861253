<ion-searchbar [(ngModel)]="query" (ngModelChange)="startSearch()" debounce="500" placeholder="Filter assets">
</ion-searchbar>
<ion-list #List class="list--padding-with-fab-btn">

  <asset-path-info [facility]="facility" [assetId]="(currentAsset || {}).Id" [preview]="preview"></asset-path-info>

  <ion-item-sliding *ngFor="let item of scrollModel.loadedData">
    <div *ngIf="!item.Deleted && item.OneToMany" [class.asset-highlight]="item.Highlight">
      <ion-item lines="none" style="z-index: 1;">
        <ion-label (click)="assetManageService.goToAsset(item, checklistId, preview)">
          {{ item.Description }}
          <div class="asset-item--type-description" *ngIf="item.SiteAssetTypeDescription != item.Description">
            {{ item.SiteAssetTypeDescription }}</div>
        </ion-label>
        <span *ngIf="item.ImageCount > 0" class="ion-margin-end" (click)="openGallery(item)">
          <ion-icon name="image-outline"></ion-icon>
        </span>
        <asset-heatmap-indicator [facility]="facility" [asset]="item" [takeSpace]="true"></asset-heatmap-indicator>
        <!-- <span (click)="takePhoto(item)" slot="end" class="battery-item-photo" *ngIf="!isWeb">
          <ion-icon name="camera">
          </ion-icon>
        </span>
        <span (click)="takePhoto(item)" slot="end" class="battery-item-photo">
          <ion-icon name="image-outline"></ion-icon>
        </span> -->
        <span *ngIf="item.QuoteAcceptedDate" class="ion-margin-start" (click)="quoteInfo()">
          <i class="fas fa-wrench"></i>
        </span>
        <span *ngIf="item.QuoteInProcess" class="ion-margin-start" (click)="quoteInProcessInfo()">
          <span class="quote-in-process">
            <i class="far fa-wrench"></i>
            <span class="quote-in-process-dot"></span>
          </span>
        </span>
        <ion-button slot="end" (click)="moreOptions(item, getAssets(), $event)">
          &nbsp;<i class="far fa-ellipsis-v"></i>&nbsp;
        </ion-button>
      </ion-item>
      <div class="asset-item-details-wrapper" (click)="assetManageService.goToAsset(item, checklistId, preview)"
        *ngIf="item.Info.length > 0 || item.AssetsInfo.length > 0">
        <div class="left">
          <ng-container *ngFor="let info of item.Info">
            <div class="asset-item-details">
              <span class="label">{{info.Label}}: </span>
              <span class="label">{{info.Text}}</span>
            </div>
          </ng-container>
        </div>
        <div class="right">
          <ng-container *ngFor="let info of item.AssetsInfo">
            <div class="asset-item-details">
              <asset-heatmap-indicator [facility]="facility" [assets]="info.Assets" class="float-right">
              </asset-heatmap-indicator>
              <span class="label">{{info.Label}}: </span>
              <span class="label">{{info.Text}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ion-item-sliding>
</ion-list>
<ion-infinite-scroll threshold="100px" (ionInfinite)="scrollModel.loadData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>

<ion-card *ngIf="scrollModel.loadedData.length == 0 && restrictToSelectedAssets" class="ion-text-center">
  <ion-card-header>
    <ion-card-title>No assets available</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <div class="ion-padding-bottom ion-text-start">
      <div>
        This project restricts access to job assets.
      </div>
      <div>
        Ask your manager to assign assets to this job. Assets can be assigned by updating the job.
      </div>
    </div>
  </ion-card-content>
</ion-card>