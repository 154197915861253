import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as _ from "lodash";
import { ModalController } from "@ionic/angular";
import { SelectLocationDialogComponent } from "./select-location-dialog.component";
import { NewJobLocationModel } from "../../my-work.models";

@Component({
  selector: "location-input",
  templateUrl: "location-input.component.html"
})
export class LocationInputComponent implements OnInit {
  @Input()
  customerId: number;
  @Input()
  model: number;
  @Input()
  defaultText: string;
  @Input()
  locationInfo: NewJobLocationModel;
  @Input()
  disabled: boolean;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() locationInfoChange: EventEmitter<NewJobLocationModel> = new EventEmitter<NewJobLocationModel>();

  constructor(
    private modalController: ModalController  ) { }

  async ngOnInit() {
    this.locationInfo = this.locationInfo || {} as NewJobLocationModel;
  }

  async openModel() {
    if (this.disabled) {
      return;
    }
    const modal = await this.modalController.create({
      component: SelectLocationDialogComponent,
      componentProps: {
        model: this.model,
        modelChange: this.modelChange,
        customerId: this.customerId,
        locationInfo: this.locationInfo,
        locationInfoChange: this.locationInfoChange
      }
    });
    return await modal.present();
  }
}