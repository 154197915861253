import { Component } from "@angular/core";
import { RegisterService } from "../register.service";
import { RegisterModel } from "../register.models";
import * as EmailValidator from "email-validator";
import { ActivatedRoute, Router } from "@angular/router";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";

@Component({
  selector: "new-account",
  templateUrl: "new-account.component.html"
})
export class NewAccountComponent {
  model: RegisterModel;
  emailValid: boolean = true;
  phoneValid: boolean = true;
  companyValid: boolean = true;
  fullNameValid: boolean = true;
  registrationInProgress: boolean = false;

  constructor(
    private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    private browserHelperService: BrowserHelperService
  ) {
    this.model = new RegisterModel();
    // Use the component constructor to inject providers.
  }

  async ionViewWillEnter() {
    this.model.Email = this.route.snapshot.params["email"];
  }

  async register() {
    if (!this.isDataValid()) {
      return;
    }
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    this.registerService.register(this.model);
  }

  login() {
    if (this.model.Email) {
      this.router.navigate([`login-with-email/${this.model.Email}`], { replaceUrl: true });
    } else {
      this.router.navigate(["login"], { replaceUrl: true });
    }
  }

  isDataValid() {
    this.emailValid = EmailValidator.validate(this.model.Email);
    this.fullNameValid = (this.model.FullName || "").length > 0;
    this.companyValid = (this.model.Company || "").length > 0;
    this.phoneValid = (this.model.Phone || "").length > 0;

    return this.emailValid && this.fullNameValid && this.companyValid && this.phoneValid;
  }
  
  goToHelp() {
    this.router.navigate(['help']);
  }
}
