<oms-header title="Support" [menu]="false">
  <ion-back-button defaultHref="/login"></ion-back-button>
</oms-header>
<ion-content>
  <oms-app-info></oms-app-info>
  <ion-card>
    <div class="ion-text-center ion-padding-top">
      <i class="far fa-question-square color-gray" style="font-size: 64px"></i>
    </div>
    <ion-card-header class="ion-text-center">
      <ion-card-title>Contact OMS Support</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="ion-text-center">
        <a [href]="'mailto:support.oms@mpinarada.com?subject=OMS support - version:' + version">
          <ion-button shape="round" fill="outline" class="w-100">
            <i class="fal fa-envelope-open"></i> Email
          </ion-button>
        </a>
        <a href="sms:6159435080">
          <ion-button shape="round" fill="outline" class="w-100">
            <i class="fal fa-sms"></i> Text
          </ion-button>
        </a>
        <a href="tel:6159435080">
          <ion-button shape="round" fill="outline" class="w-100">
            <i class="fal fa-phone-volume"></i> Call
          </ion-button>
        </a>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <div class="ion-text-center ion-padding-top">
      <i class="fal fa-books" style="font-size: 64px"></i>
    </div>
    <ion-card-header class="ion-text-center">
      <ion-card-title>OMS Resources</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="ion-text-center">
        <a (click)="openLink('https://www.youtube.com/channel/UCW-THeTnAAOmlYUioRq2ByA/')">
          <ion-button shape="round" fill="outline" class="w-100">
            <i class="fal fa-film"></i> Videos
          </ion-button>
        </a>
        <!-- <a (click)="openLink('https://mpinarada.com/oms/')">
          <ion-button shape="round" fill="outline" class="w-100">
            <i class="fal fa-books"></i> Documentation
          </ion-button>
        </a> -->
        <ion-button shape="round" fill="outline" class="w-100" routerLink="/about">
          <i class="fal fa-info-circle"></i> About MPINarada
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
  <oms-send-logs></oms-send-logs>
  <oms-bottom-trademark></oms-bottom-trademark>
</ion-content>