import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ApiService } from "src/app/shared/services/api/api.service";
import { AssetQuoteContact, AssetQuoteModel, AssetQuoteDbModel, FacilityQuoteDbModel, AssetQuoteDocument } from "../../assets.models";
import { BaseResponseModel, BaseResponse, BaseDataResponse } from "src/app/shared/models/base-response-model";
import { FileLikeObject } from "ng2-file-upload";
import { StorageWrapperService } from "src/app/shared/services/storage-wrapper/storage-wrapper.service";

@Injectable()
export class AssetQuoteService {
  constructor(
    private browserHelperService: BrowserHelperService,
    private storage: StorageWrapperService,
    private apiService: ApiService
  ) { }

  private static key: string = "oms-shopping-cart-key";
  private static emailKey: string = "oms-shopping-cart-emails-key";

  async removeFacilityQuoteItems(facilityId: string) {
    let facilities = await this.getAllFacilities();
    _.remove(facilities, f => f.FacilityId == facilityId);
    await this.setAllFacilities(facilities);
  }

  async setFacilityQuoteItem(facilityId: string, item: AssetQuoteDbModel): Promise<void> {
    let facility = await this.getFacilityQuote(facilityId);
    let index = _.findIndex(facility.Items, q => q.ItemId == item.ItemId && q.ItemType == item.ItemType);
    if (index >= 0) {
      facility.Items[index].Quantity = item.Quantity;
      facility.Items[index].Images = item.Images;
    } else {
      facility.Items.push({
        ItemId: item.ItemId,
        Quantity: item.Quantity,
        ItemType: item.ItemType,
        Images: item.Images
      });
    }
    await this.setFacilityQuote(facility);
  }

  async deleteFacilityQuoteItem(facilityId: string, itemId: string): Promise<void> {
    let facility = await this.getFacilityQuote(facilityId);
    _.remove(facility.Items, i => i.ItemId == itemId);
    await this.setFacilityQuote(facility);
  }

  async setFacilityQuote(facility: FacilityQuoteDbModel) {
    let facilities = await this.getAllFacilities();
    let index = _.findIndex(facilities, f => f.FacilityId == facility.FacilityId);
    if (index >= 0) {
      facilities[index] = facility;
    } else {
      facilities.push(facility);
    }
    await this.setAllFacilities(facilities);
  }

  async removeFacilityQuote(facilityId: string): Promise<void> {
    let facilities = await this.getAllFacilities();
    _.remove(facilities, f => f.FacilityId == facilityId);
    await this.setAllFacilities(facilities);
  }

  async getFacilityQuote(facilityId: string): Promise<FacilityQuoteDbModel> {
    let quoteItems = await this.getAllFacilities();
    for (let item of quoteItems) {
      if (item.FacilityId == facilityId) {
        return item;
      }
    }
    return {
      Id: null,
      Key: null,
      FacilityId: facilityId,
      Notes: null,
      Description: null,
      IncludeSiteWalk: false,
      RequiresApproval: false,
      Items: [],
      Documents: [],
      Warranty: false,
      UserId: null,
      StatusId: null
    }
  }

  async getEmails(): Promise<AssetQuoteContact[]> {
    let items: AssetQuoteContact[] = [];
    items = JSON.parse(await this.storage.get(AssetQuoteService.emailKey));
    return items || [];
  }

  async setEmails(items: AssetQuoteContact[]): Promise<void> {
    await this.storage.set(AssetQuoteService.emailKey, JSON.stringify(items));
  }

  async addNewEmail(email: AssetQuoteContact): Promise<BaseResponseModel<AssetQuoteContact>> {
    return await this.apiService.post<BaseResponseModel<AssetQuoteContact>>("quote/addemail", email);
  }

  async getQuoteModel(facilityId: string, quoteId: string): Promise<FacilityQuoteDbModel> {
    var result = await this.apiService.get<BaseDataResponse<FacilityQuoteDbModel>>("quote/getquotemodel", {
      id: quoteId
    });
    if (result.Data != null) {
      return result.Data;
    }
    return {
      Id: null,
      FacilityId: facilityId,
      Notes: null,
      Warranty: false,
      IncludeSiteWalk: false,
      RequiresApproval: false,
      Description: null,
      Items: [],
      Documents: [],
      UserId: null,
      StatusId: null,
      Key: quoteId
    }
  }

  async submit(
    quoteKey: string,
    siteKey: string,
    items: AssetQuoteModel[],
    contacts: AssetQuoteContact[],
    note: string,
    description: string,
    includeSiteWalk: boolean,
    requestApproval: boolean,
    warranty: boolean,
    files: FileLikeObject[],
    existingFiles: AssetQuoteDocument[],
    type: "submit" | "cancel" | "save" | "accept"): Promise<BaseResponse> {
    let request = [];
    for (let item of items) {
      request.push({
        AssetId: item.Asset.Id,
        BatteryKey: item.Battery ? item.Battery.BatteryKey : null,
        Amount: item.Quantity,
        Images: item.SelectedImages
      });
    }
    let formData = new FormData();
    let data = {
      Key: quoteKey,
      SiteKey: siteKey,
      Items: request,
      Contacts: contacts,
      IncludeSiteWalk: includeSiteWalk,
      Note: note,
      Description: description,
      Warranty: warranty,
      ExistingFiles: existingFiles,
      RequestApproval: requestApproval,
      RequiresApproval: requestApproval,
      Saved: type == "save",
      Accepted: type == "accept",
      Submitted: type == "submit",
      Cancelled: type == "cancel"
    }
    formData.append('data', JSON.stringify(data));
    files.forEach((file) => {
      formData.append('files', file.rawFile, file.name);
    });

    // return await this.apiService.post("quote/submit", {
    //   SiteKey: siteKey,
    //   Items: request,
    //   Contacts: contacts,
    //   IncludeSiteWalk: includeSiteWalk,
    //   Note: note,
    //   Description: description
    // });

    return await this.apiService.post("quote/submit", formData);
  }

  private async getAllFacilities(): Promise<FacilityQuoteDbModel[]> {
    let items: FacilityQuoteDbModel[] = [];
    items = JSON.parse(await this.storage.get(AssetQuoteService.key));
    return items || [];
  }

  private async setAllFacilities(items: FacilityQuoteDbModel[]): Promise<void> {
    await this.storage.set(AssetQuoteService.key, JSON.stringify(items));
  }
}
