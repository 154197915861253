import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppInfo } from "src/app/globals/AppInfo";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";
import { UserService } from "../user-service/user-service.service";
import { EnvironmentService } from "../environment-service/environment.service";
import { SyncQueue } from "src/app/data/entities/SyncQueue";
import { timeout } from "rxjs/operators";

@Injectable()
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private configService: ConfigService,
    private environmentService: EnvironmentService
  ) { }

  public async post<T>(url: string, body: any, headers?: { [header: string]: string | string[]; }): Promise<T> {
    if (!(await this.isAuthorized())) {
      return null;
    }
    return await this.postAnonimous(url, body, headers);
  }

  public async postAnonimous<T>(url: string, body: any, headers?: { [header: string]: string | string[]; }): Promise<T> {
    let baseUrl = await this.baseUrl();
    return this.httpClient
      .post<T>(`${baseUrl}${url}`, body, {
        headers: headers
      })
      .toPromise();
  }

  public async postAnonimousWithObservable(url: string, body: any): Promise<Observable<Object>> {
    let baseUrl = await this.baseUrl();
    return this.httpClient.post(`${baseUrl}${url}`, body);
  }

  public async get<T>(url: string, params?: any): Promise<T> {
    if (!(await this.isAuthorized())) {
      return new Promise<T>(() => {
        return null;
      })
    }
    let baseUrl = await this.baseUrl();
    return await this.httpClient
      .get<T>(`${baseUrl}${url}`, {
        params: params
      })
      .toPromise<T>();
  }

  public async ping(): Promise<any> {
    let baseUrl = await this.baseUrl();
    return await this.httpClient
      .get(`${baseUrl}jobs/ping`, { headers: new HttpHeaders({ timeout: `${10000}` }) })
      .pipe(timeout(10000))
      .toPromise<any>();
  }

  public async getBaseUrl(): Promise<string> {
    let env = await this.environmentService.getEnvironment();
    return this.environmentService.getRootUrl(env);
  }

  public async getToken() {
    //Web override
    var token = window["userToken"];
    if (token && token.length > 0) {
      return token;
    }
    //End Web override

    const user = await this.userService.getLoggedInUser();
    return user?.Token;
  }

  public getSyncModelHeaders(syncModel: SyncQueue): { [header: string]: string | string[]; } {
    return {
      latitude: syncModel.Latitude,
      longitude: syncModel.Longitude,
      datetimestamp: syncModel.DateCreated
    }
  }

  private async isAuthorized() {
    return (await this.getToken() || "").length > 0
  }

  async baseUrl(): Promise<string> {
    let baseUrl = await this.getBaseUrl();
    return `${baseUrl}/api/`;
  }
}
