import { Component, Input, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { MyWorkService } from "../../my-work.service";
import { UserTimeOffDayModel, UserTimeOffModel } from "../../my-work.models";
import { IonDatetime, ModalController } from "@ionic/angular";
import * as moment from "moment";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { ActivatedRoute } from "@angular/router";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { SelectListItem } from "src/app/assets/assets.models";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "add-timeoff",
  templateUrl: "add-timeoff.component.html"
})
export class AddTimeOffComponent implements OnInit {
  @Input()
  model: UserTimeOffModel = { Days: [] };
  inputDays: string[] = [];
  loading: boolean = false;
  minDate: string = moment().startOf("year").format("YYYY-MM-DD");
  forUser: string;
  userId: number | null;
  selectUser: boolean = false;
  users: SelectListItem[] = [];
  @ViewChild(IonDatetime)
  dateTimeComponent: IonDatetime;
  myUserId: number;

  constructor(
    private myWorkService: MyWorkService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private errorHandlerService: ErrorHandlerService,
    private pubSubService: PubSubService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.loading = true;
    try {
      this.myUserId = (await this.userService.getLoggedInUser()).UserId;
      this.selectUser = this.activatedRoute.snapshot.params["selectUser"] == "true";
      if (this.selectUser) {
        this.users = (await this.myWorkService.getTimeOffUsers()).Items;
      }
      if (window["timeOffForUser"]?.id && window["timeOffForUser"]?.name) {
        this.forUser = `for ${window["timeOffForUser"]?.name}`;
        this.userId = window["timeOffForUser"]?.id;
      }
      await this.loadData();
    } catch (err) {
      this.errorHandlerService.logErrorAndShowGenericToast(err);
    } finally {
      this.loading = false;
    }
  }

  onSelectUser(user: SelectListItem) {
    this.forUser = `for ${user.Text}`;
    this.userId = +user.Value;
    this.selectUser = false;
    try {
      this.loading = true;
      this.loadData();
    } finally {
      this.loading = false;
    }
  }

  private async loadData() {
    this.model.Days = (await this.myWorkService.getTimeOff(this.userId, "thisyear")).Items;
    const date = this.activatedRoute.snapshot.params["jobDate"];
    if (date && !this.model.Days.some(d => d.Day == date)) {
      this.model.Days.push({
        Day: date,
        Description: null,
        Id: null,
        HasDescription: false,
        Delete: false
      });
    }
    this.setInputDays();
  }

  onDateSelectionChange(event: any) {
    let selected = event.detail.value || [];
    for (let day of this.model.Days) {
      day.Delete = !selected.some(x => x == day.Day);
    }

    for (let day of selected) {
      if (!this.model.Days.some(x => x.Day == day)) {
        this.model.Days.push({
          Day: day,
          Description: null,
          Id: null,
          HasDescription: false,
          Delete: false
        });
      }
    }

    this.model.Days = _.orderBy(this.model.Days, x => x.Day).reverse();
  }

  public get availableDays(): UserTimeOffDayModel[] {
    return _.orderBy(this.model.Days.filter(x => !x.Delete), x => x.Day).reverse();
  }

  getNumberOfDays() {
    return this.model.Days.filter(x => !x.Delete).length;
  }

  removeDay(day: UserTimeOffDayModel) {
    day.Delete = true;
    this.setInputDays();
  }

  async save() {
    if (this.loading) return;
    try {
      if (!await this.browserHelperService.isOfflineAndMessage()) {
        this.loading = true;
        await this.myWorkService.saveTimeOff(this.userId, this.model.Days);
        this.pubSubService.$pub(EventConstants.TIME_OFF_ADD_UPDATE);
        await this.dismissModal();
      }
    } catch (err) {
      this.errorHandlerService.logErrorAndShowGenericToast(err);
    } finally {
      this.loading = false;
    }
  }

  async dismissModal(): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "calendar-time-off-created" }, "*");
    } else {
      await this.modalController.dismiss();
    }
  }

  formatDate(day: string) {
    return moment(day).format("MMM Do YY");
  }

  private setInputDays() {
    this.inputDays = this.model.Days.filter(x => !x.Delete).map(x => x.Day);
  }
}