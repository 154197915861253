import { IonInfiniteScroll } from "@ionic/angular";
import * as _ from "lodash";

export class InfiniteScrollModel<T> {
  private allData: T[];
  loadedData: T[] = [];
  private pageSize: number = 15;
  private loadedCount: number = 0;
  infiniteScroll: IonInfiniteScroll;
  constructor() {
    this.allData = [];
  }
  setPageSize(value: number) {
    this.pageSize = value;
  }

  init(data: T[], infiniteScroll: IonInfiniteScroll) {
    this.allData = data;
    this.loadedData = _.take(this.allData, this.pageSize);
    this.loadedCount = this.loadedData.length;
    this.initInfiniteScrollElement(infiniteScroll);
  }

  initInfiniteScrollElement(infiniteScroll: IonInfiniteScroll) {
    if (infiniteScroll) {
      this.infiniteScroll = infiniteScroll;
      this.infiniteScroll.disabled = this.allLoaded;
    }
  }

  private loadMore() {
    if (this.allData.length > this.loadedCount) {
      let nextBatch = _.take(_.drop(this.allData, this.loadedCount), this.pageSize);
      this.loadedData = this.loadedData.concat(nextBatch);
      this.loadedCount = this.loadedData.length;
    }
  }

  loadData(event) {
    if (this.allData.length > this.loadedCount) {
      this.loadMore();
      event.target.complete();
      this.infiniteScroll.disabled = this.allLoaded;
    }
  }

  get allLoaded() {
    return this.loadedCount == this.allData.length;
  }

  get data() {
    return this.loadedData;
  }
}