import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../my-work.service";
import { JobDataModel, LocationJobHistoryModel } from "src/app/checklist/checklist.models";
import { AssetsService } from "src/app/assets/assets.service";
import { Facility } from "src/app/data/entities/Facility";
import { ModalController, PopoverController } from "@ionic/angular";
import { DomSanitizer } from "@angular/platform-browser";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { WorkStatusHistoryComponent } from "../work-item-details/work-status-history/work-status-history.component";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { WorkJobHistoryComponent } from "../work-item-details/work-job-history/work-job-history.component";

@Component({
  selector: "work-item-site-details",
  templateUrl: "work-item-site-details.component.html"
})
export class WorkItemSiteDetailsComponent implements OnInit {
  @Input()
  jobModel: JobDataModel;
  @Input()
  facility: Facility;
  @Input()
  fromMap: boolean;
  @Input()
  preview: boolean;

  jobHistoryItems: LocationJobHistoryModel[] = [];

  isVendor: boolean;

  constructor(
    private myWorkService: MyWorkService,
    private assetsService: AssetsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private sanitizer: DomSanitizer,
    private browserHelperService: BrowserHelperService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    let jobId = this.activatedRoute.snapshot.params["id"];
    this.jobModel = this.jobModel ?? await this.myWorkService.getJob(jobId);
    this.facility = this.facility ?? await this.assetsService.getFacilityWithAssets(this.jobModel.SiteKey);
    this.isVendor = await this.userService.isVendor();
    this.loadLocationJobHistory();
  }

  goToItem() {
    try {
      this.modalController.dismiss();
    } catch { }
    try {
      this.popoverController.dismiss();
    } catch { }
    this.router.navigate([`/mywork/work-details/${this.jobModel.ChecklistId}/true`]);
  }

  async navigate() {
    var navigator = window["launchnavigator"];
    if (navigator) {
      window["launchnavigator"].navigate([this.jobModel.Latitude, this.jobModel.Longitude]);
    } else {
      alert("You can only use this functionality if you install the app.");
    }
  }

  dismiss() {
    this.popoverController.dismiss();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  getPhoneHref(phone: string) {
    return this.sanitizer.bypassSecurityTrustUrl(`tel:${phone}`);
  }

  getSMSHref(phone: string) {
    return this.sanitizer.bypassSecurityTrustUrl(`sms:${phone}`);
  }

  async statusHistory() {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const modal = await this.modalController.create({
      component: WorkStatusHistoryComponent,
      componentProps: {
        jobModel: this.jobModel
      }
    });
    return await modal.present();
  }

  async loadLocationJobHistory() {
    try {
      let response = await this.myWorkService.getLocationJobHistory(this.jobModel.ChecklistId);
      this.jobHistoryItems = response.Items;
    } catch (err) {
    }
  }

  async locationJobHistory() {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const modal = await this.modalController.create({
      component: WorkJobHistoryComponent,
      componentProps: {
        items: this.jobHistoryItems
      }
    });
    await modal.present();
  }
}