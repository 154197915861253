import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  Input
} from "@angular/core";

@Directive({
  selector: "[decimal-input]" // Attribute selector
})
export class DecimalDirective {
  @Output() input: EventEmitter<string> = new EventEmitter<string>();
  @Input("decimal-input") decimalPlaces: number = 2;
  @Input("allow-negative") allowNegative: boolean = false;

  constructor(public elementRef: ElementRef) { }

  @HostListener("keyup", ["$event"])
  keyEvent(event) {
    let element = event.srcElement;
    if (element.tagName !== "INPUT") {
      return;
    }

    if (element.value) {
      const decimalPlaces = this.decimalPlaces || 2;
      let regex = `\\d*\\.?\\d{0,${decimalPlaces}}`;
      if (this.allowNegative) {
        regex = `[-]?` + regex;
      }
      var match = element.value.match(new RegExp(regex, "g"));
      if (match) {
        element.value = match[0];
        try {
          this.elementRef.nativeElement.value = element.value;
        } catch { }
      } else {
        element.value = '';
      }
    }
  }
}
