import { Component, Input, OnInit } from "@angular/core";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { Facility } from "src/app/data/entities/Facility";
import * as _ from "lodash";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { Entry } from "@ionic-native/file/ngx";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ApiService } from "src/app/shared/services/api/api.service";
import { ChecklistQuestionModel } from "../checklist.models";
import { SyncService } from "src/app/sync/sync.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "preview-view",
  templateUrl: "preview-view.component.html"
})
export class PreviewViewComponent implements OnInit {
  @Input()
  checklist: ChecklistQuestionModel;
  pendingCount: number = 0;
  url: string;

  constructor(
    private browserHelperService: BrowserHelperService,
    private apiService: ApiService,
    private syncService: SyncService,
    private userService: UserService) {
  }

  async ngOnInit() {
    this.setPendingCount();
    let baseUrl = await this.apiService.getBaseUrl();
    let token = await this.userService.getUserToken();
    this.url = `${baseUrl}/ChecklistQuestions/Pdf/${this.checklist.Id}?mobile-app-token=${token}`;
  }

  async open() {
    let baseUrl = await this.apiService.getBaseUrl();
    let token = await this.userService.getUserToken();
    this.browserHelperService.openUrl(`${baseUrl}/ChecklistQuestions/Pdf/${this.checklist.Id}?mobile-app-token=${token}`);
  }

  async openBlank() {
    let baseUrl = await this.apiService.getBaseUrl();
    let token = await this.userService.getUserToken();
    this.browserHelperService.openUrl(`${baseUrl}/ChecklistQuestions/Pdf/${this.checklist.Id}?blank=true&mobile-app-token=${token}`);
  }

  async openCloseout() {
    let baseUrl = await this.apiService.getBaseUrl();
    let token = await this.userService.getUserToken();
    this.browserHelperService.openUrl(`${baseUrl}/CloseoutPackage/PdfPreviewOrGenerated/${this.checklist.Id}?mobile-app-token=${token}`);
  }

  private async setPendingCount() {
    this.pendingCount = await this.syncService.getPendingTotalCount();
  }
}