import { Component, Input, OnInit } from "@angular/core";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AssetsService } from "../assets.service";
import { Facility } from "src/app/data/entities/Facility";
import { AvailableScheduleData, AvailableScheduleVendorData, LookupListItem, MemberLookup, ScheduleAssetModel, ScheduleModel } from "../assets.models";
import { MemberService } from "../member.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { AssetScheduleService } from "../services/quotes/asset-schedule.service";
import { DateTimeHelperService } from "src/app/shared/services/datetime/datetime-helper.service";
import * as _ from "lodash";
import * as moment from "moment";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { User } from "src/app/data/entities/User";
import { Guid } from "guid-typescript";

@Component({
  selector: "asset-scheduling",
  templateUrl: "asset-scheduling.component.html"
})
export class AssetSchedulingComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  asset: SiteAsset;
  lookupData: MemberLookup;
  scheduleModel: ScheduleModel;
  availableSheduleData: AvailableScheduleData;
  selectedVendorData: AvailableScheduleVendorData;
  includeAdditionalAssets: boolean;
  subAssets: ScheduleAssetModel[] = [];
  loading: boolean = false;
  minDate: string;
  maxDate: string;
  user: User;
  isTech: boolean;
  id: string;

  constructor(
    private router: Router,
    private assetsService: AssetsService,
    private memberService: MemberService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private assetScheduleService: AssetScheduleService,
    private dateTimeHelperService: DateTimeHelperService,
    private loaderHelperService: LoaderHelperService,
    private userService: UserService
  ) {
    this.scheduleModel = {} as ScheduleModel;
    this.selectedVendorData = {} as AvailableScheduleVendorData;
  }

  async ngOnInit() {
    this.loading = true;
    this.minDate = moment().format("YYYY-MM-DD");
    this.maxDate = moment().add(10, "years").format("YYYY-MM-DD");
    this.lookupData = await this.memberService.getLookupData(this.facility.CustomerId);
    this.availableSheduleData = await this.assetScheduleService.getAvailableScheduleData(this.facility.Id, this.asset.Id);
    this.scheduleModel.ScheduleDate = this.dateTimeHelperService.getString();
    this.user = await this.userService.getLoggedInUser();
    this.isTech = await this.userService.isTechnician();
    if (this.isTech) {
      this.scheduleModel.TechnicianId = this.user.UserId;
      this.scheduleModel.VendorId = this.user.MemberId
      this.selectedVendorData = _.find(this.availableSheduleData.Vendors, v => v.Value == this.scheduleModel.VendorId) || {} as AvailableScheduleVendorData;
    }
    this.id = Guid.create().toString();
    let additionalAssets = await this.assetsService.getAssets(this.facility, this.asset.ParentId);
    // for (let asset of additionalAssets) {
    //   if (this.asset.Id != asset.Id && asset.SiteAssetTypeId == this.asset.SiteAssetTypeId && asset.Schedulable) {
    //     this.subAssets.push({
    //       Asset: asset,
    //       Selected: false
    //     });
    //   }
    // }
    for (let asset of this.asset.Assets) {
      if (asset.Schedulable && !asset.Deleted) {
        this.subAssets.push({
          Asset: asset,
          Selected: false
        });
      }
    }
    this.loading = false;
  }

  assetToggle(item: ScheduleAssetModel) {
    item.Selected = !item.Selected;
  }

  async schedule() {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    if (!this.scheduleModel.ProjectId) {
      alert("Project is required");
      return;
    }
    if (!this.scheduleModel.VendorId) {
      alert("Service Provider is required");
      return;
    }
    if (this.scheduleModel.ScheduleNow) {
      if (!this.selectedVendorData.IsMyMember && !this.scheduleModel.ProjectManagerId) {
        alert("Project Manager is required");
        return;
      }
      if (!this.scheduleModel.TechnicianId) {
        alert("Technician is required");
        return;
      }
    }
    this.scheduleModel.AssetId = this.asset.Id;
    this.scheduleModel.AdditionalAssetIds = [];
    if (this.includeAdditionalAssets) {
      for (let asset of this.subAssets) {
        if (asset.Selected) {
          this.scheduleModel.AdditionalAssetIds.push(asset.Asset.Id);
        }
      }
    }
    try {
      await this.loaderHelperService.on();
      let response = await this.assetScheduleService.assetSchedule(this.scheduleModel);
      await this.loaderHelperService.off();
      setTimeout(() => {
        if (!response.Success) {
          alert(response.Message);
          return;
        }
        if (this.scheduleModel.ScheduleNow) {
          alert("Successfully scheduled");
        } else {
          alert("Job Created");
        }
        this.dismissModal();
      }, 10);
    } finally {
      await this.loaderHelperService.off();
    }
  }

  onVendorChange() {
    this.selectedVendorData = _.find(this.availableSheduleData.Vendors, v => v.Value == this.scheduleModel.VendorId) || {} as AvailableScheduleVendorData;
    this.scheduleModel.TechnicianId = null;
    this.scheduleModel.AdditionalTechnicianIds = [];
    this.scheduleModel.AlsoNotifyIds = [];
    this.scheduleModel.ScheduleNow = false;
  }

  async dismissModal(): Promise<void> {
    await this.modalController.dismiss();
  }
}