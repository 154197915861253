import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "new-account-confirmation",
  templateUrl: "new-account-confirmation.html"
})
export class NewAccountConfirmationComponent {
  constructor(private router: Router) { }

  login() {
    this.router.navigate(["login"], { replaceUrl: true });
  }
}
