<!-- <div class="ion-text-center">
    <ion-note>
        <ion-icon name="information-circle-outline"></ion-icon>
        Pull down to refresh
    </ion-note>
</div>
<ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingText="Pull down more to refresh" refreshingText="Refreshing...">
    </ion-refresher-content>
</ion-refresher> -->
<ion-list>
    <ng-container *ngFor="let item of scrollModel.loadedData">
        <div *ngIf="isFirstOfThisDate(item)" class="my-work-item-job-date">
            To Do: {{item.ScheduledWorkDateText}}
        </div>
        <ion-item (click)="goToWorkItem(item)" class="my-work-item" *ngIf="!item.Hidden"
            [class.work-item-highlight]="item.Highlight">
            <ion-label>
                <div [style.background-color]="item.StatusColor" class="job-status">
                </div>
                <div class="job-lead" *ngIf="item.LeadTechnician && item.HasAdditionalTechnicians">
                </div>
                <div class="job-additional-technician" *ngIf="!item.LeadTechnician && item.HasAdditionalTechnicians">
                </div>
                <!-- <div *ngIf="item.FACode" class="site-name">FA Code: {{ item.FACode }}</div> -->
                <div class="site-name">
                    {{ item.SiteName }}
                </div>
                <div class="project-name" style="font-size: 12px;" *ngIf="item.Assets">
                    <ul style="margin: 0.2rem; padding-left: 1.5rem; max-height: 63px; overflow: hidden;">
                        <li *ngFor="let asset of item.Assets">{{asset.Name}}</li>
                    </ul>
                </div>
                <div class="project-name" *ngIf="isVendor">
                    Asset Owner: <i>{{ item.CustomerName }}</i>
                </div>
                <div class="project-name">
                    Project: <i>{{ item.ProjectName }}</i>
                </div>
                <div class="job-name">
                    Job ID: {{ item.ChecklistId }} - {{item.StatusName}}
                    <!-- <ion-badge [style.background-color]="item.StatusColor" class="ion-float-right job-status-badge">
                        {{item.StatusName}}
                    </ion-badge>
                    <ion-badge color="medium" class="ion-float-right" *ngIf="item.LeadTechnician">Lead</ion-badge> -->
                </div>
                <div class="job-name" *ngIf="item.BatteryQty">
                    Battery Qty: {{ item.BatteryQty }}
                </div>
                <div class="job-name" *ngIf="item.EquipmentName">
                    Equipment Name: {{ item.EquipmentName }}
                </div>
                <div class="job-name" *ngIf="item.SiteNotes">
                    Notes: {{ item.SiteNotes }}
                </div>
                <div class="job-checkedin" *ngIf="item.CheckedIn">
                    <i class="far fa-cog icon"></i>
                </div>
            </ion-label>
        </ion-item>
    </ng-container>
</ion-list>
<ion-infinite-scroll threshold="100px" (ionInfinite)="scrollModel.loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
</ion-infinite-scroll>
<ion-card *ngIf="workList.length === 0" class="ion-text-center">
    <ion-card-header>
        <ion-card-title>My Jobs</ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <div class="ion-padding-bottom">
            You don't have any scheduled work yet.
        </div>
    </ion-card-content>
</ion-card>