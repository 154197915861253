import { Component, Input, OnInit } from "@angular/core";
import { ParsedBatteryTestModel } from "../../battery-test.models";

@Component({
  selector: "battery-test-no-tester-form",
  templateUrl: "battery-test-no-tester-form.component.html"
})
export class BatteryTestNoTesterFormComponent implements OnInit {
  @Input()
  data: ParsedBatteryTestModel;

  ngOnInit(): void {
    if (!this.data.BatteryTest.BatteryType) {
      this.data.BatteryTest.BatteryType = "VRLA";
    }
  }
}