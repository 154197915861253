import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../my-work.service";
import { AlertController, IonInput, IonSelect, IonTextarea, ModalController, ToastController } from "@ionic/angular";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { NewJobModel, NewWarehouseBuildItemModel, NewWarehouseBuildModel } from "../my-work.models";
import { LookupListGroup, SelectListItem } from "src/app/assets/assets.models";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";

@Component({
  selector: "new-warehouse-build",
  templateUrl: "new-warehouse-build.component.html"
})
export class NewWarehouseBuildComponent implements OnInit {
  @Input()
  model: NewWarehouseBuildModel = {} as any;
  templates: NewWarehouseBuildModel[] = [];
  loading: boolean = false;
  templateCategoryVisible: boolean;
  submitting: boolean;
  buildId: number;

  constructor(
    private myWorkService: MyWorkService,
    private toastController: ToastController,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private modalController: ModalController,
    private alertController: AlertController,
    private browserHelperService: BrowserHelperService,
    private barcodeScannerHelperService: BarcodeScannerHelperService
  ) { }

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    this.buildId = this.route.snapshot.params["buildId"] || null;
    this.loading = true;
    if (this.buildId) {
      await this.loadBuild(this.buildId);
    } else {
      this.templateCategoryVisible = true;
      await this.loadData();
    }
    this.loading = false;
  }

  async scanAnswer(item: NewWarehouseBuildItemModel, ionItem: any) {
    this.scan(() => {
      ionItem.el.getElementsByTagName("input")[0].focus();
    }, value => item.Data = value);
  }

  private async scan(handHeldCallback: () => void, scanCallback: (value: string) => {}) {
    if (await this.checkForHandHeldScanner()) {
      handHeldCallback();
    } else {
      const text = await this.barcodeScannerHelperService.scan();
      if (text) {
        scanCallback(text);
      }
    }
  }

  private async checkForHandHeldScanner() {
    if (!this.browserHelperService.isNativeApp()) {
      await this.notifyMessage(`If you have hand held scanner you can use it to scan now`);
      return true;
    }
    return false;
  }

  private async notifyMessage(message: string, color: "info" | "danger" | "success" = "info") {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: "bottom",
      duration: 3000,
      cssClass: "new-job-hand-held-scanner-toast"
    });
    await toast.present();
  }

  async create() {
    if (!(await this.validate())) return;
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    try {
      this.submitting = true;
      var response = await this.myWorkService.createWarehouseBuild(this.model);
      if (response.Success) {
        if (WebAppShowComponent.IsWebAppIFrame) {
          if (this.model.Id > 0) {
            window.parent.postMessage({ message: "warehouse-build-updated" }, "*");
          } else {
            window.parent.postMessage({ message: "warehouse-build-created" }, "*");
          }
        } else {
          if (this.model.Id > 0) {
            this.notifyMessage("Successfully created", "success");
          } else {
            this.notifyMessage("Successfully updated", "success");
          }
          this.modalController.dismiss();
        }
      } else {
        this.notifyMessage(`There was an error while creating warehouse build: ${response.Message}`, "danger");
      }
    } catch {
      this.notifyMessage("There was an error while creating warehouse build", "danger");
    } finally {
      this.submitting = false;
    }
  }

  dismissModalWithoutPrompt() {
    this.modalController.dismiss();
  }
  
  async dismissModal() {
    const alert = await this.alertController.create({
      header: "Are you sure you want to cancel?",
      buttons: [
        {
          text: "Yes, I am sure",
          handler: async () => {
            this.dismiss();
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        },
      ]
    });
    return await alert.present();
  }

  templateCategoryChanged(model: NewWarehouseBuildModel) {
    this.model = model;
    this.templateCategoryVisible = false;
  }

  private async dismiss(): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "close-modal" }, "*");
    } else {
      await this.modalController.dismiss();
    }
  }
  private async validate(): Promise<boolean> {
    var message = null;
    for (let item of this.model.Items) {
      if (_.isEmpty(item.Data)) {
        message = `${item.DisplayName} is required`;
        break;
      }
    }

    if (!message) {
      let allScans = this.model.Items.filter(x => x.DataType == 'scan' && !_.isEmpty(x.Data)).map(x => x.Data);
      if (allScans.length > 0) {
        let uniqueScans = _.uniq(allScans);
        if (allScans.length != uniqueScans.length) {
          message = "All Serial Numbers have to be unique";
        }
      }
    }

    if (message) {
      await this.notifyMessage(message, "danger");
      return false;
    }
    return true;
  }

  private async loadBuild(id: number): Promise<void> {
    try {
      this.model = (await this.myWorkService.getWarehouseBuildById(id)).Data;
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Warehouse Build ${id}`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }

  private async loadData(): Promise<void> {
    try {
      this.templates = (await this.myWorkService.getWarehouseBuildTemplates()).Items;
      if (this.templates.length == 1) {
        this.templateCategoryChanged(this.templates[0]);
      } else {
        this.templateCategoryVisible = true;
      }
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Warehouse Build Templates`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }
}