import { Component, HostListener, OnInit } from "@angular/core";
import { RegisterService } from "../register.service";
import { LoginModel } from "../register.models";
import * as EmailValidator from "email-validator";
import { Router, ActivatedRoute } from "@angular/router";
import { EnvironmentService } from "src/app/shared/services/environment-service/environment.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants"
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { ToastController } from "@ionic/angular";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  model: LoginModel;
  emailDataValid: boolean = true;
  usernameDataValid: boolean = true;
  passwordDataValid: boolean = true;
  environment: string
  showHiddenEnvironments = false;
  showHiddenEnvironmentsTimeout: number;
  loading: boolean = false;
  showEnvironmentSwitcher: boolean = false;
  mode: string = "password";
  termsURL: string;

  constructor(
    private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    private environmentService: EnvironmentService,
    private pubSub: PubSubService,
    private userService: UserService,
    public toastController: ToastController,
    private browserHelperService: BrowserHelperService
  ) {
    this.model = new LoginModel();
    this.model.AcceptTerms = true; 
    this.showEnvironmentSwitcher = this.browserHelperService.isNativeApp() || environment.isDev;
    this.showHiddenEnvironments = environment.isDev;
  }

  @HostListener('touchstart', ['$event'])
  onTouchEnd(event: TouchEvent): void {
    if (event.touches.length > 3) {
      this.showHiddenEnvironmentsTimeout = window.setTimeout(async () => {
        this.showHiddenEnvironments = true;
        let toast = await this.toastController.create({
          message: "Now you can switch to other environments",
          duration: 2000
        });
        toast.present();
      }, 1 * 1000);
    }
  }

  @HostListener('touchend', ['$event']) onTouchStart(event: Event): void {
    window.clearTimeout(this.showHiddenEnvironmentsTimeout);
  }

  async ngOnInit() {
    let env = await this.environmentService.getEnvironment();
    let rootUrl = this.environmentService.getRootUrl(env);
    this.termsURL = rootUrl + "/home/downloadterms";
  }

  ionViewWillEnter(): void {
    this.model.Email = this.route.snapshot.params["email"];
    this.userService.setLoggedInUser(null);
  }

  async loginWithPassword() {
    if (!this.isDataValidWithPassword()) {
      return;
    }
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    this.loading = true;
    try {
      await this.registerService.loginWithPassword(this.model);
    } finally {
      this.loading = false;
    }
  }

  async loginWithoutPassword() {
    if (!this.isDataValidWithoutPassword()) {
      return;
    }
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    this.loading = true;
    await this.registerService.loginWithoutPassword(this.model);
    this.loading = false;
  }

  newAccount() {
    if (this.model.Email) {
      this.router.navigate([`new-account/${this.model.Email}`], { replaceUrl: true });
    } else {
      this.router.navigate([`new-account`], { replaceUrl: true });
    }
  }

  isDataValidWithoutPassword() {
    this.emailDataValid = EmailValidator.validate(this.model.Email);
    return this.emailDataValid;
  }

  isDataValidWithPassword() {
    this.usernameDataValid = (this.model.Username || "").length > 0;
    this.passwordDataValid = (this.model.Password || "").length > 0;
    return this.emailDataValid && this.passwordDataValid;
  }

  async switchEnv(env: string) {
    this.environment = env;
    await this.environmentService.setEnvironment(env);
    this.pubSub.$pub(EventConstants.ENVIRONMENT_UPDATED, env);
  }

  goToHelp() {
    this.router.navigate(['help']);
  }

  switchMode(mode: string) {
    this.mode = mode;
  }

  async readTerms() {
    this.browserHelperService.openUrl(this.termsURL);
  }
}
