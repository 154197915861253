<div [class.question-web]="isWeb">
    <ng-container *ngIf="item.FieldDataType != 'file'">
        <div class="ion-padding-top ">
            <i class="fas fa-asterisk color-danger question-required" style="font-size: 1rem;"
                *ngIf="item.Required"></i>
            <div class="ion-padding-bottom"
                [class.photo-only]="item.FieldDataType == 'photo' || item.FieldDataType == 'video'"
                [class.ion-padding-bottom]="item.FieldDataType == 'photo'" style="padding-right: 1rem;">
                <span slot="start" slot="start" *ngIf="hasAttachments()" (click)="openAttachments()" class="battery-item-photo">
                    <ion-icon name="attach-outline" style="transform: rotate(45deg);"></ion-icon>
                </span>
                <div class="photo-only-text" [class.label-question]="item.FieldDataType == 'label'">
                    {{ item.PromptText }}
                </div>
                <span *ngIf="!isWeb && item.FieldDataType == 'photo' && !preview" (click)="takePhoto($event, true)"
                    slot="end" class="battery-item-photo" style="z-index: 5;">
                    <ion-icon name="image-outline">
                    </ion-icon>
                </span>
                <span *ngIf="!isWeb && item.FieldDataType == 'photo' && !preview" (click)="takePhoto($event)" slot="end"
                    class="battery-item-photo" style="z-index: 5; float: right;">
                    <ion-icon name="camera" [color]="item.ImageCount > 0 ? 'primary' : ''">
                    </ion-icon>
                    <span class="count">{{item.ImageCount || 0}}</span>
                </span>
                <span *ngIf="!isWeb && item.FieldDataType == 'video' && !preview" (click)="takePhoto($event)" slot="end"
                    class="battery-item-photo" style="z-index: 5; float: right;">
                    <ion-icon name="videocam" [color]="item.ImageCount > 0 ? 'primary' : ''"></ion-icon>
                    <span class="count">{{item.ImageCount || 0}}</span>
                </span>
                <span
                    *ngIf="isWeb && (item.FieldDataType == 'photo' || item.FieldDataType == 'video' || item.FieldDataType == 'file')"
                    (click)="fileUpload.click()" slot="end" class="battery-item-photo ion-margin-end"
                    style="z-index: 5;">
                    <ion-icon name="duplicate-outline" [color]="item.ImageCount > 0 ? 'primary' : ''">
                    </ion-icon>
                    <input style="display: none;" type="file" multiple class="file-input"
                        (change)="uploadFileWeb($event)" #fileUpload>
                </span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="item.FieldDataType == 'file'">
        <div class="ion-padding-top">
            <i class="fas fa-asterisk color-danger question-required" style="font-size: 1rem;"
                *ngIf="item.Required"></i>
            <div class="ion-padding-bottom photo-only">
                <span slot="start" slot="start" *ngIf="hasAttachments()" (click)="openAttachments()" class="battery-item-photo" style="float: left; margin-right: 1rem;">
                    <ion-icon name="attach-outline" style="transform: rotate(45deg);"></ion-icon>
                </span>
                <div class="photo-only-text">
                    {{ item.PromptText }}
                </div>
                <span *ngIf="!isWeb && !preview" (click)="selectFile($event)" slot="end" class="battery-item-photo"
                    style="z-index: 5; float: right; margin-right: 1rem;">
                    <ion-icon name="document-outline" [color]="item.ImageCount > 0 ? 'primary' : ''">
                    </ion-icon>
                    <span class="count">{{item.ImageCount || 0}}</span>
                </span>
                <span *ngIf="isWeb && item.FieldDataType != 'label'" (click)="fileUpload.click()" slot="end"
                    class="battery-item-photo ion-margin-end" style="z-index: 5;">
                    <ion-icon name="duplicate-outline" [color]="item.ImageCount > 0 ? 'primary' : ''">
                    </ion-icon>
                    <input style="display: none;" type="file" multiple class="file-input"
                        (change)="uploadFileWeb($event)" #fileUpload>
                </span>
            </div>
        </div>
    </ng-container>
    <div [class.ion-padding-bottom]="!isWeb"
        *ngIf="item.FieldDataType != 'photo' && item.FieldDataType != 'video' && item.FieldDataType != 'file'">
        <ng-container *ngIf="item.VendorListFK !== null">
            <ion-item class="custom-input-ion-item" #elementRef>
                <span slot="start" slot="start" *ngIf="hasAttachments()" (click)="openAttachments()" class="battery-item-photo">
                    <ion-icon name="attach-outline" style="transform: rotate(45deg);"></ion-icon>
                </span>
                <!-- <ion-radio-group [(ngModel)]="item.AuditData">
                <ion-button *ngFor="let i of item.AnswerOptions" size="default" color="light">
                    {{i.Name}}
                </ion-button>
            </ion-radio-group> -->

                <oms-multiple-options-picker [(model)]="item.AuditData" (modelChange)="onAnswerChange()"
                    [options]="options" [preview]="preview"></oms-multiple-options-picker>

                <span tabindex="1" (click)="takePhoto($event, true)" slot="end" class="battery-item-photo"
                    style="z-index: 5; margin-right: -2rem;" *ngIf="!isWeb && (item.Photo) && !preview">
                    <ion-icon name="image-outline" *ngIf="item.Photo && !item.Video">
                    </ion-icon>
                </span>
                <span tabindex="1" (click)="takePhoto($event)" slot="end" class="battery-item-photo" style="z-index: 5;"
                    *ngIf="!isWeb && (item.Photo || item.Video) && !preview">
                    <ion-icon name="camera" [color]="item.ImageCount > 0 ? 'primary' : ''"
                        *ngIf="item.Photo && !item.Video">
                    </ion-icon>
                    <ion-icon name="videocam" [color]="item.ImageCount > 0 ? 'primary' : ''"
                        *ngIf="!item.Photo && item.Video"></ion-icon>
                    <i class="fas fa-photo-video" *ngIf="item.Photo && item.Video"
                        [class.color-primary]="item.ImageCount > 0"></i>
                    <span class="count">{{item.ImageCount || 0}}</span>
                </span>
                <span *ngIf="isWeb && item.FieldDataType != 'label'" (click)="fileUpload.click()" slot="end"
                    class="battery-item-photo" style="z-index: 5;">
                    <ion-icon name="duplicate-outline" [color]="item.ImageCount > 0 ? 'primary' : ''">
                    </ion-icon>
                    <input style="display: none;" type="file" multiple class="file-input"
                        (change)="uploadFileWeb($event)" #fileUpload>
                </span>
            </ion-item>
        </ng-container>
        <ng-container *ngIf="item.VendorListFK === null && item.FieldDataType != 'label'">
            <ng-container [ngSwitch]="item.FieldDataType">
                <ion-item>
                    <span slot="start" slot="start" *ngIf="hasAttachments()" (click)="openAttachments()" class="battery-item-photo">
                        <ion-icon name="attach-outline" style="transform: rotate(45deg);"></ion-icon>
                    </span>
                    <ion-input tabindex="2" #ionInput *ngSwitchCase="'numeric'" [(ngModel)]="item.AuditData"
                        decimal-input inputmode="decimal" positive-numbers-only min="0" custom-max-length="10"
                        [placeholder]="preview ? '' : 'Tap to start typing'" (keyup)="onAnswerChange()"
                        [disabled]="preview">
                    </ion-input>

                    <ion-input *ngSwitchCase="'date'" value="{{ item.AuditData | date: 'MM/dd/yyyy' }}" [id]="id"
                        [disabled]="preview" [placeholder]="preview ? '' : 'Tap to select a date'">
                    </ion-input>
                    <ion-modal [attr.trigger]="id" size="auto" *ngSwitchCase="'date'" class="date-picker-modal" #ionModal>
                        <ng-template>
                            <ion-content>
                                <ion-datetime #ionDateTime presentation="date" [disabled]="preview"
                                    [(ngModel)]="item.AuditData" (ionChange)="onAnswerChange()" locale="en-US">
                                    <ion-buttons slot="buttons">
                                        <ion-button color="danger" (click)="ionDateTime.cancel(true);">Cancel</ion-button>
                                        <ion-button color="primary" (click)="onDateSelected(ionDateTime)">Done</ion-button>
                                    </ion-buttons>
                                </ion-datetime>
                            </ion-content>
                        </ng-template>
                    </ion-modal>


                    <ion-input tabindex="2" #ionInput *ngSwitchCase="'scan'" [(ngModel)]="item.AuditData"
                        maxlength="250" [placeholder]="preview ? '' : 'Tap to start typing'" (keyup)="onAnswerChange()"
                        [disabled]="preview">
                    </ion-input>
                    <span *ngSwitchCase="'photo'"></span>
                    <span (click)="clearDate(item)" slot="end" class="battery-item-photo clear-date"
                        *ngIf="item.FieldDataType == 'date' && item.AuditData && !preview">
                        <ion-icon name="close-outline"></ion-icon>
                    </span>
                    <ng-container *ngSwitchCase="'scan'">
                        <span *ngIf="!isWeb && !preview" (click)="scan(item, $event)" slot="end"
                            class="battery-item-photo" style="z-index: 5;" tabindex="1">
                            <ion-icon name="barcode" [color]="item.Data ? 'primary' : ''">
                            </ion-icon>
                        </span>
                    </ng-container>
                    <ion-button *ngSwitchCase="'battery'" size="normal" (click)="goToAssets()">Assets</ion-button>
                    <ion-button *ngSwitchCase="'assignassets'" size="normal" (click)="goToAssetSelection()"
                        [attr.color]="item.AuditData ? 'primary' : 'light'" [disabled]="preview">Assign Assets
                    </ion-button>
                    <ion-button *ngSwitchCase="'replaceassets'" size="normal" (click)="replaceAssets()"
                        [attr.color]="item.AuditData ? 'primary' : 'light'">Replace Assets</ion-button>
                    <ion-button *ngSwitchCase="'uploadtests'" size="normal" (click)="uploadBatteryTests()"
                        [attr.color]="item.AuditData ? 'primary' : 'light'">Upload Battery Tests</ion-button>
                    <ion-textarea auto-grow="true" tabindex="2" #ionInput *ngSwitchDefault [(ngModel)]="item.AuditData"
                        maxlength="1000" [placeholder]="preview ? '' : 'Tap to start typing'" (keyup)="onAnswerChange()"
                        [disabled]="preview">
                    </ion-textarea>
                    <span tabindex="1" (click)="takePhoto($event, true)" slot="end" class="battery-item-photo"
                        style="z-index: 5; margin-right: -2rem"
                        *ngIf="!isWeb && (item.FieldDataType != 'battery' && item.FieldDataType != 'assignassets' && item.FieldDataType != 'replaceassets' && (item.Photo)) && !preview">
                        <ion-icon name="image-outline" *ngIf="item.Photo">
                        </ion-icon>
                    </span>
                    <span
                        *ngIf="!isWeb && (item.FieldDataType != 'battery' && item.FieldDataType != 'assignassets' && item.FieldDataType != 'replaceassets' && (item.Photo || item.Video)) && !preview"
                        (click)="takePhoto($event)" slot="end" class="battery-item-photo" style="z-index: 5;"
                        tabindex="1">
                        <ion-icon name="camera" [color]="item.ImageCount > 0 ? 'primary' : ''"
                            *ngIf="item.Photo && !item.Video">
                        </ion-icon>
                        <ion-icon name="videocam" [color]="item.ImageCount > 0 ? 'primary' : ''"
                            *ngIf="!item.Photo && item.Video"></ion-icon>
                        <i class="fas fa-photo-video" *ngIf="item.Photo && item.Video"
                            [class.color-primary]="item.ImageCount > 0"></i>
                        <span class="count">{{item.ImageCount || 0}}</span>
                    </span>
                    <span *ngIf="isWeb && item.FieldDataType != 'label'" (click)="fileUpload.click()" slot="end"
                        class="battery-item-photo" style="z-index: 5;">
                        <ion-icon name="duplicate-outline" [color]="item.ImageCount > 0 ? 'primary' : ''">
                        </ion-icon>
                        <input style="display: none;" type="file" multiple class="file-input"
                            (change)="uploadFileWeb($event)" #fileUpload>
                    </span>
                </ion-item>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="showComment()">
            <ion-item class="custom-input-ion-item">
                <ion-textarea auto-grow="true" tabindex="2" [(ngModel)]="item.Comment" maxlength="1000"
                    [placeholder]="preview ? '' : ('Type in a comment for ' + item.PromptText)"
                    (keyup)="onAnswerChange()" [disabled]="preview">
                </ion-textarea>
            </ion-item>
        </ng-container>
    </div>
    <div *ngIf="showQuestionWebImages" class="questions-web-images">
        <div>
            <ion-note *ngIf="item.FirstName && item.LastName && item.LastUpdatedDate">
                Last updated at {{item.LastUpdatedDate | localdatetime}} by {{item.FirstName}} {{item.LastName}}
            </ion-note>
            <ion-note *ngIf="item.HasHistory" (click)="historyWeb()" class="question-history">
                History
            </ion-note>
        </div>
        <div class=questions-web-images-list #webImages>
            <div *ngFor="let image of item.Images" class="questions-web-images--item" (click)="openImageWeb(image)">
                <ion-badge *ngIf="image.AnnotationCount <= 0 && image.Index >= 0">{{image.Index + 1}}</ion-badge>
                <ion-badge *ngIf="image.AnnotationCount > 0" color="danger">{{image.Index + 1}}</ion-badge>
                <img [src]="image.ThumbnailUrl" />
            </div>
            <!-- <ng-container *ngIf="item.Images && item.Images.length > 0">
                <div *ngFor="let i of [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]" class="questions-web-images--item question-image-with-annotations">
                    <ion-badge color="danger">{{item.Images[0].Index}}</ion-badge>
                    <img [src]="item.Images[0].ThumbnailUrl" />
                </div>
            </ng-container> -->
        </div>
    </div>
</div>