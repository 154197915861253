import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AssetsComponent } from "./assets.component";
import { FacilitiesComponent } from "./facilities/facilities.component";
import { BatteriesComponent } from "./batteries/batteries.component";
import { FacilityComponent } from "./facility/facility.component";
import { AssetQuotesComponent } from "./asset-quotes/asset-quotes.component";
import { AssetSchedulingSelectionComponent } from "./asset-scheduling-selection/asset-scheduling-selection.component";
import { BatteryTestsComponent } from "./battery-tests/battery-tests.component";

const routes: Routes = [
  { path: "facilities/:id/assets/:assetId/highlight/:highlightId/:preview", component: AssetsComponent },
  { path: "facilities/:id/assets/:assetId/highlight/:highlightId", component: AssetsComponent },
    { path: "facilities/:id/assets/:assetId/:preview", component: AssetsComponent },
    { path: "facilities/:id/assets/fromJob/:checklistId/:assetId/:preview", component: AssetsComponent },
    { path: "facilities/:id/assets/:assetId/batteries/highlight/:highlightId/:preview", component: BatteriesComponent },
    { path: "facilities/:id/assets/:checklistId/:assetId/batteries/:preview", component: BatteriesComponent },
    { path: "facilities/:id/assets/fromJob/:checklistId/:assetId/highlight/:highlightId/:preview", component: AssetsComponent },
    { path: "facilities/:id/assets/fromJob/:checklistId/:assetId/:preview", component: AssetsComponent },
    { path: "facilities/:id/assets/fromJob/:checklistId/:preview/version/:version", component: AssetsComponent },
    { path: "facilities/:id/assets/fromJob/:checklistId/:preview", component: AssetsComponent },
    { path: "facilities/:id/assets/:preview/version/:version", component: AssetsComponent },
    { path: "facilities/:id/assets/version/:version", component: AssetsComponent },
    { path: "facilities/:id/assets/:preview", component: AssetsComponent },
    { path: "facilities/:id/assets", component: AssetsComponent },
    { path: "facilities/:id/quotes/:checklistId/highlight/:highlightId", component: AssetQuotesComponent },
    { path: "facilities/:id/batterytests/:checklistId/:assetId/version/:version", component: BatteryTestsComponent },
    { path: "facilities/:id/batterytests/:checklistId/:assetId", component: BatteryTestsComponent },
    { path: "facilities/:id/quotes/:checklistId/highlightbattery/:highlightBatteryId", component: AssetQuotesComponent },
    { path: "facilities/:id/editquote/:quoteId/:editQuote/:showDrafts", component: AssetQuotesComponent },
    { path: "facilities/:id/quotes/preview/:showDrafts", component: AssetQuotesComponent },
    { path: "facilities/:id/asset-scheduling/:assetIds/:index/version/:version", component: AssetSchedulingSelectionComponent },
    { path: "facilities/:id/asset-scheduling/:assetIds/:index", component: AssetSchedulingSelectionComponent },
    { path: "facilities/:id/asset-scheduling/:index", component: AssetSchedulingSelectionComponent },
    { path: "facilities/:id/quotes", component: AssetQuotesComponent },
    { path: "facility/:id", component: FacilityComponent },
    { path: "facilities", component: FacilitiesComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
export class AssetsRoutingModule { }
