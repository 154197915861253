import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
  constructor(
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let headers = await this.getHeaders();
    const authReq = req.clone({
      setHeaders: headers
    });

    return next.handle(authReq).toPromise();
  }

  private async getHeaders() {
    return {
      timezoneoffset: new Date().getTimezoneOffset().toString()
    };
  }
}
