import { Injectable } from "@angular/core";
import { User } from "src/app/data/entities/User";
import { UserConfigModel } from "../../models/user-config-model";
import { BrowserHelperService } from "../browser/browser-helper.service";
import { ConfigService } from "../config/config.service";
import { StorageWrapperService } from "../storage-wrapper/storage-wrapper.service";

@Injectable()
export class UserService {
  private static key: string = "oms-user-key";
  constructor(
    private browserHelperService: BrowserHelperService,
    private storage: StorageWrapperService,
    private configService: ConfigService) {
  }

  async getLoggedInUser(): Promise<User> {
    var user = JSON.parse(await this.storage.get(UserService.key)) as User;
    if(user) {
      user.Config = user.Config || ({} as UserConfigModel);
    }
    return user;
  }

  async setLoggedInUser(user: User) {
    await this.storage.set(UserService.key, JSON.stringify(user || null));
  }

  async isVendor() {
    const user = await this.getLoggedInUser();
    return user.IntCodeId == "S";
  }

  async isAdmin() {
    const user = await this.getLoggedInUser();
    return user.RoleId == 50 || user.RoleId == 90;
  }

  async canMaintainAssets() {
    if (await this.isVendor()) {
      return !(await this.isTechnician());
    }
    return true;
  }

  async isTechnician() {
    const user = await this.getLoggedInUser();
    const roleId = user?.RoleId;
    return roleId == 54 || roleId == 94;
  }

  async isVendorTechnician() {
    const user = await this.getLoggedInUser();
    const roleId = user?.RoleId;
    return roleId == 54 && user.IntCodeId == "S";
  }

  async getUserToken() {
    var userToken = window["userToken"];
    if ((userToken || "").length == 0) {
      let user = await this.getLoggedInUser();
      if (user) {
        userToken = user.Token;
      }
    }
    return userToken;
  }
}
