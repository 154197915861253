import { Component, Input } from "@angular/core";
import { ChecklistSectionModel, ChecklistQuestionModel } from "../checklist.models";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { ChecklistService } from "../checklist.service";
import * as _ from "lodash";
import { DateTimeHelperService } from "src/app/shared/services/datetime/datetime-helper.service";

@Component({
  selector: "question-media-selector",
  templateUrl: "question-media-selector.component.html"
})
export class QuestionMediaSelectorComponent {
  @Input()
  checklist: FacilityChecklist
  @Input()
  section: ChecklistSectionModel;
  @Input()
  item: ChecklistQuestionModel;

  constructor(
    private cameraHelperService: CameraHelperService,
    private checklistService: ChecklistService,
    private dateTimeHelperService: DateTimeHelperService,
  ) {

  }

  async takePhoto(event: Event): Promise<void> {
    event.preventDefault();
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist.Id,
      VendorViewId: this.item.VendorViewPK,
    });
    let image = await this.cameraHelperService.takePhoto(fileName);
    if (image) {
      this.item.ImageCount = (this.item.ImageCount || 0) + 1;
      this.item.ImageUpdatedDate = this.dateTimeHelperService.getUtcString();
      this.checklistService.updateFacilityChecklistNoSyncNeeded(this.checklist);
    }
  }


  async takeVideo(event: Event): Promise<void> {
    event.preventDefault();
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist.Id,
      VendorViewId: this.item.VendorViewPK,
    });
    let image = await this.cameraHelperService.takeVideo(fileName);
    if (image) {
      this.item.ImageCount = (this.item.ImageCount || 0) + 1;
      this.item.ImageUpdatedDate = this.dateTimeHelperService.getUtcString();
      this.checklistService.updateFacilityChecklistNoSyncNeeded(this.checklist);
    }
  }
}
