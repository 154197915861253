import { NgModule } from "@angular/core";
import { RegisterRoutingModule } from "./register-routing.module";
import { RegisterService } from "./register.service";
import { TokenConfirmationComponent } from "./confirmation/token-confirmation.component";
import { SharedModule } from "../shared/shared.module";
import { LoginComponent } from "./login/login.component";
import { TermsComponent } from "./confirmation/terms.component";
import { NewAccountConfirmationComponent } from "./new-account-confirmation/new-account-confirmation.component";
import { NewAccountComponent } from "./new-account/new-account.component";
  
@NgModule({
    imports: [
        SharedModule.forRoot(),
        RegisterRoutingModule,
    ],
    declarations: [
        NewAccountComponent,
        TokenConfirmationComponent,
        NewAccountConfirmationComponent,
        TermsComponent,
        LoginComponent
    ],
    entryComponents: [TermsComponent],
    providers : [
        RegisterService
    ]
})
export class RegisterModule { }

