<ion-content>
  <ng-container *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ng-container>
  <ion-list class="cart-site-items" *ngIf="!loading">
    <h3 class="ion-text-center">
      Items ({{assetsToSubmit.length}})
    </h3>
    <ion-item *ngFor="let item of assetsToSubmit">
      <ion-label>
        <ion-note *ngIf="item.AssetPathString" (click)="goToAsset(item.Asset)">
          {{item.AssetPathString}}<span *ngIf="item.Battery">/{{item.Asset.Description}}</span>
        </ion-note>
        <h2 class="cart-item-title">
          <div (click)="goToAsset(item.Asset)" *ngIf="!item.Battery">
            {{item.Asset.Description}}
          </div>
          <div *ngIf="item.Battery" class="cart-item-battery-model">
            <i class="far fa-car-battery"></i>&nbsp; {{item.Battery.Position}} - {{item.Battery.BatteryTypeName}}
          </div>
          <div class="cart-item-options">
            <i class="far fa-minus" (click)="itemDown(item)"></i>
            <ion-badge color="light">
              {{item.Quantity}}
            </ion-badge>
            <i class="far fa-plus" (click)="itemUp(item)"></i>
            <span slot="end" class="fas fa-trash color-danger" (click)="deleteItem(item)"></span>
          </div>
        </h2>
        <div *ngIf="item.BatteryModel && !item.Battery" class="cart-item-battery-model">
          {{item.BatteryModel}}
        </div>
        <div class="checklist-questions-container" *ngIf="item.SelectedImages && item.SelectedImages.length > 0">
          <ion-grid>
            <ion-row>
              <ion-col size="3" *ngFor="let imageId of item.SelectedImages; let index = index">
                <div class="gallery-image-web">
                  <img [src]="getImageUrl(imageId)" (click)="openImage(imageId)" />
                  <ion-button size="small" (click)="deleteImage(item, index, imageId)">
                    <ion-icon name="trash-outline"></ion-icon>
                  </ion-button>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
  <div class="shopping-cart-divider" *ngIf="!loading"></div>
  <div class="ion-margin-start ion-margin-end">
    <ng-container *ngIf="!loading">
      <!-- <ion-label>Warranty</ion-label>
      <div>
        <ion-toggle [(ngModel)]="warranty"></ion-toggle>
      </div> -->
      <ion-label>Request Notes</ion-label>
      <ion-textarea [(ngModel)]="notes" maxlength="5000" placeholder="Add quote request notes here"
        class="input-with-borders" (keyup)="notesChanged()"></ion-textarea>
      <br />
      <ion-label>Tags <ion-note>(only visible to your company)</ion-note>
      </ion-label>
      <ion-textarea [(ngModel)]="description" maxlength="5000" class="input-with-borders"
        placeholder='Add tags to locate quote requests easily "2020 budget"' (keyup)="notesChanged()"></ion-textarea>
      <br />
    </ng-container>
    <ng-container
      *ngIf="existingFacilityQuote != null && existingFacilityQuote.Documents && existingFacilityQuote.Documents.length > 0">
      <div>Previously added files</div>
      <ion-list class="ion-margin-bottom">
        <ion-item *ngFor="let item of existingFacilityQuote.Documents; let i = index">
          <ion-label (click)="downloadPreviouslyAdded(item)">
            {{ item.Name }}
          </ion-label>
          <ion-button (click)="deletePreviouslyAdded(i)">
            <ion-icon name="trash"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </ng-container>
    <multi-file-upload [label]="'Add Files to Quote Request'"></multi-file-upload>
  </div>
  <div class="cart-site-walk-summary-container" *ngIf="!loading && facility && facility.SiteWalkEnabled">
    <ion-item lines="none">
      <ion-label>
        Add Site Walk
        <div class="cart-site-walk-last-updated" *ngIf="siteWalkSummary && siteWalkSummary.lastUpdatedDate">
          Updated: {{siteWalkSummary.lastUpdatedDate | localdatetime}}
        </div>
      </ion-label>
      <ion-toggle [(ngModel)]="includeSiteWalk" slot="end" [disabled]="!enableSiteWalk"></ion-toggle>
      <ion-button (click)="goToSiteWalk()" color="light" size="small" slot="end">
        View
      </ion-button>
    </ion-item>
  </div>
  <ng-container *ngIf="canAskApproval && submittable">
    <div class="ion-padding">
      <ion-label>Ask Location Manager(s) for approval</ion-label>
      <div>
        <ion-toggle [(ngModel)]="requestApproval"></ion-toggle>
      </div>
    </div>
  </ng-container>
  <oms-bottom-trademark *ngIf="!loading"></oms-bottom-trademark>
</ion-content>