import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { environment } from "src/environments/environment";
import { ConfigService } from "../shared/services/config/config.service";

@Component({
  selector: "web-app-component",
  template: "<div></div>"
})
export class WebAppShowComponent implements OnInit {
  public static BaseUrl: string;
  public static IsWebAppIFrame: boolean = false;
  public static IFrameBaseUrl: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService
  ) {
  }


  async ngOnInit() {
    var rootURL = this.route.snapshot.paramMap.get("rootURL").split("{{slash}}").join("/");
    environment.apiUrl = rootURL;

    window["userToken"] = this.route.snapshot.params["token"] || null;
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.parent.postMessage({ message: "enable-back-button-web" }, "*")
      }
    })
  }
}
