<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      {{title}} {{ asset.SiteAssetTypeDescription }}
    </ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">Close</ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="ion-margin-bottom ion-text-center">
    <!-- {{ asset.Description }} -->
  </div>
  <ion-item *ngIf="containers.length > 1" class="custom-input-ion-item">
    <ion-note position="fixed" slot="start" (click)="ionSelect.open()">
      Belongs To
    </ion-note>
    <ion-select [(ngModel)]="asset.ParentId" okText="Okay" cancelText="Dismiss" #ionSelect [disabled]="preview">
      <ion-select-option [value]="item.Id != null ? item.Id : ''" *ngFor="let item of containers">{{ item.Description }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item class="custom-input-ion-item">
    <ion-note position="fixed" slot="start" (click)="ionInput.setFocus()">
      Name
    </ion-note>
    <ion-input [(ngModel)]="asset.Description" maxlength="50" [placeholder]="asset.SiteAssetTypeDescription" #ionInput
      [disabled]="preview">
    </ion-input>
  </ion-item>
  <ng-container *ngFor="let item of asset.Answers">
    <asset-item-data-answer [facility]="facility" [asset]="asset" [item]="item" [memberLookup]="memberLookup"
      [checklistId]="checklistId" [preview]="preview">
    </asset-item-data-answer>
  </ng-container>
  <ion-item *ngIf="asset.HasBatteries && asset.Batteries.length == 0">
    <ion-checkbox [(ngModel)]="asset.BatteryDetailsEnabled" [disabled]="preview"></ion-checkbox>
    <ion-note position="fixed" slot="end">
      Insert Batteries (Scan SNs...)
    </ion-note>
  </ion-item>
  <ion-item *ngIf="asset.HasBatteries && asset.Batteries.length > 0">
    <ion-checkbox [(ngModel)]="asset.DeleteBatteryDetails" [disabled]="preview"></ion-checkbox>
    <ion-note position="fixed" slot="end">
      Delete Batteries (Scan SNs...)
    </ion-note>
  </ion-item>
  <ion-item *ngIf="asset.HasBatteries && asset.BatteryDetailsEnabled && asset.Batteries.length > 0 && !replace">
    <ion-button expand="block" (click)="goToBatteryDetails(asset)">
      Batteries (Scan SNs...)
      <ion-icon name="battery-charging"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-item *ngIf="asset.BatteryTestEnabled && checklistId > 0 && !preview">
    <ion-button expand="block" (click)="goToBatteryTests(asset)">
      Upload Battery Tests
      <ion-icon name="cloud-upload-outline" slot="end"></ion-icon>
    </ion-button>
  </ion-item>

  <ng-container *ngFor="let item of asset.Assets">
    <ion-card *ngIf="!item.OneToMany" style="margin: 1rem 0;">
      <ion-card-header (click)="toggleAsset(item)" class="ion-text-left">
        <ion-card-subtitle>
          <ion-label class="w-100">
            <span>
              {{item.SiteAssetTypeDescription}}
            </span>
            <ion-icon name="arrow-dropdown" slot="start" class="ion-float-left" *ngIf="!item.Expand"></ion-icon>
            <ion-icon name="arrow-dropup" slot="start" class="ion-float-left" *ngIf="item.Expand">
            </ion-icon>
            <ion-button (click)="subAssetMoreOptions(item, $event)" size="small" class="sub-asset-options-btn"
              *ngIf="subAssetHasMoreOptions(item) && !preview">
              &nbsp;<i class="far fa-ellipsis-v"></i>&nbsp;
            </ion-button>
            <asset-heatmap-indicator [facility]="facility" [asset]="item" class="float-right"></asset-heatmap-indicator>
          </ion-label>
        </ion-card-subtitle>
      </ion-card-header>
      <ion-card-content *ngIf="item.Expand" class="ion-no-padding">
        <ng-container *ngFor="let answer of item.Answers">
          <asset-item-data-answer [facility]="facility" [asset]="item" [item]="answer" [memberLookup]="memberLookup"
            [checklistId]="checklistId" [preview]="preview">
          </asset-item-data-answer>
        </ng-container>
        <ion-item *ngIf="item.HasBatteries && item.Batteries.length == 0 && !preview">
          <ion-checkbox [(ngModel)]="item.BatteryDetailsEnabled"></ion-checkbox>
          <ion-note position="fixed" slot="end">
            Insert Batteries (Scan SNs...)
          </ion-note>
        </ion-item>
        <ion-item *ngIf="item.HasBatteries && item.Batteries.length > 0 && !preview">
          <ion-checkbox [(ngModel)]="item.DeleteBatteryDetails"></ion-checkbox>
          <ion-note position="fixed" slot="end">
            Delete Batteries (Scan SNs...)
          </ion-note>
        </ion-item>
        <ion-item *ngIf="item.HasBatteries && item.BatteryDetailsEnabled && item.Batteries.length > 0 && !replace">
          <ion-button expand="block" (click)="goToBatteryDetails(item)">
            Batteries (Scan SNs...)
            <ion-icon name="battery-charging"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item *ngIf="asset.BatteryTestEnabled && checklistId > 0 && !preview">
          <ion-button expand="block" (click)="goToBatteryTests(asset)">
            Upload Battery Tests
            <ion-icon name="cloud-upload-outline" slot="end"></ion-icon>
          </ion-button>
        </ion-item>

      </ion-card-content>
    </ion-card>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="addAsset()" color="primary" checked>
        <ion-label *ngIf="!preview">{{title}}</ion-label>
      </ion-segment-button>
      <ion-segment-button (click)="dismissModal()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>