import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { ChecklistQuestionAttachmentModel } from "../checklist.models";
@Component({
  selector: "question-attachments",
  templateUrl: "question-attachments.component.html"
})
export class QuestionAttachmentsComponent implements OnInit {
  @Input()
  attachments: ChecklistQuestionAttachmentModel[] = [];
  files: ChecklistQuestionAttachmentModel[] = [];
  images: ChecklistQuestionAttachmentModel[] = [];
  isNative: boolean = false;

  private token: string;
  constructor(
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    this.images = this.attachments.filter(this.isImage);
    this.files = this.attachments.filter(a => !this.isImage(a));
    this.token = await this.userService.getUserToken();
  }

  getUrl(item: ChecklistQuestionAttachmentModel) {
    return item.DownloadUrl  + `?mobile-app-token=${this.token}`;
  }
  
  async open(item: ChecklistQuestionAttachmentModel) {
    this.browserHelperService.openUrl(await this.getUrl(item));
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  private isImage(item: ChecklistQuestionAttachmentModel) {
    const imageExtensions = ["jpg", "jpeg", "png", "svg"];
    const lowerName = item.FileName.toLowerCase();
    return imageExtensions.some(x => lowerName.endsWith(x));
  }
}
