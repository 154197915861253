<oms-header title="Image Transfer Details">
  <ion-back-button defaultHref="/login"></ion-back-button>
</oms-header>
<ion-content>
  <ion-card>
    <div class="ion-text-center ion-padding-top">
      <i class="fas fa-upload color-gray" style="font-size: 64px"></i>
      <ion-badge *ngIf="pendingCount == 0" style="position: absolute;" color="success">{{pendingCount}}
      </ion-badge>
      <ion-badge *ngIf="pendingCount > 0" style="position: absolute;" color="warning">{{pendingCount}}
      </ion-badge>
    </div>
    <ion-card-header class="ion-text-center">
      <ion-card-title>Images to Upload</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="ion-text-center">
        You have {{pendingCount}} images to upload to OMS. This will happen automatically.
      </div>
      <div class="ion-text-center ion-padding-top" *ngIf="pendingCount > 0">
        <ion-button shape="round" fill="outline" class="w-100" (click)="downloadTaken()">
          <i class="far fa-download"></i> Download
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <div class="ion-text-center ion-padding-top">
      <i class="fal fa-images color-gray" style="font-size: 64px"></i>
      <ion-badge style="position: absolute;">{{uploadedCount}}
      </ion-badge>
    </div>
    <ion-card-header class="ion-text-center">
      <ion-card-title>Images Uploaded</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="ion-text-center">
        You have {{uploadedCount}} images on your phone that have been uploaded to OMS.
      </div>
      <div class="ion-text-center ion-padding-top" *ngIf="uploadedCount > 0">
        <ion-button shape="round" fill="outline" class="w-100" (click)="downloadUploaded()">
          <i class="far fa-download"></i> Download
        </ion-button>
        <ion-button shape="round" fill="outline" class="w-100" color="warning" (click)="moveUploadedToTrash()">
          <i class="far fa-trash"></i> Move to Trash
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <div class="ion-text-center ion-padding-top">
      <i class="far fa-trash color-gray" style="font-size: 64px"></i>
      <ion-badge style="position: absolute;" color="danger">{{trashCount}}
      </ion-badge>
    </div>
    <ion-card-header class="ion-text-center">
      <ion-card-title>Trash</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="ion-text-center">
        You have {{trashCount}} images in Trash.
      </div>
      <div class="ion-text-center ion-padding-top" *ngIf="trashCount > 0">
        <ion-button shape="round" fill="outline" class="w-100" (click)="downloadTrash()">
          <i class="far fa-download"></i> Download
        </ion-button>
        <ion-button shape="round" fill="outline" class="w-100" color="danger" (click)="emptyTrash()">
          Empty Trash
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>