<div class="battery-test-form">
  <h1>Configuration</h1>

  <table class="battery-test-form--configuration">
    <tr>
      <td class="header">
        Battery Model
      </td>
      <td>
        <oms-group-select tabindex="2" #omsGroupSelect [(model)]="data.BatteryTest.BatteryId" [title]="'Select Battery'"
          (modelChange)="onBatteryChange()" [options]="data.BatteryList" [(text)]="data.BatteryTest.BatteryText"
          defaultText='Select Battery'>
        </oms-group-select>
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr>
      <td class="header">
        Battery Type
      </td>
      <td>
        <select [(ngModel)]="data.BatteryTest.BatteryType">
          <option value="VRLA">VRLA</option>
          <option value="NiCd">NiCd</option>
          <option value="LiIon">LI-Ion</option>
          <option value="VLA">VLA</option>
        </select>
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr>
      <td class="header">
        Date Code
      </td>
      <td>
        <input type="text" [(ngModel)]="data.BatteryTest.DateCode">
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <!-- <tr *ngIf="isVRLALike()">
      <td class="header">
        Test Type
      </td>
      <td>
        <select [(ngModel)]="data.BatteryTest.TestTypeId" (ngModelChange)="onTestTypeChange()">
          <option *ngFor="let item of data.TestTypeList" [value]="item.Value">{{item.Text}}</option>
        </select>
      </td>
    </tr>
    <tr class="divider-row" *ngIf="isVRLALike()">
      <td colspan="3"></td>
    </tr> -->
    <tr>
      <td class="header">
        Temperature
      </td>
      <td>
        <select [(ngModel)]="data.BatteryTest.TempType" (ngModelChange)="onTemperatureTypeChange()">
          <option *ngFor="let item of data.TempTypeList" [value]="item.Value">{{item.Text}}</option>
        </select>
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr *ngIf="isVRLALike()">
      <td class="header">
        Battery Voltage Measurement
      </td>
      <td>
        <select [(ngModel)]="data.BatteryTest.ChargeType" (ngModelChange)="onChargeTypeChange()">
          <option *ngFor="let item of data.ChargeTypeList" [value]="item.Value">{{item.Text}}</option>
        </select>
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr>
      <td colspan="3">
        <select [(ngModel)]="data.BatteryTest.CalcType" style="width: 96.5%">
          <option *ngFor="let item of data.CalcTypeList" [value]="item.Value">{{item.Text}}</option>
        </select>
      </td>
    </tr>
    <tr class="divider-row" *ngIf="isVRLALike()">
      <td colspan="3"></td>
    </tr>
  </table>

  <table class="battery-test-form--configuration" *ngIf="isVRLALike() && isCalcTypeByReference()">
    <tr>
      <td class="header">
        Voltage Range
      </td>
      <td>
        <div>Low</div>
        <input [(ngModel)]="data.BatteryTest.VoltLow" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
      <td>
        <div>High</div>
        <input [(ngModel)]="data.BatteryTest.VoltHigh" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr>
      <td class="header">
        {{getTestTypeLabel()}} Range
      </td>
      <td>
        <div>Low</div>
        <input [(ngModel)]="data.BatteryTest.MeasLow" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
      <td>
        <div>High</div>
        <input [(ngModel)]="data.BatteryTest.MeasHigh" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr>
      <td class="header">
        Temperature Range ({{data.BatteryTest.TempType}})
      </td>
      <td>
        <div>Low</div>
        <input [(ngModel)]="data.BatteryTest.TempLow" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
      <td>
        <div>High</div>
        <input [(ngModel)]="data.BatteryTest.TempHigh" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
    </tr>
  </table>

  <table class="battery-test-form--configuration" *ngIf="isVLALike() && isCalcTypeByReference()">
    <tr>
      <td class="header">
        Specific Gravity Range
      </td>
      <td>
        <div>Low</div>
        <input [(ngModel)]="data.BatteryTest.GravityLow" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
      <td>
        <div>High</div>
        <input [(ngModel)]="data.BatteryTest.GravityHigh" decimal-input inputmode="decimal" positive-numbers-only
          min="0" custom-max-length="10" />
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr>
      <td class="header">
        Electrolyte Temp ({{data.BatteryTest.TempType}})
      </td>
      <td>
        <div>Low</div>
        <input [(ngModel)]="data.BatteryTest.TempLow" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
      <td>
        <div>High</div>
        <input [(ngModel)]="data.BatteryTest.TempHigh" decimal-input inputmode="decimal" positive-numbers-only min="0"
          custom-max-length="10" />
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr>
      <td class="header" colspan="2">
        AC Ripple Amps
      </td>
      <td>
        <input [(ngModel)]="data.BatteryTest.ACRippleVolts" decimal-input inputmode="decimal" positive-numbers-only
          min="0" custom-max-length="10" />
      </td>
    </tr>
    <tr class="divider-row">
      <td colspan="3"></td>
    </tr>
    <tr>
      <td class="header" colspan="2">
        DC Current Amps
      </td>
      <td>
        <input [(ngModel)]="data.BatteryTest.DCCurrentAmps" decimal-input inputmode="decimal" positive-numbers-only
          min="0" custom-max-length="10" />
      </td>
    </tr>
  </table>


  <table class="battery-test-form--configuration">
    <tr class="divider-row">
      <td></td>
    </tr>
    <tr>
      <td>
        <div>
          Description
        </div>
        <textarea style="width: 100%" [(ngModel)]="data.BatteryTest.Description"></textarea>
      </td>
    </tr>
    <tr class="divider-row">
      <td></td>
    </tr>
    <tr>
      <td>
        <div>
          Issues
        </div>
        <textarea style="width: 100%" [(ngModel)]="data.BatteryTest.Issues"></textarea>
      </td>
    </tr>
  </table>
  <h1>Test Data</h1>
  <battery-test-data [data]="data" (onTestTypeChangeOutput)="onTestTypeChangeOutput($event)"></battery-test-data>
  <battery-test-no-tester-form [data]="data" *ngIf="data && data.Data.length == 0">
  </battery-test-no-tester-form>
</div>