<oms-header [title]="'Job ID:' + jobModel?.ChecklistId" [subtitle]="'Location: ' + facility?.Name"
    [subtitle2]="'Project: ' + jobModel?.ProjectName">
    <ion-back-button defaultHref="/facilities"></ion-back-button>
</oms-header>
<ion-content class="my-work-details-container" *ngIf="!loading">
    <work-data [jobModel]="jobModel" [facility]="facility" [fromMap]="fromMap" [galleryCount]="galleryCount" [hasUpdates]="hasUpdates" [correctionsNeeded]="correctionsNeeded"></work-data>
</ion-content>
<ion-content *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
        </ion-label>
    </ion-item>
</ion-content>