import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TokenConfirmationComponent } from "./confirmation/token-confirmation.component";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { LoginComponent } from "./login/login.component";
import { NewAccountConfirmationComponent } from "./new-account-confirmation/new-account-confirmation.component";
import { NewAccountComponent } from "./new-account/new-account.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "login-with-email/:email", component: LoginComponent },
  { path: "token-confirmation", component: TokenConfirmationComponent },
  { path: "new-account-confirmation", component: NewAccountConfirmationComponent },
  { path: "new-account/:email", component: NewAccountComponent },
  { path: "new-account", component: NewAccountComponent }
];

@NgModule({
  imports: [FormsModule, RouterModule.forChild(routes), IonicModule.forRoot({
    mode: "md"
  })],
  exports: [RouterModule]
})
export class RegisterRoutingModule {}
