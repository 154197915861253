import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
  AfterViewInit
} from "@angular/core";

@Directive({
  selector: "[custom-max-length]" // Attribute selector
})
export class MaxLengthDirective implements AfterViewInit {
  @Output() input: EventEmitter<string> = new EventEmitter<string>();
  @Input("custom-max-length") maxLength: number;

  constructor(public elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.setAttribute("maxlength", this.maxLength);
  }

  @HostListener("keypress", ["$event"])
  keyEvent(event) {
    let element = event.srcElement;
    if (element.tagName !== "INPUT") {
      return;
    }

    if (element.value) {
      if(element.value.length == this.maxLength){
        event.preventDefault();
      } else if (element.value.length > this.maxLength){
        element.value = element.value.slice(0, this.maxLength);
      }
    }
  }
}
