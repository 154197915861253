<oms-header [title]="'Quote Request ' + (existingFacilityQuote?.Id || '')"
  [subtitle]="'Location: ' + (facility?.Name || '')">
  <ion-back-button defaultHref="/facilities" *ngIf="!quotePreviewWeb && !editQuoteWeb"></ion-back-button>
</oms-header>
<div style="padding: 0.25rem 0" *ngIf="!loading">
  <ion-segment mode="ios" [value]="mode" (ionChange)="segmentChanged($event)">
    <ion-segment-button value="select">
      <ion-label>Select Assets</ion-label>
    </ion-segment-button>
    <ion-segment-button value="submit">
      <ion-label>Preview</ion-label>
    </ion-segment-button>
    <ion-segment-button value="history" *ngIf="showDrafts">
      <ion-label>Drafts</ion-label>
    </ion-segment-button>
  </ion-segment>
</div>
<ion-content *ngIf="!loading" #quoteContent>
  <asset-quote-asset-list [facility]="facility" [assets]="assets" [checklistId]="checklistId"
    [hidden]="mode != 'select'">
  </asset-quote-asset-list>
  <asset-quote-submit [facility]="facility" [assets]="assets" [checklistId]="checklistId" [mode]="mode"
    [submittable]="submittable" [existingFacilityQuote]="existingFacilityQuote" [editQuoteWeb]="editQuoteWeb"
    [hidden]="mode != 'submit'">
  </asset-quote-submit>
  <asset-quote-history [facility]="facility" (draftClick)="loadDataForQuote($event)" *ngIf="mode == 'history'">
  </asset-quote-history>
</ion-content>

<ion-content *ngIf="loading">
  <ion-list>
    <ion-item *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
      <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
    </ion-item>
  </ion-list>
</ion-content>

<ion-tab-bar slot="bottom" class="checklist-questions-tabs" *ngIf="!loading">
  <ng-container *ngIf="existingFacilityQuote && mode == 'submit'">
    <ion-tab-button (click)="assetQuoteSubmitComponent.save()">
      <span>Save&nbsp;</span>
      <ion-icon name="save-outline"></ion-icon>
    </ion-tab-button>
    <ion-tab-button (click)="assetQuoteSubmitComponent.submit()" *ngIf="submittable">
      <span>Submit Request&nbsp;</span>
      <ion-icon name="send"></ion-icon>
    </ion-tab-button>
    <ion-tab-button (click)="assetQuoteSubmitComponent.accept()"
      *ngIf="submittable && existingFacilityQuote.Key && existingFacilityQuote.StatusId == 6">
      <span>Accept Quote&nbsp;</span>
      <ion-icon name="checkmark-done-outline" color="success"></ion-icon>
    </ion-tab-button>
    <ion-tab-button (click)="assetQuoteSubmitComponent.cancel()"
      *ngIf="submittable && existingFacilityQuote.Key && existingFacilityQuote.Key.length > 0">
      <span>Cancel Request&nbsp;</span>
      <ion-icon name="close-outline" color="danger"></ion-icon>
    </ion-tab-button>
  </ng-container>
  <ng-container *ngIf="existingFacilityQuote && mode == 'select'">
    <ion-tab-button (click)="switchMode('submit')">
      <span>Next&nbsp;</span>
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </ion-tab-button>
  </ng-container>
</ion-tab-bar>