import { Component, Input } from "@angular/core";
import * as _ from "lodash";
import { ModalController } from "@ionic/angular";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { LocationJobHistoryModel } from "src/app/checklist/checklist.models";

@Component({
  selector: "work-job-history",
  templateUrl: "work-job-history.component.html"
})
export class WorkJobHistoryComponent {

  @Input()
  items: LocationJobHistoryModel[] = [];
  loading: boolean;

  constructor(
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService
  ) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  download(item: LocationJobHistoryModel) {
    this.browserHelperService.openUrl(item.DownloadUrl);
  }
}