<oms-header title="Data Transfer">
  <ion-back-button defaultHref="/login"></ion-back-button>
</oms-header>
<ion-content>
  <ion-card>
    <div class="ion-text-center ion-padding-top">
      <i class="fad fa-database color-gray" style="font-size: 64px"></i>
      <ion-badge *ngIf="pendingDataCount == 0" style="position: absolute;" color="success">{{pendingDataCount}}
      </ion-badge>
      <ion-badge *ngIf="pendingDataCount > 0" style="position: absolute;" color="warning">{{pendingDataCount}}
      </ion-badge>
    </div>
    <ion-card-header class="ion-text-center">
      <ion-card-title>Data Transfer</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="ion-text-center">
        You have {{pendingDataCount}} pending data batches to send to OMS
      </div>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <div class="ion-text-center ion-padding-top">
      <i class="fal fa-images color-gray" style="font-size: 64px"></i>
      <ion-badge *ngIf="pendingImageCount == 0" style="position: absolute;" color="success">{{pendingImageCount}}
      </ion-badge>
      <ion-badge *ngIf="pendingImageCount > 0" style="position: absolute;" color="warning">{{pendingImageCount}}
      </ion-badge>
    </div>
    <ion-card-header class="ion-text-center">
      <ion-card-title>Image Transfer</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="ion-text-center">
        You have {{pendingImageCount}} pending images to send to OMS
      </div>
      <a routerLink="/pending-image-details" class="ion-padding-top">
        <ion-button shape="round" fill="outline" class="w-100">
          More details
        </ion-button>
      </a>
    </ion-card-content>
  </ion-card>
  <oms-send-logs></oms-send-logs>
</ion-content>