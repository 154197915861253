import { Injectable } from "@angular/core";
import { Network } from '@ionic-native/network/ngx';
import { AppInfo } from "src/app/globals/AppInfo";
import { PubSubService } from "../pubsub/pub-sub.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ToastController } from "@ionic/angular";

@Injectable()
export class BrowserHelperService {
  constructor(
    private network: Network,
    private pubSub: PubSubService,
    private inAppBrowser: InAppBrowser,
    private toastController: ToastController
  ) { }

  isNativeApp() {
    return window.cordova != null;
  }

  isOffline(): boolean {
    if (this.isNativeApp()) {
      return this.network.type == 'none';
    } else {
      return !navigator.onLine;
    }
  }

  async isOfflineAndMessage(): Promise<boolean> {
    if (this.isOffline()) {
      let toast = await this.toastController.create({
        message: "You're offline",
        duration: 2000,
        color: "danger"
      });
      await toast.present();
      return true;
    }
    return false;
  }

  openUrl(url: string) {
    try {
      var platform = AppInfo.Platform;
      if (platform == "android") {
        navigator["app"].loadUrl(url, { openExternal: true });
      }
      else if (platform == 'ios') {
        let browser = this.inAppBrowser.create(url, "_system");
        browser.show();
      }
      else {
        window.open(url, '_blank');
      }
    } catch (err) {
      this.pubSub.$pub(EventsConstants.LOG_ERROR, err);
    }
  }
}
