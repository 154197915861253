import { Entity, Column, PrimaryColumn } from "typeorm";

@Entity("filesync")
export class FileSync {
  @PrimaryColumn()
  Id: number;
  @Column({ nullable: false })
  OriginalFileName: string;
  @Column({ nullable: false })
  NewFileName: string;
}