import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { environment } from "src/environments/environment";
import { FacilityService } from "../assets/facility.service";
import { RepositoryService } from "../data/db/repository.service";
import { LoaderHelperService } from "../shared/services/browser/loader-helper.service";
import { ConfigService } from "../shared/services/config/config.service";

@Component({
  selector: "web-app-quote-edit-component",
  template: "<div></div>"
})
export class WebAppQuoteEditComponent implements OnInit {
  public static BaseUrl: string;
  public static IsWebAppIFrame: boolean = false;
  public static IFrameBaseUrl: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private loaderHelperService: LoaderHelperService,
    private repositoryService: RepositoryService,
    private facilityService: FacilityService
  ) {
  }


  async ngOnInit() {
    var rootURL = this.route.snapshot.paramMap.get("rootURL").split("{{slash}}").join("/");
    environment.apiUrl = rootURL;

    window["userToken"] = this.route.snapshot.params["token"] || null;
    const siteKey = this.route.snapshot.params["siteKey"] || null;
    const quoteId = this.route.snapshot.params["quoteId"] || null;

    await this.loaderHelperService.on();
    await this.repositoryService.cleanDb();
    await this.facilityService.reloadMyFacilitiesAndAssets([siteKey]);
    await this.loaderHelperService.off();
    this.router.navigate([`/facilities/${siteKey}/editquote/${quoteId}/true/true`]);
  }
}
