import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../my-work.service";
import { AlertController, IonInput, IonSelect, IonTextarea, ModalController, ToastController } from "@ionic/angular";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { NewJobModel } from "../my-work.models";
import { LookupListGroup, SelectListItem } from "src/app/assets/assets.models";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { NewCustomerProjectLocationComponent } from "../new-customer-project-location/new-customer-project-location.component";

@Component({
  selector: "new-job",
  templateUrl: "new-job.component.html"
})
export class NewJobComponent implements OnInit {
  @Input()
  model: NewJobModel = { CustomerLocationProject: {} } as any;
  templateCategories: SelectListItem[] = [];
  templates: SelectListItem[] = [];
  loading: boolean = false;
  technicianList: LookupListGroup[] = [];
  templateCategoryVisible: boolean;
  submitting: boolean;

  @ViewChild("poNumberInput") poNumberInput: IonInput;
  @ViewChild("soNumberInput") soNumberInput: IonInput;
  @ViewChild("referenceNumberInput") referenceNumberInput: IonInput;
  @ViewChild("templateCategorySelectList") templateCategorySelectList: IonSelect;
  @ViewChild("templateSelectList") templateSelectList: IonSelect;
  @ViewChild("customerInput") customerInput: IonInput;
  @ViewChild("locationInput") locationInput: IonInput;
  @ViewChild("workOrderNumberInput") workOrderNumberInput: IonInput;
  @ViewChild("systemPartNumberInput") systemPartNumberInput: IonInput;
  @ViewChild("cabinetSerialNumberInput") cabinetSerialNumberInput: IonInput;
  @ViewChild("customerLocationProject") customerLocationProject: NewCustomerProjectLocationComponent;

  constructor(
    private myWorkService: MyWorkService,
    private toastController: ToastController,
    private router: Router,
    private userService: UserService,
    private modalController: ModalController,
    private alertController: AlertController,
    private browserHelperService: BrowserHelperService,
    private barcodeScannerHelperService: BarcodeScannerHelperService
  ) { }

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    this.loading = true;
    this.templateCategoryVisible = true;
    await this.loadData();
    this.loading = false;
  }

  focusPoNumber() {
    this.poNumberInput.setFocus();
  }

  focusSoNumber() {
    this.soNumberInput.setFocus();
  }

  focusReferenceNumber() {
    this.referenceNumberInput.setFocus();
  }

  focusTemplateCategorySelectList() {
    this.templateCategorySelectList.open();
  }

  focusTemplateSelectList() {
    this.templateSelectList.open();
  }

  focusCustomer() {
    this.customerInput.setFocus();
  }

  focusLocation() {
    this.locationInput.setFocus();
  }

  focusWorkOrderNumber() {
    this.workOrderNumberInput.setFocus();
  }

  focusSystemPartNumber() {
    this.systemPartNumberInput.setFocus();
  }

  focusCabinetSerialNumber() {
    this.cabinetSerialNumberInput.setFocus();
  }

  async scanPoNumber() {
    this.scan(() => this.focusPoNumber(), value => this.model.PONumber = value);
  }

  async scanSoNumber() {
    this.scan(() => this.focusSoNumber(), value => this.model.SONumber = value);
  }

  async scanReferenceNumber() {
    this.scan(() => this.focusReferenceNumber(), value => this.model.ReferenceNumber = value);
  }

  async scanCustomer() {
    this.scan(() => this.focusCustomer(), value => this.model.CustomerLocationProject.Customer = value);
  }

  async scanLocation() {
    this.scan(() => this.focusLocation(), value => this.model.CustomerLocationProject.Location = value);
  }

  async scanWorkOrderNumber() {
    this.scan(() => this.focusWorkOrderNumber(), value => this.model.WorkOrderNumber = value);
  }

  async scanSystemPartNumber() {
    this.scan(() => this.focusSystemPartNumber(), value => this.model.SystemPartNumber = value);
  }

  async scanCabinetSerialNumber() {
    this.scan(() => this.focusCabinetSerialNumber(), value => this.model.CabinetSerialNumber = value);
  }

  private async scan(handHeldCallback: () => void, scanCallback: (value: string) => {}) {
    if (await this.checkForHandHeldScanner()) {
      handHeldCallback();
    } else {
      const text = await this.barcodeScannerHelperService.scan();
      if (text) {
        scanCallback(text);
      }
    }
  }

  private async checkForHandHeldScanner() {
    if (!this.browserHelperService.isNativeApp()) {
      await this.notifyMessage(`If you have hand held scanner you can use it to scan now`);
      return true;
    }
    return false;
  }

  private async notifyMessage(message: string, color: "info" | "danger" = "info") {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: "bottom",
      duration: 3000,
      cssClass: "new-job-hand-held-scanner-toast"
    });
    await toast.present();
  }

  async templateCategoryChanged(templateCategoryId) {
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    try {
      this.templates = await this.myWorkService.getTemplates(templateCategoryId);
      if (this.templates.length == 1) {
        this.model.CustomerLocationProject.TemplateId = parseInt(this.templates[0].Value);
      }
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Work Types`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present()
    } finally { }
  }

  async createJob() {
    if (!(await this.validate())) return;
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    try {
      this.submitting = true;
      var response = await this.myWorkService.createJob(this.model);
      if (response.Success) {
        //Todo, refresh my jobs, then redirect to this jobId
        const checklistId = response.Data;

        this.myWorkService.loadJobsLightThenFull(() => {
          //Loaded light
        }, (errLight) => {
          //Error light
        }, () => {
          //Loaded full
          if (WebAppShowComponent.IsWebAppIFrame) {
            this.myWorkService.changeJobStatus(checklistId, 4, null).finally(() => {
              window.parent.postMessage({ message: "job-created", jobId: checklistId }, "*");
            });
          } else {
            this.router.navigate([`/mywork/work-details/${checklistId}/start/true`]);
          }
        }, (errFull) => {
          //Error full
        }).finally(() => {
          if (!WebAppShowComponent.IsWebAppIFrame) {
            this.modalController.dismiss();
          }
          this.submitting = false;
        });
      } else {
        this.submitting = false;
        this.notifyMessage(`There was an error while creating job: ${response.Message}`, "danger");
      }
    } catch {
      this.submitting = false;
      this.notifyMessage("There was an error while creating job", "danger");
    } finally {
    }
  }

  async dismissModal() {
    const alert = await this.alertController.create({
      header: "Are you sure you want to cancel?",
      buttons: [
        {
          text: "Yes, I am sure",
          handler: async () => {
            this.dismiss();
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        },
      ]
    });
    return await alert.present();
  }

  private async dismiss(): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "close-modal" }, "*");
    } else {
      await this.modalController.dismiss();
    }
  }
  private async validate(): Promise<boolean> {
    var message = null;
    const customerLocationProjectValidate = this.customerLocationProject.validate();
    if (!customerLocationProjectValidate.valid) {
      message = customerLocationProjectValidate.message;
    } else if ((this.model.CustomerLocationProject.TemplateId || 0) == 0) {
      message = "Work Type is required";
    }
    if (message) {
      await this.notifyMessage(message, "danger");
      return false;
    }
    return true;
  }

  private async loadData(): Promise<void> {
    try {
      this.templateCategories = await this.myWorkService.getQcTemplateCategories();
      if (this.templateCategories.length == 1) {
        this.model.CustomerLocationProject.TemplateCategoryId = this.templateCategories[0].Value;
        this.templateCategoryChanged(this.model.CustomerLocationProject.TemplateCategoryId);
        this.templateCategoryVisible = false;
      }
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Work Templates`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }
}