import { Component, OnInit } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import * as _ from "lodash";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";
import * as EmailValidator from "email-validator";
import { AssetQuoteService } from "src/app/assets/services/quotes/asset-quote.service";

@Component({
  selector: "add-quote-email",
  templateUrl: "add-quote-email.component.html"
})
export class AddQuoteEmailComponent implements OnInit {

  name: string;
  email: string;
  nameValid: boolean = true;
  emailValid: boolean = true;

  constructor(
    public modalController: ModalController,
    private pubSubService: PubSubService,
    private toastController: ToastController,
    private assetQuoteService: AssetQuoteService,
    private loaderHelperService: LoaderHelperService,
    private errorHandlerService: ErrorHandlerService,
    private browserHelperService: BrowserHelperService
  ) {
  }

  async ngOnInit() {
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  async addEmail() {
    if (!this.isDataValid()) {
      return;
    }
    if(await this.browserHelperService.isOfflineAndMessage()){
      return;
    }
    await this.loaderHelperService.on();
    try {
      let response = await this.assetQuoteService.addNewEmail({
        Id: null,
        MemberId: null,
        Email: this.email,
        Name: this.name,
        Selected: null
      });
      if (response && response.Success) {
        await this.assetQuoteService.setEmails(response.Items);
        await this.modalController.dismiss();
        let toast = await this.toastController.create({
          message: `Email was successfully added`,
          position: "bottom",
          duration: 2000
        });
        this.pubSubService.$pub(EventsConstants.SHOPPING_CART_EMAIL_ADDED);
        toast.present();
      } else {
        if (response.Message) {
          alert(response.Message);
        } else {
          alert("An error occurred");
        }
      }
    } catch (err) {
      this.errorHandlerService.showErrorMessage(err);
      return;
    } finally {
      await this.loaderHelperService.off();
    }
  }

  isDataValid() {
    this.emailValid = EmailValidator.validate(this.email);
    this.nameValid = (this.name || "").length > 0;
    return this.emailValid && this.nameValid;
  }
}
