import { assertPlatform, Component, OnInit, ViewChild } from "@angular/core";
import { AssetsService } from "../assets.service";
import * as _ from "lodash";
import { Facility } from "src/app/data/entities/Facility";
import { ActivatedRoute, Router } from "@angular/router";
import { AssetQuoteModel, LookupListItem, MemberLookup, SelectedAsset, SiteAssetType } from "../assets.models";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { InfiniteScrollModel } from "src/app/shared/models/infinite-scroll-model";
import { IonInfiniteScroll, ModalController } from "@ionic/angular";
import { ChecklistService } from "src/app/checklist/checklist.service";

@Component({
  selector: "asset-scheduling-selection",
  templateUrl: "asset-scheduling-selection.component.html"
})
export class AssetSchedulingSelectionComponent implements OnInit {
  facility: Facility;
  assets: AssetQuoteModel[] = [];
  filteredAssets: AssetQuoteModel[] = [];
  loading: boolean = false;
  assetTypes: SiteAssetType[] = [];
  assetType: number;
  lookupData: MemberLookup;
  itemIndex: number;
  quotePreviewWeb: boolean;
  facilityId: string;
  assetIds: string;
  checklistId: number;
  hasAssetConfig: boolean;
  onSelected: (items: SelectedAsset[]) => {};

  scrollModel: InfiniteScrollModel<AssetQuoteModel> = new InfiniteScrollModel<AssetQuoteModel>();
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

  query: string;

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    private router: Router,
    private checklistService: ChecklistService,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    this.loading = true;
    this.facilityId = this.route.snapshot.params["id"] || this.facilityId;
    this.assetIds = this.assetIds || this.route.snapshot.params["assetIds"] || "";
    this.itemIndex = this.route.snapshot.params["index"];
    this.facility = await this.assetsService.getFacilityWithAssets(this.facilityId);
    this.hasAssetConfig = (await this.assetsService.getConfiguration(this.facility.CustomerId) || []).length > 0;
    var assetTypesTmp = [];
    await this.assetRecursive(this.facility.Assets, 0, async (asset: SiteAsset, level: number) => {
      if (asset.Quotable && !asset.Deleted) {
        let assetPathString = null;
        let assetPath = await this.assetsService.getAssetPath(this.facility, asset.Id);
        let tmp = [];
        for (let j = 0; j < assetPath.length - 1; j++) {
          tmp.push(assetPath[j].Description);
        }
        if (tmp.length > 0) {
          assetPathString = tmp.join("/");
        }
        let selected = this.assetIds.indexOf(asset.Id) >= 0;
        this.assets.push({
          Asset: asset,
          Battery: null,
          BatteryModel: null,
          Selected: selected,
          Level: level,
          AssetPathString: assetPathString,
          Quantity: 0,
          OldQuantity: 0,
          Hidden: false,
          SelectedImages: [],
          BatteryTests: 0
        });
      }
    });
    for (let asset of this.assets) {
      if (!_.find(assetTypesTmp, i => i.Id == asset.Asset.SiteAssetTypeId)) {
        assetTypesTmp.push({
          Description: asset.Asset.SiteAssetTypeDescription,
          Id: asset.Asset.SiteAssetTypeId
        } as SiteAssetType);
      }
    }
    this.assetTypes = assetTypesTmp;
    this.filteredAssets = this.assets;
    this.scrollModel.init(this.filteredAssets, this.infiniteScroll);
    this.loading = false;
    setTimeout(() => {
      this.scrollModel.initInfiniteScrollElement(this.infiniteScroll);
    }, 1000);
  }

  startSearch() {
    this.onFilterChange();
  }

  onFilterChange() {
    this.filteredAssets = [];
    var queryLowerCase = (this.query || "").toLowerCase();
    for (let asset of this.assets) {
      let hidden = false;
      if (!hidden && this.assetType != null) {
        hidden = this.assetType != asset.Asset.SiteAssetTypeId;
      }

      if (!hidden && this.query) {
        if (asset.Battery) {
          hidden = (asset.BatteryModel || "").toLowerCase().indexOf(queryLowerCase) < 0;
        } else if (asset.Asset) {
          hidden = (asset.Asset.Description || "").toLowerCase().indexOf(queryLowerCase) < 0 && (asset.AssetPathString || "").toLowerCase().indexOf(queryLowerCase) < 0;
        }
      }

      asset.Hidden = hidden;
      if (!asset.Hidden) {
        this.filteredAssets.push(asset);
      }
    }
    this.scrollModel.init(this.filteredAssets, this.infiniteScroll);
  }

  toggleAsset(assetQuote: AssetQuoteModel) {
    assetQuote.Selected = !assetQuote.Selected;
  }

  async goToAssets() {
    try {
      await this.modalController.dismiss();
    } catch { }
    this.router.navigate([`/facilities/${this.facility.Id}/assets`]);
  }

  async select() {
    var selectedAssets: SelectedAsset[] = [];
    for (let item of this.assets) {
      if (item.Asset && item.Asset.Deleted) {
        continue;
      }
      if (item.Selected) {
        selectedAssets.push({
          Name: (item.AssetPathString ? (item.AssetPathString + "/") : "") + item.Asset.Description,
          Id: item.Asset.Id,
          ReplacedWithId: item.Asset.ReplacedWithId,
          ReplacedDate: item.Asset.ReplacedDate,
          BatteryTests: item.BatteryTests
        });
      }
    }
    for (let item of this.assets) {
      item.Selected = false;
    }
    if (this.onSelected) {
      if (this.checklistId) {
        let checklist = await this.checklistService.getChecklistById(this.checklistId);
        checklist.JobDataModel = JSON.parse(checklist.JobData);
        checklist.JobDataModel.Assets = selectedAssets;
        checklist.JobData = JSON.stringify(checklist.JobDataModel);
        await this.checklistService.saveChecklist(checklist);
      }
      this.onSelected(selectedAssets);
      this.modalController.dismiss();
    } else {
      window.parent.postMessage({ message: "work-queue-select-assets", selectedAssets, index: this.itemIndex }, "*")
    }
  }

  cancel() {
    window.parent.postMessage({ message: "close-modal" }, "*")
    this.modalController.dismiss();
  }

  selectedCount() {
    return this.assets.filter(x => x.Selected).length;
  }

  toggleSelected(item: AssetQuoteModel) {
    item.Selected = !item.Selected;
  }

  private async assetRecursive(siteAssets: SiteAsset[], level: number, callback: (item: SiteAsset, level: number) => void): Promise<void> {
    for (let asset of siteAssets) {
      await callback(asset, level);
      await this.assetRecursive(asset.Assets, level + 1, callback);
    }
  }
}