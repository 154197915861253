import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { Facility } from "src/app/data/entities/Facility";
import * as _ from "lodash";
import { ChecklistComponent } from "../checklist.component";
import { ChecklistSectionModel } from "../checklist.models";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { Subscription } from "rxjs";

@Component({
  selector: "checklist-list-view",
  templateUrl: "list-view.component.html"
})
export class ListViewComponent implements OnInit, OnDestroy {
  @Input()
  facility: Facility;
  @Input()
  checklist: FacilityChecklist;
  @Input()
  preview: boolean;
  section: ChecklistSectionModel;
  sections: ChecklistSectionModel[] = [];
  currentSectionIndex: number = 0;
  landscape: boolean = false;
  keyboardOpen: boolean = false;

  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;


  constructor(
    private keyboard: Keyboard,
    private screenOrientation: ScreenOrientation,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  async ngOnInit() {
    this.sections = this.checklist.SectionsModel.filter(x => x.Questions.some(y => !y.Hidden));
    this.currentSectionIndex = ChecklistComponent.currentSectionIndex || 0;
    this.goToCurrentSectionIndex();
    this.subscription1 = this.screenOrientation.onChange().subscribe(x => {
      this.setLanscape();
      this.changeDetectorRef.detectChanges();
    });
    this.subscription2 = this.keyboard.onKeyboardWillShow().subscribe(x => {
      console.log("keyboard open");
      this.keyboardOpen = true;
      this.changeDetectorRef.detectChanges();
    });
    this.subscription3 = this.keyboard.onKeyboardWillHide().subscribe(x => {
      this.keyboardOpen = false;
      this.changeDetectorRef.detectChanges();
      console.log("keyboard hidden");
    });
    this.setLanscape();
    this.keyboardOpen = this.keyboard.isVisible;
  }

  private setLanscape() {
    this.landscape = this.screenOrientation.type == this.screenOrientation.ORIENTATIONS.LANDSCAPE ||
      this.screenOrientation.type == this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY ||
      this.screenOrientation.type == this.screenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY;
    console.log("landscape " + this.landscape);
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
  }

  async next() {
    this.currentSectionIndex++;
    while(this.checklist.SectionsModel[this.currentSectionIndex] && !this.sectionVisible(this.checklist.SectionsModel[this.currentSectionIndex])) {
      this.currentSectionIndex++;
    }
    this.goToCurrentSectionIndex();
  }

  async prev() {
    this.currentSectionIndex--;
    while(this.checklist.SectionsModel[this.currentSectionIndex] && !this.sectionVisible(this.checklist.SectionsModel[this.currentSectionIndex])) {
      this.currentSectionIndex--;
    }
    this.goToCurrentSectionIndex();
  }

  private goToCurrentSectionIndex() {
    ChecklistComponent.currentSectionIndex = this.currentSectionIndex;
    this.section = this.checklist.SectionsModel[this.currentSectionIndex];
    this.changeDetectorRef.detectChanges();
  }

  private sectionVisible(section: ChecklistSectionModel): boolean {
    return section.Questions.some(x => !x.Hidden);
  }
}