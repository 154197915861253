import { Component, Input, OnInit } from "@angular/core";
import { Facility } from "../../data/entities/Facility";
import { Router } from "@angular/router";

@Component({
  selector: "quotes-button",
  templateUrl: "quotes-button.component.html"
})
export class QuotesButtonComponent implements OnInit {
  @Input()
  facility: Facility;

  constructor(
    private router: Router
  ) { }

  async ngOnInit() {
  }

  goToQuotes() {
    this.router.navigate([`/facilities/${this.facility.Id}/quotes`]);
  }
}
