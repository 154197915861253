<ion-list>
    <ion-item (click)="editBattery()" *ngIf="!preview">
        <ion-label>
            Edit
        </ion-label>
        <ion-icon name="create-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="replaceBattery()" *ngIf="!preview && !isVendor">
        <ion-label>
            Replace
        </ion-label>
        <ion-icon name="repeat-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="editBattery()" *ngIf="preview">
        <ion-label>
            View
        </ion-label>
        <ion-icon name="eye-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="getQuote()" lines="none" *ngIf="facility.QuotesEnabled && !preview">
        <ion-label>
            Request Quote
        </ion-label>
        <ion-icon name="send-outline" slot="end"></ion-icon>
    </ion-item>
</ion-list>