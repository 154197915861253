import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { AssetsService } from "./assets.service";
import { MemberLookup } from "./assets.models";
import {
  ActionSheetController,
  ModalController,
  ToastController,
  PopoverController
} from "@ionic/angular";
import { AssetItemDataComponent } from "./asset-data/asset-item-data.component";
import { ActivatedRoute } from "@angular/router";
import { Facility } from "../data/entities/Facility";
import * as _ from "lodash";
import { SiteAsset, SiteAssetAnswer } from "../data/entities/SiteAsset";
import { AssetHelpComponent } from "./help/asset-help.component";
import { LoaderHelperService } from "../shared/services/browser/loader-helper.service";
import { MemberService } from "./member.service";
import { WebAppShowComponent } from "../webapp/web-app.component";
import { AssetManageService } from "./asset-manage.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import { Subscription } from "rxjs";
import { PreviewService } from "../shared/services/preview-service/preview-service.service";
import { AssetDataOverviewComponent } from "./asset-data/asset-data-overview.component";
import { UserService } from "../shared/services/user-service/user-service.service";
import { MyWorkService } from "../my-work/my-work.service";

@Component({
  selector: "assets",
  templateUrl: "assets.component.html"
})
export class AssetsComponent implements OnInit, OnDestroy {
  facility: Facility;
  currentAsset: SiteAsset;
  assets: SiteAsset[] = [];
  loading: boolean = true;
  hasRootItems: boolean = true;
  hasNonRootItems: boolean = true;
  assetPath: SiteAsset[] = [];
  showBackButton: boolean = true;
  lookupData: MemberLookup;
  preview: boolean;
  checklistId: number;
  canAddItems: boolean = true;

  @ViewChild(AssetDataOverviewComponent, { static: false }) assetDataOverview: AssetDataOverviewComponent;

  private subscription: Subscription;
  private subscription1: Subscription;

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    public actionSheetController: ActionSheetController,
    public modalController: ModalController,
    public toastController: ToastController,
    public popoverController: PopoverController,
    private loaderHelperService: LoaderHelperService,
    private memberService: MemberService,
    private assetManageService: AssetManageService,
    private pubSub: PubSubService,
    private previewService: PreviewService,
    private userService: UserService,
    private myWorkService: MyWorkService
  ) {
    this.facility = {
      Assets: []
    } as any;
  }

  async ngOnInit() {
    this.loading = true;
    this.subscription = this.pubSub.$sub(EventConstants.REFRESH_ASSETS_WEB, () => {
      this.ionViewWillEnter();
    });
    this.subscription1 = this.pubSub.$sub(EventConstants.REFRESH_ASSETS, () => {
      this.ionViewWillEnter();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
  }

  async ionViewWillEnter() {
    this.loading = true;
    let facilityId = this.route.snapshot.params["id"];
    let currentAssetId = this.route.snapshot.params["assetId"] || null;
    let highlightId = this.route.snapshot.params["highlightId"] || null;
    this.checklistId = this.route.snapshot.params["checklistId"] || null;
    this.facility = await this.assetsService.getFacilityWithAssets(facilityId);
    await this.assetManageService.init(this.facility);
    this.currentAsset = await this.assetsService.getAssetFromFacility(this.facility, currentAssetId);
    this.assets = this.currentAsset ? this.currentAsset.Assets : this.facility.Assets;
    if (highlightId) {
      for (let asset of this.assets) {
        if (asset.Id == highlightId) {
          asset.Highlight = true;
        }
      }
    }
    //this.assetsService.setAssetsInfo(this.assets);
    this.lookupData = await this.memberService.getLookupData(this.facility.CustomerId);
    if (WebAppShowComponent.IsWebAppIFrame && this.currentAsset == null) {
      this.showBackButton = false;
    }

    let matchingConfigs = await this.assetsService.getConfigurationByCurrentAssetType(this.facility,
      this.currentAsset == null ? null : this.currentAsset.SiteAssetTypeId
    );
    this.hasRootItems = _.filter(matchingConfigs, item => !item.ParentId).length > 0;
    this.hasNonRootItems = _.filter(matchingConfigs, item => item.ParentId).length > 0;

    this.assetPath = await this.assetsService.getAssetPath(this.facility, (this.currentAsset || <any>{}).Id);

    this.assetsService.setAssetsInfo(this.assets);

    this.loaderHelperService.off();
    this.preview = this.route.snapshot.params["preview"] == "true";
    if (this.assetDataOverview) {
      this.assetDataOverview.ngOnInit();
    }

    this.canAddItems = true;
    if (this.checklistId) {
      let job = (await this.myWorkService.getJob(this.checklistId));
      if (job) {
        this.canAddItems = !job.RestrictToSelectedAssets;
      }
    }

    this.loading = false;
  }

  async ionViewDidEnter() {
  }

  allowAddingAssets() {
    return (
      _.filter(this.currentAsset.Assets, (asset: SiteAsset) => asset.IsContainer)
        .length > 0
    );
  }

  async presentActionSheet(rootItem: boolean = false) {
    let matchingConfigs = await this.assetsService.getConfigurationByCurrentAssetType(this.facility,
      this.currentAsset == null ? null : this.currentAsset.SiteAssetTypeId
    );

    let buttons = [];
    for (let config of matchingConfigs) {
      if ((rootItem ? !config.ParentId : config.ParentId) && config.OneToMany) {
        buttons.push({
          text: config.SiteAssetType.ResKey,
          handler: async () => {
            //await this.loaderHelperService.on();
            let asset = this.assetsService.mapConfigToSiteAsset(
              this.facility.Id,
              config
            );
            asset.ParentId = this.currentAsset ? this.currentAsset.Id : null;
            await this.presentModal(asset);
          }
        });
      }
    }

    buttons.push({
      text: "Cancel",
      icon: "close",
      role: "cancel",
      handler: () => { }
    });
    const actionSheet = await this.actionSheetController.create({
      header: "Add an item",
      buttons: buttons
    });
    await actionSheet.present();
  }

  async presentModal(asset: SiteAsset) {
    const modal = await this.modalController.create({
      component: AssetItemDataComponent,
      componentProps: {
        facility: this.facility,
        asset: asset,
        memberLookup: this.lookupData,
        checklistId: this.checklistId,
        preview: this.preview
      }
    });
    return await modal.present();
  }

  async presentHelpPopover(ev: any) {
    const popover = await this.modalController.create({
      component: AssetHelpComponent,
      componentProps: {
        facility: this.facility
      }
    });
    return await popover.present();
  }

  getAnswerText(siteAssetAnswer: SiteAssetAnswer): string {
    if ((siteAssetAnswer.AnswerText || "").length > 0) {
      return siteAssetAnswer.AnswerText;
    }
    return (siteAssetAnswer.DataFloat || siteAssetAnswer.DataDate || siteAssetAnswer.Data) as string;
  }

  getPageTitle() {
    if (this.currentAsset == null) {
      if (this.facility && this.facility.Name) {
        return `Assets: ${this.facility.Name}`;
      } else {
        return 'Loading...';
      }
    } else {
      return `${this.currentAsset.SiteAssetTypeDescription}: ${this.currentAsset.Description}`;
    }
  }

  getPageSubtitle() {
    if (this.currentAsset != null) {
      if (this.facility && this.facility.Name) {
        return `Assets: ${this.facility.Name}`;
      } else {
        return "Loading...";
      }
    }
    return '';
  }

  getAssetsCount() {
    return _.filter(this.assets, a => !a.Deleted && a.OneToMany).length;
  }
}
