<ion-content class="ion-padding">
  <ion-grid style="height: 100%">
    <ion-row justify-content-center align-items-center style="height: 100%; align-content: center;">
      <ion-col size="12">
        <ion-img [src]="'/assets/images/logo.png'"></ion-img>
      </ion-col>
      <ion-col size="12">
        <ion-title class="ion-text-center ion-padding-bottom ion-padding-top">
          New Account Request
        </ion-title>
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-note *ngIf="!fullNameValid" color="danger" position="fixed" slot="end">
            Full Name is required
          </ion-note>
          <ion-input next-on-enter="email" placeholder="Full Name" [(ngModel)]="model.FullName" id="fullname">
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-note *ngIf="!emailValid" color="danger" position="fixed" slot="end">
            Email is required
          </ion-note>
          <ion-input next-on-enter="company" placeholder="Email" [(ngModel)]="model.Email" type="email" id="email">
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-note *ngIf="!companyValid" color="danger" position="fixed" slot="end">
            Company is required
          </ion-note>
          <ion-input next-on-enter="phone" placeholder="Company" [(ngModel)]="model.Company" id="company"></ion-input>
        </ion-item>
        <ion-item>
          <ion-note *ngIf="!phoneValid" color="danger" position="fixed" slot="end">
            Phone is required
          </ion-note>
          <ion-input close-keyboard-on-enter placeholder="Phone" [(ngModel)]="model.Phone" type="tel" id="phone">
          </ion-input>
        </ion-item>
        <br />
        <ion-button expand="full" class="ion-margin-top" (click)="register()">
          Send Request
        </ion-button>
        <div class="ion-padding-top">
          Or if you already have an account, you can <a (click)="login()">login</a>.
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-fab vertical="bottom" horizontal="start" slot="fixed" (click)="goToHelp()">
  <ion-fab-button color="default">
    <ion-icon name="help-circle-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>