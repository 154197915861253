<ng-container *ngIf="!loading">
    <ion-searchbar [(ngModel)]="query" (ngModelChange)="search()" showCancelButton debounce="500"
        *ngIf="facilities.length > 10" placeholder="Find a Location"></ion-searchbar>
    <div class="ion-text-center">
        <ion-note>
            <ion-icon name="information-circle-outline"></ion-icon>
            Pull down to refresh
        </ion-note>
    </div>
    <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content pullingText="Pull down more to refresh" refreshingText="Refreshing...">
        </ion-refresher-content>
    </ion-refresher>
    <ion-list>
        <ng-container *ngFor="let item of facilities">
            <ion-item *ngIf="!item.Hidden && item.Downloaded">
                <ion-label (click)="goToFacility(item)">
                    <!-- <div *ngIf="item.FACode">FA Code: {{ item.FACode }}</div> -->
                    <div>{{ item.Name }}</div>
                    <div class="my-location-search-item-description">{{ item.Description }}</div>
                    <div class="my-location-search-item-address">{{ item.Address }}</div>
                </ion-label>
                <ion-button (click)="additionalWork(item)" slot="end" color="light">
                    <i class="far fa-wrench"></i>
                </ion-button>
                <ion-button (click)="delete(item)" slot="end" color="danger">
                    <ion-icon name="trash-outline"></ion-icon>
                </ion-button>
            </ion-item>
        </ng-container>
    </ion-list>
    <ion-card *ngIf="facilities.length === 0" class="ion-text-center">
        <ion-card-header>
            <ion-card-title>My Locations</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <div class="ion-padding-bottom ion-text-start">
                You don't have any downloaded locations yet. To download locations:
                <ul>
                    <li>Tap Search All at the bottom right</li>
                    <li>Find a Location</li>
                    <li>Download</li>
                </ul>
                After that location you downloaded will show up in this list.
                <br />
                You can download maximum of 10 locations.
            </div>
        </ion-card-content>
    </ion-card>
</ng-container>
<ng-container class="ion-padding" *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ng-container>
<oms-bottom-trademark *ngIf="!loading"></oms-bottom-trademark>