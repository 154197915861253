<oms-header [title]="title" [subtitle]="'Location: ' + facility?.Name" [subtitle2]="getSubtitle2()" *ngIf="!embed">
  <ion-back-button defaultHref="/facilities"></ion-back-button>
</oms-header>
<ion-content class="checklist-questions-container" *ngIf="!loading">
  <div class="ion-text-center">
    <ion-note>
      <ion-icon name="information-circle-outline"></ion-icon>
      Pull down to refresh
    </ion-note>
  </div>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingText="Pull down more to refresh" refreshingText="Refreshing...">
    </ion-refresher-content>
  </ion-refresher>
  <ng-container *ngFor="let asset of assets">
    <gallery-item #galleryitemsref [facility]="facility" [checklistId]="checklistId" [asset]="asset"
      [selectable]="selectable" [selectedFileIds]="selectedFileIds" (onImageSelected)="onImageSelectedCallback($event)"
      (onImageDeselected)="onImageDeselectedCallback($event)" (setImageSelectedIds)="onSetImageSelectedIds($event)"
      [loadingWeb]="loadingWeb" [imagesWeb]="imagesWeb" [allowTakingPhotos]="allowTakingPhotos" [preview]="preview"></gallery-item>
  </ng-container>
  <ng-container *ngIf="assets.length == 0">
    <gallery-item #galleryitemref [facility]="facility" [checklistId]="checklistId" [checklist]="checklist"
      [checklistGallery]="checklistGallery" [question]="question" [selectable]="selectable"
      [selectedFileIds]="selectedFileIds" (onImageSelected)="onImageSelectedCallback($event)"
      (onImageDeselected)="onImageDeselectedCallback($event)" (setImageSelectedIds)="onSetImageSelectedIds($event)"
      [loadingWeb]="loadingWeb" [imagesWeb]="imagesWeb" [allowTakingPhotos]="allowTakingPhotos" [preview]="preview"></gallery-item>
  </ng-container>
  <ng-container *ngIf="showAssetSiteGallery">
    <div class="ion-padding-start ion-padding-end">
      <ion-button (click)="openAssetSiteGallery()" size="small" expand="block" color="light">Location Gallery</ion-button>
    </div>
  </ng-container>
</ion-content>
<ion-content *ngIf="loading">
  <ion-item *ngFor="let i of [1,2,3,4,5]">
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-content>