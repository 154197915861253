import { Component, Input, OnInit } from "@angular/core";
import * as _ from "lodash";
import { MyWorkService } from "../../my-work.service";
import { ChecklistStatusHistoryModel, JobDataModel } from "src/app/checklist/checklist.models";
import { ModalController } from "@ionic/angular";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";

@Component({
  selector: "work-status-history",
  templateUrl: "work-status-history.component.html"
})
export class WorkStatusHistoryComponent implements OnInit {
  @Input()
  jobModel: JobDataModel;
  items: ChecklistStatusHistoryModel[] = [];
  loading: boolean;

  constructor(
    private myWorkService: MyWorkService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  async ngOnInit() {
    this.loading = true;
    if(await this.browserHelperService.isOfflineAndMessage()){
      return;
    }
    try {
      let response = await this.myWorkService.getStatusHistory(this.jobModel.ProjectBillingId);
      this.items = response.Items;
    } catch (err) {
      this.errorHandlerService.showErrorMessage(err, "An error occurred. Make sure you are online.");
    }
    this.loading = false;
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}