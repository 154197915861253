import { Component, OnInit } from "@angular/core";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { AppInfo } from "../../globals/AppInfo";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";

@Component({
  selector: "oms-about",
  templateUrl: "about.component.html"
})
export class AboutComponent implements OnInit {
  version: string;

  constructor(
    private appVersion: AppVersion,
    private errorHandlerService: ErrorHandlerService,
    private browserHelperService: BrowserHelperService
  ) {
  }

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    try {
      this.version = await this.appVersion.getVersionNumber();
      if (AppInfo.AppSubVersion) {
        this.version = this.version + "-" + AppInfo.AppSubVersion;
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
    }
  }

  openLink(url: string) {
    this.browserHelperService.openUrl(url);
  }
}
