import { Injectable } from "@angular/core";
import { RepositoryService } from "../data/db/repository.service";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import { DateTimeHelperService } from "../shared/services/datetime/datetime-helper.service";
import { SyncQueueService } from "../sync/sync-queue.service";
import * as _ from "lodash";
import { GalleryImage } from "./gallery.models";
import { ApiService } from "../shared/services/api/api.service";
import { BaseResponseModel } from "../shared/models/base-response-model";
import { AppInfo } from "../globals/AppInfo";

@Injectable()
export class GalleryService {
  constructor(
    private repositoryService: RepositoryService,
    public errorHandlerService: ErrorHandlerService,
    private apiService: ApiService
  ) {
  }

  async getImagesFromWeb(checklistId: number): Promise<GalleryImage[]> {
    let result = await this.apiService.get<BaseResponseModel<GalleryImage>>("image/getimages", {
      ChecklistId: checklistId,
      IOS: AppInfo.Platform == "ios"
    });
    return result.Items;
  }

  async deleteImage(image: GalleryImage): Promise<void> {
    await this.apiService.post<BaseResponseModel<GalleryImage>>("image/deleteimage", image);
  }
}
