import { Injectable } from "@angular/core";
import { ApiService } from "../../shared/services/api/api.service";
import { RepositoryService } from "../../data/db/repository.service";
import * as _ from "lodash";
import { Repository } from "typeorm";
import { BaseResponse } from "src/app/shared/models/base-response-model";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { ChecklistSectionModel } from "../checklist.models";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { SyncQueueService } from "src/app/sync/sync-queue.service";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";

@Injectable()
export class ChecklistSyncService {
  constructor(
    private apiService: ApiService,
    private repositoryService: RepositoryService,
    private pubSub: PubSubService,
    private syncQueueService: SyncQueueService,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  get facilityRepo(): Repository<FacilityChecklist> {
    return this.repositoryService.getFacilityChecklistRepository();
  }

  async sync(): Promise<boolean> {
    try {
      let items = await this.syncQueueService.getItemsToSync("checklist");
      for (let item of items) {
        console.log("Sync checklist: " + item.EntityId);
        let facilityChecklist: FacilityChecklist = JSON.parse(item.Data);
        const syncResult = await this.syncQuestions(facilityChecklist.Id, JSON.parse(facilityChecklist.Sections), this.apiService.getSyncModelHeaders(item));
        if (syncResult) {
          console.log("Sync questions done" + item);
          await this.syncQueueService.removeSynchronizedItem(item);
          this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
        }
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      return false;
    }
    return true;
  }

  private async syncQuestions(
    checklistId: number,
    sections: ChecklistSectionModel[],
    headers?: { [header: string]: string | string[]; }
  ): Promise<boolean> {
    return (await this.apiService.post<BaseResponse>("site/savequestions", {
      checklistId,
      sections
    }, headers)).Success;
  }
}
