<div class="file-input-container" [hidden]="hideUploadButton">
    <label (click)="open($event)">
        {{label}}
    </label>
</div>
<ion-list>
    <ion-item *ngFor="let item of uploadedFiles; let i = index">
        <ion-label>
            <ng-container *ngIf="isImage(item)">
                <img [src]="this.convertUrl(item.Url)" />
            </ng-container>
            <ng-container *ngIf="!isImage(item)">
                {{item.Name}}
            </ng-container>
        </ion-label>
        <ion-button (click)="delete(i)">
            <ion-icon name="trash"></ion-icon>
        </ion-button>
    </ion-item>
</ion-list>