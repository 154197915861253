<ion-item class="custom-input-ion-item" *ngIf="customers.length > 0">
    <ion-note position="fixed" slot="start" (click)="focusCustomerList()">
        Customer
    </ion-note>
    <oms-group-select [(model)]="model.CustomerId" #customerSelectList [defaultText]="'Please Choose'"
        [title]="'Select Customer'" [options]="customers" (modelChange)="customerChanged()">
    </oms-group-select>
</ion-item>
<ion-item class="custom-input-ion-item" *ngIf="model.CustomerId == -1 || customers.length == 0">
    <ion-note position="fixed" slot="start" (click)="focusCustomer()">
        New Customer Name
    </ion-note>
    <ion-input type="text" [(ngModel)]="model.Customer" #customerInput placeholder="Type in..."
        next-on-enter="sonumber">
    </ion-input>
    <ion-select [(ngModel)]="model.CustomerType" slot="end" class="ion-no-margin">
        <ion-select-option value="B">Telecom</ion-select-option>
        <ion-select-option value="D">Data Center</ion-select-option>
        <ion-select-option value="E">Energy Storage</ion-select-option>
    </ion-select>
    <!-- <div slot="end">
        <span tabindex="1" (click)="scanCustomer($event)" class="battery-item-photo" style="z-index: 5;">
            <ion-icon name="barcode-outline"></ion-icon>
        </span>
    </div> -->
</ion-item>

<ion-item class="custom-input-ion-item" *ngIf="customerSelected() && !hideLocations">
    <ion-note position="fixed" slot="start" (click)="focusLocation()">
        Location
    </ion-note>
    <location-input [(model)]="model.Location" [customerId]="model.CustomerId" [(locationInfo)]="model.LocationInfo" (modelChange)="locationChanged($event)" #locationInput
        [defaultText]="model.CustomerId > 0 ? 'Select/Create Location' : 'Create Location'">
    </location-input>
</ion-item>

<ng-content select="[slot='afterLocation']"></ng-content>

<ng-container *ngIf="customerSelected() && !hideProjectTemplates && !hideProjects">
    <ion-item class="custom-input-ion-item" *ngIf="!hideProjectTemplates">
        <ion-note position="fixed" slot="start" (click)="focusTemplateSelectList()">
            Template
        </ion-note>
        <oms-group-select [(model)]="model.TemplateId" #templateSelectList [defaultText]="'Please Choose'"
            [title]="'Select Template'" [options]="templates" (modelChange)="templateChanged()">
        </oms-group-select>
    </ion-item>

    <ion-item class="custom-input-ion-item" *ngIf="model.TemplateId == -1">
        <ion-note position="fixed" slot="start" (click)="templateCategorySelectList.setFocus()">
            Work Category For New Template
        </ion-note>
        <ion-select tabindex="2" #templateCategorySelectList [(ngModel)]="model.TemplateCategoryId" okText="Okay"
            cancelText="Dismiss" placeholder="" (ionChange)="templateCategoryChanged($event.detail.value)"
            placeholder="Select Work Category" [interfaceOptions]="{ header: 'Work Category'}">
            <ion-select-option [value]="item.Value" *ngFor="let item of templateCategories">
                {{item.Text }}
            </ion-select-option>
        </ion-select>
    </ion-item>
    
    <ion-item class="custom-input-ion-item"
        *ngIf="(model.TemplateId == -1 || !hasTemplates()) && !hideProjects">
        <ion-note position="fixed" slot="start" (click)="templateNameInput.setFocus()">
            New Template Name
        </ion-note>
        <ion-input type="text" [(ngModel)]="model.TemplateName" #templateNameInput placeholder="Type in template name...">
        </ion-input>
    </ion-item>

    <ion-item class="custom-input-ion-item" *ngIf="hasProjects() && !hideProjects">
        <ion-note position="fixed" slot="start" (click)="focusProjectSelectList()">
            Project
        </ion-note>
        <oms-group-select [(model)]="model.ProjectId" #projectSelectList [defaultText]="'Please Choose'"
            [title]="'Select Project'" [options]="projects">
        </oms-group-select>
    </ion-item>
    <ion-item class="custom-input-ion-item"
        *ngIf="(model.ProjectId == -1 || !hasProjects()) && !hideProjects">
        <ion-note position="fixed" slot="start" (click)="focusProjectName()">
            New Project Name
        </ion-note>
        <ion-input type="text" [(ngModel)]="model.ProjectName" #projectNameInput placeholder="Type in project name...">
        </ion-input>
    </ion-item>
</ng-container>