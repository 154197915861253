import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  Input
} from "@angular/core";

@Directive({
  selector: "[positive-numbers-only]" // Attribute selector
})
export class PositiveNumbersOnlyDirective {
  @Output() input: EventEmitter<string> = new EventEmitter<string>();

  constructor(public elementRef: ElementRef) { }

  @HostListener("keydown", ["$event"])
  keyEvent(event) {
    if (event.key == "-" || event.keyCode == 38 || event.keyCode == 40) {
      event.preventDefault();
    }
  }

  @HostListener("change", ["$event"])
  change(event) {
    let element = event.srcElement;
    if (element && parseFloat(element.value) <= 0) {
      element.value = "0";
    }
  }
}
