import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChange } from "@angular/core";
import * as _ from "lodash";
import { Facility } from "../../data/entities/Facility";
import { AssetsService } from "../../assets/assets.service";
import { SiteAsset } from "../../data/entities/SiteAsset";
import { ChecklistQuestionModel } from "../../checklist/checklist.models";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { GalleryService } from "../gallery.service";
import { GalleryImage } from "../gallery.models";
import { Entry } from "@ionic-native/file/ngx";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventsConstants from '../../shared/constants/events-constants';
import { CameraPhotoModel } from "src/app/shared/services/plugin-helpers/models/camera-photo.model";
import { Subscription } from "rxjs";
import { BrowserHelperService } from "../../shared/services/browser/browser-helper.service";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { DomSanitizer } from "@angular/platform-browser";
import { LocalDateTimePipe } from "../../shared/pipes/local-datetime.pipe";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import * as moment from "moment";
import { MyWorkService } from "src/app/my-work/my-work.service";

@Component({
  selector: "gallery-item",
  templateUrl: "gallery-item.component.html"
})
export class GalleryItemComponent implements OnInit, OnDestroy, OnChanges {
  loading: boolean;
  @Input()
  loadingWeb: boolean;
  @Input()
  facility: Facility;
  @Input()
  asset: SiteAsset;
  @Input()
  question: ChecklistQuestionModel;
  @Input()
  checklist: FacilityChecklist;
  @Input()
  selectable: boolean;
  @Input()
  checklistId: number;
  @Input()
  selectedFileIds: number[] = [];
  @Input()
  @Input()
  preview: boolean;
  @Input()
  checklistGallery: boolean = false;
  @Output()
  count = new EventEmitter<number>();
  @Output()
  onImageSelected: EventEmitter<GalleryImage> = new EventEmitter<GalleryImage>();
  @Output()
  onImageDeselected: EventEmitter<GalleryImage> = new EventEmitter<GalleryImage>();
  @Output()
  setImageSelectedIds: EventEmitter<number[]> = new EventEmitter<number[]>();

  assetImages: AssetGalleryModel = {
    Properties: [],
    BatteryImages: []
  } as AssetGalleryModel;
  checklistImages: ChecklistSectionGalleryModel[] = [];
  unassociatedChecklistImages: GalleryImage[] = [];
  images: GalleryImage[] = [];
  @Input()
  imagesWeb: GalleryImage[] = [];
  @Input()
  allowTakingPhotos: boolean = true;
  isOffline: boolean;

  private subscription: Subscription;
  private subscription1: Subscription;

  constructor(
    private assetsService: AssetsService,
    private cameraHelperService: CameraHelperService,
    private galleryService: GalleryService,
    private pubSub: PubSubService,
    private browserHelperService: BrowserHelperService,
    private domSanitizer: DomSanitizer,
    private previewService: PreviewService,
    private myWorkService: MyWorkService
  ) { }

  reset() {
    this.images = [];
    this.assetImages = {
      Properties: [],
      BatteryImages: []
    } as AssetGalleryModel;
    this.checklistImages = [];
    this.imagesWeb = [];
  }

  async ngOnInit() {
    this.loading = true;
    this.isOffline = this.browserHelperService.isOffline();
    if (this.asset) {
      let assetIds = [];
      let assets = await this.assetsService.getAssets(this.facility, this.asset.Id, true);
      assetIds.push(this.asset.Id);
      for (let asset of assets) {
        assetIds.push(asset.Id);
      }
      this.assetImages = {
        Name: this.asset.Description,
        Type: this.asset.SiteAssetTypeDescription,
        Images: [],
        BatteryImages: [],
        Properties: []
      };
      if (!this.selectable) {
        await this.loadPhoneImages();
      }
    }
    if (this.checklistGallery) {
      for (let section of this.checklist.SectionsModel) {
        let gallerySection = new ChecklistSectionGalleryModel();
        gallerySection.Name = section.Name;
        gallerySection.Questions = [];
        for (let q of section.Questions) {
          let questionItem = new ChecklistQuestionGalleryModel();
          questionItem.Name = q.PromptText;
          questionItem.VendorViewId = q.VendorViewPK;
          questionItem.Images = [];
          gallerySection.Questions.push(questionItem);
        }
        this.checklistImages.push(gallerySection);
      }
      if (!this.selectable) {
        await this.loadPhoneImages();
      }
    }
    this.loading = false;
    this.sub();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    let property = "loadingWeb";
    if (changes[property] && changes[property].previousValue === true && changes[property].currentValue === false) {
      this.allImagesLoadedFromWeb(this.imagesWeb);
    }
  }

  sub() {
    this.subscription = this.pubSub.$sub(EventsConstants.IMAGE_TAKEN).subscribe((image: CameraPhotoModel) => {
      let imageTaken = {
        NativeURL: image.PhonePath,
        FileName: image.FileName,
        Entry: image.Entry,
        Url: this.convertUrl(image.PhonePath),
        ThumbnailUrl: this.convertUrl(image.PhonePath),
        Loaded: false,
        Deleting: false,
        IsPhone: true,
        IsUploaded: false,
        ChecklistId: parseInt(this.cameraHelperService.getFileNameProperty(image.FileName, "c")),
        ImageId: null,
        Selected: false,
        DateCreated: this.cameraHelperService.getImageDate(image.FileName) as any,
        VendorViewId: parseInt(this.cameraHelperService.getFileNameProperty(image.FileName, "vv")),
        AssetId: this.cameraHelperService.getFileNameProperty(image.FileName, "sa"),
        BatteryKey: this.cameraHelperService.getFileNameProperty(image.FileName, "bk"),
        AssetConfigId: parseInt(this.cameraHelperService.getFileNameProperty(image.FileName, "sac")),
        UserId: null,
        UploadedByMe: true,
        DeletedOnTheWeb: false
      };
      this.images.unshift(imageTaken);
      if (this.asset) {
        //this.images = this.images.concat(tmpImages);
        this.setAssetImages([imageTaken], true);
      } else if (this.checklistGallery) {
        this.setChecklistImages([imageTaken], true);
      }
    });

    this.subscription1 = this.pubSub.$sub(EventsConstants.IMAGE_SYNC_DONE, (data: any) => {
      let entry = data.entry;
      let id = data.id;
      if (entry) {
        for (let image of this.images || []) {
          if (image.FileName == entry.name) {
            this.setImageAsUploaded(image, entry, id);
          }
        }
        for (let section of this.checklistImages || []) {
          for (let q of section.Questions) {
            for (let image of q.Images) {
              if (image.FileName == entry.name) {
                this.setImageAsUploaded(image, entry, id);
              }
            }
          }
        }
        for (let image of this.assetImages.Images || []) {
          if (image.FileName == entry.name) {
            this.setImageAsUploaded(image, entry, id);
          }
        }
        for (let property of this.assetImages.Properties || []) {
          for (let image of property.Images) {
            this.setImageAsUploaded(image, entry, id);
          }
        }
      }
    });
  }

  private setImageAsUploaded(image: GalleryImage, entry, id) {
    image.IsUploaded = true;
    image.Entry = entry;
    image.ImageId = id;
    image.NativeURL = entry.nativeURL;
  }

  async ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  async takeAssetPhoto(siteAsset: SiteAsset): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame && window.parent) {
      window.parent.postMessage({ message: "open-image-gallery-web", siteKey: this.facility.Id, siteAssetKey: siteAsset.Id }, "*")
      return;
    }
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklistId,
      SiteAssetId: siteAsset.Id
    });
    const image = await this.cameraHelperService.takePhoto(fileName);
    if (image) {
      siteAsset.ImageCount = (siteAsset.ImageCount || 0) + 1;
      this.assetsService.updateFacilityNoSyncNeeded(this.facility);
    }
  }

  async takeChecklistPhoto(fromGallery: boolean) {
    if (WebAppShowComponent.IsWebAppIFrame && window.parent) {
      window.parent.postMessage({ message: "open-image-gallery-web", checklistKey: this.checklistId }, "*")
      return;
    }
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklistId
    });
    let images: CameraPhotoModel[] = [];
    if (fromGallery) {
      images = await this.cameraHelperService.pickImages(() => {
        return this.cameraHelperService.getFileName({
          ChecklistId: this.checklistId
        });
      });
    } else {
      const image = await this.cameraHelperService.takePhoto(fileName);
      if (image) {
        images.push(image);
      }
    }
    if (images.length > 0) {
      let job = await this.myWorkService.updateJobImageCount(this.checklistId, images.length);
    }
  }

  private allImagesLoadedFromWeb(images: GalleryImage[]) {
    let newImages: GalleryImage[] = [];
    for (let image of images) {
      if (image.ChecklistId != this.checklistId) {
        continue;
      }
      if (image.AssetId && this.asset && this.asset.Id != image.AssetId) {
        continue;
      }
      if (this.asset && this.asset.Id != image.AssetId && !image.BatteryKey && image.ChecklistId != this.checklistId) {
        continue;
      }
      if (this.question && this.question.VendorViewPK != image.VendorViewId && image.ChecklistId != this.checklistId) {
        continue;
      } else if (this.checklistGallery && !image.VendorViewId && image.ChecklistId != this.checklistId) {
        continue;
      }
      image.IsPhone = false;
      image.IsUploaded = true;
      image.Selected = _.findIndex(this.selectedFileIds, x => x == image.ImageId) >= 0;
      newImages.push(image);
    }
    this.selectedFileIds = _.map(_.filter(newImages, i => i.Selected), x => x.ImageId);
    for (let phoneImage of this.images) {
      if (phoneImage.IsUploaded && _.filter(newImages, i => i.FileName == phoneImage.FileName).length == 0) {
        phoneImage.DeletedOnTheWeb = true;
      }
    }
    this.images = this.images.concat(newImages);
    if (this.asset) {
      this.setAssetImages(newImages);
    }
    if (this.checklistGallery) {
      this.setChecklistImages(newImages);
    }
  }

  private async loadPhoneImages() {
    let allImages: Entry[] = [];
    let imagesTaken = await this.cameraHelperService.getImagesTaken();
    let imagesUploaded = await this.cameraHelperService.getImagesUploaded();
    let allPhoneImages = imagesTaken;
    allPhoneImages = allPhoneImages.concat(imagesUploaded);
    for (let image of allPhoneImages) {
      if (this.isAssetImage({
        FileName: image.name
      } as GalleryImage) || (this.checklistGallery && this.isChecklistImage(image.name))) {
        allImages.push(image);
      }
    }
    let tmpImages: GalleryImage[] = [];
    let sorted = this.cameraHelperService.sortImages(allImages, "asc").reverse();
    for (let image of sorted) {
      tmpImages.push(this.getImageFromEntry(image, imagesUploaded));
    }
    this.images = this.images.concat(tmpImages);
    if (this.asset) {
      //this.images = this.images.concat(tmpImages);
      this.setAssetImages(tmpImages);
    } else if (this.checklistGallery) {
      this.setChecklistImages(tmpImages);
    }
  }

  private getImageFromEntry(image: Entry, imagesUploaded: Entry[]): GalleryImage {
    return {
      NativeURL: image.nativeURL,
      Entry: image,
      FileName: image.name,
      Url: this.convertUrl(image.nativeURL),
      ThumbnailUrl: this.convertUrl(image.nativeURL),
      Loaded: false,
      Deleting: false,
      IsPhone: true,
      ChecklistId: parseInt(this.cameraHelperService.getFileNameProperty(image.name, "c")),
      ImageId: null,
      IsUploaded: _.findIndex(imagesUploaded, i => i.name == image.name) >= 0,
      Selected: false,
      DateCreated: moment.utc(this.cameraHelperService.getImageDate(image.name)).format("MM/DD/YYYY HH:mm"),
      VendorViewId: parseInt(this.cameraHelperService.getFileNameProperty(image.name, "vv")),
      AssetId: this.cameraHelperService.getFileNameProperty(image.name, "sa"),
      BatteryKey: this.cameraHelperService.getFileNameProperty(image.name, "bk"),
      AssetConfigId: parseInt(this.cameraHelperService.getFileNameProperty(image.name, "sac")),
      UserId: null,
      UploadedByMe: true,
      DeletedOnTheWeb: false
    }
  }
  private convertUrl(url: string) {
    let newUrl = window["Ionic"].WebView.convertFileSrc(url);
    if (newUrl && newUrl.indexOf("file://") == 0) {
      newUrl = newUrl.replac("file://", "");
    }
    newUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(newUrl);
    return newUrl;
  }

  private setChecklistImages(images: GalleryImage[], atTheBeginning: boolean = false) {
    for (let s of this.checklistImages) {
      for (let q of s.Questions) {
        let filteredImages = _.filter(images, x => x.VendorViewId == q.VendorViewId);
        for (let image of filteredImages) {
          //if (!this.ifImageIsAlreadyInTheList(image, q.Images)) {
          if (atTheBeginning) {
            q.Images.unshift(image);
          } else {
            q.Images.push(image)
          }
          q.Images = this.sortAndDistinctImages(q.Images);
          //}
        }
      }
    }
    let unassociatedFilteredImages = _.filter(images, image => !this.isAssetImage(image) && this.checklistId == image.ChecklistId && !image.VendorViewId);
    if (unassociatedFilteredImages.length > 0) {
      for (let image of unassociatedFilteredImages) {
        if (atTheBeginning) {
          this.unassociatedChecklistImages.unshift(image);
        } else {
          this.unassociatedChecklistImages.push(image);
        }
      }
      this.unassociatedChecklistImages = this.sortAndDistinctImages(this.unassociatedChecklistImages);
    }
  }

  private setAssetImages(images: GalleryImage[], atTheBeginning = false) {
    for (let image of images) {
      let configId = image.AssetConfigId || parseInt(this.cameraHelperService.getFileNameProperty(image.FileName, "sac"));
      if (configId) {
        let index = _.findIndex(this.assetImages.Properties, x => x.ConfigId == configId);
        if (index >= 0) {
          //if (!this.ifImageIsAlreadyInTheList(image, this.assetImages.Properties[index].Images)) {
          if (atTheBeginning) {
            this.assetImages.Properties[index].Images.unshift(image);
          } else {
            this.assetImages.Properties[index].Images.push(image);
          }
          this.assetImages.Properties[index].Images = this.sortAndDistinctImages(this.assetImages.Properties[index].Images);
          //}
        } else {
          let answer = _.find(this.asset.Answers, x => x.SiteAssetDataItemConfigurationId == configId);
          if (answer) {
            this.assetImages.Properties.push({
              Name: answer.FieldName,
              ConfigId: configId,
              Images: [image]
            });
          }
        }
      } else if (this.isAssetBatteryImage(image)) {
        //if (!this.ifImageIsAlreadyInTheList(image, this.assetImages.BatteryImages)) {
        if (atTheBeginning) {
          this.assetImages.BatteryImages.unshift(image);
        } else {
          this.assetImages.BatteryImages.push(image);
        }
        this.assetImages.BatteryImages = this.sortAndDistinctImages(this.assetImages.BatteryImages);
        //}
      }
      else if (image.AssetId == this.asset.Id || (image.FileName ?? "").indexOf(`sa_${this.asset.Id}`) >= 0) {
        //if (!this.ifImageIsAlreadyInTheList(image, this.assetImages.Images)) {
        if (atTheBeginning) {
          this.assetImages.Images.unshift(image);
        } else {
          this.assetImages.Images.push(image);
        }
        this.assetImages.Images = this.sortAndDistinctImages(this.assetImages.Images);
        //}
      }
    }
  }

  sortAndDistinctImages(images: GalleryImage[]) {
    let unique = [];
    for (let image of images) {
      let fileName = (image.FileName || "");
      if (image.IsPhone && _.filter(images, i => !i.IsPhone && i.FileName == fileName).length > 0) {
        continue;
      } else {
        unique.push(image);
      }
    }
    //var unique = _.uniqBy(images, i => this.isPhoneFileName(i.FileName) ? i.FileName : i.ImageId);
    return _.orderBy(unique, i => this.getDate(i.DateCreated), ['desc']);
  }

  private getDate(date: string) {
    let tmp = new LocalDateTimePipe().transform(date);
    if (tmp) {
      return new Date(tmp).getTime();
    }
    return null;
  }

  // private ifImageIsAlreadyInTheList(image: GalleryImage, images: GalleryImage[]): boolean {
  //   if (image.IsPhone && this.isPhoneFileName(image.FileName)) {
  //     return _.filter(images, i => i != image && i.FileName == image.FileName).length > 0;
  //   }
  //   return false;
  // }

  private isPhoneFileName(fileName: string) {
    return (fileName || "").indexOf("c_") == 0 && (fileName || "").length > 50
  }

  private isAssetImage(image: GalleryImage): boolean {
    if (this.asset) {
      return this.isChecklistImage(image.FileName) && (image.FileName.indexOf(`sa_${this.asset.Id}`) >= 0 || this.isAssetBatteryImage(image));
    }
    return false;
  }

  private isAssetPropertyImage(fileName: string): boolean {
    if (this.asset) {
      return fileName.indexOf("_sac_") >= 0;
    }
    return false;
  }

  private isAssetBatteryImage(image: GalleryImage): boolean {
    if (this.asset) {
      let batteryKey = image.BatteryKey || this.cameraHelperService.getFileNameProperty(image.FileName, "bk");
      if (_.filter(this.asset.Batteries, b => b.BatteryKey == batteryKey).length > 0) {
        return true;
      }
    }
    return false;
  }

  private isVendorViewIdImage(fileName: string): boolean {
    if (this.question) {
      return fileName.indexOf(`vv_${this.question.VendorViewPK}`) >= 0;
    }
    return false;
  }

  private isChecklistImage(fileName: string): boolean {
    return fileName.indexOf(`c_${this.checklistId}`) >= 0;
  }

  public async deleteAssetBatteryImage(image: GalleryImage) {
    this.deleteImage(image, () => {
      _.remove(this.assetImages.BatteryImages, i => i.IsPhone ? image.FileName == i.FileName : image.ImageId == i.ImageId);
    });
  }

  public async deleteAssetImage(image: GalleryImage) {
    this.deleteImage(image, () => {
      _.remove(this.assetImages.Images, i => i.IsPhone ? image.FileName == i.FileName : image.ImageId == i.ImageId);
    });
  }

  public async deleteAssetPropertyImage(image: GalleryImage) {
    this.deleteImage(image, () => {
      for (let property of this.assetImages.Properties) {
        _.remove(property.Images, i => i.IsPhone ? image.FileName == i.FileName : image.ImageId == i.ImageId);
      }
    });
  }

  public async deleteQuestionImage(image: GalleryImage, questionIndex: number, questionImageIndex: number) {
    this.deleteImage(image, () => {
      for (let checklistImage of this.checklistImages) {
        for (let question of checklistImage.Questions) {
          _.remove(question.Images, i => i.IsPhone ? image.FileName == i.FileName : image.ImageId == i.ImageId);
        }
      }
      this.myWorkService.updateJobImageCount(image.ChecklistId, -1);
    });
  }

  public async deleteChecklistUnassociatedImage(image: GalleryImage) {
    this.deleteImage(image, () => {
      _.remove(this.unassociatedChecklistImages, i => i.IsPhone ? image.FileName == i.FileName : image.ImageId == i.ImageId);
    });
    this.myWorkService.updateJobImageCount(image.ChecklistId, -1);
  }

  public async deleteImage(image: GalleryImage, callback: () => void) {
    try {
      if (confirm("Are you sure you want to delete this image?")) {
        // let index = image.IsPhone ? _.findIndex(this.images, i => i.NativeURL == image.NativeURL) :
        //   _.findIndex(this.images, i => i.ImageId == image.ImageId)
        // if (index >= 0) {
        if (!image.IsUploaded) {
          await this.cameraHelperService.moveImageToRecycleBin(image.Entry);
        } else {
          try {
            var imagesTaken = await this.cameraHelperService.getImagesTaken();
            let itemToDelete = _.find(imagesTaken, i => i.name == image.FileName);
            if (itemToDelete) {
              await this.cameraHelperService.moveImageToRecycleBin(itemToDelete);
            } else {
              var imagesUploaded = await this.cameraHelperService.getImagesUploaded();
              itemToDelete = _.find(imagesUploaded, i => i.name == image.FileName);
              if (itemToDelete) {
                await this.cameraHelperService.moveImageToRecycleBin(itemToDelete);
              }
            }
          } catch { }
          await this.galleryService.deleteImage(image);
        }
        this.onImageDeselectedCallback(image);
        callback();
        //}
      }
    } finally {
      image.Deleting = false;
    }
  }

  onImageSelectedCallback(image: GalleryImage) {
    this.onImageSelected.next(image);
  }

  onImageDeselectedCallback(image: GalleryImage) {
    this.onImageDeselected.next(image);
  }
}

export class ChecklistSectionGalleryModel {
  Name: string;
  hasImages() {
    for (let q of this.Questions) {
      if (q.hasImages()) {
        return true;
      }
    }
    return false;
  }
  Questions: ChecklistQuestionGalleryModel[];
}

export class ChecklistQuestionGalleryModel {
  Name: string;
  VendorViewId: number;
  Images: GalleryImage[];
  hasImages() {
    if (this.Images.length > 0) {
      return true;
    }
    return false;
  }
}

export class AssetGalleryModel {
  Name: string;
  Type: string;
  Images: GalleryImage[];
  BatteryImages: GalleryImage[]
  Properties: AssetPropertyGalleryModel[];
}

export class AssetPropertyGalleryModel {
  Name: string;
  ConfigId: number;
  Images: GalleryImage[];
}