<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Add New Email
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-label>
    Name
  </ion-label>
  <ion-item class="ion-margin-bottom">
    <ion-note *ngIf="!nameValid" color="danger" position="fixed" slot="end">
      Name is required
    </ion-note>
    <ion-input [(ngModel)]="name" type="text" placeholder="Type in Name">
    </ion-input>
  </ion-item>
  <ion-label>
    Email
  </ion-label>
  <ion-item class="ion-margin-bottom">
    <ion-note *ngIf="!emailValid" color="danger" position="fixed" slot="end">
      Email is required
    </ion-note>
    <ion-input [(ngModel)]="email" type="text" placeholder="Type in Email">
    </ion-input>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="addEmail()" color="primary" checked>
        <ion-label>Add Email</ion-label>
      </ion-segment-button>
      <ion-segment-button (click)="dismissModal()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>