<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Upload Test
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <input type="file" #fileInput (change)="onFileChange($event)" style="display: none" />
  <ion-button *ngIf="showUploadButton && !alreadyOpened" (click)="selectFile()">Select test to upload</ion-button>
  <div *ngIf="processErrorMessage"
    style="padding: 1rem; background-color: orange; margin: 1rem 0; border-radius: 1rem;">
    <div>
      There is a problem with processing the file you selected:
    </div>
    <p>Error message: {{processErrorMessage}}</p>
  </div>
  <battery-test-form *ngIf="data && !loading" [data]="data" [refreshBattery]="refreshBattery"></battery-test-form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="save()" color="primary" checked *ngIf="!loading && data">
        <ion-label>
          Upload
        </ion-label>
      </ion-segment-button>
      <ion-segment-button color="primary" checked *ngIf="loading">
        <ion-label>
          Uploading...
        </ion-label>
      </ion-segment-button>
      <ion-segment-button (click)="dismissModal()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>