import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { MyWorkService } from "../my-work.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { ChecklistDocumentModel, JobDataModel } from "src/app/checklist/checklist.models";
import { AssetsService } from "src/app/assets/assets.service";
import { ToastController } from "@ionic/angular";
import { Facility } from "src/app/data/entities/Facility";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "work-item-files",
  templateUrl: "work-item-files.component.html"
})
export class WorkItemFilesComponent implements OnInit {
  loading: boolean = true;
  jobModel: JobDataModel;
  facility: Facility;
  constructor(
    private myWorkService: MyWorkService,
    private pubSub: PubSubService,
    private toastController: ToastController,
    private assetsService: AssetsService,
    private router: Router,
    private route: ActivatedRoute,
    private browserHelperService: BrowserHelperService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.loading = true;
    let jobId = this.route.snapshot.params["id"];
    this.jobModel = await this.myWorkService.getJob(jobId);
    this.facility = await this.assetsService.getFacilityWithAssets(this.jobModel.SiteKey);
    this.loading = false;
  }

  async openFile(item: ChecklistDocumentModel) {
    let token = await this.userService.getUserToken();
    this.browserHelperService.openUrl(`${item.Url}?mobile-app-token=${token}`);
  }
}