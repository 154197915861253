import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AssetsService } from "../assets.service";
import * as _ from "lodash";
import { Facility } from "src/app/data/entities/Facility";
import { ActivatedRoute } from "@angular/router";
import { AssetQuoteModel, FacilityQuoteDbModel, MemberLookup } from "../assets.models";
import { SiteAsset, SiteAssetBattery } from "src/app/data/entities/SiteAsset";
import { MemberService } from "../member.service";
import { AssetQuoteService } from "../services/quotes/asset-quote.service";
import { IonContent } from "@ionic/angular";
import { Subscription } from "rxjs";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { AssetQuoteSubmitComponent } from "./asset-quote-submit/asset-quote-submit.component";

@Component({
  selector: "asset-quotes",
  templateUrl: "asset-quotes.component.html"
})
export class AssetQuotesComponent implements OnInit, OnDestroy {
  facility: Facility;
  checklistId: number;
  mode: string = 'select';
  assets: AssetQuoteModel[] = [];
  loading: boolean = false;
  @ViewChild("quoteContent")
  content: IonContent;

  lookupData: MemberLookup;

  quotePreviewWeb: boolean;
  editQuoteWeb: boolean;
  submittable: boolean;
  quoteId: string;
  showDrafts: boolean;

  existingFacilityQuote: FacilityQuoteDbModel;

  @ViewChild(AssetQuoteSubmitComponent) assetQuoteSubmitComponent: AssetQuoteSubmitComponent;

  private subscription: Subscription;
  private subscription1: Subscription;
  private subscription2: Subscription;
  highlightId: string;
  highlightBatteryId: string;

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    private memberService: MemberService,
    private assetQuoteService: AssetQuoteService,
    private pubSub: PubSubService,
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    let facilityId = this.route.snapshot.params["id"];
    this.quoteId = this.route.snapshot.params["quoteId"] || null;
    await this.loadData(this.quoteId, facilityId);
    this.subscription = this.pubSub.$sub(EventsConstants.QUOTE_HISTORY, () => {
      this.switchMode("history");
    });

    this.subscription1 = this.pubSub.$sub(EventsConstants.QUOTE_REVIEW_NAVIGATE_WEB, async (data) => {
      await this.loadData(data.quoteKey, data.siteKey);
    });

    this.subscription2 = this.pubSub.$sub(EventsConstants.QUOTE_REVIEW_NAVIGATE_WEB_START, (data) => {
      this.loading = true;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }

  loadDataForQuote(quoteKey: string) {
    this.loadData(quoteKey, this.facility.Id);
  }

  private async setSubmittable() {
    const user = await this.userService.getLoggedInUser();
    this.submittable = !this.quoteId || this.existingFacilityQuote.UserId == user.UserId;
  }
  private async loadData(quoteKey, facilityId) {
    this.loading = true;
    this.editQuoteWeb = this.route.snapshot.params["editQuote"] == "true";
    this.showDrafts = (this.route.snapshot.params["showDrafts"] || "true") == "true";
    this.submittable = true;
    this.highlightId = this.route.snapshot.params["highlightId"] || null;
    this.highlightBatteryId = this.route.snapshot.params["highlightBatteryId"] || null;
    this.checklistId = this.route.snapshot.params["checklistId"] || null;
    this.facility = await this.assetsService.getFacilityWithAssets(facilityId);
    this.lookupData = await this.memberService.getLookupData(this.facility.CustomerId);

    this.existingFacilityQuote = await this.assetQuoteService.getFacilityQuote(this.facility.Id);
    if (this.editQuoteWeb) {
      this.existingFacilityQuote = await this.assetQuoteService.getQuoteModel(this.facility.Id, quoteKey);
      await this.assetQuoteService.setFacilityQuote(this.existingFacilityQuote);
      await this.setSubmittable();
      this.switchMode("submit");
    } else if (this.quotePreviewWeb) {
      await this.setSubmittable();
    }

    if (this.highlightId || this.highlightBatteryId) {
      this.switchMode("submit");
    }

    this.assets = [];
    await this.assetRecursive(this.facility.Assets, 0, async (asset: SiteAsset, level: number) => {
      if (asset.Quotable && !asset.Deleted) {
        asset.Highlight = asset.Id == this.highlightId;
        let calc = this.assetsService.getSiteAssetCalcInfo(asset, this.facility);
        asset.Calc = calc;
        let assetPathString = null;
        let assetPath = await this.assetsService.getAssetPath(this.facility, asset.Id);
        let tmp = [];
        for (let j = 0; j < assetPath.length - 1; j++) {
          tmp.push(assetPath[j].Description);
        }
        if (tmp.length > 0) {
          assetPathString = tmp.join("/");
        }
        let existingAsset = _.find(this.existingFacilityQuote.Items, i => i.ItemId == asset.Id);
        var assetQuantity = this.getQuantity(asset);
        let quantity = existingAsset ? existingAsset.Quantity : assetQuantity;
        let selected = existingAsset ? true : false
        if (asset.Highlight) {
          selected = true;
          if (!quantity) {
            quantity = 1;
          } else {
            if (existingAsset) {
              quantity++;
            } else {
              quantity = assetQuantity;
            }
          }
        }
        if (asset.Highlight) {
          //selected = true;
          await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
            ItemId: asset.Id,
            ItemType: "asset",
            Quantity: quantity,
            Images: existingAsset ? existingAsset.Images : []
          });
        }
        this.assets.push({
          Asset: asset,
          Battery: null,
          BatteryModel: null,
          Selected: selected,
          Level: level,
          AssetPathString: assetPathString,
          Quantity: quantity,
          OldQuantity: quantity,
          Hidden: false,
          SelectedImages: existingAsset ? existingAsset.Images : [],
          BatteryTests: 0
        });
        asset.Batteries = _.sortBy(asset.Batteries, b => b.Position);
        for (let battery of asset.Batteries) {
          battery.Highlight = battery.BatteryKey == this.highlightBatteryId;
          let existingBattery = _.find(this.existingFacilityQuote.Items, i => i.ItemId == battery.BatteryKey);
          let quantity = existingBattery ? existingBattery.Quantity : 0;
          let selected = existingBattery ? true : false
          if (battery.Highlight) {
            selected = true;
            if (!quantity) {
              quantity = 1;
            } else {
              quantity++;
            }
          }
          if (battery.Highlight) {
            //selected = true;
            if (!quantity) {
              quantity = 1;
            }
            if (selected) {
              await this.assetQuoteService.setFacilityQuoteItem(this.facility.Id, {
                ItemId: battery.BatteryKey,
                ItemType: "battery",
                Quantity: quantity,
                Images: existingBattery ? existingBattery.Images : []
              });
            }
          }
          if (battery.Highlight || existingBattery) {
            battery.BatteryTypeName = this.getBatteryTypeText(battery);
            this.assets.push({
              Asset: asset,
              Battery: battery,
              BatteryModel: null,
              Selected: selected,
              Level: level + 1,
              AssetPathString: assetPathString,
              Quantity: quantity,
              OldQuantity: quantity,
              Hidden: false,
              SelectedImages: existingBattery ? existingBattery.Images : [],
              BatteryTests: 0
            });
          }
        }
      }
    });
    if (location.pathname.indexOf("preview") > 0) {
      this.quotePreviewWeb = true;
    }
    if (this.assets.length == 0) {
      this.switchMode("submit");
    }
    let highLightIndex = -1;
    if (this.highlightId) {
      highLightIndex = _.findIndex(this.assets, i => i.Asset.Highlight)
    } else if (this.highlightBatteryId) {
      highLightIndex = _.findIndex(this.assets, i => (i.Battery || {} as SiteAssetBattery).Highlight);
    }
    if (highLightIndex >= 0) {
      setTimeout(() => {
        //this.content.scrollToPoint(0, highLightIndex * 70);
      }, 100);
    }
    this.loading = false;
  }

  async ionViewWillEnter() {
    if (this.quotePreviewWeb) {
      await this.setSubmittable();
    }
  }

  ionViewDidEnter() {
    if (this.assetQuoteSubmitComponent) {
      this.assetQuoteSubmitComponent.ionViewWillEnter();
    }
  }

  switchMode(mode: string) {
    this.mode = mode;
  }

  segmentChanged(event: any) {
    this.switchMode(event.detail.value);
  }

  private async assetRecursive(siteAssets: SiteAsset[], level: number, callback: (item: SiteAsset, level: number) => void): Promise<void> {
    for (let asset of siteAssets) {
      await callback(asset, level);
      await this.assetRecursive(asset.Assets, level + 1, callback);
    }
  }

  private getQuantity(asset: SiteAsset): number {
    return this.assetsService.getAssetAnswerListOrNumeric("battery-count", asset, this.facility) ||
      this.assetsService.getAssetAnswerListOrNumeric("quantity", asset, this.facility) || 0;
  }

  private getBatteryTypeText(battery: SiteAssetBattery) {
    for (let group of this.lookupData.BatteryTypes) {
      for (let item of group.Items) {
        if (battery.InvgenId == item.Value) {
          if (group.Text == null) {
            return item.Text;
          }
          return `${group.Text} - ${item.Text}`;
        }
      }
    }
  }

}