<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Review and Update Tests
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <battery-test-form *ngIf="data && !loading" [data]="data"></battery-test-form>
  <ion-list *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
      <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="save()" color="primary" checked *ngIf="!loading">
        <ion-label *ngIf="!updating">
          Update Test
        </ion-label>
        <ion-label *ngIf="updating">
          Updating...
        </ion-label>
      </ion-segment-button>
      <ion-segment-button (click)="dismissModal()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>