import { UserConfigModel } from "../shared/models/user-config-model";

export class RegisterModel {
  Email: string;
  Company: string;
  Phone: string;
  FullName: string;
  UserId: number;
}

export class RegisterModelResponse {
  Success: boolean;
  Message: string;
}

export class LoginModel {
  Email: string;
  Username: string;
  Password: string;
  AcceptTerms: boolean;
}

export class LoginModelResponse extends RegisterModelResponse {
  Email: string;
  Username: string;
  Company: string;
  Phone: string;
  FullName: string;
  UserId: number;
  RoleId: number;
  MemberId: number;
  CustomerType: string;
  IntCodeId: string;
  Token: string;
  HasJobs: boolean;
  Config: UserConfigModel
}

export class ConfirmRegistrationModel {
  Code: string;
  Email: string;
  AcceptTerms: boolean;
}

export class ConfirmRegistrationResponseModel {
  Token: string;
  Email: string;
  Message: string;
  Success: boolean;
  HasJobs: boolean;
}
