<oms-header title="Batteries" *ngIf="!modalView">
  <ion-back-button defaultHref="/facilities"></ion-back-button>
</oms-header>
<ion-header *ngIf="modalView">
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Batteries
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div *ngIf="!loading">
    <ion-card *ngIf="asset != null && asset.Batteries.length === 0" class="ion-text-center">
      <ion-card-header>
        <ion-card-title *ngIf="asset != null">Batteries in {{asset.Description}}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="ion-padding-bottom" *ngIf="asset != null">
          You haven't added any batteries yet to the {{asset.SiteAssetTypeDescription}} <b>{{asset.Description}}</b>.
        </div>
      </ion-card-content>
    </ion-card>
    <ng-container *ngIf="asset">
      <ion-item *ngIf="autoScanEnabled">
        <ion-label>
          <div>
            Auto Scan
          </div>
          <ion-note>Automatically scan consecutive batteries</ion-note>
        </ion-label>
        <ion-toggle [(ngModel)]="autoScan" slot="end" [disabled]="preview" (click)="stopAutoScanIfNeeded()"></ion-toggle>
      </ion-item>
      <ion-list class="list--padding-with-fab-btn">
        <asset-path-info *ngIf="asset && facility && asset.Batteries && asset.Batteries.length > 0"
          [facility]="facility" [assetId]="asset.Id" [preview]="preview"></asset-path-info>
        <div class="ion-margin-top"></div>
        <!-- <ion-virtual-scroll [items]="asset.Batteries | orderBy: 'asc' : 'Position'" approxItemHeight="100px" [trackBy]="trackByFn"> -->
        <!-- <ion-item *virtualItem="let item" [class.asset-highlight]="item.Highlight" style="opacity:1;"> -->
        <ion-item *ngFor="let item of asset.Batteries | orderBy: 'asc' : 'Position'"
          [class.asset-highlight]="item.Highlight">
          <div class="battery-item--container">
            <div class="battery-item--first-row">
              <div class="battery-item--battery-type-label-text text-truncate">
                <ion-text color="primary">{{ item.Position }}</ion-text> - {{ getTypeText(item) }}
              </div>
              <div class="battery-actions-container">
                <ion-icon name="barcode-outline" (click)="scanSerialNumber(item)" *ngIf="!preview" slot="end"
                  style="font-size: 1.5rem;">
                </ion-icon>
                <span (click)="takePhoto(item)" slot="end" *ngIf="!preview" class="battery-item-photo">
                  <ion-icon name="camera" [color]="item.ImageCount > 0 ? 'primary' : ''">
                  </ion-icon>
                  <span class="count">{{item.ImageCount || 0}}</span>
                </span>
                <ion-button (click)="more(item, $event)" slot="end" *ngIf="!preview">
                  <i class="far fa-ellipsis-v"></i>
                </ion-button>
              </div>
            </div>
            <div class="battery-item--additional-info">
              <div class="battery-item--additional-info-data"
                *ngIf="!item.SerialNumber && item.ConditionId == null && (!item.DateManufactured || item.DateCode == null) && !item.DateInstalled">
                No data provided
              </div>
              <div *ngIf="item.SerialNumber" class="battery-item--additional-info-data">
                SN: {{ item.SerialNumber }}
              </div>
              <div *ngIf="item.ConditionId != null" class="battery-item--additional-info-data">
                Condition: {{ getConditionText(item) }}
              </div>
              <!-- <div *ngIf="item.DateManufactured && !isTypeD" class="battery-item--additional-info-data">
                  Manufactured: {{ item.DateManufactured | date: 'MM/dd/yyyy' }}
                </div> -->
              <div *ngIf="item.DateCode" class="battery-item--additional-info-data">
                Date Code: {{ item.DateCode }}
              </div>
              <div *ngIf="item.DateInstalled" class="battery-item--additional-info-data">
                Installed: {{ item.DateInstalled | date: 'MM/dd/yyyy' }}
              </div>
            </div>
          </div>
        </ion-item>
        <!-- </ion-virtual-scroll> -->
      </ion-list>
    </ng-container>
  </div>
  <div *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </div>
</ion-content>
<ion-footer *ngIf="!loading && modalView">
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="dismissModal()" color="light" *ngIf="modalView">
        <ion-label>Close</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>