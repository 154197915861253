import { Component } from "@angular/core";
import { AppInfo } from "src/app/globals/AppInfo";
import { ApiService } from "src/app/shared/services/api/api.service";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { SyncQueueService } from "src/app/sync/sync-queue.service";

@Component({
  selector: "oms-send-logs",
  templateUrl: "send-logs.component.html"
})
export class SendLogsComponent {
  sendingLogs: boolean;
  sendingPing: boolean;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private syncQueueService: SyncQueueService,
    private cameraHelperService: CameraHelperService,
    private apiService: ApiService,
    private userService: UserService
  ) {
  }

  async sendLogs() {
    if (this.sendingLogs) {
      return;
    }
    try {
      this.sendingLogs = true;
      let pendingDataCount = await this.syncQueueService.getItemsToSyncCount();
      var pendingImageCount = (await this.cameraHelperService.getImagesTaken()).length;
      var images = (await this.cameraHelperService.getImagesTaken()).map(x => x.name);;
      var checklistQuestionsData = await this.syncQueueService.getItemsToSync("checklist");
      var user = await this.userService.getLoggedInUser();
      var userId = 0;
      var username = "";
      if (user == null) {
        username = prompt("Please enter your name");
      } else {
        userId = user.UserId;
      }
      var issue = prompt("Please explain the issue");
      this.errorHandlerService.sendLogs({
        userId,
        username,
        issue,
        deviceuuid: AppInfo.UUID || "",
        version: AppInfo.Version || "",
        platform: AppInfo.Platform || "",
        pendingDataCount,
        pendingImageCount,
        images,
        questionsSyncData: checklistQuestionsData || "no data",
      });
    } catch (err) {
      alert("Error occurred: " + JSON.stringify(err));
    } finally {
      this.sendingLogs = false;
    }
  }

  async ping() {
    if (this.sendingPing) {
      return;
    }
    try {
      this.sendingPing = true;
      let response = await this.apiService.ping();
      alert("Success");
    } catch (err) {
      alert("an error occurred: " + JSON.stringify(err));
    } finally {
      this.sendingPing = false;
    }
  }
}
