import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { ChecklistService } from "./checklist.service";
import { Facility } from "../data/entities/Facility";
import { FacilityChecklist } from "../data/entities/FacilityChecklist";
import * as _ from "lodash";
import { CameraHelperService } from "../shared/services/plugin-helpers/camera-helper.service";
import { WebAppShowComponent } from "../webapp/web-app.component";
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";

@Component({
  selector: "checklist",
  templateUrl: "checklist.component.html"
})
export class ChecklistComponent implements OnInit, OnDestroy {
  facility: Facility;
  checklist: FacilityChecklist;
  loading: boolean = false;
  mode: string = 'summary';
  title: string;
  preview: boolean;
  isWeb: boolean = false;

  public static currentSectionIndex: number = 0;

  constructor(
    private checklistService: ChecklistService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cameraHelperService: CameraHelperService,
    private browserHelperService: BrowserHelperService) { }

  async ngOnInit() {
    if (WebAppShowComponent.IsWebAppIFrame) {
      this.isWeb = true;
    }
    this.mode = "summary";
    ChecklistComponent.currentSectionIndex = 0;
    this.loading = true;
    let siteChecklistId = this.activatedRoute.snapshot.params["id"];
    this.title = this.activatedRoute.snapshot.params["title"] || this.title;
    this.preview = this.preview || this.activatedRoute.snapshot.params["preview"] == "true";
    this.facility = await this.checklistService.getFacilityByChecklistId(siteChecklistId);
    this.checklist = await this.checklistService.getChecklistById(siteChecklistId);
    await this.setImageCount();
    if (this.checklist.Id == this.facility.SiteWalkChecklistId) {
      this.title = "Site Walk";
    } else {
      this.title = "Checklist"
    }
    this.setView();
    this.loading = false;
  }

  async ionViewWillEnter() {
    this.setView();
    ChecklistComponent.currentSectionIndex = 0;
  }

  async ngOnDestroy() {
  }

  switchMode(mode: string) {
    this.mode = mode;
  }

  private setView() {
    this.mode = this.checklist.SectionsModel.filter(x => x.Questions.some(y => !y.Hidden)).length > 1 ? "summary" : "list";
    if (this.router.url.indexOf("openFirstSection") > 0) {
      this.switchMode("list");
      ChecklistComponent.currentSectionIndex = _.findIndex(this.checklist.SectionsModel, s => s.IsReviewSection) || 0;
    }
  }

  private async setImageCount(): Promise<void> {
    if (this.browserHelperService.isNativeApp()) {
      try {
        let allImages = await this.cameraHelperService.getImagesTaken();
        allImages = allImages.concat(await this.cameraHelperService.getImagesUploaded());
        for (let section of this.checklist.SectionsModel) {
          for (let question of section.Questions) {
            question.ImageCount = allImages
              .filter(x => this.cameraHelperService.getChecklistId(x.name) == this.checklist.Id &&
                this.cameraHelperService.getVendorViewId(x.name) == question.VendorViewPK)
              .length;
          }
        }
      }
      catch { }
    }
  }
}