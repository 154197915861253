<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Upload Battery Tests to Assets
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" *ngIf="!loading">
  <div *ngIf="assets.length === 0" class="ion-text-center ion-padding">
    There are no Assets selected for this job.
  </div>
  <ion-list>
    <ng-container *ngFor="let item of assets">
      <ion-item *ngIf="item.Asset" [class.asset-highlight]="item.Asset.Highlight"
        style="align-items: center; border-bottom: 1px solid lightgray" lines="none">
        <ion-label (click)="previewAsset(item)">
          <ion-note *ngIf="item.AssetPathString" class="text-italic">
            Belongs To: {{item.AssetPathString}}
          </ion-note>
          <div>
            {{item.Asset.Description}}
          </div>
          <ion-note *ngIf="item.Asset.SiteAssetTypeDescription != item.Asset.Description">
            {{item.Asset.SiteAssetTypeDescription}}
          </ion-note>
          <div *ngIf="!item.Asset.BatteryTestEnabled">
            <ion-note style="color: red;">
              Battery test upload not enabled for this asset type
            </ion-note>
          </div>
        </ion-label>
        <ion-icon name="checkmark-done-outline" color="success" *ngIf="item.BatteryTests > 0" slot="end"></ion-icon>
        <ion-button slot="end" color="primary" (click)="upload(item)" *ngIf="!preview && item.Asset.BatteryTestEnabled">
          Upload
          <ion-icon name="cloud-upload-outline" slot="end"></ion-icon> ({{item.BatteryTests || 0}})
        </ion-button>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>
<ion-content class="ion-padding" *ngIf="loading">
  <ion-item *ngFor="let i of [1,2,3,4,5]">
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-content>
<ion-footer *ngIf="!loading">
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="cancel()" color="light">
        <ion-label>Close</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>