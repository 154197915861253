import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, ObservableInput } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { empty } from 'rxjs';
import { throwError } from 'rxjs';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //return next.handle(request).pipe(catchError(this.errorHandler));
    return next.handle(request).pipe(catchError((error) => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'], { replaceUrl: true });
        // this response is handled
        // stop the chain of handlers by returning empty
        return empty();
      }
      // rethrow so other error handlers may pick this up
      return throwError(error);
    }));
  }
}
