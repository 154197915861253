import { Component, Input, OnInit } from "@angular/core";
import { Facility } from "../../data/entities/Facility";
import { Router } from "@angular/router";
import { AssetsService } from "../assets.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "asset-lookup-button",
  templateUrl: "asset-lookup-button.component.html"
})
export class AssetLookupButtonComponent implements OnInit {
  @Input()
  facility: Facility;

  private canMaintainAssets: boolean;

  constructor(
    private assetsService: AssetsService,
    private router: Router,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.canMaintainAssets = await this.userService.canMaintainAssets();
  }

  async assetScanLookup() {
    const text = await this.barcodeScannerHelperService.scan();
    if (text) {
      let asset = this.assetsService.getAssetFromFacilityBySerial(this.facility, text);
      if (asset) {
        this.router.navigate([`/facilities/${this.facility.Id}/assets/${asset.ParentId}/highlight/${asset.Id}/${!this.canMaintainAssets}`]);
        return;
      } else {
        let batteryData = this.assetsService.getAssetFromFacilityByBatterySerial(this.facility, text);
        if (batteryData) {
          let batteryKey = batteryData[0];
          let batteryAsset = batteryData[1];
          if (batteryAsset) {
            this.router.navigate([`/facilities/${this.facility.Id}/assets/${batteryAsset.Id}/batteries/highlight/${batteryKey}/${!this.canMaintainAssets}`]);
            return;
          }
        }
      }
      alert("No assets found");
    }
  }
}
