import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../../my-work.service";
import { JobDataModel } from "src/app/checklist/checklist.models";
import { Facility } from "src/app/data/entities/Facility";
import { ModalController } from "@ionic/angular";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { AddTimeComponent } from "../add/add-time.component";
import { UserTimeTrackingModel } from "../../my-work.models";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { AssetsService } from "src/app/assets/assets.service";

@Component({
  selector: "time-list",
  templateUrl: "time-list.component.html"
})
export class TimeListComponent implements OnInit {
  @Input()
  checklistId: number;
  subtitle: string;
  subtitle2: string;
  timeList: UserTimeTrackingModel[] = [];
  isWeb: boolean = false;
  loading: boolean = false;
  totalHours: number = 0;
  totalMiles: number = 0;
  totalWorkingHours: number;
  totalDrivingMiles: number;
  totalFlyingMiles: number;
  totalOtherHours: number;
  totalOtherMiles: number;
  summary: boolean;

  constructor(
    private myWorkService: MyWorkService,
    private modalController: ModalController,
    private pubSubService: PubSubService,
    private assetsService: AssetsService,
    private browserHelperService: BrowserHelperService,
    private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    this.checklistId = this.activatedRoute.snapshot.params["checklistId"] || undefined;
    if (this.checklistId == 0) this.checklistId = null;
    if (this.checklistId) {
      const jobModel = await this.myWorkService.getJob(this.checklistId);
      if (jobModel) {
        const facility = await this.assetsService.getFacilityWithAssets(jobModel.SiteKey);
        if (facility) {
          this.subtitle = 'Location: ' + facility?.Name;
          this.subtitle2 = 'Job: #' + jobModel?.ChecklistId + ', Project: ' + jobModel?.ProjectName;
        }
      }
    }
    this.pubSubService.$sub(EventConstants.JOB_TIME_ADD_UPDATE, async () => {
      this.loadData();
    });

    this.loadData();
    this.isWeb = WebAppShowComponent.IsWebAppIFrame;
  }

  async edit(model: UserTimeTrackingModel){
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const modal = await this.modalController.create({
      component: AddTimeComponent,
      componentProps: {
        checklistId: this.checklistId,
        model: model
      }
    });
    return await modal.present();
  }

  async add() {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const modal = await this.modalController.create({
      component: AddTimeComponent,
      componentProps: {
        checklistId: this.checklistId
      }
    });
    return await modal.present();
  }

  async loadData() {
    try {
      this.loading = true;
      this.timeList = (await this.myWorkService.getJobTimeTracking(this.checklistId)).Items;
      this.totalHours = 0;
      this.totalMiles = 0;

      this.totalWorkingHours = 0;
      this.totalDrivingMiles = 0;
      this.totalFlyingMiles = 0;
      this.totalOtherHours = 0;
      this.totalOtherMiles = 0;

      for (let item of this.timeList) {
        this.totalHours += item.Time || 0;
        this.totalMiles += item.Mileage || 0;

        this.totalWorkingHours += item.WorkingHours || 0;
        this.totalDrivingMiles += item.DrivingMiles || 0;
        this.totalFlyingMiles += item.FlyingMiles || 0;
        this.totalOtherHours += item.OtherHours || 0;
        this.totalOtherMiles += item.OtherMiles || 0;
      }
    } finally {
      this.loading = false;
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  isFirstOfThisDate(item: UserTimeTrackingModel) {
    const filteredList = this.timeList.filter(x => x.DateText == item.DateText);
    return filteredList[0] == item;
  }

  toggleSummary() {
    this.summary = !this.summary;
  }

}