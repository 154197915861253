import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RepositoryService } from "src/app/data/db/repository.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { PubSubService } from "../pubsub/pub-sub.service";

@Injectable({
  providedIn: 'root'
})
export class PreviewService {
  static readonly supportedUrls = [
    "/facilities",
    "/checklist",
    "/mywork/work-details",
    "/gallery"
  ];

  constructor(
    private pubSubService: PubSubService,
    private router: Router,
    private repositoryService: RepositoryService
  ) {
    if (!window["previewData"]) {
      //this.setPreviewOff();
    }
  }

  // async setAllOnBasedOnChecklistId(jobId: number) {
  //   const checklist = await this.repositoryService.getFacilityChecklistRepository().findOne(jobId);
  //   const facility = await this.repositoryService.getFacilityRepository().findOne(checklist.SiteKey);
  //   this.setJobPreviewOn(jobId);
  //   this.setJobPreviewOn(facility.SiteWalkChecklistId);
  //   this.setJobPreviewOn(facility.SiteGalleryChecklistId);
  //   this.setSitePreviewOn(checklist.SiteKey);
  //   this.previewData.jobPreview.jobId = jobId;
  // }

  // setJobPreviewOn(jobId: number) {
  //   this.previewData.jobPreview[jobId] = true;
  //   this.previewData.jobPreview.jobId = jobId;
  //   this.pubSubService.$pub(EventConstants.PREVIEW_STAMP_ON);
  // }

  // setSitePreviewOn(siteKey: string) {
  //   this.previewData.sitePreview[siteKey] = true;
  //   this.pubSubService.$pub(EventConstants.PREVIEW_STAMP_ON);
  // }

  // isSitePreview(siteKey: string) {
  //   return this.previewData.sitePreview[siteKey] == true;
  // }

  // isJobPreview(jobId: number) {
  //   return this.previewData.jobPreview[jobId] == true;
  // }

  // isPreviewOn() {
  //   return this.supportedUrls.some(x => this.router.url.indexOf(x) >= 0) &&
  //     this.previewData.jobPreview.jobId &&
  //     this.previewData.jobPreview[this.previewData.jobPreview.jobId] === true;
  // }

  setPreviewOff() {
    this.pubSubService.$pub(EventConstants.PREVIEW_STAMP_OFF);
  }

  setPreviewOn(jobId: number) {
    this.pubSubService.$pub(EventConstants.PREVIEW_STAMP_ON, jobId);
  }

  // getJobUrl() {
  //   if (this.previewData.jobPreview.jobId) {
  //     return `/mywork/work-details/${this.previewData.jobPreview.jobId}`;
  //   }
  // }

  // private get previewData(): PreviewData {
  //   return window["previewData"];
  // }
}

class PreviewData {
  jobPreview: {
    jobId: number
  };
  sitePreview: {}
}
