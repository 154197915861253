import { Injectable } from '@angular/core';
import { Coordinates, Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import * as moment from 'moment';

@Injectable({
  providedIn: "root"
})
export class GeolocationService {

  private static lastActivity;
  private static coords: Coordinates = {
    latitude: null,
    longitude: null
  } as Coordinates;

  constructor(
    private geolocation: Geolocation,
  ) { }

  setCurrentLocation() {
    this.geolocation.getCurrentPosition({
      enableHighAccuracy: false,
      maximumAge: 0,
      timeout: 10000
    }).then((position: Geoposition) => {
      GeolocationService.coords = position.coords;
      GeolocationService.lastActivity = moment().utc();
    })
  }

  getCurrentLocation(): Coordinates {
    var duration = moment.duration(moment().utc().diff(GeolocationService.lastActivity || moment().utc())).asSeconds();
    if (duration > 60 * 10) {
      this.setCurrentLocation();
    }
    return GeolocationService.coords;
  }

  async getCurrentPosition(): Promise<Geoposition> {
    return this.geolocation.getCurrentPosition({
      enableHighAccuracy: false,
      maximumAge: 0,
      timeout: 10000
    });
  }
}