<div class="checklist-questions-group" *ngIf="section">
    <h3 class="checklist-section-header" [class.header-hidden]="this.landscape && this.keyboardOpen">
        <i class="fal fa-chevron-left ion-padding-start ion-padding-end float-left"
            style="position: absolute; left: 0; top: 0.8rem"
            [style.visibility]="(sections.length > 0 && currentSectionIndex > 0) ? 'visible' : 'hidden'"
            (click)="prev()"></i>
        <span class="ion-padding-start ion-padding-end header">
            {{section.Name}}
        </span>
        <i class="fal fa-chevron-right ion-padding-start ion-padding-end float-right"
            [style.visibility]="(sections.length > 0 && currentSectionIndex < sections.length - 1) ? 'visible' : 'hidden'"
            style="position: absolute; right: 0; top: 0.8rem" (click)="next()"></i>
    </h3>
    <checklist-questions [checklist]="checklist" [section]="section" [preview]="preview" [class.landscape-with-keyboard]="this.landscape && this.keyboardOpen"></checklist-questions>
</div>