<ion-list *ngIf="!loading">
    <ng-container *ngFor="let section of checklist.SectionsModel; let i = index">
        <ion-item (click)="goToSection(i)" [class.section-highlight]="isSelected(i)" *ngIf="sectionVisible(section)">
            <ion-label>
                <div>
                    {{section.Name}}
                </div>
                <ion-note *ngIf="section.Summary.lastUpdatedDate">
                    Updated on: {{section.Summary.lastUpdatedDate | localdatetime}}
                </ion-note>
            </ion-label>
            <ion-badge *ngIf="section.Summary.imageCount > 0" class="ion-margin-end" color="medium">
                <i class="fas fa-image color-white"></i> {{section.Summary.imageCount}}
            </ion-badge>
            <ion-badge color="danger" class="ion-margin-end" *ngIf="section.Summary.requiredCount > 0">
                <i class="fas fa-asterisk color-white"></i>
                {{section.Summary.requiredAnsweredCount}}/{{section.Summary.requiredCount}}
            </ion-badge>
            <ion-badge>
                {{section.Summary.totalAnsweredCount}}/{{section.Summary.totalCount}}
            </ion-badge>
        </ion-item>
    </ng-container>
</ion-list>