import { Component, Input, OnInit } from "@angular/core";
import * as _ from "lodash";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { Facility } from "src/app/data/entities/Facility";
import { ModalController } from "@ionic/angular";
import { GalleryImage } from "src/app/gallery/gallery.models";

@Component({
  selector: "asset-quote-select-images",
  templateUrl: "asset-quote-select-images.component.html"
})
export class AssetQuoteSelectImagesComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  checklistId: number;
  @Input()
  asset: SiteAsset;
  @Input()
  useSelectedCallback: (selected: number[]) => {};
  @Input()
  selectedImages: number[];
  newSelectedImages: number[] = [];

  constructor(
    private modalController: ModalController
  ) { }

  async ngOnInit() {
    this.selectedImages = this.selectedImages || [];
    this.newSelectedImages = [...this.selectedImages];
  }

  onImageSelected(image: GalleryImage) {
    this.newSelectedImages.push(image.ImageId);
  }

  onImageDeselected(image: GalleryImage) {
    this.newSelectedImages = _.filter(this.newSelectedImages, x => x != image.ImageId);
  }

  useSelected() {
    this.useSelectedCallback(this.newSelectedImages);
  }

  onSetImageSelectedIds(imageIds: number[]) {
    this.newSelectedImages = imageIds;
  }

  cancel() {
    this.modalController.dismiss();
  }
}