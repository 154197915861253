import { Component, Injector, OnInit } from "@angular/core";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { AppInfo } from "../../globals/AppInfo";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";

@Component({
  selector: "oms-help",
  templateUrl: "help.component.html"
})
export class HelpComponent implements OnInit {
  version: string;
  constructor(
    private appVersion: AppVersion,
    private browserHelperService: BrowserHelperService
  ) {
  }

  async ngOnInit() {
    try {
      let version = await this.appVersion.getVersionNumber();
      if (AppInfo.AppSubVersion) {
        version = version + "-" + AppInfo.AppSubVersion;
      }
    } catch{ }
  }

  openLink(url: string) {
    this.browserHelperService.openUrl(url);
  }
}
