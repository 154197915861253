import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { RepositoryService } from "../data/db/repository.service";
import { LoaderHelperService } from "../shared/services/browser/loader-helper.service";
import { MenuController, AlertController, ModalController } from "@ionic/angular";
import * as EventsConstants from "../shared/constants/events-constants";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import { UserService } from "../shared/services/user-service/user-service.service";
import { Subscription } from "rxjs";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { SyncService } from "../sync/sync.service";
import * as _ from "lodash";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import { EnvironmentService } from "../shared/services/environment-service/environment.service";
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";
import { NewJobComponent } from "../my-work/new-job/new-job.component";
import { NewWarehouseBuildComponent } from "../my-work/new-warehouse-build/new-warehouse-build.component";

@Component({
  selector: "oms-menu",
  templateUrl: "menu.component.html"
})
export class MenuComponent implements OnInit, OnDestroy {
  syncInProgress: boolean;
  reloadInProgress: boolean;
  pendingCount: number = 0;
  userFullName: string;
  mobileCreateJobsEnabled: boolean;
  mobileWarehouseBuildEnabled: boolean;
  mobileTimeOffEnabled: boolean;
  mobileTimeTrackingEnabled: boolean;

  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscription4: Subscription;

  constructor(
    private router: Router,
    private repository: RepositoryService,
    private loader: LoaderHelperService,
    private menu: MenuController,
    private alertController: AlertController,
    private pubSub: PubSubService,
    private userService: UserService,
    private syncService: SyncService,
    private errorHandlerService: ErrorHandlerService,
    private environmentService: EnvironmentService,
    private browserHelperService: BrowserHelperService,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
    this.setUserData()
    this.subscription1 = this.pubSub.$sub(EventConstants.SYNC_DONE, async () => {
      this.syncInProgress = false;
      await this.setPendingChanges();
    });
    this.subscription2 = this.pubSub.$sub(EventConstants.SYNC_STARTED, async () => {
      this.syncInProgress = true;
      await this.setUserData();
    });
    this.subscription3 = this.pubSub.$sub(EventConstants.LOG_IN, async () => {
      await this.setUserData();
    });
    this.subscription4 = this.pubSub.$sub(EventConstants.SYNC_UPDATE, async () => {
      await this.setPendingChanges();
    });
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscription4.unsubscribe();
  }

  menuWillOpen() {
    this.setPendingChanges();
  }

  async mySites() {
    this.router.navigate(["facilities"], { replaceUrl: true });
  }

  async myWork() {
    this.router.navigate(["mywork/list"], { replaceUrl: true });
  }

  async warranty() {
    this.pubSub.$pub(EventsConstants.NEW_WARRANTY);
    this.router.navigate(["warranty/list"]);
    this.menu.close();
  }

  async about() {
    this.router.navigate(["/about"]);
  }

  async help() {
    this.router.navigate(["/help"]);
  }

  timeOff() {
    this.router.navigate(["/timetracking/timeoff"]);
  }

  timeTracking() {
    this.router.navigate([`timetracking/0/false`]);
  }

  async website() {
    const url = await this.environmentService.getRootUrl(await this.environmentService.getEnvironment());
    this.browserHelperService.openUrl(url);
  }

  async pendingChanges() {
    this.router.navigate(["/pending-changes"]);
  }

  async newJob() {
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    let modal = await this.modalController.create({
      component: NewJobComponent,
      componentProps: {
        qcJob: true
      }
    });
    await modal.present();
    await this.menu.close();
  }

  async newWarehouseBuild() {
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    let modal = await this.modalController.create({
      component: NewWarehouseBuildComponent
    });
    await modal.present();
    await this.menu.close();
  }

  async signOut() {
    let totalDataCount = 0;
    try {
      totalDataCount = await this.syncService.getPendingTotalCount();
    } catch (err) {
      this.errorHandlerService.logError(err);
    }
    if (totalDataCount > 0) {
      const errorAlert = await this.alertController.create({
        header: "Sign Out not possible",
        message:
          "You can't Sign Out until all your changes are transfered!",
        buttons: [
          {
            text: "Okay",
          }
        ]
      });
      await errorAlert.present();
      return;
    }
    const alert = await this.alertController.create({
      header: "Sign Out?",
      message:
        "Are you sure you want to Sign Out?",
      buttons: [
        {
          text: "Yes I am sure",
          handler: async () => {
            await this.repository.cleanDb();
            await this.userService.setLoggedInUser(null);
            this.pubSub.$pub(EventsConstants.LOG_OUT);
            this.router.navigate(["login"], { replaceUrl: true });
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        }
      ]
    });
    return await alert.present();
  }

  private async setPendingChanges() {
    this.pendingCount = await this.syncService.getPendingTotalCount();
  }

  private async setUserData() {
    let user = await this.userService.getLoggedInUser();
    if (user) {
      this.userFullName = user.FullName;
      this.mobileCreateJobsEnabled = user.Config.MobileCreateJobsEnabled;
      this.mobileWarehouseBuildEnabled = user.Config.MobileWarehouseBuildEnabled;
      this.mobileTimeOffEnabled = user.Config.MobileTimeOffEnabled;
      this.mobileTimeTrackingEnabled = user.Config.MobileTimeTrackingEnabled;
    };
  }
}
