<oms-header [title]="'Battery Tests'" [subtitle]="'Location: ' + (facility?.Name || '')" [subtitle2]="subtitle" [menu]="!fromDialog">
  <ion-back-button defaultHref="/facilities" *ngIf="!fromDialog"></ion-back-button>
  <ion-button (click)="close()" *ngIf="fromDialog" fill="clear">
    <ion-icon name="arrow-back-outline"></ion-icon>
  </ion-button>
</oms-header>
<ion-content>
  <battery-test-list *ngIf="facility && asset" [facility]="facility" [checklistId]="checklistId" [asset]="asset" [fromDialog]="fromDialog"></battery-test-list>
</ion-content>
<ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="!loading">
  <ion-fab-button (click)="addTest()" color="primary">
    <ion-icon name="add"></ion-icon>
  </ion-fab-button>
</ion-fab>