import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../../my-work.service";
import { JobDataModel } from "src/app/checklist/checklist.models";
import { Facility } from "src/app/data/entities/Facility";
import { IonDatetime, ModalController } from "@ionic/angular";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { AddTimeOffComponent } from "../add/add-timeoff.component";
import { UserTimeOffDayModel, UserTimeOffModel } from "../../my-work.models";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import * as moment from "moment";

@Component({
  selector: "timeoff",
  templateUrl: "timeoff.component.html"
})
export class TimeOffComponent implements OnInit {
  @Input()
  jobModel: JobDataModel;
  ytdDays: UserTimeOffDayModel[] = [];
  lastYearDays: UserTimeOffDayModel[] = [];
  facility: Facility;
  timeOff: UserTimeOffModel = { Days: [] };
  isWeb: boolean = false;
  loading: boolean = false;
  daysOff: string[];
  forUser: string;
  userId: number | null;
  filter: "upcoming" | "ytd" | "lastyear" = "upcoming";

  @ViewChild(IonDatetime, { static: false }) ionDatetime: IonDatetime;

  constructor(
    private myWorkService: MyWorkService,
    private modalController: ModalController,
    private pubSubService: PubSubService,
    private browserHelperService: BrowserHelperService,
    private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    let jobId = this.activatedRoute.snapshot.params["checklistId"];
    this.jobModel = await this.myWorkService.getJob(jobId);

    this.pubSubService.$sub(EventConstants.TIME_OFF_ADD_UPDATE, async () => {
      this.loadData();
    });

    this.loadData();
    this.isWeb = WebAppShowComponent.IsWebAppIFrame;
    try {
      this.modalController.dismiss();
    } catch { }
    if (window["timeOffForUser"]?.id && window["timeOffForUser"]?.name) {
      this.userId = window["timeOffForUser"]?.id;
      this.forUser = `for ${window["timeOffForUser"]?.name}`;
    }
  }

  async edit() {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const modal = await this.modalController.create({
      component: AddTimeOffComponent,
      componentProps: {
      }
    });
    return await modal.present();
  }


  segmentChanged(event: any) {
    var segment = event.detail.value;
    this.loadData();
  }

  ionCalendarChange(event) {
  }

  async loadData() {
    try {
      this.loading = true;
      this.timeOff.Days = (await this.myWorkService.getTimeOff(this.userId, "upcoming")).Items;
      this.myWorkService.getTimeOff(this.userId, "ytd").then(result => {
        this.ytdDays = result.Items;
      });
      this.myWorkService.getTimeOff(this.userId, "lastyear").then(result => {
        this.lastYearDays = result.Items;
      });
    } finally {
      this.loading = false;
    }
  }

  async dismissModal() {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "close-modal" }, "*");
    } else {
      await this.modalController.dismiss();
    }
  }

  formatDate(day: string) {
    return moment(day).format("MMM DD, YYYY");
  }
}