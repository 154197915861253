import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Facility } from "src/app/data/entities/Facility";
import * as _ from "lodash";
import { ChecklistSummaryModel } from "../checklist.models";
import { ChecklistService } from "../checklist.service";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";

@Component({
  selector: "checklist-button",
  templateUrl: "checklist-button.component.html"
})
export class ChecklistButtonComponent implements OnInit, OnDestroy {
  @Input()
  facility: Facility;
  @Input()
  title: string;
  @Input()
  checklistId: number;
  @Input()
  icon: string = "fas fa-walking";
  @Input()
  showSummary: boolean;
  @Input()
  preview: boolean;
  summary: ChecklistSummaryModel;
  loading: boolean = true;

  private subscription: Subscription;
  
  constructor(
    private checklistService: ChecklistService,
    private router: Router,
    private modalController: ModalController,
    private pubSubService: PubSubService) {
  }

  async ngOnInit() {
    this.loading = true;
    this.summary = this.checklistService.getFacilityChecklistSummary(await this.checklistService.getChecklistById(this.checklistId));
    this.loading = false;
    this.subscription = this.pubSubService.$sub(EventConstants.UPDATE_CHECKLIST_BUTTON_SUMMARY, async () => {
      this.summary = this.checklistService.getFacilityChecklistSummary(await this.checklistService.getChecklistById(this.checklistId));
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async goToChecklist() {
    try {
      await this.modalController.dismiss();
    } catch { }
    this.router.navigate([`/checklist/${this.checklistId}/${this.preview}/open`]);
  }
}