<div class="quote-filter-container">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-searchbar [(ngModel)]="query" (ngModelChange)="startSearch()" debounce="500" placeholder="Filter assets">
        </ion-searchbar>
      </ion-col>
      <!-- <ion-col size="12">
        <ion-item>
          <ion-select [(ngModel)]="assetType" okText="Okay" cancelText="Dismiss" placeholder="Show All Asset Types"
            (ionChange)="onFilterChange()" class="w-100 ion-padding-bottom">
            <ion-select-option text-wrap [value]="null">
              Show All Asset Types
            </ion-select-option>
            <ion-select-option [value]="i.Id.toString()" *ngFor="let i of assetTypes">{{ i.Description }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col> -->
      <ion-col size="4" class="ion-text-center" *ngIf="hasHeatMapAssets">
        <div>
          <span slot="end" class="fas fa-circle color-danger"></span>
          Replace
        </div>
        <ion-toggle color="danger" [(ngModel)]="fail" (ionChange)="onFilterFailChange()"
          [hidden]="filteringFail == true">
        </ion-toggle>
        <div *ngIf="filteringFail">
          <ion-spinner></ion-spinner>
        </div>
      </ion-col>
      <ion-col size="4" class="ion-text-center" *ngIf="hasHeatMapAssets">
        <div>
          <span slot="end" class="fas fa-circle color-warning"></span>
          Warning
        </div>
        <ion-toggle color="warning" [(ngModel)]="warn" (ionChange)="onFilterWarnChange()"
          [hidden]="filteringWarn == true">
        </ion-toggle>
        <div *ngIf="filteringWarn">
          <ion-spinner></ion-spinner>
        </div>
      </ion-col>
      <ion-col size="4" class="ion-text-center" *ngIf="hasHeatMapAssets">
        <div>
          <span slot="end" class="fas fa-circle color-success"></span>
          Pass
        </div>
        <ion-toggle color="success" [(ngModel)]="pass" (ionChange)="onFilterPassChange()"
          [hidden]="filteringPass == true">
        </ion-toggle>
        <div *ngIf="filteringPass">
          <ion-spinner></ion-spinner>
        </div>
      </ion-col>
    </ion-row>
    <!-- <ion-row>
      <ion-col size="12">
        <span style="float: left;">
          <a (click)="filterOn()" *ngIf="!filterVisible" (click)="filterOn()">Show filter</a>
          <a (click)="filterOff()" *ngIf="filterVisible" (click)="filterOff()">Hide filter</a>
        </span>
        <span style="float: right;" *ngIf="isFiltering()">
          <a (click)="clearFilter()">Clear filter</a>
        </span>
      </ion-col>
    </ion-row> -->
  </ion-grid>
</div>
<h3 class="ion-padding" style="padding-right: 0.25rem;">
  Assets
  <ion-toggle style="float: right;" (ionChange)="onToggleAll($event)" [(ngModel)]="toggleAll">
  </ion-toggle>
  <ion-button size="small" color="light" style="font-size: 10px; float: right" (click)="exportHeatmap()"
    *ngIf="canExportHeatMap">
    Export Heat Map
  </ion-button>
</h3>
<ion-card *ngIf="assets.length === 0" class="ion-text-center">
  <ion-card-content>
    <div class="ion-padding-bottom">
      <div>
        There are no Assets added to this location. Would you like to add Assets now?
        <ion-button (click)="goToAssets()">Add Assets</ion-button>
      </div>
    </div>
  </ion-card-content>
</ion-card>
<ion-list>
  <ng-container *ngFor="let item of scrollModel.loadedData">
    <ion-item *ngIf="!item.Hidden && item.Asset && !item.Battery"
      style="align-items: start; border-bottom: 1px solid lightgray" lines="none">
      <ion-label>
        <ion-note *ngIf="item.AssetPathString" class="text-italic" (click)="goToAsset(item.Asset)">
          Belongs To: {{item.AssetPathString}}
        </ion-note>
        <div (click)="goToAsset(item.Asset)">
          {{item.Asset.Description}}
        </div>
        <ion-note *ngIf="item.Asset.SiteAssetTypeDescription != item.Asset.Description" (click)="goToAsset(item.Asset)">
          {{item.Asset.SiteAssetTypeDescription}}
        </ion-note>
        <ion-note *ngIf=item.BatteryModel>
          {{item.BatteryModel}}
        </ion-note>
        <div *ngIf="item.Asset.Batteries && item.Asset.Batteries.length > 0"
          (click)="goToBatteries(item.Asset, $event)">
          <ion-button>
            <span style="font-size: 10px;">
              Spot<br />Replacement
            </span>
          </ion-button>
        </div>
      </ion-label>
      <asset-heatmap-indicator [facility]="facility" [asset]="item.Asset" [checkSub]="false" slot="end"
        class="quote-heatmap">
      </asset-heatmap-indicator>

      <ng-container *ngIf="item.Asset">
        <span *ngIf="item.Asset.QuoteAcceptedDate" class="ion-margin-start image-selector" (click)="quoteInfo()">
          <i class="fas fa-wrench"></i>
        </span>
        <span *ngIf="item.Asset.QuoteInProcess" class="ion-margin-start image-selector" (click)="quoteInProcessInfo()">
          <span class="quote-in-process">
            <i class="far fa-wrench"></i>
            <span class="quote-in-process-dot"></span>
          </span>
        </span>
      </ng-container>

      <span slot="end" (click)="selectImages(item)" class="image-selector">
        <ion-icon name="image-outline" [ngClass]="item.Asset.ImageCount > 0 ? 'color-primary' : ''"></ion-icon>
        <span class="count">{{item.SelectedImages?.length}}</span>
      </span>
      <div slot="end" class="quote-quantity-wrapper">
        <ion-toggle [(ngModel)]="item.Selected" slot="end" (ionChange)="updateAsset(item, true)"></ion-toggle>
        <input decimal-input custom-max-length="3" inputmode="decimal" [(ngModel)]="item.Quantity" slot="end"
          maxlength="3" positive-numbers-only [style.visibility]="item.Selected ? 'visible' : 'hidden'"
          class="quote-quantity" (keyup)="updateAsset(item, false)" />
      </div>
    </ion-item>

    <ion-item *ngIf="!item.Hidden && item.Asset && item.Battery"
      style="align-items: start; border-bottom: 1px solid lightgray" lines="none">
      <ion-label>
        <div>
          <i class="far fa-car-battery"></i>&nbsp; {{item.Battery.Position}} - {{item.Battery.BatteryTypeName}}
        </div>
      </ion-label>
      <span slot="end" (click)="selectImages(item)" class="image-selector">
        <ion-icon name="image-outline" [ngClass]="item.Battery.ImageCount > 0 ? 'color-primary' : ''"></ion-icon>
        <span class="count">{{item.SelectedImages?.length}}</span>
      </span>
      <div slot="end" class="quote-quantity-wrapper">
        <ion-toggle [(ngModel)]="item.Selected" slot="end" (ionChange)="updateBattery(item, true)"></ion-toggle>
        <input decimal-input [(ngModel)]="item.Quantity" slot="end" maxlength="3" positive-numbers-only
          custom-max-length="3" inputmode="decimal" [style.visibility]="item.Selected ? 'visible' : 'hidden'"
          class="quote-quantity" (keyup)="updateBattery(item, false)" />
      </div>
    </ion-item>

  </ng-container>
</ion-list>
<ion-infinite-scroll threshold="100px" (ionInfinite)="scrollModel.loadData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>