import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { WebAppShowComponent } from './webapp/web-app.component';
import { HelpComponent } from './menu/help/help.component';
import { AboutComponent } from './menu/about/about.component';
import { PendingChangesComponent } from './menu/pending-changes/pending-changes.component';
import { PendingImageDetailsComponent } from './menu/pending-image-details/pending-image-details.component';
import { WebAppQuoteEditComponent } from './webapp/web-app-quote-edit.component';
import { EmptyComponent } from './menu/empty/empty.component';

const routes: Routes = [
  {
    path: "facilitiesweb/:token/:rootURL/editquote/:siteKey/:quoteId", component: WebAppQuoteEditComponent
  },
  {
    path: "facilitiesweb/:token/:rootURL/assets", component: WebAppShowComponent
  },
  {
    path: "register",
    loadChildren: () => import('./register/register.module').then(x => x.RegisterModule)
  },
  {
    path: "assets",
    loadChildren: () => import('./assets/assets.module').then(x => x.AssetsModule)
  },
  {
    path: "checklist/:id/:preview",
    loadChildren: () => import('./checklist/checklist.module').then(x => x.ChecklistModule)
  },
  {
    path: "mywork",
    loadChildren: () => import('./my-work/my-work.module').then(x => x.MyWorkModule)
  },
  {
    path: "gallery",
    loadChildren: () => import('./gallery/gallery.module').then(x => x.GalleryModule)
  },
  {
    path: "warranty",
    loadChildren: () => import('./warranty/warranty.module').then(x => x.WarrantyModule)
  },
  {
    path: "emptyPage",
    component: EmptyComponent
  },
  {
    path: "help",
    component: HelpComponent
  },
  {
    path: "about",
    component: AboutComponent
  },
  {
    path: "pending-changes",
    component: PendingChangesComponent
  },
  {
    path: "pending-image-details",
    component: PendingImageDetailsComponent
  },
  {
    path: "",
    redirectTo: "",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
