import { Component, OnInit, OnDestroy } from "@angular/core";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import { Subscription } from "rxjs";
import * as EventConstants from "src/app/shared/constants/events-constants";
import * as _ from "lodash";
import { NavigationEnd, Router } from "@angular/router";
import { PreviewService } from "../shared/services/preview-service/preview-service.service";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "job-preview-stamp",
  templateUrl: "job-preview-stamp.component.html"
})
export class JobPreviewStampComponent implements OnInit, OnDestroy {
  visible: boolean;

  private subscription1: Subscription;
  private subscription2: Subscription;
  private jobId: number;

  constructor(
    private pubSub: PubSubService,
    private router: Router,
    private previewService: PreviewService,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
    this.subscription1 = this.pubSub.$sub(EventConstants.PREVIEW_STAMP_ON, async (jobId: number) => {
      this.visible = true;
      this.jobId = jobId;
    });
    this.subscription2 = this.pubSub.$sub(EventConstants.PREVIEW_STAMP_OFF, async () => {
      this.visible = false;
      this.jobId = null;
    });
    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.visible = this.visible && PreviewService.supportedUrls.some(x => route.url.indexOf(x) >= 0);
        if (!this.visible) {
          this.jobId = null;
        }
      }
    });
  }

  goToJob() {
    if (this.jobId) {
      const url = `/mywork/work-details/${this.jobId}`;
      this.router.navigate([url]);
      try {
        this.modalController.dismiss();
      } catch { }
    }
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
}
