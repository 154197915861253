import { Component, OnDestroy, Injector, OnInit } from "@angular/core";
import { Platform, ModalController, MenuController, PopoverController, AlertController, PickerController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router, NavigationStart } from "@angular/router";
import { RepositoryService } from "./data/db/repository.service";
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { AppInfo } from "./globals/AppInfo";
import BackgroundFetch from "cordova-plugin-background-fetch";
import { AssetsSyncService } from "./assets/sync/assets-sync.service";
import { LoaderHelperService } from "./shared/services/browser/loader-helper.service";
import { AssetsService } from "./assets/assets.service";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { PubSubService } from "./shared/services/pubsub/pub-sub.service";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { WebAppShowComponent } from "./webapp/web-app.component";
import { ConfigService } from "./shared/services/config/config.service";
import { BrowserHelperService } from "./shared/services/browser/browser-helper.service";
import { SyncService } from "./sync/sync.service";
import { AssetManageService } from "./assets/asset-manage.service";
import { UserService } from "./shared/services/user-service/user-service.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { Subscription } from "rxjs";
import { CameraHelperService } from "./shared/services/plugin-helpers/camera-helper.service";
import { ErrorHandlerService } from "./shared/services/error/error-handler.service";
import { IsNull, Not } from "typeorm";
import { EnvironmentService } from "./shared/services/environment-service/environment.service";
import { FacilityService } from "./assets/facility.service";
import { AssetQuoteService } from "./assets/services/quotes/asset-quote.service";
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { RegisterService } from "./register/register.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {

  menuVisible: boolean = true;
  initialized: boolean = false;

  private syncInterval: number;
  private assetsManageService: AssetManageService;
  private assetsService: AssetsService;
  private facilityService: FacilityService;
  private syncService: SyncService;
  private loginSubscription: Subscription;
  private logoutSubscription: Subscription;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private repositoryService: RepositoryService,
    private appVersion: AppVersion,
    private uniqueDeviceID: UniqueDeviceID,
    private injector: Injector,
    private loaderHelperService: LoaderHelperService,
    private pubSub: PubSubService,
    private keyboard: Keyboard,
    private geolocation: Geolocation,
    private configService: ConfigService,
    private browserHelperService: BrowserHelperService,
    public modalController: ModalController,
    private userService: UserService,
    private environmentService: EnvironmentService,
    private menuController: MenuController,
    private cameraHelperService: CameraHelperService,
    private errorHandlerService: ErrorHandlerService,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private pickerController: PickerController,
    private assetQuoteService: AssetQuoteService,
    private permissions: AndroidPermissions,
    private registerService: RegisterService
  ) { }

  async ngOnInit() {
    await this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready();

    // const originalConsoleError = console.error;

    // console.error = function (...args) {
    //   alert("Error: " + args.map(x => JSON.stringify(x)).join(' '));
    //   return originalConsoleError.apply(this, args);
    // }

    // const originalConsoleWarning = console.warn;

    // console.warn = function (...args) {
    //   alert("Warn: " + args.map(x => JSON.stringify(x)).join(' '));
    //   return originalConsoleWarning.apply(this, args);
    // }

    this.statusBar.styleDefault();
    this.keyboard.hideFormAccessoryBar(false);
    try {
      try {
        AppInfo.Platform =
          (this.platform.is("ios")
            ? "ios"
            : this.platform.is("android")
              ? "android"
              : "web") || "";
        AppInfo.Version = (await this.appVersion.getVersionNumber()) || "";
        AppInfo.UUID = (await this.uniqueDeviceID.get()) || "";
      } catch (err) {
        this.errorHandlerService.logError(err);
      }

      try {
        if (AppInfo.Platform == "android") {
          await this.permissions.requestPermissions([this.permissions.PERMISSION.CAMERA, this.permissions.PERMISSION.READ_EXTERNAL_STORAGE, this.permissions.PERMISSION.WRITE_EXTERNAL_STORAGE]);
        }
      } catch (err) {
        alert("Permissions error - " + JSON.stringify(err) + err.toString());
      }

      try {
        await this.repositoryService.initDb();
      } catch (err) {
        alert("Init DB error - " + JSON.stringify(err) + err.toString());// code { 0 }
      }

      try {
        await this.cameraHelperService.createDirectories();
      } catch (err) {
        alert("Error while creating directories to store images: " + JSON.stringify(err) + err.toString());
      }

      try {
        this.configureBackgroundProcess();
      } catch (err) {
        alert("Background process error - " + JSON.stringify(err) + err.toString());
      }

      this.assetsService = this.injector.get(AssetsService);
      this.facilityService = this.injector.get(FacilityService);
      this.syncService = this.injector.get(SyncService);
      this.assetsManageService = this.injector.get(AssetManageService);
      this.registerService = this.injector.get(RegisterService);

      this.handleMessages();

      this.geolocation.getCurrentPosition({
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0
      }).catch((err) => {
        err.UserMessage = "No Location permissions";
        this.errorHandlerService.logError(err);
      });

      if (window.parent && window.location != window.parent.location) {
        WebAppShowComponent.IsWebAppIFrame = true;
        this.menuVisible = false;
      }

      let user = await this.userService.getLoggedInUser();
      if (!WebAppShowComponent.IsWebAppIFrame) {
        if (user && user.Token && user.Token.length > 0) {
          this.menuVisible = true;
          if (user.Config.MobileLandPage == "warranty") {
            this.router.navigate(["warranty/list"], { replaceUrl: true });
          } else {
            this.router.navigate(["mywork/list"], { replaceUrl: true });
          }
          //  else {
          //   this.router.navigate(["facilities"], { replaceUrl: true });
          // }
        } else {
          this.menuVisible = false;
          this.router.navigate(["login"], { replaceUrl: true });
        }
      }

      this.pubSub.$pub(EventConstants.SYNC_UPDATE);

      this.loginSubscription = this.pubSub.$sub(EventConstants.LOG_IN, async () => {
        await this.menuController.close();
        this.menuVisible = true;
      });
      this.logoutSubscription = this.pubSub.$sub(EventConstants.LOG_OUT, async () => {
        await this.menuController.close();
        this.menuVisible = false;
      });

      this.router.events.subscribe(e => {
        if (e instanceof NavigationStart) {
          this.menuController.close();
        }
      });

      this.splashScreen.hide();

      if (!WebAppShowComponent.IsWebAppIFrame) {
        this.syncInterval = window.setInterval(() => {
          this.syncService.sync();
        }, 15 * 1000);
      }

      this.loadConfig();
      //alert("Initialization done");
    } catch (err) {
      alert("Initialization error " + JSON.stringify(err));
    } finally {
      this.initialized = true;
    }
  }

  ngOnDestroy() {
    BackgroundFetch.stop();
    window.clearInterval(this.syncInterval);
    this.loginSubscription.unsubscribe();
    this.logoutSubscription.unsubscribe();
  }

  async loadConfig() {
    try {
      this.registerService.reloadUserConfig();
    } catch (err) { }
  }

  private async hasJobs(): Promise<boolean> {
    return (await this.repositoryService.getFacilityChecklistRepository().find({
      where: {
        JobData: Not(IsNull())
      }
    })).length > 0;
  }

  private async configureBackgroundProcess() {
    let onEvent = async (taskId) => {
      console.log('[BackgroundFetch] event received: ', taskId);
      try {
        await this.syncService.sync();
      } finally {
        // Required: Signal completion of your task to native code
        // If you fail to do this, the OS can terminate your app
        // or assign battery-blame for consuming too much background-time
        BackgroundFetch.finish(taskId);
      }
    };

    // Timeout callback is executed when your Task has exceeded its allowed running-time.
    // You must stop what you're doing immediately BackgroundFetch.finish(taskId)
    let onTimeout = async (taskId) => {
      console.log('[BackgroundFetch] TIMEOUT: ', taskId);
      BackgroundFetch.finish(taskId);
    };

    let status = await BackgroundFetch.configure({ minimumFetchInterval: 15, stopOnTerminate: true }, onEvent, onTimeout);
    BackgroundFetch.start();
    setInterval(() => {
      try {
        this.errorHandlerService.sendLogsBackground();
      } catch { }
    }, 10 * 60 * 1000);
  }

  handleMessages() {
    if (this.browserHelperService.isNativeApp()) {
      this.handleMessagesPrivate();
    } else {
      //window.onload = () => {
      this.handleMessagesPrivate();
      //}
    }
  }

  handleMessagesPrivate() {
    window.addEventListener("message", async (evt: any) => {
      if (evt.data) {
        var message = evt.data.message;
        switch (message) {
          case "web-app-calendar-create-job": {
            await this.environmentService.setEnvironment(evt.data.environment);
            var jobDate = evt.data.jobDate;
            var queueItemId = evt.data.queueItemId || 0;
            await this.userService.setLoggedInUser(evt.data.userData);
            if (queueItemId > 0) {
              await this.router.navigate([`/mywork/createcalendarjob/${jobDate}/${queueItemId || 0}/version/${new Date().getTime()}`], { replaceUrl: true });
            } else {
              await this.router.navigate([`/mywork/calendarnew/${jobDate}/version/${new Date().getTime()}`], { replaceUrl: true });
            }
            break;
          }
          case "web-app-edit-notes": {
            await this.environmentService.setEnvironment(evt.data.environment);
            var noteId = evt.data.noteId;
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.router.navigate([`/mywork/updatecalendarnote/${noteId}/version/${new Date().getTime()}`], { replaceUrl: true });
            break;
          }
          case "web-app-create-job": {
            await this.environmentService.setEnvironment(evt.data.environment);
            await this.router.navigate([`/mywork/createjob/version/${new Date().getTime()}`], { replaceUrl: true });
            break;
          }
          case "web-app-go-to-warehouse-build": {
            await this.environmentService.setEnvironment(evt.data.environment);
            let buildId = evt.data.buildId;
            await this.router.navigate([`/mywork/updatewarehousebuild/${buildId}/version/${new Date().getTime()}`], { replaceUrl: true });
            break;
          }
          case "web-app-create-warehouse-build": {
            await this.environmentService.setEnvironment(evt.data.environment);
            await this.router.navigate([`/mywork/createwarehousebuild/version/${new Date().getTime()}`], { replaceUrl: true });
            break;
          }
          case "web-app-new-time-tracking": {
            try {
              await this.modalController.dismiss();
            } catch { }
            try {
              await this.popoverController.dismiss();
            } catch { }
            try {
              await this.alertController.dismiss();
            } catch { }
            try {
              await this.pickerController.dismiss();
            } catch { }
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            const checklistId = evt.data.checklistId || 'null';
            await this.router.navigate([`timetracking/timeoff/add/${checklistId}/version/${new Date().getTime()}`], { replaceUrl: true });
            WebAppShowComponent.IFrameBaseUrl = location.href;
            WebAppShowComponent.BaseUrl = location.href;
            break;
          }
          case "web-app-edit-time-tracking": {
            try {
              await this.modalController.dismiss();
            } catch { }
            try {
              await this.popoverController.dismiss();
            } catch { }
            try {
              await this.alertController.dismiss();
            } catch { }
            try {
              await this.pickerController.dismiss();
            } catch { }
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            const timeTrackingId = evt.data.timeTrackingId;
            await this.router.navigate([`timetracking/timeoff/edit/${timeTrackingId}/version/${new Date().getTime()}`], { replaceUrl: true });
            WebAppShowComponent.IFrameBaseUrl = location.href;
            WebAppShowComponent.BaseUrl = location.href;
            break;
          }
          case "web-app-go-to-asset": {
            try {
              await this.modalController.dismiss();
            } catch { }
            try {
              await this.popoverController.dismiss();
            } catch { }
            try {
              await this.alertController.dismiss();
            } catch { }
            try {
              await this.pickerController.dismiss();
            } catch { }
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            let siteKey = evt.data.siteKey;
            let element = document.getElementsByTagName("assets")[0] as any;
            if (element) {
              element.style.visibility = "hidden";
            }
            let jobId = evt.data.jobId;
            await this.loaderHelperService.on();
            await this.repositoryService.cleanDb();
            if (jobId) {
              await this.facilityService.refreshJob(siteKey, jobId);
            } else {
              await this.facilityService.reloadMyFacilitiesAndAssets([siteKey]);
            }
            this.loaderHelperService.off();
            if (await this.modalController.getTop()) {
              await this.modalController.dismiss();
            }
            let preview = evt.data.assetsPreview == true;
            let url = `/facilities/${siteKey}/assets/${preview}`;
            if (jobId) {
              url = `/facilities/${siteKey}/assets/fromJob/${jobId}/${preview}`;
            }
            if (evt.data.openChecklist) {
              url = `/checklist/${jobId}/false/open`;
            } else if (evt.data.highlightAssetId) {
              let facility = await this.assetsService.getFacilityWithAssets(siteKey);
              let asset = await this.assetsService.getAssetFromFacility(facility, evt.data.highlightAssetId);
              url = `/facilities/${siteKey}/assets/${asset.ParentId}/highlight/${asset.Id}/${preview}`;
            } else if (evt.data.highlightBatteryKey && evt.data.batteryAssetId) {
              url = `/facilities/${siteKey}/assets/${evt.data.batteryAssetId}/batteries/highlight/${evt.data.highlightBatteryKey}/${preview}`;
            } else if (evt.data.assetSelection) {
              url = `/facilities/${siteKey}/asset-scheduling/${evt.data.assetIdsCommaSeparated}/${evt.data.index}`;
            } else if (evt.data.goToBatteryTests) {
              url = `/facilities/${siteKey}/batterytests/${evt.data.jobId}/${evt.data.assetId}`;
            }
            if (evt.data.clearQuote) {
              await this.assetQuoteService.removeFacilityQuote(siteKey);
            }
            if (this.router.url === url) {
              //this.pubSub.$pub(EventConstants.REFRESH_ASSETS_WEB);
              await this.router.navigate([url + `/version/${new Date().getTime()}`], { replaceUrl: true });
            } else {
              await this.router.navigate([url], { replaceUrl: true });
            }
            if (evt.data.quotePreview) {
              await this.router.navigate([`/facilities/${siteKey}/quotes/preview/true`]);
            }
            WebAppShowComponent.IFrameBaseUrl = location.href;
            WebAppShowComponent.BaseUrl = location.href;
            if (element) {
              element.style.visibility = "visible";
            }
            break;
          }
          case "web-app-go-to-time-off": {
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            window["timeOffForUser"] = evt.data.timeOffForUser;
            this.router.navigate([`/timetracking/timeoff/version/${new Date().getTime()}`]);
            break;
          }
          case "web-app-go-to-manage-time-off": {
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            window["timeOffForUser"] = evt.data.timeOffForUser;
            if(evt.data.date){
              await this.router.navigate([`/mywork/createcalendartimeoff/${evt.data.date}/version/${new Date().getTime()}`], { replaceUrl: true });
            } else {
              await this.router.navigate([`/mywork/createcalendartimeoff/manage/version/${new Date().getTime()}`], { replaceUrl: true });
            }
            break;
          }
          case "web-app-quote-review": {
            this.pubSub.$pub(EventConstants.QUOTE_REVIEW_NAVIGATE_WEB_START);
            try {
              await this.modalController.dismiss();
            } catch { }
            try {
              await this.popoverController.dismiss();
            } catch { }
            try {
              await this.alertController.dismiss();
            } catch { }
            try {
              await this.pickerController.dismiss();
            } catch { }
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            let quoteKey = evt.data.quoteKey;
            let siteKey = evt.data.siteKey;
            await this.loaderHelperService.on();
            await this.repositoryService.cleanDb();
            await this.facilityService.reloadMyFacilitiesAndAssets([siteKey]);
            this.loaderHelperService.off();
            if (await this.modalController.getTop()) {
              await this.modalController.dismiss();
            }
            var url = location.href;
            const newUrl = `/facilities/${siteKey}/editquote/${quoteKey}/true/false`;
            if (location.href.indexOf(newUrl) >= 0) {
              this.pubSub.$pub(EventConstants.QUOTE_REVIEW_NAVIGATE_WEB, {
                quoteKey,
                siteKey
              });
            } else {
              this.router.navigate([newUrl], { replaceUrl: true });
            }
            WebAppShowComponent.IFrameBaseUrl = location.href;
            WebAppShowComponent.BaseUrl = location.href;
            break;
          }
          case "mobile-app-increase-the-image-count-from-web": {
            if (evt.data.siteDetailKey && evt.data.siteAssetKey) {
              if (this.assetsManageService.facility.Id == evt.data.siteKey) {
                this.assetsManageService.facility;
                let battery = await this.assetsService.getBattery(evt.data.siteDetailKey, evt.data.siteAssetKey, this.assetsManageService.facility);
                if (battery) {
                  battery.ImageCount = (battery.ImageCount || 0) + (evt.data.increment || 0);
                  await this.assetsService.updateFacilityNoSyncNeeded(this.assetsManageService.facility);
                }
              }
            } else if (evt.data.siteAssetKey) {
              this.pubSub.$pub(EventConstants.IMAGE_UPLOADED_WEB, evt.data);
              if (this.assetsManageService.facility.Id == evt.data.siteKey) {
                let asset = this.assetsService.getAssetFromFacility(this.assetsManageService.facility, evt.data.siteAssetKey)
                let answerConfigId = evt.data.siteAssetItemId;
                if (asset) {
                  if (answerConfigId) {
                    for (let answer of asset.Answers) {
                      if (answer.SiteAssetDataItemConfigurationId == answerConfigId) {
                        answer.ImageCount = (answer.ImageCount || 0) + (evt.data.increment || 0);
                      }
                    }
                  } else {
                    asset.ImageCount = (asset.ImageCount || 0) + (evt.data.increment || 0);
                  }
                  await this.assetsService.updateFacilityNoSyncNeeded(this.assetsManageService.facility);
                }
              }
            }
            break;
          }
          case 'refresh-image-gallery': {
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            this.pubSub.$pub(EventConstants.REFRESH_GALLERY);
            break;
          }
          case 'web-app-refresh-image-annotation-count': {
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            this.pubSub.$pub(EventConstants.REFRESH_CHECKLIST_QUESTIONS_IMAGE_ANNOTATIONS);
            break;
          }
          case 'web-app-refresh-project-type': {
            this.pubSub.$pub(EventConstants.TEMPLATE_ADDED);
          }
          case 'web-app-duplicate-jobs': {
            await this.userService.setLoggedInUser(evt.data.userData);
            await this.environmentService.setEnvironment(evt.data.environment);
            var checklistId = evt.data.checklistId;
            await this.router.navigate(['/emptyPage'], { replaceUrl: true });
            await this.router.navigate([`/mywork/duplicatejob/${checklistId}/version/${new Date().getTime()}`], { replaceUrl: true });
          }
        }
      }
    }, false);
  }
}
