import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../my-work.service";
import { JobDataModel } from "src/app/checklist/checklist.models";
import { IonInfiniteScroll, ModalController, ToastController } from "@ionic/angular";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { InfiniteScrollModel } from "src/app/shared/models/infinite-scroll-model";
import { WorkItemSiteDetailsComponent } from "../work-item-site-details/work-item-site-details.component";

@Component({
  selector: "my-work-list",
  templateUrl: "my-work-list.component.html"
})
export class MyWorkListComponent implements OnInit {
  @Input()
  workList: JobDataModel[] = [];
  loading: boolean = false;
  @Output()
  workListUpdated: EventEmitter<JobDataModel[]> = new EventEmitter<JobDataModel[]>();
  isVendor: boolean;

  scrollModel: InfiniteScrollModel<JobDataModel> = new InfiniteScrollModel<JobDataModel>();
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

  constructor(
    private myWorkService: MyWorkService,
    private toastController: ToastController,
    private router: Router,
    private userService: UserService,
    private modalController: ModalController
  ) { }

  async ngOnInit() {
    this.isVendor = await this.userService.isVendor();

    this.scrollModel.init(this.workList.filter(x => !x.Hidden), this.infiniteScroll);
    this.loading = false;
    setTimeout(() => {
      this.scrollModel.initInfiniteScrollElement(this.infiniteScroll);
    }, 1000);
  }

  setData(data: JobDataModel[]) {
    this.scrollModel.init(data, this.infiniteScroll);
  }

  // async doRefresh(event: any) {
  //   let dataLoaded = await this.loadData();
  //   event.target.complete();
  //   if (!dataLoaded) {
  //     const toast = await this.toastController.create({
  //       message: `Can't refresh. Please upload all your data first.`,
  //       position: "bottom",
  //       duration: 5000
  //     });
  //     await toast.present();
  //   }
  // }

  async goToWorkItem(item: JobDataModel) {
    if (item.Full) {
      this.router.navigate([`/mywork/work-details/${item.ChecklistId}`]);
    } else {
      const toast = await this.toastController.create({
        message: `Job data is still loading...`,
        position: "bottom",
        color: "danger",
        duration: 5000
      });
      toast.present();
      // const popover = await this.modalController.create({
      //   component: WorkItemSiteDetailsComponent,
      //   componentProps: {
      //     jobModel: item,
      //     facility: null,
      //     stillLoading: true;
      //     fromMap: true,
      //     preview: item.CheckedIn != true
      //   }
      // });
      //return await popover.present();
    }
  }

  isFirstOfThisDate(item: JobDataModel) {
    const filteredList = this.workList.filter(x => x.ScheduledWorkDateText == item.ScheduledWorkDateText && !x.Hidden);
    return filteredList[0] == item;
  }

  private async loadData(): Promise<boolean> {
    let dataLoaded: boolean = await this.myWorkService.loadJobs();
    this.workList = await this.myWorkService.getJobs();
    if (this.workListUpdated) {
      this.workListUpdated.next(this.workList);
    }
    return dataLoaded;
  }
}