import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "work-data-corrections-needed",
  templateUrl: "work-data-corrections-needed.component.html"
})
export class WorkDataCorrectionsNeededComponent {
  @Input()
  checklistId: number;
  @Input()
  preview: boolean

  constructor(
    private router: Router,
    private popoverController: PopoverController
  ) { }

  async reviewCorrections() {
    await this.popoverController.dismiss();
    this.router.navigate([`/checklist/${this.checklistId}/${this.preview}/openFirstSection`]);
  }
}