import { Injectable } from "@angular/core";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import * as _ from "lodash";
import { ApiService } from "../shared/services/api/api.service";
import { SyncQueueService } from "../sync/sync-queue.service";
import { ChecklistStatus } from "../data/entities/ChecklistStatus";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import { JobCheckInOut } from "../data/entities/JobCheckInOut";

@Injectable()
export class MyWorkSyncService {
  constructor(
    public errorHandlerService: ErrorHandlerService,
    private apiService: ApiService,
    private syncQueueService: SyncQueueService,
    private pubSub: PubSubService
  ) {
  }

  async sync() {
    await this.syncCheckInChanges();
    await this.syncStatusChanges();
    await this.syncCheckOutChanges();
  }

  private async syncStatusChanges() {
    try {
      let items = await this.syncQueueService.getItemsToSync("checklistStatus");

      try {
        for (let item of items) {
          let checklistStatus: ChecklistStatus = JSON.parse(item.Data);
          const syncResult = await this.apiService.post("checklist/changestatus", checklistStatus);
          if (syncResult) {
            console.log("Sync status done" + items);
            await this.syncQueueService.removeSynchronizedItem(item);
            this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
          }
        }
      }
      catch (err) {
        this.errorHandlerService.logError(err);
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      return false;
    }
    return true;
  }

  private async syncCheckInChanges() {
    try {
      let items = await this.syncQueueService.getItemsToSync("jobCheckIn");
      try {
        for (let item of items) {
          let status: JobCheckInOut = JSON.parse(item.Data);
          const syncResult = await this.apiService.post("checklist/checkin", status);
          if (syncResult) {
            console.log("Sync checkin done" + items);
            await this.syncQueueService.removeSynchronizedItem(item);
            this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
          }
        }
      }
      catch (err) {
        this.errorHandlerService.logError(err);
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      return false;
    }
    return true;
  }

  private async syncCheckOutChanges() {
    try {
      let items = await this.syncQueueService.getItemsToSync("jobCheckOut");
      try {
        for (let item of items) {
          let status: JobCheckInOut = JSON.parse(item.Data);
          const syncResult = await this.apiService.post("checklist/checkout", status);
          if (syncResult) {
            console.log("Sync checkout done" + items);
            await this.syncQueueService.removeSynchronizedItem(item);
            this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
          }
        }
      }
      catch (err) {
        this.errorHandlerService.logError(err);
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      return false;
    }
    return true;
  }

}
