<ng-container *ngIf="options.length <= switchLimit">
  <ng-container *ngFor="let i of options">
    <ion-button size="default" [color]="model == i.Value ? 'primary' : 'light'" [disabled]="preview" (click)="selectItem(i)">
      {{i.Text}}
      <ion-icon name="checkmark-circle-outline" *ngIf="model == i.Value" [style.color]="i.Color"></ion-icon>
    </ion-button>
  </ng-container>
</ng-container>
<ion-select tabindex="1" #selectList1 [(ngModel)]="model" okText="Okay" cancelText="Dismiss"
  [placeholder]="preview ? '' : 'Select one'" (ionChange)="onAnswerChange()" class="w-100 ion-padding-bottom"
  [disabled]="preview" *ngIf="options.length > switchLimit">
  <ion-select-option [value]="null">
    Select one
  </ion-select-option>
  <ion-select-option [value]="i.Value" *ngFor="let i of options">{{ i.Text }}
  </ion-select-option>
</ion-select>