import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { IonInfiniteScroll, IonSearchbar, ModalController } from "@ionic/angular";
import { FacilityService } from "src/app/assets/facility.service";
import { FacilitySimpleSearchModel, LookupListItem } from "src/app/assets/assets.models";
import { NewJobLocationModel } from "../../my-work.models";
import { MyWorkService } from "../../my-work.service";

@Component({
  selector: "select-location-dialog",
  templateUrl: "select-location-dialog.component.html"
})
export class SelectLocationDialogComponent implements OnInit {
  @Input()
  customerId: number;
  @Input()
  model: string;
  @Input()
  locationInfo: NewJobLocationModel
  @Output() locationInfoChange: EventEmitter<NewJobLocationModel> = new EventEmitter<NewJobLocationModel>();
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  locations: FacilitySimpleSearchModel[] = [];
  loading: boolean = false;
  query: string;
  createLocation: boolean;
  locationExistsMessage: string;
  @ViewChild(IonSearchbar)
  searchbarInput: IonSearchbar;

  pageSize: number = 25;
  pageNumber: number = 1;

  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

  constructor(
    private modalController: ModalController,
    private facilityService: FacilityService,
    private myWorkService: MyWorkService
  ) { }

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    this.createLocation = this.customerId <= 0;
    this.search();
    // if ((this.model || "").length > 0) {
    //   this.query = this.model;

    // }
    if (!this.createLocation) {
      setTimeout(async () => {
        (await this.searchbarInput.getInputElement()).focus();
      }, 100);
    }

    // try {
    //   await this.modalController.dismiss();
    //   await this.modalController.dismiss();
    // } catch { }
    this.loading = false;
  }

  async loadMore(event: any) {
    this.pageNumber++;
    const moreData = await this.facilityService.searchFacilitiesByCustomer(this.query, this.customerId, this.pageSize, this.pageNumber);
    this.infiniteScroll.complete();
    if (moreData.Items.length < this.pageSize) {
      this.infiniteScroll.disabled = true;
    }
    this.locations = this.locations.concat(moreData.Items);
  }

  async search() {
    this.locations = [];
    this.pageNumber = 1;
    this.loading = true;
    try {
      let response = await this.facilityService.searchFacilitiesByCustomer(this.query, this.customerId, this.pageSize, this.pageNumber);
      this.locations = response.Items;
    } catch (err) {
      this.locations = [];
    } finally {
      this.loading = false;
    }
    //todo: search based on customerid and query
  }

  selectItem(item: FacilitySimpleSearchModel) {
    if (this.model !== item.Name) {
      this.model = item.Name;
      this.modelChange.emit(this.model);
    }
    this.locationInfo.SiteKey = item.SiteKey;
    this.modalController.dismiss();
  }

  async addLocation() {
    this.loading = true;
    try {
      this.locationExistsMessage = null;
      let response = await this.facilityService.searchFacilitiesByCustomer(this.query, this.customerId, 1, 25);
      const name = (this.model || "").trim();
      if (response.Items.some(x => x.Name == name)) {
        if (confirm("There is already a location with this name. Do you want to use existing location?")) {
          this.modelChange.emit(this.model);
          this.modalController.dismiss();
        }
        return;
      }
    } catch (err) {
      this.locations = [];
    } finally {
      this.loading = false;
    }
    this.modelChange.emit(this.model);
    this.locationInfoChange.emit(this.locationInfo);
    this.modalController.dismiss();
  }

  switchToAdd() {
    this.createLocation = true;
    this.model = this.query;
  }

  switchToSearch() {
    this.createLocation = false;
    this.query = this.model;
    if ((this.query || "").length > 0) {
      this.search();
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}