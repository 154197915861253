<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            {{createLocation ? 'Add Location' : 'Find Location'}}
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ng-container *ngIf="!createLocation">
        <ion-searchbar show-cancel-button="never" mode="ios" placeholder="Start typing to find location"
            [(ngModel)]="query" #searchbarInput (ngModelChange)="search()" debounce="500">
        </ion-searchbar>
        <ion-list>
            <ion-item *ngFor="let item of locations" (click)="selectItem(item)" button>
                <ion-label>
                    <div>
                        <b>{{item.Name}}</b>
                    </div>
                    <div>
                        <ion-note>
                            {{item.Description}}
                        </ion-note>
                    </div>
                    <div>
                        <ion-note>
                            {{item.Address}}
                        </ion-note>
                    </div>
                </ion-label>
                <ion-icon *ngIf="item.Name == model" name="checkmark"></ion-icon>
            </ion-item>
            <ion-item *ngIf="loading">
                <ion-label>
                    Loading
                    <ion-spinner></ion-spinner>
                </ion-label>
            </ion-item>
            <ion-item *ngIf="(query || '').length > 0 && !loading" (click)="switchToAdd()" button>
                Can't find a location. Click here to add one.
            </ion-item>
        </ion-list>
        <ion-infinite-scroll threshold="100px" (ionInfinite)="loadMore($event)">
            <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
            </ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ng-container>
    <ng-container *ngIf="createLocation">
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusLocation()">
                Location
            </ion-note>
            <ion-input type="text" [(ngModel)]="model" #locationInput id="locationName">
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationAddressDescriptionInput.focus()">
                Address Description
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.Description" #locationAddressDescriptionInput>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationAddressLine1Input.focus()">
                Address Line 1
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.Line1" #locationAddressLine1Input>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationAddressLine2Input.focus()">
                Address Line 2
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.Line2" #locationAddressLine2Input>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationCityInput.focus()">
                City
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.City" #locationCityInput>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationStateInput.focus()">
                State
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.State" #locationStateInput>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationZipInput.focus()">
                Zip
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.Zip" #locationZipInput>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationLatitudeInput.focus()">
                Latitude
            </ion-note>
            <ion-input decimal-input="6" allow-negative="true" inputmode="decimal" custom-max-length="10" [(ngModel)]="locationInfo.Latitude"
                #locationLatitudeInput>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationLongitudeInput.focus()">
                Longitude
            </ion-note>
            <ion-input decimal-input="6" allow-negative="true" inputmode="decimal" custom-max-length="10" [(ngModel)]="locationInfo.Longitude"
                #locationLongitudeInput>
            </ion-input>
        </ion-item>

        <ion-item class="custom-input-ion-item" *ngIf="managers && managers.length > 0">
            <ion-note position="fixed" slot="start" (click)="managerSelect.open()">
                Manager
            </ion-note>
            <ion-select [(ngModel)]="locationInfo.ManagerId" okText="Okay" cancelText="Dismiss" #managerSelect
                placeholder="Please Choose">
                <ion-select-option [value]="item.Value" *ngFor="let item of managers">
                    {{ item.Text }}
                </ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationManagerFirstName.focus()">
                Customer Operations Manager First Name
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.ManagerFirstName" #locationManagerFirstName>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationManagerLastName.focus()">
                Customer Operations Manager Last Name
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.ManagerLastName" #locationManagerLastName>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationManagerPhone.focus()">
                Customer Operations Manager Phone
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.ManagerPhone" #locationManagerPhone>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationManagerEmail.focus()">
                Customer Operations Manager Email
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.ManagerEmail" #locationManagerEmail>
            </ion-input>
        </ion-item>

        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationTechFirstName.focus()">
                Customer Technician First Name
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.TechFirstName" #locationTechFirstName>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationTechLastName.focus()">
                Customer Technician Last Name
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.TechLastName" #locationTechLastName>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationTechPhone.focus()">
                Customer Technician Phone
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.TechPhone" #locationTechPhone>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationTechEmail.focus()">
                Customer Technician Email
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.TechEmail" #locationTechEmail>
            </ion-input>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationDirections.focus()">
                Directions
            </ion-note>
            <ion-textarea type="text" [(ngModel)]="locationInfo.Directions" #locationDirections>
            </ion-textarea>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationAccess.focus()">
                Access
            </ion-note>
            <ion-textarea type="text" [(ngModel)]="locationInfo.Access" #locationAccess>
            </ion-textarea>
        </ion-item>


        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="locationPhone.focus()">
                Location Phone
            </ion-note>
            <ion-input type="text" [(ngModel)]="locationInfo.LocationPhone" #locationPhone>
            </ion-input>
        </ion-item>

        <ion-item *ngIf="(locationExistsMessage || '').length > 0">
            <ion-button size="small" (click)="switchToSearch">Click here to switch back to search</ion-button>
        </ion-item>
    </ng-container>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button *ngIf="createLocation" (click)="addLocation()" color="light">
                <ion-label>Add</ion-label>
            </ion-segment-button>
            <ion-segment-button (click)="dismissModal()" color="light">
                <ion-label>Cancel</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>