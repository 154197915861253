<oms-header [title]="'Time Off'" *ngIf="!isWeb">
    <ion-back-button defaultHref="/facilities"></ion-back-button>
</oms-header>
<ion-header *ngIf="isWeb">
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Time Off {{forUser}}
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-segment (ionChange)="segmentChanged($event)" mode="ios" value="upcomoing" [(ngModel)]="filter">
        <ion-segment-button value="upcoming">
            <ion-label>Upcoming<br/>({{timeOff.Days.length}})</ion-label>
        </ion-segment-button>
        <ion-segment-button value="ytd">
            <ion-label>Year to date<br/>({{ytdDays.length}})</ion-label>
        </ion-segment-button>
        <ion-segment-button value="lastyear">
            <ion-label>Last Year<br/>({{lastYearDays.length}})</ion-label>
        </ion-segment-button>
    </ion-segment>
    <ng-container *ngIf="!loading">
        <ng-container *ngIf="filter == 'upcoming'">
        <ion-list *ngIf="timeOff.Days.length > 0 && filter == 'upcoming'">
            <ion-item *ngFor="let item of timeOff.Days">
                <ion-label>
                    <div>{{formatDate(item.Day)}}</div>
                    <div *ngIf="item.Description" class="job-status-notes">
                        <i class="fal fa-comment-lines"></i>
                        {{item.Description}}
                    </div>
                </ion-label>
            </ion-item>
        </ion-list>
        <ion-card *ngIf="timeOff.Days.length === 0" class="ion-text-center">
            <ion-card-header>
                <ion-card-title>No upcoming time off entries</ion-card-title>
            </ion-card-header>
        </ion-card>
    </ng-container>
        <ng-container *ngIf="filter == 'ytd'">
            <ion-list *ngIf="ytdDays.length > 0">
                <ion-item *ngFor="let item of ytdDays">
                    <ion-label>
                        <div>{{formatDate(item.Day)}}</div>
                        <div *ngIf="item.Description" class="job-status-notes">
                            <i class="fal fa-comment-lines"></i>
                            {{item.Description}}
                        </div>
                    </ion-label>
                </ion-item>
            </ion-list>
            <ion-card *ngIf="ytdDays.length === 0" class="ion-text-center">
                <ion-card-header>
                    <ion-card-title>No year to date time off entries</ion-card-title>
                </ion-card-header>
            </ion-card>
        </ng-container>
        <ng-container *ngIf="filter == 'lastyear'">
            <ion-list *ngIf="lastYearDays.length > 0">
                <ion-item *ngFor="let item of lastYearDays">
                    <ion-label>
                        <div>{{formatDate(item.Day)}}</div>
                        <div *ngIf="item.Description" class="job-status-notes">
                            <i class="fal fa-comment-lines"></i>
                            {{item.Description}}
                        </div>
                    </ion-label>
                </ion-item>
            </ion-list>
            <ion-card *ngIf="lastYearDays.length === 0" class="ion-text-center">
                <ion-card-header>
                    <ion-card-title>No time off entries from last year</ion-card-title>
                </ion-card-header>
            </ion-card>
        </ng-container>
        <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="!preview && filter == 'upcoming'">
            <ion-fab-button (click)="edit()">
                <ion-icon name="pencil"></ion-icon>
            </ion-fab-button>
        </ion-fab>
    </ng-container>
    <ng-container *ngIf="loading">
        <ion-item *ngFor="let i of [1,2,3,4,5]">
            <ion-label>
                <h3>
                    <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                </h3>
                <p>
                    <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </p>
                <p>
                    <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
                </p>
            </ion-label>
        </ion-item>
    </ng-container>
</ion-content>
<ion-footer *ngIf="isWeb">
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="dismissModal()" color="light" class="color-white">
                Close
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>