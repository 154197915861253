import { Injectable } from "@angular/core";
import {
  RegisterModel,
  RegisterModelResponse,
  ConfirmRegistrationModel,
  ConfirmRegistrationResponseModel,
  LoginModel,
  LoginModelResponse
} from "./register.models";
import { Router } from "@angular/router";
import { ApiService } from "../shared/services/api/api.service";
import { RepositoryService } from "../data/db/repository.service";
import { LoaderHelperService } from "../shared/services/browser/loader-helper.service";
import { ToastController } from "@ionic/angular";
import * as EventConstants from "../shared/constants/events-constants"
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import { UserService } from "../shared/services/user-service/user-service.service";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import { IsNull, Not } from "typeorm";
import { BaseDataResponse } from "../shared/models/base-response-model";
import { UserConfigModel } from "../shared/models/user-config-model";

@Injectable()
export class RegisterService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private repositoryService: RepositoryService,
    private loader: LoaderHelperService,
    public toastController: ToastController,
    public errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private pubSub: PubSubService
  ) { }

  async loginWithoutPassword(model: LoginModel) {
    try {
      let data = await this.apiService.postAnonimous<LoginModelResponse>("login/loginWithEmail", model);
      await this.loader.off();
      if (data.Success) {
        await this.setLoggedInUser(data, null);
        this.router.navigate(["token-confirmation"], { replaceUrl: true });
      } else {
        alert(data.Message);
      }
    } catch (err) {
      await this.errorHandlerService.showErrorMessage(err);
    }
  }

  async loginWithPassword(model: LoginModel) {
    try {
      try {
        await this.apiService.ping();
      } catch (err) {
        this.errorHandlerService.logErrorAndShowGenericToast(err);
        return;
      }
      let data = await this.apiService.postAnonimous<LoginModelResponse>("login/loginWithPassword", model);
      await this.loader.off();
      if (data.Success) {
        await this.setLoggedInUser(data, data.Token);
        await this.navigateToHomePage(data.HasJobs);
      } else {
        alert(data.Message);
      }
    } catch (err) {
      await this.errorHandlerService.showErrorMessage(err);
    }
  }

  private async setLoggedInUser(data: LoginModelResponse, token: string = null) {
    await this.userService.setLoggedInUser({
      Email: data.Email,
      Username: data.Username,
      FullName: data.FullName,
      Company: data.Company,
      Phone: data.Phone,
      UserId: data.UserId,
      Token: token,
      RoleId: data.RoleId,
      MemberId: data.MemberId,
      CustomerType: data.CustomerType,
      IntCodeId: data.IntCodeId,
      Config: data.Config
    });
  }
  async resendCode(model: LoginModel) {
    await this.loader.on();
    try {
      let data = await this.apiService.postAnonimous<LoginModelResponse>("login/loginWithEmail", model);
      await this.loader.off();
      if (data.Success) {
        const toast = await this.toastController.create({
          message: `New 4-digit code is on it's way.`,
          position: "bottom",
          duration: 5000
        });
        await toast.present();
      } else {
        alert(data.Message);
      }
    } catch (err) {
      await this.loader.off();
      await this.errorHandlerService.showErrorMessage(err);
    }
  }

  async register(model: RegisterModel) {
    await this.loader.on();
    try {
      let data = await this.apiService.postAnonimous<RegisterModelResponse>("login/requestAccount", model);
      await this.loader.off();
      if (data.Success) {
        this.router.navigate(["new-account-confirmation"], { replaceUrl: true });
      } else {
        alert(data.Message);
      }
    } catch (err) {
      await this.loader.off();
      await this.errorHandlerService.showErrorMessage(err);
    }
  }

  async confirm(code: string, acceptTerms: boolean) {
    const user = await this.userService.getLoggedInUser();
    await this.loader.on();
    try {
      let data = await this.apiService
        .postAnonimous<ConfirmRegistrationResponseModel>("login/ConfirmWithCodeAssets", {
          Code: String(code),
          Email: user.Email,
          AcceptTerms: acceptTerms
        });
      await this.loader.off();
      if (data.Success) {
        user.Token = data.Token;
        await this.userService.setLoggedInUser(user);
        await this.navigateToHomePage(data.HasJobs);
      } else {
        alert(data.Message);
      }
    } catch (err) {
      await this.loader.off();
      await this.errorHandlerService.showErrorMessage(err);
    }
  }

  async reloadUserConfig() {
    let user = await this.userService.getLoggedInUser();
    if(user) {
      var config = await this.apiService.get<BaseDataResponse<UserConfigModel>>("user/getUserConfig");
      if(config) {
        user.Config = config.Data;
        await this.userService.setLoggedInUser(user);
      }
    }

  }

  private async navigateToHomePage(hasJobs: boolean) {
    this.pubSub.$pub(EventConstants.LOG_IN);
    window["login"] = true;
    let user = await this.userService.getLoggedInUser();
    if(user?.Config?.MobileLandPage == "warranty"){
      this.router.navigate(["warranty/list"], { replaceUrl: true });
    } else {
      this.router.navigate(["mywork/list"], { replaceUrl: true });
    }
    setTimeout(() => {
      this.pubSub.$pub(EventConstants.LOG_IN);
    }, 3000);
  }
  private async hasJobs(): Promise<boolean> {
    return (await this.repositoryService.getFacilityChecklistRepository().find({
      where: {
        JobData: Not(IsNull())
      }
    })).length > 0;
  }
}
