import { Injectable } from "@angular/core";
import {
  AssetConfigurationResponse,
  AssetFacilitiesResponse,
  AssetFacilityResponse,
  FacilitySearchModel,
  FacilitySimpleSearchModel,
  SelectListItem
} from "./assets.models";
import { ApiService } from "../shared/services/api/api.service";
import { RepositoryService } from "../data/db/repository.service";
import { Facility } from "../data/entities/Facility";
import * as _ from "lodash";
import { FacilityAssets } from "../data/entities/FacilityAssets";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import * as EventConstants from "../shared/constants/events-constants";
import { FacilityChecklist } from "../data/entities/FacilityChecklist";
import { WebAppShowComponent } from "../webapp/web-app.component";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import { AssetQuoteService } from "./services/quotes/asset-quote.service";
import { SiteJobModel } from "../checklist/checklist.models";
import { BaseResponseModel } from "../shared/models/base-response-model";
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";

@Injectable()
export class FacilityService {
  constructor(
    private apiService: ApiService,
    private repositoryService: RepositoryService,
    private pubSubService: PubSubService,
    private errorHandlerService: ErrorHandlerService,
    private assetQuoteService: AssetQuoteService,
    private browserHelperService: BrowserHelperService
  ) { }

  async searchFacilities(query: string): Promise<BaseResponseModel<FacilitySearchModel>> {
    try {
      return await this.apiService.get<BaseResponseModel<FacilitySearchModel>>("site/search", {
        query: query,
        v: new Date().getTime()
      });
    } catch (err) {
      this.errorHandlerService.logError(err);
      return;
    }
  }

  async searchFacilitiesByCustomer(query: string, customerId: number, pageSize: number, pageNumber: number): Promise<BaseResponseModel<FacilitySimpleSearchModel>> {
    try {
      return await this.apiService.get<BaseResponseModel<FacilitySimpleSearchModel>>("site/searchByCustomer", {
        query: query || '',
        customerId: customerId,
        pageSize: pageSize,
        pageNumber: pageNumber,
        v: new Date().getTime()
      });
    } catch (err) {
      this.errorHandlerService.logError(err);
      return;
    }
  }

  async downloadFacility(id: string): Promise<boolean> {
    let response: AssetFacilitiesResponse;
    let downloadedSiteKeys = [];
    try {
      let downloaded = await this.repositoryService.getFacilityRepository().find({
        where: {
          Downloaded: true
        }
      });
      downloadedSiteKeys = downloaded.map(x => x.Id);
      downloadedSiteKeys.push(id);

      response = await this.apiService.post<AssetFacilitiesResponse>("assets/getuserfacilities", {
        downloadedSiteKeys: downloadedSiteKeys
      });
    } catch (err) {
      this.errorHandlerService.logError(err);
      return false;
    }

    await this.cleanup(response);
    await this.reloadFacilitiesAssetsAndJobs(response.Facilities, downloadedSiteKeys);
    await this.reloadConfiguration(response.Configuration);
    return true;
  }

  async refreshJob(facilityId: string, jobId: number) {
    //Optimize to only get this job
    let response = await this.apiService.post<AssetFacilitiesResponse>("assets/getuserfacilities", {
      loadOnlySiteKeys: [facilityId],
      loadOnlyJobIds: [jobId],
      downloadedSiteKeys: [facilityId]
    });
    for (let facility of response.Facilities) {
      await this.repositoryService.getFacilityRepository().save(facility, { transaction: false });
      await this.repositoryService.getFacilityAssetsRepository().save(this.mapFacilityAsset(facility), { transaction: false });
      for (let job of facility.Jobs) {
        await this.repositoryService.getFacilityChecklistRepository().save(this.mapFacilityChecklist(job), { transaction: false });
      }
    }
    await this.reloadConfiguration(response.Configuration);
  }

  async reloadMyFacilitiesAndAssets(siteKeys: string[] = null) {
    if (WebAppShowComponent.IsWebAppIFrame) {
      await this.repositoryService.cleanDb();
    }
    if ((await this.browserHelperService.isOfflineAndMessage())) {
      return;
    }
    let response: AssetFacilitiesResponse;
    let downloadedSiteKeys = [];
    try {
      let downloaded = await this.repositoryService.getFacilityRepository().find({
        where: {
          Downloaded: true
        }
      });
      downloadedSiteKeys = downloaded.map(x => x.Id);
      response = await this.apiService.post<AssetFacilitiesResponse>("assets/getuserfacilities", {
        downloadedSiteKeys: downloadedSiteKeys,
        loadOnlySiteKeys: siteKeys
      });
    } catch (err) {
      this.errorHandlerService.logError(err);
      return;
    }

    await this.cleanup(response);
    await this.reloadFacilitiesAssetsAndJobs(response.Facilities, downloadedSiteKeys);
    await this.reloadConfiguration(response.Configuration);
  }

  public async getDownloadedFacilities() {
    let facilityRepo = await this.repositoryService.getFacilityRepository();
    let facilities = _.orderBy(
      await facilityRepo.find({
        where: {
          Downloaded: true
        }
      }),
      (facility: Facility) => facility.Name.toLowerCase()
    );
    return facilities;
  }

  public async reloadFacility(facility: Facility) {
    let facilityChecklistsToSave: FacilityChecklist[] = [];
    this.setFacilitySiteWalk(facility, facilityChecklistsToSave);
    for (let job of facility.Jobs) {
      facilityChecklistsToSave.push(this.mapFacilityChecklist(job));
    }
    await this.repositoryService.getFacilityRepository().save(facility, { transaction: false });
    await this.repositoryService.getFacilityAssetsRepository().save(this.mapFacilityAsset(facility), { transaction: false });

    const chunks = _.chunk(facilityChecklistsToSave, 100);
    for (let facilityChecklistChunk of chunks) {
      await this.repositoryService.getFacilityChecklistRepository().save(facilityChecklistChunk, { transaction: false });
    }
  }

  private async reloadFacilitiesAssetsAndJobs(facilities: Facility[], downloadedSiteKeys: string[]) {
    downloadedSiteKeys = downloadedSiteKeys || [];
    for (let facility of facilities) {
      if (_.some(downloadedSiteKeys, x => x == facility.Id)) {
        facility.Downloaded = true;
      }
      await this.reloadFacility(facility);

    }
    this.pubSubService.$pub(EventConstants.SYNC_UPDATE);
  }

  private async cleanup(response: AssetFacilitiesResponse) {
    var availableFacilityIds = response.Facilities.map(x => x.Id);
    var availableJobIds = [];
    for (let facility of response.Facilities) {
      availableJobIds = availableJobIds.concat(facility.Jobs.map(x => x.Model.ChecklistId));
    }

    let facilitiesFromDb = await this.repositoryService.getFacilityRepository().find();
    let facilityAssetsFromDb = await this.repositoryService.getFacilityAssetsRepository().find();
    let checklistsFromDb = await this.repositoryService.getFacilityChecklistRepository().find();
    for (let facilityFromDb of facilitiesFromDb) {
      if (availableFacilityIds.indexOf(facilityFromDb.Id) < 0) {
        this.repositoryService.getFacilityRepository().delete(facilityFromDb);
      }
    }
    for (let facilityAssetFromDb of facilityAssetsFromDb) {
      if (availableFacilityIds.indexOf(facilityAssetFromDb.Id) < 0) {
        this.repositoryService.getFacilityAssetsRepository().delete(facilityAssetFromDb);
      }
    }
    for (let checklistFromDb of checklistsFromDb) {
      if (availableJobIds.indexOf(checklistFromDb.Id) < 0) {
        this.repositoryService.getFacilityChecklistRepository().delete(checklistFromDb);
      }
    }
  }

  private setFacilitySiteWalk(facility: Facility, facilityChecklistsToSave: FacilityChecklist[]) {
    if (facility.SiteWalkChecklist && facility.SiteWalkChecklist.Id) {
      facility.SiteWalkChecklist.Sections = JSON.stringify(facility.SiteWalkChecklist.Sections);
      facilityChecklistsToSave.push(facility.SiteWalkChecklist);
      facility.SiteWalkChecklistId = facility.SiteWalkChecklist.Id;
    }
  }

  private mapFacilityChecklist(job: SiteJobModel): FacilityChecklist {
    return {
      Id: job.Model.ChecklistId,
      JobData: JSON.stringify(job.Model),
      SiteKey: job.Model.SiteKey,
      TimeStampUpdated: null,
      Sections: JSON.stringify(job.Sections),
      JobDataModel: null,
      SectionsModel: null,
      Hash: job.Hash
    };
  }

  private mapFacilityAsset(facility: Facility): FacilityAssets {
    return {
      Id: facility.Id,
      Assets: JSON.stringify(facility.Assets || [])
    };
  }

  public async reloadConfiguration(result: AssetConfigurationResponse): Promise<void> {
    const configRepo = this.repositoryService.getMemberConfigurationRepository();

    await this.assetQuoteService.setEmails(result.QuoteEmails || []);

    for (let group of result.ConfigurationGroups) {
      var existing = await configRepo.findOne({ where: { MemberId: group.MemberId } });
      var assetConfigData = group.AssetConfigurations && group.AssetConfigurations.length > 0 ? JSON.stringify(group.AssetConfigurations) : null;
      var lookupData = JSON.stringify(group.LookupData);
      if (existing) {
        existing.AsssetConfigurationData = assetConfigData;
        existing.LookupData = lookupData;
        await configRepo.save(existing, { transaction: false });
      } else {
        await configRepo.save({
          MemberId: group.MemberId,
          AsssetConfigurationData: assetConfigData,
          LookupData: lookupData
        }, { transaction: false });
      }
    }
  }

  private getJobIds(facilities: Facility[]): number[] {
    var jobIds = [];
    for (let facility of facilities) {
      jobIds = jobIds.concat(facility.Jobs.map(x => x.Model.ChecklistId));
    }
    return jobIds;
  }
}
