import { Component, OnInit } from "@angular/core";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { AppInfo } from "../../globals/AppInfo";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";

@Component({
  selector: "oms-app-info",
  templateUrl: "app-info.component.html"
})
export class AppInfoComponent implements OnInit {
  version: string;

  constructor(
    private appVersion: AppVersion,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  async ngOnInit() {
    try {
      this.version = await this.appVersion.getVersionNumber();
      if (AppInfo.AppSubVersion) {
        this.version = this.version + "-" + AppInfo.AppSubVersion;
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
    }
  }
}
