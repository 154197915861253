import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { ApiService } from "src/app/shared/services/api/api.service";
import { AvailableScheduleData, ScheduleModel } from "../../assets.models";
import { BaseDataResponse, BaseResponse } from "src/app/shared/models/base-response-model";

@Injectable()
export class AssetScheduleService {
  constructor(
    private apiService: ApiService
  ) { }

  public async getAvailableScheduleData(siteId: string, assetId: string): Promise<AvailableScheduleData> {
    let result = await this.apiService.get<BaseDataResponse<AvailableScheduleData>>("assetsscheduling/getavailablescheduledata", {
      siteId: siteId,
      assetId: assetId
    });
    return result.Data;
  }

  public async assetSchedule(model: ScheduleModel): Promise<BaseResponse> {
    let result = await this.apiService.post<BaseResponse>("assetsscheduling/assetSchedule", model);
    return result;
  }
}
