import { Component, OnInit, Input } from "@angular/core";
import { LoaderHelperService } from "../../shared/services/browser/loader-helper.service";
import { IonDatetime, ModalController, ToastController } from "@ionic/angular";
import * as _ from "lodash";
import { SiteAssetBattery, SiteAsset } from "src/app/data/entities/SiteAsset";
import { Guid } from "guid-typescript";
import * as EventsConstants from "../../../app/shared/constants/events-constants";
import { MemberService } from "../member.service";
import { LookupListItem, LookupListGroup } from "../assets.models";
import { AssetsService } from "../assets.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { Facility } from "src/app/data/entities/Facility";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import * as moment from "moment";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";

@Component({
  selector: "battery-data",
  templateUrl: "battery-data.component.html"
})
export class BatteryDataComponent implements OnInit {
  @Input()
  batteryId: string = null;
  @Input()
  asset: SiteAsset = <any>{};;
  @Input()
  facility: Facility = null;
  @Input()
  currentNumberOfBatteries: number;
  @Input()
  replace: boolean;
  @Input()
  checklistId: number;

  dateCode: string;

  dateCodeId: string;
  dateInstalledId: string;

  numberOfBatteries: number = 1;

  battery: SiteAssetBattery;
  oldBattery: SiteAssetBattery;

  batteryTypes: LookupListGroup[];
  batteryConditions: LookupListItem[];
  isTypeD: boolean = false;
  isInitialized: boolean = false;
  preview: boolean;
  title: string;
  replaceOneBattery: boolean;
  replaceOldBatterySerialNumber: string;

  constructor(
    private loaderHelperService: LoaderHelperService,
    private modalController: ModalController,
    private pubSubService: PubSubService,
    private memberService: MemberService,
    private assetsService: AssetsService,
    public toastController: ToastController,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private previewService: PreviewService
  ) { }

  async ngOnInit() {
    this.isInitialized = true;
    this.dateCodeId = Guid.create().toString();
    this.dateInstalledId = Guid.create().toString();
  }

  async ionViewWillEnter() {
    if (this.replace) {
      this.title = this.batteryId ? "Replace Battery" : `Replace ${this.asset.Batteries.length} Batteries`;
    } else {
      this.title = this.batteryId ? "Update Battery" : `Add + ${(this.numberOfBatteries || 0)} batteries`;
    }
    this.replaceOneBattery = this.replace && (this.batteryId || "").length > 0;
    await this.loadLookupData(this.facility);
    this.isTypeD = this.facility.CustomerType === "D";
    if (this.batteryId) {
      let batteryFromList = _.filter(this.asset.Batteries, battery => battery.Id == this.batteryId);
      this.battery = batteryFromList.length == 1 ? batteryFromList[0] : null;
    } else {
      let batteryModelId = 0;
      let batteryModelAnswer = _.find(this.asset.Answers, answer => answer.FieldDataType == "battery-model");
      if (batteryModelAnswer) {
        batteryModelId = batteryModelAnswer.DataFloat || 0;
      }
      let batteryDateCodeValue = null;
      let batteryDateCode = _.find(this.asset.Answers, answer => answer.FieldDataType == "datecode");
      if (batteryDateCode) {
        batteryDateCodeValue = batteryDateCode.Data;
      }

      let batteryInstallDateValue = null;
      let batteryInstallDate = _.find(this.asset.Answers, answer => answer.FieldDataType == "dateinstall");
      if (batteryInstallDate) {
        batteryInstallDateValue = batteryInstallDate.DataDate;
      }

      this.battery = {
        InvgenId: batteryModelId,
        ConditionId: 0,
        SiteAssetId: this.asset.Id,
        SiteKey: this.facility.Id,
        DateCode: batteryDateCodeValue,
        DateInstalled: batteryInstallDateValue,
        ChecklistId: this.checklistId
      } as SiteAssetBattery;
    }
    if (this.battery.DateCode) {
      this.dateCode = this.convertDateCode(this.battery.DateCode);
    }
    this.oldBattery = _.cloneDeep(this.battery);
    if (this.replace && this.battery) {
      this.replaceOldBatterySerialNumber = this.battery.SerialNumber;
      this.battery.SerialNumber = null;
      this.battery.DateCode = null;
      this.battery.ConditionId = 0;
      this.battery.Notes = null;
      this.dateCode = null;
      this.battery.DateInstalled = moment().format("YYYY-MM-DD");
    }
    await this.loaderHelperService.off();
  }

  private convertDateCode(dateCode: string): string {
    if (dateCode) {
      try {
        let month = dateCode.split("-")[0];
        let year = dateCode.split("-")[1];
        return `${year}-${month}-01`;
      } catch {
      }
    }
    return null;
  }
  async loadLookupData(facility: Facility) {
    const lookupData = await this.memberService.getLookupData(facility.CustomerId);
    this.batteryTypes = lookupData.BatteryTypes || [];
    this.batteryConditions = lookupData.BatteryConditions || [];
  }

  async save() {
    if (this.replace) {
      await this.replacePrivate();
    } else {
      await this.savePrivate();
    }
  }

  private async replacePrivate() {
    let batteryKey = null;
    if (this.batteryId) {
      for (let i = 0; i < this.asset.Batteries.length; i++) {
        if (this.batteryId == this.asset.Batteries[i].Id) {
          let newBattery = this.getBattery(this.asset.Batteries[i].Position);
          if (this.asset.Batteries[i].SerialNumber) {
            if (this.battery.SerialNumber && this.battery.SerialNumber == this.replaceOldBatterySerialNumber) {
              alert(`Serial Number '${this.battery.SerialNumber}' is already used for battery in position ${this.battery.Position}.`);
              return
            }
            if (await this.isSerialNumberUsedElsewhere(this.battery.SerialNumber, this.battery.BatteryKey)) {
              return;
            }
          }
          this.asset.Batteries[i] = newBattery;
          batteryKey = this.asset.Batteries[i].BatteryKey;
          if (this.asset.Batteries[i].SerialNumber) {
            if (await this.isSerialNumberUsedElsewhere(this.asset.Batteries[i].SerialNumber, this.battery.BatteryKey)) {
              return;
            }
          }
        }
      }
    } else {
      //replace all asset batteries
      var newBatteries = [];
      for (var oldBattery of this.asset.Batteries) {
        newBatteries.push(this.getBattery(oldBattery.Position));
      }
      this.asset.Batteries = newBatteries;
    }
    this.asset.Updated = true;

    for (let answer of this.asset.Answers) {
      // if (answer.FieldDataType == "battery-model") {
      //   answer.DataFloat = this.battery.InvgenId;

      //   for (let item of this.batteryTypes) {
      //     for (let item1 of item.Items) {
      //       if (item1.Value == this.battery.InvgenId) {
      //         answer.AnswerText = item1.Text;
      //       }
      //     }
      //   }
      //   answer.Updated = true;
      //   this.asset.Updated = true;
      // } else
      if (answer.FieldDataType == "datecode") {
        if (this.battery.DateCode) {
          answer.DataDate = moment(this.dateCode).startOf('month').format("YYYY-MM-DD");
          answer.AnswerText = moment(this.dateCode).format("MM-YYYY");
        } else {
          answer.DataDate = null;
          answer.AnswerText = null;
          answer.Data = null;
        }
        answer.Updated = true;
        this.asset.Updated = true;
      } else if (answer.FieldDataType == "dateinstall") {
        if (this.battery.DateInstalled) {
          answer.DataDate = this.battery.DateInstalled;
        } else {
          answer.DataDate = null;
          answer.Data = null;
        }
        answer.Updated = true;
        this.asset.Updated = true;
        this.assetsService.setAssetsInfo([this.asset]);
        this.asset.Calc = this.assetsService.getSiteAssetCalcInfo(this.asset, this.facility);
      }
    }

    this.asset.Calc = this.assetsService.getSiteAssetCalcInfo(this.asset, this.facility);
    await this.assetsService.updateFacility(this.facility);
    await this.assetsService.setAssetsInfo([this.asset]);
    this.pubSubService.$pub(EventsConstants.ADD_UPDATE_BATTERY, batteryKey || this.battery?.BatteryKey);
    this.pubSubService.$pub(EventsConstants.ADD_UPDATE_ASSET);
    await this.modalController.dismiss();
  }

  private getBattery(position: number): SiteAssetBattery {
    let battery = {
      Id: Guid.create().toString(),
      BatteryKey: Guid.create().toString(),
      SiteAssetId: this.asset.Id,
      SiteKey: this.asset.SiteKey,
      InvgenId: this.battery.InvgenId,
      ConditionId: this.battery.ConditionId,
      DateManufactured: this.battery.DateManufactured,
      DateInstalled: this.battery.DateInstalled,
      DateCode: this.battery.DateCode,
      Selected: false,
      Position: position,
      SerialNumber: this.battery.SerialNumber,
      Notes: this.battery.Notes,
      ImageCount: 0,
      Highlight: false,
      Updated: true,
      ChecklistId: this.checklistId
    } as SiteAssetBattery;
    return battery;
  }

  private async savePrivate() {
    if (this.numberOfBatteries > 250) {
      const toast = await this.toastController.create({
        message: `You can add maximum of 250 batteries at once`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
      return;
    }

    if (this.battery.SerialNumber) {

      if (await this.isSerialNumberUsedElsewhere(this.battery.SerialNumber, this.battery.BatteryKey)) {
        return;
      }
    }

    //await this.loaderHelperService.on();
    if (!this.battery.Id) {
      let batteriesToSave: SiteAssetBattery[] = [];
      for (let i = 0; i < this.numberOfBatteries; i++) {
        let newBattery = _.clone(this.battery);
        newBattery.Id = Guid.create().toString();
        newBattery.BatteryKey = Guid.create().toString();
        newBattery.Position = ++this.currentNumberOfBatteries;
        newBattery.Updated = true;
        batteriesToSave.push(newBattery);
        this.asset.Batteries = this.asset.Batteries.concat(batteriesToSave);
      }
    } else if (this.battery) {
      this.battery.Updated = true;
    }
    this.asset.Updated = true;
    await this.assetsService.updateFacility(this.facility);
    this.pubSubService.$pub(EventsConstants.ADD_UPDATE_BATTERY, this.battery?.BatteryKey);
    await this.modalController.dismiss();
  }

  async dismissModal() {
    _.merge(this.battery, this.oldBattery);
    this.battery = this.oldBattery;
    await this.modalController.dismiss();
  }

  async scanSerialNumber() {
    const text = await this.barcodeScannerHelperService.scan();
    if (text) {
      if (!(await this.isSerialNumberUsedElsewhere(text, this.battery.BatteryKey))) {
        this.battery.SerialNumber = text;
        this.battery.ChecklistId = this.checklistId;
      }
    }
  }

  private async isSerialNumberUsedElsewhere(serialNumber: string, batteryKey: string) {
    let result = await this.assetsService.getWhereBatterySerialNumberUsed(this.facility, serialNumber, batteryKey);
    if (result != null) {
      if (result[1].Id != this.asset.Id) {
        alert(`Serial Number '${serialNumber}' is already used for battery in a different asset on this location. Asset '${result[1].Description}', battery in position ${result[0].Position}.`);
        return true;
      } else if (result[0].Position != this.battery.Position) {
        alert(`Serial Number '${serialNumber}' is already used for battery in position ${result[0].Position}.`);
        return true;
      }
    }
    return false;
  }

  onSerialNumberChange() {
    this.battery.ChecklistId = this.checklistId;
  }

  onDateCodeChange() {
    if (this.isInitialized) {
      if (this.dateCode) {
        this.battery.DateCode = moment(this.dateCode).format("MM-YYYY");
      } else {
        this.battery.DateCode = null;
      }
    }
  }

  clearDateManufactured() {
    this.battery.DateManufactured = null;
  }

  clearDateInstalled() {
    this.battery.DateInstalled = null;
  }

  clearDateCode() {
    this.dateCode = null;
    this.battery.DateCode = null;
  }
  
  async onDateSelected(datePicker: IonDatetime) {
    await datePicker.confirm(true);
    if (!datePicker.value) {
      await datePicker.reset(new Date().toISOString());
      await datePicker.confirm(true);
    }
  }
}
