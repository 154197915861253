import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { Facility } from "src/app/data/entities/Facility";
import { ChecklistSectionModel, ChecklistQuestionModel } from "../checklist.models";
import * as _ from "lodash";
import { ChecklistComponent } from "../checklist.component";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventsConstants from "../../shared/constants/events-constants";
import { ChecklistService } from "../checklist.service";

@Component({
  selector: "checklist-summary-view",
  templateUrl: "summary-view.component.html"
})
export class SummaryViewComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  checklist: FacilityChecklist;
  currentSectionIndex: number = 0;
  @Output()
  mode = new EventEmitter<string>();
  loading: boolean;

  constructor(
    private pubSub: PubSubService,
    private checklistService: ChecklistService) {
  }

  async ngOnInit() {
    this.loading = true;
    this.currentSectionIndex = ChecklistComponent.currentSectionIndex;
    this.checklist = await this.checklistService.getChecklistById(this.checklist.Id);
    for (let section of this.checklist.SectionsModel) {
      this.checklistService.setSummary(section);
    }
    this.loading = false;
  }

  goToSection(index: number) {
    ChecklistComponent.currentSectionIndex = index;
    this.mode.next("list");
  }

  isSelected(index: number) {
    return ChecklistComponent.currentSectionIndex == index;
  }

  sectionVisible(section: ChecklistSectionModel): boolean {
    return section.Questions.some(x => !x.Hidden);
  }
}