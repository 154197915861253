import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Facility } from "src/app/data/entities/Facility";
import { ApiService } from "src/app/shared/services/api/api.service";
import { BaseResponseModel } from "src/app/shared/models/base-response-model";
import { AssetQuoteHistory } from "../../assets.models";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import * as _ from "lodash";
import * as moment from "moment";
import { Router } from "@angular/router"

@Component({
  selector: "asset-quote-history",
  templateUrl: "asset-quote-history.component.html"
})
export class AssetQuoteHistoryComponent implements OnInit {
  @Input()
  facility: Facility;
  @Output()
  @Output()
  draftClick = new EventEmitter<string>();
  loading: boolean = false;

  items: AssetQuoteHistory[] = [];
  query: string = null;

  constructor(
    private apiService: ApiService,
    private browserHelperService: BrowserHelperService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.loading = true;
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    this.items = (await this.apiService.get<BaseResponseModel<AssetQuoteHistory>>(
      "quote/drafts",
      { siteKey: this.facility.Id }
    )).Items;
    this.loading = false;
  }

  async open(item: AssetQuoteHistory) {
    this.browserHelperService.openUrl(item.Url);
  }

  search() {
    if (this.query == null) {
      _.forEach(this.items, (item: AssetQuoteHistory) => {
        item.Hidden = false;
      });
    } else {
      let queryLowercase = this.query.toLowerCase();
      _.forEach(this.items, (item: AssetQuoteHistory) => {
        item.Hidden = !(
          this.contains(item.Id.toString(), queryLowercase) ||
          this.contains(item.CreatedBy, queryLowercase) ||
          this.contains(item.Notes, queryLowercase) ||
          this.contains(item.Description, queryLowercase) ||
          this.contains(moment.utc(item.DateCreated).local().format("MM/DD/YYYY HH:mm"), queryLowercase) ||
          this.contains(item.ToEmails, queryLowercase) ||
          this.contains(item.CcEmails, queryLowercase)
        );
      });
    }
  }

  edit(history: AssetQuoteHistory) {
    const newUrl = `/facilities/${this.facility.Id}/editquote/${history.Key}/true/true`;
    if (location.pathname != newUrl) {
      this.router.navigate([newUrl], { replaceUrl: true });
    } else {
      this.draftClick.next(history.Key);
    }
  }

  private contains(value: string, query: string) {
    return (value || "").toLowerCase().indexOf(query) >= 0;
  }
}
