<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Assets Help
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="2" class="ion-text-end">
        <ion-fab-button size="small" class="help-fab-button">
          <ion-icon name="cube"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col size="10">
        <h4>
          <b>
            Add a Location
          </b>
        </h4>
        <div class="color-medium">
          Locations are used to group assets into a Suite, Floor, Room, Building, etc.
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="2" class="ion-text-end">
        <ion-fab-button size="small" class="help-fab-button">
          <ion-icon name="add"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col size="10">
        <h4>
          <b>
            Add an Asset
          </b>
        </h4>
        <div class="color-medium">
          Add a new Asset and enter information about it.
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="2" class="ion-text-end">
        <ion-button size="small" style="margin-top: 1rem;">
          <i class="far fa-ellipsis-v"></i>
        </ion-button>
      </ion-col>
      <ion-col size="10">
        <h4>
          <b>
            Asset Options
          </b>
        </h4>
        <div class="color-medium">
          <ul style="padding-left: 1rem;">
            <li>Edit - update Asset information</li>
            <li>Delete - delete Asset and all Sub-Assets</li>
            <li *ngIf="facility.QuotesEnabled">Request Quote - generate a quote</li>
          </ul>
          Any other options available
        </div>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="facility.HeatmapEnabled">
      <ion-row>
        <ion-col size="2" class="ion-text-center">
        </ion-col>
        <ion-col size="10">
          <h4>
            <b>
              Asset Replacement Heat Map
            </b>
          </h4>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="2" class="ion-text-end">
          <span class="fas fa-circle color-danger"></span>
        </ion-col>
        <ion-col size="10">
          <div class="color-medium">
            Replacement needed
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="2" class="ion-text-end">
          <span class="fas fa-circle color-warning"></span>
        </ion-col>
        <ion-col size="10">
          <div class="color-medium">
            Warning - will need replacement soon
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="2" class="ion-text-end">
          <span class="fas fa-circle color-success"></span>
        </ion-col>
        <ion-col size="10">
          <div class="color-medium">
            No replacement needed
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="2" class="ion-text-end">
        </ion-col>
        <ion-col size="10">
          <div class="color-medium ion-margin-top">
            Configure rules for Asset Replacement in the web application > My Company Profile > Asset Replacement Rules
            (Administrator Access only)
          </div>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="dismissModal()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>