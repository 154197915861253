<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            <ng-container *ngIf="asset.SiteAssetTypeDescription != asset.Description">
                {{asset.SiteAssetTypeDescription}}
            </ng-container>
            {{asset.Description}} - Work History
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-list *ngIf="!loading">
        <ion-item *ngFor="let item of history">
            <ion-label class="text-truncate battery-item--battery-type-label">
                <div class="battery-item--battery-type-label-text">
                    <ion-text><b>Job ID: {{ item.JobId }}</b></ion-text>
                    <span [style.color]="item.StatusColor">
                        ({{ item.Status }} {{ item.StatusDateString }})
                    </span>
                </div>
                <div class="battery-item--additional-info">
                    <div *ngIf="item.ProjectName" class="battery-item--additional-info-data">
                        Project: {{item.ProjectName}}
                    </div>
                    <div *ngIf="item.ServiceProvider" class="battery-item--additional-info-data">
                        Service Provider: {{ item.ServiceProvider }}
                    </div>
                </div>
            </ion-label>
            <ion-icon name="briefcase-outline" *ngIf="item.HasCloseout" (click)="openCloseout(item)" slot="end">
            </ion-icon>
        </ion-item>
    </ion-list>
    <ng-container *ngIf="loading">
        <ion-item *ngFor="let i of [1,2,3,4,5]">
            <ion-label>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </ion-label>
        </ion-item>
    </ng-container>
    <div *ngIf="history.length == 0 && !loading">
        No history
    </div>
</ion-content>
<ion-footer *ngIf="!loading">
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="dismissModal()" color="light">
                <ion-label>Close</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>