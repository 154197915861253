<ion-content class="ion-padding">
  <div class="ion-text-center ion-margin-bottom">
    <ion-icon name="mail" style="font-size: 50px;"></ion-icon>
  </div>
  <ion-title class="ion-text-center ion-padding-top ion-padding-bottom">
    Verification code is on it's way.
  </ion-title>
  <div class="ion-margin-bottom">
    We've sent you an email. Please open your email and find 4 digit code to
    verify your account.
  </div>

  <ion-item>
    <ion-input
      placeholder="4 digit code"
      [(ngModel)]="code"
      type="number"
    ></ion-input>
  </ion-item>
  <br />
  <ion-button expand="full" class="ion-margin-top" (click)="verify()">
    Verify Account
  </ion-button>
  <br />
  <div class="ion-margin-bottom">I accept OMS EULA <ion-checkbox [(ngModel)]="acceptTerms"></ion-checkbox>.</div>
  <div class="ion-margin-bottom"><a (click)="readTerms()">Please read OMS End User License Agreement here</a>.</div>
  <div>
    If you don't receive the code in 15 minutes, you can
    <a (click)="resend()">resend</a> to get another code. Notice that after
    clicking resend, you will get a new code, but you still may get two emails,
    one with the old code and one with the new code.
  </div>
</ion-content>
