import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { LookupListGroup } from "src/app/assets/assets.models";
import { RepositoryService } from "src/app/data/db/repository.service";
import { MemberService } from "src/app/assets/member.service";
import { ModalController } from "@ionic/angular";
import { OMSGroupSelectModalComponent } from "./oms-group-select-modal.component";
import { Subscription } from "rxjs";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";

@Component({
  selector: "oms-group-select",
  templateUrl: "oms-group-select.component.html",
  providers: []
})
export class OMSGroupSelectComponent implements OnInit, OnDestroy {
  @Input()
  model: number;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  title: string;
  @Input()
  options: LookupListGroup[];
  @Input()
  text: string;
  @Input()
  defaultText: string;
  @Input()
  disabled: boolean;
  @Input()
  disableSearch: boolean = false;

  private subscription: Subscription;
  constructor(
    public modalController: ModalController,
    private loaderHelperService: LoaderHelperService
  ) {
  }

  async ngOnInit() {
    this.setSelectedItemText();
    this.subscription = this.modelChange.subscribe(item => {
      this.model = item;
      this.setSelectedItemText();
      this.textChange.emit(this.text);
    });
    setTimeout(() => {
      this.textChange.emit(this.text);
    }, 1000)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setSelectedItemText() {
    for (let group of this.options) {
      for (let item of group.Items) {
        if (item.Value == this.model) {
          if (group.Text) {
            this.text = `${item.Text} - ${group.Text}`;
          } else {
            this.text = item.Text;
          }
          return;
        }
      }
    }
    this.text = this.defaultText || "";
  }

  async openModel() {
    if (this.disabled) {
      return;
    }
    await this.loaderHelperService.on();
    const modal = await this.modalController.create({
      component: OMSGroupSelectModalComponent,
      componentProps: {
        model: this.model,
        modelChange: this.modelChange,
        options: this.options,
        selectedText: this.text,
        title: this.title,
        disableSearch: this.disableSearch,
        onClose: this.onClose
      }
    });
    return await modal.present();
  }
}
