import { NgModule } from "@angular/core";
import { AssetsModule } from "../assets/assets.module";
import { ChecklistModule } from "../checklist/checklist.module";
import { GalleryModule } from "../gallery/gallery.module";
import { SharedModule } from "../shared/shared.module";
import { CalendarNewComponent } from "./calendar-new/calendar-new.component";
import { MyWorkListComponent } from "./list/my-work-list.component";
import { MyWorkMapComponent } from "./map/my-work-map.component";
import { MyWorkRoutingModule } from "./my-work-routing.module";
import { MyWorkSyncService } from "./my-work-sync.service";
import { MyWorkComponent } from "./my-work.component";
import { MyWorkService } from "./my-work.service";
import { NewCalendarNoteComponent } from "./new-calendar-note/new-calendar-note.component";
import { NewJobCalendarComponent } from "./new-job-calendar/new-job-calendar.component";
import { NewJobComponent } from "./new-job/new-job.component";
import { NewWarehouseBuildComponent } from "./new-warehouse-build/new-warehouse-build.component";
import { AddTimeComponent } from "./time/add/add-time.component";
import { TimeListComponent } from "./time/list/time-list.component";
import { AddTimeOffComponent } from "./timeoff/add/add-timeoff.component";
import { TimeOffComponent } from "./timeoff/list/timeoff.component";
import { WorkDataCorrectionsNeededComponent } from "./work-item-details/work-data/work-data-corrections-needed/work-data-corrections-needed.component";
import { WorkDataComponent } from "./work-item-details/work-data/work-data.component";
import { WorkItemDetailsComponent } from "./work-item-details/work-item-details.component";
import { WorkStatusHistoryComponent } from "./work-item-details/work-status-history/work-status-history.component";
import { WorkItemSiteDetailsComponent } from "./work-item-site-details/work-item-site-details.component";
import { WorkItemFilesComponent } from "./work-item-site-files/work-item-files.component";
import { SelectLocationDialogComponent } from "./new-customer-project-location/select-location/select-location-dialog.component";
import { LocationInputComponent } from "./new-customer-project-location/select-location/location-input.component";
import { NewCustomerProjectLocationComponent } from "./new-customer-project-location/new-customer-project-location.component";
import { WorkJobHistoryComponent } from "./work-item-details/work-job-history/work-job-history.component";

@NgModule({
    imports: [
        SharedModule.forRoot(),
        GalleryModule,
        ChecklistModule,
        AssetsModule,
        MyWorkRoutingModule,
    ],
    declarations: [
        MyWorkComponent,
        MyWorkListComponent,
        MyWorkMapComponent,
        WorkItemDetailsComponent,
        WorkItemSiteDetailsComponent,
        WorkItemFilesComponent,
        WorkStatusHistoryComponent,
        WorkJobHistoryComponent,
        WorkDataComponent,
        WorkDataCorrectionsNeededComponent,
        NewJobComponent,
        NewWarehouseBuildComponent,
        AddTimeComponent,
        TimeListComponent,
        TimeOffComponent,
        AddTimeOffComponent,
        CalendarNewComponent,
        NewCalendarNoteComponent,
        NewJobCalendarComponent,
        SelectLocationDialogComponent,
        LocationInputComponent,
        NewCustomerProjectLocationComponent
    ],
    entryComponents: [
        NewJobComponent
    ],
    exports: [
    ],
    providers: [
        MyWorkService,
        MyWorkSyncService
    ]
})
export class MyWorkModule { }
