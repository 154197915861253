import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import * as _ from "lodash";
import { MyWorkService } from "../../my-work.service";
import { UserTimeTrackingDayModel, UserTimeTrackingModel } from "../../my-work.models";
import { IonInput, ModalController, ToastController } from "@ionic/angular";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { LookupListGroup } from "src/app/assets/assets.models";
import { OMSGroupSelectComponent } from "src/app/assets/shared/components/oms-group-select.component";
import * as moment from "moment";
import { MultiPlatformFileUploadComponent } from "src/app/shared/components/upload/multi-platform-multi-file-upload.component";
import { MultiPlatformUploadFileName, MultiPlatformUploadOptions } from "src/app/shared/models/multi-file-upload-models";
import { ApiService } from "src/app/shared/services/api/api.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { Guid } from "guid-typescript";
import { ActivatedRoute } from "@angular/router";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";

@Component({
  selector: "add-time",
  templateUrl: "add-time.component.html"
})
export class AddTimeComponent implements OnInit {
  @Input()
  checklistId: number;

  @Input()
  model: UserTimeTrackingModel = {
    InField: true, Days: [], CustomerLocationProject: {}
  } as UserTimeTrackingModel;
  submitting: boolean;

  groups: LookupListGroup[] = [];

  customers: LookupListGroup[] = [];
  projects: LookupListGroup[] = [];

  loading: boolean;
  singleDay: boolean = true;
  private token: string;
  private baseUrl: string;

  @ViewChild("customerInput") customerInput: IonInput;
  @ViewChild("customerSelectList") customerSelectList: OMSGroupSelectComponent;
  @ViewChild("locationInput") locationInput: IonInput;
  @ViewChild("projectSelectList") projectSelectList: OMSGroupSelectComponent;
  @ViewChild("groupSelectList") groupSelectList: OMSGroupSelectComponent;
  @ViewChildren("hoursInput") hoursInput: any[];

  uploadOptions: MultiPlatformUploadOptions;
  @ViewChild(MultiPlatformFileUploadComponent) upload;

  constructor(
    private myWorkService: MyWorkService,
    private modalController: ModalController,
    private toastController: ToastController,
    private browserHelperService: BrowserHelperService,
    private pubSub: PubSubService,
    private apiService: ApiService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    try {
      this.loading = true;
      
      if (!this.model.CustomerLocationProject.CustomerType) {
        this.model.CustomerLocationProject.CustomerType = "D";
      }

      let editId = this.activatedRoute.snapshot.params["id"];
      if (editId) {
        if (!this.browserHelperService.isOffline()) {
          this.model = await this.myWorkService.getTimeTracking(editId);
        } else {
          this.loading = false;
          return;
        }
      }
      let tmpChecklistId = parseInt(this.checklistId || this.activatedRoute.snapshot.params["checklistId"]);
      if (!isNaN(tmpChecklistId)) {
        this.model.ChecklistId = tmpChecklistId;
        this.checklistId = tmpChecklistId;
      }
      if (!this.model.Id) {
        this.model.Date = moment().format("YYYY-MM-DD");
        this.addDay();
      } else {
        this.model.Days = [{
          Date: this.model.Date,
          Mileage: this.model.Mileage,
          Time: this.model.Time,
          PickerId: new Date().getTime().toString()
        }];
      }
      if (!this.model.Key) {
        this.model.Key = Guid.create().toString();
      }
      await this.loadGroups();
      if (!this.checklistId) {
        await this.loadCustomerData();
        if (this.model.CustomerLocationProject.CustomerId) {
          await this.loadProjects();
        }
      }

      this.baseUrl = await this.apiService.baseUrl();
      this.token = await this.userService.getUserToken();
      this.uploadOptions = {
        Options: {
          autoUpload: true,
          url: `${this.baseUrl}timetracking/uploadfile?itemKey=${this.model.Key}${(this.model.ChecklistId ? `&checklistId=${this.model.ChecklistId}` : '')}`,
          headers: [{ name: "token", value: this.token }]
        },
        GetFileIdAndName: (): MultiPlatformUploadFileName => this.getFileIdAndName(),
        PhotoOnly: false
      }
    }
    finally {
      this.loading = false;
      if (!this.model.Id) {
        setTimeout(() => {
          if (this.groupSelectList && this.groups.length > 0) {
            this.groupSelectList.openModel();
          }
        }, 100);
      }
    }
  }

  addDay() {
    this.model.Days.push({
      PickerId: new Date().getTime().toString(),
      Date: moment().add("days", -(this.model.Days.length)).format("YYYY-MM-DD")
    } as UserTimeTrackingDayModel);

    setTimeout(() => {
      (this.hoursInput as any).last?.nativeElement?.focus();
    }, 500);
  }

  removeDay(index: number) {
    this.model.Days.splice(index, 1);
  }

  backToList() {
    this.groupSelectList.openModel();
  }

  private getFileIdAndName(): MultiPlatformUploadFileName {
    let imageKey = Guid.create().toString();
    return {
      Id: imageKey,
      Name: new Date().getTime().toString()
    };
  }

  focusCustomer() {
    this.customerInput.setFocus();
  }

  focusCustomerList() {
    this.customerSelectList.openModel();
  }

  focusProjectSelectList() {
    this.projectSelectList.openModel();
  }

  focusGroupSelectList() {
    this.groupSelectList.openModel();
  }

  focusLocation() {
    this.locationInput.setFocus();
  }

  async save() {
    if (!(await this.validate())) return;
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    try {
      this.submitting = true;
      //Ionic workaround, ionsegment converting boolean to string
      this.model.InField = typeof this.model.InField == "boolean" ? this.model.InField : <any>this.model.InField == "true";
      if (this.model.ChecklistId) {
        var job = await this.myWorkService.getJob(this.model.ChecklistId);
        if (job) {
          this.model.ProjectId = job.ProjectId;
          this.model.CustomerLocationProject.ProjectId = job.ProjectId;
          this.model.CustomerLocationProject.CustomerId = job.MemberId;
          this.model.CustomerLocationProject.LocationId = job.SiteId;
        }
      }

      var files = this.upload.getFiles();
      for (let day of this.model.Days) {
        await this.myWorkService.addOrUpdateTimeTracking({
          ...this.model,
          Date: day.Date,
          Time: day.Time,
          Mileage: day.Mileage
        }, files);
      }
      this.afterSaveUpdate();
      this.submitting = false;
      this.pubSub.$pub(EventConstants.JOB_TIME_ADD_UPDATE);
      this.dismissModal();
    } catch {
      this.submitting = false;
      this.notifyMessage("There was an error while creating time tracking", "danger");
    } finally {
    }
  }

  async delete() {
    if (confirm("Are you sure you want to delete this note?")) {
      try {
        this.submitting = true;
        var response = await this.myWorkService.deleteTimeTracking(this.model.Id);
        if (response.Success) {
          this.afterSaveUpdate();
          this.submitting = false;
          this.pubSub.$pub(EventConstants.JOB_TIME_ADD_UPDATE);
          this.dismissModal();
        } else {
          this.submitting = false;
          this.notifyMessage(`There was an error while deleting time tracking: ${response.Message}`, "danger");
        }
      } catch {
        this.submitting = false;
        this.notifyMessage("There was an error while deleting time tracking", "danger");
      } finally {
      }
    }
  }

  switchToSingleDay() {
    this.model.Days = [this.model.Days[0]];
  }

  private afterSaveUpdate() {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "time-tracking-add-update" }, "*")
    }
  }

  private async validate(): Promise<boolean> {
    var message = null;
    if (this.model.Type == 1) {
      for (let day of this.model.Days) {
        if ((day.Time || 0) == 0) {
          message = "Time is required";
        } else if ((day.Time || 0) > 24) {
          message = "You can't put in more than 24 hours. If you want to do that, add multiple days.";
        }
      }
    }
    if (this.model.Type > 1) {
      for (let day of this.model.Days) {
        if ((day.Time || 0) == 0 && (day.Mileage || 0) == 0) {
          message = "Time and/or Mileage is required";
        } else if ((day.Time || 0) > 24) {
          message = "You can't put in more than 24 hours. If you want to do that, add multiple days.";
        }
      }
    }
    if (message) {
      await this.notifyMessage(message, "danger");
      return false;
    }
    return true;
  }

  private async notifyMessage(message: string, color: "info" | "danger" = "info") {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: "bottom",
      duration: 3000,
      cssClass: "new-job-hand-held-scanner-toast"
    });
    await toast.present();
  }

  dismissModal() {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "close-modal" }, "*");
    } else {
      this.modalController.dismiss();
    }
  }

  async customerChanged() {
    this.loadProjects();
  }

  onGroupSelectClose() {
    this.dismissModal();
  }

  onSubGroupChange(event) {
    switch (event.detail.value) {
      case "2": {
        this.model.InField = true;
        break;
      }
      case "3": {
        this.model.InField = true;
        break;
      }
    }
    setTimeout(() => {
      (this.hoursInput as any).first.nativeElement.focus();
    }, 500);
  }

  private async loadCustomerData(): Promise<void> {
    try {
      this.customers = await this.myWorkService.getCustomers();
      if (this.customers.length > 0) {
        this.customers[0].Items.push({
          Text: "Can't find customer in the list, I want to add a new customer",
          Value: -1
        });
      }
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Customers`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }

  private async loadGroups(): Promise<void> {
    try {
      this.groups = await this.myWorkService.getTimeTrackingGroups();
    } catch {
      this.groups = [];
      const toast = await this.toastController.create({
        message: `Error while loading Groups`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }

  private async loadProjects(): Promise<void> {
    if (this.model.CustomerLocationProject.CustomerId > 0) {
      try {
        this.projects = await this.myWorkService.getProjectsByCustomer(this.model.CustomerLocationProject.CustomerId);
      } catch {
        this.projects = [];
        const toast = await this.toastController.create({
          message: `Error while loading Projects`,
          color: "danger",
          position: "bottom",
          duration: 5000,
          header: "Error"
        });
        await toast.present();
      }
    }
  }
}