import { Entity, Column, PrimaryColumn } from "typeorm";
import { SiteAsset } from "./SiteAsset";
import { SiteAssetDataConfiguration, MemberLookup } from "src/app/assets/assets.models";
import { FacilityChecklist } from "./FacilityChecklist";
import { SiteJobModel } from "src/app/checklist/checklist.models";

@Entity("facility")
export class Facility {
  @PrimaryColumn()
  Id: string;
  @Column({ nullable: false })
  SiteId: number;
  @Column({ nullable: false })
  Name: string;
  @Column({ nullable: true })
  FACode: string;
  @Column({ nullable: true })
  Description: string;
  @Column({ nullable: false })
  Address: string;
  @Column({ nullable: false })
  CustomerType: string;
  @Column({ nullable: false })
  CustomerId: number;
  @Column({ nullable: true })
  SiteGalleryChecklistId: number;
  @Column({ nullable: true, default: null })
  TimeStampUpdated: number;
  @Column({ nullable: true, default: null })
  SiteWalkChecklistId: number;
  @Column({ nullable: true, default: null })
  QuotesEnabled: boolean;
  @Column({ nullable: true, default: null })
  HeatmapEnabled: boolean;
  @Column({ nullable: true, default: null })
  SiteWalkEnabled: boolean;
  @Column({ nullable: true, default: null })
  SchedulingEnabled: boolean;
  @Column({ nullable: true, default: null })
  AssetsEnabled: boolean;
  @Column({ nullable: true, default: null })
  Downloaded: boolean;
  @Column({ nullable: true, default: null })
  ManagerId: number;
  @Column({ nullable: true, default: null })
  AdditionalManagerIds: string;
  @Column({ nullable: true, default: null })
  ManagerFullName: string;
  Hidden: boolean;
  Assets: SiteAsset[];
  Configurations: SiteAssetDataConfiguration[]
  SiteWalkChecklist: FacilityChecklist;
  Jobs: SiteJobModel[];
  MemberLookup: MemberLookup;
}