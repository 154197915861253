import { Component, Input, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { IonInfiniteScroll, IonList, PopoverController } from "@ionic/angular";
import { AssetManageService } from "../asset-manage.service";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { AssetsService } from "../assets.service";
import { Facility } from "src/app/data/entities/Facility";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { AssetOptionSelectorComponent } from "../asset-option-selector/asset-option-selector.component";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventsConstants from "../../../app/shared/constants/events-constants";
import { Subscription } from "rxjs";
import { InfiniteScrollModel } from "src/app/shared/models/infinite-scroll-model";
import * as _ from "lodash";
import { MyWorkService } from "src/app/my-work/my-work.service";
import { SelectedAsset } from "../assets.models";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "asset-data-overview",
  templateUrl: "asset-data-overview.component.html"
})
export class AssetDataOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(IonList) list: IonList;
  @Input() facility: Facility;
  @Input() currentAsset: SiteAsset;
  @Input() checklistId: number;
  @Input() preview: boolean;
  isWeb: boolean = false;
  query: string;
  scrollModel: InfiniteScrollModel<SiteAsset> = new InfiniteScrollModel<SiteAsset>();
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

  private filteredAssets: SiteAsset[] = [];
  private subscription: Subscription;
  private jobAssets: SelectedAsset[] = [];
  restrictToSelectedAssets: boolean = false;

  constructor(
    public assetManageService: AssetManageService,
    private router: Router,
    private cameraHelperService: CameraHelperService,
    private assetsService: AssetsService,
    private popoverController: PopoverController,
    private pubSubService: PubSubService,
    private myWorkService: MyWorkService,
    private userService: UserService
  ) {
    this.router.events.subscribe(val => {
      this.list.closeSlidingItems();
    });
  }

  async ngOnInit() {
    this.isWeb = WebAppShowComponent.IsWebAppIFrame;
    this.facility = await this.assetsService.getFacilityWithAssets(this.facility.Id);
    await this.assetManageService.init(this.facility);
    if (this.currentAsset) {
      this.currentAsset = this.assetsService.getAssetFromFacility(this.facility, this.currentAsset.Id);
      this.currentAsset.ImageCount = this.assetsService.getAssetImageCount(this.currentAsset);
      for (let asset of this.currentAsset.Assets) {
        asset.Calc = this.assetsService.getSiteAssetCalcInfo(asset, this.facility);
        asset.ImageCount = this.assetsService.getAssetImageCount(asset);
      }
    } else {
      for (let asset of this.facility.Assets) {
        asset.Calc = this.assetsService.getSiteAssetCalcInfo(asset, this.facility);
        asset.ImageCount = this.assetsService.getAssetImageCount(asset);
      }
    }
    let assets = this.getAssets();
    for (let asset of assets) {
      asset.Hidden = false;
    }

    if (this.checklistId) {
      let job = (await this.myWorkService.getJob(this.checklistId));
      this.jobAssets = (job || {}).Assets || [];
      if (job) {
        this.restrictToSelectedAssets = job.RestrictToSelectedAssets;
      }
    }

    this.assetsService.setAllAssetsInfo(this.facility);
    if (this.currentAsset) {
      this.assetsService.setAssetsInfo([this.currentAsset]);
    }
    this.subscription = this.pubSubService.$sub(EventsConstants.ADD_UPDATE_ASSET, () => {
      this.assetsService.setAssetsInfo(this.getAssets());
      this.sortAssets();
      this.initScroll();
    });
    this.initScroll();
  }

  private isAssetHidden(siteAsset: SiteAsset) {
    if (this.checklistId && this.restrictToSelectedAssets) {
      const isInJobAssets = this.jobAssets.some(x => x.Id == siteAsset.Id || x.ReplacedWithId == siteAsset.Id) ||
        siteAsset.Assets.some(a => this.jobAssets.some(x => x.Id == a.Id || x.ReplacedWithId == a.Id));
      return !isInJobAssets;
    }
    return false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  quoteInfo() {
    alert("Asset has been quoted and the quote has been accepted. This asset is pending replacement.");
  }

  quoteInProcessInfo() {
    alert("This asset is on a quote request that is currently in process.");
  }

  startSearch() {
    let assets = this.getAssets();
    var queryLowerCase = (this.query || "").toLowerCase();
    for (let asset of assets) {
      let hidden = false;
      if (this.query) {
        hidden = (asset.SiteAssetTypeDescription || "").toLowerCase().indexOf(queryLowerCase) < 0 && (asset.Description || "").toLowerCase().indexOf(queryLowerCase) < 0;
      }
      asset.Hidden = hidden || this.isAssetHidden(asset);
    }
    this.filteredAssets = _.filter(assets, x => !x.Deleted && !x.Hidden);
    this.scrollModel.init(this.filteredAssets, this.infiniteScroll);
  }

  getAssets(): SiteAsset[] {
    if (this.currentAsset) {
      return this.currentAsset.Assets;
    } else {
      return this.facility.Assets;
    }
  }

  sortAssets() {
    if (this.currentAsset) {
      this.currentAsset.Assets = this.assetsService.sortAssets(this.currentAsset.Assets);
    } else {
      this.facility.Assets = this.assetsService.sortAssets(this.facility.Assets);
    }
  }

  async takePhoto(siteAsset: SiteAsset): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame && window.parent) {
      window.parent.postMessage({ message: "open-image-gallery-web", siteKey: this.facility.Id, siteAssetKey: siteAsset.Id }, "*")
      return;
    }
    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist,
      SiteAssetId: siteAsset.Id
    });
    const image = await this.cameraHelperService.takePhoto(fileName);
    if (image) {
      siteAsset.ImageCount = (siteAsset.ImageCount || 0) + 1;
      this.assetsService.updateFacilityNoSyncNeeded(this.facility);
    }
  }

  async moreOptions(siteAsset: SiteAsset, assets: SiteAsset[], event: any) {
    await this.presentActionSheet(siteAsset, assets, event);
  }

  async presentActionSheet(siteAsset: SiteAsset, assets: SiteAsset[], event: any) {
    const popover = await this.popoverController.create({
      component: AssetOptionSelectorComponent,
      event: event,
      mode: "ios",
      componentProps: {
        facility: this.facility,
        asset: siteAsset,
        assets: assets,
        checklistId: this.checklistId,
        preview: this.preview
      }
    });
    await popover.present();
  }

  async openGallery(asset: SiteAsset): Promise<void> {
    this.router.navigate([`/gallery/${this.facility.Id}/${this.checklist}/${asset.Id}/null/false/true/${this.preview}/open`]);
  }

  get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }


  private initScroll() {
    this.startSearch();
    this.scrollModel.init(this.filteredAssets, this.infiniteScroll);
    setTimeout(() => {
      this.scrollModel.initInfiniteScrollElement(this.infiniteScroll);
    }, 1000);
  }
}
