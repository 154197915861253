import { Entity, Column, PrimaryColumn, PrimaryGeneratedColumn } from "typeorm";

@Entity("syncqueue")
export class SyncQueue {
  @PrimaryGeneratedColumn()
  Id: number;
  @Column({ nullable: false })
  Type: 'facility' | 'checklist' | 'checklistStatus' | 'jobCheckIn' | 'jobCheckOut';
  @Column({ nullable: true })
  EntityId: string;
  @Column({ nullable: false })
  TimeStampUpdated: number;
  @Column({ nullable: false })
  Data: string;
  @Column({ nullable: false, default: 0 })
  SyncCount: number;
  @Column()
  Latitude: string;
  @Column()
  Longitude: string;
  @Column()
  DateCreated: string;
}

export class SyncQueueModel {
  items: SyncQueue[];
  lastItem: SyncQueue;
}