import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../my-work.service";
import { AlertController, IonInput, ModalController, ToastController } from "@ionic/angular";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { CustomerLocationProject } from "../my-work.models";
import { LookupListGroup, SelectListItem } from "src/app/assets/assets.models";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { OMSGroupSelectComponent } from "src/app/assets/shared/components/oms-group-select.component";
import { LocationInputComponent } from "./select-location/location-input.component";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { Subscription } from "rxjs";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { UserInfo, userInfo } from "os";
import { User } from "src/app/data/entities/User";

@Component({
  selector: "new-customer-project-location",
  templateUrl: "new-customer-project-location.component.html"
})
export class NewCustomerProjectLocationComponent implements OnInit, OnDestroy {
  @Input()
  model: CustomerLocationProject = {} as any;
  @Input()
  hideLocations: boolean;
  @Input()
  hideProjects: boolean;
  @Input()
  hideProjectTemplates: boolean;
  customers: LookupListGroup[] = [];
  templateCategories: SelectListItem[] = [];
  templates: LookupListGroup[] = [];
  projects: LookupListGroup[] = [];
  loading: boolean = false;
  templateCategoryVisible: boolean;
  customerListVisible: boolean;
  @Output() projectChange: EventEmitter<LookupListGroup[]> = new EventEmitter<LookupListGroup[]>();
  @Output() locationChange: EventEmitter<string> = new EventEmitter<string>();

  subscription: Subscription;
  @ViewChild("templateSelectList") templateSelectList: OMSGroupSelectComponent;
  @ViewChild("customerSelectList") customerSelectList: OMSGroupSelectComponent;
  @ViewChild("projectSelectList") projectSelectList: OMSGroupSelectComponent;
  @ViewChild("customerInput") customerInput: IonInput;
  @ViewChild("locationInput") locationInput: LocationInputComponent;
  @ViewChild("projectNameInput") projectNameInput: IonInput;

  private loggedInUser: User;

  constructor(
    private myWorkService: MyWorkService,
    private toastController: ToastController,
    private router: Router,
    private userService: UserService,
    private modalController: ModalController,
    private alertController: AlertController,
    private browserHelperService: BrowserHelperService,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private activatedRoute: ActivatedRoute,
    private pubSubService: PubSubService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.templateCategoryVisible = true;
    this.loggedInUser = await this.userService.getLoggedInUser();
    if (!this.model.CustomerType) this.model.CustomerType = "D";
    if (!this.model.LocationInfo) this.model.LocationInfo = {} as any;
    this.subscription = this.pubSubService.$sub(EventConstants.TEMPLATE_ADDED, async () => {
      await this.loadTemplates();

      var max = _.maxBy(_.flatMap(this.templates, t => t.Items), i => i.Value);
      if (max && max.Value) {
        this.model.TemplateId = max.Value;
        this.templateSelectList.model = max.Value;
        this.templateSelectList.options = this.templates;
        this.templateSelectList.setSelectedItemText();
      }
    });

    await this.loadData();

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async ionViewWillEnter() {
  }

  async loadData() {
    await this.loadCustomerData();
    await this.loadTemplates();
    this.setSelectText();
  }

  customerSelected() {
    return this.model.CustomerId > 0 || (this.model.CustomerId == -1 && (this.model.Customer || '').length > 1);
  }

  hasProjects() {
    return this.projects.length > 0 && this.projects[0].Items.length > 0;
  }

  hasTemplates() {
    return this.templates.length > 0 && this.templates[0].Items.length > 0;
  }

  hasTemplateCategories() {
    return this.templateCategories.length > 0;
  }


  focusTemplateSelectList() {
    this.templateSelectList.openModel();
  }

  focusCustomerList() {
    if (this.customerSelectList) {
      this.customerSelectList.openModel();
    }
  }

  focusProjectSelectList() {
    this.projectSelectList.openModel();
  }

  focusCustomer() {
    this.customerInput.setFocus();
  }

  focusLocation() {
    if (this.locationInput)
      this.locationInput.openModel();
  }

  focusProjectName() {
    this.projectNameInput.setFocus();
  }

  async templateChanged() {
    await this.loadProjects();
    await this.loadTemplateCategories();
    if (this.model.TemplateId == -1) {
      window.parent.postMessage({ message: "create-new-template" }, "*");
      this.model.TemplateId = null;
      this.templateSelectList.model = this.model.TemplateId;
      this.templateSelectList.setSelectedItemText();
    }
  }

  templateCategoryChanged(templateCategoryId: number) {
  }

  locationChanged(name: string) {
    this.locationChange.emit(name);
  }

  async customerChanged() {
    this.loadProjects();
    await this.setCustomerAssetsEnabled(this.model.CustomerId);
  }

  async setCustomerAssetsEnabled(customerId: number) {
    try {
      const result = await this.myWorkService.areAssetsConfigured(customerId);
      this.model.LocationInfo.AssetsEnabled = result.Data;
    } catch {
      return false;
    }
  }

  toNumeric(value) {
    return parseInt(value);
  }

  async scanCustomer() {
    this.scan(() => this.focusCustomer(), value => this.model.Customer = value);
  }

  async scanLocation() {
    this.scan(() => this.focusLocation(), value => this.model.Location = value);
  }

  validate() {
    let message = null;
    if (((this.model.Customer || "").length == 0 && this.model.CustomerId == -1) || (this.model.CustomerId || 0) == 0) {
      message = "Customer is required";
    } else if (!this.hideProjectTemplates && (this.model.TemplateId == -1 || (this.model.TemplateId || 0) == 0) && (this.model.TemplateName || "") == "") {
      message = "Template is required";
    } else if (this.model.TemplateId == -1 && !this.hideProjectTemplates && (this.model.TemplateCategoryId == "-1" || (this.model.TemplateCategoryId || 0) == 0) && (this.model.TemplateCategoryName || "") == "") {
      message = "Work Category is required";
    } else if (!this.hideProjects && (this.model.ProjectId == -1 || (this.model.ProjectId || 0) == 0) && (this.model.ProjectName || "") == "") {
      message = "Project is required";
    }
    if (message === null) { return { valid: true, message: null } };
    return { valid: false, message };
  }

  private async scan(handHeldCallback: () => void, scanCallback: (value: string) => {}) {
    if (await this.checkForHandHeldScanner()) {
      handHeldCallback();
    } else {
      const text = await this.barcodeScannerHelperService.scan();
      if (text) {
        scanCallback(text);
      }
    }
  }

  private async checkForHandHeldScanner() {
    if (!this.browserHelperService.isNativeApp()) {
      await this.notifyMessage(`If you have hand held scanner you can use it to scan now`);
      return true;
    }
    return false;
  }

  private async notifyMessage(message: string, color: "info" | "danger" = "info") {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: "bottom",
      duration: 3000,
      cssClass: "new-job-hand-held-scanner-toast"
    });
    await toast.present();
  }

  private setSelectText() {
    setTimeout(() => {
      if (this.customerSelectList) {
        this.customerSelectList.setSelectedItemText();
      }
      if (this.templateSelectList) {
        this.templateSelectList.setSelectedItemText();
      }
    }, 500);
  }

  private async loadCustomerData(): Promise<void> {
    try {
      this.customers = await this.myWorkService.getCustomers();
      if (this.customers.length > 0 && this.loggedInUser.Config.AddMembers) {
        this.customers[0].Items.push({
          Text: "Can't find customer in the list, I want to add a new customer",
          Value: -1
        });
      }
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Customers`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }

  private async loadTemplates() {
    if (this.hideProjectTemplates) return;
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    try {
      this.templates = await this.myWorkService.getGrouppedTemplates();
      if (WebAppShowComponent.IsWebAppIFrame && this.templates[0].Items.length > 0) {
        this.templates.push({
          Text: "Can't find template?",
          Items: [{
            Text: "Add new template",
            Value: -1
          }]
        });
      }
      this.projectChange.emit(this.projects);
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Templates`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present()
    } finally { }
  }

  public async loadProjects(): Promise<void> {
    if (this.hideProjects) return;
    if (this.model.CustomerId > 0 && this.model.TemplateId > 0) {
      try {
        this.projects = await this.myWorkService.getProjects(this.model.TemplateId, this.model.CustomerId);
        if (this.projects[0].Items.length > 0) {
          this.projects.push({
            Text: "Can't find project?",
            Items: [{
              Text: "Add new project",
              Value: -1
            }]
          });
        }
        this.projectChange.emit(this.projects);
      } catch {
        const toast = await this.toastController.create({
          message: `Error while loading Projects`,
          color: "danger",
          position: "bottom",
          duration: 5000,
          header: "Error"
        });
        await toast.present();
      }
    }
    else {
      this.projects = [];
      this.projectChange.emit([]);
    }
  }

  private async loadTemplateCategories() {
    try {
      this.templateCategories = await this.myWorkService.getAllTemplateCategories();
      if (this.templateCategories.length == 1) {
        this.model.TemplateCategoryId = this.templateCategories[0].Value;
        this.templateCategoryVisible = false;
      } else {
        this.templateCategoryVisible = true;
      }
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Work Templates`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }
}