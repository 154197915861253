import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { RegisterAssetModel, LookupListItem, MemberLookup, SiteAssetDataConfiguration } from "../assets.models";
import { ModalController, PopoverController, ToastController } from "@ionic/angular";
import { SiteAsset, SiteAssetBattery } from "../../../app/data/entities/SiteAsset";
import { Guid } from "guid-typescript";
import { AssetsService } from "../assets.service";
import * as EventsConstants from "../../../app/shared/constants/events-constants";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";
import * as _ from "lodash";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { AssetsSyncService } from "../sync/assets-sync.service";
import { BatteriesComponent } from "../batteries/batteries.component";
import { Facility } from "src/app/data/entities/Facility";
import { Subscription } from "rxjs";
import { SubAssetOptionSelectorComponent } from "../sub-asset-option-selector/sub-asset-option-selector.component";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import * as moment from "moment";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { DateTimeHelperService } from "src/app/shared/services/datetime/datetime-helper.service";
import { BatteryTestsComponent } from "../battery-tests/battery-tests.component";
import { MyWorkService } from "src/app/my-work/my-work.service";

@Component({
  selector: "asset-item-data",
  templateUrl: "asset-item-data.component.html"
})
export class AssetItemDataComponent implements OnInit, OnDestroy {
  @Input() asset: SiteAsset;
  oldAsset: SiteAsset;
  @Input() facility: Facility;
  @Input() memberLookup: MemberLookup;
  @Input() checklistId: number;
  @Input() preview: boolean;
  @Input() vendorViewId: number

  containers: SiteAsset[] = [];
  batteryTypes: LookupListItem[];
  addingInProgress: boolean = false;
  currentAssetParentId: string;
  update: boolean = false;
  replace: boolean = false;
  replaceAllSubAssets: boolean = false;
  title: string;

  private subscription: Subscription;

  constructor(
    public modalController: ModalController,
    private assetService: AssetsService,
    private pubSubService: PubSubService,
    private loaderHelperService: LoaderHelperService,
    private assetsSyncService: AssetsSyncService,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private previewService: PreviewService,
    private dateTimeHelperService: DateTimeHelperService,
    private myWorkService: MyWorkService
  ) {
  }

  async ngOnInit() {
    this.currentAssetParentId = this.asset.ParentId;
    this.containers = await this.assetService.getAssetContainers(this.asset, this.facility);
    let assets = this.asset.Assets;
    for (let c of this.facility.Configurations) {
      if (c.SiteAssetTypeId == this.asset.SiteAssetTypeId) {
        for (let c1 of this.facility.Configurations) {
          if (c1.ParentId == c.Id && !c1.OneToMany) {
            let item = _.find(assets, a => a.SiteAssetDataConfigurationId == c1.Id);
            if (item == null) {
              item = this.assetService.mapConfigToSiteAsset(
                this.facility.Id,
                c1
              )
              assets.push(item);
            }
          }
        }
      }
    }
    for (let a of this.asset.Assets) {
      a.Calc = this.assetService.getSiteAssetCalcInfo(a, this.facility);
    }
    this.asset.Assets = this.assetService.sortAssets(assets);
    this.oldAsset = _.cloneDeep(this.asset);
    if (!this.update) {
      this.subscription = this.pubSubService.$sub(EventsConstants.IMAGE_UPLOADED_WEB, (data: any) => {
        let assetId = data.siteAssetKey;
        let configId = data.siteAssetItemId;
        let imageCount = data.increment;
        this.updateImageCount(this.asset, assetId, configId, imageCount);
        for (let asset of this.asset.Assets) {
          this.updateImageCount(asset, assetId, configId, imageCount);
        }
      });
    }
    //await this.loaderHelperService.off();

    if (this.replace) {
      this.setReplaceAssetAnswers(this.asset);
    }

    this.title = this.update ? "Update" : "Add";
    if (this.replace) {
      this.title = "Replace";
    }
    if (this.preview) {
      this.title = "Preview";
    }
  }

  private setReplaceAssetAnswers(siteAsset: SiteAsset) {
    for (let answer of siteAsset.Answers) {
      if (answer.FieldDataType == "datecode" || answer.FieldDataType == "dateinstall") {
        answer.Data = null;
        answer.DataDate = null;
        if (answer.FieldDataType == "dateinstall") {
          answer.DataDate = moment().format("YYYY-MM-DD");;
        }
      }
    }
  }

  private async replaceAsset(facility: Facility, oldAsset: SiteAsset, newA: SiteAsset) {
    // var originalId = newAsset.Id;
    // var originalParentId = newAsset.ParentId;
    let newAsset = _.cloneDeep(newA);
    await this.replaceAssetInternal(facility, oldAsset, newAsset);
    if (this.replaceAllSubAssets) {
      await this.replaceSubAssetsRecursively(newAsset, oldAsset);
    } else if (newAsset.Assets) {
      for (let i = 0; i < newAsset.Assets.length; i++) {
        if (!newAsset.Assets[i].Deleted) {
          if (!newAsset.Assets[i].OneToMany && !newAsset.Assets[i].Replaceable) {
            await this.replaceAssetInternal(this.facility, oldAsset.Assets[i], newAsset.Assets[i]);
            await this.replaceSubAssetsRecursively(newAsset.Assets[i], oldAsset.Assets[i]);
          } else {
            newAsset.Assets[i].ParentId = newAsset.Id;
            newAsset.Assets[i].Updated = true;
          }
        }
      }
      oldAsset.Assets = [];
    }
    this.assetService.updateAsset(facility, oldAsset.Id, newAsset.ParentId, newAsset);
    this.addAssetToTheFacility(oldAsset);
  }

  private async replaceSubAssetsRecursively(asset: SiteAsset, oldAsset: SiteAsset) {
    if (asset.Assets && oldAsset.Assets) {
      for (let i = 0; i < asset.Assets.length; i++) {
        if (!asset.Assets[i].Deleted) {
          asset.Assets[i].ParentId = asset.Id;
          await this.replaceAssetInternal(this.facility, oldAsset.Assets[i], asset.Assets[i]);
          await this.replaceSubAssetsRecursively(asset.Assets[i], oldAsset.Assets[i]);
        }
      }
    }
  }

  private replaceBatteries(asset: SiteAsset) {
    if (asset.Batteries) {
      var newBatteries: SiteAssetBattery[] = [];
      for (var oldBattery of asset.Batteries) {
        newBatteries.push({
          Id: Guid.create().toString(),
          BatteryKey: Guid.create().toString(),
          SiteAssetId: asset.Id,
          SiteKey: asset.SiteKey,
          InvgenId: oldBattery.InvgenId,
          ConditionId: 0,
          DateManufactured: oldBattery.DateManufactured,
          DateInstalled: moment().format("YYYY-MM-DD"),
          DateCode: null,
          Selected: false,
          Position: oldBattery.Position,
          SerialNumber: null,
          Notes: null,
          ImageCount: 0,
          Highlight: false,
          BatteryTypeName: null,
          Updated: true,
          ChecklistId: this.checklistId
        });
      }
      asset.Batteries = newBatteries;
    }
  }

  private async replaceAssetInternal(facility: Facility, oldAsset: SiteAsset, newAsset: SiteAsset) {
    let oldAssetId = oldAsset.Id;

    var newAssetId = Guid.create().toString();
    oldAsset.ReplacedWithId = newAssetId;
    oldAsset.ReplacedDate = this.dateTimeHelperService.getUtcString();
    oldAsset.Deleted = true;
    var id = newAsset.Id;
    newAsset.Id = newAssetId;
    newAsset.QuoteAcceptedDate = null;
    newAsset.QuoteInProcess = false;
    newAsset.Deleted = false;
    newAsset.Updated = true;
    oldAsset.Updated = true;
    for (let answer of newAsset.Answers) {
      answer.Updated = true;
      answer.SiteAssetId = newAsset.Id;
      answer.Id = Guid.create().toString();
    }
    this.replaceBatteries(newAsset);



    if (this.checklistId) {
      let job = await this.myWorkService.getJob(this.checklistId);
      if (job && job.Assets) {
        let jobAsset = _.find(job.Assets, x => x.Id == oldAssetId);
        if (jobAsset) {
          jobAsset.ReplacedWithId = newAssetId;
          await this.myWorkService.updateJob(job);
        }
      }
    }

    //this.assetService.moveAssetTo(facility, newAsset, oldAssetParentId, newAsset.ParentId);
  }

  private updateImageCount(asset: SiteAsset, assetId: string, configId: number, imageCount: number) {
    if (asset.Id == assetId) {
      if (configId) {
        for (let answer of asset.Answers) {
          if (answer.SiteAssetDataItemConfigurationId == configId) {
            answer.ImageCount = (asset.ImageCount || 0) + (imageCount || 0);
            if (answer.ImageCount < 0) {
              answer.ImageCount = 0;
            }
          }
        }
      } else {
        asset.ImageCount = (asset.ImageCount || 0) + (imageCount || 0);
        if (asset.ImageCount < 0) {
          asset.ImageCount = 0;
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  dismissModal() {
    this.oldAsset.Batteries = this.asset.Batteries;
    _.merge(this.asset, this.oldAsset);
    this.asset = this.oldAsset;
    this.modalController.dismiss();
  }

  async addAsset() {
    if (this.addingInProgress) {
      return;
    }

    //await this.loaderHelperService.on();

    if (!(await this.checkAssetIfBatteriesAreValid(this.asset))) {
      await this.loaderHelperService.off();
      return;
    }
    for (let a of this.asset.Assets) {
      if (!a.OneToMany && !(await this.checkAssetIfBatteriesAreValid(a))) {
        await this.loaderHelperService.off();
        return;
      }
    }

    this.asset.Updated = true;
    await this.setAssetData(this.asset);
    await this.assetService.setAssetsInfo([this.asset]);
    this.asset.Calc = await this.assetService.getSiteAssetCalcInfo(this.asset, this.facility);
    this.asset.ImageCount = await this.assetService.getAssetImageCount(this.asset);

    for (let a of this.asset.Assets) {
      if (!a.OneToMany) {
        a.ParentId = this.asset.Id;
        a.Updated = this.asset.Updated;
        await this.setAssetData(a);
        a.Calc = await this.assetService.getSiteAssetCalcInfo(a, this.facility);
        a.ImageCount = await this.assetService.getAssetImageCount(a);
      }
    }

    if (this.replace) {
      await this.replaceAsset(this.facility, this.oldAsset, this.asset);
    } else if (!this.update) {
      this.addAssetToTheFacility(this.asset);
    } else if (this.currentAssetParentId != this.asset.ParentId) {
      this.assetService.moveAssetTo(this.facility, this.asset, this.currentAssetParentId, this.asset.ParentId);
    } else if (this.update) {
      this.assetService.updateAsset(this.facility, this.asset.Id, this.asset.ParentId, this.asset);
    }

    await this.modalController.dismiss();
    this.addingInProgress = false;
    await this.assetService.updateFacility(this.facility);
    this.pubSubService.$pub(EventsConstants.ADD_UPDATE_ASSET);
    this.pubSubService.$pub(EventsConstants.REFRESH_ASSETS);
    if (this.replaceAsset) {
      this.pubSubService.$pub(EventsConstants.ASSET_REPLACED, { asset: this.asset, replaceAllSubAssets: this.replaceAllSubAssets });
    }
    //await this.loaderHelperService.off();
  }


  toggleAsset(item: SiteAsset) {
    item.Expand = !item.Expand;
  }

  async goToBatteryDetails(item: SiteAsset) {
    const modal = await this.modalController.create({
      component: BatteriesComponent,
      componentProps: {
        asset: item,
        facility: this.facility,
        modalView: true,
        checklistId: this.checklist,
        preview: this.preview,
        vendorViewId: this.vendorViewId
      }
    });
    return await modal.present();
  }

  async goToBatteryTests(item: SiteAsset) {
    let batteryModelId = 0;
    let batteryModelAnswer = _.find(this.asset.Answers, answer => answer.FieldDataType == "battery-model");
    if (batteryModelAnswer) {
      batteryModelId = batteryModelAnswer.DataFloat || 0;
    }
    let batteryDateCodeValue = null;
    let batteryDateCode = _.find(this.asset.Answers, answer => answer.FieldDataType == "datecode");
    if (batteryDateCode) {
      batteryDateCodeValue = batteryDateCode.AnswerText || batteryDateCode.Data;
    }
    const modal = await this.modalController.create({
      component: BatteryTestsComponent,
      componentProps: {
        facilityId: this.facility.Id,
        checklistId: this.checklistId,
        assetId: item.Id,
        fromDialog: true,
        addedDuringReplace: true,
        dateCode: batteryDateCodeValue,
        batteryId: batteryModelId
      }
    });
    return await modal.present();
  }

  subAssetHasMoreOptions(siteAsset: SiteAsset): boolean {
    return (this.facility.SchedulingEnabled && siteAsset.Schedulable) || (this.facility.QuotesEnabled && siteAsset.Quotable);
  }

  async subAssetMoreOptions(siteAsset: SiteAsset, event: any) {
    event.stopPropagation();
    await this.presentActionSheet(siteAsset, event);
  }

  async presentActionSheet(siteAsset: SiteAsset, event: any) {
    const popover = await this.popoverController.create({
      component: SubAssetOptionSelectorComponent,
      event: event,
      mode: "ios",
      componentProps: {
        facility: this.facility,
        asset: siteAsset,
        checklistId: this.checklist,
        preview: this.preview
      }
    });
    await popover.present();
  }

  private addAssetToTheFacility(asset: SiteAsset) {
    let parentAsset = this.assetService.getAssetFromFacility(this.facility, this.asset.ParentId);
    if (parentAsset) {
      parentAsset.Assets.push(asset);
      parentAsset.Assets = this.assetService.sortAssets(parentAsset.Assets);
    } else {
      this.facility.Assets.push(asset);
      this.facility.Assets = this.assetService.sortAssets(this.facility.Assets);
    }
  }

  private setAssetData(asset: SiteAsset) {
    if ((asset.Description || "").replace(/ /g, '').length == 0) {
      asset.Description = asset.SiteAssetTypeDescription;
    }

    //Workaround for ion-select with null, we need to put '' to select empty value, so here we're putting it back to null if it's ''
    if (asset.ParentId == "") {
      asset.ParentId = null;
    }
    let batteryCount = this.assetService.getAssetAnswerListOrNumeric("battery-count", asset, this.facility) || 0;
    let batteryTypeId: number = 0;
    let batteryDateCode: string = null;
    let dateInstalled: string = null;
    let batteryStartPosition = 1;
    for (let answer of asset.Answers) {
      if (!this.update && answer.DataFloat == 0) {
        answer.Updated = true;
      }
      if ((answer.Id || "").length === 0) {
        answer.Id = Guid.create().toString();
      }
      if (asset.BatteryDetailsEnabled) {
        if (answer.FieldDataType == "battery-model") {
          batteryTypeId = answer.DataFloat;
        } else if (answer.FieldDataType == "battery-start-position") {
          batteryStartPosition = answer.DataFloat == null ? 1 : answer.DataFloat;
        } else if (answer.FieldDataType == "datecode") {
          batteryDateCode = answer.AnswerText;
        } else if (answer.FieldDataType == "dateinstall") {
          dateInstalled = answer.DataDate;
        }
      }
    }

    if (!this.update && asset.BatteryDetailsEnabled && asset.HasBatteries && batteryCount > 0) {
      let newBatteries = this.getBatteries(asset, batteryTypeId, batteryDateCode, dateInstalled, batteryCount);
      asset.Batteries = newBatteries;
    } else {
      if (asset.DeleteBatteryDetails) {
        asset.BatteryDetailsEnabled = false;
        asset.Batteries = [];
      } else {
        for (let battery of asset.Batteries) {
          battery.InvgenId = batteryTypeId;
          if (batteryDateCode) {
            battery.DateCode = batteryDateCode;
          }
          if (dateInstalled) {
            battery.DateInstalled = dateInstalled;
          }
          battery.Updated = true;
        }
        let currentBatteryCount = asset.Batteries.length;
        if (batteryCount > currentBatteryCount) {
          let newBatteries = this.getBatteries(asset, batteryTypeId, batteryDateCode, dateInstalled, batteryCount - currentBatteryCount);
          asset.Batteries = asset.Batteries.concat(newBatteries);
        } else if (batteryCount < currentBatteryCount) {
          for (let i = asset.Batteries.length - 1; i >= batteryCount; i--) {
            asset.Batteries.splice(i, 1);
          }
        }
      }
    }

    this.addingInProgress = true;
    for (let battery of asset.Batteries) {
      var newPosition = batteryStartPosition++;
      if (battery.Position != newPosition) {
        battery.Position = newPosition;
        battery.Updated = true;
      }
    }
  }

  private async checkAssetIfBatteriesAreValid(asset: SiteAsset): Promise<boolean> {
    let batteryCount = 0;
    if (asset.BatteryDetailsEnabled) {
      batteryCount = this.assetService.getAssetAnswerListOrNumeric("battery-count", asset, this.facility) || 0;
    }
    if (batteryCount > 250) {
      const toast = await this.toastController.create({
        message: `You can add maximum of 250 batteries at once`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
      return false;
    }
    return true;
  }

  private getBatteries(asset: SiteAsset, batteryTypeId: number, batteryDateCode: string, dateInstalled: string, batteryCount: number): SiteAssetBattery[] {
    let batteries = [];
    for (let i = 0; i < batteryCount; i++) {
      let battery = {
        Id: Guid.create().toString(),
        BatteryKey: Guid.create().toString(),
        SiteAssetId: asset.Id,
        SiteKey: asset.SiteKey,
        InvgenId: batteryTypeId,
        ConditionId: 0,
        DateManufactured: null,
        DateInstalled: dateInstalled,
        DateCode: batteryDateCode,
        Selected: false,
        Position: 0,
        SerialNumber: null,
        ImageCount: 0,
        Highlight: false,
        Updated: true,
        ChecklistId: this.checklistId
      };
      batteries.push(battery);
    }
    return batteries;
  }

  get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }
}
