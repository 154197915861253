import { Injectable } from "@angular/core";
import { ActionSheetController, ModalController, AlertController } from "@ionic/angular";
import { Router } from "@angular/router";
import { SiteAsset } from "../data/entities/SiteAsset";
import { AssetItemDataComponent } from "./asset-data/asset-item-data.component";
import { LoaderHelperService } from "../shared/services/browser/loader-helper.service";
import { MemberService } from "./member.service";
import { AssetsService } from "./assets.service";
import * as EventsConstants from "../../app/shared/constants/events-constants";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import * as _ from "lodash";
import { Facility } from "../data/entities/Facility";
import { ChecklistService } from "../checklist/checklist.service";
import { MyWorkService } from "../my-work/my-work.service";
import { WebAppShowComponent } from "../webapp/web-app.component";

@Injectable()
export class AssetManageService {
  facility: Facility;

  constructor(
    private router: Router,
    public modalController: ModalController,
    public actionSheetController: ActionSheetController,
    private loaderHelperService: LoaderHelperService,
    private memberService: MemberService,
    private alertController: AlertController,
    private assetsService: AssetsService,
    private pubSubService: PubSubService,
    private checklistService: ChecklistService,
    private myWorkService: MyWorkService
  ) { }

  init(facility: Facility) {
    this.facility = facility;
  }

  async goToAsset(siteAsset: SiteAsset, checklistId: number, preview: boolean, highlightAssetId: string = null) {
    if (siteAsset.IsContainer) {
      if (checklistId) {
        let checklist = await this.checklistService.getChecklistById(checklistId);
        if (WebAppShowComponent.IsWebAppIFrame || (checklist?.JobData || "").length > 0) {
          if (highlightAssetId) {
            this.router.navigate([`/facilities/${this.facility.Id}/assets/fromJob/${checklistId}/${siteAsset.Id}/highlight/${highlightAssetId}/${preview}`]);
          } else {
            this.router.navigate([`/facilities/${this.facility.Id}/assets/fromJob/${checklistId}/${siteAsset.Id}/${preview}`]);
          }
          return;
        }
      }
      if (highlightAssetId) {
        this.router.navigate([
          `facilities/${this.facility.Id}/assets/${siteAsset.Id}/highlight/${highlightAssetId}/${preview}`
        ]);
        return;
      } else {
        this.router.navigate([
          `facilities/${this.facility.Id}/assets/${siteAsset.Id}/${preview}`
        ]);
        return;
      }
    } else if (siteAsset.HasBatteries) {
      await this.goToBatteries(siteAsset, checklistId, preview);
    } else {
      await this.editAsset(siteAsset, checklistId, false, false, preview, null);
    }
  }

  async goToBatteries(siteAsset: SiteAsset, checklistId: number, preview: boolean) {
    if (siteAsset.HasBatteries && siteAsset.BatteryDetailsEnabled) {
      this.router.navigate([
        `facilities/${this.facility.Id}/assets/${checklistId}/${siteAsset.Id}/batteries/${preview}`
      ]);
    }
  }

  async editAsset(siteAsset: SiteAsset, checklistId: number, replace: boolean, replaceAllSubAssets: boolean, preview: boolean, vendorViewId: number) {
    await this.presentModal(siteAsset, checklistId, replace, replaceAllSubAssets, preview, vendorViewId);
  }

  async deleteAsset(siteAsset: SiteAsset, siteAssets: SiteAsset[], checklistId: number) {
    const alert = await this.alertController.create({
      header: "Delete Asset?",
      message:
        `Are you sure you want to delete asset '${siteAsset.Description}'?`,
      buttons: [
        {
          text: "I am sure",
          handler: async () => {
            await this.loaderHelperService.on();
            await this.assetsService.deleteAsset(this.facility, siteAsset);
            if (!siteAsset.ReplacedWithId && checklistId) {
              try {
                let job = await this.myWorkService.getJob(checklistId);
                if (job) {
                  job.Assets = job.Assets.filter(x => x.Id != siteAsset.Id);
                  await this.myWorkService.updateJob(job);
                }
              } catch { }
            }
            this.pubSubService.$pub(EventsConstants.ADD_UPDATE_ASSET);
            this.pubSubService.$pub(EventsConstants.REFRESH_ASSETS);
            this.loaderHelperService.off();
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        }
      ]
    });
    return await alert.present();
  }

  async presentModal(asset: SiteAsset, checklistId: number, replace: boolean, replaceAllSubAssets: boolean, preview: boolean, vendorViewId: number) {
    const lookupData = await this.memberService.getLookupData(this.facility.CustomerId);
    const modal = await this.modalController.create({
      component: AssetItemDataComponent,
      componentProps: {
        asset: asset,
        facility: this.facility,
        memberLookup: lookupData,
        update: true,
        replace: replace,
        replaceAllSubAssets: replaceAllSubAssets,
        checklistId: checklistId,
        preview: preview,
        vendorViewId: vendorViewId
      }
    });
    return await modal.present();
  }
}
