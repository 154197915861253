import { NgModule, ModuleWithProviders } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { AssetsService } from "./assets.service";
import { AssetsSyncService } from "./sync/assets-sync.service";
import { AssetManageService } from "./asset-manage.service";
import { AssetsRoutingModule } from "./assets-routing.module";
import { AssetsComponent } from "./assets.component";
import { AssetItemDataComponent } from "./asset-data/asset-item-data.component";
import { FacilitiesComponent } from "./facilities/facilities.component";
import { AssetDataOverviewComponent } from "./asset-data/asset-data-overview.component";
import { AssetHelpComponent } from "./help/asset-help.component";
import { BatteriesComponent } from "./batteries/batteries.component";
import { BatteryDataComponent } from "./batteries/battery-data.component";
import { MemberService } from "./member.service";
import { AssetPathInfoComponent } from "./asset-data/asset-path-info.component";
import { AssetItemDataAnswerComponent } from "./asset-data/asset-item-data-answer.component";
import { FacilityComponent } from "./facility/facility.component";
import { AssetOptionSelectorComponent } from "./asset-option-selector/asset-option-selector.component";
import { AssetHeatmapIndicatorComponent } from "./asset-heatmap-indicator/asset-heatmap-indicator.component";
import { AssetQuotesComponent } from "./asset-quotes/asset-quotes.component";
import { AssetQuoteAssetListComponent } from "./asset-quotes/asset-quote-asset-list/asset-quote-asset-list.component";
import { AssetQuoteSubmitComponent } from "./asset-quotes/asset-quote-submit/asset-quote-submit.component";
import { AssetQuoteHistoryComponent } from "./asset-quotes/asset-quote-history/asset-quote-history.component";
import { AssetQuoteService } from "./services/quotes/asset-quote.service";
import { AddQuoteEmailComponent } from "./asset-quotes/asset-quote-submit/add-quote-email/add-quote-email.component";
import { BatteryOptionSelectorComponent } from "./batteries/battery-option-selector/battery-option-selector.component";
import { AssetQuoteSelectImagesComponent } from "./asset-quotes/asset-quote-select-images/asset-quote-select-images.component";
import { AssetSchedulingComponent } from "./asset-scheduling/asset-scheduling.component";
import { GalleryModule } from "../gallery/gallery.module";
import { AssetScheduleService } from "./services/quotes/asset-schedule.service";
import { AssetWorkHistoryComponent } from "./asset-work-history/asset-work-history.component";
import { AssetsButtonComponent } from "./assets-button/assets-button.component";
import { ChecklistModule } from "../checklist/checklist.module";
import { QuotesButtonComponent } from "./quotes-button/quotes-button.component";
import { AssetLookupButtonComponent } from "./asset-lookup-button/asset-lookup-button.component";
import { SubAssetOptionSelectorComponent } from "./sub-asset-option-selector/sub-asset-option-selector.component";
import { FacilitiesListComponent } from "./facilities/list/facilities-list.component";
import { FacilitiesSearchComponent } from "./facilities/search/facilities-search.component";
import { FacilityService } from "./facility.service";
import { AssetSchedulingSelectionComponent } from "./asset-scheduling-selection/asset-scheduling-selection.component";
import { AssetReplaceComponent } from "./asset-replace/asset-replace.component";
import { BatteryTestsComponent } from "./battery-tests/battery-tests.component";
import { BatteryTestListComponent } from "./battery-tests/battery-test-list/battery-test-list.component";
import { BatteryTestService } from "./battery-test.service";
import { BatteryTestNewComponent } from "./battery-tests/battery-test-new/battery-test-new.component";
import { BatteryTestUpdateComponent } from "./battery-tests/battery-test-update/battery-test-update.component";
import { BatteryTestFormComponent } from "./battery-tests/battery-test-form/battery-test-form.component";
import { BatteryTestOptionSelectorComponent } from "./battery-tests/battery-test-option-selector/battery-test-option-selector.component";
import { BatteryTestDataComponent } from "./battery-tests/battery-test-form/battery-test-data/battery-test-data.component";
import { BatteryTestNoTesterFormComponent } from "./battery-tests/battery-test-no-tester-form/battery-test-no-tester-form.component";
import { ChecklistAnswerBatteryTestUploadComponent } from "./checklist-answer-battery-test-upload/checklist-answer-battery-test-upload.component";
import { OMSGroupSelectModalComponent } from "./shared/components/oms-group-select-modal.component";
import { OmsMultipleOptionsPickerComponent } from "./shared/components/oms-multiple-options-picker/oms-multiple-options-picker.component";

@NgModule({
    imports: [
        SharedModule.forRoot(),
        GalleryModule,
        ChecklistModule,
        AssetsRoutingModule,
    ],
    declarations: [
        AssetsComponent,
        AssetItemDataComponent,
        AssetItemDataAnswerComponent,
        AssetDataOverviewComponent,
        FacilitiesComponent,
        FacilityComponent,
        AssetHelpComponent,
        BatteriesComponent,
        BatteryDataComponent,
        AssetPathInfoComponent,
        AssetOptionSelectorComponent,
        SubAssetOptionSelectorComponent,
        AssetHeatmapIndicatorComponent,
        AssetQuotesComponent,
        AssetQuoteAssetListComponent,
        AssetQuoteSubmitComponent,
        AssetQuoteHistoryComponent,
        AddQuoteEmailComponent,
        BatteryOptionSelectorComponent,
        AssetQuoteSelectImagesComponent,
        AssetSchedulingComponent,
        AssetReplaceComponent,
        ChecklistAnswerBatteryTestUploadComponent,
        AssetWorkHistoryComponent,
        AssetsButtonComponent,
        QuotesButtonComponent,
        AssetLookupButtonComponent,
        FacilitiesListComponent,
        FacilitiesSearchComponent,
        AssetSchedulingSelectionComponent,
        BatteryTestsComponent,
        BatteryTestListComponent,
        BatteryTestNewComponent,
        BatteryTestUpdateComponent,
        BatteryTestFormComponent,
        BatteryTestDataComponent,
        BatteryTestOptionSelectorComponent,
        BatteryTestNoTesterFormComponent
    ],
    entryComponents: [
        AssetItemDataComponent,
        AssetHelpComponent,
        BatteryDataComponent,
        OMSGroupSelectModalComponent,
        OmsMultipleOptionsPickerComponent,
        AssetOptionSelectorComponent,
        SubAssetOptionSelectorComponent,
        AddQuoteEmailComponent,
        BatteryOptionSelectorComponent,
        AssetQuoteSelectImagesComponent,
        AssetSchedulingComponent,
        AssetWorkHistoryComponent,
        AssetsButtonComponent,
        QuotesButtonComponent,
        AssetLookupButtonComponent
    ],
    exports: [
        AssetsButtonComponent,
        QuotesButtonComponent,
        AssetLookupButtonComponent
    ]
})
export class AssetsModule {
    static forRoot(): ModuleWithProviders<AssetsModule> {
        return {
            ngModule: AssetsModule,
            providers: [
                AssetsService,
                FacilityService,
                AssetsSyncService,
                AssetManageService,
                AssetQuoteService,
                MemberService,
                AssetScheduleService,
                BatteryTestService
            ]
        }
    }
}
