<ion-list>
    <ion-item (click)="getQuote()" *ngIf="facility.QuotesEnabled && asset.Quotable && !preview">
        <ion-label>
            Request Quote
        </ion-label>
        <ion-icon name="send-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="scheduleWork()" *ngIf="facility.SchedulingEnabled && asset.Schedulable && !preview">
        <ion-label>
            Schedule Work
        </ion-label>
        <ion-icon name="hammer-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="workHistory()" *ngIf="facility.SchedulingEnabled && asset.Schedulable && !preview">
        <ion-label>
            Work History
        </ion-label>
        <ion-icon name="list-outline" slot="end"></ion-icon>
    </ion-item>
</ion-list>