<!-- <ion-virtual-scroll [items]="section.Questions" approxItemHeight="50px">
    <div *virtualItem="let item" class="w-100 ion-text-left">
        <div class="ion-padding-top">
            {{ item.PromptText }}
        </div>
        <div class="ion-padding-bottom">
            <checklist-question [checklist]="checklist" [section]="section" [item]="item"></checklist-question>
        </div>
    </div>
</ion-virtual-scroll> -->

<div *ngFor="let item of section.Questions" class="w-100 ion-text-left">
    <ng-container *ngIf="!item.Hidden">
        <checklist-question [checklist]="checklist" [section]="section" [preview]="preview" [item]="item" [ngClass]="getClass(item)"></checklist-question>
    </ng-container>
</div>