import { Entity, Column, PrimaryColumn } from "typeorm";
import { ChecklistSectionModel, JobDataModel } from "src/app/checklist/checklist.models";

@Entity("facilitychecklist")
export class FacilityChecklist {
  @PrimaryColumn()
  Id: number;
  @Column({ nullable: false })
  SiteKey: string;
  @Column({ nullable: false })
  Sections: string;
  @Column({ nullable: true, default: null })
  JobData: string;
  @Column({ nullable: true, default: null })
  TimeStampUpdated: number;
  @Column({ nullable: true, default: null })
  Hash: string;
  
  SectionsModel: ChecklistSectionModel[];
  JobDataModel: JobDataModel;
}