<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Location Job History
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content *ngIf="!loading">
    <ion-list>
        <ion-item *ngFor="let item of items">
            <ion-label>
                <div>Job# {{item.ChecklistId}} at {{item.CloseoutDate | localdate}}</div>
                <div class="job-status-notes">
                    <div><i>Customer:</i> {{item.CustomerName}}</div>
                </div>
                <div class="job-status-notes">
                    <div><i>Location ID:</i> {{item.SiteName}}</div>
                </div>
                <div class="job-status-notes" *ngIf="item.SoNumber">
                    <div><i>SO#:</i> {{item.SoNumber}}</div>
                </div>
                <div class="job-status-notes">
                    <div><i>Project:</i> {{item.ProjectName}}</div>
                </div>
                <div class="job-status-notes">
                    <div><i>Work Type:</i> {{item.TemplateName}}</div>
                </div>
                <div class="job-status-notes" *ngIf="item.CompletedDate">
                    <div><i>Date Completed:</i> {{item.CompletedDate | localdate}}</div>
                </div>
            </ion-label>
            <ion-button slot="end" (click)="download(item)">
                <i class="far fa-download"></i>
            </ion-button>
        </ion-item>
    </ion-list>
    <ion-card *ngIf="items.length === 0" class="ion-text-center">
        <ion-card-header>
            <ion-card-title>No location job history</ion-card-title>
        </ion-card-header>
    </ion-card>
</ion-content>
<ion-content *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="dismissModal()" color="light" class="color-white">
                Close
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>