import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AssetsModule } from './assets/assets.module';
import { RegisterModule } from './register/register.module';
import { SharedModule } from './shared/shared.module';
import { MenuComponent } from './menu/menu.component';
import { LoaderHelperService } from './shared/services/browser/loader-helper.service';
import { ChecklistModule } from './checklist/checklist.module';
import { WebAppShowComponent } from './webapp/web-app.component';
import { SyncService } from './sync/sync.service';
import { HelpComponent } from './menu/help/help.component';
import { AboutComponent } from './menu/about/about.component';
import { PendingChangesComponent } from './menu/pending-changes/pending-changes.component';
import { PendingImageDetailsComponent } from './menu/pending-image-details/pending-image-details.component';
import { SendLogsComponent } from './menu/send-logs/send-logs.component';
import { AppInfoComponent } from './menu/app-info/app-info.component';
import { SyncQueueService } from './sync/sync-queue.service';
import { MyWorkModule } from './my-work/my-work.module';
import { JobPreviewStampComponent } from './job-preview-stamp/job-preview-stamp.component';
import { WebAppQuoteEditComponent } from './webapp/web-app-quote-edit.component';
import { EmptyComponent } from './menu/empty/empty.component';

@NgModule({
  declarations: [AppComponent,
    MenuComponent,
    JobPreviewStampComponent,
    HelpComponent,
    EmptyComponent,
    AboutComponent,
    PendingChangesComponent,
    PendingImageDetailsComponent,
    SendLogsComponent,
    WebAppShowComponent,
    WebAppQuoteEditComponent,
    AppInfoComponent
  ],
  entryComponents: [MenuComponent, JobPreviewStampComponent],
  imports: [
    SharedModule.forRoot(),
    AppRoutingModule,
    AssetsModule.forRoot(),
    RegisterModule,
    ChecklistModule,
    MyWorkModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LoaderHelperService,
    SyncService,
    SyncQueueService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
