import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from '@ionic/angular';
import { Facility } from "src/app/data/entities/Facility";

@Component({
  selector: "asset-help",
  templateUrl: "asset-help.component.html"
})

export class AssetHelpComponent implements OnInit {
  @Input()
  facility: Facility;
  constructor(
    private modalController: ModalController
  ) {
  }

  ngOnInit(){
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
