import { Component, OnInit, OnDestroy } from "@angular/core";
import { AssetsService } from "../../assets.service";
import { RepositoryService } from "../../../../app/data/db/repository.service";
import { Facility } from "../../../../app/data/entities/Facility";
import { Router } from "@angular/router";
import * as EventsConstants from "../../../shared/constants/events-constants";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ToastController } from "@ionic/angular";
import { SyncService } from "src/app/sync/sync.service";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { FacilityService } from "../../facility.service";
import { FacilitySearchModel } from "../../assets.models";
import { MyWorkService } from "src/app/my-work/my-work.service";

@Component({
  selector: "facilities-list",
  templateUrl: "facilities-list.component.html"
})
export class FacilitiesListComponent implements OnInit, OnDestroy {
  facilities: Facility[] = [];
  query: string = null;
  loading: boolean = false;
  private reloadDataSubscription: Subscription;
  private logOutSubscription: Subscription;

  constructor(
    private assetsService: AssetsService,
    private facilityService: FacilityService,
    private syncService: SyncService,
    private repositoryService: RepositoryService,
    private router: Router,
    private pubSub: PubSubService,
    private browserHelperService: BrowserHelperService,
    private toastController: ToastController,
    private previewService: PreviewService,
    private myWorkService: MyWorkService
  ) { }

  async ngOnInit() {
    this.reloadDataSubscription = this.pubSub
      .$sub(EventsConstants.RELOAD_DATA_DONE)
      .subscribe(async () => {
        await this.init();
      });

    this.logOutSubscription = this.pubSub
      .$sub(EventsConstants.LOG_OUT)
      .subscribe(async () => {
        this.facilities = [];
        this.query = null;
      });
    this.previewService.setPreviewOff();
    this.init();
  }

  ngOnDestroy() {
    this.reloadDataSubscription.unsubscribe();
    this.logOutSubscription.unsubscribe();
  }

  async init() {
    this.facilities = await this.facilityService.getDownloadedFacilities();
    this.loadData();
    // this.loading = window["login"] == true || this.facilities.length == 0;
    // window["login"] = false;
    // try {
    //   await this.loadData();
    //   this.search();
    // } finally {
    //   this.loading = false;
    // }
  }

  search() {
    if (this.query == null) {
      _.forEach(this.facilities, (facility: Facility) => {
        facility.Hidden = false;
      });
    } else {
      _.forEach(this.facilities, (facility: Facility) => {
        facility.Hidden = !(
          facility.Name &&
          facility.Name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0
        );
      });
    }
  }

  goToFacility(item: Facility) {
    this.router.navigate([`/facility/${item.Id}`]);
  }

  async doRefresh(event: any) {
    let dataLoaded = await this.loadData();
    event.target.complete();
    if (!dataLoaded) {
      const toast = await this.toastController.create({
        message: `Can't refresh. Please upload all your data first.`,
        position: "bottom",
        duration: 5000
      });
      await toast.present();
    }
  }

  additionalWork(item: FacilitySearchModel) {
    this.myWorkService.addAdditionalWorkSheetLive(item.Id as any, item.SiteId);
  }
  
  async delete(item: Facility) {
    item.Downloaded = false;
    await this.repositoryService.getFacilityRepository().save(item, { transaction: false });
    this.facilities = await this.facilityService.getDownloadedFacilities();
  }

  private async loadData(): Promise<boolean> {
    let dataLoaded: boolean = false;
    let pendingDataCount = await this.syncService.getPendingDataCount();
    if (pendingDataCount == 0 && !this.browserHelperService.isOffline()) {
      await this.facilityService.reloadMyFacilitiesAndAssets();
      dataLoaded = true;
    }
    this.facilities = await this.facilityService.getDownloadedFacilities();
    return dataLoaded;
  }
}
