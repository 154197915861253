import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'localdatetime' })
export class LocalDateTimePipe implements PipeTransform {

  transform(value: any): string {
    if (value == "Invalid date") {
      return "";
    }
    if (!value) { return value; }
    // if (/d*/.test(value)) {
    //   return moment.utc(parseInt(value)).local().format("MM/DD/YYYY HH:mm");
    // }
    let result = moment.utc(value).local().format("MM/DD/YYYY HH:mm");
    if (result == "Invalid date") {
      return moment(value).format("MM/DD/YYYY HH:mm");
    }
    return result;
  }
}