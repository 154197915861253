import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ChecklistRoutingModule } from "./checklist-routing.module";
import { ChecklistComponent } from "./checklist.component";
import { ChecklistService } from "./checklist.service";
import { QuestionsComponent } from "./questions/questions.component";
import { QuestionComponent } from "./questions/question.component";
import { SectionSelectorComponent } from "./section-selector/section-selector.component";
import { ChecklistSyncService } from "./sync/checklist-sync.service";
import { ListViewComponent } from "./list-view/list-view.component";
import { SummaryViewComponent } from "./summary-view/summary-view.component";
import { ImageViewComponent } from "./image-view/image-view.component";
import { PreviewViewComponent } from "./preview-view/preview-view.component";
import { GalleryModule } from "../gallery/gallery.module";
import { QuestionMediaSelectorComponent } from "./questions/question-media-selector.component";
import { ChecklistButtonComponent } from "./checklist-button/checklist-button.component";
import { OmsMultipleOptionsPickerComponent } from "../assets/shared/components/oms-multiple-options-picker/oms-multiple-options-picker.component";
import { QuestionAttachmentsComponent } from "./questions/question-attachments.component";
  
@NgModule({
    imports: [
        SharedModule.forRoot(),
        GalleryModule,
        ChecklistRoutingModule,
    ],
    declarations: [
        ChecklistComponent,
        QuestionsComponent,
        QuestionComponent,
        SectionSelectorComponent,
        ListViewComponent,
        ImageViewComponent,
        SummaryViewComponent,
        PreviewViewComponent,
        QuestionMediaSelectorComponent,
        ChecklistButtonComponent,
        QuestionAttachmentsComponent
    ],
    entryComponents: [
        SectionSelectorComponent,
        QuestionMediaSelectorComponent,
        ChecklistButtonComponent,
        OmsMultipleOptionsPickerComponent
    ],
    exports: [
        ChecklistButtonComponent
    ],
    providers : [
        ChecklistService,
        ChecklistSyncService
    ]
})
export class ChecklistModule { }
