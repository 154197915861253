<ion-header class="main-header">
  <ion-toolbar *ngIf="title || subtitle || subtitle2">
    <ion-buttons slot="start">
      <ion-menu-toggle menu="main-menu" *ngIf="menu">
        <ion-button>
          <ion-icon name="menu"></ion-icon>
          <span class="menu-indicator" *ngIf="pendingCount > 0"></span>
        </ion-button>
      </ion-menu-toggle>
      <ng-content></ng-content>
    </ion-buttons>
    <ion-title *ngIf="title">{{ title }}</ion-title>
    <ion-note class="ion-text-center" *ngIf="subtitle">{{ subtitle }}
    </ion-note>
    <div *ngIf="subtitle2">
      <ion-note class="ion-text-center">{{ subtitle2 }}
      </ion-note>
    </div>
  </ion-toolbar>
</ion-header>