import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { LookupListGroup, LookupListItem } from "src/app/assets/assets.models";
import { ModalController } from "@ionic/angular";
import * as _ from "lodash";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";

@Component({
  selector: "oms-group-select-modal",
  templateUrl: "oms-group-select-modal.component.html"
})
export class OMSGroupSelectModalComponent implements OnInit {
  @Input()
  model: number;
  @Input()
  options: LookupListGroup[];
  @Input()
  selectedText: string;
  @Input()
  title: string;
  @Input()
  disableSearch: boolean = false;
  @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  query: string;
  visibleOptions: LookupListGroup[] = [];
  constructor(
    private modalController: ModalController,
    private loaderHelperService: LoaderHelperService) {
  }

  async ngOnInit() {
    await this.loaderHelperService.on();
    this.visibleOptions = _.clone(this.options);
  }

  async ionViewDidEnter() {
    await this.loaderHelperService.off();
  }
  
  selectItem(lookupListItem: LookupListItem) {
    this.model = lookupListItem.Value;
    this.modelChange.emit(this.model);
    this.modalController.dismiss();
  }

  search() {
    if (this.query == null) {
      this.visibleOptions = this.options;
    } else {
      var query = this.query.toLowerCase();

      var result: LookupListGroup[] = [];
      _.forEach(this.options, (option: LookupListGroup) => {
        var newOption: LookupListGroup = {
          Text: option.Text,
          Items: []
        };
        _.forEach(option.Items, (item: LookupListItem) => {
          if (item.Value == -1 || (item.Text || "").toLowerCase().indexOf(query) >= 0 || (option.Text || "").toLowerCase().indexOf(query) >= 0) {
            newOption.Items.push({
              Text: item.Text,
              Value: item.Value,
              Color: null
            });
          }
        });
        if (newOption.Items.length > 0) {
          result.push(newOption);
        }
      });
      this.visibleOptions = result;
    }
  }

  async dismissModal() {
    await this.modalController.dismiss();
    this.onClose.emit(true);
  }
}
