<oms-header title="About" [menu]="false">
  <ion-back-button defaultHref="/login"></ion-back-button>
</oms-header>
<ion-content>
  <oms-app-info></oms-app-info>
  <ion-card>
    <ion-card-header class="ion-text-center">
      <ion-img [src]="'/assets/images/logo.png'"></ion-img>
      <ion-card-title>About Us</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      MPINarada®-Narada team is an energy storage battery technology enablement company that brings innovative battery
      solutions to market to solve complex power solution challenges. We deliver value-added products, engineering
      support, logistics, monitoring, project management services and software to leading data centers, carriers, OEM’s,
      and channel service partners. Our technologies and customized programs help our customers operate efficiently,
      meet their goals and transform in a dynamic market.
      <br />
      Integrity, innovation and responsiveness to customers needs are our hallmark. Our entire team is focused on
      meeting or exceeding our customer’s expectations with on-time deliveries, outstanding customer support and
      creative solutions. <a (click)="openLink('https://mpinarada.com/')">Read more...</a>
      <a (click)="openLink('https://mpinarada.com/')">
        <ion-button shape="round" fill="outline" class="w-100">
          <i class="fal fa-globe"></i> Website
        </ion-button>
      </a>
    </ion-card-content>
  </ion-card>
</ion-content>