import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CalendarNewComponent } from "./calendar-new/calendar-new.component";
import { MyWorkComponent } from "./my-work.component";
import { NewCalendarNoteComponent } from "./new-calendar-note/new-calendar-note.component";
import { NewJobCalendarComponent } from "./new-job-calendar/new-job-calendar.component";
import { NewJobComponent } from "./new-job/new-job.component";
import { NewWarehouseBuildComponent } from "./new-warehouse-build/new-warehouse-build.component";
import { AddTimeComponent } from "./time/add/add-time.component";
import { TimeListComponent } from "./time/list/time-list.component";
import { TimeOffComponent } from "./timeoff/list/timeoff.component";
import { WorkItemDetailsComponent } from "./work-item-details/work-item-details.component";
import { WorkItemSiteDetailsComponent } from "./work-item-site-details/work-item-site-details.component";
import { WorkItemFilesComponent } from "./work-item-site-files/work-item-files.component";
import { AddTimeOffComponent } from "./timeoff/add/add-timeoff.component";

const routes: Routes = [
  { path: "work-details/:id/files", component: WorkItemFilesComponent },
  { path: "work-details/:id/site-details", component: WorkItemSiteDetailsComponent },
  { path: "work-details/:id/start/:start", component: WorkItemDetailsComponent },
  { path: "work-details/:id/:map", component: WorkItemDetailsComponent },
  { path: "work-details/:id", component: WorkItemDetailsComponent },
  { path: "list/:highlightId", component: MyWorkComponent },
  { path: "list", component: MyWorkComponent },
  { path: "createcalendarjob/:jobDate/:queueItemId/version/:version", component: NewJobCalendarComponent },
  { path: "updatecalendarnote/:noteId/version/:version", component: NewCalendarNoteComponent },
  { path: "createcalendarnote/:jobDate/version/:version", component: NewCalendarNoteComponent },
  { path: "createcalendartimeoff/:jobDate/selectUser/:selectUser/version/:version", component: AddTimeOffComponent },
  { path: "createcalendartimeoff/manage/version/:version", component: AddTimeOffComponent },
  { path: "createcalendartimeoff/:jobDate/version/:version", component: AddTimeOffComponent },
  { path: "calendarnew/:jobDate/version/:version", component: CalendarNewComponent },
  { path: "duplicatejob/:duplicateFromJobId/version/:version", component: NewJobCalendarComponent },
  { path: "createjob/version/:version", component: NewJobComponent },
  { path: "createjob", component: NewJobComponent },
  { path: "createwarehousebuild/version/:version", component: NewWarehouseBuildComponent },
  { path: "createwarehousebuild", component: NewWarehouseBuildComponent },
  { path: "updatewarehousebuild/:buildId/version/:version", component: NewWarehouseBuildComponent },
  { path: "timetracking/:checklistId/:preview/version/:version", component: TimeListComponent },
  { path: "timetracking/:checklistId/:preview", component: TimeListComponent },
  { path: "timetracking/timeoff/version/:version", component: TimeOffComponent },
  { path: "timetracking/timeoff/edit/:id/version/:version", component: AddTimeComponent },
  { path: "timetracking/timeoff/add/:checklistId/version/:version", component: AddTimeComponent },
  { path: "timetracking/timeoff", component: TimeOffComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyWorkRoutingModule { }
