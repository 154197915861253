import { NgModule } from "@angular/core";
import { Routes, RouterModule, UrlSegment, UrlSegmentGroup } from "@angular/router";
import { GalleryComponent } from "./gallery.component";

const routes: Routes = [
  {
    path: ":id/:checklistId/:assetId/:vendorViewId/:checklistGallery/:checklistGalleryAllowTakingPhotos/:preview/open",
    component: GalleryComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GalleryRoutingModule { }
