import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { ChecklistQuestionModel, ChecklistSectionModel } from "../checklist.models";
import * as _ from "lodash";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { Subscription } from "rxjs";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { ChecklistService } from "../checklist.service";

@Component({
  selector: "checklist-questions",
  templateUrl: "questions.component.html"
})
export class QuestionsComponent implements OnInit, OnDestroy {
  @Input()
  checklist: FacilityChecklist
  @Input()
  section: ChecklistSectionModel;
  @Input()
  preview: boolean;
  private isTechnician: boolean;

  private subscription: Subscription;

  constructor(private userService: UserService,
    private pubSubService: PubSubService,
    private checklistService: ChecklistService) {
  }

  async ngOnInit() {
    this.isTechnician = await this.userService.isTechnician();
    this.subscription = this.pubSubService.$sub(EventConstants.REFRESH_CHECKLIST_QUESTIONS_IMAGE_ANNOTATIONS, async () => {
      let annotations = await this.checklistService.getImageAnnotationCounts(this.checklist.Id);
      for (let image of annotations) {
        for (let q of this.section.Questions) {
          for (let i of q.Images) {
            if (i.ImageId == image.ImageId) {
              i.AnnotationCount = image.AnnotationCount;
            }
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getClass(item: ChecklistQuestionModel) {
    if (!this.section.IsReviewSection || !this.isTechnician) {
      return null;
    }
    if (item.VendorQuestionDataTypeFK == -1 || item.VendorQuestionDataTypeFK == -2) {
      let correctionResultValue = null;
      if (item.VendorQuestionDataTypeFK == -2) {
        correctionResultValue = item.AuditData;
      } else {
        correctionResultValue = this.getCorrectionResultValue();
      }
      if (correctionResultValue == "4") {
        return "question-item-correction-needed";
      } else if (correctionResultValue == "5") {
        return "question-item-correction-completed";
      }
    }
    return null;
  }

  private getCorrectionResultValue() {
    return _.find(this.section.Questions, q => q.VendorQuestionDataTypeFK == -2)?.AuditData;
  }
}
