<ion-card (click)="goToAssets()" *ngIf="facility?.AssetsEnabled">
  <ion-card-header class="ion-text-center">
    <ion-card-title>Assets</ion-card-title>
    <i class="fas fa-layer-group facility-assets-icon facility-icon"></i>
  </ion-card-header>
  <ion-card-content *ngIf="!loading">
    <div class="ion-text-center" *ngIf="heatmap">
      <div style="display: flex; justify-content: space-around;" *ngIf="facility && facility.HeatmapEnabled">
        <div>
          Replace
          <br />
          <span class="fas fa-circle color-danger"></span> <span>&nbsp;{{totalFail}}</span>
        </div>
        <div>
          Warn
          <br />
          <span class="fas fa-circle color-warning"></span> <span>&nbsp;{{totalWarn}}</span>
        </div>
        <div>
          Good
          <br />
          <span class="fas fa-circle color-success"></span> <span>&nbsp;{{totalPass}}</span>
        </div>
      </div>
    </div>
  </ion-card-content>
  <ion-card-content *ngIf="loading">
    <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
  </ion-card-content>
</ion-card>