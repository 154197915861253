import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ChecklistComponent } from "./checklist.component";

const routes: Routes = [
  { path: "open", component: ChecklistComponent },
  { path: "open/version/:version", component: ChecklistComponent },
  { path: "openFirstSection", component: ChecklistComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChecklistRoutingModule { }
