import { Component, OnInit } from "@angular/core";
import { RegisterService } from "../register.service";
import { ModalController, AlertController } from "@ionic/angular";
import { TermsComponent } from "./terms.component";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { EnvironmentService } from "src/app/shared/services/environment-service/environment.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";

@Component({
  selector: "token-confirmation",
  templateUrl: "token-confirmation.component.html"
})
export class TokenConfirmationComponent implements OnInit {
  code: string;
  acceptTerms: boolean;
  termsURL: string;
  constructor(
    private userService: UserService,
    private registerService: RegisterService,
    public modalController: ModalController,
    private alertController: AlertController,
    private environmentService: EnvironmentService,
    private browserHelperService: BrowserHelperService
  ) {
    this.acceptTerms = true;
  }

  async ngOnInit() {
    let env = await this.environmentService.getEnvironment();
    let rootUrl = this.environmentService.getRootUrl(env);
    this.termsURL = rootUrl + "/home/downloadterms";
  }

  async verify() {
    if (!this.acceptTerms) {
      const alert = await this.alertController.create({
        header: "OMS End User License Agreement",
        message:
          "Use of the OMS Mobile application requires acceptance of the End User License Agreement. Please review the OMS EULA and check the box to accept the agreement in order to proceed.",
        buttons: [
          {
            text: "Read EULA",
            handler: () => {
              this.readTerms();
            }
          },
          "Close"
        ]
      });
      await alert.present();
    } else {
      if (await this.browserHelperService.isOfflineAndMessage()) {
        return;
      }
      await this.registerService.confirm(this.code, this.acceptTerms);
    }
  }

  async resend() {
    let user = await this.userService.getLoggedInUser();
    await this.registerService.resendCode({
      Email: user.Email,
      Username: user.Username,
      Password: null,
      AcceptTerms: false
    });
  }

  async readTerms() {
    this.browserHelperService.openUrl(this.termsURL);
  }
}
