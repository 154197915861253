<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Select Assets
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" *ngIf="!loading">
  <ion-grid>
    <ion-row>
      <!-- <ion-col size="12">
          <ion-item>
            <ion-select [(ngModel)]="assetType" okText="Okay" cancelText="Dismiss" placeholder="Show All Asset Types"
              (ionChange)="onFilterChange()" class="w-100 ion-padding-bottom">
              <ion-select-option text-wrap [value]="null">
                Show All Asset Types
              </ion-select-option>
              <ion-select-option [value]="i.Id.toString()" *ngFor="let i of assetTypes">{{ i.Description }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col> -->
      <ion-col size="12">
        <ion-searchbar [(ngModel)]="query" (ngModelChange)="startSearch()" debounce="500" placeholder="Filter assets">
        </ion-searchbar>
      </ion-col>
    </ion-row>
  </ion-grid>
  <h3 class="ion-padding">
    Assets
  </h3>
  <ion-card *ngIf="assets.length === 0" class="ion-text-center">
    <ion-card-content>
      <div class="ion-padding-bottom">
        <div>
          <ng-container *ngIf="hasAssetConfig">
            There are no Assets added to this location. Would you like to add Assets now?
            <ion-button (click)="goToAssets()">Add Assets</ion-button>
          </ng-container>
          <ng-container *ngIf="!hasAssetConfig">
            If you would like to use Assets contact MPI Narada.
          </ng-container>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
  <ion-list>
    <ng-container *ngFor="let item of scrollModel.loadedData">
      <ion-item *ngIf="item.Asset" [class.asset-highlight]="item.Asset.Highlight"
        style="align-items: start; border-bottom: 1px solid lightgray" lines="none">
        <ion-label (click)="toggleSelected(item)">
          <ion-note *ngIf="item.AssetPathString" class="text-italic">
            Belongs To: {{item.AssetPathString}}
          </ion-note>
          <div>
            {{item.Asset.Description}}
          </div>
          <ion-note *ngIf="item.Asset.SiteAssetTypeDescription != item.Asset.Description">
            {{item.Asset.SiteAssetTypeDescription}}
          </ion-note>
        </ion-label>
        <div slot="end" class="quote-quantity-wrapper">
          <ion-toggle [(ngModel)]="item.Selected" slot="end"></ion-toggle>
        </div>
      </ion-item>
    </ng-container>
  </ion-list>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="scrollModel.loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<ion-content class="ion-padding" *ngIf="loading">
  <ion-item *ngFor="let i of [1,2,3,4,5]">
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-content>
<ion-footer *ngIf="!loading">
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="select()" color="primary" checked>
        <ion-label>Use {{selectedCount()}} selected</ion-label>
      </ion-segment-button>
      <ion-segment-button (click)="cancel()" color="light">
        <ion-label>Cancel</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>