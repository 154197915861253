import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { Map, tileLayer, marker, icon, Icon, latLng, markerClusterGroup, LeafletEvent, MarkerClusterGroup } from 'leaflet';
import "leaflet.markercluster";
import { JobDataModel } from "src/app/checklist/checklist.models";
import { GeolocationService } from "src/app/shared/services/geolocation-service/geolocation.service";
import { Router } from "@angular/router";
import { WorkItemSiteDetailsComponent } from "../work-item-site-details/work-item-site-details.component";
import { ModalController, PopoverController } from "@ionic/angular";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { Subscription } from "rxjs";
import { Coordinates } from "@ionic-native/geolocation/ngx";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";

@Component({
  selector: "my-work-map",
  templateUrl: "my-work-map.component.html"
})
export class MyWorkMapComponent implements OnInit, OnDestroy {
  @Input()
  workList: JobDataModel[] = [];
  map: Map;
  private zoom: 15;
  private subscription: Subscription;
  private markers: MarkerClusterGroup;
  private userCoordinates: Coordinates;

  constructor(
    private geolocationService: GeolocationService,
    private router: Router,
    private modalController: ModalController,
    private pubSubService: PubSubService,
    private previewService: PreviewService
  ) {
  }

  ngOnInit() {
    this.initMap();
    this.addMarkersToMap();
    this.fitMapToBounds();
    this.subscription = this.pubSubService.$sub(EventConstants.JOB_SEARCH_DONE, () => {
      this.markers.removeLayers(this.markers.getLayers());
      this.addMarkersToMap();
      this.fitMapToBounds();
    });
    this.setHat();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private async setHat() {
    this.geolocationService.getCurrentPosition().then(position => {
      if (position && position.coords) {
        this.userCoordinates = position.coords;
        marker([this.userCoordinates.latitude, this.userCoordinates.longitude], {
          icon: icon({
            iconUrl: `/assets/icon/construction-helmet.png`,
            iconAnchor: [12, 19]
          }),
          zIndexOffset: 1000
        }).addTo(this.map);
        this.fitMapToBounds();
      }
    })
  }

  async openItemPreview(item: JobDataModel, ev: Event) {
    const popover = await this.modalController.create({
      component: WorkItemSiteDetailsComponent,
      componentProps: {
        jobModel: item,
        facility: null,
        fromMap: true,
        preview: item.CheckedIn != true
      }
    });
    return await popover.present();
  }

  private initMap() {
    this.map = new Map("my-work-map", {
      zoomControl: false
    });
    const coords = this.geolocationService.getCurrentLocation();
    if (coords.latitude && coords.longitude) {
      this.map.setView([coords.latitude, coords.longitude], 10);
    }
    tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png').addTo(this.map);
  }

  private addMarkersToMap() {
    this.markers = markerClusterGroup({
    });
    this.markers.on("clusterclick", (e: any) => {
      e.originalEvent.preventDefault();
      e.originalEvent.stopPropagation();
      console.log("data");
    });
    for (let item of this.workList) {
      if (item.Hidden) {
        continue;
      }
      if (item.Latitude && item.Longitude) {
        this.markers.addLayer(
          marker([item.Latitude, item.Longitude], {
            icon: this.getItemIcon(item),
            title: item.SiteName,
            zIndexOffset: 1000
          })
            .on("click", (e: any) => {
              setTimeout(async () => {
                console.log("click");
                await this.openItemPreview(item, e.originalEvent);
              }, 10);
            })
        );
      }
    }
    this.markers.addTo(this.map);
  }

  private fitMapToBounds() {
    const pointList = this.workList.filter(x => !x.Hidden).map(x => {
      return latLng(x.Latitude, x.Longitude)
    });
    if (this.userCoordinates) {
      pointList.push(latLng(this.userCoordinates.latitude, this.userCoordinates.longitude));
    }
    this.map.fitBounds(pointList as any, {
      maxZoom: this.zoom
    });
  }

  private getItemIcon(item: JobDataModel): Icon {
    return icon({
      iconUrl: `/assets/icon/${this.getItemImage(item)}`,
      iconSize: [25, 41],
      iconAnchor: [12, 19]
    })
  }

  private getItemImage(item: JobDataModel) {
    if (item.StatusId == 3) {
      return "marker-icon-green.png";
    } else if (item.StatusId == 4 || item.StatusId == 16) {
      return "marker-icon-orange.png";
    } else if (item.StatusId == 5) {
      return "marker-icon-blue.png";
    } else if (item.StatusId == 9) {
      return "marker-icon-red.png";
    } else if (item.StatusId == 7) {
      return "marker-icon-red.png";
    } else if (item.StatusId == 8) {
      return "marker-icon-blue.png";
    } else if (item.StatusId == 15) {
      return "marker-icon-orange.png";
    } else {
      return "marker-icon-grey.png";
    }
  }
}