import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AssetsService } from "../assets.service";
import * as _ from "lodash";
import { Facility } from "src/app/data/entities/Facility";
import { ActivatedRoute } from "@angular/router";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { MemberService } from "../member.service";
import { Subscription } from "rxjs";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { BatteryTestService } from "../battery-test.service";
import { ActionSheetController, ModalController } from "@ionic/angular";
import { BatteryChemistry, BatteryTester } from "../battery-test.models";
import { ActionSheetButton } from "@ionic/core";
import { BatteryTestNewComponent } from "./battery-test-new/battery-test-new.component";

@Component({
  selector: "battery-tests",
  templateUrl: "battery-tests.component.html"
})
export class BatteryTestsComponent implements OnInit, OnDestroy {
  facility: Facility;
  @Input() facilityId: string;
  @Input() checklistId: number;
  @Input() assetId: string;
  @Input() fromDialog: boolean = false;
  @Input() addedDuringReplace: boolean = false;
  @Input() dateCode: string;
  @Input() batteryId: number;
  @Input() vendorViewId: number;
  asset: SiteAsset;
  loading: boolean = false;
  subtitle: string;
  testers: BatteryTester[];
  chemistries: BatteryChemistry[];

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    private memberService: MemberService,
    private pubSub: PubSubService,
    private userService: UserService,
    private batteryTestService: BatteryTestService,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController
  ) {
  }

  async ngOnInit() {
    this.loading = true;
    this.facilityId = this.route.snapshot.params["id"] || this.facilityId;
    this.checklistId = this.route.snapshot.params["checklistId"] || this.checklistId;
    this.assetId = this.route.snapshot.params["assetId"] || this.assetId;
    this.facility = await this.assetsService.getFacilityWithAssets(this.facilityId);
    this.asset = await this.assetsService.getAssetFromFacility(this.facility, this.assetId) || this.asset;
    let user = await this.userService.getLoggedInUser();
    await this.setSubtitle();
    this.testers = (await this.batteryTestService.getBatteryTesters(user.MemberId)).Items;
    this.chemistries = (await this.batteryTestService.getBatteryChemistries()).Items;
    this.loading = false;
  }

  async setSubtitle() {
    this.subtitle = "";
    let subtitle = null;
    if (this.asset) {
      if (this.asset.ParentId) {
        let parentAsset = await this.assetsService.getAssetFromFacility(this.facility, this.asset.ParentId);
        subtitle = `${parentAsset.Description} / ${this.asset.Description}`;
      } else {
        subtitle = this.asset.Description;
      }
      this.subtitle = `Asset: ${subtitle}`;
    }
  }

  ngOnDestroy() {
  }

  async addTest() {
    let modal = await this.modalController.create({
      component: BatteryTestNewComponent,
      componentProps: {
        facility: this.facility,
        assetId: this.assetId,
        checklistId: this.checklistId,
        chemistries: this.chemistries,
        addedDuringReplace: this.addedDuringReplace,
        fromDialog: this.fromDialog,
        dateCode: this.dateCode,
        batteryId: this.batteryId,
        vendorViewId: this.vendorViewId
      }
    });
    modal.present();
  }

  // async addBatteryTest() {
  //   let buttons = [];
  //   for (let tester of this.testers) {
  //     buttons.push({
  //       text: `${tester.Model} - ${tester.Version}`,
  //       handler: async () => {
  //         //await this.loaderHelperService.on();
  //         this.addTest(tester);
  //       }
  //     } as ActionSheetButton);
  //   }

  //   buttons.push({
  //     text: `Tester not in the list`,
  //     handler: async () => {
  //       this.addTest(null);
  //     }
  //   } as ActionSheetButton);

  //   buttons.push({
  //     text: "Cancel",
  //     icon: "close",
  //     role: "cancel",
  //     handler: () => { }
  //   });
  //   const actionSheet = await this.actionSheetController.create({
  //     header: "Add battery tests - select tester",
  //     buttons: buttons
  //   });
  //   await actionSheet.present();
  // }

  close() {
    if (this.fromDialog) {
      this.modalController.dismiss();
    }
  }
}