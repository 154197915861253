<ol>
    <li>
        Review Notes and Corrections Needed
    </li>
    <li>
        Tap END PREVIEW - START JOB (green button)
    </li>
    <li>
        Fix issues and enter Correction Notes to describe corrections made
    </li>
    <li>
        Tap STOP to finish work and update the Job Status
    </li>
</ol>
<div class="ion-text-center">
    <ion-button (click)="reviewCorrections()">Review Corrections</ion-button>
</div>