import { Component, OnInit, OnDestroy } from "@angular/core";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { Subscription } from "rxjs";
import { AlertController } from "@ionic/angular";

@Component({
  selector: "oms-pending-image-details",
  templateUrl: "pending-image-details.component.html"
})
export class PendingImageDetailsComponent implements OnInit, OnDestroy {
  pendingCount: number = 0;
  uploadedCount: number = 0;
  trashCount: number = 0;
  constructor(
    private cameraHelperService: CameraHelperService,
    private pubSub: PubSubService,
    private alertController: AlertController
  ) {
  }

  private subscription1: Subscription;
  private subscription2: Subscription;

  async ngOnInit() {
    await this.loadData();
    this.subscription1 = this.pubSub.$sub(EventConstants.SYNC_DONE, () => {
      this.loadData();
    });
    this.subscription2 = this.pubSub.$sub(EventConstants.SYNC_STARTED, () => {
      this.loadData();
    });
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
  
  async downloadTaken() {
    await this.cameraHelperService.downloadImagesTaken();
    await this.loadData();
  }

  async downloadUploaded() {
    await this.cameraHelperService.downloadImagesUploaded();
    await this.loadData();
  }

  async moveUploadedToTrash() {
    const alert = await this.alertController.create({
      header: "Move Images to Trash",
      message:
        `Are you sure you want to move ${this.uploadedCount} image(s) to Trash?`,
      buttons: [
        {
          text: "Yes I am sure",
          handler: async () => {
            await this.cameraHelperService.moveImagesUploadedToRecycleBin();
            await this.loadData();
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        }
      ]
    });
    return await alert.present();
  }

  async downloadTrash() {
    await this.cameraHelperService.downloadImagesDeleted();
    await this.loadData();
  }

  async emptyTrash() {
    const alert = await this.alertController.create({
      header: "Empty Trash",
      message:
        `Are you sure you want to permanently remove ${this.trashCount} image(s) from Trash? You can not undo this action!`,
      buttons: [
        {
          text: "Yes I am sure",
          handler: async () => {
            await this.cameraHelperService.deleteImagesDeleted();
            await this.loadData();
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        }
      ]
    });
    return await alert.present();
  }

  private async loadData() {
    this.pendingCount = (await this.cameraHelperService.getImagesTaken()).length;
    this.uploadedCount = (await this.cameraHelperService.getImagesUploaded()).length;
    this.trashCount = (await this.cameraHelperService.getDeletedImages()).length;
  }
}
