import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { AssetsService } from "../assets.service";
import { ActivatedRoute } from "@angular/router";
import { IonContent, IonList, IonVirtualScroll, ModalController, PopoverController } from "@ionic/angular";
import * as _ from "lodash";
import { SiteAsset, SiteAssetBattery } from "src/app/data/entities/SiteAsset";
import { BatteryDataComponent } from "./battery-data.component";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { LookupListItem, LookupListGroup } from "../assets.models";
import { MemberService } from "../member.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { Facility } from "src/app/data/entities/Facility";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { BatteryOptionSelectorComponent } from "./battery-option-selector/battery-option-selector.component";
import * as EventsConstants from "../../../app/shared/constants/events-constants";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { Subscription } from "rxjs";

@Component({
  selector: "batteries",
  templateUrl: "batteries.component.html"
})
export class BatteriesComponent implements OnInit, OnDestroy {
  @Input()
  facility: Facility;
  @Input()
  asset: SiteAsset = null;
  @Input()
  checklistId: number;
  @Input()
  preview: boolean;

  modalView: boolean = false;

  loading: boolean = false;
  batteryTypes: LookupListGroup[];
  batteriesFlat: LookupListItem[];
  batteryConditions: LookupListItem[];
  isTypeD: boolean = false;
  autoScan: boolean;
  autoScanEnabled: boolean;

  scanSyncInterval: number;
  serialNumbersString: string;

  @ViewChild(IonList, { read: ElementRef }) list: ElementRef;

  private subscription: Subscription;

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private memberService: MemberService,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private cameraHelperService: CameraHelperService,
    private popoverController: PopoverController,
    private pubSubService: PubSubService,
    private previewService: PreviewService
  ) { }

  async ngOnInit() {
    this.autoScanEnabled = this.browserHelperService.isNativeApp();
    this.subscription = this.pubSubService.$sub(EventsConstants.ADD_UPDATE_BATTERY, (highlightId) => {
      this.ionViewWillEnter(highlightId);
    });
  }

  async ngOnDestroy() {
    this.subscription.unsubscribe();
    this.stopAutoScanInterval();
  }

  async ionViewWillEnter(highlightId: string) {
    this.loading = true;
    if (!this.modalView) {
      this.facility = await this.assetsService.getFacilityWithAssets(this.route.snapshot.params["id"]);
    }
    if (!this.modalView) {
      this.asset = await this.assetsService.getAssetFromFacility(this.facility, this.route.snapshot.params["assetId"]);
    }
    highlightId = highlightId || this.route.snapshot.params["highlightId"] || null;

    this.checklistId = this.checklistId || this.route.snapshot.params["checklistId"] || null;

    this.isTypeD = this.facility.CustomerType === "D";
    await this.loadData();
    for (let battery of this.asset.Batteries) {
      battery.Highlight = false;
    }
    if (highlightId) {
      for (let battery of this.asset.Batteries) {
        if (battery.BatteryKey == highlightId) {
          battery.Highlight = true;
        }
      }
    }
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        this.scrollToHighlighted();
      }, 100);
    }, 100);
    this.preview = this.preview || this.route.snapshot.params["preview"] == "true";
  }

  async loadData() {
    const lookupData = await this.memberService.getLookupData(this.facility.CustomerId);
    this.batteryTypes = lookupData.BatteryTypes || [];
    this.batteriesFlat = _.flatMap(this.batteryTypes, i => i.Items);
    this.batteryConditions = lookupData.BatteryConditions || [];
  }

  async addBatteries() {
    const modal = await this.modalController.create({
      component: BatteryDataComponent,
      componentProps: {
        asset: this.asset,
        facility: this.facility,
        checklistId: this.checklistId,
        currentNumberOfBatteries: (this.asset.Batteries || []).length,
        preview: this.preview
      }
    });
    return await modal.present();
  }

  async more(battery: SiteAssetBattery, event: Event) {
    const popover = await this.popoverController.create({
      component: BatteryOptionSelectorComponent,
      event: event,
      translucent: true,
      componentProps: {
        facility: this.facility,
        asset: this.asset,
        battery: battery,
        checklistId: this.checklist,
        preview: this.preview
      },
      mode: "ios"
    });
    return await popover.present();
  }

  async scanSerialNumber(battery: SiteAssetBattery, index: number) {
    this.stopAutoScanInterval();
    if (battery == null) {
      battery = this.asset.Batteries[index];
    }
    const text = await this.barcodeScannerHelperService.scan(`Scan barcode for battery at a position ${battery.Position}`);
    for (let battery of this.asset.Batteries) {
      battery.Highlight = false;
    }
    battery.Highlight = true;
    this.scrollToHighlighted();
    if (text) {
      if (await this.isSerialNumberUsedElsewhere(text, battery)) {
        await this.assetsService.updateFacility(this.facility);
        return;
      }

      battery.SerialNumber = text;
      battery.ChecklistId = this.checklistId;
      battery.Updated = true;
      this.asset.Updated = true;

      let batteryToScan = null;
      if (this.autoScan) {
        this.startAutoScanInterval();
        var index = 0;
        for (let b of this.asset.Batteries) {
          if (b.Position > battery.Position) {
            batteryToScan = b;
            break;
          }
          index++;
        }
        if (batteryToScan) {
          this.scanSerialNumber(null, index);
        } else {
          this.stopAutoScanInterval();
          await this.assetsService.updateFacility(this.facility);
        }
      } else {
          await this.assetsService.updateFacility(this.facility);  
      }
    } else {
      this.stopAutoScanInterval();
      await this.assetsService.updateFacility(this.facility);
    }
  }

  stopAutoScanIfNeeded() {
    if (!this.autoScanEnabled) {
      this.stopAutoScanInterval();
    }
  }

  private stopAutoScanInterval() {
    //clearInterval(this.scanSyncInterval);
  }

  private startAutoScanInterval() {
    // this.stopAutoScanInterval();
    // if (this.autoScanEnabled) {
    //   setInterval(async () => {
    //     if (this.autoScanEnabled) {
    //       let tmp = this.asset.Batteries.map(x => x.SerialNumber).join(",");
    //       if (tmp != this.serialNumbersString) {
    //         await this.assetsService.updateFacility(this.facility);
    //         console.log("update facility from auto scan");
    //         this.setSerialNumberString();
    //       }
    //     }
    //   }, 120 * 1000);
    // } else {
    //   this.setSerialNumberString();
    // }
  }

  private setSerialNumberString() {
    this.serialNumbersString = this.asset.Batteries.map(x => x.SerialNumber).join(",");
  }

  async takePhoto(battery: SiteAssetBattery) {
    if (WebAppShowComponent.IsWebAppIFrame && window.parent) {
      window.parent.postMessage({ message: "open-image-gallery-web", siteKey: this.facility.Id, siteDetailKey: battery.Id, siteAssetKey: this.asset.Id }, "*")
      return;
    }

    if (!this.browserHelperService.isNativeApp()) {
      alert("You can only use this functionality if you install the app.");
      return;
    }

    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist,
      BatteryKey: battery.BatteryKey
    });

    const image = await this.cameraHelperService.takePhoto(fileName);
    if (image) {
      battery.ImageCount = (battery.ImageCount || 0) + 1;
      this.assetsService.updateFacility(this.facility);
    }

    this.highlightBattery(battery);
  }

  private async isSerialNumberUsedElsewhere(serialNumber: string, battery: SiteAssetBattery) {
    let result = await this.assetsService.getWhereBatterySerialNumberUsed(this.facility, serialNumber, battery.BatteryKey);
    if (result != null) {
      if (result[1].Id != this.asset.Id) {
        alert(`Serial Number '${serialNumber}' is already used for battery in a different asset on this location. Asset '${result[1].Description}', battery in position ${result[0].Position}.`);
      } else {
        alert(`Serial Number '${serialNumber}' is already used for battery in position ${result[0].Position}.`);
      }
      return true;
    }
    return false;
  }

  getConditionText(battery: SiteAssetBattery) {
    const item = _.find(this.batteryConditions, x => x.Value == battery.ConditionId);
    if (item) {
      return item.Text;
    }
    return "";
  }

  getTypeText(battery: SiteAssetBattery) {
    const item = _.find(this.batteriesFlat, x => x.Value == battery.InvgenId);
    if (item) {
      return item.Text;
    }
    return "";
  }

  async dismissModal() {
    this.modalController.dismiss();
  }

  get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }

  private scrollToHighlighted() {
    var index = _.findIndex(this.asset.Batteries, b => b.Highlight);
    if (index >= 0) {
      const array = this.list.nativeElement.children;
      const item = array[index];
      item.scrollIntoView({ behaviour: 'auto', block: 'start' });
    }
  }

  private highlightBattery(battery: SiteAssetBattery) {
    for (let battery of this.asset.Batteries) {
      battery.Highlight = false;
    }
    battery.Highlight = true;
  }
}
