import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { GeolocationService } from '../geolocation-service/geolocation.service';

@Injectable()
export class GeolocationInterceptor implements HttpInterceptor {
  constructor(
    private geolocationService: GeolocationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let headers = await this.getHeaders();
    const authReq = req.clone({
      setHeaders: headers
    });

    return next.handle(authReq).toPromise();
  }

  private async getHeaders() {
    let location = this.geolocationService.getCurrentLocation();
    return {
      //latitude: String(location.latitude || ""),
      //longitude: String(location.longitude || "")
    };
  }
}
