import { Injectable } from '@angular/core';
import { BrowserHelperService } from '../browser/browser-helper.service';
import { Camera, CameraOptions, MediaType } from '@ionic-native/camera/ngx';
import { File, DirectoryEntry, Entry, FileEntry } from '@ionic-native/file/ngx';
import { CameraPhotoModel, PhotoFileNameModel } from './models/camera-photo.model';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PubSubService } from '../pubsub/pub-sub.service';
import * as EventsConstants from '../../constants/events-constants';
import { LoaderHelperService } from '../browser/loader-helper.service';
import * as _ from "lodash";
import { Guid } from 'guid-typescript';
import { EnvironmentService } from '../environment-service/environment.service';
import { ErrorHandlerService } from '../error/error-handler.service';
import { GeolocationService } from '../geolocation-service/geolocation.service';
import { MediaCapture, CaptureError, MediaFile } from '@ionic-native/media-capture/ngx';
import * as moment from 'moment';
import { Chooser } from '@ionic-native/chooser/ngx';
import { RepositoryService } from 'src/app/data/db/repository.service';
import { FileSync } from 'src/app/data/entities/FileSync';

@Injectable()
export class CameraHelperService {

  constructor(
    private browserHelperService: BrowserHelperService,
    private camera: Camera,
    private file: File,
    private statusBar: StatusBar,
    private pubSub: PubSubService,
    private loaderHelperService: LoaderHelperService,
    private environmentService: EnvironmentService,
    private errorHandlerService: ErrorHandlerService,
    private geolocationService: GeolocationService,
    private mediaCapture: MediaCapture,
    private chooser: Chooser,
    private repositoryService: RepositoryService
  ) {
  }

  async takeVideo(fileName: string): Promise<CameraPhotoModel> {
    if (!this.browserHelperService.isNativeApp()) {
      alert("You can only use this functionality if you install the app.");
      return null;
    }
    try {
      const options: CameraOptions = {
        quality: 70,
        targetWidth: 3000,
        targetHeight: 3000,
        destinationType: this.camera.DestinationType.FILE_URI,
        correctOrientation: true,
        encodingType: this.camera.EncodingType.JPEG,
        allowEdit: false
      };
      let mediaFile = (await this.mediaCapture.captureVideo({
        limit: 1,
        quality: 0
      })) as MediaFile[];
      if (mediaFile && mediaFile.length > 0) {
        let video = mediaFile[0];
        fileName = fileName.split(".jpg")[0] + "." + video.fullPath.split(".")[1];
        var newPath = video.fullPath.replace("/private", "file://");
        const storedPhoto = await this.movePhotoToPermanentStorage(newPath, fileName);
        this.pubSub.$pub(EventsConstants.IMAGE_TAKEN, storedPhoto);
        return storedPhoto;
      } else {
        //alert("Error while capturing video");
      }
    } catch (err) {
      if (err != "No Video Selected") {
        //alert(JSON.stringify(err));
        //alert("Error while capturing video");
      }
    } finally {
      try {
        if (this.browserHelperService.isNativeApp()) {
          await this.statusBar.hide();
          await this.statusBar.show();
        }
      } catch (err) {
        this.errorHandlerService.logError(err);
      }
    }
    return null;
  }

  async addFileSync(newFileName: string, originalFileName: string) {
    let repo = this.repositoryService.getFileSyncRepository();
    await this.removeFileSync(newFileName);
    let fileSync: FileSync = {
      Id: null,
      NewFileName: newFileName,
      OriginalFileName: originalFileName,
    }
    await repo.save(fileSync, { transaction: false });
  }

  async removeFileSync(fileName: string) {
    let repo = this.repositoryService.getFileSyncRepository();
    let fromDb = await this.getFileSync(fileName);
    if (fromDb) {
      await repo.remove(fromDb, { transaction: false });
    }
  }

  async getFileSync(fileName: string): Promise<FileSync> {
    let repo = this.repositoryService.getFileSyncRepository();
    let fromDb = await repo.findOne({
      where: {
        NewFileName: fileName
      }
    })
    return fromDb;
  }

  async selectFile(fileName: string): Promise<CameraPhotoModel> {
    if (!this.browserHelperService.isNativeApp()) {
      alert("You can only use this functionality if you install the app.");
      return null;
    }
    try {
      let file = await this.chooser.getFile();
      let extension = "";
      let split = file.name.split(".");
      if (split.length > 1) {
        extension = "." + split[split.length - 1];
      }
      fileName = fileName.replace(".jpg", extension);
      let result = await this.copyFileToPermanentStorage(file.uri, fileName);
      result.OriginalFileName = file.name;
      this.addFileSync(fileName, file.name);
      return result;
    } catch (err) {
      this.errorHandlerService.logError(err);
      if (err == "Failed to fetch data.") {
        alert("File corrupted or file type not supported.");
      }
    }
    return null;
  }

  async takePhoto(fileName: string, fromGallery: boolean = false): Promise<CameraPhotoModel> {
    if (!this.browserHelperService.isNativeApp()) {
      alert("You can only use this functionality if you install the app.");
      return null;
    }
    try {
      const options: CameraOptions = {
        quality: 70,
        targetWidth: 3000,
        targetHeight: 3000,
        destinationType: this.camera.DestinationType.FILE_URI,
        correctOrientation: true,
        encodingType: this.camera.EncodingType.JPEG,
        allowEdit: false
      };
      if (fromGallery) {
        options.sourceType = this.camera.PictureSourceType.SAVEDPHOTOALBUM;
      }
      const tempImage = await this.camera.getPicture(options);
      const storedPhoto = await this.movePhotoToPermanentStorage(tempImage, fileName);
      this.pubSub.$pub(EventsConstants.IMAGE_TAKEN, storedPhoto);
      return storedPhoto;
    } catch (err) {
      console.error(err);
      if (err != "No Image Selected") {
        alert("Error while taking photo");
      }
    } finally {
      try {
        if (this.browserHelperService.isNativeApp()) {
          await this.statusBar.hide();
          await this.statusBar.show();
        }
      } catch (err) {
        this.errorHandlerService.logError(err);
      }
    }
    return null;
  }

  async pickImages(getFileName: (galleryPhoto: GalleryPhotos) => string): Promise<CameraPhotoModel[]> {
    // eslint-disable-next-line no-async-promise-executor
    var images = await new Promise<GalleryPhotos[]>(async resolve => {
      return this.multipleFileInputExperience(resolve);
    });
    let result: CameraPhotoModel[] = [];
    for (let image of images) {
      const imageDirectory = await this.getImagesTakenDirectory();
      const fileName = getFileName(image);
      //const fileUrl = imageDirectory.fullPath + "/" + fileName;
      try{
      let entry: FileEntry = await this.file.writeFile(imageDirectory.toURL(),
        fileName,
        image.file,
        { replace: true });
      //let entry: FileEntry = await this.file.resolveLocalFilesystemUrl(fileUrl);
      let item = {
        FileName: entry.name,
        PhonePath: entry.nativeURL,
        Entry: entry
      }
      result.push(item);
      this.pubSub.$pub(EventsConstants.IMAGE_TAKEN, item);
    } catch(err){
      debugger;
    }
    }
    return result;
  }


  private multipleFileInputExperience(resolve: any) {
    let input = document.querySelector(
      '#camera-input-multiple',
    ) as HTMLInputElement;

    const cleanup = () => {
      input.parentNode?.removeChild(input);
    };

    if (!input) {
      input = document.createElement('input') as HTMLInputElement;
      input.id = 'camera-input-multiple';
      input.type = 'file';
      input.hidden = true;
      input.multiple = true;
      document.body.appendChild(input);
      input.addEventListener('change', (_e: any) => {
        const photos: GalleryPhotos[] = [];
        for (let i = 0; i < input.files!.length; i++) {
          const file = input.files![i];
          let format = 'jpeg';

          if (file.type === 'image/png') {
            format = 'png';
          } else if (file.type === 'image/gif') {
            format = 'gif';
          }
          photos.push({
            webPath: URL.createObjectURL(file),
            name: file.name,
            format: format,
            file: file
          });
        }
        resolve(photos);
        cleanup();
      });
    }

    input.accept = 'image/*';

    input.click();
  }

  public getFileName(options: PhotoFileNameModel) {
    var items = [];

    var setItem = (key: string, value: any) => {
      if (value) {
        items.push(`${key}_${value}`);
      }
    }

    var getFileLatLong = () => {
      let coords = this.geolocationService.getCurrentLocation();
      let lat;
      let long;
      if (coords && coords.latitude && coords.longitude) {
        lat = (coords.latitude || 0).toString().replace(".", "--");
        long = (coords.longitude || 0).toString().replace(".", "--");
      }
      var tmpItems = [];
      tmpItems.push("la_" + lat);
      tmpItems.push("lo_" + long);
      return tmpItems.join("__");
    }

    setItem("c", options.ChecklistId);
    setItem("s", options.SiteId);
    setItem("ck", options.ChecklistId);
    setItem("sa", options.SiteAssetId);
    setItem("sac", options.SiteAssetDataItemConfigurationId);
    setItem("bk", options.BatteryKey);
    setItem("vv", options.VendorViewId);
    setItem("wik", options.WarrantyItemKey);
    setItem("wc", options.WarrantyColumn);
    setItem("wfk", options.WarrantyFileKey);
    setItem("dc", moment().utc().valueOf());
    //setItem("u", userId);
    items.push(getFileLatLong());
    setItem("v", Guid.create().toString().replace(/-/g, ""));
    return items.join("__") + ".jpg";
  };

  getChecklistId(fileName: string): number {
    return parseInt(this.getFileNameProperty(fileName, "c"));
  }

  getVendorViewId(fileName: string): number {
    return parseInt(this.getFileNameProperty(fileName, "vv"));
  }

  async getPhotoBase64(path: string): Promise<string> {
    const tempFilename = path.substr(path.lastIndexOf('/') + 1);
    const tempBaseFilesystemPath = path.substr(0, path.lastIndexOf('/') + 1);
    let base64 = await this.file.readAsDataURL(tempBaseFilesystemPath, tempFilename);
    return (base64 || "").split(",")[1];
    // .replace('data:image/jpeg;base64,', '')
    // .replace('data:image/jpg;base64,', '')
    // .replace('data:image/png;base64,', '')
    // .replace('data:image/gif;base64,', '')
    // .replace('data:video/mp4;base64,', '')
    // .replace('data:video/quicktime;base64,', '');
  }

  public async getImagesTaken(): Promise<Entry[]> {
    return this.getDirectoryFiles(await this.getImagesTakenFolder());
  }

  public async getImagesTakenDirectory(): Promise<DirectoryEntry> {
    return this.getDirectory(await this.getImagesTakenFolder());
  }

  public async getImagesUploaded(): Promise<Entry[]> {
    return this.getDirectoryFiles(await this.getImagesUploadedFolder());
  }

  public async getImagesUploadedDirectory(): Promise<DirectoryEntry> {
    return this.getDirectory(await this.getImagesUploadedFolder());
  }

  public async getDeletedImagesDirectory(): Promise<DirectoryEntry> {
    return this.getDirectory(await this.getImagesDeletedFolder());
  }

  public async getDeletedImages(): Promise<Entry[]> {
    return this.getDirectoryFiles(await this.getImagesDeletedFolder());
  }

  public async createDirectories() {
    await this.getImagesTakenDirectory();
    await this.getImagesUploadedDirectory();
    await this.getDeletedImagesDirectory();
  }

  public async downloadImagesTaken() {
    const imagesTaken = await this.getImagesTaken();
    this.downloadImages(imagesTaken, await this.getImagesTakenFolder());
  }

  public async downloadImagesUploaded() {
    const imagesUploaded = await this.getImagesUploaded();
    this.downloadImages(imagesUploaded, await this.getImagesUploadedFolder());
  }

  public async moveImagesUploadedToRecycleBin() {
    const imagesUploaded = await this.getImagesUploaded();
    await this.moveImages(imagesUploaded, await this.getDeletedImagesDirectory());
  }

  public async moveImageToRecycleBin(image: Entry) {
    await this.moveImage(image, await this.getDeletedImagesDirectory());
  }

  public async downloadImagesDeleted() {
    const imagesDeleted = await this.getDeletedImages();
    this.downloadImages(imagesDeleted, await this.getImagesDeletedFolder());
  }

  public async deleteImagesDeleted() {
    const imagesDeleted = await this.getDeletedImages();
    await this.deleteImages(imagesDeleted);
  }

  private async getImagesTakenFolder() {
    return await this.getFolderName("OMSImagesTaken");
  }

  private async getImagesUploadedFolder() {
    return await this.getFolderName("OMSImagesUploaded");
  }

  private async getImagesDeletedFolder() {
    return await this.getFolderName("OMSImagesDeleted");
  }

  private async getFolderName(folder: string) {
    let environment = await this.environmentService.getEnvironment();
    if (environment && environment != "live" && environment != "") {
      return folder + environment + "new";
    }
    return folder + "new";
  }

  public get dataDirectory() { return this.file.dataDirectory };

  private async getDirectoryFiles(folder: string): Promise<Entry[]> {
    let directory = await this.getDirectory(folder);
    let images = await this.file.listDir(this.dataDirectory, folder);
    return images || [];
  }

  private async getDirectory(folder: string): Promise<DirectoryEntry> {
    try {
      await this.file.createDir(this.dataDirectory, folder, false);
    } catch (err) {
      console.log(err);
    }
    const path = this.dataDirectory + folder;
    return await this.file.resolveDirectoryUrl(path);
    // let fileSystem = await this.getFileSystem();
    // if (fileSystem) {
    //   return new Promise<DirectoryEntry>((resolve, reject) => {
    //     fileSystem.root.getDirectory(folder, {
    //       create: true
    //     }, (dataDir) => {
    //       resolve(dataDir);
    //     }, (err) => {
    //       this.errorHandlerService.logError(err);
    //       reject();
    //     });
    //   });
    // } else {
    //   return null;
    // }
  }

  // private async getFileSystem(): Promise<any> {
  //   return new Promise<DirectoryEntry>((resolve, reject) => {
  //     if (window["requestFileSystem"] && window["LocalFileSystem"]) {
  //       window["requestFileSystem"](window["LocalFileSystem"].PERSISTENT, 0, (fileSystem) => {
  //         resolve(fileSystem);
  //       }, (err) => {
  //         this.errorHandlerService.logError(err);
  //         reject();
  //       });
  //     } else {
  //       resolve(null);
  //     }
  //   });
  // }

  private async movePhotoToPermanentStorage(tempImage: any, fileName: string): Promise<CameraPhotoModel> {
    const imageDirectory = await this.getImagesTakenDirectory();
    let imageEntry = await this.file.resolveLocalFilesystemUrl(tempImage);
    return new Promise<CameraPhotoModel>((resolve, reject) => {
      imageEntry.moveTo(imageDirectory, fileName, (entry) => {
        resolve({
          FileName: entry.name,
          PhonePath: entry.nativeURL,
          Entry: entry
        });
      }, (err) => {
        this.errorHandlerService.logError(err);
        reject();
      });
    });
  }

  private async copyFileToPermanentStorage(filePath: any, fileName: string): Promise<CameraPhotoModel> {
    const imageDirectory = await this.getImagesTakenDirectory();
    let imageEntry = await this.file.resolveLocalFilesystemUrl(filePath);
    return new Promise<CameraPhotoModel>((resolve, reject) => {
      imageEntry.copyTo(imageDirectory, fileName, (entry) => {
        resolve({
          FileName: entry.name,
          PhonePath: entry.nativeURL,
          Entry: entry
        });
      }, (err) => {
        this.errorHandlerService.logError(err);
        reject();
      });
    });
  }

  public async downloadImage(image: Entry): Promise<void> {
    this.downloadImages([image], await this.getFolderName("OMSDownload"));
  }

  private async downloadImages(images: Entry[], destinationFolderName: string): Promise<void> {
    let downloadsDirectoryEntry = null;
    if (this.iOS()) {
      if (!confirm(`About to copy ${images.length} files(s) to your image gallery. Are you sure?`)) {
        return;
      }
    } else {
      let destination = await this.getAndroidDownloadsPath(destinationFolderName);
      downloadsDirectoryEntry = await this.file.resolveDirectoryUrl(destination);
      if (!confirm(`About to copy ${images.length} files(s) to Download/${destinationFolderName}. Are you sure?`)) {
        return;
      }
    }

    images = this.sortImages(images, "asc");

    let countCopied = 0;
    let countFailed = 0;
    await this.loaderHelperService.on();
    await this.waitFor(100);
    try {
      for (let image of images) {
        try {
          if (this.iOS()) {
            if (await this.copyToiOSImageGallery(image)) {
              countCopied++;
            } else {
              countFailed++;
            }
          } else {
            await image.copyTo(downloadsDirectoryEntry)
            countCopied++;
          }
        } catch (err) {
          countFailed++;
          this.errorHandlerService.logError(err);
        } finally {
          this.loaderHelperService.setContent(`Copying ${countCopied}/${images.length}. Failed to copy: ${countFailed}`)
        }
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      alert("Error occurred while copying images. Please consider sending logs.");
    } finally {
      this.loaderHelperService.off();
      if (countCopied === 0 && images.length > 0) {
        alert("Make sure you allow OMS to access photos first. You can configure access rights in settings.");
      } else {
        if (this.iOS()) {
          alert(`Successfully copied ${countCopied}/${images.length}. Check your image gallery for images.`);
        } else {
          alert(`Successfully copied ${countCopied}/${images.length}. Check your Download/${destinationFolderName} folder for images.`);
        }
      }
    }
  }

  private async deleteImages(images: Entry[]): Promise<void> {
    let countDeleted = 0;
    let countFailed = 0;
    await this.loaderHelperService.on();
    await this.waitFor(100);
    try {
      for (let image of images) {
        try {
          if (await this.deleteImage(image)) {
            countDeleted++;
          } else {
            countFailed++;
          }
        } catch (err) {
          countFailed++;
          this.errorHandlerService.logError(err);
        } finally {
          this.loaderHelperService.setContent(`Deleting ${countDeleted}/${images.length}. Failed to delete: ${countFailed}`)
        }
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      alert("Error occurred while deleting images. Please consider sending logs.");
    } finally {
      this.loaderHelperService.off();
      if (countDeleted === 0 && images.length > 0) {
        alert("Make sure you allow OMS to access photos first. You can configure access rights in settings.");
      } else {
        alert(`Successfully deleted ${countDeleted}/${images.length}.`);
      }
    }
  }

  private async moveImages(images: Entry[], directory: DirectoryEntry): Promise<void> {
    let countMoved = 0;
    let countFailed = 0;
    await this.loaderHelperService.on();
    await this.waitFor(100);
    try {
      for (let image of images) {
        try {
          if (await this.moveImage(image, directory)) {
            countMoved++;
          } else {
            countFailed++;
          }
        } catch (err) {
          countFailed++;
          this.errorHandlerService.logError(err);
        } finally {
          this.loaderHelperService.setContent(`Moving ${countMoved}/${images.length}. Failed to move: ${countFailed}`)
        }
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      alert("Error occurred while moving images. Please consider sending logs.");
    } finally {
      this.loaderHelperService.off();
      if (countMoved === 0 && images.length > 0) {
        alert("Make sure you allow OMS to access photos first. You can configure access rights in settings.");
      } else {
        alert(`Successfully moved ${countMoved}/${images.length}.`);
      }
    }
  }

  private async deleteImage(image: Entry) {
    return new Promise<boolean>((resolve, reject) => {
      image.remove(() => {
        resolve(true);
      }, (err) => {
        this.errorHandlerService.logError(err);
        reject();
      })
    });
  }

  private async moveImage(image: Entry, directory: DirectoryEntry) {
    return new Promise<boolean>((resolve, reject) => {
      image.moveTo(directory, image.name, () => {
        resolve(true);
      }, (err) => {
        this.errorHandlerService.logError(err);
        reject();
      })
    });
  }

  public sortImages(images: Entry[], sortBy: "asc" | "desc"): Entry[] {
    return _.orderBy<Entry>(images, (item) => {
      return this.getImageDate(item);
    }, sortBy === "desc" ? "desc" : "asc");
  }

  public getFileNameProperty(fileName: string, propertyName: string): string {
    try {
      let items = (fileName || "").split("__");
      for (let i = 0; i < items.length; i++) {
        if (items[i].indexOf(`${propertyName}_`) === 0) {
          return items[i].replace(`${propertyName}_`, "");
        }
      }
    }
    catch {
      return "";
    }
  }

  public getImageDate(fileName): number {
    //"c_84250__la_44--7624063__lo_17--2066268__dc_1590074512066__u_8695__v_d0a3477382f0913e29da1da9e81f4444.jpg"
    let date: number = 0;
    try {
      let value = this.getFileNameProperty(fileName, "dc")
      if (value) {
        date = parseInt(value);
      }
    }
    catch {
    }
    return date;
  }

  private waitFor(timeout): Promise<void> {
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        resolve();
      }, timeout);
    });
  }

  private async copyToiOSImageGallery(image: Entry): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      cordova.plugins["imagesaver"].saveImageToGallery(image.nativeURL, () => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    });
  }

  private async getAndroidDownloadsPath(destinationFolderName: string): Promise<string> {
    try {
      await this.file.createDir(`${this.file.externalRootDirectory}`, "Download", false);
    } catch (err) {
    }
    try {
      await this.file.createDir(`${this.file.externalRootDirectory}/Download`, destinationFolderName, false);
    } catch (err) {
    }
    return `${this.file.externalRootDirectory}/Download/${destinationFolderName}`;
  }

  private iOS(): boolean {
    return (window["device"].platform || "").toLowerCase() == "ios";
  }
}


export class GalleryPhotos {
  webPath: string;
  name: string;
  format: string;
  file: globalThis.File
}