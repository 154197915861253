<oms-header [title]="'Time and Mileage'" *ngIf="!isWeb" [subtitle]="subtitle" [subtitle2]="subtitle2">
    <ion-back-button defaultHref="/facilities"></ion-back-button>
</oms-header>
<ion-header *ngIf="isWeb">
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Time and Mileage
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content *ngIf="!loading">
    <ion-list *ngIf="timeList.length > 0">
        <ng-container *ngFor="let item of timeList">
            <div *ngIf="isFirstOfThisDate(item)" class="time-tracking-sticky-date">
                {{item.DateText}}, Total {{item.TotalHoursOnThisDate}}<span class="time-tracking-unit">hrs</span>
                <ng-container *ngIf="item.TotalMilesOnThisDate">
                    and
                    {{item.TotalMilesOnThisDate}}<span class="time-tracking-unit">miles</span>
                </ng-container>
                <div class="time-tracking-sticky-date-details">
                    <ion-icon name="time-outline"></ion-icon>{{item.TotalWorkingHoursOnThisDate}}<span
                        class="time-tracking-unit">hrs</span> |
                    <ng-container *ngIf="item.TotalDrivingMilesOnThisDate">
                        <ion-icon name="car-outline"></ion-icon>{{item.TotalDrivingMilesOnThisDate}}<span
                            class="time-tracking-unit">miles</span> |
                    </ng-container>

                    <ng-container *ngIf="item.TotalFlyingMilesOnThisDate">
                        <ion-icon name="airplane-outline"></ion-icon>{{item.TotalFlyingMilesOnThisDate}}<span
                            class="time-tracking-unit">miles</span> |
                    </ng-container>

                    <ng-container *ngIf="item.TotalOtherHoursOnThisDate">
                        <ion-icon name="reader-outline"></ion-icon>{{item.TotalOtherHoursOnThisDate}}<span
                            class="time-tracking-unit">hrs</span>
                    </ng-container>
                    <ng-container *ngIf="item.TotalOtherHoursOnThisDate">
                        &nbsp;{{item.TotalOtherHoursOnThisDate}}<span class="time-tracking-unit">miles</span>
                    </ng-container>
                </div>
            </div>
            <ion-item (click)="edit(item)">
                <ion-icon name="time-outline" slot="end" *ngIf="item.Type == 1"></ion-icon>
                <ion-icon name="car-outline" slot="end" *ngIf="item.Type == 2"></ion-icon>
                <ion-icon name="airplane-outline" slot="end" *ngIf="item.Type == 3"></ion-icon>
                <ion-icon name="reader-outline" slot="end" *ngIf="item.Type == 4"></ion-icon>
                <ion-label>
                    <div>
                        <span *ngIf="item.Time" style="margin-right: 10px">{{item.Time}} hrs</span>
                        <span *ngIf="item.Mileage" style="margin-right: 10px">{{item.Mileage}} miles</span>
                        <span *ngIf="item.SubGroupName">{{item.SubGroupName}}</span>
                    </div>
                    <div *ngIf="item.ChecklistId" class="job-status-notes">
                        Job#: {{item.ChecklistId}}
                    </div>
                    <div *ngIf="item.Customer" class="job-status-notes">
                        Customer: {{item.Customer}}
                    </div>
                    <div *ngIf="item.Location" class="job-status-notes">
                        Location: {{item.Location}}
                    </div>
                    <div *ngIf="item.Project" class="job-status-notes">
                        Project: {{item.Project}}
                    </div>
                    <div *ngIf="item.Description" class="job-status-notes">
                        <i class="fal fa-comment-lines"></i>
                        {{item.Description}}
                    </div>
                    <ion-note>
                        <i>#{{item.Id}} by {{item.FullName}} on {{item.DateText}}
                            <span *ngIf="item.InField">
                                in the field
                                <ion-icon name="build-outline"></ion-icon>
                            </span>
                            <span *ngIf="!item.InField">
                                in office
                                <ion-icon name="business-outline"></ion-icon>
                            </span>
                        </i>
                    </ion-note>
                </ion-label>
            </ion-item>
        </ng-container>
    </ion-list>
    <ion-card *ngIf="timeList.length === 0" class="ion-text-center">
        <ion-card-header>
            <ion-card-title>No time tracking entries added yet</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <div class="ion-padding ion-text-start">
                <div>
                    Once you track your time on the job it will be available here
                </div>
                <div>
                    You can track time, mileage and more...
                </div>
            </div>
        </ion-card-content>
    </ion-card>
    <div class="time-tracking-total-summary" *ngIf="totalHours > 0 || totalMiles > 0" (click)="toggleSummary()">
        <div [class.time-tracking-total-summary-total]="summary">
            <ion-icon name="chevron-up-outline" *ngIf="!summary"></ion-icon>
            <ion-icon name="chevron-down-outline" *ngIf="summary"></ion-icon>
            Total: <b>{{totalHours}}</b><span class="time-tracking-unit">hrs</span>
            <ng-container *ngIf="totalMiles"> and <b>{{totalMiles}}</b><span
                    class="time-tracking-unit">miles</span>
            </ng-container>
        </div>
        <table *ngIf="summary">
            <tbody>
                <tr>
                    <td>
                        <ion-icon name="time-outline"></ion-icon> Working&nbsp;
                    </td>
                    <td>
                        {{totalWorkingHours}}<span class="time-tracking-unit">hrs</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ion-icon name="car-outline"></ion-icon> Driving&nbsp;
                    </td>
                    <td>
                        {{totalDrivingMiles}}<span class="time-tracking-unit">miles</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ion-icon name="airplane-outline"></ion-icon> Flying&nbsp;
                    </td>
                    <td>
                        {{totalFlyingMiles}}<span class="time-tracking-unit">miles</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ion-icon name="reader-outline"></ion-icon> Other&nbsp;
                    </td>
                    <td>
                        {{totalOtherHours}}<span class="time-tracking-unit">hrs</span> {{totalFlyingMiles}}<span
                            class="time-tracking-unit">miles</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="!preview">
        <ion-fab-button (click)="add()">
            <ion-icon name="add"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</ion-content>
<ion-content *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>
<ion-footer *ngIf="isWeb">
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="dismissModal()" color="light" class="color-white">
                Close
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>