import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../my-work.service";
import { JobDataModel } from "src/app/checklist/checklist.models";
import { AssetsService } from "src/app/assets/assets.service";
import { Facility } from "src/app/data/entities/Facility";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ChecklistService } from "src/app/checklist/checklist.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { WorkDataComponent } from "./work-data/work-data.component";
import { Subscription } from "rxjs";
import { FacilityService } from "src/app/assets/facility.service";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";
import { SyncService } from "src/app/sync/sync.service";

@Component({
  selector: "work-item-details",
  templateUrl: "work-item-details.component.html"
})
export class WorkItemDetailsComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  @Input()
  jobModel: JobDataModel;
  facility: Facility;
  fromMap: boolean;
  galleryCount: number;
  correctionsNeeded: boolean;
  preview: boolean;


  private timeoutId;
  hasUpdates: boolean = false;
  private jobHash: string;
  private subscription: Subscription;
  private destroyed = false;
  private start: boolean = false;
  @ViewChild(WorkDataComponent, { static: false }) workDataComponent: WorkDataComponent

  constructor(
    private myWorkService: MyWorkService,
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    private previewService: PreviewService,
    private cameraHelperService: CameraHelperService,
    private browserHelperService: BrowserHelperService,
    private checklistService: ChecklistService,
    private pubSubService: PubSubService,
    private facilityService: FacilityService,
    private errorHandlerService: ErrorHandlerService,
    private syncService: SyncService
  ) { }

  async ngOnInit() {
    this.hasUpdates = false;
    this.destroyed = false;
  }

  async ngOnDestroy() {
  }

  ionViewWillLeave() {
    this.destroyed = true;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.subscription.unsubscribe();
  }

  async ionViewWillEnter() {
    this.destroyed = false;
    this.loading = true;
    this.subscription = this.pubSubService.$sub(EventConstants.JOB_REFRESHED, async (data: JobDataModel) => {
      this.onJobRefreshed();
    });
    let jobId = this.route.snapshot.params["id"];
    this.fromMap = this.route.snapshot.params["map"] == "true";
    this.start = this.route.snapshot.params["start"] == "true";
    this.jobModel = jobId ? await this.myWorkService.getJob(jobId) : this.jobModel;
    this.jobHash = await this.myWorkService.getJobHash(this.jobModel.ChecklistId);
    this.facility = await this.assetsService.getFacilityWithAssets(this.jobModel.SiteKey);
    this.loading = false;
    this.setImageCount();
    this.setCorrectionsNeededFlag();
    this.pubSubService.$pub(EventConstants.UPDATE_CHECKLIST_BUTTON_SUMMARY);
    if (this.jobModel.CheckedIn) {
      this.previewService.setPreviewOff();
    } else {
      this.previewService.setPreviewOn(this.jobModel.ChecklistId);
    }
  }

  ionViewDidEnter() {
    if (this.workDataComponent) {
      this.workDataComponent.ngOnInit();
    }
    this.checkForUpdates();
    if (!this.jobModel.CheckedIn && this.start) {
      this.workDataComponent.checkIn();
      setTimeout(() => {
        this.workDataComponent.checklistButtonComponent.goToChecklist();
      }, 100);
    }
  }

  setImageCount() {
    this.galleryCount = 0;
    if (!this.browserHelperService.isOffline()) {
      this.galleryCount = this.jobModel.ImageCount;
      return;
    }

    this.cameraHelperService.getImagesTaken().then(imagesTaken => {
      let takenCount = 0;
      for (let image of imagesTaken) {
        let checklistId = parseInt(this.cameraHelperService.getFileNameProperty(image.name, "c"));
        if (this.jobModel.ChecklistId == checklistId) {
          takenCount++;
        }
      }
      this.galleryCount += takenCount;
      this.cameraHelperService.getImagesUploaded().then(imagesTaken => {
        let uploadedCount = 0;
        for (let image of imagesTaken) {
          let checklistId = parseInt(this.cameraHelperService.getFileNameProperty(image.name, "c"));
          if (this.jobModel.ChecklistId == checklistId) {
            uploadedCount++;
          }
        }
        this.galleryCount += uploadedCount;
      });
    });
  }

  private async setCorrectionsNeededFlag() {
    let checklist = await this.checklistService.getChecklistById(this.jobModel.ChecklistId);
    this.correctionsNeeded = false;
    for (let section of checklist.SectionsModel) {
      for (let question of section.Questions) {
        if (question.VendorQuestionDataTypeFK == -2 && question.AuditData == "4") {
          this.correctionsNeeded = true;
        }
      }
    }
  }

  private async checkForUpdates() {
    clearTimeout(this.timeoutId);
    let dataRefreshed = false;
    try {

      let hash = await this.myWorkService.getJobHashFromTheServer(this.jobModel.ChecklistId);
      let hasUpdates = this.jobHash != hash;
      if (hasUpdates && !this.destroyed) {
        await this.workDataComponent.refreshJob();
      }
      dataRefreshed = true;
    } catch (err) { this.errorHandlerService.logError(err); }
    // try {
    //   if (!dataRefreshed && !this.destroyed) {
    //     let hash = await this.myWorkService.getJobHashFromTheServer(this.jobModel.ChecklistId);
    //     this.hasUpdates = this.jobHash != hash;
    //   }
    // } catch (err) {
    //   this.hasUpdates = false;
    //   this.errorHandlerService.logError(err);
    // }
    finally {
      if (!this.destroyed) {
        this.timeoutId = setTimeout(() => {
          this.checkForUpdates();
        }, 10 * 1000);
      }
    }
  }

  private async onJobRefreshed() {
    this.hasUpdates = false;
    await this.ionViewWillEnter();
  }
}