<ng-container *ngIf="item.ListId != null">
  <ion-item class="custom-input-ion-item" #elementRef>
    <ion-note position="fixed" slot="start" (click)="focusElement()">
      {{ item.FieldName }}
    </ion-note>
    <ion-select tabindex="2" #selectList1 [(ngModel)]="item.DataListItemId" okText="Okay" cancelText="Dismiss"
      placeholder="" (ionChange)="changeSelect($event, memberLookup.CustomLists[item.ListId])" [disabled]="preview">
      <ion-select-option [value]="item.Value" *ngFor="let item of memberLookup.CustomLists[item.ListId]">{{ item.Text }}
      </ion-select-option>
    </ion-select>
    <span tabindex="1" (click)="takePhoto(item, $event)" slot="end" class="battery-item-photo" style="z-index: 5;"
      *ngIf="!preview">
      <ion-icon name="camera" [color]="item.ImageCount > 0 ? 'primary' : ''">
      </ion-icon>
      <span class="count">{{item.ImageCount || 0}}</span>
    </span>
  </ion-item>
</ng-container>
<ng-container *ngIf="item.ListId == null">
  <ion-item [ngSwitch]="item.FieldDataType" class="custom-input-ion-item">
    <ion-note position="fixed" slot="start" (click)="focusElement()">
      {{ item.FieldName }}
    </ion-note>
    <ion-input tabindex="2" #ionInput *ngSwitchCase="'numeric'" [(ngModel)]="item.DataFloat" decimal-input
      inputmode="decimal" positive-numbers-only min="0" custom-max-length="10" placeholder=""
      (change)="onAnswerChange()" [disabled]="preview"></ion-input>
    <ion-input tabindex="2" #ionInput *ngSwitchCase="'battery-count'" [(ngModel)]="item.DataFloat" type="number"
      positive-numbers-only min="0" custom-max-length="2" placeholder="" (change)="onAnswerChange()"
      [disabled]="preview"></ion-input>
    <ion-input tabindex="2" #ionInput *ngSwitchCase="'replacement-period'" [(ngModel)]="item.DataFloat" type="number"
      positive-numbers-only min="0" custom-max-length="2" placeholder="" (change)="onAnswerChange()"
      [disabled]="preview"></ion-input>
    <ion-input tabindex="2" #ionInput *ngSwitchCase="'quantity'" [(ngModel)]="item.DataFloat" type="number"
      positive-numbers-only min="0" custom-max-length="2" placeholder="" (change)="onAnswerChange()"
      [disabled]="preview"></ion-input>
    <ion-input tabindex="2" #ionInput *ngSwitchCase="'battery-start-position'" [(ngModel)]="item.DataFloat"
      type="number" positive-numbers-only min="0" custom-max-length="5" placeholder=""
      (ionChange)="onBatteryStartPositionChange()" [disabled]="preview">
    </ion-input>
    <oms-group-select tabindex="2" #omsGroupSelect *ngSwitchCase="'battery-model'" [(model)]="item.DataFloat"
      (modelChange)="onAnswerChange()" [title]="'Select Battery Model'" [(text)]="item.AnswerText"
      [options]="memberLookup.BatteryTypes" [disabled]="preview">
    </oms-group-select>
    <ion-input *ngSwitchCase="'battery-model'" [hidden]="true"></ion-input>
    <oms-group-select #omsGroupSelect *ngSwitchCase="'ups-model'" [(model)]="item.DataFloat"
      (modelChange)="onAnswerChange()" [title]="'Select UPS Model'" [(text)]="item.AnswerText"
      [options]="memberLookup.UPSModels" [disabled]="preview">
    </oms-group-select>
    <ion-input *ngSwitchCase="'ups-model'" [hidden]="true"></ion-input>
    <oms-group-select tabindex="2" #omsGroupSelect *ngSwitchCase="'cabinet-model'" [(model)]="item.DataFloat"
      (modelChange)="onAnswerChange()" [title]="'Select Equipment Model'" [(text)]="item.AnswerText"
      [options]="memberLookup.CabinetModels" [disabled]="preview">
    </oms-group-select>
    <ion-input *ngSwitchCase="'cabinet-model'" [hidden]="true"></ion-input>

    <ion-input *ngSwitchCase="'date'" value="{{ item.DataDate | date: 'MM/dd/yyyy' }}" [id]="id" [disabled]="preview"
      [placeholder]="preview ? '' : 'Tap to select a date'">
    </ion-input>
    <ion-modal #ionDateModal [attr.trigger]="id" size="auto" *ngSwitchCase="'date'" class="date-picker-modal">
      <ng-template>
        <ion-content>
          <ion-datetime #ionDateTime presentation="date" [disabled]="preview" [(ngModel)]="item.DataDate"
            (ionChange)="onAnswerChange()" locale="en-US">
            <ion-buttons slot="buttons">
              <ion-button color="danger" (click)="ionDateTime.cancel(true);">Cancel</ion-button>
              <ion-button color="primary" (click)="onDateSelected(ionDateTime)">Done</ion-button>
            </ion-buttons>
          </ion-datetime>
        </ion-content>
      </ng-template>
    </ion-modal>

    <ion-input *ngSwitchCase="'dateinstall'" value="{{ item.DataDate | date: 'MM/dd/yyyy' }}" [id]="id"
      [disabled]="preview" [placeholder]="preview ? '' : 'Tap to select a date'">
    </ion-input>
    <ion-modal #ionDateModal [attr.trigger]="id" size="auto" *ngSwitchCase="'dateinstall'" class="date-picker-modal">
      <ng-template>
        <ion-content>
          <ion-datetime #ionDateTime presentation="date" [disabled]="preview" [(ngModel)]="item.DataDate"
            (ionChange)="onInstallDateAnswerChange()" locale="en-US">
            <ion-buttons slot="buttons">
              <ion-button color="danger" (click)="ionDateTime.cancel(true);">Cancel</ion-button>
              <ion-button color="primary" (click)="onDateSelected(ionDateTime)">Done</ion-button>
            </ion-buttons>
          </ion-datetime>
        </ion-content>
      </ng-template>
    </ion-modal>

    <ion-input *ngSwitchCase="'datecode'" value="{{ item.DataDate | date: 'MM/yyyy' }}" [id]="id" [disabled]="preview"
      [placeholder]="preview ? '' : 'Tap to select a date code'">
    </ion-input>
    <ion-modal #ionDateModal [attr.trigger]="id" size="auto" *ngSwitchCase="'datecode'" class="date-picker-modal">
      <ng-template>
        <ion-content>
          <ion-datetime #ionDateTime presentation="month-year" [disabled]="preview" [(ngModel)]="item.DataDate"
            (ionChange)="onDateCodeAnswerChange()" locale="en-US">
            <ion-buttons slot="buttons">
              <ion-button color="danger" (click)="ionDateTime.cancel(true);">Cancel</ion-button>
              <ion-button color="primary" (click)="onDateSelected(ionDateTime)">Done</ion-button>
            </ion-buttons>
          </ion-datetime>
        </ion-content>
      </ng-template>
    </ion-modal>

    <ion-textarea tabindex="2" #ionInput *ngSwitchCase="'scan'" [(ngModel)]="item.Data" maxlength="250" placeholder=""
      (change)="onAnswerChange()" [disabled]="preview"></ion-textarea>
    <ion-input tabindex="2" #ionInput *ngSwitchDefault [(ngModel)]="item.Data" maxlength="250" placeholder=""
      (change)="onAnswerChange()" [disabled]="preview"></ion-input>
    <span tabindex="1" (click)="clearDate()" slot="end" class="battery-item-photo clear-date" style="z-index: 5;"
      *ngIf="item.DataDate && !preview">
      <ion-icon name="close-outline"></ion-icon>
    </span>
    <div slot="end">
      <span tabindex="1" (click)="takePhoto(item, $event)" class="battery-item-photo"
        style="z-index: 5; display: block;" *ngIf="!preview">
        <ion-icon name="camera" [color]="item.ImageCount > 0 ? 'primary' : ''">
        </ion-icon>
        <span class="count">{{item.ImageCount || 0}}</span>
      </span>
      <span tabindex="1" (click)="scan(item, $event)" class="battery-item-photo" style="z-index: 5;"
        *ngIf="item.FieldDataType == 'scan' && !preview">
        <ion-icon name="barcode-outline" [color]="item.Data ? 'primary' : ''">
        </ion-icon>
      </span>
    </div>
  </ion-item>
  <ion-item *ngIf="(item.FieldDataType == 'battery-model' || item.FieldDataType == 'ups-model') && item.DataFloat == -1"
    class="custom-input-ion-item">
    <ion-note position="fixed" slot="start" (click)="focusElement()">
      No data found for {{ item.FieldName }}
    </ion-note>
    <ion-textarea #ionInput [(ngModel)]="item.DataNote" maxlength="250"
      placeholder="Provide info for 'Not On List' item and MPI will review it and add it to the list"
      (change)="onAnswerChange()" [disabled]="preview">
    </ion-textarea>
  </ion-item>
</ng-container>