<ion-content class="vertical-align-content ion-padding">
  <div class="login-logo">
    <ion-img [src]="'/assets/images/logo.png'"></ion-img>
  </div>
  <ion-grid style="height: 80%; margin-top: 20%" *ngIf="mode == 'password'">
    <ion-row justify-content-center align-items-center style="height: 100%; align-content: center;">
      <ion-col size="12">
        <ion-item>
          <ion-note *ngIf="!usernameDataValid" color="danger" position="fixed" slot="end">
            Required
          </ion-note>
          <ion-input next-on-enter="password" placeholder="Enter your username or email" [(ngModel)]="model.Username" type="email">
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-note *ngIf="!passwordDataValid" color="danger" position="fixed" slot="end">
            Required
          </ion-note>
          <ion-input id="password" placeholder="Enter your password" [(ngModel)]="model.Password" type="password">
          </ion-input>
        </ion-item>
        <br />
        <div class="font-small">
          <div class="ion-margin-bottom" style="display: flex;flex-direction: row;">I accept&nbsp;<a
              (click)="readTerms()" class="ion-margin-end">OMS EULA</a>
            <ion-checkbox [(ngModel)]="model.AcceptTerms" style="width: 1rem; height: 1rem"></ion-checkbox>
          </div>
        </div>
        <ion-button expand="full" class="ion-margin-top" (click)="loginWithPassword()" [disabled]="loading">
          <span *ngIf="loading">
            <ion-spinner></ion-spinner>
          </span>
          <span *ngIf="!loading">Login</span>
        </ion-button>
        <div class="ion-text-center ion-margin-bottom ion-margin-top">Or if you don't have an account you can always <a
            (click)="newAccount()">send request for new account</a>.</div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid style="height: 80%; margin-top: 20%" *ngIf="mode == 'nopassword'">
    <ion-row justify-content-center align-items-center style="height: 100%; align-content: center;">
      <ion-col size="12">
        <ion-item>
          <ion-note *ngIf="!emailDataValid" color="danger" position="fixed" slot="end">
            Required
          </ion-note>
          <ion-input placeholder="Enter your email" [(ngModel)]="model.Email" type="email">
          </ion-input>
        </ion-item>
        <br />
        <ion-button expand="full" class="ion-margin-top" (click)="loginWithoutPassword()" [disabled]="loading">
          <span *ngIf="loading">
            <ion-spinner></ion-spinner>
          </span>
          <span *ngIf="!loading">Login</span>
        </ion-button>
        <ion-note class="ion-text-center" style="display: block;">
          You will get an email with confirmation code.
        </ion-note>
        <div class="ion-text-center ion-margin-bottom ion-margin-top">Or if you don't have an account you can always <a
            (click)="newAccount()">send request for new account</a>.</div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-tab-bar slot="bottom" *ngIf="!loading" class="checklist-questions-tabs">
  <ion-tab-button (click)="switchMode('password')" [class.tab-selected]="mode == 'password'">
    <i class="far fa-lock-alt"></i>
    <ion-label>Login with password</ion-label>
  </ion-tab-button>
  <ion-tab-button (click)="switchMode('nopassword')" [class.tab-selected]="mode == 'nopassword'">
    <i class="far fa-door-open"></i>
    <ion-label>Login without password</ion-label>
  </ion-tab-button>
</ion-tab-bar>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="showEnvironmentSwitcher" style="margin-bottom: 4rem">
  <ion-fab-button color="default">
    <ion-icon name="repeat"></ion-icon>
  </ion-fab-button>
  <ion-fab-list side="top">
    <ion-fab-button *ngIf="showHiddenEnvironments && environment != 'local'" (click)="switchEnv('local')">
      <ion-label style="font-size: 10px">Local</ion-label>
    </ion-fab-button>
    <ion-fab-button *ngIf="showHiddenEnvironments && environment != 'uat'" (click)="switchEnv('dev')">
      <ion-label>Dev</ion-label>
    </ion-fab-button>
    <ion-fab-button *ngIf="showHiddenEnvironments && environment != 'dev'" (click)="switchEnv('uat')">
      <ion-label>Uat</ion-label>
    </ion-fab-button>
    <ion-fab-button *ngIf="environment != 'training'" (click)="switchEnv('training')">
      <ion-label style="font-size: 10px">Training</ion-label>
    </ion-fab-button>
    <ion-fab-button *ngIf="environment != ''" (click)="switchEnv('')">
      <ion-label>Live</ion-label>
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-fab vertical="bottom" horizontal="start" slot="fixed" (click)="goToHelp()" style="margin-bottom: 4rem">
  <ion-fab-button color="default">
    <ion-icon name="help-circle-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>