import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'localdate' })
export class LocalDatePipe implements PipeTransform {

  transform(value: any): string {
    if (value == "Invalid date") {
      return "";
    }
    if (!value) { return value; }
    let result = moment.utc(value).local().format("MM/DD/YYYY");
    if (result == "Invalid date") {
      return moment(value).format("MM/DD/YYYY");
    }
    return result;
  }
}