<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            <ng-container *ngIf="model && model.Id > 0">
                Update Time/Mileage #{{model.Id}}
            </ng-container>
            <ng-container *ngIf="!model || !model.Id">
                Add Time/Mileage
            </ng-container>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" *ngIf="!loading">
    <h3 *ngIf="groups.length === 0">No Activities defines. Please contact your system administrator.</h3>
    <h3 *ngIf="model.SubGroupName">{{model.SubGroupName}}</h3>
    <h3 *ngIf="!model.SubGroupName && model.SubGroupId && groupSelectList && groupSelectList.text"
        class="ion-text-center">
        {{groupSelectList.text}}</h3>
    <oms-group-select [(model)]="model.SubGroupId" #groupSelectList [disableSearch]="true" [title]="'Activity'"
        [options]="groups" style="visibility: hidden" (onClose)="onGroupSelectClose()">
    </oms-group-select>
    <ng-container *ngIf="model.SubGroupId">
        <ion-segment [(ngModel)]="model.Type" (ionChange)="onSubGroupChange($event)" mode="ios">
            <ion-segment-button value="1" *ngIf="!model.Type || model.Type == 1">
                <ion-label>Time</ion-label>
                <ion-icon name="time-outline"></ion-icon>
            </ion-segment-button>
            <ion-segment-button value="2" *ngIf="!model.Type || model.Type == 2">
                <ion-label>Driving</ion-label>
                <ion-icon name="car-outline"></ion-icon>
            </ion-segment-button>
            <ion-segment-button value="3" *ngIf="!model.Type || model.Type == 3">
                <ion-label>Flying</ion-label>
                <ion-icon name="airplane-outline"></ion-icon>
            </ion-segment-button>
        </ion-segment>
        <br />
        <ion-segment mode="ios" [(ngModel)]="singleDay" *ngIf="model.Type && !model.Id">
            <ion-segment-button [value]="true" (click)="switchToSingleDay()">
                <ion-label>One Day</ion-label>
            </ion-segment-button>
            <ion-segment-button [value]="false">
                <ion-label>Multiple Days</ion-label>
            </ion-segment-button>
        </ion-segment>
        <br />
        <ng-container *ngIf="model.Type">
            <ion-item class="ion-margin-bottom" lines="none" *ngFor="let day of model.Days; let index = index">
                <div class="time-tracking-input-wrapper">
                    <input [(ngModel)]="day.Time" type="number" #hoursInput positive-numbers-only min="0"
                        custom-max-length="5" placeholder="0.0" />
                    <label>hrs</label>
                </div>
                <div class="time-tracking-input-wrapper" *ngIf="model.Type == 2">
                    <input [(ngModel)]="day.Mileage" type="number" positive-numbers-only min="0" custom-max-length="5"
                        placeholder="0.0" />
                    <label>miles</label>
                </div>
                <div class="time-tracking-input-wrapper date">
                    <label>
                        <ion-icon name="calendar-outline" [slot]="end"></ion-icon>
                    </label>
                    <input readonly="readonly" value="{{ day.Date | date:'MM/dd/yyyy' }}" [id]="day.PickerId" />
                </div>
                <ion-modal [attr.trigger]="day.PickerId" size="auto" class="date-picker-modal">
                    <ng-template>
                        <ion-content>
                            <ion-datetime #ionDateTime presentation="date" [(ngModel)]="day.Date" format="YYYY-MM-DD"
                                locale="en-US" showDefaultButtons="true">
                            </ion-datetime>
                        </ion-content>
                    </ng-template>
                </ion-modal>
                <ion-icon (click)="removeDay(index)" name="trash-outline" slot="end"
                    style="margin-left: 0; margin-right: -1rem" *ngIf="index > 0"></ion-icon>
            </ion-item>
            <ion-item *ngIf="singleDay == 'false'" lines="none">
                <ion-button size="small" (click)="addDay()">Add another day</ion-button>
            </ion-item>

            <ion-segment mode="ios" [(ngModel)]="model.InField" *ngIf="model.Type == '1' || model.Type == '4'">
                <ion-segment-button [value]="true">
                    <ion-label>In Field</ion-label>
                </ion-segment-button>
                <ion-segment-button [value]="false">
                    <ion-label>In Office</ion-label>
                </ion-segment-button>
            </ion-segment>

            <new-customer-project-location [(model)]="model.CustomerLocationProject" #customerLocationProject *ngIf="!checklistId"></new-customer-project-location>
            <ion-item class="ion-margin-bottom">
                <ion-textarea [(ngModel)]="model.Description" placeholder="Notes">
                </ion-textarea>
            </ion-item>
            <multi-platform-multi-file-upload #readingsUpload class="w-100" [label]="'Add files/photos'"
                [options]="uploadOptions">
            </multi-platform-multi-file-upload>
        </ng-container>
        <ion-button *ngIf="model && model.Id > 0" class="ion-margin-top ion-margin-bottom" color="danger" size="block"
            (click)="delete()">
            Delete Time Tracking Record
            <ion-icon slot="end" name="trash"></ion-icon>
        </ion-button>
    </ng-container>
</ion-content>
<ion-footer *ngIf="!loading">
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="backToList()" color="light">
                <ion-label>Back</ion-label>
            </ion-segment-button>
            <ion-segment-button (click)="save()" color="primary" checked
                *ngIf="!submitting && !model.Id && !upload?.isUploading()">
                <ion-label>Save</ion-label>
            </ion-segment-button>
            <ion-segment-button (click)="save()" color="primary" checked
                *ngIf="!submitting && model.Id > 0 && !upload?.isUploading()">
                <ion-label>Update</ion-label>
            </ion-segment-button>
            <ion-segment-button *ngIf="submitting">
                <ion-spinner></ion-spinner>
            </ion-segment-button>
            <ion-segment-button (click)="dismissModal()" color="light">
                <ion-label>Cancel</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>

<ion-content *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
                <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>