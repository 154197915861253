import { Injectable } from "@angular/core";
import { RepositoryService } from "../data/db/repository.service";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import * as _ from "lodash";
import { ApiService } from "../shared/services/api/api.service";
import { BaseDataResponse, BaseResponse, BaseResponseModel } from "../shared/models/base-response-model";
import { ChecklistStatusHistoryModel, JobDataModel, LocationJobHistoryModel, SiteJobModel } from "../checklist/checklist.models";
import { IsNull, Not } from "typeorm";
import { SyncService } from "../sync/sync.service";
import { SyncQueueService } from "../sync/sync-queue.service";
import { AssetsService } from "../assets/assets.service";
import { ChecklistStatus } from "../data/entities/ChecklistStatus";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants"
import { DateTimeHelperService } from "../shared/services/datetime/datetime-helper.service";
import { JobCheckInOut } from "../data/entities/JobCheckInOut";
import { PreviewService } from "../shared/services/preview-service/preview-service.service";
import { AddWorkResponse, AvailableScheduleVendorData, LookupListGroup, LookupListItem, SelectListItem } from "../assets/assets.models";
import { Router } from "@angular/router";
import { GeolocationService } from "../shared/services/geolocation-service/geolocation.service";
import { FacilityService } from "../assets/facility.service";
import { ActionSheetController, AlertController, ToastController } from "@ionic/angular";
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";
import { LoaderHelperService } from "../shared/services/browser/loader-helper.service";
import { JobResponseModel, NewCalendarNoteModel, NewJobModel, NewWarehouseBuildModel, UserTimeOffDayModel, UserTimeTrackingModel, UserTimeTrackingModelFile } from "./my-work.models";
import { FacilityChecklist } from "../data/entities/FacilityChecklist";

@Injectable()
export class MyWorkService {
  constructor(
    private repositoryService: RepositoryService,
    public errorHandlerService: ErrorHandlerService,
    private apiService: ApiService,
    private syncService: SyncService,
    private assetsService: AssetsService,
    private facilityService: FacilityService,
    private syncQueueService: SyncQueueService,
    private pubSubService: PubSubService,
    private dateTimeHelperService: DateTimeHelperService,
    private previewService: PreviewService,
    private router: Router,
    private geolocationService: GeolocationService,
    private actionSheetController: ActionSheetController,
    private browserHelperService: BrowserHelperService,
    private alertController: AlertController,
    private toastController: ToastController,
    private loaderHelperService: LoaderHelperService
  ) {
  }

  async getJob(id: number): Promise<JobDataModel> {
    let checklist = await this.repositoryService.getFacilityChecklistRepository().findOneBy({ Id: id });
    if (checklist) {
      return JSON.parse(checklist.JobData);
    }
    return null;
  }

  async getJobHash(id: number): Promise<string> {
    let checklist = await this.repositoryService.getFacilityChecklistRepository().findOneBy({ Id: id });
    return checklist.Hash;
  }

  async getJobHashFromTheServer(id: number): Promise<string> {
    return await this.apiService.get("assets/GetJobHash", { checklistId: id, v: new Date().getTime() });
  }

  async areAssetsConfigured(customerId: number): Promise<BaseDataResponse<boolean>>{
    return await this.apiService.get("assets/areAssetsConfigured", { customerId });
  }

  async updateJob(job: JobDataModel): Promise<void> {
    let checklist = await this.repositoryService.getFacilityChecklistRepository().findOneBy({ Id: job.ChecklistId });
    checklist.JobData = JSON.stringify(job);
    await this.repositoryService.getFacilityChecklistRepository().save(checklist, { transaction: false });
  }

  async updateJobImageCount(checklistId: number, addNumber: number) {
    let job = await this.getJob(checklistId);
    if (job) {
      job.ImageCount = (job.ImageCount || 0) + addNumber;
      if (job.ImageCount < 0) {
        job.ImageCount = 0;
      }
      await this.updateJob(job);
    }
  }

  async getJobs(): Promise<JobDataModel[]> {
    let checklists = await this.repositoryService.getFacilityChecklistRepository().find({
      where: {
        JobData: Not(IsNull())
      }
    });
    let jobs: JobDataModel[] = [];
    for (let checklist of checklists) {
      jobs.push(JSON.parse(checklist.JobData));
    }
    return _.orderBy(jobs, x => x.DisplayOrder);
  }

  async loadJobsLightThenFull(lightDone: () => void, lightError: (err: any) => void, fullDone: () => void, fullError: (err: any) => void): Promise<void> {
    if ((await this.browserHelperService.isOfflineAndMessage())) {
      return;
    }
    let pendingDataCount = await this.syncService.getPendingDataCount();
    if (pendingDataCount > 0) {
      const toast = await this.toastController.create({
        message: `Can't refresh jobs. Please upload all your data first.`,
        position: "bottom",
        duration: 5000
      });
      await toast.present();
    } else {
      try {
        await this.apiService.ping();
      } catch (err) {
        this.errorHandlerService.logErrorAndShowGenericToast(err);
        lightError(err);
        return;
      }
      try {
        await this.loadJobsLight();
        lightDone();
        try {
          await this.loadJobs();
          fullDone();
        } catch (err1) {
          fullError(err1);
          const toast = await this.toastController.create({
            message: `Can't load jobs details. Please try refreshing.`,
            position: "bottom",
            duration: 5000
          });
          await toast.present();
        }
      } catch (err) {
        lightError(err);
        this.errorHandlerService.logErrorAndShowGenericToast(err);
      }
    }
  }

  async getJobsIds(): Promise<number[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.post<BaseResponseModel<number>>("jobs/getjobsids", {});
      return response.Items;
    }
    return [];
  }

  async loadJobsLight(): Promise<void> {
    let pendingDataCount = await this.syncService.getPendingDataCount();
    if (pendingDataCount == 0) {
      let response = await this.apiService.post<JobResponseModel>("jobs/getjobslight", {});
      let jobs = response.Jobs;

      //Cleanup
      var availableJobIds = jobs.map(x => x.Model.ChecklistId);
      let checklistsFromDb = await this.repositoryService.getFacilityChecklistRepository().find();
      for (let checklistFromDb of checklistsFromDb) {
        if (availableJobIds.indexOf(checklistFromDb.Id) < 0) {
          this.repositoryService.getFacilityChecklistRepository().delete(checklistFromDb);
        }
      }

      //Map
      let facilityChecklistsToSave: FacilityChecklist[] = [];
      for (let job of jobs) {
        facilityChecklistsToSave.push(this.mapFacilityChecklist(job));
      }

      //Save
      const chunks = _.chunk(facilityChecklistsToSave, 100);
      for (let facilityChecklistChunk of chunks) {
        await this.repositoryService.getFacilityChecklistRepository().save(facilityChecklistChunk, { transaction: false });
      }
    }
  }

  async loadJobs(): Promise<boolean> {
    let dataLoaded: boolean = false;
    let pendingDataCount = await this.syncService.getPendingDataCount();
    if (pendingDataCount == 0) {
      await this.facilityService.reloadMyFacilitiesAndAssets();
      dataLoaded = true;
    }
    return dataLoaded;
  }

  async changeJobStatus(checklistId: number, statusId, notes: string): Promise<void> {
    let jobModel = await this.getJob(checklistId);
    if (jobModel.StatusId == statusId && jobModel.StatusNotes == notes) {
      return;
    }
    let status = jobModel.StatusList.find(x => x.Value == statusId);
    if (status) {
      jobModel.StatusId = statusId;
      jobModel.StatusName = status.Text;
      jobModel.StatusColor = status.Color;
      jobModel.StatusNotes = notes;
      await this.updateJob(jobModel);
      this.pubSubService.$pub(EventConstants.UPDATE_JOB_STATUS, jobModel);
      const location = this.geolocationService.getCurrentLocation();
      const checklistStatus: ChecklistStatus = {
        Id: checklistId,
        Status: statusId,
        Notes: notes,
        Latitude: String(location.latitude || ""),
        Longitude: String(location.longitude || ""),
        DateCreated: this.dateTimeHelperService.getUtcString()
      };
      await this.syncQueueService.addToQueueEveryTime('checklistStatus', checklistStatus);
    }
  }

  async checkInToJob(checklistId: number): Promise<void> {
    const location = this.geolocationService.getCurrentLocation();
    const checklistStatus: JobCheckInOut = {
      ChecklistId: checklistId,
      Latitude: String(location.latitude || ""),
      Longitude: String(location.longitude || ""),
      DateCreated: this.dateTimeHelperService.getUtcString()
    };
    await this.syncQueueService.addToQueueEveryTime('jobCheckIn', checklistStatus);
    this.previewService.setPreviewOff();
  }

  async checkOutFromJob(checklistId: number): Promise<void> {
    const location = this.geolocationService.getCurrentLocation();
    const checklistStatus: JobCheckInOut = {
      ChecklistId: checklistId,
      Latitude: String(location.latitude || ""),
      Longitude: String(location.longitude || ""),
      DateCreated: this.dateTimeHelperService.getUtcString()
    };
    await this.syncQueueService.addToQueueEveryTime('jobCheckOut', checklistStatus);
    await this.previewService.setPreviewOn(checklistId);
  }

  async canCheckin(projectBillingId: number): Promise<BaseDataResponse<{ CanCheckin: boolean, Message: string }>> {
    return await this.apiService.get("checklist/canCheckin", { projectBillingId });
  }

  async getStatusHistory(projectBillingId: number): Promise<BaseResponseModel<ChecklistStatusHistoryModel>> {
    return await this.apiService.get("checklist/statushistory", { projectBillingId });
  }

  async getLocationJobHistory(checklistId: number): Promise<BaseResponseModel<LocationJobHistoryModel>> {
    return await this.apiService.get("checklist/getLocationJobHistory", { checklistId });
  }

  async getJobsBySiteAndProject(siteId: number, projectId: number): Promise<JobDataModel[]> {
    return (await this.getJobs()).filter(x => x.SiteId == siteId && x.ProjectId == projectId);
  }

  async addAdditionalWorkSheetLive(siteKey: string, siteId: number) {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    await this.loaderHelperService.on();
    try {
      let projects = await this.apiService.get<LookupListItem[]>("site/getavailableprojects", {
        siteKey: siteKey
      });
      if (projects.length > 0) {
        await this.addAdditionalWorkSheet(siteId, projects);
      } else {
        const toast = await this.toastController.create({
          message: "No available projects",
          duration: 2000
        });
        toast.present();
      }
    } finally {
      await this.loaderHelperService.off();
    }
  }

  async createJob(model: NewJobModel): Promise<BaseDataResponse<number>> {
    return await this.apiService.post<BaseDataResponse<number>>("jobs/createjob", model);
  }

  async deleteTimeTracking(id: number): Promise<BaseResponse> {
    return await this.apiService.post<BaseResponse>("TimeTracking/DeleteTimeTracking", id);
  }

  async addOrUpdateTimeTracking(model: UserTimeTrackingModel, files: UserTimeTrackingModelFile[]): Promise<BaseDataResponse<number>> {
    return await this.apiService.post<BaseDataResponse<number>>("TimeTracking/AddOrUpdateTimeTracking", { model, files, customerLocationProject: model.CustomerLocationProject });
  }

  async getCalendarNote(id: number): Promise<BaseDataResponse<NewCalendarNoteModel>> {
    return await this.apiService.get<BaseDataResponse<NewCalendarNoteModel>>("calendar/getcalendarnote", { id: id });
  }

  async deleteCalendarNote(id: number): Promise<BaseResponse> {
    return await this.apiService.post<BaseResponse>("calendar/deleteCalendarNote", id);
  }

  async addOrUpdateCalendarNote(model: NewCalendarNoteModel): Promise<BaseDataResponse<number>> {
    return await this.apiService.post<BaseDataResponse<number>>("calendar/addOrUpdateCalendarNote", model);
  }

  async createCalendarJob(model: NewJobModel): Promise<BaseDataResponse<number>> {
    return await this.apiService.post<BaseDataResponse<number>>("jobs/createcalendarjob", model);
  }

  async createWarehouseBuild(model: NewWarehouseBuildModel): Promise<BaseResponse> {
    return await this.apiService.post("warehousebuild/create", model);
  }

  async getWarehouseBuildTemplates(): Promise<BaseResponseModel<NewWarehouseBuildModel>> {
    return await this.apiService.get("warehousebuild/gettemplates");
  }

  async getWarehouseBuildById(id: number): Promise<BaseDataResponse<NewWarehouseBuildModel>> {
    return await this.apiService.get("warehousebuild/getwarehousebuildbyid", { id: id });
  }

  async addAdditionalWorkSheet(siteId: number, projects: LookupListItem[]) {
    if (projects.length == 0) {
      return;
    }
    let buttons = [];
    for (let status of projects) {
      buttons.push({
        text: status.Text,
        handler: async () => {
          await this.addAdditionalWorkSheetSelection(siteId, status.Value);
        }
      });
    }

    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
    });

    const actionSheet = await this.actionSheetController.create({
      header: 'More Jobs',
      cssClass: "more-jobs-action-sheet",
      buttons: buttons
    });
    await actionSheet.present();
  }


  async getCustomers(): Promise<LookupListGroup[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<LookupListGroup>>("jobs/getCustomers", {});
      return response.Items;
    }
    return [];
  }

  async getCalendarNoteTasks(): Promise<LookupListItem[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<LookupListItem>>("calendar/getCalendarNoteTasks", {});
      return response.Items;
    }
    return [];
  }

  async getCalendarUsers(): Promise<LookupListItem[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<LookupListItem>>("calendar/getUsers", {});
      return response.Items;
    }
    return [];
  }

  async getUsers(): Promise<LookupListItem[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<LookupListItem>>("jobs/getUsers", {});
      return response.Items;
    }
    return [];
  }

  async getModelFromQueue(queueItemId: number): Promise<NewJobModel> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseDataResponse<NewJobModel>>("jobs/getModelFromQueue", { queueItemId });
      return response.Data;
    }
    return {} as NewJobModel;
  }

  async getProjects(templateId: number, customerId: number): Promise<LookupListGroup[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<LookupListGroup>>("jobs/getProjects", { projectTypeId: templateId, customerId });
      return response.Items;
    }
    return [];
  }

  async getTimeTracking(id: number): Promise<UserTimeTrackingModel> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseDataResponse<UserTimeTrackingModel>>("timetracking/getforedit", { id });
      return response.Data;
    }
    return null;
  }

  async getTimeTrackingGroups(): Promise<LookupListGroup[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<LookupListGroup>>("timetracking/getgroups");
      return response.Items;
    }
    return [];
  }

  async getProjectsByCustomer(customerId: number): Promise<LookupListGroup[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<LookupListGroup>>("jobs/getProjectsByCustomer", { customerId });
      return response.Items;
    }
    return [];
  }

  async getSubVendors(): Promise<AvailableScheduleVendorData[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<AvailableScheduleVendorData>>("jobs/GetSubVendorData", {});
      return response.Items;
    }
    return [];
  }

  async getQcTemplateCategories(): Promise<SelectListItem[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<SelectListItem>>("jobs/getTemplateCategories", { showOnlyAvailableOnMobile: true });
      return response.Items;
    }
    return [];
  }

  async getTemplateCategories(): Promise<SelectListItem[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<SelectListItem>>("jobs/getTemplateCategories", { showOnlyAvailableOnMobile: false });
      return response.Items;
    }
    return [];
  }

  async getAllTemplateCategories(): Promise<SelectListItem[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<SelectListItem>>("jobs/getAllTemplateCategories", { showOnlyAvailableOnMobile: false });
      return response.Items;
    }
    return [];
  }

  async getJobToDuplicate(checklistId: number): Promise<NewJobModel> {
    let response = await this.apiService.get<BaseDataResponse<NewJobModel>>("jobs/getJobToDuplicate", {
      checklistId
    });
    return response.Data;
  }

  async getTemplates(categoryId: number): Promise<SelectListItem[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<SelectListItem>>("jobs/getTemplates", {
        categoryId
      });
      return response.Items;
    }
    return [];
  }

  async getGrouppedTemplates(): Promise<LookupListGroup[]> {
    if (!this.browserHelperService.isOffline()) {
      let response = await this.apiService.get<BaseResponseModel<LookupListGroup>>("jobs/getGrouppedTemplates");
      return response.Items;
    }
    return [];
  }

  private async addAdditionalWorkSheetSelection(siteId, projectId: number) {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const pendingCount = await this.syncService.getPendingDataCount();
    if (pendingCount > 0) {
      alert("You have some pending data, please make sure that is uploaded first before doing this");
      return;
    }

    this.addAdditionalWorkAlert(siteId, projectId);
  }

  getJobTimeTracking(jobId: number): Promise<BaseResponseModel<UserTimeTrackingModel>> {
    if (jobId) {
      return this.apiService.get("timetracking/get", { checklistId: jobId });
    }
    return this.apiService.get("timetracking/get");
  }

  getTimeOffUsers(): Promise<BaseResponseModel<SelectListItem>> {
    return this.apiService.get("timetracking/getUsers");
  }

  getTimeOff(userId: number | null, filter?: "upcoming" | "ytd" | "lastyear" | "thisyear"): Promise<BaseResponseModel<UserTimeOffDayModel>> {
    if (userId) {
      return this.apiService.get("timetracking/getTimeOff", { forUserId: userId, filter: filter || "upcoming" });
    }
    return this.apiService.get("timetracking/getTimeOff", { filter: filter || "upcoming" });
  }

  saveTimeOff(userId: number | null, model: UserTimeOffDayModel[]): Promise<BaseResponseModel<UserTimeOffDayModel>> {
    if (userId) {
      return this.apiService.post("timetracking/addTimeOff", { items: model, forUserId: userId });
    }
    return this.apiService.post("timetracking/addTimeOff", { items: model });
  }

  private async addAdditionalWorkAlert(siteId: number, projectId: number) {
    const existing = await this.getJobsBySiteAndProject(siteId, projectId);
    const alert = await this.alertController.create({
      header: "ADD JOB",
      subHeader: existing.length > 0 ? "You already have Job with this Project and this Location in My Work" : undefined,
      buttons: [
        {
          text: "Add and stay on this page",
          handler: async (value: any) => {
            await this.addAdditionalWorkInternal(siteId, projectId);
            const toast = await this.toastController.create({
              message: "New Job added to My Work",
              duration: 2000
            });
            toast.present();
          }
        },
        {
          text: "Add and go to the new Job",
          handler: async (value: any) => {
            const checklistId = await this.addAdditionalWorkInternal(siteId, projectId);
            const toast = await this.toastController.create({
              message: "New Job added",
              duration: 2000
            });
            toast.present();
            if (checklistId) {
              this.previewService.setPreviewOff();
              setTimeout(() => {
                this.router.navigate([`mywork/work-details/${checklistId}`]);
              }, 50);
            }
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        }
      ]
    });
    return await alert.present();
  }

  private async addAdditionalWorkInternal(siteId, projectId: number): Promise<number> {
    let checklistId: number = null;
    try {
      await this.loaderHelperService.on();
      let response = await this.addAdditionalWorkServer(siteId, projectId);
      if (response.Success) {
        checklistId = response.HighlightChecklistId;
        let facility = await this.repositoryService.getFacilityRepository().findOneBy({ Id: response.Facility.Id });
        response.Facility.Downloaded = facility.Downloaded;
        await this.facilityService.reloadFacility(response.Facility);
      } else {
        alert(response.Message);
      }
    } finally {
      this.loaderHelperService.off();
    }
    return checklistId;
  }

  private async addAdditionalWorkServer(siteId: number, projectId: number): Promise<AddWorkResponse> {
    let response = await this.apiService.post<AddWorkResponse>("assets/AddAdditionalWork", {
      siteId: siteId,
      projectId: projectId,
      date: this.dateTimeHelperService.getUtcString()
    });
    await this.facilityService.reloadConfiguration(response.ConfigurationData);
    return response;
  }

  private mapFacilityChecklist(job: SiteJobModel): FacilityChecklist {
    return {
      Id: job.Model.ChecklistId,
      JobData: JSON.stringify(job.Model),
      SiteKey: job.Model.SiteKey,
      TimeStampUpdated: null,
      Sections: JSON.stringify(job.Sections),
      JobDataModel: null,
      SectionsModel: null,
      Hash: job.Hash
    };
  }
}
