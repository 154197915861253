import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { MyWorkService } from "./my-work.service";
import { JobDataModel, SiteJobModel } from "../checklist/checklist.models";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { PreviewService } from "../shared/services/preview-service/preview-service.service";
import { MyWorkListComponent } from "./list/my-work-list.component";
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";
import { ToastController } from "@ionic/angular";
import { SyncService } from "../sync/sync.service";

@Component({
  selector: "my-work",
  templateUrl: "my-work.component.html"
})
export class MyWorkComponent implements OnInit, OnDestroy {
  workList: JobDataModel[] = [];
  loading: boolean = false;
  newJobCount: number = 0;
  mode: string = 'list';
  search: boolean = false;
  @ViewChild("searchBarElement") searchBarElement: HTMLIonSearchbarElement;
  query: string;
  statusFilter: string;
  refreshing: boolean = false;

  private subscription: Subscription;
  private highlightId: string;

  @ViewChild(MyWorkListComponent, { static: false }) myWorkListComponent: MyWorkListComponent;

  constructor(
    private myWorkService: MyWorkService,
    private pubSubService: PubSubService,
    private route: ActivatedRoute,
    private previewService: PreviewService,
    private browserHelperService: BrowserHelperService,
    private toastController: ToastController,
    private syncService: SyncService
  ) { }

  ngOnInit() {
    this.subscription = this.pubSubService.$sub(EventConstants.UPDATE_JOB_STATUS, (model: JobDataModel) => {
      for (let item of this.workList) {
        if (item.ChecklistId == model.ChecklistId) {
          item.StatusId = model.StatusId;
          item.StatusName = model.StatusName;
          item.StatusColor = model.StatusColor;
          item.CheckedIn = model.CheckedIn;
        }
      }
      if (this.myWorkListComponent) {
        this.myWorkListComponent.setData(this.workList);
      }
    });
    this.highlightId = this.route.snapshot.params["highlightId"] || null;
    this.loading = true;
    this.refresh();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async ionViewWillEnter() {
    //this.loading = window["login"] == true || this.workList.length == 0;
    window["login"] = false;
    if (!this.loading) {
      await this.loadJobs();
      if (this.workList.length == 0) {
        this.loading = true;
        this.refresh();
      }
    }
    this.searchPrivate();
    this.previewService.setPreviewOff();
    if (!this.refreshing) {
      this.myWorkService.getJobsIds().then((jobs) => {
        var existingJobIds = this.workList.map(x => x.ChecklistId);
        //If there is an Id in the list from the server that is not on the local list tell user that there may be new jobs available.
        var newJobs = jobs.filter(x => existingJobIds.indexOf(x) < 0);
        this.newJobCount = newJobs.length;
      });
    }

    if (this.statusFilter == "3" && !this.hasScheduled()) {
      this.goToAll();
    } else if (this.statusFilter == "started" && !this.hasStarted()) {
      this.goToAll();
    } else if (this.statusFilter == "9" && !this.hasCorrection()) {
      this.goToAll();
    } else if (!this.hasStatusFilter()) {
      this.goToAll();
    }
  }

  private goToAll() {
    this.segmentChanged({ detail: { value: "all" } });
  }

  switchMode(mode: string) {
    this.mode = mode;
  }

  onWorkListUpdated(newList: JobDataModel[]) {
    this.workList = newList;
    this.startSearch();
  }

  searchOn() {
    this.search = true;
    this.searchBarElement.setFocus();
  }

  startSearch() {
    this.searchPrivate();
  }

  private containsSearchValue(value: string): boolean {
    return (value ?? "").toLowerCase().indexOf((this.query ?? "").toLowerCase()) >= 0;
  }


  segmentChanged(event: any) {
    this.statusFilter = event.detail.value;
    this.searchPrivate();
  }

  hasCorrection(): boolean {
    return this.workList.some(item => item.StatusId == 9);
  }

  hasScheduled(): boolean {
    return this.workList.some(item => item.StatusId == 3);
  }

  hasStarted(): boolean {
    //return this.workList.some(item => item.StatusId == 4 || item.StatusId == 16);
    return this.workList.some(item => item.CheckedIn);
  }

  hasStatusFilter() {
    return _.uniqBy(this.workList, item => item.StatusId).length > 1;
  }

  async refresh() {
    if (this.refreshing) {
      const toast = await this.toastController.create({
        message: `Already refreshing...`,
        position: "bottom",
        duration: 5000
      });
      await toast.present();
      return;
    }
    if ((await this.browserHelperService.isOfflineAndMessage())) {
      try {
        await this.loadJobs();
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
      return;
    }
    this.refreshing = true;
    this.myWorkService.loadJobsLightThenFull(() => {
      this.loading = false;
      this.loadJobs();
      this.newJobCount = 0;
    }, (errLight) => {
      //Error light
      this.loadJobs();
    }, () => {
      this.loadJobs();
    }, (errFull) => {
      //Error full
    }).finally(() => {
      this.refreshing = false;
      this.loading = false;
      this.newJobCount = 0;
    });
  }

  private searchPrivate() {
    this.restartSearch();
    for (let item of this.workList) {
      if (!(
        this.containsSearchValue(item.SiteName) ||
        this.containsSearchValue(item.ProjectName) ||
        this.containsSearchValue(item.ProjectType) ||
        this.containsSearchValue(item.ChecklistId.toString()) ||
        this.containsSearchValue(item.ScheduledWorkDateText) ||
        this.containsSearchValue(item.StatusName))
      ) {
        item.Hidden = true;
      }
    }
    this.filterOnStatus(this.statusFilter);
    this.pubSubService.$pub(EventConstants.JOB_SEARCH_DONE);
    if (this.myWorkListComponent) {
      this.myWorkListComponent.setData(this.workList.filter(x => !x.Hidden));
    }
  }

  private filterOnStatus(value: string) {
    this.statusFilter = value;
    let filterStatuses = [];
    if (value == "3" || value == "9") {
      const valueInt = parseInt(value);
      filterStatuses.push(valueInt);
    } else if (value == "started") {
      //filterStatuses = [4, 16];
      for (let item of this.workList) {
        if (!item.CheckedIn) {
          item.Hidden = true;
        }
      }
    }
    if (filterStatuses.length > 0) {
      for (let item of this.workList) {
        if (!filterStatuses.some(_ => _ == item.StatusId)) {
          item.Hidden = true;
        }
      }
    }
  }

  private restartSearch() {
    for (let item of this.workList) {
      item.Hidden = false;
    }
  }

  private async loadJobs() {
    this.workList = await this.myWorkService.getJobs();
    for (let item of this.workList) {
      item.Highlight = String(item.ChecklistId) == this.highlightId;
    }
    this.searchPrivate();
    setTimeout(() => {
      this.scrollToHighlight();
    }, 500);
  }

  private scrollToHighlight() {
    try {
      document.getElementsByClassName("work-item-highlight")[0].scrollIntoView({
        behavior: "smooth"
      });
    } catch { }
  }
}
