import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { MyWorkService } from "../../my-work.service";
import { ChecklistQuestionModel, JobDataModel } from "src/app/checklist/checklist.models";
import { AssetsService } from "src/app/assets/assets.service";
import { Facility } from "src/app/data/entities/Facility";
import { ActionSheetController, AlertController, ModalController, PopoverController, ToastController } from "@ionic/angular";
import { WorkItemSiteDetailsComponent } from "../../work-item-site-details/work-item-site-details.component";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { SyncService } from "src/app/sync/sync.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";
import { GeolocationService } from "src/app/shared/services/geolocation-service/geolocation.service";
import { WorkStatusHistoryComponent } from "../work-status-history/work-status-history.component";
import { FacilityService } from "src/app/assets/facility.service";
import { ChecklistService } from "src/app/checklist/checklist.service";
import { WorkDataCorrectionsNeededComponent } from "./work-data-corrections-needed/work-data-corrections-needed.component";
import { SelectedAsset } from "src/app/assets/assets.models";
import { FacilityChecklist } from "src/app/data/entities/FacilityChecklist";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { ChecklistButtonComponent } from "src/app/checklist/checklist-button/checklist-button.component";

@Component({
  selector: "work-data",
  templateUrl: "work-data.component.html"
})
export class WorkDataComponent implements OnInit {
  @Input()
  jobModel: JobDataModel;
  @Input()
  facility: Facility;
  @Input()
  fromMap: boolean;
  @Input()
  galleryCount: number;
  @Input()
  correctionsNeeded: boolean;
  @Input()
  hasUpdates: boolean;

  assetsPreviewOnly: boolean = false;
  refreshing: boolean = false;
  pendingCount: number = 0;

  timeTrackingEnabled: boolean = false;

  @ViewChild(ChecklistButtonComponent, { static: false }) checklistButtonComponent: ChecklistButtonComponent;

  get preview() {
    return this.jobModel.CheckedIn != true;
  }

  private facilityChecklist: FacilityChecklist;
  private replaceQuestion: ChecklistQuestionModel;
  private readonly workCompleteStatusId = 5;
  private readonly openStatusId = 4;
  private readonly inProgressStatusId = 16;
  constructor(
    private myWorkService: MyWorkService,
    private assetsService: AssetsService,
    private facilityService: FacilityService,
    private router: Router,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private pubSubService: PubSubService,
    private previewService: PreviewService,
    private cameraHelperService: CameraHelperService,
    private syncService: SyncService,
    private browserHelperService: BrowserHelperService,
    private loaderHelperService: LoaderHelperService,
    private toastController: ToastController,
    private geolocationService: GeolocationService,
    private checklistService: ChecklistService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.facilityChecklist = await this.checklistService.getChecklistById(this.jobModel.ChecklistId);
    this.replaceQuestion = this.checklistService.getFirstQuestionWithDataType("replaceassets", this.facilityChecklist);
    this.geolocationService.setCurrentLocation();

    let checklist = await this.checklistService.getChecklistById(this.jobModel.ChecklistId);
    let question = this.checklistService.getFirstQuestionWithDataType("battery", checklist);
    let canMaintainAssets = await this.userService.canMaintainAssets();
    if (!canMaintainAssets && !question) {
      this.assetsPreviewOnly = true;
    }
    this.pendingCount = await this.syncService.getPendingDataCount();

    this.pubSubService.$sub(EventConstants.SYNC_UPDATE, async () => {
      this.pendingCount = await this.syncService.getPendingDataCount();
    });

    let user = await this.userService.getLoggedInUser();
    if (user) {
      this.timeTrackingEnabled = user.Config.MobileTimeTrackingEnabled;
    }
  }

  async refreshJob() {
    this.pendingCount = await this.syncService.getPendingDataCount();
    if (this.pendingCount > 0) {
      return;
    }
    if (!(await this.browserHelperService.isOfflineAndMessage())) {
      try {
        //this.refreshing = true;
        await this.facilityService.refreshJob(this.facility.Id, this.jobModel.ChecklistId);
        this
      } finally {
        //this.refreshing = false;
        this.pubSubService.$pub(EventConstants.JOB_REFRESHED);
      }
    }
  }

  async goToInfo() {
    this.router.navigate([`mywork/work-details/${this.jobModel.ChecklistId}/site-details`]);
  }

  async goToFiles() {
    if (this.jobModel.Documents.length == 0) {
      return;
    }
    this.router.navigate([`mywork/work-details/${this.jobModel.ChecklistId}/files`]);
  }

  galleryUnavailable(): boolean {
    return !this.checkedIn() && this.jobModel.ImageCount == 0;
  }

  assetReplacedLabel(item: SelectedAsset) {
    if (item.ReplacedWithId) {
      if (item.ReplacedDate) {
        return `replaced ${moment(item.ReplacedDate).format("MM-DD-YYYY")}`;
      }
    } else {
      let asset = this.assetsService.getAssetFromFacility(this.facility, item.Id);
      if (asset) {
        let isReplaced = String(asset.ReplacedWithId || "").length > 0;
        if (isReplaced && asset.ReplacedDate) {
          return `replaced ${moment(asset.ReplacedDate).format("MM-DD-YYYY")}`;
        }
      }
    }
    return "";
  }

  isAssetReplaced(item: SelectedAsset) {
    if (item.ReplacedWithId) {
      return true;
    } else {
      let asset = this.assetsService.getAssetFromFacility(this.facility, item.Id);
      if (asset) {
        return String(asset.ReplacedWithId || "").length > 0;
      }
    }
    return false;
    // if(this.replaceQuestion && this.replaceQuestion.AuditData) {
    //   return this.replaceQuestion.AuditData.split(",").indexOf(item.Id) >= 0;
    // }
    // return false;
  }

  async goToGallery() {
    if (!this.galleryUnavailable()) {
      // let summary = this.checklistService.getFacilityChecklistSummary(await this.checklistService.getChecklistById(this.jobModel.ChecklistId));
      // if (summary.totalCount > 0) {
      //   this.router.navigate([`/gallery/${this.facility.Id}/${this.jobModel.ChecklistId}/null/null/true/false/${this.preview}/open`]);
      // } else {
      //   this.router.navigate([`/gallery/${this.facility.Id}/${this.jobModel.ChecklistId}/null/null/true/true/${this.preview}/open`]);
      // }
      this.router.navigate([`/gallery/${this.facility.Id}/${this.jobModel.ChecklistId}/null/null/true/true/${this.preview}/open`]);
    }
  }

  canWorkComplete(): boolean {
    if (this.jobModel.StatusId == this.workCompleteStatusId) {
      return false;
    }
    return this.jobModel.StatusList.some(item => item.Value == this.workCompleteStatusId);
  }

  async workComplete() {
    await this.checkOutPopup(this.workCompleteStatusId);
  }

  async checkOutPopup(statusId: number, notes: string = null) {
    if ((notes || "").length == 0) {
      let checklist = await this.checklistService.getChecklistById(this.jobModel.ChecklistId)
      for (let section of checklist.SectionsModel) {
        for (let question of section.Questions) {
          if (question.VendorQuestionDataTypeFK == -1) {
            if (question.AuditData) {
              notes = "Correction Notes: " + question.AuditData;
            }
          }
        }
      }
    }
    let buttons =
      [
        {
          text: "Confirm",
          handler: async (value: any) => {
            await this.updateStatusInternal(statusId, value[0]);
            await this.checkOutInternal();
          }
        },
        {
          text: "Select different status",
          handler: async (value: any) => {
            await this.selectStatus(value[0]);
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        }
      ];

    if (statusId != this.workCompleteStatusId) {
      buttons.splice(1, 1);
    }
    let status = this.jobModel.StatusList.find(x => x.Value == statusId);
    const alert = await this.alertController.create({
      header: status.Text,
      message:
        `Are you sure you want to mark this Job as ${status.Text}?`,
      buttons: buttons,
      inputs: [
        {
          type: 'textarea',
          placeholder: 'Notes (optional)',
          max: 1000,
          value: notes
        }
      ]
    });
    return await alert.present();
  }

  async moreInfo(ev) {
    const modal = await this.modalController.create({
      component: WorkItemSiteDetailsComponent,
      componentProps: {
        jobModel: this.jobModel,
        facility: this.facility,
        fromMap: false,
        preview: this.preview
      }
    });
    return await modal.present();
  }

  timeTracking(event) {
    this.router.navigate([`timetracking/${this.jobModel.ChecklistId}/${this.preview}`]);
  }

  async checkIn() {
    if (!this.browserHelperService.isOffline()) {
      try {
        await this.loaderHelperService.on();
        const response = await this.myWorkService.canCheckin(this.jobModel.ProjectBillingId);
        if(!response.Data.CanCheckin){
          alert(response.Data.Message);
          return;
        }
      } //catch { }
      finally {
        await this.loaderHelperService.off();
      }
    }
    this.checkInPrivate();
  }

  private async checkInPrivate() {
    await this.myWorkService.checkInToJob(this.jobModel.ChecklistId);
    await this.updateStatusInternal(this.openStatusId, null);
    this.jobModel.CheckedIn = true;
    await this.myWorkService.updateJob(this.jobModel);
    this.pubSubService.$pub(EventConstants.UPDATE_JOB_STATUS, this.jobModel);
  }

  async checkOut() {
    if (this.jobModel.HasAdditionalTechnicians && !this.jobModel.LeadTechnician) {
      await this.checkOutPopup(this.inProgressStatusId);
      return;
    }
    await this.workComplete();
  }

  checkedIn(): boolean {
    return this.jobModel.CheckedIn;
  }


  async selectStatus(notes: string) {
    let buttons = [];
    for (let status of this.jobModel.StatusList.filter(x => x.Value != this.workCompleteStatusId)) {
      buttons.push({
        text: status.Text,
        handler: async () => {
          await this.checkOutPopup(status.Value, notes);
        }
      });
    }
    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
    });

    const actionSheet = await this.actionSheetController.create({
      header: 'Set Job Status',
      buttons: buttons
    });
    await actionSheet.present();
  }

  async additionalTasks() {
    if (this.jobModel.Projects.length > 0) {
      this.myWorkService.addAdditionalWorkSheet(this.jobModel.SiteId, this.jobModel.Projects);
    }
  }

  async openStatusHistory() {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    const modal = await this.modalController.create({
      component: WorkStatusHistoryComponent,
      componentProps: {
        jobModel: this.jobModel
      }
    });
    return await modal.present();
  }

  async correctionsNeededInfo(ev) {
    let popover = await this.popoverController.create({
      component: WorkDataCorrectionsNeededComponent,
      event: ev,
      mode: "ios",
      componentProps: {
        checklistId: this.jobModel.ChecklistId,
        preview: this.preview
      }
    });
    popover.present();
  }

  private async updateStatusInternal(statusId: number, statusNotes: string) {
    await this.myWorkService.changeJobStatus(this.jobModel.ChecklistId, statusId, statusNotes);
    this.jobModel = await this.myWorkService.getJob(this.jobModel.ChecklistId);
  }

  private async checkOutInternal() {
    await this.myWorkService.checkOutFromJob(this.jobModel.ChecklistId);
    this.jobModel.CheckedIn = false;
    this.myWorkService.updateJob(this.jobModel);
    this.pubSubService.$pub(EventConstants.UPDATE_JOB_STATUS, this.jobModel);
  }

}