<ion-list>
    <ion-item (click)="takePhoto($event)">
        <ion-label>
            Take Photo
        </ion-label>
        <ion-icon name="camera" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="takeVideo($event)" lines="none">
        <ion-label>
            Capture Video
        </ion-label>
        <ion-icon name="videocam-outline" slot="end"></ion-icon>
    </ion-item>
</ion-list>