<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom">
            Create Job
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" *ngIf="!loading">
    <ion-list>
        <new-customer-project-location [(model)]="model.CustomerLocationProject" #customerLocationProject
            (projectChange)="onVendorChange(null)" [hideLocations]="true" [hideProjects]="true" [hideProjectTemplates]="true"></new-customer-project-location>
        <!-- <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusLocation()">
                Location
            </ion-note>
            <ion-input type="text" [(ngModel)]="model.Location" #locationInput placeholder="Scan or type in..."
            next-on-enter="sonumber" id="locationName">
            </ion-input>
            <div slot="end">
                <span tabindex="1" (click)="scanLocation($event)" class="battery-item-photo" style="z-index: 5;">
                    <ion-icon name="barcode-outline"></ion-icon>
                </span>
            </div>
        </ion-item> -->
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusSoNumber()">
                Sales Order Number
            </ion-note>
            <ion-input [(ngModel)]="model.SONumber" #soNumberInput placeholder="Scan or type in..."
                next-on-enter="wonumber" id="sonumber">
            </ion-input>
            <div slot="end">
                <span tabindex="1" (click)="scanSoNumber($event)" class="battery-item-photo" style="z-index: 5;">
                    <ion-icon name="barcode-outline"></ion-icon>
                </span>
            </div>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusWorkOrderNumber()">
                Work Order Number
            </ion-note>
            <ion-input [(ngModel)]="model.WorkOrderNumber" maxlength="150" #workOrderNumberInput placeholder="Scan or type in..."
                next-on-enter="ponumber" id="wonumber">
            </ion-input>
            <div slot="end">
                <span tabindex="1" (click)="scanWorkOrderNumber($event)" class="battery-item-photo" style="z-index: 5;">
                    <ion-icon name="barcode-outline"></ion-icon>
                </span>
            </div>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusPoNumber()">
                Customer PO
            </ion-note>
            <ion-input type="text" [(ngModel)]="model.PONumber" #poNumberInput placeholder="Scan or type in..."
                next-on-enter="systempartnumber" id="ponumber">
            </ion-input>
            <div slot="end">
                <span tabindex="1" (click)="scanPoNumber($event)" class="battery-item-photo" style="z-index: 5;">
                    <ion-icon name="barcode-outline"></ion-icon>
                </span>
            </div>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusSystemPartNumber()">
                System Part Number
            </ion-note>
            <ion-input [(ngModel)]="model.SystemPartNumber" maxlength="150" #systemPartNumberInput placeholder="Scan or type in..."
                next-on-enter="cabinetserialnumber" id="systempartnumber">
            </ion-input>
            <div slot="end">
                <span tabindex="1" (click)="scanSystemPartNumber($event)" class="battery-item-photo"
                    style="z-index: 5;">
                    <ion-icon name="barcode-outline"></ion-icon>
                </span>
            </div>
        </ion-item>
        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusCabinetSerialNumber()">
                Cabinet Serial Number
            </ion-note>
            <ion-input [(ngModel)]="model.CabinetSerialNumber" #cabinetSerialNumberInput
                placeholder="Scan or type in..." maxlength="150" next-on-enter="referencenumber" id="cabinetserialnumber">
            </ion-input>
            <div slot="end">
                <span tabindex="1" (click)="scanCabinetSerialNumber($event)" class="battery-item-photo"
                    style="z-index: 5;">
                    <ion-icon name="barcode-outline"></ion-icon>
                </span>
            </div>
        </ion-item>
        <!-- <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start" (click)="focusReferenceNumber()">
                Reference #
            </ion-note>
            <ion-input [(ngModel)]="model.ReferenceNumber" maxlength="150" #referenceNumberInput placeholder="Scan or type in..." id="referencenumber">
            </ion-input>
            <div slot="end">
                <span tabindex="1" (click)="scanReferenceNumber($event)" class="battery-item-photo" style="z-index: 5;">
                    <ion-icon name="barcode-outline"></ion-icon>
                </span>
            </div>
        </ion-item> -->
        <ion-item class="custom-input-ion-item" *ngIf="templateCategoryVisible">
            <ion-note position="fixed" slot="start" (click)="focusTemplateCategorySelectList()">
                Work Category
            </ion-note>
            <ion-select tabindex="2" #templateCategorySelectList [(ngModel)]="model.CustomerLocationProject.TemplateCategoryId" okText="Okay"
                cancelText="Dismiss" placeholder="" (ionChange)="templateCategoryChanged($event.detail.value)"
                placeholder="Select Work Category" [interfaceOptions]="{ header: 'Work Category'}">
                <ion-select-option [value]="item.Value" *ngFor="let item of templateCategories">
                    {{item.Text }}
                </ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item class="custom-input-ion-item" *ngIf="model.CustomerLocationProject.TemplateCategoryId">
            <ion-note position="fixed" slot="start" (click)="focusTemplateSelectList()">
                Work Type
            </ion-note>
            <ion-select *ngIf="templates.length != 1" tabindex="2" #templateSelectList [(ngModel)]="model.CustomerLocationProject.TemplateId"
                okText="Okay" cancelText="Dismiss" placeholder="Select Work Type"
                [interfaceOptions]="{ header: 'Work Type'}">
                <ion-select-option [value]="item.Value" *ngFor="let item of templates">
                    {{item.Text }}
                </ion-select-option>
            </ion-select>
            <ion-input [disabled]="true" *ngIf="templates.length == 1">
                {{templates[0].Text}}
            </ion-input>
        </ion-item>
    </ion-list>
</ion-content>
<ion-content class="ion-padding" *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="createJob()" color="primary" checked *ngIf="!submitting">
                <ion-label>
                    Create Job
                </ion-label>
            </ion-segment-button>
            <ion-segment-button *ngIf="submitting">
                <ion-spinner></ion-spinner>
            </ion-segment-button>
            <ion-segment-button (click)="dismissModal()" color="light" *ngIf="!submitting">
                <ion-label>Cancel</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>