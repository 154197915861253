import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import * as _ from "lodash";
import { BrowserHelperService } from '../../services/browser/browser-helper.service';
import { MultiFileUploadComponent } from './multi-file-upload.component';
import { NativeMultiFileUploadComponent } from './native-multi-file-upload.component';
import { MultiPlatformUploadOptions, UploadedFile } from '../../models/multi-file-upload-models';

@Component({
  selector: 'multi-platform-multi-file-upload',
  templateUrl: './multi-platform-multi-file-upload.component.html'
})
export class MultiPlatformFileUploadComponent implements AfterViewInit {
  @Input()
  label: string = "Attach Additional Files";
  @Input()
  options: MultiPlatformUploadOptions;
  @Input()
  hideUploadButton: boolean;
  @Input()
  uploadedFiles: UploadedFile[] = [];
  isNative: boolean = false;
  @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @ViewChild(MultiFileUploadComponent, { static: false }) multiFileUploadComponent: MultiFileUploadComponent;
  @ViewChild(NativeMultiFileUploadComponent, { static: false }) nativeMultiFileUploadComponent: NativeMultiFileUploadComponent;

  constructor(
    private browserHelperService: BrowserHelperService,
    private readonly viewRef: ViewContainerRef
  ) {
    this.isNative = browserHelperService.isNativeApp();
  }

  public get nativeElement() { return this.viewRef.element.nativeElement };

  ngAfterViewInit() {
    this.finishedLoading.emit(true);
 }

  reloadUploadedFiles(files: UploadedFile[]) {
    if (this.isNative) {
      this.nativeMultiFileUploadComponent.setFiles(files);
    } else {
      this.multiFileUploadComponent.setFiles(files);
    }
  }

  getFiles(): UploadedFile[] {
    if (this.isNative) {
      return this.nativeMultiFileUploadComponent.getFiles();
    } else {
      return this.multiFileUploadComponent.getUploadedFiles();
    }
  }

  isUploading(): boolean {
    if (this.isNative) {
      return false;
    } else {
      return this.multiFileUploadComponent.isUploading();
    }
  }

  open(event: Event) {
    if (this.isNative) {
      this.nativeMultiFileUploadComponent.open(event);
    } else {
      this.multiFileUploadComponent.open();
    }
  }

  openGalleryPicker() {
    if(this.isNative){
      this.nativeMultiFileUploadComponent.openGalleryPicker();
    }
  }

  hasFiles() {
    if (this.isNative) {
      return this.nativeMultiFileUploadComponent?.hasFiles();
    } else {
      return this.multiFileUploadComponent?.hasFiles();
    }
  }
}