import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { MemberLookup, LookupListItem } from "../assets.models";
import { SiteAsset, SiteAssetAnswer } from "../../../app/data/entities/SiteAsset";
import * as _ from "lodash";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { IonInput, IonDatetime, IonModal } from "@ionic/angular";
import { OMSGroupSelectComponent } from "../shared/components/oms-group-select.component";
import { Facility } from "src/app/data/entities/Facility";
import { AssetsService } from "../assets.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import * as moment from "moment";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { Guid } from "guid-typescript";

@Component({
  selector: "asset-item-data-answer",
  templateUrl: "asset-item-data-answer.component.html"
})
export class AssetItemDataAnswerComponent implements OnInit, AfterViewInit {
  @Input() facility: Facility;
  @Input() asset: SiteAsset;
  @Input() item: SiteAssetAnswer;
  @Input() memberLookup: MemberLookup;
  @Input() checklistId: number;
  @Input() preview: boolean;

  id: string;

  constructor(
    private cameraHelperService: CameraHelperService,
    private assetsService: AssetsService,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private previewService: PreviewService
  ) { }

  private isInitialized: boolean = false;
  ngOnInit() {
    if (this.item.FieldDataType == "datecode" && this.item.DataDate) {
      this.item.DataDate = moment(this.item.DataDate).format("YYYY-MM-DD");
    }
    this.id = Guid.create().toString();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setNotOnList();
    }, 10);
    setTimeout(() => {
      this.isInitialized = true;
    }, 1000);
  }

  async takePhoto(siteAssetAnswer: SiteAssetAnswer, event: Event): Promise<void> {
    event.preventDefault();
    if (WebAppShowComponent.IsWebAppIFrame && window.parent) {
      window.parent.postMessage({
        message: "open-image-gallery-web",
        siteKey: this.asset.SiteKey,
        siteAssetKey: this.asset.Id,
        assetItemId: siteAssetAnswer.SiteAssetDataItemConfigurationId
      }, "*")
      return;
    }

    let fileName = this.cameraHelperService.getFileName({
      ChecklistId: this.checklist,
      SiteAssetId: this.asset.Id,
      SiteAssetDataItemConfigurationId: siteAssetAnswer.SiteAssetDataItemConfigurationId
    });
    let image = await this.cameraHelperService.takePhoto(fileName);
    if (image) {
      siteAssetAnswer.ImageCount = (siteAssetAnswer.ImageCount || 0) + 1;
      this.markAnswerAsUpdated();
      this.assetsService.updateFacilityNoSyncNeeded(this.facility);
    }
  }

  async scan(siteAssetAnswer: SiteAssetAnswer, event: Event): Promise<void> {
    const text = await this.barcodeScannerHelperService.scan();
    if (text) {
      siteAssetAnswer.Data = text;
      this.markAnswerAsUpdated();
    }
  }

  changeSelect(event: any, list: LookupListItem[]) {
    this.markAnswerAsUpdated();
    var value = event.detail.value;
    var item = _.find(list, item => item.Value === value);
    if (item) {
      this.item.AnswerText = item.Text;
      return;
    }
    this.item.AnswerText = null;
  }

  @ViewChild("selectList1") select1: any;
  @ViewChild("selectList2") select2: any;
  @ViewChild("ionInput") ionInput: IonInput;
  @ViewChild("ionDateModal") ionDateModal: IonModal;
  @ViewChild("omsGroupSelect") omsGroupSelect: OMSGroupSelectComponent;

  focusSelect1() {
    this.select1.focus();
  }
  focusSelect2() {
    this.select2.focus();
  }
  focusElement() {
    if (this.select1) {
      this.select1.open();
    } else if (this.select2) {
      this.select2.open();
    } else if (this.ionInput) {
      this.ionInput.setFocus();
    } else if (this.ionDateModal) {
      this.ionDateModal.present();
    } else if (this.omsGroupSelect) {
      this.omsGroupSelect.openModel();
    }
  }

  onAnswerChange() {
    if (this.isInitialized) {
      this.markAnswerAsUpdated();
    }
  }

  async onDateSelected(datePicker: IonDatetime) {
    await datePicker.confirm(true);
    if (!datePicker.value) {
      await datePicker.reset(new Date().toISOString());
      await datePicker.confirm(true);
    }
  }

  onDateCodeAnswerChange() {
    if (this.isInitialized) {
      this.markAnswerAsUpdated();
      if (this.item.DataDate) {
        //Workaround for bug in ionic 4 date picker when format is only month and year, day is hidden
        //And it's the 30th of March for example and you select February, it actually keep the 30th as the day which makes that date invalid.
        //Remove the fix after updating to newer ionic and testing that it is fixed.
        this.item.DataDate = this.item.DataDate
          .replace("-29T", "-01T")
          .replace("-30T", "-01T")
          .replace("-31T", "-01T");
        this.item.DataDate = moment(this.item.DataDate).startOf('month').format("YYYY-MM-DD");
        this.item.AnswerText = moment(this.item.DataDate).format("MM-YYYY");
      } else {
        this.item.AnswerText = null;
      }
      this.assetsService.setAssetsInfo([this.asset]);
      this.asset.Calc = this.assetsService.getSiteAssetCalcInfo(this.asset, this.facility);
    }
  }

  onInstallDateAnswerChange() {
    if (this.isInitialized) {
      this.markAnswerAsUpdated();
      this.asset.Calc = this.assetsService.getSiteAssetCalcInfo(this.asset, this.facility);
    }
  }

  onBatteryStartPositionChange() {
    if (this.isInitialized) {
      this.markAnswerAsUpdated();
      this.item.AnswerText = String(this.item.DataFloat || '');
      this.assetsService.setAssetsInfo([this.asset]);
      this.asset.Calc = this.assetsService.getSiteAssetCalcInfo(this.asset, this.facility);
    }
  }

  clearDate() {
    this.item.DataDate = null;
  }

  private markAnswerAsUpdated() {
    this.item.Updated = true;
    this.asset.Updated = true;
    this.setNotOnList();
  }

  private setNotOnList() {
    if ((this.item.FieldDataType == "battery-model" || this.item.FieldDataType == "ups-model")) {
      var submittedText = " - Item Submitted";
      this.item.AnswerText = (this.item.AnswerText || "").replace(submittedText, "");
      if (this.item.DataFloat != -1) {
        this.item.DataNote = null;
      } else if (this.item.DataNote && !this.item.DataNote.endsWith(submittedText)) {
        this.item.AnswerText += submittedText;
      }
    }
  }

  private get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }
}
