import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../user-service/user-service.service';
import { AppInfo } from 'src/app/globals/AppInfo';
import { from } from 'rxjs';
import { WebAppShowComponent } from 'src/app/webapp/web-app.component';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let headers = await this.getHeaders();
    const authReq = req.clone({
      setHeaders: headers
    });

    return next.handle(authReq).toPromise();
  }

  private async getHeaders() {
    let userToken = await this.userService.getUserToken();
    return {
      deviceuuid: AppInfo.UUID || "",
      version: AppInfo.Version || "",
      platform: AppInfo.Platform || "",
      token: userToken || "",
      isWebApp: String(WebAppShowComponent.IsWebAppIFrame || "false")
    };
  }
}
