import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { AlertController, ToastController } from "@ionic/angular";
import { ApiService } from "../api/api.service";
import { BaseResponse } from "../../models/base-response-model";
import { Subscription } from "rxjs";
import { PubSubService } from "../pubsub/pub-sub.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import * as _ from "lodash";

@Injectable()
export class ErrorHandlerService implements OnInit, OnDestroy {
  private static errors: any[] = [];
  constructor(
    private alertController: AlertController,
    private apiService: ApiService,
    private pubSub: PubSubService,
    private browserHelperService: BrowserHelperService,
    private toastController: ToastController) { }

  private subscription: Subscription;
  public static readonly defaultMessage: string =
    "An error occurred while accessing OMS server. Make sure you have a stable connection or try to restart the app. If that doesn't fix the issue please contact support.";

  ngOnInit() {
    this.subscription = this.pubSub.$sub(EventsConstants.LOG_ERROR, (err) => {
      this.logError(err);
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async showErrorMessage(
    err: any,
    message: string = ErrorHandlerService.defaultMessage
  ): Promise<HTMLIonAlertElement> {
    const errorAlert = await this.alertController.create({
      header: "Error",
      message: message,
      buttons: ["Okay"]
    });
    if (err) {
      this.logError(err);
    }
    await errorAlert.present();
    return errorAlert;
  }

  async logErrorAndShowGenericToast(err) {
    this.logError(err);
    const toast = await this.toastController.create({
      message: ErrorHandlerService.defaultMessage,
      position: "bottom",
      duration: 10000
    });
    await toast.present();
  }

  logError(err: any) {
    console.error(err);
    try {
      ErrorHandlerService.errors.push(JSON.stringify(err));
    } catch { }
  }

  public static log(err: any) {
    try {
      ErrorHandlerService.errors.push(JSON.stringify(err));
    } catch { }
  }

  async sendLogs(obj: any = {}) {
    if (!obj) {
      obj = {};
    }
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return;
    }
    else if (ErrorHandlerService.errors.length == 0 && _.isEmpty(obj)) {
      alert("You have nothing to send");
    } else {

      try {
        await this.apiService.ping();
      } catch (err1) {
        this.logErrorAndShowGenericToast(err1);
        return;
      }

      try {
        await this.sendLogsBackground(obj);
        const errorAlert = await this.alertController.create({
          header: "Success",
          message: "Logs have been sent! ",// + JSON.stringify(ErrorHandlerService.errors),
          buttons: ["Okay"]
        });
        await errorAlert.present();
      } catch (err) {
        alert("Error occurred: " + JSON.stringify(err));
      }
    }
  }

  async sendLogsBackground(obj: any = {}): Promise<void> {
    if (!obj) {
      obj = {};
    }
    if (ErrorHandlerService.errors.length == 0 && _.isEmpty(obj)) {
      return;
    }

    obj.errors = ErrorHandlerService.errors;
    await this.apiService.postAnonimous<BaseResponse>("site/sendlogs", {
      logs: JSON.stringify(obj)
    });
    ErrorHandlerService.errors = [];
  }
}
