import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { Facility } from "src/app/data/entities/Facility";
import { ActivatedRoute } from "@angular/router";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { BatteryTestService } from "../../battery-test.service";
import { BatteryChemistry, ParsedBatteryTestModel } from "../../battery-test.models";
import { ModalController, ToastController, Platform } from "@ionic/angular";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";

@Component({
  selector: "battery-test-new",
  templateUrl: "battery-test-new.component.html"
})
export class BatteryTestNewComponent implements OnInit, OnDestroy {
  @Input()
  facility: Facility;
  @Input()
  checklistId: number;
  @Input()
  assetId: string;
  @Input()
  chemistries: BatteryChemistry[];
  @Input()
  addedDuringReplace: boolean;
  @Input()
  fromDialog: boolean;
  @Input()
  dateCode: string;
  @Input()
  batteryId: number;
  @Input()
  vendorViewId: number;

  refreshBattery: boolean = false;
  loading: boolean = false;
  data: ParsedBatteryTestModel;
  processErrorMessage: string;

  showUploadButton: boolean = false;
  alreadyOpened: boolean = false;

  @ViewChild('fileInput') fileInput;

  constructor(
    private batteryTestService: BatteryTestService,
    private route: ActivatedRoute,
    private pubSub: PubSubService,
    private userService: UserService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private toastController: ToastController,
    private pubSubService: PubSubService,
    private platform: Platform,
  ) {
  }

  async ngOnInit() {
    if (this.batteryId || this.dateCode) {
      this.refreshBattery = true;
    }
  }

  ngOnDestroy() {
  }

  ionViewDidEnter() {
    this.showUploadButton = window["safari"] != undefined || this.platform.is("ios");
    this.alreadyOpened = false;
    if (!this.showUploadButton) {
      setTimeout(() =>{ 
        this.fileInput.nativeElement.click();
      }, 100);
    }
  }

  selectFile() {
    this.fileInput.nativeElement.click();
    this.alreadyOpened = true;
  }

  async save() {
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      let formData = this.getFormData();

      let response = await this.batteryTestService.addTest(formData);
      if (response.Success) {
        let toast = await this.toastController.create({
          message: "Tests Added",
          duration: 2000,
          color: "success"
        });
        await toast.present();
        this.onSuccessfullySaved();
        this.dismissModal();
      } else {
        this.processErrorMessage = response.Message;
      }
    } finally {
      this.loading = false;
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  private fileToUpload: any = null;
  onFileChange(fileChangeEvent: any) {
    this.fileToUpload = fileChangeEvent.target.files[0];
    this.clearData();
    this.fileInput.nativeElement.value = "";
    this.processFile();
  }

  private clearData() {
    this.data = null;
  }

  private onSuccessfullySaved() {
    this.pubSubService.$pub(EventsConstants.ADD_BATTERY_TESTS, {
      assetId: this.data.BatteryTest.SiteAssetId
    });
    this.pubSubService.$pub(EventsConstants.ADD_UPDATE_BATTERY_TESTS);
    if (window.parent) {
      window.parent.postMessage({ message: "sync-done" }, "*")
    }
  }
  private async processFile() {
    this.processErrorMessage = null;
    try {
      this.loading = true;
      if (!(await this.validate())) return;
      let formData = this.getFormData();
      let response = await this.batteryTestService.processFile(formData);
      if (response.Success) {
        this.data = response.Data;
        if (this.dateCode) {
          this.data.BatteryTest.DateCode = this.dateCode;
        }
        if (this.batteryId) {
          this.data.BatteryTest.BatteryId = this.batteryId;
        }
      } else {
        this.processErrorMessage = response.Message;
      }
    } finally {
      this.loading = false;
    }
  }

  private async validate(): Promise<boolean> {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return false;
    }
    let message = null;
    if (!this.fileToUpload) {
      message = "You need to select a file first."
    }

    if (message) {
      let toast = await this.toastController.create({
        message: message,
        duration: 2000,
        color: "danger"
      });
      await toast.present();
      return false;
    }
    return true;
  }

  private getFormData(): FormData {
    let formData = new FormData();
    // Add the file that was just added to the form data
    formData.append("file", this.fileToUpload);
    formData.append("checklistId", this.checklistId.toString());
    formData.append("assetId", this.assetId);
    formData.append("addedDuringReplace", this.addedDuringReplace.toString());
    if (this.vendorViewId) {
      formData.append("vendorViewId", this.vendorViewId.toString());
    }
    if (this.data && this.data.BatteryTest) {
      this.data.BatteryTest.SiteCheckListId = this.checklistId;
      this.data.BatteryTest.SiteAssetId = this.assetId;
      this.data.BatteryTest.AddedDuringReplace = this.addedDuringReplace;
      if (this.vendorViewId) {
        this.data.BatteryTest.VendorViewId = this.vendorViewId;
      }
      formData.append("model", JSON.stringify(this.data));
    }

    return formData;
  }
}