<ion-header>
    <ion-toolbar>
        <ion-title class="ion-padding-top ion-padding-bottom" *ngIf="!templateCategoryVisible && !model.Id">
            New Warehouse Build - {{model.TemplateDescription}}
        </ion-title>
        <ion-title class="ion-padding-top ion-padding-bottom" *ngIf="templateCategoryVisible && !model.Id">
            New Warehouse Build
        </ion-title>
        <ion-title class="ion-padding-top ion-padding-bottom" *ngIf="model.Id > 0">
            Update Warehouse Build #{{model.Id}}
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" *ngIf="!loading">
    <h2 *ngIf="templateCategoryVisible && templates.length > 0" class="ion-text-center">
        Select Build
    </h2>
    <ion-list *ngIf="templateCategoryVisible">
        <ion-item *ngFor="let template of templates" (click)="templateCategoryChanged(template)">
            <ion-label>{{template.TemplateDescription}}</ion-label>
        </ion-item>
    </ion-list>
    <div *ngIf="templateCategoryVisible && templates.length == 0" class="ion-padding">
        You don't have any templates configured. You can go to OMS>Templates>Warehouse Build Templates to configure. If you don't have this option available then you have to contact OMS support to enable it.
        <ion-button (click)="dismissModalWithoutPrompt()">Close</ion-button>
    </div>
    <ion-list *ngIf="!templateCategoryVisible">
        <ion-item class="custom-input-ion-item" *ngFor="let item of model.Items" #ionItem>
            <ng-container [ngSwitch]="item.DataType">
                <ion-note position="fixed" slot="start" (click)="input.focus()">
                    {{item.DisplayName}}
                </ion-note>
                <ion-input *ngSwitchCase="'scan'" type="text" [(ngModel)]="item.Data" placeholder="Scan or type in...">
                </ion-input>
                <ion-input type="text" *ngSwitchDefault [(ngModel)]="item.Data" placeholder="Type in...">
                </ion-input>
                <div slot="end" *ngIf="item.DataType == 'scan'" (click)="scanAnswer(item, ionItem)">
                    <span tabindex="1" class="battery-item-photo" style="z-index: 5;">
                        <ion-icon name="barcode-outline"></ion-icon>
                    </span>
                </div>
            </ng-container>
        </ion-item>

        <ion-item class="custom-input-ion-item">
            <ion-note position="fixed" slot="start">
                Build description (optional)
            </ion-note>
            <ion-textarea [(ngModel)]="model.Description" placeholder="Type in..."></ion-textarea>
        </ion-item>
    </ion-list>
</ion-content>
<ion-content class="ion-padding" *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ion-content>
<ion-footer *ngIf="!templateCategoryVisible">
    <ion-toolbar>
        <ion-segment>
            <ion-segment-button (click)="create()" color="primary" checked *ngIf="!submitting">
                <ion-label *ngIf="model.Id == 0">
                    Create
                </ion-label>
                <ion-label *ngIf="model.Id > 0">
                    Update
                </ion-label>
            </ion-segment-button>
            <ion-segment-button *ngIf="submitting">
                <ion-spinner></ion-spinner>
            </ion-segment-button>
            <ion-segment-button (click)="dismissModal()" color="light" *ngIf="!submitting">
                <ion-label>Cancel</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-toolbar>
</ion-footer>