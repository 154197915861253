<oms-header [title]="getPageTitle()" [subtitle]="getPageSubtitle()">
  <ion-back-button defaultHref="/facilities" *ngIf="showBackButton"></ion-back-button>
</oms-header>

<ion-content class="ion-padding">
  <ng-container *ngIf="!loading">
    <ion-card *ngIf="getAssetsCount() === 0" class="ion-text-center">
      <ion-card-header>
        <ion-card-title *ngIf="currentAsset != null">Assets in {{currentAsset.Description}}</ion-card-title>
        <ion-card-title *ngIf="currentAsset == null && facility != null">Assets in {{facility.Name}}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="ion-padding-bottom" *ngIf="currentAsset != null">
          Click
          <ion-fab-button size="small" class="help-assets-button" (click)="presentActionSheet(false)">
            <ion-icon name="add"></ion-icon>
          </ion-fab-button> to add an asset to {{currentAsset.SiteAssetTypeDescription}}
          <b>{{currentAsset.Description}}</b>.
        </div>
        <div class="ion-padding-bottom" *ngIf="currentAsset == null && facility != null">
          You haven't added any assets yet to the Facility <b>{{facility.Name}}</b>.
        </div>
      </ion-card-content>
    </ion-card>
    <asset-data-overview *ngIf="getAssetsCount() > 0" [facility]="facility" [currentAsset]="currentAsset" [checklistId]="checklistId" [preview]="preview">
    </asset-data-overview>
  </ng-container>
  <ion-fab vertical="bottom" horizontal="start" slot="fixed" *ngIf="!loading && !preview">
    <ion-fab-button *ngIf="getAssetsCount() > 0" (click)="presentHelpPopover()" color="light">
      <ion-icon name="information"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="!loading && !preview && canAddItems">
    <ion-row>
      <ion-col *ngIf="hasRootItems">
        <ion-fab-button (click)="presentActionSheet(true)">
          <ion-icon name="cube"></ion-icon>
        </ion-fab-button>
      </ion-col>
      <ion-col *ngIf="hasNonRootItems">
        <ion-fab-button (click)="presentActionSheet(false)">
          <ion-icon name="add"></ion-icon>
        </ion-fab-button>
      </ion-col>
    </ion-row>
  </ion-fab>
  <ng-container *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ng-container>
</ion-content>