import { Component, Input, OnInit } from "@angular/core";
import { SiteAsset, SiteAssetBattery } from "src/app/data/entities/SiteAsset";
import { PopoverController, ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { Facility } from "src/app/data/entities/Facility";
import { BatteryDataComponent } from "../battery-data.component";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "battery-option-selector",
  templateUrl: "battery-option-selector.component.html"
})
export class BatteryOptionSelectorComponent implements OnInit {
  @Input()
  battery: SiteAssetBattery;
  @Input()
  facility: Facility;
  @Input()
  asset: SiteAsset;
  @Input()
  checklistId: number;
  preview: boolean;
  isVendor: boolean;

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private router: Router,
    private previewService: PreviewService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.isVendor = await this.userService.isVendor();
  }

  async editBattery() {
    const modal = await this.modalController.create({
      component: BatteryDataComponent,
      componentProps: {
        asset: this.asset,
        facility: this.facility,
        checklistId: this.checklistId,
        batteryId: this.battery.Id,
        preview: this.preview
      }
    });
    this.popoverController.dismiss();
    return await modal.present();
  }

  async replaceBattery() {
    const modal = await this.modalController.create({
      component: BatteryDataComponent,
      componentProps: {
        asset: this.asset,
        facility: this.facility,
        batteryId: this.battery.Id,
        replace: true,
        preview: this.preview
      }
    });
    this.popoverController.dismiss();
    return await modal.present();
  }

  async getQuote() {
    try {
      await this.popoverController.dismiss();
    } catch { }
    try {
      await this.modalController.dismiss();
    } catch { }
    try {
      await this.modalController.dismiss();
    } catch { }
    this.router.navigate([`/facilities/${this.asset.SiteKey}/quotes/${this.checklist}/highlightbattery/${this.battery.BatteryKey}`]);
  }

  get checklist() {
    return this.checklistId || this.facility.SiteGalleryChecklistId;
  }
}
