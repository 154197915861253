import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../my-work.service";
import { AlertController, IonInput, IonSelect, IonTextarea, ModalController, ToastController } from "@ionic/angular";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { NewJobAssetModel, NewJobModel } from "../my-work.models";
import { AvailableScheduleVendorData, LookupListGroup, SelectedAsset } from "src/app/assets/assets.models";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";
import { NewCustomerProjectLocationComponent } from "../new-customer-project-location/new-customer-project-location.component";
import * as EmailValidator from "email-validator";
import * as moment from "moment";
import { FacilityService } from "src/app/assets/facility.service";
import { AssetSchedulingSelectionComponent } from "src/app/assets/asset-scheduling-selection/asset-scheduling-selection.component";

@Component({
  selector: "new-job-calendar",
  templateUrl: "new-job-calendar.component.html"
})
export class NewJobCalendarComponent implements OnInit {
  @Input()
  model: NewJobModel = { CustomerLocationProject: {}, ReleaseJob: true } as any;
  customers: LookupListGroup[] = [];
  templates: LookupListGroup[] = [];
  projects: LookupListGroup[] = [];
  selectedVendorData: AvailableScheduleVendorData;
  vendors: AvailableScheduleVendorData[] = [];
  loading: boolean = false;
  templateCategoryVisible: boolean;
  customerListVisible: boolean;
  submitting: boolean;
  isTech: boolean;
  loadingAssets: boolean;
  assetsLoaded: boolean = false;

  @ViewChild("poNumberInput") poNumberInput: IonInput;
  @ViewChild("soNumberInput") soNumberInput: IonInput;
  @ViewChild("referenceNumberInput") referenceNumberInput: IonInput;
  @ViewChild("workOrderNumberInput") workOrderNumberInput: IonInput;

  @ViewChild("equipmentNameInput") equipmentNameInput: IonInput;
  @ViewChild("batteryQtyNumberInput") batteryQtyNumberInput: IonInput;
  @ViewChild("notesInput") notesInput: IonTextarea;

  @ViewChild("workOrderNumberInput") additionalTechSelectList: IonSelect;
  @ViewChild("workOrderNumberInput") alsoNotifySelectList: IonSelect;
  @ViewChild("workOrderNumberInput") leadTechSelectList: IonSelect;
  @ViewChild("serviceProviderSelectList") serviceProviderSelectList: IonSelect;

  @ViewChild("customerLocationProject") customerLocationProject: NewCustomerProjectLocationComponent;

  duplicateJob: boolean = false;

  constructor(
    private myWorkService: MyWorkService,
    private toastController: ToastController,
    private router: Router,
    private userService: UserService,
    private modalController: ModalController,
    private alertController: AlertController,
    private browserHelperService: BrowserHelperService,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService
  ) { }

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    try {
      await this.modalController.dismiss();
      await this.modalController.dismiss();
    } catch { }
    this.loading = true;

    this.templateCategoryVisible = true;
    this.model = {
      CustomerType: "D",
      LocationInfo: {},
      AdditionalTechnicianIds: [],
      AlsoNotifyIds: [],
      Assets: [],
      CustomerLocationProject: {},
      ReleaseJob: true
    } as any;

    this.isTech = await this.userService.isTechnician();
    this.model.Date = this.activatedRoute.snapshot.params["jobDate"] || this.model.Date;
    this.model.QueueItemId = parseInt(this.activatedRoute.snapshot.params["queueItemId"] || this.model.QueueItemId);


    const duplicateFromJobId = this.activatedRoute.snapshot.params["duplicateFromJobId"];

    if (duplicateFromJobId) {
      this.model = await this.myWorkService.getJobToDuplicate(duplicateFromJobId);
      this.duplicateJob = true;
    }

    if (this.model.QueueItemId > 0) {
      await this.loadModelFromQueueData();
      if (this.customerLocationProject) {
        await this.customerLocationProject.loadData();
      }
      await this.loadSubVendors();
    } else {
      if (this.customerLocationProject) {
        await this.customerLocationProject.loadData();
      }
      this.loadSubVendors();
    }

    this.loading = false;
  }

  async ionViewDidEnter() {
    if (this.customerLocationProject) {
      if (this.duplicateJob) {
        await this.customerLocationProject.loadData();
        await this.customerLocationProject.loadProjects();
      }
    }
    if (!this.duplicateJob && !this.model.QueueItemId) {
      this.customerLocationProject?.focusCustomerList();
    }
  }

  focusLeadTechnician() {
    this.leadTechSelectList.open();
  }

  focusEquipmentName() {
    this.equipmentNameInput.setFocus();
  }

  focusBatteryQty() {
    this.batteryQtyNumberInput.setFocus();
  }

  focusNotes() {
    this.notesInput.setFocus();
  }

  focusAdditionalTechnicians() {
    this.additionalTechSelectList.open();
  }

  focusAlsoNotify() {
    this.alsoNotifySelectList.open();
  }

  focusServiceProvider() {
    this.serviceProviderSelectList.open();
  }

  focusPoNumber() {
    this.poNumberInput.setFocus();
  }

  focusSoNumber() {
    this.soNumberInput.setFocus();
  }

  focusReferenceNumber() {
    this.referenceNumberInput.setFocus();
  }

  focusWorkOrderNumber() {
    this.workOrderNumberInput.setFocus();
  }

  async scanPoNumber() {
    this.scan(() => this.focusPoNumber(), value => this.model.PONumber = value);
  }

  async scanEquipmentName() {
    this.scan(() => this.focusEquipmentName(), value => this.model.EquipmentName = value);
  }

  async scanSoNumber() {
    this.scan(() => this.focusSoNumber(), value => this.model.SONumber = value);
  }

  async scanReferenceNumber() {
    this.scan(() => this.focusReferenceNumber(), value => this.model.ReferenceNumber = value);
  }

  async scanWorkOrderNumber() {
    this.scan(() => this.focusWorkOrderNumber(), value => this.model.WorkOrderNumber = value);
  }

  private async scan(handHeldCallback: () => void, scanCallback: (value: string) => {}) {
    if (await this.checkForHandHeldScanner()) {
      handHeldCallback();
    } else {
      const text = await this.barcodeScannerHelperService.scan();
      if (text) {
        scanCallback(text);
      }
    }
  }

  private async checkForHandHeldScanner() {
    if (!this.browserHelperService.isNativeApp()) {
      await this.notifyMessage(`If you have hand held scanner you can use it to scan now`);
      return true;
    }
    return false;
  }

  private async notifyMessage(message: string, color: "info" | "danger" = "info") {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: "bottom",
      duration: 3000,
      cssClass: "new-job-hand-held-scanner-toast"
    });
    await toast.present();
  }

  onVendorChange(vendorId) {
    this.model.VendorId = vendorId;
    this.selectedVendorData = _.find(this.vendors, v => v.Value == this.model.VendorId);
    if (this.selectedVendorData) {
      this.selectedVendorData.TechnicianList = this.selectedVendorData.TechnicianList || [];
      if (!this.selectedVendorData.TechnicianList.some(x => x.Value == this.model.TechnicianId)) {
        this.model.TechnicianId = null;
      }
      const additionalTechList = this.selectedVendorData.TechnicianList;
      this.model.AdditionalTechnicianIds = this.model.AdditionalTechnicianIds.filter(x => additionalTechList.some(y => y.Value == x));
      this.model.AlsoNotifyIds = (this.model.AlsoNotifyIds || []).filter(x => additionalTechList.some(y => y.Value == x));
    } else {
      this.model.TechnicianId = null;
      this.model.AdditionalTechnicianIds = [];
      this.model.AlsoNotifyIds = [];
    }
  }

  async createJob() {
    if (!(await this.validate())) return;
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    try {
      this.submitting = true;
      this.model.ReleaseJob = typeof (this.model.ReleaseJob) == "string" ? <any>this.model.ReleaseJob === "true" : this.model.ReleaseJob;
      var response = await this.myWorkService.createCalendarJob(this.model);
      if (response.Success) {
        const checklistId = response.Data;
        window.parent.postMessage({ message: "sync-done" }, "*");
        const alert = await this.alertController.create({
          header: "Job successfully created",
          subHeader: "What do you want to do next?",
          buttons: [
            {
              text: "Create another job like this one",
              handler: () => {
                alert.dismiss();
              }
            },
            {
              text: "Create another job from scratch",
              handler: () => {
                alert.dismiss();
                this.router.navigate([`/mywork/createcalendarjob/${this.model.Date}/0/version/${new Date().getTime()}`], { replaceUrl: true });
              }
            },
            {
              text: "Close",
              role: "cancel",
              handler: () => {
                window.parent.postMessage({ message: "calendar-job-created", jobId: checklistId }, "*");
                window.parent.postMessage({ message: "sync-done" }, "*");
              }
            }
          ]
        });
        await alert.present();
      } else {
        this.submitting = false;
        this.notifyMessage(`There was an error while creating job: ${response.Message}`, "danger");
      }
    } catch {
      this.notifyMessage("There was an error while creating job", "danger");
    } finally {
      this.submitting = false;
    }
  }

  deleteAsset(asset: NewJobAssetModel, index: number) {
    this.model.Assets.splice(index, 1);
  }

  assetsCommaSeparated() {
    return this.model.Assets.map(x => x.AssetName).join(", ");
  }

  onLocationChanged(){
    this.assetsLoaded = false;
    this.model.Assets = [];
  }

  async dismissModal() {
    const alert = await this.alertController.create({
      header: "Are you sure you want to cancel?",
      buttons: [
        {
          text: "Yes, I am sure",
          handler: async () => {
            this.dismiss();
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        },
      ]
    });
    return await alert.present();
  }

  toNumeric(value) {
    return parseInt(value);
  }

  async selectAssets() {
    if(this.loadingAssets) return; 
    const siteKey = this.model.CustomerLocationProject?.LocationInfo?.SiteKey;
    if (siteKey) {
      this.loadingAssets = true;
      if(!this.assetsLoaded){
        await this.facilityService.reloadMyFacilitiesAndAssets([siteKey]);
        this.assetsLoaded = true;
      }
      this.loadingAssets = false;
      const modal = await this.modalController.create({
        component: AssetSchedulingSelectionComponent,
        componentProps: {
          facilityId: siteKey,
          assetIds: this.model.Assets.map(x => x.AssetId).join(","),
          onSelected: (assets: SelectedAsset[]) => {
            this.model.Assets = assets.map(x => {
              return {
                AssetId: x.Id,
                AssetName: x.Name
              }
            });
          }
        }
      });
      modal.present();
    } else {
      alert("You have to select location");
    }
  }

  private async dismiss(): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "close-modal" }, "*");
    } else {
      await this.modalController.dismiss();
    }
  }
  private async validate(): Promise<boolean> {
    var message = null;
    const customerLocationProjectValidate = this.customerLocationProject.validate();
    if (!customerLocationProjectValidate.valid) {
      message = customerLocationProjectValidate.message;
    }
    else if ((this.model.VendorId || 0) == 0) {
      message = "Vendor is required";
    } else if (this.model.VendorId == -1) {
      if ((this.model.ServiceProviderName || "").length == 0) {
        message = "New Service Provider Name is required";
      } else if (!EmailValidator.validate(this.model.ServiceProviderUserEmail)) {
        message = "New Service Provider User Email is required";
      } else if ((this.model.ServiceProviderUserFirstName || "").length == 0) {
        message = "New Service Provider User First Name is required";
      } else if ((this.model.ServiceProviderUserLastName || "").length == 0) {
        message = "New Service Provider User Last Name is required";
      }
    } else if ((this.model.TechnicianId || 0) == 0) {
      message = "Lead Technician is required";
    }
    else if (this.model.Date && this.model.DueDate) {
      if (moment(this.model.DueDate) < moment(this.model.Date)) {
        message = "Due Date can't be before Job Schedule Date";
      }
    }
    if (message) {
      await this.notifyMessage(message, "danger");
      return false;
    }
    return true;
  }

  private async loadSubVendors() {
    try {
      this.vendors = await this.myWorkService.getSubVendors();
      var myMember = this.vendors.find(x => x.IsMyMember);
      if (myMember) {
        this.model.VendorId = myMember.Value;
        this.onVendorChange(this.model.VendorId);
      }
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Vendors`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }

  private async loadModelFromQueueData() {
    try {
      var date = this.model.Date;
      var queueItemId = this.model.QueueItemId;
      this.model = await this.myWorkService.getModelFromQueue(this.model.QueueItemId);
      this.model.Date = date;
      this.model.QueueItemId = queueItemId
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Queue data`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }
}