<div class="ion-margin-top">
    <div class="project-name" style="font-size: 12px; text-align: left;"
        *ngIf="jobModel.Assets && jobModel.Assets.length > 0">
        <b style="padding-left: 1rem; font-size: 1rem;">Assets ({{(jobModel.Assets || []).length}})</b>
        <ul style="margin: 0.2rem; padding-left: 2.5rem; max-height: 63px; overflow-y: auto;">
            <li *ngFor="let asset of jobModel.Assets">
                <span [class.text-linethrough]="isAssetReplaced(asset)">
                    {{asset.Name}}
                </span>
                <span *ngIf="isAssetReplaced(asset)" class="color-success">
                    {{assetReplacedLabel(asset)}}
                </span>
                <span *ngIf="asset.BatteryTests > 0" class="color-success">
                    <ion-icon name="checkmark-done-outline" color="success" *ngIf="asset.BatteryTests > 0">
                    </ion-icon> (Test uploaded)
                </span>
            </li>
        </ul>
    </div>
    <ion-button color="success" slot="end" class="ion-margin-start ion-margin-end" *ngIf="!checkedIn()" (click)="checkIn()">End Preview - Start Job
    </ion-button>
    <ion-item lines="none">
        <ion-label (click)="openStatusHistory()">
            <b>Job Status: </b>
            <span [style.color]="jobModel.StatusColor" class="job-status-name">{{jobModel.StatusName}}</span>
            <div class="job-status-notes" *ngIf="jobModel.StatusNotes">
                <i class="fal fa-comment-lines"></i>
                {{jobModel.StatusNotes}}
            </div>
        </ion-label>
        <ion-button color="warning" slot="end" size="normal" *ngIf="checkedIn()" (click)="checkOut()">Stop
        </ion-button>
    </ion-item>
    <ion-item lines="none" *ngIf="correctionsNeeded">
        <ion-label style="justify-content: center; display: flex; font-size: 3rem;">
            <i class="fas fa-exclamation-triangle color-danger" (click)="correctionsNeededInfo($event)"></i>
        </ion-label>
    </ion-item>
</div>
<checklist-button *ngIf="!jobModel.IsSiteWalk" [facility]="facility" [checklistId]="jobModel.ChecklistId"
    title="Checklist" icon="fas fa-list" [preview]="!checkedIn()">
</checklist-button>
<checklist-button *ngIf="jobModel.IsSiteWalk" [facility]="facility" [checklistId]="facility.SiteWalkChecklistId"
    title="Site Walk" [showSummary]="false" [preview]="!checkedIn()">
</checklist-button>
<ion-card (click)="goToGallery()" *ngIf="!jobModel.IsSiteWalk" [class.my-work-action-inactive]="galleryUnavailable()">
    <ion-card-header class="ion-text-center">
        <ion-card-title>Gallery <span class="count">{{galleryCount}}</span></ion-card-title>
        <i class="fas fa-camera facility-icon"></i>
    </ion-card-header>
    <ion-card-content>
    </ion-card-content>
</ion-card>
<ion-card (click)="goToFiles()" [class.my-work-action-inactive]="jobModel.Documents.length == 0">
    <ion-card-header class="ion-text-center">
        <ion-card-title>Files <span class="count">{{jobModel.Documents.length}}</span></ion-card-title>
        <i class="far fa-file-alt facility-icon"></i>
    </ion-card-header>
    <ion-card-content>
    </ion-card-content>
</ion-card>
<ion-card (click)="additionalTasks()" [class.my-work-action-inactive]="jobModel.Projects.length == 0">
    <ion-card-header class="ion-text-center">
        <ion-card-title>More Jobs <span class="count">{{jobModel.Projects.length}}</span></ion-card-title>
        <i class="far fa-wrench facility-icon"></i>
    </ion-card-header>
    <ion-card-content>
    </ion-card-content>
</ion-card>
<assets-button *ngIf="jobModel.AssetsEnabled" [facility]="facility" [heatmap]="false"
    [checklistId]="jobModel.ChecklistId" [preview]="jobModel.CheckedIn != true || assetsPreviewOnly"></assets-button>
<ion-card (click)="timeTracking($event)" *ngIf="timeTrackingEnabled">
    <ion-card-header class="ion-text-center">
        <ion-card-title>Time and Mileage</ion-card-title>
        <i class="fal fa-clock facility-icon"></i>
    </ion-card-header>
    <ion-card-content>
    </ion-card-content>
</ion-card>
<ion-card (click)="moreInfo($event)">
    <ion-card-header class="ion-text-center">
        <ion-card-title>Job Details</ion-card-title>
        <i class="far fa-info facility-icon"></i>
    </ion-card-header>
    <ion-card-content>
    </ion-card-content>
</ion-card>

<div (click)="refreshJob()" class="newer-data-available" *ngIf="hasUpdates && !refreshing">
    <div>
        There is newer job data available.
    </div>
    <div *ngIf="pendingCount == 0">
        Tap here to refresh.
    </div>
    <div *ngIf="pendingCount > 0">
        Can't refresh, you have {{pendingCount}} pending change(s).
    </div>
</div>

<div class="newer-data-available" *ngIf="refreshing">
    Refreshing... <ion-spinner></ion-spinner>
</div>