import {
  Directive,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  HostListener
} from "@angular/core";
import { Keyboard } from "@ionic-native/keyboard/ngx";

const TAB_KEY_CODE = 9;
const ENTER_KEY_CODE = 13;

@Directive({
  selector: "[next-on-enter]" // Attribute selector
})
export class NextOnEnterDirective {
  @Output() input: EventEmitter<string> = new EventEmitter<string>();
  @Input("next-on-enter") nextIonInputId: any = null;

  constructor(public Keyboard: Keyboard, public elementRef: ElementRef) {}

  @HostListener("keydown", ["$event"])
  keyEvent(event) {
    if (event.srcElement.tagName !== "INPUT") {
      return;
    }

    var code = event.keyCode || event.which;
    if (code === TAB_KEY_CODE) {
      event.preventDefault();
      this.onNext();
      let previousIonElementValue = this.elementRef.nativeElement.children[0]
        .value;
      this.input.emit(previousIonElementValue);
    } else if (code === ENTER_KEY_CODE) {
      event.preventDefault();
      this.onEnter();
      let previousIonElementValue = this.elementRef.nativeElement.children[0]
        .value;
      this.input.emit(previousIonElementValue);
    }
  }

  onEnter() {
    if (!this.nextIonInputId) {
      return;
    }

    let nextInputElement = document.getElementById(this.nextIonInputId);

    // On enter, go to next input field
    if (nextInputElement && nextInputElement.children[0]) {
      let element: any = nextInputElement.children[0];
      if (element.tagName === "INPUT") {
        element.focus();
      }
    }
  }

  onNext() {
    if (!this.nextIonInputId) {
      return;
    }

    let nextInputElement = document.getElementById(this.nextIonInputId);

    // On enter, go to next input field
    if (nextInputElement && nextInputElement.children[0]) {
      let element: any = nextInputElement.children[0];
      if (element.tagName === "INPUT") {
        element.focus();
      }
    }
  }
}
