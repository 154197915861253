<ng-container *ngIf="!loading">
  <div class="ion-text-center">
    <ion-note>
      <ion-icon name="information-circle-outline"></ion-icon>
      Pull down to refresh
    </ion-note>
  </div>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingText="Pull down more to refresh" refreshingText="Refreshing...">
    </ion-refresher-content>
  </ion-refresher>
  <ion-list *ngIf="items.length > 0">
    <ion-item *ngFor="let item of items">
      <div class="ion-padding">
        <div>
          <b>#{{item.Id}}</b> {{item.TesterModel}} <em
            style="font-size: 12px; color: darkgray;">{{item.TesterVersion}}</em>
        </div>
        <div style="font-size: 12px; color: darkgray;">
          {{item.UserFullName}} at {{item.DateCreated}}
        </div>
      </div>
      <ion-button (click)="more(item, $event)" slot="end">
        &nbsp;<i class="far fa-ellipsis-v"></i>&nbsp;
      </ion-button>
    </ion-item>
  </ion-list>
  <ion-card *ngIf="items.length == 0">
    <ion-card-header>
      <ion-card-title>No tests added yet</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      You can add tests by clicking on the plus button on the bottom right
    </ion-card-content>
  </ion-card>
</ng-container>
<ion-list *ngIf="loading">
  <ion-item *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
    <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
  </ion-item>
</ion-list>