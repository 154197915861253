import { Component, OnInit, OnDestroy } from "@angular/core";
import { AssetsService } from "../../assets.service";
import { RepositoryService } from "../../../../app/data/db/repository.service";
import { Facility } from "../../../../app/data/entities/Facility";
import { Router } from "@angular/router";
import * as EventsConstants from "../../../shared/constants/events-constants";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ToastController } from "@ionic/angular";
import { SyncService } from "src/app/sync/sync.service";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { FacilityService } from "../../facility.service";
import { FacilitySearchModel } from "../../assets.models";
import { MyWorkService } from "src/app/my-work/my-work.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "facilities-search",
  templateUrl: "facilities-search.component.html"
})
export class FacilitiesSearchComponent implements OnInit, OnDestroy {
  facilities: FacilitySearchModel[] = [];
  downloadedFacilities: Facility[] = [];
  query: string = null;
  loading: boolean = false;
  downloading: boolean;
  isVendorTechnician: boolean;
  constructor(
    private facilityService: FacilityService,
    private repositoryService: RepositoryService,
    private browserHelperService: BrowserHelperService,
    private toastController: ToastController,
    private syncService: SyncService,
    private myWorkService: MyWorkService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.downloadedFacilities = await this.facilityService.getDownloadedFacilities();
    this.isVendorTechnician = await this.userService.isVendorTechnician();
  }

  ngOnDestroy() {
  }

  async search() {
    if(await this.browserHelperService.isOfflineAndMessage()){
      return;
    }
    this.loading = true;
    try {
      this.facilities = (await this.facilityService.searchFacilities(this.query)).Items;
      for (let facility of this.facilities) {
        facility.Downloaded = this.isDownloaded(facility);
      }
    } finally {
      this.loading = false;
    }
  }

  async download(facility: FacilitySearchModel) {
    if (facility.Downloaded || facility.Downloading) {
      return;
    }
    let downloadedFacilities = await this.facilityService.getDownloadedFacilities();
    if (downloadedFacilities.length >= 10) {
      alert("You can't have more than 10 downloaded locations. If you want to download this location, go to Downloaded tab and delete some locations.");
      return;
    }

    const pendingCount = await this.syncService.getPendingDataCount();
    if (pendingCount > 0) {
      alert("You have some pending data, please make sure that is uploaded first before doing this");
      return;
    }

    try {
      this.downloading = true;
      facility.Downloading = true;
      facility.Downloaded = await this.facilityService.downloadFacility(facility.SiteKey);
      if (facility.Downloaded) {
        this.downloadedFacilities.push({
          Id: facility.SiteKey
        } as Facility);
        let toast = await this.toastController.create({
          message: "Location Downloaded",
          duration: 2000
        });
        toast.present();
      }
    } finally {
      facility.Downloading = false;
      this.downloading = false;
    }
  }

  additionalWork(item: FacilitySearchModel) {
    this.myWorkService.addAdditionalWorkSheet(item.Id, item.Projects);
  }

  private isDownloaded(facility: FacilitySearchModel) {
    return this.downloadedFacilities.some(x => x.Id == facility.SiteKey);
  }
}
