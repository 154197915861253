import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { FacilitiesListComponent } from "./list/facilities-list.component";

@Component({
  selector: "facilities",
  templateUrl: "facilities.component.html"
})
export class FacilitiesComponent implements OnInit {
  query: string = null;
  mode: string = "downloaded";
  isVendorTechnician: boolean;

  @ViewChild(FacilitiesListComponent, { static: false })
  facilitiesListComponent: FacilitiesListComponent;

  constructor(
    private previewService: PreviewService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.isVendorTechnician = await this.userService.isVendorTechnician();
    if(this.isVendorTechnician) {
      this.mode = "search";
    }
  }

  ionViewWillEnter() {
    this.previewService.setPreviewOff();
    if(this.facilitiesListComponent) {
      this.facilitiesListComponent.init();
    }
  }

  switchMode(mode: string) {
    this.mode = mode;
  }
}
