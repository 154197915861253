import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { MyWorkService } from "../my-work.service";
import { AlertController, IonInput, IonSelect, IonTextarea, ModalController, ToastController } from "@ionic/angular";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { NewCalendarNoteModel } from "../my-work.models";
import { LookupListItem } from "src/app/assets/assets.models";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { BarcodeScannerHelperService } from "src/app/shared/services/plugin-helpers/barcode-scanner-helper.service";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";

@Component({
  selector: "new-calendar-note",
  templateUrl: "new-calendar-note.component.html"
})
export class NewCalendarNoteComponent implements OnInit {
  @Input()
  model: NewCalendarNoteModel = {} as any;
  tasks: LookupListItem[] = [];
  users: LookupListItem[] = [];
  loading: boolean = false;
  submitting: boolean;

  @ViewChild("customerInput") customerInput: IonInput;
  @ViewChild("locationInput") locationInput: IonInput;
  @ViewChild("taskSelect") taskSelect: IonSelect;
  @ViewChild("projectNameInput") notesTextArea: IonTextarea;
  @ViewChild("notify") notify: IonSelect;

  constructor(
    private myWorkService: MyWorkService,
    private toastController: ToastController,
    private router: Router,
    private userService: UserService,
    private modalController: ModalController,
    private alertController: AlertController,
    private browserHelperService: BrowserHelperService,
    private barcodeScannerHelperService: BarcodeScannerHelperService,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    try {
      await this.modalController.dismiss();
      await this.modalController.dismiss();
    } catch { }
    this.loading = true;
    this.model = {
    } as any;

    this.model.Date = this.activatedRoute.snapshot.params["jobDate"] || this.model.Date;

    let noteId = this.activatedRoute.snapshot.params["noteId"];

    await this.loadTaskData();
    await this.loadUserData();

    if (noteId > 0) {
      try {
        this.model = (await this.myWorkService.getCalendarNote(noteId)).Data;
      } catch {
        const toast = await this.toastController.create({
          message: `Error while loading Note`,
          color: "danger",
          position: "bottom",
          duration: 5000,
          header: "Error"
        });
        await toast.present();
      }
    }

    this.loading = false;
  }

  ionViewDidEnter() {
  }

  focusCustomer() {
    this.customerInput.setFocus();
  }

  focusLocation() {
    this.locationInput.setFocus();
  }

  focusTask() {
    this.taskSelect.open();
  }

  focusNotes() {
    this.notesTextArea.setFocus();
  }

  async createNote() {
    if (!(await this.validate())) return;
    if (await this.browserHelperService.isOfflineAndMessage()) return;
    try {
      this.submitting = true;
      var response = await this.myWorkService.addOrUpdateCalendarNote(this.model);
      if (response.Success) {
        const checklistId = response.Data;
        window.parent.postMessage({ message: "calendar-job-created", jobId: checklistId }, "*");
      } else {
        this.submitting = false;
        this.notifyMessage(`There was an error while creating note: ${response.Message}`, "danger");
      }
    } catch {
      this.submitting = false;
      this.notifyMessage("There was an error while creating note", "danger");
    } finally {
    }
  }

  async deleteNote() {
    if (confirm("Are you sure you want to delete this note?")) {
      try {
        this.submitting = true;
        var response = await this.myWorkService.deleteCalendarNote(this.model.Id);
        if (response.Success) {
          window.parent.postMessage({ message: "calendar-job-created" }, "*");
        } else {
          this.submitting = false;
          this.notifyMessage(`There was an error while deleting note: ${response.Message}`, "danger");
        }
      } catch {
        this.submitting = false;
        this.notifyMessage("There was an error while deleting note", "danger");
      } finally {
      }
    }
  }

  selectAllToNotify(){
    this.notify.value = this.users.map(x => x.Value);
  }

  async dismissModal() {
    const alert = await this.alertController.create({
      header: "Are you sure you want to cancel?",
      buttons: [
        {
          text: "Yes, I am sure",
          handler: async () => {
            this.dismiss();
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        },
      ]
    });
    return await alert.present();
  }

  private async dismiss(): Promise<void> {
    if (WebAppShowComponent.IsWebAppIFrame) {
      window.parent.postMessage({ message: "close-modal" }, "*");
    } else {
      await this.modalController.dismiss();
    }
  }
  private async validate(): Promise<boolean> {
    var message = null;
    if ((this.model.TaskId || 0) == 0) {
      message = "Task is required";
    }
    if (message) {
      await this.notifyMessage(message, "danger");
      return false;
    }
    return true;
  }

  private async notifyMessage(message: string, color: "info" | "danger" = "info") {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: "bottom",
      duration: 3000,
      cssClass: "new-job-hand-held-scanner-toast"
    });
    await toast.present();
  }

  private async loadTaskData(): Promise<void> {
    try {
      this.tasks = await this.myWorkService.getCalendarNoteTasks();
      if (this.tasks.length > 0) {
        this.model.TaskId = this.tasks[0].Value as any;
      }
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Tasks`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }

  private async loadUserData(): Promise<void> {
    try {
      this.users = await this.myWorkService.getCalendarUsers();
    } catch {
      const toast = await this.toastController.create({
        message: `Error while loading Users`,
        color: "danger",
        position: "bottom",
        duration: 5000,
        header: "Error"
      });
      await toast.present();
    }
  }
}