import { Injectable } from "@angular/core";
import {
  SiteAssetDataConfiguration,
  AssetConfigurationResponse,
  SiteAssetDataItemConfiguration,
  AssetFacilitiesResponse,
  MemberLookup,
  AssetWorkHistory
} from "./assets.models";
import { ApiService } from "../shared/services/api/api.service";
import { RepositoryService } from "../data/db/repository.service";
import { Facility } from "../data/entities/Facility";
import * as _ from "lodash";
import { SiteAsset, SiteAssetAnswer, SiteAssetBattery, SiteAssetInfo, SiteAssetCalcInfo } from "../data/entities/SiteAsset";
import { Guid } from "guid-typescript";
import { Not, IsNull } from "typeorm";
import { DateTimeHelperService } from "../shared/services/datetime/datetime-helper.service";
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";
import { FacilityAssets } from "../data/entities/FacilityAssets";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import * as EventConstants from "../shared/constants/events-constants";
import { FacilityChecklist } from "../data/entities/FacilityChecklist";
import { SyncQueueService } from "../sync/sync-queue.service";
import { WebAppShowComponent } from "../webapp/web-app.component";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import * as moment from "moment";
import { AssetQuoteService } from "./services/quotes/asset-quote.service";
import { MemberService } from "./member.service";
import { BaseResponseModel } from "../shared/models/base-response-model";
import { SiteJobModel } from "../checklist/checklist.models";

@Injectable()
export class AssetsService {
  constructor(
    private apiService: ApiService,
    private repositoryService: RepositoryService,
    private dateTimeHelperService: DateTimeHelperService,
    private browserHelperService: BrowserHelperService,
    private pubSubService: PubSubService,
    private syncQueueService: SyncQueueService,
    private errorHandlerService: ErrorHandlerService,
    private assetQuoteService: AssetQuoteService,
    private memberService: MemberService
  ) { }

  async getConfiguration(memberId: number): Promise<SiteAssetDataConfiguration[]> {
    const repo = this.repositoryService.getMemberConfigurationRepository();
    let config = await repo.findOne({ where: { MemberId: memberId, AsssetConfigurationData: Not(IsNull()) } });
    if (!config) {
      config = await repo.findOne({ where: { MemberId: null } });
    }
    if (config) {
      return JSON.parse(config.AsssetConfigurationData || "[]");
    }
    return [];
  }

  async getAssetWorkHistory(assetId: string): Promise<BaseResponseModel<AssetWorkHistory>> {
    return await this.apiService.post("assets/workhistory", { assetId: assetId });
  }

  async getAssets(facility: Facility, parentId: string, includeOneToOne = false): Promise<SiteAsset[]> {
    let assets = [];
    if (parentId) {
      assets = (await this.getAssetRecursive(parentId, facility.Assets)).Assets || [];
    } else {
      assets = facility.Assets
    }

    let assetList: SiteAsset[] = [];
    for (let asset of assets) {
      if (asset.OneToMany || includeOneToOne) {
        assetList.push(asset);
      }
    }
    return assetList;
  }

  async updateFacilityNoSyncNeeded(facility: Facility) {
    try {
      await this.updateFacilityAssets(facility.Id, facility.Assets);
      await this.repositoryService.getFacilityRepository().save(facility, { transaction: false });
    } catch (err) {
      this.errorHandlerService.logError(err);
      alert("An error occurred!");
    }
  }

  async updateFacility(facility: Facility): Promise<void> {
    try {
      facility.TimeStampUpdated = this.dateTimeHelperService.getUtcTimeStamp();
      await this.updateFacilityNoSyncNeeded(facility);
      await this.syncQueueService.addToQueueOncePerEntity(facility.Id, 'facility', facility);
    } catch (err) {
      this.errorHandlerService.logError(err);
      alert("An error occurred!");
    }
  }

  setAllAssetsInfo(facility: Facility) {
    this.assetRecursiveForEach(facility.Assets || [], (asset: SiteAsset) => {
      this.setAssetsInfo([asset]);
    });
  }

  assetRecursiveForEach(assets: SiteAsset[], callback: (siteAsset: SiteAsset) => void) {
    for (let asset of assets) {
      callback(asset);
      this.assetRecursiveForEach(asset.Assets, callback);
    }
  }


  updateAsset(facility: Facility, assetId: string, parentAssetId: string, newAsset: SiteAsset) {
    if (parentAssetId) {
      let asset = this.getAssetFromFacility(facility, parentAssetId);
      if (asset) {
        let index = _.findIndex(asset.Assets, a => a.Id == assetId);
        if (index >= 0) {
          asset.Assets[index] = newAsset;
        }
      }
    } else {
      let index = _.findIndex(facility.Assets, a => a.Id == assetId);
      if (index >= 0) {
        facility.Assets[index] = newAsset;
      }
    }
  }

  async moveAssetTo(facility: Facility, asset: SiteAsset, moveFromAssetId: string, moveToAssetId: string) {
    let from = this.getAssetFromFacility(facility, moveFromAssetId);
    let fromAssets = from?.Assets || facility.Assets || [];
    let index = _.findIndex(fromAssets, a => a.Id == asset.Id);
    if (index >= 0) {
      fromAssets.splice(index, 1);
      if (moveToAssetId) {
        let to = this.getAssetFromFacility(facility, moveToAssetId);
        if (to) {
          to.Assets.push(asset);
          to.Assets = this.sortAssets(to.Assets);
        }
      } else {
        facility.Assets.push(asset)
        facility.Assets = this.sortAssets(facility.Assets);
      }
    }
  }

  async getFacilityWithAssets(siteKey: string): Promise<Facility> {
    let facility = await this.repositoryService.getFacilityRepository().findOne({
      where: { Id: siteKey }
    });
    facility.Assets = await this.getFacilityAssets(siteKey);
    facility.Configurations = await this.getConfiguration(facility.CustomerId);
    facility.MemberLookup = await this.memberService.getLookupData(facility.CustomerId);
    return facility;
  }

  getAssetFromFacility(facility: Facility, assetId: string): SiteAsset {
    return this.getAssetRecursive(assetId, facility.Assets);
  }

  getAssetFromFacilityBySerial(facility: Facility, serial: string): SiteAsset {
    return this.getAssetRecursiveBySerial(serial, facility.Assets);
  }

  private getAssetRecursiveBySerial(serial: string, siteAssets: SiteAsset[]): SiteAsset {
    for (let asset of siteAssets) {
      for (let answer of asset.Answers) {
        if (answer.FieldDataType == 'scan' && answer.Data == serial) {
          return asset;
        }
      }
      let result = this.getAssetRecursiveBySerial(serial, asset.Assets);
      if (result) {
        return result;
      }
    }
  }

  getAssetFromFacilityByBatterySerial(facility: Facility, serial: string): [string, SiteAsset] {
    return this.getAssetRecursiveByBatterySerial(serial, facility.Assets);
  }

  private getAssetRecursiveByBatterySerial(serial: string, siteAssets: SiteAsset[]): [string, SiteAsset] {
    for (let asset of siteAssets) {
      for (let battery of asset.Batteries) {
        if (battery.SerialNumber == serial) {
          return [battery.BatteryKey, asset];
        }
      }
      let result = this.getAssetRecursiveByBatterySerial(serial, asset.Assets);
      if (result) {
        return result;
      }
    }
  }

  async deleteAsset(facility: Facility, asset: SiteAsset) {
    this.removeSiteAsset(facility.Assets, asset);
    this.updateAsset(facility, asset.Id, asset.ParentId, asset);
    await this.updateFacility(facility);
  }

  private getAssetRecursive(assetId: string, siteAssets: SiteAsset[]): SiteAsset {
    for (let asset of siteAssets) {
      if (asset.Id === assetId) {
        return asset;
      }
      let result = this.getAssetRecursive(assetId, asset.Assets);
      if (result) {
        return result;
      }
    }
  }

  private async getFacilityAssets(siteKey: string): Promise<SiteAsset[]> {
    let facilityAssets = await this.repositoryService.getFacilityAssetsRepository().findOneBy({ Id: siteKey });
    let facility = await this.repositoryService.getFacilityRepository().findOneBy({ Id: siteKey });
    let assets: SiteAsset[] = JSON.parse(facilityAssets.Assets || <any>[]);
    const configurations = await this.getConfiguration(facility.CustomerId);
    for (let asset of assets) {
      await this.fillAssetWithAdditionalInfo(facility.CustomerId, asset, configurations);
    }
    assets = this.sortAssets(assets);
    return assets;
  }

  private async updateFacilityAssets(siteKey: string, assets: SiteAsset[]) {
    let repo = this.repositoryService.getFacilityAssetsRepository();
    let facilityAssets = await repo.findOneBy({ Id: siteKey });
    facilityAssets.Assets = JSON.stringify(assets);
    await repo.save(facilityAssets, { transaction: false });
  }

  private removeSiteAsset(assets: SiteAsset[], siteAsset: SiteAsset) {
    siteAsset.Deleted = true;
    for (let asset of siteAsset.Assets) {
      this.removeSiteAsset(asset.Assets, asset);
    }
  }

  async getBattery(batteryKey: string, assetId: string, facility: Facility): Promise<SiteAssetBattery> {
    let asset = await this.getAssetFromFacility(facility, assetId);
    let index = _.findIndex(asset.Batteries, b => b.BatteryKey == batteryKey);
    if (index >= 0) {
      return asset.Batteries[index];
    }
    return null;
  }

  async getAssetPath(facility: Facility, assetId: string) {
    let assetPath: SiteAsset[] = [];
    await this.loadAssetPath(facility, assetId, assetPath)
    return assetPath;
  }

  private async loadAssetPath(facility: Facility, assetId: string, assetPath: SiteAsset[]) {
    if (assetId) {
      let asset = await this.getAssetFromFacility(facility, assetId);
      if (asset) {
        if (asset.ParentId) {
          await this.loadAssetPath(facility, asset.ParentId, assetPath);
        }
        assetPath.push(asset);
      }
    }
  }

  sortAssets(assets: SiteAsset[]) {
    return _.orderBy(
      assets,
      ["DisplayOrder", "Description", "TimeStamp"],
      ["asc", "asc", "asc"]
    );
  }

  private async fillAssetWithAdditionalInfo(
    memberId: number,
    asset: SiteAsset,
    configurations: SiteAssetDataConfiguration[]
  ): Promise<SiteAsset> {

    const config = _.find(
      configurations,
      (item: SiteAssetDataConfiguration) =>
        item.Id == asset.SiteAssetDataConfigurationId && item.MemberId == memberId
    );
    asset.OneToMany = config.OneToMany;
    asset.SiteAssetTypeDescription = config.SiteAssetType.ResKey;
    asset.HasBatteries = config.SiteAssetType.HasBatteries;
    asset.Quotable = config.SiteAssetType.Quotable;
    asset.Schedulable = config.SiteAssetType.Schedulable;
    asset.Replaceable = config.SiteAssetType.Replaceable;
    asset.SiteAssetTypeId = config.SiteAssetTypeId;
    asset.DisplayOrder = config.DisplayOrder;

    asset.IsContainer = config.IsContainer;
    asset.ImageCount = this.getAssetImageCount(asset);

    asset.Info = [];
    asset.AssetsInfo = [];

    for (let answer of asset.Answers) {
      const configItem = _.find(
        config.Items,
        (item: SiteAssetDataItemConfiguration) =>
          item.Id == answer.SiteAssetDataItemConfigurationId
      );
      answer.FieldName = configItem.FieldName;
      answer.FieldDataType = configItem.FieldDataType;
      answer.ListId = configItem.ListId;
      answer.DisplayOrder = configItem.DisplayOrder;
      answer.AssetDisplay = configItem.AssetDisplay;
      answer.ParentAssetDisplay = configItem.ParentAssetDisplay;
    }

    asset.Answers = _.orderBy(
      asset.Answers,
      (x: SiteAssetAnswer) => x.DisplayOrder
    );

    if (asset.Assets) {
      for (let a of asset.Assets) {
        await this.fillAssetWithAdditionalInfo(memberId, a, configurations);
      }
    }

    asset.Assets = this.sortAssets(asset.Assets);
    return asset;
  }

  mapConfigToSiteAsset(
    siteKey: string,
    config: SiteAssetDataConfiguration
  ): SiteAsset {
    let asset: SiteAsset = {
      Id: Guid.create().toString(),
      Description: "",
      SiteAssetDataConfigurationId: config.Id,
      SiteKey: siteKey,
      DateSynchronized: null,
      SyncError: false,
      Answers: [],
      Assets: [],
      Batteries: [],
      DisplayOrder: config.DisplayOrder,
      ParentId: null,
      TimeStamp: this.dateTimeHelperService.getUtcTimeStamp(),
      SiteAssetTypeId: config.SiteAssetTypeId,
      SiteAssetTypeDescription: config.SiteAssetType.ResKey,
      HasBatteries: config.SiteAssetType.HasBatteries,
      Replaceable: config.SiteAssetType.Replaceable,
      Quotable: config.SiteAssetType.Quotable,
      Schedulable: config.SiteAssetType.Schedulable,
      WarnThreshold: config.SiteAssetType.WarnThreshold,
      FailThreshold: config.SiteAssetType.FailThreshold,
      BatteryTestEnabled: config.SiteAssetType.BatteryTestEnabled,
      BatteryDetailsEnabled: true,
      IsContainer: config.IsContainer,
      ImageCount: 0,
      Deleted: false,
      Hidden: false,
      Updated: false,
      Expand: false,
      OneToMany: config.OneToMany,
      DeleteBatteryDetails: false,
      AssetsInfo: [],
      Info: [],
      Calc: null,
      Highlight: false,
      ReplacedWithId: null,
      QuoteAcceptedDate: null,
      QuoteInProcess: false,
      ReplacedDate: null
    };
    const sorted = _.orderBy(config.Items, c => c.DisplayOrder);
    for (let item of sorted) {
      if (item.Active) {
        asset.Answers.push({
          Id: Guid.create().toString(),
          Data: "",
          DataDate: null,
          DataFloat: item.FieldDataType == "battery-model" ? 0 : null,
          DataNote: null,
          DataListItemId: null,
          ListId: item.ListId,
          AssociatedAssetId: null,
          SiteAssetId: null,
          FieldName: item.FieldName,
          FieldDataType: item.FieldDataType,
          SiteAssetDataItemConfigurationId: item.Id,
          DisplayOrder: null,
          SiteKey: siteKey,
          ImageCount: 0,
          AssetDisplay: item.AssetDisplay,
          ParentAssetDisplay: item.ParentAssetDisplay,
          AnswerText: null,
          Updated: false
        });
      }
    }
    return asset;
  }

  async getConfigurationByCurrentAssetType(facility: Facility, currentAssetType: number): Promise<SiteAssetDataConfiguration[]> {
    let matchingConfigs: SiteAssetDataConfiguration[] = [];
    const configurations = await this.getConfiguration(facility.CustomerId);

    //Workaround for facility level
    if (currentAssetType == null) {
      for (let config of configurations) {
        const innerConfigs = _.filter(configurations, c => c.ParentId == config.Id);
        if (config.ParentId == null) {
          matchingConfigs.push(config);
          matchingConfigs = _.concat(matchingConfigs, innerConfigs);
        }
      }
    } else {
      for (let config of configurations) {
        const innerConfigs = _.filter(configurations, c => c.ParentId == config.Id);
        if (
          config.SiteAssetTypeId == currentAssetType && innerConfigs.length > 0
        ) {
          matchingConfigs = _.concat(matchingConfigs, innerConfigs);
        }
      }
    }
    return matchingConfigs;
  }

  async getAssetContainers(
    asset: SiteAsset,
    facility: Facility
  ): Promise<SiteAsset[]> {
    const configurations = await this.getConfiguration(facility.CustomerId);

    const config = _.find(
      configurations,
      (config: SiteAssetDataConfiguration) =>
        config.SiteAssetTypeId == asset.SiteAssetTypeId
    );

    const availableConfigs = _.filter(
      configurations,
      (c: SiteAssetDataConfiguration) => c.Id == config.ParentId
    );
    const siteAssetTypeIds = _.map(
      availableConfigs,
      (c: SiteAssetDataConfiguration) => c.SiteAssetTypeId
    );

    const assets = await this.getAssetFlatByFacility(facility);
    const result = _.filter(
      assets,
      (asset: SiteAsset) =>
        _.filter(
          siteAssetTypeIds,

          (siteAssetTypeId: number) => asset.SiteAssetTypeId == siteAssetTypeId && !asset.Deleted
        ).length > 0
    );

    if (
      config.ParentId == null// ||
      //(config.Parent && config.Parent.ParentId == null)
    ) {
      result.unshift({
        Id: null,
        Description: facility.Name
      } as any);
    }

    return result;
  }

  async getWhereBatterySerialNumberUsed(facility: Facility, serialNumber: string, batteryKey: string): Promise<[SiteAssetBattery, SiteAsset]> {
    let assets = await this.getAssetFlatByFacility(facility);
    for (let asset of assets) {
      if (asset.Batteries) {
        for (let battery of asset.Batteries) {
          if (battery.BatteryKey != batteryKey && battery.SerialNumber == serialNumber) {
            return [battery, asset];
          }
        }
      }
    }
    return null;
  }

  getSiteAssetCalcInfo(siteAsset: SiteAsset, facility: Facility): SiteAssetCalcInfo {
    if (!facility.MemberLookup.UseHeatMap) {
      return null;
    }
    let failThreshold = siteAsset.FailThreshold;
    let warnThreshold = siteAsset.WarnThreshold;
    if (!failThreshold && failThreshold != 0 && !warnThreshold && warnThreshold != 0) {
      let value = this.getAssetAnswerListOrNumeric("replacement-period", siteAsset, facility);
      failThreshold = value;
      if (value > 1) {
        warnThreshold = value - 1;
      }
    }

    if ((failThreshold != null && failThreshold >= 0) || (warnThreshold != null && warnThreshold >= 0)) {
      let dateCode = this.getAssetDateCode(siteAsset);
      if (!dateCode) {
        return null;
      }
      let isFail = (failThreshold != null && failThreshold >= 0) ? this.calculate(dateCode, failThreshold * 12, siteAsset) : null;
      let isWarn = (warnThreshold != null && warnThreshold >= 0) ? this.calculate(dateCode, warnThreshold * 12, siteAsset) : null;
      return {
        IsFail: isFail,
        IsWarn: isWarn && !isFail,
        IsPass: !isFail && !isWarn,
      };
    }
  }

  private calculate(dateCode: string, compareWith: number, siteAsset: SiteAsset): boolean {
    if (compareWith >= 0) {
      if (dateCode) {
        let dateCalculated = moment(dateCode).add(compareWith, "months").startOf("month");
        let now = moment().startOf("month");
        return now.diff(dateCalculated, "months") >= 0;
      }
    }
    return false;
  }

  private getAssetDateCode(siteAsset: SiteAsset): string {
    let dateCode: string;
    let dateInstall: string;
    for (let answer of siteAsset.Answers) {
      if (answer.FieldDataType == "datecode" && answer.DataDate) {
        dateCode = answer.DataDate;
        break;
      } else if (answer.FieldDataType == "dateinstall" && answer.DataDate) {
        dateInstall = answer.DataDate;
      }
    }
    return dateCode || dateInstall;
  }

  private async getAssetFlatByFacility(facility: Facility): Promise<SiteAsset[]> {
    let assets = facility.Assets;
    let assetsFlat: SiteAsset[] = [];
    this.populateAssetsFlatFromFacility(assets, assetsFlat);
    let sortedAssets = this.sortAssets(assetsFlat);
    return sortedAssets;
  }

  private populateAssetsFlatFromFacility(siteAssets: SiteAsset[], siteAssetsFlat: SiteAsset[]) {
    siteAssetsFlat = siteAssetsFlat || [];
    siteAssets = siteAssets || [];
    for (let asset of siteAssets) {
      siteAssetsFlat.push(asset);
      this.populateAssetsFlatFromFacility(asset.Assets, siteAssetsFlat);
    }
  }

  getAssetImageCount(siteAsset: SiteAsset) {
    let imageCount = this.setAssetImageCountRecursive(siteAsset);
    return imageCount;
  }

  private setAssetImageCountRecursive(siteAsset: SiteAsset): number {
    let imageCount = (siteAsset.ImageCount || 0);
    for (let answer of siteAsset.Answers) {
      imageCount += answer.ImageCount || 0;
    }
    for (let batttery of siteAsset.Batteries) {
      imageCount += batttery.ImageCount;
    }
    for (let asset of siteAsset.Assets) {
      imageCount += this.setAssetImageCountRecursive(asset);
    }
    return imageCount;
  }

  setAssetsInfo(siteAssets: SiteAsset[]) {
    for (let asset of siteAssets) {
      if (asset.Deleted) {
        continue;
      }

      asset.Info = [];

      for (let answer of asset.Answers) {
        if (answer.AssetDisplay) {
          if (answer.FieldDataType == "battery-model" && asset.Batteries.length > 0) {
            let batteryNames = [];
            var dateCodes = [];
            var installDates = [];

            let convertDateCode = (dc) => {
              let month = dc.split("-")[0];
              let year = dc.split("-")[1];
              return `${year}-${month}-01`;
            }

            for (let battery of asset.Batteries) {
              batteryNames.push(battery.InvgenId);
              if (battery.DateCode) {
                dateCodes.push(moment(convertDateCode(battery.DateCode)));
              }
              if (battery.DateInstalled) {
                installDates.push(moment(battery.DateInstalled));
              }
            }
            let mixed = _.uniq(batteryNames).length > 1;
            let text = mixed ? "Mixed" : this.getAnswerText(answer);
            if (text != null && text != '') {
              asset.Info.push({
                Label: answer.FieldName,
                Text: text,
                Key: answer.SiteAssetDataItemConfigurationId,
                Assets: []
              });
            }

            if (dateCodes.length > 0) {
              let sortedDescArray = dateCodes.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
              asset.Info.push({
                Label: "Date Code",
                Text: sortedDescArray[0].format("MM-YYYY"),
                Key: answer.SiteAssetDataItemConfigurationId,
                Assets: []
              });
            }
            if (installDates.length > 0) {
              let sortedDescArray = installDates.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
              asset.Info.push({
                Label: "Date Installed",
                Text: sortedDescArray[0].format("MM-DD-YYYY"),
                Key: answer.SiteAssetDataItemConfigurationId,
                Assets: []
              });
            }

          } else {
            let text = this.getAnswerText(answer);
            if (text != null && text != '') {
              asset.Info.push({
                Label: answer.FieldName,
                Text: text,
                Key: answer.SiteAssetDataItemConfigurationId,
                Assets: []
              });
            }
          }
        }
      }

      let grouppedSubAssetInfo: { [key: string]: SiteAsset[] } = {};
      let grouppedSubInfo: { [key: string]: SiteAssetInfo[] } = {};

      let subInfoBatteryCount: number = 0;
      let hasSubInfoBatteryCount: boolean = false;
      let hasSubInfoBatteryLabel: string;
      let batteryCountDataItemConfigurationId: number;

      for (let subAsset of asset.Assets) {
        if (subAsset.Deleted || !subAsset.OneToMany) {
          continue;
        }
        let assetKey = subAsset.SiteAssetDataConfigurationId;
        if (grouppedSubAssetInfo[assetKey]) {
          grouppedSubAssetInfo[assetKey].push(subAsset);
        } else {
          grouppedSubAssetInfo[assetKey] = [subAsset];
        }

        for (let answer of subAsset.Answers) {
          if (answer.ParentAssetDisplay) {
            let text = this.getAnswerText(answer);
            if (text != null && text! != '') {
              if (answer.FieldDataType == "battery-count") {
                hasSubInfoBatteryCount = true;
                hasSubInfoBatteryLabel = answer.FieldName;
                batteryCountDataItemConfigurationId = answer.SiteAssetDataItemConfigurationId;
                subInfoBatteryCount += parseInt(text);
              }
              else {
                if (answer.FieldDataType == "battery-model" && subAsset.Batteries.length > 0) {
                  let batteryNames = [];
                  for (let battery of subAsset.Batteries) {
                    batteryNames.push(battery.InvgenId);
                  }
                  let mixed = _.uniq(batteryNames).length > 1;
                  if (mixed) {
                    text = "Mixed";
                  }
                }
                let item = {
                  Label: answer.FieldName,
                  Text: text,
                  Key: answer.SiteAssetDataItemConfigurationId,
                  Assets: []
                };
                let key = item.Key;
                if (grouppedSubInfo[key]) {
                  grouppedSubInfo[key].push(item);
                } else {
                  grouppedSubInfo[key] = [item];
                }
              }
            }
          }
        }
      }

      let subAssetInfo: SiteAssetInfo[] = [];
      for (let key in grouppedSubAssetInfo) {
        let item = grouppedSubAssetInfo[key];
        subAssetInfo.push({
          Label: item[0].SiteAssetTypeDescription,
          Text: item.length.toString(),
          Key: item[0].SiteAssetDataConfigurationId,
          Assets: item
        });
      }

      asset.AssetsInfo = subAssetInfo;

      let subInfo: SiteAssetInfo[] = [];
      for (let key in grouppedSubInfo) {
        let item = grouppedSubInfo[key];
        let mixed = _.uniqBy(item, i => i.Text).length > 1;
        subInfo.push({
          Label: item[0].Label,
          Text: mixed ? "Mixed" : item[0].Text,
          Key: item[0].Key,
          Assets: []
        });
      }

      if (hasSubInfoBatteryCount) {
        subInfo.push({
          Label: hasSubInfoBatteryLabel,
          Text: String(subInfoBatteryCount),
          Key: batteryCountDataItemConfigurationId,
          Assets: []
        });
      }

      asset.Info = asset.Info.concat(subInfo);
    }
  }

  getAssetAnswerListOrNumeric(fieldDataType: string, asset: SiteAsset, facility: Facility): number {
    let returnValue = null;
    for (let answer of asset.Answers) {
      if (answer.FieldDataType == fieldDataType) {
        if (answer.DataListItemId != null) {
          const configItems = _.flatMap(facility.Configurations, i => i.Items);
          const configItem = _.find(configItems, i => i.Id === answer.SiteAssetDataItemConfigurationId);
          const value = _.find(facility.MemberLookup.CustomLists[configItem.ListId], i => i.Value == answer.DataListItemId);
          returnValue = parseInt(value.Text);
        } else {
          returnValue = answer.DataFloat;
        }
      }
    }
    return returnValue;
  }

  private getAnswerText(siteAssetAnswer: SiteAssetAnswer): string {
    if ((siteAssetAnswer.AnswerText || "").length > 0) {
      return siteAssetAnswer.AnswerText;
    }
    return (siteAssetAnswer.DataFloat || siteAssetAnswer.DataDate || siteAssetAnswer.Data) as string;
  }
}
