import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root"
})
export class LoaderHelperService {
  private loader: HTMLIonLoadingElement = null;
  private loaderTimeout: number;
  constructor(public loadingController: LoadingController) {
  }
  async on() {
    window.clearTimeout(this.loaderTimeout);
    this.loaderTimeout = window.setTimeout(() => {
      this.off();
    }, 60 * 1000);

    if(this.loader != null){
      return;
    }
    this.loader = await this.loadingController.create({
      message: "Please wait",
      animated: false,
      duration: 0
    });
    await this.loader.present();
  }

  setContent(content: string) {
    if(this.loader) {
      this.loader.message = content;
    }
  }

  async off() {
    if (this.loader != null) {
      await this.loader.dismiss();
      this.loader = null;
    }
  }
}
