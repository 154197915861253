import { Component, Input } from "@angular/core";
import * as _ from "lodash";
import { BatteryInfo, ParsedBatteryTestModel } from "../../battery-test.models";
import { BatteryTestService } from "../../battery-test.service";

@Component({
  selector: "battery-test-form",
  templateUrl: "battery-test-form.component.html"
})
export class BatteryTestFormComponent {
  @Input()
  data: ParsedBatteryTestModel;
  @Input()
  refreshBattery: boolean = false;

  constructor(
    private batteryTestService: BatteryTestService
  ) {
  }

  async ngOnInit() {
    setTimeout(() => {
      window["syncscroll"].reset();
    }, 1000);
    this.setBatteryRanges();
    if (this.refreshBattery) {
      this.onBatteryChange();
    }
  }

  async onBatteryChange() {
    let result = await this.batteryTestService.getBatteryInfo(this.data.BatteryTest.BatteryId);
    if (result && result.Data) {
      this.data.BatteryInfo = result.Data;
      this.setBatteryRanges();
    }
  }

  isCalcTypeByReference() {
    return this.data.BatteryTest.CalcType == 1;
  }

  getTestTypeLabel() {
    let data = _.find(this.data.TestTypeList, item => item.Value == String(this.data.BatteryTest.TestTypeId));
    if (data) {
      return data.Text;
    }
    return "";
  }

  onTestTypeChangeOutput(event) {
    this.data.BatteryTest.TestTypeId = event;
    this.onTestTypeChange();
  }

  onTestTypeChange() {
    if (!this.data.BatteryInfo) {
      return;
    }
    //Conductance
    if (this.data.BatteryTest.TestTypeId == 1) {
      this.data.BatteryTest.MeasLow = this.data.BatteryInfo.ConductanceLow;
      this.data.BatteryTest.MeasHigh = this.data.BatteryInfo.ConductanceHigh;
    }
    //Impedance
    else if (this.data.BatteryTest.TestTypeId == 2) {
      this.data.BatteryTest.MeasLow = this.data.BatteryInfo.ImpedanceLow;
      this.data.BatteryTest.MeasHigh = this.data.BatteryInfo.ImpedanceHigh;
    }
    //Resistance
    else if (this.data.BatteryTest.TestTypeId == 3) {
      this.data.BatteryTest.MeasLow = this.data.BatteryInfo.ResistanceLow;
      this.data.BatteryTest.MeasHigh = this.data.BatteryInfo.ResistanceHigh;
    }
  }

  onTemperatureTypeChange() {
    if (!this.data.BatteryInfo) {
      return;
    }
    if (this.data.BatteryTest.TempType == "C") {
      this.data.BatteryTest.TempLow = this.data.BatteryInfo.TemperatureLowC;
      this.data.BatteryTest.TempHigh = this.data.BatteryInfo.TemperatureHighC;
    } else if (this.data.BatteryTest.TempType == "F") {
      this.data.BatteryTest.TempLow = this.data.BatteryInfo.TemperatureLowF;
      this.data.BatteryTest.TempHigh = this.data.BatteryInfo.TemperatureHighF;
    }
  }
  onChargeTypeChange() {
    if (!this.data.BatteryInfo) {
      return;
    }
    //On Charge
    if (this.data.BatteryTest.ChargeType == 1) {
      this.data.BatteryTest.VoltLow = this.data.BatteryInfo.VoltageLowOnCharge;
      this.data.BatteryTest.VoltHigh = this.data.BatteryInfo.VoltageHighOnCharge;
    } else if (this.data.BatteryTest.ChargeType == 2) {
      this.data.BatteryTest.VoltLow = this.data.BatteryInfo.VoltageLowOCV;
      this.data.BatteryTest.VoltHigh = this.data.BatteryInfo.VoltageHighOCV;
    }
  }

  isVRLALike() {
    return this.data && (this.data.BatteryTest.BatteryType == 'VRLA' ||
      this.data.BatteryTest.BatteryType == 'NiCd' ||
      this.data.BatteryTest.BatteryType == 'LiIon');
  };

  isVLALike() {
    return this.data && this.data.BatteryTest.BatteryType == 'VLA';
  }

  private setBatteryRanges() {
    if (this.data.BatteryInfo) {
      if (this.data.BatteryInfo.IsVLA) {
        this.data.BatteryTest.BatteryType = "VLA";
      } else if (this.data.BatteryInfo.IsLiIon) {
        this.data.BatteryTest.BatteryType = "LiIon";
      } else if (this.data.BatteryInfo.IsNiCd) {
        this.data.BatteryTest.BatteryType = "NiCd";
      } else {
        this.data.BatteryTest.BatteryType = "VRLA";
      }
    }
    this.onChargeTypeChange();
    this.onTestTypeChange();
    this.onTemperatureTypeChange();
  }
}