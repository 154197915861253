<ion-content class="ion-padding">
  <ion-img [src]="'/assets/images/logo.png'"></ion-img>
  <div class="ion-text-center ion-margin-bottom ion-margin-top">
    <ion-icon name="mail" style="font-size: 50px;"></ion-icon>
  </div>
  <ion-title class="ion-text-center ion-padding-top ion-padding-bottom">
    Request successfully sent
  </ion-title>
  <div class="ion-margin-bottom">
    We'll review your request and get back to you as soon as possible.
    <div class="ion-text-center ion-margin-top">
      <a (click)="login()">Go back to login</a>
    </div>
  </div>
</ion-content>