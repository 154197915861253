import { Component, Input, OnInit } from "@angular/core";
import { AssetsService } from "../assets.service";
import { Facility } from "../../data/entities/Facility";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { Router } from "@angular/router";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "assets-button",
  templateUrl: "assets-button.component.html"
})
export class AssetsButtonComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  heatmap: boolean = true;
  @Input()
  checklistId: number;
  @Input()
  preview: boolean;
  
  totalWarn: number = 0;
  totalFail: number = 0;
  totalPass: number = 0;
  loading: boolean = true;
  visible: boolean = true;
  
  constructor(
    private assetsService: AssetsService,
    private router: Router,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.totalPass = 0;
    this.totalWarn = 0;
    this.totalFail = 0;
    this.visible = !(await this.userService.isVendor());
    this.assetRecursive(this.facility.Assets, (asset: SiteAsset) => {
      if (!asset.Deleted) {
        let calc = this.assetsService.getSiteAssetCalcInfo(asset, this.facility);
        if (calc) {
          if (calc.IsWarn) {
            this.totalWarn += 1;
          }
          if (calc.IsFail) {
            this.totalFail += 1;
          }
          if (calc.IsPass) {
            this.totalPass += 1;
          }
        }
      }
    });
    this.loading = false;
  }

  goToAssets() {
    if (this.checklistId) {
      this.router.navigate([`/facilities/${this.facility.Id}/assets/fromJob/${this.checklistId}/${this.preview}`]);
    } else {
      this.router.navigate([`/facilities/${this.facility.Id}/assets`]);
    }
  }

  private assetRecursive(siteAssets: SiteAsset[], callback: (item: SiteAsset) => void): void {
    for (let asset of siteAssets) {
      callback(asset);
      this.assetRecursive(asset.Assets, callback);
    }
  }
}
