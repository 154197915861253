import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { IonicModule, IonProgressBar } from "@ionic/angular";
import { HeaderComponent } from "./components/header/header.component";
import { BrowserHelperService } from "./services/browser/browser-helper.service";
import { RepositoryService } from "../data/db/repository.service";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { Network } from '@ionic-native/network/ngx';
import { NextOnEnterDirective } from "./directives/next-on-enter.directive";
import { CloseKeyboardOnEnterDirective } from "./directives/close-keyboard-on-enter.directive";
import { ApiService } from "./services/api/api.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";
import { DateTimeHelperService } from "./services/datetime/datetime-helper.service";
import { LoaderHelperService } from "./services/browser/loader-helper.service";
import { ErrorHandlerService } from "./services/error/error-handler.service";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { OrderByPipe } from "./pipes/order-by.pipe";
import { PubSubService } from "./services/pubsub/pub-sub.service";
import { MaxLengthDirective } from "./directives/max-length.directive";
import { BarcodeScannerHelperService } from "./services/plugin-helpers/barcode-scanner-helper.service";
import { CameraHelperService } from "./services/plugin-helpers/camera-helper.service";
import { ConfigService } from "./services/config/config.service";
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { PositiveNumbersOnlyDirective } from "./directives/positive-numbers-only.directive";
import { UserService } from "./services/user-service/user-service.service";
import { EnvironmentService } from "./services/environment-service/environment.service";
import { OmsBottomTrademarkComponent } from "./components/trademark/oms-bottom-trardemark.component";
import { EnvironmentComponent } from "./components/environment/environment.component";
import { IonicStorageModule } from '@ionic/storage-angular';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { TokenInterceptor } from "./services/api/token.interceptor";
import { UnauthorizedInterceptor } from "./services/api/unauthorized.interceptor";
import { LocalDateTimePipe } from "./pipes/local-datetime.pipe";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileUploadModule } from 'ng2-file-upload';
import { MultiFileUploadComponent } from "./components/upload/multi-file-upload.component";
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { GeolocationService } from "./services/geolocation-service/geolocation.service";
import { PreviewService } from "./services/preview-service/preview-service.service";
import { GeolocationInterceptor } from "./services/api/geolocation.interceptor";
import { TimezoneInterceptor } from "./services/api/timezone.interceptor";
import { Chooser } from '@ionic-native/chooser/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { OMSGroupSelectComponent } from "../assets/shared/components/oms-group-select.component";
import { OMSGroupSelectModalComponent } from "../assets/shared/components/oms-group-select-modal.component";
import { OmsMultipleOptionsPickerComponent } from "../assets/shared/components/oms-multiple-options-picker/oms-multiple-options-picker.component";
import { UploadMediaPreviewDirective } from "./directives/upload-media-preview.directive";
import { MultiPlatformFileUploadComponent } from "./components/upload/multi-platform-multi-file-upload.component";
import { NativeMultiFileUploadComponent } from "./components/upload/native-multi-file-upload.component";
import { NativeMultiFileUploadMediaSelectorComponent } from "./components/upload/native-multi-file-upload-media-selector.component";
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from "@ionic/storage";
import { StorageWrapperService } from "./services/storage-wrapper/storage-wrapper.service";
import { DecimalDirective } from "./directives/decimal.directive";
import { LocalDatePipe } from "./pipes/local-date.pipe";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: "md"
    }),
    FormsModule,
    IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
    }),
    FileUploadModule
  ],
  declarations: [
    HeaderComponent,
    EnvironmentComponent,
    OmsBottomTrademarkComponent,
    NextOnEnterDirective,
    CloseKeyboardOnEnterDirective,
    OrderByPipe,
    LocalDateTimePipe,
    LocalDatePipe,
    MaxLengthDirective,
    DecimalDirective,
    PositiveNumbersOnlyDirective,
    MultiFileUploadComponent,
    MultiPlatformFileUploadComponent,
    NativeMultiFileUploadComponent,
    OMSGroupSelectComponent,
    OMSGroupSelectModalComponent,
    OmsMultipleOptionsPickerComponent,
    UploadMediaPreviewDirective,
    NativeMultiFileUploadMediaSelectorComponent
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    IonicModule,
    FormsModule,
    HeaderComponent,
    OmsBottomTrademarkComponent,
    EnvironmentComponent,
    NextOnEnterDirective,
    CloseKeyboardOnEnterDirective,
    OrderByPipe,
    LocalDateTimePipe,
    LocalDatePipe,
    MaxLengthDirective,
    DecimalDirective,
    PositiveNumbersOnlyDirective,
    MultiFileUploadComponent,
    MultiPlatformFileUploadComponent,
    NativeMultiFileUploadComponent,
    OMSGroupSelectComponent,
    OMSGroupSelectModalComponent,
    OmsMultipleOptionsPickerComponent,
    UploadMediaPreviewDirective,
    NativeMultiFileUploadMediaSelectorComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        RepositoryService,
        BrowserHelperService,
        EnvironmentService,
        Keyboard,
        ApiService,
        UserService,
        UniqueDeviceID,
        AppVersion,
        DateTimeHelperService,
        LoaderHelperService,
        ErrorHandlerService,
        BarcodeScanner,
        Geolocation,
        PubSubService,
        BarcodeScannerHelperService,
        CameraHelperService,
        ConfigService,
        StorageWrapperService,
        Camera,
        File,
        Network,
        Base64,
        StatusBar,
        PhotoViewer,
        InAppBrowser,
        FileUploadModule,
        MediaCapture,
        ScreenOrientation,
        GeolocationService,
        PreviewService,
        StreamingMedia,
        AndroidPermissions,
        Chooser,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: GeolocationInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TimezoneInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: UnauthorizedInterceptor,
          multi: true
        }
      ]
    }
  }
}
