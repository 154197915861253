<ion-content *ngIf="!loading">
  <ion-card *ngIf="items.length === 0" class="ion-text-center">
    <ion-card-header>
      <ion-card-title>No drafts yet</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div class="ion-padding-bottom">
        <div>
          You will see all of your drafts here
        </div>
      </div>
    </ion-card-content>
  </ion-card>
  <ng-container *ngIf="items.length > 0">
    <ion-searchbar [(ngModel)]="query" (ngModelChange)="search()" showCancelButton debounce="500"
      *ngIf="items.length > 5" placeholder="Filter drafts"></ion-searchbar>
    <ion-list>
      <ng-container *ngFor="let item of items">
        <ion-item *ngIf="!item.Hidden">
          <ion-label (click)="open(item)">
            <div>
              Quote Request Number: {{item.Id}}
            </div>
            <div>
              {{item.DateUpdated | localdatetime}}
            </div>
            <ion-note>
              <i>By:</i> {{item.CreatedBy}}
              <div *ngIf="item.Notes">
                <i>Notes:</i> {{item.Notes}}
              </div>
              <div *ngIf="item.Description">
                <i>Tags:</i> {{item.Description}}
              </div>
              <!-- <div *ngIf="item.ToEmails">
                <i>Sent To:</i> {{item.ToEmails}}
              </div>
              <div *ngIf="item.CcEmails">
                <i>CC:</i> {{item.CcEmails}}
              </div> -->
            </ion-note>
          </ion-label>
          <ion-icon *ngIf="item.IncludeSiteWalk" name="walk-outline" slot="end" style="color: #2f3292"></ion-icon>
          <ion-icon name="pencil-outline" slot="end" (click)="edit(item)"></ion-icon>
        </ion-item>
      </ng-container>
    </ion-list>
    <oms-bottom-trademark></oms-bottom-trademark>
  </ng-container>
</ion-content>
<ion-content *ngIf="loading">
  <ion-item *ngFor="let i of [1,2,3,4,5]">
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-content>