import { Component, OnInit, OnDestroy } from "@angular/core";
import { PubSubService } from "../../services/pubsub/pub-sub.service";
import { EnvironmentService } from "../../services/environment-service/environment.service";
import * as EventConstants from "src/app/shared/constants/events-constants"

@Component({
  selector: "oms-environment",
  templateUrl: "environment.component.html"
})
export class EnvironmentComponent implements OnInit, OnDestroy {
  environment: string;

  constructor(private pubSub: PubSubService,
    private environmentService: EnvironmentService) {
  }

  async ngOnInit() {
    this.setEnvironment((await this.environmentService.getEnvironment()) || "");
    this.pubSub.$sub(EventConstants.ENVIRONMENT_UPDATED, (env) => {
      this.setEnvironment(env ?? "");
    });
  }

  private setEnvironment(value: string) {
    if (value == "stage") {
      this.environment = "";
    } else {
      this.environment = value;
    }
  }

  ngOnDestroy() {

  }
}
