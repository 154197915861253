import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { RepositoryService } from "../data/db/repository.service";
import { SyncQueue, SyncQueueModel } from "../data/entities/SyncQueue";
import { DateTimeHelperService } from "../shared/services/datetime/datetime-helper.service";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants"
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";
import { GeolocationService } from "../shared/services/geolocation-service/geolocation.service";
import { sum } from "lodash";

@Injectable()
export class SyncQueueService {
  constructor(
    private repositoryService: RepositoryService,
    private dateTimeHelperService: DateTimeHelperService,
    private pubSubService: PubSubService,
    private browserHelperService: BrowserHelperService,
    private geolocationService: GeolocationService
  ) {
  }


  async addToQueueEveryTime(type: 'facility' | 'checklist' | 'checklistStatus' | 'jobCheckIn' | 'jobCheckOut', data: any) {
    await this.addToQueuePrivate(null, type, data);
  }

  async addToQueueOncePerEntity(entityId: string, type: 'facility' | 'checklist' | 'checklistStatus' | 'jobCheckIn' | 'jobCheckOut', data: any) {
    await this.addToQueuePrivate(entityId, type, data);
  }

  private async addToQueuePrivate(entityId: string, type: 'facility' | 'checklist' | 'checklistStatus' | 'jobCheckIn' | 'jobCheckOut', data: any) {
    console.log("Add to queue");
    const repository = this.repositoryService.getSyncQueueRepository();
    const coords = this.geolocationService.getCurrentLocation();
    let existing = entityId ? await repository.findOne({
      where: {
        Type: type,
        EntityId: entityId
      }
    }) : null;
    if (existing) {
      existing.Data = JSON.stringify(data);
      existing.TimeStampUpdated = this.dateTimeHelperService.getUtcTimeStamp();
      existing.Latitude = String(coords.latitude || "");
      existing.Longitude = String(coords.longitude || "");
      existing.SyncCount = (existing.SyncCount || 0) + 1;
      await repository.save(existing, { transaction: false });
    } else {
      await repository.save({
        Id: null,
        EntityId: entityId,
        Type: type,
        SyncCount: 1,
        Data: JSON.stringify(data),
        TimeStampUpdated: this.dateTimeHelperService.getUtcTimeStamp(),
        Latitude: String(coords.latitude || ""),
        Longitude: String(coords.longitude || ""),
        DateCreated: this.dateTimeHelperService.getUtcString()
      }, { transaction: false });
    }
    await this.pubSubService.$pub(EventConstants.SYNC_DONE);
    _.throttle(this.publish, 100)();
  }

  private publish = () => {
    if (!this.browserHelperService.isNativeApp()) {
      this.pubSubService.$pub(EventConstants.TRIGGER_SYNC_WEB);
    } else {
      this.pubSubService.$pub(EventConstants.SYNC_UPDATE);
    }
  }

  async getItemsToSyncCount(): Promise<number> {
    let queryBuilder = this.repositoryService.getSyncQueueRepository().createQueryBuilder("syncqueue");
    let result = await queryBuilder.select("SUM(syncqueue.SyncCount)", "Sum")
      .getRawOne();
    var sum = result.Sum || 0;
    console.log("Sync Count: " + sum);
    return sum;
    //return await this.repositoryService.getSyncQueueRepository().count();
  }

  async getItemsToSync(type: 'facility' | 'checklist' | 'checklistStatus' | 'jobCheckIn' | 'jobCheckOut'): Promise<SyncQueue[]> {
    let items = await this.repositoryService.getSyncQueueRepository().find({
      where: {
        Type: type
      }
    });
    return _.orderBy(items, item => item.TimeStampUpdated, ['asc'])
  }

  async removeSynchronizedItem(model: SyncQueue): Promise<void> {
    await this.repositoryService.getSyncQueueRepository().remove(model, { transaction: false });
  }

  async removeSynchronizedItems(items: SyncQueue[]): Promise<void> {
    const chunks = _.chunk(items, 100);
    for (let syncChunk of chunks) {
      await this.repositoryService.getSyncQueueRepository().remove(syncChunk), { transaction: false };
    }
  }
}
