import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import * as _ from "lodash";
import { GalleryImage } from "../gallery.models";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { PreviewService } from "src/app/shared/services/preview-service/preview-service.service";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { AppInfo } from "src/app/globals/AppInfo";

@Component({
  selector: "image-item",
  templateUrl: "image-item.component.html"
})
export class ImageItemComponent implements OnInit {
  @Input()
  image: GalleryImage;
  @Input()
  selectable: boolean;
  @Input()
  preview: boolean;
  @Output()
  deleteImage: EventEmitter<GalleryImage> = new EventEmitter<GalleryImage>();
  @Output()
  onImageSelected: EventEmitter<GalleryImage> = new EventEmitter<GalleryImage>();
  @Output()
  onImageDeselected: EventEmitter<GalleryImage> = new EventEmitter<GalleryImage>();
  private isInitialized = false;

  constructor(
    private browserHelperService: BrowserHelperService,
    private cameraHelperService: CameraHelperService,
    private photoViewer: PhotoViewer,
    private previewService: PreviewService,
    private streamingMedia: StreamingMedia
  ) {
  }

  async ngOnInit() {
    if (this.isVideo(this.image.FileName)) {
      this.image.ThumbnailUrl = "/assets/images/video_image.png";
    } else if (!this.isPhoto(this.image.FileName)) {
      let url = "/assets/images/text_image.png";
      let type = "";
      try {
        type = this.image.FileName.split(".")[this.image.FileName.split(".").length - 1].toLowerCase();
      } catch { }
      if (type == "pdf") {
        url = "/assets/images/pdf_image.png";
      }
      else if (type == "doc" || type == "docx" || type == "pages") {
        url = "/assets/images/word_image.png";
      }
      else if (type == "xls" || type == "xlsx" || type == "xlsm" || type == "numbers") {
        url = "/assets/images/excel_image.png";
      }
      else if (type == "ppt" || type == "pptx") {
        url = "/assets/images/power_point_image.png";
      }
      else if (type == "txt") {
        url = "/assets/images//text_image.png";
      }
      else if (type == "mp4" || type == "mov") {
        url = "/assets/images/video_image.png";
      }
      this.image.ThumbnailUrl = url;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isInitialized = true;
    }, 100);
  }

  async openImage(image: GalleryImage) {
    if (image.IsPhone) {
      if (this.isVideo(image.FileName)) {
        this.streamingMedia.playVideo(this.image.NativeURL);
        // if (AppInfo.Platform == "ios") {
        //   this.streamingMedia.playVideo(this.image.NativeURL);
        // } else {
        //   this.videoPlayer.play(this.image.NativeURL);
        // }
      } else if (this.isPhoto(image.FileName)) {
        this.photoViewer.show(this.image.NativeURL, "", {
          share: true, // default is false
          closeButton: true, // default is true
          copyToReference: false, // default is false
          headers: '',  // If this is not provided, an exception will be triggered
          piccasoOptions: {} // If this is not provided, an exception will be triggered
        });
      }
    } else {
      this.browserHelperService.openUrl(image.Url);
    }
  }

  private convertUrl(url: string) {
    let newUrl = window["Ionic"].WebView.convertFileSrc(url);
    if (newUrl && newUrl.indexOf("file://") == 0) {
      newUrl = newUrl.replac("file://", "");
    }
    return newUrl;
  }

  imageLoaded(image: GalleryImage) {
    image.Loaded = true;
  }

  delete() {
    if (this.image.IsUploaded && this.browserHelperService.isOffline()) {
      let text = "image";
      if (this.isVideo(this.image.FileName)) {
        text = "video";
      }
      alert(`This ${text} has been uploaded to OMS and currently can't be deleted because you're offline`);
      return;
    }
    this.image.Deleting = true;
    this.deleteImage.next(this.image);
  }

  imageToggled() {
    if (!this.isInitialized) {
      return;
    }
    if (this.image.Selected) {
      this.onImageSelected.next(this.image);
    } else {
      this.onImageDeselected.next(this.image);
    }
  }

  private isVideo(name: string) {
    return (name || "").indexOf(".mp4") > 0 || (name || "").indexOf(".MOV") > 0 || (name || "").indexOf(".mov") > 0;
  }
  private isPhoto(name: string) {
    return (name || "").indexOf(".jpg") > 0 || (name || "").indexOf(".JPG") > 0 ||
      (name || "").indexOf(".jpeg") > 0 || (name || "").indexOf(".JPEG") > 0 ||
      (name || "").indexOf(".png") > 0 || (name || "").indexOf(".PNG") > 0;
  }
}