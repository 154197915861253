<ion-header>
  <ion-toolbar>
    <ion-title class="ion-padding-top ion-padding-bottom">
      Replace Assets
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" *ngIf="!loading">
  <div class="quote-filter-container" *ngIf="assets.length > 0">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-select [(ngModel)]="assetType" okText="Okay" cancelText="Dismiss" placeholder="Show All Asset Types"
              (ionChange)="onFilterChange()" class="w-100 ion-padding-bottom">
              <ion-select-option text-wrap [value]="null">
                Show All Asset Types
              </ion-select-option>
              <ion-select-option [value]="i.Id.toString()" *ngFor="let i of assetTypes">{{ i.Description }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <h3 class="ion-padding" *ngIf="assets.length > 0 || jobAssets.length == 0 || anyAssetReplaced()">
    Assets to replace
  </h3>
  <div *ngIf="assets.length === 0" class="ion-text-center ion-padding">
    <div *ngIf="hasReplacedAssets()">
      <div style="font-size: 1.5rem;">
        <i class="fal fa-check-double color-success"></i>
      </div>
      All the Assets have been replaced
    </div>
    <div *ngIf="!hasReplacedAssets()">
      There are no Assets selected for this job.
    </div>
  </div>
  <ion-list>
    <ng-container *ngFor="let item of scrollModel.loadedData">
      <ion-item *ngIf="item.Asset" [class.asset-highlight]="item.Asset.Highlight"
        style="align-items: center; border-bottom: 1px solid lightgray" lines="none">
        <ion-label (click)="previewAsset(item)">
          <ion-note *ngIf="item.AssetPathString" class="text-italic">
            Belongs To: {{item.AssetPathString}}
          </ion-note>
          <div>
            {{item.Asset.Description}}
          </div>
          <ion-note *ngIf="item.Asset.SiteAssetTypeDescription != item.Asset.Description">
            {{item.Asset.SiteAssetTypeDescription}}
          </ion-note>
        </ion-label>
        <ion-button slot="end" color="danger" (click)="replace(item)" *ngIf="!preview">Replace</ion-button>
      </ion-item>
    </ng-container>
  </ion-list>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="scrollModel.loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  <div class="ion-padding-bottom" *ngIf="isAnyAssetReplaced()" class="asset-replaced-summary-item">
    <h4 class="ion-padding">
      Replaced Assets
    </h4>
    <ion-list>
      <ng-container *ngFor="let asset of jobAssets">
        <ion-item *ngIf="isAssetReplaced(asset)">
          <ion-label>
            {{asset.Name}}
          </ion-label>
          <ion-icon *ngIf="!preview" name="create-outline" color="primary"
            (click)="openAsset(asset.Id, asset.ReplacedWithId)" slot="end"></ion-icon>
        </ion-item>
      </ng-container>
    </ion-list>
    <div class="ion-padding">
      <ion-note *ngIf="!preview">If you edit an asset, only the new asset will be changed.</ion-note>
    </div>
  </div>
</ion-content>
<ion-content class="ion-padding" *ngIf="loading">
  <ion-item *ngFor="let i of [1,2,3,4,5]">
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-content>
<ion-footer *ngIf="!loading">
  <ion-toolbar>
    <ion-segment>
      <ion-segment-button (click)="cancel()" color="light">
        <ion-label>Close</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-footer>