import { Component, Input, OnInit } from "@angular/core";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { PopoverController, ModalController, AlertController, ToastController } from "@ionic/angular";
import { Facility } from "src/app/data/entities/Facility";
import * as EventsConstants from "../../../../app/shared/constants/events-constants";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import { BatteryTestResultItemModel } from "../../battery-test.models";
import { BatteryTestService } from "../../battery-test.service";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";
import { BatteryTestUpdateComponent } from "../battery-test-update/battery-test-update.component";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { WebAppShowComponent } from "src/app/webapp/web-app.component";

@Component({
  selector: "battery-test-option-selector",
  templateUrl: "battery-test-option-selector.component.html"
})
export class BatteryTestOptionSelectorComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  checklistId: number;
  @Input()
  asset: SiteAsset;
  @Input()
  test: BatteryTestResultItemModel;
  @Input()
  fromDialog: boolean;

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private userService: UserService,
    private alertController: AlertController,
    private batteryTestService: BatteryTestService,
    private pubSubService: PubSubService,
    private loaderHelperService: LoaderHelperService,
    private browserHelperService: BrowserHelperService
  ) { }

  async ngOnInit() {
  }

  async edit() {
    let modal = await this.modalController.create({
      component: BatteryTestUpdateComponent,
      componentProps: {
        batteryTestId: this.test.Id,
        facility: this.facility,
        checklistId: this.checklistId,
        asset: this.asset,
        fromDialog: this.fromDialog
      }
    });
    modal.present();
    this.popoverController.dismiss();
  }

  async delete() {
    const alert = await this.alertController.create({
      header: "Delete Test?",
      message:
        `Are you sure you want to delete test?`,
      buttons: [
        {
          text: "I am sure",
          handler: async () => {
            try {
              await this.loaderHelperService.on();
              await this.batteryTestService.deleteTest(this.test.Id);
              this.pubSubService.$pub(EventsConstants.ADD_UPDATE_BATTERY_TESTS);
              this.pubSubService.$pub(EventsConstants.DELETE_BATTERY_TESTS, {
                assetId: this.asset ? this.asset.Id : null
              });
              if (window.parent) {
                window.parent.postMessage({ message: "sync-done" }, "*")
              }
            } finally {
              this.loaderHelperService.off();
            }
          }
        },
        {
          text: "Cancel",
          role: "cancel"
        }
      ]
    });
    this.popoverController.dismiss();
    return await alert.present();
  }

  async preview() {
    this.browserHelperService.openUrl(`${this.test.PreviewUrl}`);
  }

  async download() {
    this.browserHelperService.openUrl(this.test.DownloadUrl);
  }
}
