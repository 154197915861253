import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { SelectListItem } from "src/app/assets/assets.models";
import * as _ from "lodash";

@Component({
  selector: "oms-multiple-options-picker",
  templateUrl: "oms-multiple-options-picker.component.html"
})
export class OmsMultipleOptionsPickerComponent implements OnInit {
  @Input()
  model: string;
  @Input()
  options: SelectListItem[];
  @Input()
  preview: boolean;
  @Input()
  switchLimit: number = 3;
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  
  constructor() {
  }

  async ngOnInit() {
  }

  async ionViewDidEnter() {
  }
  
  selectItem(selectListItem: SelectListItem) {
    this.model = selectListItem.Value;
    this.modelChange.emit(this.model);
  }

  onAnswerChange() {
    this.modelChange.emit(this.model);
  }
}
