import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { Facility } from "src/app/data/entities/Facility";
import { ActivatedRoute } from "@angular/router";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { Subscription } from "rxjs";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { BatteryTestService } from "../../battery-test.service";
import { BatteryTestResultItemModel } from "../../battery-test.models";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { ModalController, PopoverController } from "@ionic/angular";
import { BatteryTestUpdateComponent } from "../battery-test-update/battery-test-update.component";
import { BatteryTestOptionSelectorComponent } from "../battery-test-option-selector/battery-test-option-selector.component";

@Component({
  selector: "battery-test-list",
  templateUrl: "battery-test-list.component.html"
})
export class BatteryTestListComponent implements OnInit, OnDestroy {
  @Input()
  facility: Facility;
  @Input()
  checklistId: number;
  @Input()
  asset: SiteAsset;
  loading: boolean;
  @Input()
  fromDialog: boolean;
  items: BatteryTestResultItemModel[] = [];

  private subscription: Subscription;

  constructor(
    private batteryTestService: BatteryTestService,
    private route: ActivatedRoute,
    private pubSub: PubSubService,
    private userService: UserService,
    private browserHelperService: BrowserHelperService,
    private modalController: ModalController,
    private popoverController: PopoverController
  ) {
  }

  async ngOnInit() {
    this.loadData();
    this.subscription = this.pubSub.$sub(EventsConstants.ADD_UPDATE_BATTERY_TESTS, () => {
      this.loadData();
    });
  }

  async ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async doRefresh(event: any) {
    await this.loadData();
    event.target.complete();
  }

  async loadData() {
    if (await this.browserHelperService.isOfflineAndMessage()) {
      return false;
    }
    this.loading = true;
    try {
      this.items = (await this.batteryTestService.getBatteryTests(this.checklistId, this.asset.Id)).Items;
    } finally {
      this.loading = false;
    }
  }

  async more(test: BatteryTestResultItemModel, event: Event) {
    const popover = await this.popoverController.create({
      component: BatteryTestOptionSelectorComponent,
      event: event,
      translucent: true,
      componentProps: {
        facility: this.facility,
        asset: this.asset,
        test: test,
        checklistId: this.checklistId,
        fromDialog: this.fromDialog
      },
      mode: "ios"
    });
    return await popover.present();
  }
}