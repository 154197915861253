import { Injectable } from '@angular/core';
import { BrowserHelperService } from '../browser/browser-helper.service';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { ErrorHandlerService } from '../error/error-handler.service';

@Injectable()
export class BarcodeScannerHelperService {
  private static onBackButtonCallback: () => {} = () => { return false };
  private static inProgress: boolean = false;
  constructor(
    private browserHelperService: BrowserHelperService,
    private barcodeScanner: BarcodeScanner,
    private errorHandlerService: ErrorHandlerService
  ) {
  }
  async scan(prompt: string = undefined): Promise<string> {
    if (!this.browserHelperService.isNativeApp()) {
      alert("You can only use this functionality if you install the app.");
      return null;
    }
    try {
      if (BarcodeScannerHelperService.inProgress) {
        return;
      }
      document.addEventListener("backbutton", BarcodeScannerHelperService.onBackButtonCallback, false);
      BarcodeScannerHelperService.inProgress = true;
      const result = await this.barcodeScanner.scan({
        prompt: ((prompt || "").length > 0 ? prompt : null)
      });
      if (!result.cancelled) {
        return result.text;
      } else {
        alert("Scan Cancelled");
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      alert("Error while scanning");
    } finally {
      BarcodeScannerHelperService.inProgress = false;
      document.removeEventListener("backbutton", BarcodeScannerHelperService.onBackButtonCallback, false);
    }
    return null;
  }
}