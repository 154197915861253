<ion-searchbar [(ngModel)]="query" (ngModelChange)="search()" showCancelButton debounce="500"
    placeholder="Find a Location"></ion-searchbar>
<ng-container *ngIf="!loading">
    <ion-list>
        <ng-container *ngFor="let item of facilities">
            <ion-item>
                <ion-label>
                    <!-- <div *ngIf="item.FACode">FA Code: {{ item.FACode }}</div> -->
                    <div>{{ item.Name }}</div>
                    <div class="my-location-search-item-description">{{ item.Description }}</div>
                    <div class="my-location-search-item-address">{{ item.Address }}</div>
                </ion-label>
                <ion-button (click)="additionalWork(item)" slot="end" color="light"
                    *ngIf="item.Projects && item.Projects.length > 0 && !downloading">
                    <i class="far fa-wrench"></i>
                </ion-button>
                <ion-button (click)="download(item)" slot="end" color="secondary"
                    *ngIf="!item.Downloaded && (!downloading || item.Downloading) && !isVendorTechnician">
                    <i class="far fa-save" *ngIf="!item.Downloading"></i>
                    <ion-spinner *ngIf="item.Downloading" style="width:0.7rem;"></ion-spinner>
                </ion-button>
            </ion-item>
        </ng-container>
    </ion-list>
    <ion-card *ngIf="facilities.length === 0" class="ion-text-center">
        <ion-card-header>
            <ion-card-title>No data found</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <div class="ion-padding-bottom ion-text-start">
                <div>
                    Type in query in the search box above to find a location.
                </div>
                <div>
                    If you can't find a location you either don't have access to it or it is not in OMS.
                </div>
                <div>
                    We will show a maximum of 10 locations that match your search.
                </div>
            </div>
        </ion-card-content>
    </ion-card>
    <oms-bottom-trademark></oms-bottom-trademark>
</ng-container>
<ng-container class="ion-padding" *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
            <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
        </ion-label>
    </ion-item>
</ng-container>