import { Injectable, OnInit } from "@angular/core";
import { ApiService } from "../../shared/services/api/api.service";
import { RepositoryService } from "../../data/db/repository.service";
import { Facility } from "../../data/entities/Facility";
import * as _ from "lodash";
import { SiteAsset } from "../../data/entities/SiteAsset";
import { Repository } from "typeorm";
import { BaseResponse } from "src/app/shared/models/base-response-model";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventsConstants from "src/app/shared/constants/events-constants";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";
import { SyncQueueService } from "src/app/sync/sync-queue.service";

@Injectable()
export class AssetsSyncService {

  constructor(
    private apiService: ApiService,
    private repositoryService: RepositoryService,
    private pubSub: PubSubService,
    private errorHandlerService: ErrorHandlerService,
    private syncQueueService: SyncQueueService
  ) {
  }

  get facilityRepo(): Repository<Facility> {
    return this.repositoryService.getFacilityRepository();
  }

  async sync(): Promise<boolean> {
    try {
      let items = await this.syncQueueService.getItemsToSync("facility");
      for (let item of items) {
        let facility: Facility = JSON.parse(item.Data);
        const syncResult = await this.syncAssets(facility.Id, facility.Assets);
        if (syncResult) {
          await this.syncQueueService.removeSynchronizedItem(item);
          this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
        }
      }
    } catch (err) {
      this.errorHandlerService.logError(err);
      return false;
    }
    return true;
  }

  private async syncAssets(
    siteKey: string,
    assets: SiteAsset[]
  ): Promise<boolean> {
    return (await this.apiService.post<BaseResponse>("assets/syncassets", {
      siteKey,
      assets
    })).Success;
  }
}
