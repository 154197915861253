import { Component, Input } from "@angular/core";
import * as _ from "lodash";

@Component({
  selector: "native-multi-file-upload-media-selector",
  templateUrl: "native-multi-file-upload-media-selector.component.html"
})
export class NativeMultiFileUploadMediaSelectorComponent {
  @Input()
  getFileName: () => string;
  @Input()
  takePhoto: () => void;
  @Input()
  selectFile: () => void;

  async select(event: Event): Promise<void> {
    event.preventDefault();
    this.selectFile();
  }

  async capturePhoto(event: Event) {
    this.takePhoto()
  }
}
