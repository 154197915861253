import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AssetsService } from "../assets.service";
import * as _ from "lodash";
import { Facility } from "src/app/data/entities/Facility";
import { AssetQuoteModel, MemberLookup, SiteAssetType } from "../assets.models";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { ModalController } from "@ionic/angular";
import { MyWorkService } from "src/app/my-work/my-work.service";
import { AssetManageService } from "../asset-manage.service";
import { BatteryTestsComponent } from "../battery-tests/battery-tests.component";
import { Subscription } from "rxjs";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventsConstants from "../../../app/shared/constants/events-constants";

@Component({
  selector: "checklist-answer-battery-test-upload",
  templateUrl: "checklist-answer-battery-test-upload.component.html"
})
export class ChecklistAnswerBatteryTestUploadComponent implements OnInit, OnDestroy {
  facility: Facility;
  assets: AssetQuoteModel[] = [];
  loading: boolean = false;
  assetTypes: SiteAssetType[] = [];
  assetType: number;
  lookupData: MemberLookup;
  quotePreviewWeb: boolean;
  @Input()  preview: boolean;
  @Input() facilityId: string;
  @Input() checklistId: number;
  @Input() vendorViewId: number;
  assetIds: string[];
  onUploaded: (asset: SiteAsset) => {};

  private subscription: Subscription;
  private subscription1: Subscription;

  constructor(
    private assetsService: AssetsService,
    private modalController: ModalController,
    private myWorkService: MyWorkService,
    private assetManageService: AssetManageService,
    private pubSubService: PubSubService
  ) {
  }

  async ngOnInit() {
    this.subscription = this.pubSubService.$sub(EventsConstants.ADD_BATTERY_TESTS, async (data: { assetId: string }) => {
      if (data && data.assetId) {
        let siteAsset = this.assetsService.getAssetFromFacility(this.facility, data.assetId);
        if (siteAsset) {
          this.updateJobAssetTestCount(data.assetId, 1);
          this.onUploaded(siteAsset);
        }
      }
    });
    this.subscription1 = this.pubSubService.$sub(EventsConstants.DELETE_BATTERY_TESTS, async (data: { assetId: string }) => {
      if (data && data.assetId) {
        let siteAsset = this.assetsService.getAssetFromFacility(this.facility, data.assetId);
        if (siteAsset) {
          this.updateJobAssetTestCount(data.assetId, -1);
        }
      }
    });
  }

  async ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
  }

  async ionViewWillEnter() {
    this.loading = true;
    this.facility = await this.assetsService.getFacilityWithAssets(this.facilityId);
    this.assetManageService.init(this.facility);
    let job = (await this.myWorkService.getJob(this.checklistId));
    let jobAssets = (job || {}).Assets || [];
    this.assets = [];

    await this.assetRecursive(this.facility.Assets, 0, async (asset: SiteAsset, level: number) => {
      let jobAsset = jobAssets.find(x => (x.ReplacedWithId ? x.ReplacedWithId : x.Id) == asset.Id);
      if (!asset.Deleted && asset.BatteryTestEnabled && jobAsset) {
        let assetPathString = null;
        let assetPath = await this.assetsService.getAssetPath(this.facility, asset.Id);
        let tmp = [];
        for (let j = 0; j < assetPath.length - 1; j++) {
          tmp.push(assetPath[j].Description);
        }
        if (tmp.length > 0) {
          assetPathString = tmp.join("/");
        }
        this.assets.push({
          Asset: asset,
          Battery: null,
          BatteryModel: null,
          Selected: false,
          Level: level,
          AssetPathString: assetPathString,
          Quantity: 0,
          OldQuantity: 0,
          Hidden: false,
          SelectedImages: [],
          BatteryTests: jobAsset.BatteryTests || 0
        });
      }
    });
    this.loading = false;
  }

  async previewAsset(item: AssetQuoteModel) {
    await this.assetManageService.editAsset(item.Asset, this.checklistId, false, false, this.preview, this.vendorViewId);
  }


  async upload(item: AssetQuoteModel) {
    if (this.preview) {
      return;
    }
    if (!item.Asset.BatteryTestEnabled) {
      alert("Battery test upload not enabled for this asset type")
    }
    let batteryModelId = 0;
    let batteryModelAnswer = _.find(item.Asset.Answers, answer => answer.FieldDataType == "battery-model");
    if (batteryModelAnswer) {
      batteryModelId = batteryModelAnswer.DataFloat || 0;
    }
    let batteryDateCodeValue = null;
    let batteryDateCode = _.find(item.Asset.Answers, answer => answer.FieldDataType == "datecode");
    if (batteryDateCode) {
      batteryDateCodeValue = batteryDateCode.AnswerText || batteryDateCode.Data;
    }
    const modal = await this.modalController.create({
      component: BatteryTestsComponent,
      componentProps: {
        facilityId: this.facility.Id,
        checklistId: this.checklistId,
        assetId: item.Asset.Id,
        fromDialog: true,
        addedDuringReplace: false,
        dateCode: batteryDateCodeValue,
        batteryId: batteryModelId,
        vendorViewId: this.vendorViewId
      }
    });
    return await modal.present();
  }

  cancel() {
    if (window.parent) {
      window.parent.postMessage({ message: "close-modal" }, "*")
    }
    this.modalController.dismiss();
  }

  private async assetRecursive(siteAssets: SiteAsset[], level: number, callback: (item: SiteAsset, level: number) => void): Promise<void> {
    for (let asset of siteAssets) {
      await callback(asset, level);
      await this.assetRecursive(asset.Assets, level + 1, callback);
    }
  }

  private async updateJobAssetTestCount(siteAssetId: string, increment: number) {
    if (!siteAssetId) {
      return;
    }
    let siteAsset = this.assetsService.getAssetFromFacility(this.facility, siteAssetId);
    if (siteAsset) {
      let job = await this.myWorkService.getJob(this.checklistId);
      if (job.Assets) {
        let jobAsset = _.find(job.Assets, x => (x.ReplacedWithId ? x.ReplacedWithId : x.Id) == siteAsset.Id);
        if (jobAsset) {
          jobAsset.BatteryTests = (jobAsset.BatteryTests || 0) + increment;
          if (jobAsset.BatteryTests < 0) {
            jobAsset.BatteryTests = 0;
          }
          await this.myWorkService.updateJob(job);

          let tmpAsset = _.find(this.assets, x => x.Asset.Id == siteAssetId);
          if (tmpAsset) {
            tmpAsset.BatteryTests = jobAsset.BatteryTests;
          }
        }
      }
    }
  }
}