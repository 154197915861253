import { Component, Input, OnInit } from "@angular/core";
import { SiteAsset } from "src/app/data/entities/SiteAsset";
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AssetsService } from "../assets.service";
import { Facility } from "src/app/data/entities/Facility";
import { AssetWorkHistory, AvailableScheduleData, AvailableScheduleVendorData, LookupListItem, MemberLookup, ScheduleAssetModel, ScheduleModel } from "../assets.models";
import { MemberService } from "../member.service";
import { BrowserHelperService } from "src/app/shared/services/browser/browser-helper.service";
import { AssetScheduleService } from "../services/quotes/asset-schedule.service";
import { DateTimeHelperService } from "src/app/shared/services/datetime/datetime-helper.service";
import * as _ from "lodash";
import { LoaderHelperService } from "src/app/shared/services/browser/loader-helper.service";
import { ApiService } from "src/app/shared/services/api/api.service";
import { UserService } from "src/app/shared/services/user-service/user-service.service";

@Component({
  selector: "asset-work-history",
  templateUrl: "asset-work-history.component.html"
})
export class AssetWorkHistoryComponent implements OnInit {
  @Input()
  facility: Facility;
  @Input()
  asset: SiteAsset;
  loading: boolean = false;
  history: AssetWorkHistory[] = [];

  constructor(
    private assetsService: AssetsService,
    private modalController: ModalController,
    private browserHelperService: BrowserHelperService,
    private apiService: ApiService,
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    this.loading = true;
    try {
      this.history = (await this.assetsService.getAssetWorkHistory(this.asset.Id)).Items;
    }
    finally {
      this.loading = false;
    }
  }

  async openCloseout(item: AssetWorkHistory) {
    let baseUrl = await this.apiService.getBaseUrl();
    let token = await this.userService.getUserToken();
    this.browserHelperService.openUrl(`${baseUrl}/CloseoutPackage/GetGeneratedPdf/${item.JobId}?mobile-app-token=${token}`);
  }

  async dismissModal(): Promise<void> {
    await this.modalController.dismiss();
  }
}