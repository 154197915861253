<div [hidden]="!image.Loaded" class="gallery-image-web">
    <img [src]="image.ThumbnailUrl" (click)="openImage(image)" (load)="imageLoaded(image)" />
    <div class="image-datetime">{{image.DateCreated | localdatetime}}</div>
    <i class="fal fa-check-double color-success uploaded" *ngIf="image.IsUploaded"></i>
    <i class="far fa-user-circle color-primary uploaded-by-me" *ngIf="image.UploadedByMe"></i>
    <i class="fal fa-times color-danger deleted-web" *ngIf="image.DeletedOnTheWeb"></i>
    <ion-button size="small" (click)="delete()" *ngIf="!selectable && !preview">
        <ion-icon name="trash-outline" *ngIf="!image.Deleting"></ion-icon>
        <ion-spinner *ngIf="image.Deleting"></ion-spinner>
    </ion-button>
    <ion-toggle [(ngModel)]="image.Selected" *ngIf="selectable && !preview" (ionChange)="imageToggled()"
        [disabled]="!image.IsUploaded"></ion-toggle>
</div>
<div class="checklist-image-skeleton" *ngIf="!image.Loaded">
    <div class="img">
        <ion-spinner name="dots"></ion-spinner>
    </div>
</div>