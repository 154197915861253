import { Injectable, OnInit, OnDestroy, Injector } from "@angular/core";
import * as _ from "lodash";
import { Network } from '@ionic-native/network/ngx';
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import { BaseResponse, BaseDataResponse } from "src/app/shared/models/base-response-model";
import { CameraHelperService } from "src/app/shared/services/plugin-helpers/camera-helper.service";
import { ApiService } from "../shared/services/api/api.service";
import { Entry } from '@ionic-native/file/ngx';
import * as EventsConstants from "../shared/constants/events-constants";
import { Subscription } from "rxjs";
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";
import * as moment from "moment";
import { AssetsSyncService } from "../assets/sync/assets-sync.service";
import { ChecklistSyncService } from "../checklist/sync/checklist-sync.service";
import { ChecklistService } from "../checklist/checklist.service";
import { AssetsService } from "../assets/assets.service";
import { RepositoryService } from "../data/db/repository.service";
import { SyncQueueModel } from "../data/entities/SyncQueue";
import { SyncQueueService } from "./sync-queue.service";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import { MyWorkSyncService } from "../my-work/my-work-sync.service";

@Injectable()
export class SyncService implements OnInit, OnDestroy {
  private syncInProgress = false;
  private lastActivity = moment().utc();
  private subscription: Subscription;
  constructor(
    private apiService: ApiService,
    private pubSub: PubSubService,
    private cameraHelperService: CameraHelperService,
    private network: Network,
    private browserHelperService: BrowserHelperService,
    private assetSyncService: AssetsSyncService,
    private checklistSyncService: ChecklistSyncService,
    private myWorkSyncService: MyWorkSyncService,
    private checklistService: ChecklistService,
    private assetService: AssetsService,
    private repositoryService: RepositoryService,
    private syncQueueService: SyncQueueService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.ngOnInit();
  }

  private webSyncQueue: number = 0;

  async ngOnInit() {
    this.lastActivity = moment().utc();
    if (!this.browserHelperService.isNativeApp()) {
      this.initWebLoop();
    }
    this.subscription = this.pubSub.$sub(EventsConstants.TRIGGER_SYNC_WEB, () => {
      this.webSyncQueue++;
      if (this.webSyncQueue < 1) {
        this.webSyncQueue = 1;
      }
    });
  }

  initWebLoop() {
    setTimeout(async () => {
      try {
        if (this.webSyncQueue > 0) {
          let currentSyncQueue = this.webSyncQueue;
          await this.syncWeb();
          this.webSyncQueue -= currentSyncQueue;
        };
      } finally {
        this.initWebLoop();
      }
    }, 1000);
  }

  async ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async getPendingDataCount() {
    const count = await this.syncQueueService.getItemsToSyncCount();
    return count;
  }

  async getPendingImagesCount() {
    return (await this.cameraHelperService.getImagesTaken()).length;
  }

  async getPendingTotalCount() {
    return (await this.getPendingDataCount()) + (await this.getPendingImagesCount());
  }

  async sync() {
    try {
      if (!this.isConnected()) {
        this.pubSub.$pub(EventsConstants.SYNC_DONE);
        return;
      }
      // if (this.syncInProgress) {
      //   var duration = moment.duration(moment().utc().diff(this.lastActivity || moment().utc())).asSeconds();
      //   if (duration > 60) {
      //     this.pubSub.$pub(EventsConstants.SYNC_DONE);
      //   }
      // }
      if (this.syncInProgress) {
        return;
      }

      let itemsToSync = await this.getPendingTotalCount();
      if (itemsToSync === 0) {
        this.pubSub.$pub(EventsConstants.SYNC_DONE);
        return;
      }
      this.syncInProgress = true;
      await this.syncWeb();
    } catch (err) {
      this.logError(err);
    }
    // finally {

    // }
    this.syncInProgress = false;
    this.lastActivity = moment().utc();
    this.pubSub.$pub(EventsConstants.SYNC_DONE);
  }

  private async syncWeb() {
    this.pubSub.$pub(EventsConstants.SYNC_STARTED);
    try {
      this.lastActivity = moment().utc();
      await this.assetSyncService.sync();
    } catch (err) {
      this.logError(err);
    }
    this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
    try {
      this.lastActivity = moment().utc();
      await this.checklistSyncService.sync();
    } catch (err) {
      this.logError(err);
    }
    this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
    try {
      this.lastActivity = moment().utc();
      await this.myWorkSyncService.sync();
    } catch (err) {
      this.logError(err);
    }
    this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
    try {
      this.lastActivity = moment().utc();
      const imagesTaken = await this.cameraHelperService.getImagesTaken();
      if (imagesTaken.length > 0) {
        for (let image of imagesTaken) {
          await this.syncImage(image);
        }
      }
    } catch (err) {
      this.logError(err);
    }
    try {
      window.parent.postMessage({ message: "sync-done" }, "*")
    }
    catch { }
  }

  async getItemsToSync(type: 'facility' | 'checklist'): Promise<SyncQueueModel> {
    let items = await this.repositoryService.getSyncQueueRepository().find({
      where: {
        Type: type
      }
    });
    let item = _.last(_.orderBy(items, item => item.TimeStampUpdated, ['desc']));
    return {
      lastItem: item,
      items: items
    }
  }

  async removeSynchronizedItems(model: SyncQueueModel): Promise<void> {
    const chunks = _.chunk(model.items, 100);
    for (let syncChunk of chunks) {
      await this.repositoryService.getSyncQueueRepository().remove(syncChunk, { transaction: false });
    }
  }

  private async syncImage(image: Entry): Promise<boolean> {
    let fileSync = await this.cameraHelperService.getFileSync(image.name);
    try {
      this.lastActivity = moment().utc();
      const base64Image = await this.cameraHelperService.getPhotoBase64(image.nativeURL);
      let result = await this.apiService.post<BaseDataResponse<number>>("image/uploadimage", {
        FileName: image.name,
        OriginalFileName: fileSync?.OriginalFileName,
        Base64: base64Image
      });
      if (result.Success) {
        const uploadedDirectory = await this.cameraHelperService.getImagesUploadedDirectory();
        image.moveTo(uploadedDirectory, null, (entry => {
          this.pubSub.$pub(EventsConstants.IMAGE_SYNC_DONE, {
            entry: entry,
            id: result.Data
          });
        }));
        this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
        return true;
      } else if (result.Message === "Code-DeleteImage") {
        const deletedDirectory = await this.cameraHelperService.getDeletedImagesDirectory();
        image.moveTo(deletedDirectory);
        this.pubSub.$pub(EventsConstants.SYNC_UPDATE);
      }
    } catch (err) {
      this.logError(err);
    } finally {
    }
  }

  private async getInfo(pendingDataCount: number) {
    try {
      const imagesTaken = await this.cameraHelperService.getImagesTaken();
      const imagesUploaded = await this.cameraHelperService.getImagesUploaded();
      const imagesDeleted = await this.cameraHelperService.getDeletedImages();
    } catch (err) {
      this.logError(err);
      alert("Error while getting info");
    }
  }

  private async loadImageLogsData() {
    const imagesTaken = await this.cameraHelperService.getImagesTaken();
    const imagesUploaded = await this.cameraHelperService.getImagesUploaded();
    const imagesDeleted = await this.cameraHelperService.getDeletedImages();
  }

  private isConnected(): boolean {
    if (this.browserHelperService.isNativeApp()) {
      let conntype = this.network.type;
      return conntype && conntype !== 'unknown' && conntype !== 'none';
    }
    return true;
  }

  private logError(err) {
    try {
      this.errorHandlerService.logError(err)
    } catch { }
  }
}
