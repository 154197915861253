import { Injectable } from "@angular/core";
import { ApiService } from "../shared/services/api/api.service";
import { RepositoryService } from "../data/db/repository.service";
import * as _ from "lodash";
import { DateTimeHelperService } from "../shared/services/datetime/datetime-helper.service";
import { BrowserHelperService } from "../shared/services/browser/browser-helper.service";
import { PubSubService } from "../shared/services/pubsub/pub-sub.service";
import * as EventConstants from "../shared/constants/events-constants";
import { SyncQueueService } from "../sync/sync-queue.service";
import { ErrorHandlerService } from "../shared/services/error/error-handler.service";
import * as moment from "moment";
import { BaseDataResponse, BaseResponse, BaseResponseModel } from "../shared/models/base-response-model";
import { BatteryChemistry, BatteryInfo, BatteryTest, BatteryTester, BatteryTestResultItemModel, ParsedBatteryTestModel } from "./battery-test.models";

@Injectable()
export class BatteryTestService {
  constructor(
    private apiService: ApiService,
    private repositoryService: RepositoryService,
    private dateTimeHelperService: DateTimeHelperService,
    private browserHelperService: BrowserHelperService,
    private pubSubService: PubSubService,
    private syncQueueService: SyncQueueService,
    private errorHandlerService: ErrorHandlerService
  ) { }
  async getBatteryTests(checklistId: number, assetId: string): Promise<BaseResponseModel<BatteryTestResultItemModel>> {
    return await this.apiService.get("batterytest/get", { checklistId, assetId });
  }

  async getBatteryTesters(memberId: number): Promise<BaseResponseModel<BatteryTester>> {
    return await this.apiService.get("batterytest/gettesters", { memberId });
  }

  async getBatteryChemistries(): Promise<BaseResponseModel<BatteryChemistry>> {
    return await this.apiService.get("batterytest/getchemistries");
  }

  async processFile(data: FormData): Promise<BaseDataResponse<ParsedBatteryTestModel>> {
    return await this.apiService.post("batterytest/processfile", data);
  }

  async getBatteryInfo(batteryId: number): Promise<BaseDataResponse<BatteryInfo>> {
    return await this.apiService.get("batterytest/getbatteryinfo", { id: batteryId });
  }

  async addTest(data: FormData): Promise<BaseDataResponse<ParsedBatteryTestModel>> {
    return await this.apiService.post("batterytest/addtests", data);
  }

  async updateTest(data: ParsedBatteryTestModel): Promise<BaseResponse> {
    return await this.apiService.post("batterytest/updatetests", data);
  }

  async getModel(id: number): Promise<BaseDataResponse<ParsedBatteryTestModel>> {
    return await this.apiService.get("batterytest/getmodel", { id: id });
  }

  async deleteTest(id: number): Promise<BaseResponse> {
    return await this.apiService.post("batterytest/deletetest", { id: id });
  }
}
