<oms-header [title]="title" [subtitle]="'Location: ' + facility?.Name">
  <ion-back-button defaultHref="/facilities" *ngIf="!isWeb"></ion-back-button>
</oms-header>
<ion-content class="checklist-questions-container" *ngIf="!loading">
  <checklist-list-view [facility]="facility" [checklist]="checklist" [preview]="preview" *ngIf="mode == 'list'">
  </checklist-list-view>
  <checklist-summary-view [facility]="facility" [checklist]="checklist" (mode)="switchMode($event)"
    *ngIf="mode == 'summary'">
  </checklist-summary-view>
  <gallery [facilityId]="facility.Id" [checklistId]="checklist.Id" [checklist]="checklist" [checklistGallery]="true"
    [embed]="true" [allowTakingPhotos]="true" *ngIf="mode == 'images'"></gallery>
  <preview-view [checklist]="checklist" *ngIf="mode == 'preview'">
  </preview-view>
</ion-content>
<ion-content *ngIf="loading">
  <ion-item *ngFor="let i of [1,2,3,4,5]">
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-content>
<ion-tab-bar slot="bottom" *ngIf="!loading" class="checklist-questions-tabs">
  <ion-tab-button (click)="switchMode('summary')" [class.tab-selected]="mode == 'summary'">
    <i class="far fa-th-list"></i>
    <ion-label>Summary</ion-label>
  </ion-tab-button>
  <ion-tab-button (click)="switchMode('list')" [class.tab-selected]="mode == 'list'">
    <i class="fas fa-tasks"></i>
    <ion-label>List</ion-label>
  </ion-tab-button>
  <ion-tab-button (click)="switchMode('images')" [class.tab-selected]="mode == 'images'" *ngIf="!isWeb">
    <i class="fas fa-image"></i>
    <ion-label>Gallery</ion-label>
  </ion-tab-button>
  <ion-tab-button (click)="switchMode('preview')" [class.tab-selected]="mode == 'preview'" *ngIf="!isWeb">
    <i class="far fa-file-pdf"></i>
    <ion-label>Preview</ion-label>
  </ion-tab-button>
</ion-tab-bar>