<oms-header [title]="'Job ID:' + jobModel?.ChecklistId" [subtitle]="'Location: ' + facility?.Name"
    [subtitle2]="'Project: ' + jobModel?.ProjectName">
    <ion-back-button defaultHref="/facilities"></ion-back-button>
</oms-header>
<ion-content class="my-work-details-container ion-padding" *ngIf="!loading">
    <ion-list>
        <ion-item *ngFor="let item of jobModel.Documents" (click)="openFile(item)">
            <ion-label>
                {{item.FileName}}
                <div>
                    <ion-note>
                        {{item.Description}}
                    </ion-note>
                </div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="jobModel.Documents.length == 0" lines="none">
            No Files
        </ion-item>
    </ion-list>
</ion-content>
<ion-content *ngIf="loading">
    <ion-item *ngFor="let i of [1,2,3,4,5]">
        <ion-label>
            <h3>
                <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
            </h3>
        </ion-label>
    </ion-item>
</ion-content>