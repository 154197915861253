import { Entity, PrimaryGeneratedColumn, Column } from "typeorm";

@Entity("memberconfiguration")
export class MemberConfiguration {
  @PrimaryGeneratedColumn()
  Id: number;
  @Column({ nullable: true })
  MemberId: number;
  @Column({ nullable: true })
  AsssetConfigurationData: string;
  @Column({ nullable: true })
  LookupData: string;
}