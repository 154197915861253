import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { GalleryService } from "./gallery.service";
import { GalleryComponent } from "./gallery.component";
import { GalleryRoutingModule } from "./gallery-routing.module";
import { GalleryItemComponent } from "./gallery-item/gallery-item.component";
import { ImageItemComponent } from "./image-item/image-item.component";

@NgModule({
    imports: [
        SharedModule.forRoot(),
        GalleryRoutingModule,
    ],
    declarations: [
        GalleryComponent,
        GalleryItemComponent,
        ImageItemComponent
    ],
    entryComponents: [
    ],
    exports: [
        GalleryComponent
    ],
    providers: [
        GalleryService
    ]
})
export class GalleryModule { }
