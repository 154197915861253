import { Component, OnInit, OnDestroy } from "@angular/core";
import { SyncService } from "src/app/sync/sync.service";
import { Subscription } from "rxjs";
import { PubSubService } from "src/app/shared/services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants";
import { ErrorHandlerService } from "src/app/shared/services/error/error-handler.service";

@Component({
  selector: "oms-pending-changes",
  templateUrl: "pending-changes.component.html"
})
export class PendingChangesComponent implements OnInit, OnDestroy {
  pendingDataCount: number = 0;
  pendingImageCount: number = 0;
  constructor(
    private syncService: SyncService,
    private pubSub: PubSubService,
    private errorHandlerService: ErrorHandlerService
  ) {
  }

  private subscription1: Subscription;
  private subscription2: Subscription;

  async ngOnInit() {
    await this.loadData();
    this.subscription1 = this.pubSub.$sub(EventConstants.SYNC_DONE, () => {
      this.loadData();
    });
    this.subscription2 = this.pubSub.$sub(EventConstants.SYNC_STARTED, () => {
      this.loadData();
    });
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }

  async sendLogs() {
    this.errorHandlerService.sendLogs();
  }
  
  private async loadData() {
    this.pendingDataCount = await this.syncService.getPendingDataCount();
    this.pendingImageCount = await this.syncService.getPendingImagesCount();
  }
}
