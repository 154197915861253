import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { PubSubService } from "../../services/pubsub/pub-sub.service";
import * as EventConstants from "src/app/shared/constants/events-constants"
import { Subscription } from "rxjs";
import { SyncService } from "src/app/sync/sync.service";

@Component({
  selector: "oms-header",
  templateUrl: "header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  subtitle2: string;
  @Input()
  menu: boolean = true;
  pendingCount: number = 0;
  preview: boolean = true;
  
  private subscription1: Subscription;
  private subscription2: Subscription;

  constructor(private pubSub: PubSubService,
    private syncService: SyncService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  async ngOnInit() {
    if (this.menu) {
      this.subscription1 = this.pubSub.$sub(EventConstants.SYNC_UPDATE, async () => {
        await this.setPendingChanges();
      });
      this.subscription2 = this.pubSub.$sub(EventConstants.SYNC_DONE, async () => {
        await this.setPendingChanges();
      });
    }
  }

  ionViewWillEnter() {
    this.setPendingChanges();
  }

  ngOnDestroy() {
    if (this.menu) {
      this.subscription1.unsubscribe();
      this.subscription2.unsubscribe();
    }
  }

  private async setPendingChanges() {
    if (this.menu) {
      this.pendingCount = await this.syncService.getPendingTotalCount();
      this.changeDetectorRef.detectChanges();
    }
  }
}
