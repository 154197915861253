import { Component, Input, OnInit } from "@angular/core";
import { SiteAsset } from "../../data/entities/SiteAsset";
import * as _ from "lodash";
import { AssetsService } from "../assets.service";
import { Facility } from "src/app/data/entities/Facility";

@Component({
  selector: "asset-heatmap-indicator",
  templateUrl: "asset-heatmap-indicator.component.html"
})
export class AssetHeatmapIndicatorComponent implements OnInit {
  @Input() facility: Facility;
  @Input() asset: SiteAsset;
  @Input() assets: SiteAsset[];
  @Input() checkSub: boolean = true;
  @Input() takeSpace: boolean;

  constructor(private assetsService: AssetsService) { }

  ngOnInit() {
    if(this.asset) {
      this.asset.Calc = this.assetsService.getSiteAssetCalcInfo(this.asset, this.facility);
    }
    if (this.assets) {
      let filtered = _.filter(this.assets, a => a.OneToMany == true);
      let itemsWithCalc: SiteAsset[] = [];
      for (let item of filtered) {
        let calc = this.assetsService.getSiteAssetCalcInfo(item, this.facility);
        if (calc) {
          item.Calc = calc;
          itemsWithCalc.push(item);
        }
        if (this.checkSub) {
          let subFiltered = _.filter(item.Assets, a => a.OneToMany == false);
          for (let subItem of subFiltered) {
            let calc = this.assetsService.getSiteAssetCalcInfo(subItem, this.facility);
            if (calc) {
              subItem.Calc = calc;
              itemsWithCalc.push(subItem);
            }
          }
        }
      }
      let asset = _.find(itemsWithCalc, a => a.Calc.IsFail) ||
        _.find(itemsWithCalc, a => a.Calc.IsWarn) ||
        _.find(itemsWithCalc, a => a.Calc.IsPass);
      if (asset) {
        this.asset = asset;
      }
    } else if (this.checkSub) {
      let subAssetCalc = this.getSubAssetCalc(this.asset, this.asset.Assets);
      if (subAssetCalc) {
        this.asset = subAssetCalc;
      }
    }
  }

  getSubAssetCalc(asset: SiteAsset, assets: SiteAsset[]) {
    let filtered = _.filter(assets, a => a.OneToMany == false);
    let itemsWithCalc = [];
    if (asset && asset.Calc) {
      itemsWithCalc.push(asset);
    }
    for (let item of filtered) {
      let calc = this.assetsService.getSiteAssetCalcInfo(item, this.facility);
      if (calc) {
        item.Calc = calc;
        itemsWithCalc.push(item);
      }
    }
    let returnAsset = _.find(itemsWithCalc, a => a.Calc.IsFail) ||
      _.find(itemsWithCalc, a => a.Calc.IsWarn) ||
      _.find(itemsWithCalc, a => a.Calc.IsPass);
    return returnAsset;
  }
}
